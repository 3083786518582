import React, {useState} from 'react';

import styles from './DeclineItem.module.css';
import { useSelector } from 'react-redux';
import vector from '../../../../../assets/svg/vector.svg'

import { useNavigate } from 'react-router-dom';

import {useMutation, useQuery} from '@apollo/client';
import {REFUND_ITEM, STATUS_REFUND_ITEM} from '../../../../../requests/refundRequests';


import {refundItemApi} from '../../../../../utils/apiCalls/ordersApi';


function ReasonButton ({reason, setValue}) {
    const reasonButtons = ['Брак', 'Поврежден при доставке', 'Не подошел', 'Не тот товар']

    return (
      <div className={styles.reazonButtonContainer}>
        {reasonButtons.map((button, index) => (
          <button
            type="button"
            className={`${styles.reazonButton} ${
                reason === button ? styles.active : ""
            }`}
            key={index}
            onClick={() => setValue(button)}
          >
            {button}
          </button>
        ))}
      </div>
    );
  };



function handleDelete(imageUrl, type) {
    console.log('delete', imageUrl)
    }





const ImageInput = ({ imageList, title, handleMediaChange, handleDelete, type, info, error }) => {
        


        return (
            <div className={styles.mediaContainer}>
                <div className={styles.avatarContainer}>
                    <input
                        type="file"
                        accept="image/*"
                        className={styles.newAvatar}
                        onChange={(e) => handleMediaChange(e, type)}
                    />
                </div>
    
                {imageList.length > 0 ? imageList.map((imageUrl) => (
                    <div className={styles.avatarContainer} key={imageUrl}>
                        <img src={imageUrl} alt="foto" className={styles.avatar} />
                        <div type="button" className={styles.deleteButton} onClick={() => handleDelete(imageUrl, type)}>
                            <img src={vector} alt="delete" />
                        </div>
                    </div>
                )) : 
                    <div className={styles.infoFotoContainer}>
                        <div className={styles.fotoTitle}>{title}</div>
                        {type === 'date' ? 
                            <div></div> :  
                            <div className={styles.necessarity}>Обязательно</div> 
                        }
                        <div className={styles.fotoInfo}>до {type === 'item' ? 4 : 2} фотографий в формате PNG, JPEG</div>
                    </div>
                }
                {info && <div className={styles.info}>{info}</div>}
                {error && <div className={styles.error}>{error}</div>}
            </div>
        );
    };


function Media({imageListItem, 
                setImageListItem, 
                imageListRezon, 
                setImageListRezon, 
                imageListDate, 
                setImageListDate}) {


    const [itemError, setItemError] = useState(null);
    const [rizonError, setRizonError] = useState(null);
    const [dataError, setDataError] = useState(null);

    const [infoItem, setInfoItem] = useState(null);
    const [infoRizon, setInfoRizon] = useState(null);
    const [infoData, setInfoData] = useState(null);
    

    function handleMediaChange(event, type) {

        
            console.log('type', type)


            const setError = {
                item: setItemError,
                rizon: setRizonError,
                data: setDataError,
              }[type];

            const setInfo = {
                item: setInfoItem,
                rizon: setInfoRizon,
                data: setInfoData,
              }[type];


            if(type === 'item' && imageListItem.length >= 4) {
                alert("No more than 4 images allowed.");
            } else if(type === 'rizon' && imageListRezon.length >= 2) {
                alert("No more than 2 images allowed.");
            }
            else if(type === 'date' && imageListDate.length >= 2) {
                alert("No more than 2 images allowed.");
            } else {

            const url = `https://api.jbspace.xyz/files/item_photo`
            const file = event.target.files[0];

            setError(null);
            setInfo('загрузка на сервер...');


            


            if (!file) {
                console.log('Uploader: No file selected!');
                return;
              }
          
            // setUrl(URL.createObjectURL(selectedFile));
            // setInfo('загрузка на сервер...');
            console.log('file', file)
          
            const formData = new FormData();
            formData.append('files', file);
          
            fetch(url, { method: 'POST', body: formData })
                  .then((response) => {
                    // console.log('RESPONSE', response)
                    if (response.ok) return response.json();
                    throw new Error(`Upload failed with status ${response.status}`);

                  })
                  .then((data) => {
                    console.log('data', data);
                    
                    if(type === 'item'){      
                        setImageListItem(prevItems => [...prevItems, data.url[0]]);
                      } else if (type ==='rizon') {
                        setImageListRezon(prevItems => [...prevItems, data.url[0]]);
                      } else {
                        setImageListDate(prevItems => [...prevItems, data.url[0]]);
                      }
                    setInfo(null);

                  })
              
                  .catch((error) => {
                    setError('не получилось загрузить фото, попробуйте еще раз');
                //   
                    // setUrl(null);
                    setInfo(null);
                    console.error('Error:', error);
                  });

                }
          }



    return (
        <>
        <div className={styles.fotoContainer}>
            <ImageInput
                imageList={imageListItem}
                title="Товар целиком"
                handleMediaChange={handleMediaChange}
                handleDelete={handleDelete}
                type='item'
                info={infoItem}
                error={itemError}
            />
            <ImageInput
                imageList={imageListRezon}
                title="Дефект крупным планом"
                handleMediaChange={handleMediaChange}
                handleDelete={handleDelete}
                vector={vector}
                type ='rizon'
                info={infoRizon}
                error={rizonError}
            />
            <ImageInput
                imageList={imageListDate}
                title="Срок годности или серийный номер"
                handleMediaChange={handleMediaChange}
                handleDelete={handleDelete}
                type = 'date'
                info={infoData}
                error={dataError}
            />
        </div>
    </>
    )
            }






function DeclineItem(){

    const [imageListItem, setImageListItem] = useState([])
    const [imageListRezon, setImageListRezon] = useState([])
    const [imageListDate, setImageListDate] = useState([])


    const navigate = useNavigate();
    const item = useSelector(state => state.userOrder.item);


    console.log('item', item)
    
    const [comment, setComment] = useState('')
    const [reason, setReason] = useState('')

    const [loadProcess, setLoadProcess] = useState(false)
    // const [changeStatus] = useMutation(STATUS_REFUND_ITEM);
    // const [insertRefund] = useMutation(REFUND_ITEM);

    const disabled = reason !== '' && imageListItem.length !== 0 && imageListRezon.length !== 0 ;
    // console.log('disabled', disabled)
    // console.log(
    //     'imageListItem', imageListItem,
    //     'imageListRezon', imageListRezon,
    //     'reason', reason
    // )
    
    
    const refundItem = async (item_id, reason, comment, setLoadProcess) => {
        setLoadProcess(true);

        const images ={
            item: imageListItem,
            rizon: imageListRezon,
            data: imageListDate,
        }
        refundItemApi(item_id, reason, comment, images).
        then(() => {
            setLoadProcess(false)
            navigate("/profile");

        }).catch(err => {
            console.log('err', err)
            alert('Произошла ошибка при отмене заказа')
        })




        // changeStatus({
        //         variables: {
        //             orderItemID: item_id,
        //         }
        //     }).then(({ data }) => {
        //         insertRefund({
        //             variables: {
        //                 orderItemID: item_id,
        //                 reason: reason,
        //                 comment: comment,
        //             }
        //         }).then(({ data }) => {
        //             navigate('/profile/refunds');
        //         }
        //         ).catch((error) => {
        //             console.log('error', error)
        //         }
        //         )
        //     }
        //     ).catch((error) => {
        //         console.log('error', error)
        //     }
        //     )

        setLoadProcess(false);

        
    };

    return (
        

        <div className = {styles.container}>
            <h3 className = {styles.title}>Оформление возврата</h3>
        
        <div className = {styles.itemContainer}>
            <div className={styles.itemImage}>
                  <img src={item.item.item_media[0].path} alt="item" />
            </div>
            <div className={styles.itemInfo}>
                <div className={styles.priceContainer}>
                    <div className={styles.itemPrice}> {item.price} ₽ за {item.quantity} шт. </div>
                    <div className={styles.itemTotal}> Итого :  {item.quantity * item.price } ₽</div>
                </div>
                  <div className={styles.itemDescription}>
                    <div  className={styles.itemName}>{item.item.name}</div>
                  </div>
                  <div className={styles.itemStatusContainer}>
                      <div > {item.quantity} шт.</div>
                </div>
            </div>
            
        </div>
        <div className ={styles.reazonContainer}>
            <div className={styles.reazonTitle}>Причина отказа :</div>
            <ReasonButton reason={reason} setValue={setReason} />

            
            <textarea className = {styles.textarea} 
                        placeholder = 'Подробно опишите причину, по которой хотите отменить заказ...'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        ></textarea>
        </div>


        <div className={styles.fotoRizonContainer}>
            <div className={styles.reazonTitle}>Фото, чтобы подтвердить причину возврата:</div>
            <div className={styles.infoArea}>
            Чтобы заявку одобрили, добавьте все требуемые фото. 
            Заявка может быть отклонена, если дефект получен из-за 
            неправильного использования товара. При возврате телефона или 
            компьютера сбросьте устройство до заводоских настроек.
            </div>       
        </div>

        <Media imageListItem = {imageListItem} 
                setImageListItem = {setImageListItem}
                imageListRezon = {imageListRezon}
                setImageListRezon = {setImageListRezon}
                imageListDate = {imageListDate}
                setImageListDate = {setImageListDate}
                />


        
        <div className={styles.buttonContainer}>
            {loadProcess ? (
                <button className={styles.button}>обработка ....</button> )
                :
            
            (
            <button className={styles.button}
                   onClick={() => refundItem(item.id, reason, comment, setLoadProcess)}
                   disabled={!disabled}
                    >Оформить возврат</button>

            )}
        </div>


    </div>

    )
}

export default DeclineItem;