import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import DisplayItems from '../StandartGoodsDisplay/DisplayItems';
import classes from './LikedItems.module.css';
import 'react-tabs/style/react-tabs.css';

// import '../ProductCard/DiscribesAndProperties/DiscribesAndProperties.css';
import { useQuery } from '@apollo/client';
import { USERS_LIKES } from '../../requests/likesRequests';
import { TelegramContext } from '../../App';
import { useContext } from 'react';
import Watched from '../Watched';
import ShopsCard from '../../Сomponents/ShopsCard';
import { combineReducers } from 'redux';

export default function LikedItems(){
    
    const {userID} = useContext(TelegramContext) ;

    
    const {data, error, loading} = useQuery(USERS_LIKES,{
        fetchPolicy: 'cache-and-network',
        variables: { userID},
    });
    
    

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('liked items error', error);
        return 'error';
    }
 


    return (
        < div className = {classes.container}>
        <h3 className = {classes.header}>ИЗБРАННОЕ</h3>

        <Tabs>
            <TabList>
                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Товары</div>
                </Tab>

                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Продавцы</div>
                </Tab>
            </TabList>

            <TabPanel>

                <DisplayItems data = {data} 
                             likes = {true}
                           
                    />
            </TabPanel>
            <TabPanel>
                <div>
                    <ShopsCard shops={data.shop}/>
               
                </div>
            </TabPanel>
        </Tabs>
        <div className={classes.line}> </div> 
        <Watched/>
        

        </div>
    );
}
    
