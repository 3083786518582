import React, { useEffect, useState} from 'react'
import styles from './MainLot.module.css'
import MainLotData from './MainLotData/MainLotData';


const MainLot = ({lot, activeLot, setActiveLot  }) => {
    


    // console.log('lot', lot) 
    // console.log('activeLot', activeLot)
    const [isActive, setIsActive] = useState(false)
    
    const totalLotQuantity =lot.total

    useEffect(() => {
        setIsActive(activeLot.id === lot.id)
    }, [activeLot])

    const handleChangeActiveLot = (e) => {
        // console.log('e.target.value', e.target.value)
        setActiveLot(lot)
    }   

    const contentClassName = isActive
        ? `${styles.content} ${styles.active}`
        : styles.content

    return (
        <>
            <div  className = {contentClassName} onClick = {handleChangeActiveLot} value = {lot.id}>

                <div className = {styles.price}>{`${lot.price} ₽`}</div>
                <div className = {styles.minQuantity}>{`от ${lot.step} шт.`}</div>
                <MainLotData endDate = {lot.expire_date} isActive = {isActive} id = {lot.id}/>
                <div className = {styles.buyerСounter}>{`Прогресс: `}<span
                    style = {{fontWeight: "700"}}>{`${lot.ordered_quantity}`}/{`${totalLotQuantity}`}</span></div>

            </div>
        </>
    )
}

export default MainLot