import React, {useState} from 'react';

import styles from './GetItem.module.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { CONFIRM_ITEM } from '../../../../../requests/orderRequests';
import { confirmOrderApi } from '../../../../../utils/apiCalls/ordersApi';


function GetItem({ order,  orderItemId}){
    console.log('orderItemId', orderItemId)
    console.log('order', order)

    const navigate = useNavigate();

    const [shopEstimate, setShopEsitmate] = useState(0)
    const [deliveryEstimate, setDeliveryEsitmate] = useState(0)
    const [loadProcess, setLoadProcess] = useState(false)
    
    const [confirmItem] = useMutation(CONFIRM_ITEM);


    const item = useSelector(state => state.userOrder.item);
    console.log('item', item)

    const estimate =[5,4,3,2,1]


    function confirmItems() {

        // send request to server check all order and change status 
        // 
        setLoadProcess(true)

        confirmItem({
            variables: {
                order_item: item.id,
                // shop_estimate: shopEstimate,
                // delivery_estimate: deliveryEstimate,
            }})
            
        confirmOrderApi(item.id, shopEstimate, deliveryEstimate).then(({ data }) => {
            // console.log('data', data)
            navigate ('/profile/reviews')
        }
        ).catch((error) => {
            console.log('error', error)
        }
        )

    }




    return (
        <div className = {styles.container}>
            <h3 className = {styles.title}>Получение товара</h3>
        
        <div className = {styles.itemContainer}>
            <div className={styles.itemImage}>
                  <img src={item.item.item_media[0].path} alt="item" />
            </div>
            <div className={styles.itemInfo}>
                <div className={styles.priceContainer}>
                    <div className={styles.itemPrice}> {item.price} ₽ за {item.quantity} шт. </div>
                    <div className={styles.itemTotal}> Итого :  {item.quantity * item.price } ₽</div>
                </div>
                  <div className={styles.itemDescription}>
                    <div  className={styles.itemName}>{item.item.name}</div>
                  </div>
                  <div className={styles.itemStatusContainer}>
                      <div > {item.quantity} шт.</div>
                </div>
            </div>
            
        </div>
        <div className ={styles.deliveryContainer}>
           {item.order_deliveries[0].company }
        </div>

        <div className={styles.estimateContainer}>
             <div className={styles.estimateTitle}>Оцените работу продавца</div>
                <div className={styles.estimateButtonContainer}>

                {estimate.map((est, index) => (
                    <span 
                        className={`${styles.estimateButton} ${est === deliveryEstimate ? styles.active : ''}`} 
                        key={index} 
                        onClick={() => setDeliveryEsitmate(est)}
                    >
                        {est}
                    </span>
                ))}

                </div>
            

            <div className={styles.estimateTitle}>Оцените работу транспортной компании:</div>
                <div className={styles.estimateButtonContainer}>
                {estimate.map((est, index) => (
                    <span 
                        className={`${styles.estimateButton} ${est === shopEstimate ? styles.active : ''}`} 
                        key={index} 
                        onClick={() => setShopEsitmate(est)}
                    >
                        {est}
                    </span>
                ))}

            </div>
            <button className={styles.button} 
                onClick={() => confirmItems()}
                    >
                Подтвердить получение товаров
            </button>
        </div>



      </div>
    )
}

export default GetItem;