import { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES, 
        GET_SUBCATEGORIES,
        GET_PRODUCT_CATEGORIES,
     } from "../../../../requests/categoryRequests";
import link from '../../../../assets/svg/link.svg';
import point from '../../../../assets/svg/point.svg';
import backArrow from '../../../../assets/png/backArrow.png';

// import info from '../../../../assets/svg/info.svg';
import pointActive from '../../../../assets/svg/pointActive.svg';
import styles from './CreateItem.module.css';
import InfoItem from "./InfoItem";
import ItemMedia from "./ItemMedia";
import Variants from "./Variants";

import { brandList } from "../../../../utils/brandList";
import { countryList } from "../../../../utils/countryList";
import { colorList } from "../../../../utils/colorList";

import {useDispatch, useSelector} from "react-redux";
import {setCategory, setShopId } from "../../../../features/createItemSlice";
// import {fetchUserBonus} from "../../../../features/bonusSlice";



export const ADD_ITEM_MODES = {
    category: "CATEGORY", 
    subcategory: "SUBCATEGORY", 
    productCategory: "PRODUCTCATEGORY", 
    itemInfo: "ITEMINFO",
    brand : "BRAND",
    country : "COUNTRY",
    color :  "COLOR",   
    media : "MEDIA",
    variants : "VARIANTS",
}



function ProductCategory({selectedProductCategory,selectedCategory, subCategoryName, setDisplayMode, setSelectedProductCategory}) {
    
    const dispatch = useDispatch();
  
    const [searchTerm, setSearchTerm] = useState('');

    const {data, error, loading} = useQuery(GET_PRODUCT_CATEGORIES, {
        fetchPolicy: 'cache-and-network',
        variables: {subcategory: subCategoryName}
    });

    if (loading) return 'loading...';
    if (error) {
        console.error('get categories error', error);
        return 'error';
    }

    const { category } = data; // Simplified destructuring

    function productCategoryHandler(category) {
        setSelectedProductCategory(category);
        dispatch(setCategory(category.id));
    }
    const filteredCategories = category.filter((cat) => 
    cat.product_category.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return (
        <div>
        <h1 className={styles.header}>Категория</h1>
            <div className={styles.stepContainer}>
            <img className={styles.backArrow} src={backArrow} alt="back" onClick={() => setDisplayMode(ADD_ITEM_MODES.subcategory)} />
            <span className={styles.step} >Шаг 1/4</span>
            </div>
        <input  className={styles.search} 
                    type="text" 
                    placeholder="Поиск по категории и по типу"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />
            <span className={styles.allSubCategory} onClick={() => setDisplayMode(ADD_ITEM_MODES.subcategory)}>{selectedCategory}</span>
            <div className={styles.categoryName}>
                <span>{subCategoryName}</span>
                

            </div>
            {filteredCategories.map((category) => 
                <div className={styles.categoryContainer} onClick={() => productCategoryHandler(category)} key={category.id}>
                    <span>{category.product_category}</span>
                    <div className="point">
                        {selectedProductCategory?.product_category === category.product_category
                            ? <img src={pointActive} alt="link"/> 
                            : <img src={point} alt="choose"/>
                        }
                    </div>
                </div>
            )}

            <button className={styles.addCategory} disabled={!selectedProductCategory} onClick={() => setDisplayMode(ADD_ITEM_MODES.itemInfo)}>
                Далее
            </button>
        </div>
    );
}



function SubCategory({ setDisplayMode , categoryName, setSelectedSubCategory }){
    const [searchTerm, setSearchTerm] = useState('');

    function subcategoryHandler(category){
        setSelectedSubCategory(category.subcategory)
        setDisplayMode(ADD_ITEM_MODES.productCategory)
    }
     // take category from redux 


    const {data, error, loading} = useQuery(GET_SUBCATEGORIES,{
        fetchPolicy: 'cache-and-network',
        variables : {category : categoryName}
    })
    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('get categories error', error);
        return 'error';
    }
    const {category} = {category: data.category,}
    // console.log('category', category)

    const filteredCategories = category.filter((cat) => 
    cat.subcategory.toLowerCase().includes(searchTerm.toLowerCase())
      );



    return (
        <div>
        <h1 className={styles.header}>Категория</h1>
        <div className={styles.stepContainer}>
            <img className={styles.backArrow} src={backArrow} alt="back" onClick={() => setDisplayMode(ADD_ITEM_MODES.category)} />
            <span className={styles.step} >Шаг 1/4</span>
          </div>
        <input  className={styles.search} 
                    type="text" 
                    placeholder="Поиск по категории и по типу"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm( e.target.value)}
                    />
            <span className={styles.allSubCategory} onClick={() => setDisplayMode(ADD_ITEM_MODES.category)} >Все категории</span>
            

        <div className={styles.categoryName}>
            <span>{categoryName}</span>

        </div>
        {filteredCategories.map((category) => 
        <div className={styles.categoryContainer}  onClick = {() =>subcategoryHandler(category)} key={category.id}>
            <span>{category.subcategory}</span>
            <div className="link" >
            <img src={link} alt="link"/>
            </div>
        </div>
    )}
    </div>
    )


}




  function Category({setDisplayMode, setSelectedCategory} ){
      
      const [searchTerm, setSearchTerm] = useState('');




    function categoryHandler(category){
        setSelectedCategory(category.category)
        setDisplayMode(ADD_ITEM_MODES.subcategory)
    }




    const {data, error, loading} = useQuery(GET_CATEGORIES,{
        fetchPolicy: 'cache-and-network',
    }); 
    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('get categories error', error);
        return 'error';
    }
    const {category} = {category: data.category,}
    // console.log('category', category)

    const filteredCategories = category.filter((cat) => 
      cat.category.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div>
            <h1 className={styles.header}>Категория</h1>
            <div className={styles.stepContainer}>
              <span className={styles.step} >Шаг 1/4</span>
            </div>

            <input  className={styles.search} 
                    type="text" 
                    placeholder="Поиск по категории и по типу"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />

            <span className={styles.allCategory} >Все категории</span>
            

        {filteredCategories.map((category) => 
        <div className={styles.categoryContainer}  onClick = {() =>categoryHandler(category)} key={category.id}>
            <span>{category.category}</span>
            <div className="link" >
            <img src={link} alt="link"/>
            </div>
        </div>
    )}
    </div>
    )
}





export function SelectionList({
    title,
    setDisplayMode,
    setSelectedValue,
    selectedValue,
    list,
    placeholder,
    setFormData,
  }) {
    
    const [searchTerm, setSearchTerm] = useState('');
  
    const windowName = { 'Бренд': 'brand', 'Страна': 'country', 'Цвет': 'color' }[title];
   


    function itemHandler(event, item) {
      // console.log('name', name);
      setSelectedValue(item);
      setFormData((prev) => ({ ...prev, [windowName]: item })); 
    }

    const filteredItems = list.filter((item) =>
      item.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log('name', windowName)
  
    return (
      <div>
        <div className={styles.stepContainer}>
            <img className={styles.backArrow} src={backArrow} alt="back" onClick={() => setDisplayMode(ADD_ITEM_MODES.itemInfo)} />
        
            <h1 className={styles.header}>{title}</h1>
        </div>
        <span
          className={styles.cthrow}
          onClick={() => {
            setDisplayMode(ADD_ITEM_MODES.itemInfo);
            setSelectedValue('');
            setFormData((prev) => ({ ...prev, [windowName]: '' }));
          }}
        >
          Сбросить
        </span>
        <input
          className={styles.search}
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className={styles.brandList}>
          {filteredItems.map((item, index) => (
            <div
              className={styles.categoryContainer}
              onClick={(e) => itemHandler(e, item)}
              key={index}
            >
              <span>{item}</span>
                {/* {title === 'Цвет' ? ( 
              <div
                className={selectedValue === item ? styles.colorActive : styles.color}
                style={{ backgroundColor: item }}
              />
            ) : null}
            */}
              <div className="point">
                {selectedValue === item ? (
                  <img src={pointActive} alt="link" />
                ) : (
                  <img src={point} alt="choose" />
                )}
              </div>
            </div>
          ))}
        </div>
  
        <button
          className={styles.addButton}
          disabled={!selectedValue}
          onClick={(event) => {
            event.preventDefault();

            setDisplayMode(ADD_ITEM_MODES.itemInfo)}}
        >
          Далее
        </button>
      </div>
    );
  }
  



function CreateItem(){


    const location = useLocation();
    const dispatch = useDispatch();

    const [displayMode, setDisplayMode] = useState(ADD_ITEM_MODES.category)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] =  useState(null)
    const [selectedProductCategory, setSelectedProductCategory] =  useState(null)

    const [selectedBrand, setSelectedBrand] =  useState(null)
    const [selectedCountry, setSelectedCountry] =  useState(null)
    const [selectedColor, setSelectedColor] =  useState(null)
  
    const [boxButton, setBoxButton] = useState(null);
    


    const [formData, setFormData] = useState({
            
            brand : selectedBrand || '',
            name : '',
            articleNumber : '',
            partNumber : '',
            barcode : '',
            description : '',
            characteristics : '',
            country : selectedCountry || '',
            color : selectedColor || '',
            quantity_box : '',
            waranty : '',
            weight : '',
            height : '',
            width : '',
            length : '',
            character : [{characterName : '', characterValue : ''}],
    });

    useEffect(() => {
        const shopId = location.state.shopId;
        dispatch(setShopId(shopId));

    }, [])  


    
    let content;
    switch (displayMode) {
      case ADD_ITEM_MODES.category:
        content = <Category setSelectedCategory={setSelectedCategory} setDisplayMode={setDisplayMode} />;
        break;
      case ADD_ITEM_MODES.subcategory:
        content = <SubCategory categoryName={selectedCategory} setDisplayMode={setDisplayMode} setSelectedSubCategory={setSelectedSubCategory} />;
        break;
      case ADD_ITEM_MODES.productCategory:
        content = <ProductCategory 
                            selectedProductCategory={selectedProductCategory}
                            selectedCategory={selectedCategory} 
                            subCategoryName={selectedSubCategory} 
                            setDisplayMode={setDisplayMode} 
                            setSelectedProductCategory={setSelectedProductCategory} />;
        break;
      case ADD_ITEM_MODES.itemInfo:
            content = <InfoItem
                            formData={formData}
                            setFormData={setFormData}

                            setSelectedCountry={setSelectedCountry}
                            countryList={countryList}

                            setDisplayMode={setDisplayMode}
                            boxButton={boxButton}
                            setBoxButton={setBoxButton}
                             />;
            break;
      case ADD_ITEM_MODES.brand:
            content = <SelectionList
            title="Бренд"
            setDisplayMode={setDisplayMode}
            
            setSelectedValue={setSelectedBrand}
            selectedValue={selectedBrand}
            list={brandList}
            placeholder="Введите бренд"
            setFormData={setFormData}
          />
            break;
        
        case ADD_ITEM_MODES.country:
            content = <SelectionList
            title="Страна"
            setDisplayMode={setDisplayMode}
            setSelectedValue={setSelectedCountry}
            selectedValue={selectedCountry}
            list={countryList}
            placeholder="Введите страну"
            setFormData={setFormData}

          />

            break;
        case ADD_ITEM_MODES.color:
            content = <SelectionList
            title="Цвет"
            setDisplayMode={setDisplayMode}
            setSelectedValue={setSelectedColor}
            selectedValue={selectedColor}
            list={colorList}
            placeholder="Введите цвет"
            setFormData={setFormData}

          />

            break;

        case ADD_ITEM_MODES.media:
            content = <ItemMedia 
                setDisplayMode={setDisplayMode}
                ADD_ITEM_MODES={ADD_ITEM_MODES}
            />
            break;

        case ADD_ITEM_MODES.variants:
            content = <Variants 
                setDisplayMode={setDisplayMode}
                ADD_ITEM_MODES={ADD_ITEM_MODES}
            />
            break;

      default:
        content = <div>Invalid mode</div>; 
        break;
    }


    return(
        <div className= {styles.wrapper}>
            

                {content}     

        </div>
    )
    
}

export default CreateItem;