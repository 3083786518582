import React from 'react';
import classes from './category.module.css';

import {Swiper, SwiperSlide} from 'swiper/react';

// Import Slider styles
import 'swiper/css';

export default function SubCategorySwiper({data, setSubCategory}) {
    const [activeSubCategoryIndex, setSubCategoryIndex] = React.useState('');

    const subCategoryOnClick = (category, index) => {
        setSubCategory(category.subcategory);
        setSubCategoryIndex(index);
    };

    let className = 'SubCategorySwiperSlide';

    const display_sub_category =
        data &&
        data.data.category.map((category, index) => {
            return (
                <SwiperSlide
                    className = {
                        activeSubCategoryIndex === index
                            ? (className += ' SubCategorySwiperSlide-active')
                            : 'SubCategorySwiperSlide'
                    }
                    key = {index}
                    onClick = {() => {
                        subCategoryOnClick(category, index);
                    }}>
                    {category.subcategory}
                </SwiperSlide>
            );
        });

    return (
        <div>
            <Swiper
                loop = "true"
                loopedSlides = {60}
                slidesPerView = {'auto'}
                spaceBetween = {10}
                className = "SubCategorySwiper">
                {display_sub_category}
            </Swiper>
        </div>
    );
}
