import React, {useEffect, useState} from 'react';
import styles from "./RegisteredPhone.module.css"
import ProfileInput from '../../components/ProfileInput/ProfileInput';
import resend from "../../../../../assets/svg/resend.svg"

import RemoveChar from '../../../../../utils/RemoveChar';
import axios from 'axios';

import {sendCode} from '../../../../../utils/apiCalls/authApi';


function RegisteredPhone({phone, phoneCode, setPhoneCode, phoneInputcallback, isVerified, setIsVerified, phoneError}) {


    const [disabledApply, setDisabledApply] = useState(true);
    const [disabledCheck, setDisabledCheck] = useState(true);
    const [disabledTimer, setDisabledTimer] = useState(true);
    const [mode, setMode] = useState('input')//input
    const [seconds, setSeconds] = useState(0);
    const [codeValue, setCodeValue] = useState('')

    //const code = '111111'
    const maxTimerLong = 120

    useEffect(() => {
        setDisabledTimer(true)
    }, [mode])

    useEffect(() => {

        phone !== "+" && phone !== "" && !phoneError ? setDisabledApply(false) : setDisabledApply(true)
        setCodeValue('')
    }, [mode, phone])

    // useEffect(() => {
    //     phone !== "" && !phoneError ? setDisabledApply(false) : setDisabledApply(true)
    // }, [phone])

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 1) {
                setSeconds(seconds - 1);
            }
            if (seconds === 1) {
                setDisabledTimer(false)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);

    useEffect(() => {
        codeValue.length === 5 ? setDisabledCheck(false) : setDisabledCheck(true)
    }, [codeValue])

    const toVerifyMode = () => {
        setMode("verify")
        setSeconds(maxTimerLong)
        setDisabledApply(true)

        //get only numbers from phone
        const apiPhone = RemoveChar(phone)

        //get verification code

        // console.log('apiPhone: SEND TO TEST API', apiPhone)

        // const resp = sendCode(apiPhone)
        // if (resp.data){
        //     console.log('ответ ', resp.data)
        //     setPhoneCode(resp.data);
        // }

        const apiUrl = `https://api.jbspace.xyz/send_sms/${apiPhone}`;
        // const apiUrl = `http://127.0.0.1:8002/send_sms/${apiPhone}`;
        axios.get(apiUrl).then((resp) => {
            // console.log('ответ получен')
            setPhoneCode(resp.data);
            // console.log(resp.data)   
        }, (error) => {
            console.log(error);
        });

    }


    const applyButtonCallback = !isVerified ? toVerifyMode : () => {


    }

    const codeValidation = (value, validationCallback) => {
        const regex = /^[0-9]{0,6}$/;
        if (regex.test(value)) {
            validationCallback(value)
        }
    }

    const checkCodeInputhandler = (value) => {
        codeValidation(value, setCodeValue)
    }

    const checkButtonOnClickhandler = () => {
        setIsVerified(true)
        setMode("input")
    }

    const checkButtonClass = !disabledCheck && codeValue === phoneCode
        ? `${styles.checkButton} ${styles.success}`
        : `${styles.checkButton} ${styles.fail}`

    const checkButtonTitle = disabledCheck || codeValue === phoneCode
        ? "Подтвердить"
        : "Неверно"

    const applyButtonClass = !isVerified ? styles.applyButton : `${styles.applyButton} ${styles.confirm}`
    const applyButtonTitle = !isVerified ? "Подтвердить" : "Подтвержден"

    const timerButtonTitle = disabledTimer ? seconds : <img className = {styles.resend} src = {resend}/>

    const onClickTimerButtonHandler = () => {
        //send new sms
        //get only numbers from phone
        const apiPhone = RemoveChar(phone)

        //get verification code
        const apiUrl = `https://api.jbspace.xyz/send_sms/${apiPhone}`;
        axios.get(apiUrl).then((resp) => {
            console.log('ответ получен')
            setPhoneCode(resp.data);
            console.log(resp.data)
        }, (error) => {
            console.log(error);
        });

        setSeconds(maxTimerLong)
        setDisabledTimer(true)
    }

    return (<>
            {mode === "input" && < div className = {styles.wrapper}>
                <ProfileInput label = {"Телефон:"} type = {"tel"} value = {phone}
                              callback = {phoneInputcallback} placeholder = {'Введите номер +7...'}
                />
                <button className = {applyButtonClass} disabled = {disabledApply}
                        onClick = {applyButtonCallback}>{applyButtonTitle}</button>
            </div>}
            {mode === "verify" && < div className = {styles.verifyWrapper}>
                <p className = {styles.message}>{`Код придет на ${phone} течении 60 сек:`}</p>
                <div className = {styles.verifyContent}>
                    <p className = {styles.text}>Введите код:</p>
                    <ProfileInput type = {"text"} placeholder = {"Код"} value = {codeValue}
                                  callback = {checkCodeInputhandler}/>
                    <button className = {checkButtonClass} disabled = {disabledCheck}
                            onClick = {checkButtonOnClickhandler}>{checkButtonTitle}</button>

                    <button className = {styles.timerButton} disabled = {disabledTimer}
                            onClick = {onClickTimerButtonHandler}
                    >
                        {timerButtonTitle}
                        {/*{seconds}*/}
                    </button>
                </div>

            </div>}
        </>

    );
}

export default RegisteredPhone;

