import classes from './main.module.css';
import Podborki from './Podborki/podborki';
import CategorySlyder from './Category_slyder';

// import LoadGoods from '../GoodsMain/LoadGoods';
import TopSlider from './Top_slider';
import {useEffect} from 'react';
import MainItems from '../StandartGoodsDisplay/MainItems';


// import AddTodo from '../Test_case';


function Main() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className = {classes.wrapper}>


                {/* <AddTodo/> */}
                {/*{window.Telegram.WebApp.platform}*/}
                {/*{{useEffect, useState} window.navigator.userAgent.toLowerCase()}*/}
                {/*--------------------------------*/}
                {/*{window.navigator.platform.toLowerCase()}*/}
                {/*----------------------------------------*/}
                {/*{console.log(!!window.MSStream)}*/}

                <TopSlider/>
                <Podborki/>
            </div>
            <CategorySlyder/>
            <div className = {classes.wrapperLoadGoods}>
                {/* <LoadGoods/> */}
                {/*<LoadItems/>*/}
                <MainItems limit = {10}/>
            </div>
        </>
    );
}

export default Main;
