export default function SortCities(arrObj) {
  return arrObj.sort(function (a, b) {
    if (a.city > b.city) {
      return 1;
    }
    if (a.city < b.city) {
      return -1;
    }
  });
}
