import { useQuery } from '@apollo/client';
import { USER_PROFILE } from '../../../requests/userRequests';


function  countBonus(userBonus) {

    const currentScore = userBonus.reduce((accumulator, event) => {
        if (event.event_type === 'add') {
          return accumulator + event.score;
        } else {
          return accumulator - event.score;
        }
      }, 0);
    return currentScore;
}



export const useFetchUserProfile = (userID) => {

    // console.log('useFetchUserProfile --', userID);

    const { loading, error, data } = useQuery(USER_PROFILE, {
        fetchPolicy: 'cache-and-network',
        variables: { userID },
    });

    let profileData = null;
    let errorMessage = null;

    if (loading) {
        return { loading, profileData, errorMessage };
    }

    if (error) {
        errorMessage = 'Error while fetching user data.';
        console.error('userError --', error);
        return { loading: false, profileData, errorMessage };
    }

    if (data) {
        // console.log('user data --', data);
        profileData = {
            userProfile: data.user_profile[0],
            userShop: data.shop,
            company : data.company,
            cityName: data.user[0]?.delivery_city?.city ,
            
            cityID: data.user[0]?.delivery_city?.id, 
            likes : data.users_likes_aggregate.aggregate.count + data.shops_likes_aggregate.aggregate.count,

            comments: data.comment_item_aggregate.aggregate.count,
            bonus : countBonus(data.user_bonus),
        };
    }

    return { loading: false, profileData, errorMessage };
};