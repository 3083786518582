import React, {useEffect, useState} from "react";
import DeleteLike from "./DeleteLikes";
import {useMutation} from "@apollo/client";
import {GET_ITEMS} from "../../requests/itemRequests";
import likesImage from "../../assets/svg/likes.svg";
import classes from "./Likes.module.css";
import {likesAmount} from "../LikedItems/utils/AmountOfLikes";
import {ADD_LIKE} from "../../requests/likesRequests";
import {useContext} from "react";
import {TelegramContext} from "../../App";


export default function Like({ItemID, doWeHaveLike}) {
    // const userID = localStorage.getItem("userID") || "534677865";
    const [add, setAdd] = useState(doWeHaveLike);
    const {userID} = useContext(TelegramContext) 


    const [datachange] = useMutation(ADD_LIKE, {
        
        variables: {ItemID,  userID},
        // refetchQueries: [
            // DocumentNode object parsed with gql
            // {query: GET_ITEMS},
        // ],
        
    });

    const show_like = add ? (
        <DeleteLike ItemID = {ItemID} setAdd = {setAdd}/>
    ) : (
        <img
            alt = ""
            src = {likesImage}
            className = {classes.Likes}
            onClick = {() => {
                if (!add && userID!==0) {
                    datachange();
                }
                setAdd((a) => !a);

                likesAmount(likesAmount() + 1);
            }}
        ></img>
    );

    return <div className = {classes.LikesContainer}>{show_like}</div>;
}
