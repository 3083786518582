import Home from './Home/Home';
import Catalog from './Catalog/Catalog';
import Backet from './Backet/Backet';
import Profile from './Profile/Profile';
import classes from './Footer.module.css';
import React, {useMemo} from 'react';
// import {SearchContext} from '../../App';
import {useLocation} from 'react-router-dom';
// import goBack from '../../assets/svg/goBack.svg';
import Dashbord from './Dashbord/Dashbord';
import ToMarket from './ToMarket/ToMarket';
import ShopBacket from './ShopBacket/ShopBacket';
import Chats from './Chats/Chats';
import Menu from './Menu/Menu';

const Footer = () => {
    // console.log('Footer rerender')
    // const {searchValue, setSearchValue} = React.useContext(SearchContext);

    // useEffect(() => {
    //     console.log("footer rerender")
    // }, [searchValue])

    const location = useLocation();
    // console.log('pathname in footedr', location.pathname)

    const path = location.pathname
    
    return (<>

            {
                path !== '/profile/seller/shop/setting'
                    ? <footer className = {classes.wrapper}>
                        <div className = {classes.footer}>
                            <Home/>
                            <Catalog/>
                            <Backet/>
                            <Profile/>
                        </div>
                    </footer>
                    : <footer className = {classes.wrapper}>
                        <div className = {classes.footer}>
                            <ToMarket/>
                            <Dashbord/>
                            <ShopBacket/>
                            <Chats/>
                            <Menu/>


                        </div>
                    </footer>
            }
        </>
    )
        ;
};

export default React.memo(Footer);
