import React, {memo} from 'react';
import {useLocation} from 'react-router-dom';
import pagesPaths from '../../routes/paths';
import HeaderMinimize from './HeaderMinimize'
import HeaderMain from './HeaderMain';
import HeaderProfile from './HeaderProfile';


const Header = () => {
    // console.log("HEADER")
    const location = useLocation();
    const path = location.pathname;

    const productPathRegex = /^\/product\/\d+$/;
    const shopPathRegex = /^\/shop\/\d+$/;
    const shopSettingsPathRegex = /^\/shop_settings\/\d+$/; 

    const productReviewPathRegex = new RegExp("^/product/\\d+/reviews$");
    // console.log("path", path)

    switch (path) {
        case pagesPaths.main :
            return <HeaderMain/>

        case pagesPaths.cart :
            return <HeaderMinimize/>

        case pagesPaths.payment :
            return <HeaderMinimize/>

        case pagesPaths.category :
            return <HeaderMinimize/>

        case pagesPaths.profileMain :
            return <HeaderProfile/>

        case pagesPaths.myReviews :
            return <HeaderProfile/>

        case pagesPaths.profileSetting :
            return <div></div>

        case pagesPaths.userRegistration :
            return <HeaderMinimize/>

        case pagesPaths.myShops :
            return <HeaderProfile/>

        case pagesPaths.likes:
            return <HeaderMinimize/>

        case pagesPaths.shop:
            return <HeaderMinimize/>

        case pagesPaths.addresses:
            return <HeaderMinimize/>

        case pagesPaths.bonus:
            return <HeaderMinimize/>

        case pagesPaths.company_registration:
            return <HeaderProfile/>

        case pagesPaths.createShop:
            return <HeaderProfile/>

        case pagesPaths.shopSettings:
            return <HeaderMinimize/>
        
        case pagesPaths.goodsStorage:
            return <HeaderProfile/>
        
        case pagesPaths.createItem:
            return <HeaderProfile/>

        case pagesPaths.storageItems:
            return <HeaderProfile/>

        case pagesPaths.goodsSettings:
            return

        case pagesPaths.shopLots:
            return <HeaderProfile/> 
        
        case pagesPaths.createLots:
            return <HeaderProfile/>
        
        case pagesPaths.changeStorageItems:
            return <HeaderProfile/>

        case pagesPaths.settingsLots:
            return <HeaderProfile/>

        
        

        default:
            if (productPathRegex.test(path) || productReviewPathRegex.test(path) 
                
                ) {
                return <HeaderMinimize/>
            } else if (
                 shopSettingsPathRegex.test(path)) {
                    return <HeaderProfile/>
                  
            }else {
                return <HeaderMinimize/> 
            }

    }

};

export default memo(Header);
