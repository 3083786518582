import styles from './DeleteReview.module.css';
import ColoredButton from '../../ProfileMain/components/ColoredButton/ColoredButton';
import React, {useContext} from 'react';
import {useMutation} from '@apollo/client';
import {DELETE_REVIEW} from '../../../../requests/commentUserRequests';
import {ModalContext} from '../../../../App';
import {MODE} from '../index';


const DeleteReview = ({reviewID, refetch, setDisplayMode, images}) => {
    const {setModalActive, setModalContent} = useContext(ModalContext);

    const [deleteReview] = useMutation(DELETE_REVIEW, {variables: {id: reviewID}})

    const asyncDeleteReview = async (images) => {
        //api для удаления массива фото
        const url = `https://api.jbspace.xyz/files/delete_comment_photo/${images}`
        try {
            const response = await fetch(url, {
                method: 'DELETE',
            });
            if (response.ok) {
                const {data, error} = await deleteReview()
                if (data) {
                    refetch()
                    setModalContent('');
                    setModalActive(false);
                }
                if (error) setDisplayMode(MODE.default)
            } else {
                new Error(`Upload failed with status ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className = {styles.container}>
            <h4 className = {styles.deleteTitle}>Вы точно хотите удалить отзыв?</h4>
            <ColoredButton name = {"Да"} callback = {asyncDeleteReview}/>
        </div>
    )

}

export default DeleteReview