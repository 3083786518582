import { createSlice } from '@reduxjs/toolkit'


const createItemSlice= createSlice({
    name : 'createItem',
    initialState : {
        shopId : null,
        category : '',
        itemInfo : {},
        itemMedia : {},
        variants : [],
    },
    reducers: {
        setShopId: (state, action) => {
            state.shopId = action.payload;
        },

        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setItemInfo: (state, action) => {
            state.itemInfo = action.payload;
        },
        setItemMedia: (state, action) => {
            state.itemMedia = action.payload;
        },
        setVariants: (state, action) => {
            state.variants = action.payload;
        },
        
    }
})

export const { setCategory, setShopId, setItemInfo, setItemMedia, setVariants } = createItemSlice.actions;

export default createItemSlice.reducer