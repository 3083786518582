import axios from 'axios';


export const updateLotApi = async (data) => {
    console.log('data', data)
    try {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/lots/update`, {
            
              ...data
            
          }, {
            headers: {
              "content-type": "application/json",
              // "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
            }
            
          });
          return res;
        } catch (error) {
          await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message: `error while update lots ${error.message}`, }),
          });
          alert("Произошла ошибка при обновлении лота.");
        }
      }