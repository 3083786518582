export const  companySellerRules = `
ДОГОВОР КУПЛИ-ПРОДАЖИ (для юридических лиц)
Данный Договор является публичным предложением ПСП (Офертой) любому Покупателю к заключению Договора на указанных ниже условиях.
Договор считается заключенным с момента совершения Покупателем действий, предусмотренных разделом 2 Договора, означающих полное и безоговорочное принятие им всех условий Договора.
1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.
Договор — настоящий договор, заключаемый между Продавцом и Покупателем в порядке, предусмотренном разделом 2 Договора, опубликованный в сети Интернет по адресу https://sovmestno.sale/
а также все приложения к нему, являющиеся его неотъемлемой частью.
Покупатель — индивидуальный предприниматель или юридическое лицо, зарегистрированное в установленном законом порядке на территории РФ и размещающее заказы на Сайте для нужд, не связанных с осуществлением предпринимательской деятельности, а также в целях дальнейшей перепродажи.
ПСП - ООО «Мидори» (ОГРН 1217000003770; ИНН 7017484000; 	634026, Томская Область, г.о. Город Томск, г Томск, ул Ленская, д. 31, помещ. Ц037).
Продавец — индивидуальный предприниматель, юридическое лицо или плательщик налога на профессиональный доход, осуществляющий деятельность по продаже товаров через Сервис Покупателю с использованием дистанционного способа и прошедшее регистрацию на Сервисе.
Получатель – лицо, уполномоченное Покупателем на получение Заказа.
Сервис ПСП (Сервис) — веб-сайт по адресу https://sovmestno.sale/
, интегрированный с программами для ЭВМ и Базами данных ПСП, а также мобильное приложение, разработанное Разработчиком, доменное имя которого зарегистрировано за Разработчиком, и которое используется ПСП для исполнения обязательств по настоящему Договору. Также представляет собой совокупность объектов интеллектуальной собственности, а именно: программное обеспечение, базу данных, дизайн (графическое оформление), а также информацию, размещенную Продавцом и/или Разработчиком. Доменное имя, наименование Сайта может быть изменено по усмотрению Разработчика.
Товар — материальный объект, являющийся предметом договора купли-продажи.
В Договоре могут быть использованы термины, не определенные в настоящем разделе Договора. В этом случае толкование такого термина производится в соответствии с текстом Договора. В случае отсутствия однозначного толкования термина в тексте Договора следует руководствоваться толкованием термина, определенным: в первую очередь – законодательством РФ, во вторую очередь – сложившимся (общеупотребимым) в сети Интернет.
2. ПОРЯДОК АКЦЕПТА ОФЕРТЫ
    1. Покупатель обязан ознакомиться с текстом настоящей Оферты, опубликованной по адресу https://sovmestno.sale/
  до регистрации на Сервисе и получения доступа к Личному кабинету. 
  Для возможности использования Сервиса Покупатель обязан пройти регистрацию для доступа к Личному кабинету на странице https://sovmestno.sale/
    2. Регистрация Покупателя на Сервисе ПСП является подтверждением того, что Покупатель принял условия Оферты в полном объеме.
    3. Оферта считается принятой Покупателем, в случае если Покупатель продолжает пользоваться услугами Сервиса, в том числе в части оформления заказов, а также после опубликования на Сервисе изменений/дополнений в Оферте.
3. ОФОРМЛЕНИЕ ЗАКАЗА
3.1. С помощью Сервиса Покупатель может осуществлять поиск информации о Товарах, и самостоятельно оформить заказ на приобретение необходимого Товара.
3.2. На странице Товара указывается информация о возможности возмещения Покупателем НДС при покупке Товара:
    • Товар с НДС. Означает, что Продавец находится на общей системе налогообложения (ОСНО), участвует в документообороте с ПСП. В таком случае ПСП направляет Покупателю УПД-1 от имени Продавца, НДС принимается Покупателем к вычету.
    • НДС не возмещается. Означает, что Продавец находится на общей системе налогообложения (ОСНО), но не участвует в документообороте с ПСП. В таком случае ПСП направляет Покупателю УПД-2 от имени Продавца, НДС не принимается Покупателем к вычету.
    • Товар без НДС. Означает, что Продавец применяет упрощённую систему налогообложения, В таком случае ПСП направляет Покупателю УПД-2 от имени Продавца.
3.3. Информационные материалы о товарах и услугах носят справочный характер и не могут в полной мере передавать достоверную информацию о свойствах и характеристиках товара, включая цвета, размеры и формы.
3.4. Покупателю доступны следующие способы получения Товара:
    • самостоятельное получение в Пункте выдачи заказов;
    • курьерская доставка по указанному адресу при наличии возможности со стороны ПСП.
3.5. Заказ на товар может аннулировать Продавец и ПСП. Покупатель не вправе аннулировать заказ после произведения оплаты Товара.
3.6. Продавец или ПСП имеют право аннулировать Заказ с уведомлением Покупателя в следующих случаях:
    • отсутствия Товара в наличии;
    • если Покупателя не устроило время/срок доставки Заказа;
    • уклонение Покупателя от получения Заказа (Товара);
    • истёк срок хранения заказа на Пункте выдачи заказа;
    • возникновение иных обстоятельств, препятствующих передаче Товара согласованным способом.
3.7. В случае аннуляции заказа Продавцом или ПСП полностью или частично, стоимость аннулированного товара отражается на балансе средств Покупателя в Личном кабинете. По запросу Покупателя указанные денежные средства могут быть переведены на расчётный счёт Покупателем посредством заполнения соответствующей формы на Сервисе.
3.8. Покупатель вправе согласовать новые условия доставки Товара путем оформления нового заказа, если на момент оформления заказа Товар есть в продаже.
4. ПОРЯДОК РАСЧЕТОВ
4.1. Оплата заказа осуществляется путем 100% предоплаты.
4.2. Покупатель оплачивает Заказ с использованием внесенного аванса в пределах лимита, отражённого на балансе средств Покупателя в Личном кабинете.
4.3. Заказ принимается в обработку только после зачисления денежных средств Покупателя на расчётный счёт ПСП.
4.4. Возврат денежных средств с баланса средств Покупателя в Личном кабинете на расчетный счет Покупателя осуществляется путем заполнения формы заявки на возврат денежных средств в соответствующем разделе Личного кабинета.
5. ДОСТАВКА
5.1. Территория доставки Товаров ограничена территорией размещения Пунктов выдачи заказов транспортных компаний.
5.2. Доставка Товара Покупателю осуществляется по адресу и в сроки, согласованные при оформлении Заказа, либо Покупатель самостоятельно забирает Товар с Пункта выдачи заказов.
5.3. Точная стоимость доставки Товара определяется при оформлении заказа и не может быть изменена после согласования Покупателем.
5.4. Заказ хранится в Пункте выдачи заказов в течение 5 дней с момента его поступления на Пункт выдачи заказов. Неявка Покупателя или не совершение иных необходимых действий для принятия Товара могут рассматриваться Продавцом в качестве отказа Покупателя от исполнения Договора. 
5.5. ПСП не несет ответственность за убытки и расходы Покупателя, связанные с нарушением сроков доставки. 
5.6. Риск случайной гибели или случайного повреждения товара переходит к Покупателю с момента передачи ему заказа.
5.7. В случае выбора Покупателем способа доставки «Пункт выдачи», выдача заказа Покупателю осуществляется посредством сообщения Получателем СМС-кода лицу, осуществляющему выдачу заказа, в следующем порядке:
5.7.1. Для получения СМС-кода Покупатель при оформлении Заказа указывает достоверный контактный номер телефона Получателя в специальной форме в Личном кабинете Покупателя на Сервисе ПСП. При этом Покупатель заверяет, что предоставление ПСП такой информации, ее содержание и последующее использование, не нарушает права третьих лиц, нормы действующего законодательства, включая законодательство о персональных данных, не обременено правами третьих лиц и не повлечет у ПСП обязательств по выплате вознаграждения третьим лицам.
5.7.2. В целях выдачи заказа Покупателю ПСП направляет на указанный в Личном кабинете Покупателя контактный номер Получателя номер заказа и СМС-код.
5.7.3. Покупатель обязуется собственными силами и за свой счет проинформировать Получателя заказа о способе получения заказа и необходимости соблюдения конфиденциальности в отношении полученного СМС-кода.
5.7.4. Стороны установили, что отправка СМС-кодов может осуществляться ПСП как самостоятельно, так и путем привлечения третьих лиц.
5.7.5. При приемке товара Получатель должен сообщить лицу, осуществляющему вручение заказа, полученный от ПСП СМС-код. Сообщение лицу, осуществляющему вручение заказа, корректного СМС-кода является безусловным основанием для ПСП считать лицо, сообщившее СМС-код, уполномоченным Покупателем на получение заказа.
В случае сообщения Получателем лицу, осуществляющему вручение заказа, некорректного СМС-кода выдача заказа не осуществляется.
5.7.6. Покупатель обязан соблюдать конфиденциальность в отношении полученных СМС-кодов, а также обеспечить соблюдение конфиденциальности Получателем.
5.8. В случае выбора Покупателем способа доставки заказа «Курьером до двери» заказ вручается лицу, указанному при оформлении Заказа в качестве Получателя Заказа. В случае изменения данных о получателе Заказа Покупатель обязан не позднее чем за 1 (один) рабочий день до даты доставки Заказа направить по адресу электронной почты info@sovmestno.sale уведомление об изменении данных о получателе Заказа.
5.9. Во избежание случаев мошенничества, а также для выполнения взятых на себя обязательств при вручении заказа лицо, осуществляющее выдачу заказа в ПВЗ или курьерскую доставку заказа, вправе затребовать документ, удостоверяющий личность  Получателя. ПСП гарантирует конфиденциальность и защиту персональной информации Получателя. В случае отсутствия указанного документа заказ не передается, при этом услуга по доставке будет считаться оказанной (в случае курьерской доставки).
5.10. Покупатель обязан принять заказ по количеству, ассортименту товарных единиц в момент получения заказа в Пункте выдачи заказов (курьерской доставкой) и проверить его качество в течение 5 (пяти) дней.
6. ОРГАНИЗАЦИЯ ДОКУМЕНТООБОРОТА
6.1. ПСП, в соответствии с законодательством Российской Федерации, оформляет от имени Продавца универсальный передаточный документ (УПД) на сумму заказа. ПСП вносит в УПД информацию о ставке НДС, исходя из данных, указанных Продавцом в Карточке товара.
6.2. После передачи Заказа Покупателю копия УПД, выставленного от имени Продавца, размещается в Личном кабинете Покупателя.
В случае, если в течение 1 (одного) дня Покупатель не заявит возражений относительно получения Заказа, Заказ считается переданным Покупателю.
6.3. Отчетные документы, размещенные в Личном кабинете после передачи заказа Покупателю, считаются согласованными и принятыми Покупателем, если Покупатель в течение 5 (пяти) дней с даты размещения таких документов в Личном кабинете не заявит своих возражений.
6.4. Предоставление оригиналов отчетных документов осуществляется посредством подключения Покупателя к системе электронного документооборота согласно Инструкции о порядке организации электронного документооборота между Покупателем и ПСП по ссылке https://sovmestno.sale/    
.
6.5. В случае если Покупатель не подключен к системе электронного документооборота в порядке, установленном пунктом 6.4 настоящего Договора, Покупатель вправе направить запрос в Службу поддержки по адресу электронной почты support@sovmestno.sale о предоставлении оригиналов отчетных документов в бумажном виде.
7. ВОЗВРАТ ТОВАРА
7.1. Возврат товара надлежащего качества
7.1.1. Возврат товара надлежащего качества после его получения не предусмотрен.
7.2. Возврат товара ненадлежащего качества
7.2.1. Покупатель вправе возвратить товар ненадлежащего качества Продавцу и потребовать возврата уплаченной денежной суммы в течение 5 (пяти) дней с момента получения заказа в случае обнаружения явных недостатков.
В случае обнаружения в товаре скрытых дефектов Покупатель вправе возвратить товар в течение гарантийного срока, предоставив Продавцу вместе с товаром заключение авторизованного сервисного центра или эксперта, подтверждающее производственный характер соответствующего недостатка, а также его существенность (в отношении технически сложных товаров).
7.2.2. При обнаружении в заказе Товара ненадлежащего качества Покупатель обязан составить претензию по качеству Товара с указанием причин, по которым Покупатель считает товар некачественным. Претензия предоставляется Покупателем в момент возврата товара ненадлежащего качества.
В случае, если Покупателем не была предоставлена претензия по качеству Товара ПСП вправе отказать Покупателю в удовлетворении претензий по качеству Товара.
7.2.3. Продавец отвечает за недостатки Товара, если Покупатель докажет, что недостатки товара возникли до его передачи Покупателю или по причинам, возникшим до этого момента.
7.2.4. В случае отказа Покупателя от Договора и предъявления требования о возврате уплаченной за товар денежной суммы, стоимость товара возвращается Покупателю на баланс средств Покупателя в Личном кабинете в течение 10 (десяти) рабочих дней с даты получения Продавцом Товара и претензии по качеству Товара.
7.2.5. Транспортные расходы по замене некачественного товара несёт Покупатель.
7.3. Нарушение условий об ассортименте
7.3.1. Покупатель обязан проверить ассортимент Товара в момент получения заказа в Пункте выдачи заказов (курьерской доставкой). В случае обнаружения в заказе товара, не соответствующего заказанному ассортименту (ошибка в описании, пересорт, в заказе другой товар, некомплектность), Покупатель вправе в день получения заказа отказаться от товара и потребовать замены на товар в ассортименте, предусмотренном заказом, либо потребовать возврата денежных средств за фактически непереданный товар.
7.3.2. Товар, переданный Покупателю в нарушение условия об ассортименте, подлежит возврату ПСП.
7.3.3. Замена товара, не соответствующего заказу по ассортименту, осуществляется путём оформления нового заказа.
7.3.4. В случае невозможности осуществить замену товара, ПСП уведомляет об этом Покупателя посредством направления сообщения на электронный адрес, указанный Покупателем. Денежные средства, фактически оплаченные за непереданный товар, возвращаются на баланс средств Покупателя в Личном кабинете.
7.4. Нарушение условий о количестве
7.4.1. Покупатель обязан проверить количество товара в момент получения заказа в Пункте выдачи заказов (курьерской доставкой).
7.4.2. Если ПСП передал Покупателю меньшее количество товара, чем определено заказом (недовложение), Покупатель вправе принять товар в части, соответствующей заказу, и потребовать передать недостающее количество товара, либо отказаться от заказа в части недостающего товара и потребовать возврата денежных средств за недостающий товар.
7.4.3. Передача недостающего товара осуществляется посредством оформления нового заказа по согласованию сторон.
7.4.4. В случае невозможности передать недостающий товар, ПСП уведомляет об этом Покупателя посредством направления сообщения на электронный адрес Покупателя.
Денежные средства возвращаются на баланс средств Покупателя в Личном кабинете.
7.4.5. Возвращаемые денежные средства отражаются на балансе средств Покупателя в Личном кабинете и могут быть использованы Покупателем для оплаты последующих заказов.
8. ПРОЧИЕ УСЛОВИЯ
8.1. Интеллектуальная собственность
8.1.1. Вся текстовая информация и графические изображения, находящиеся на Сайте, являются собственностью ПСП, Продавцов и/или их контрагентов.
8.2. Гарантии и ответственность
8.2.1. ПСП и Продавец не несут ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования товаров, заказанных на Сайте.
8.2.2. ПСП не несёт ответственности за содержание и функционирование внешних сайтов.
8.2.3. ПСП вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.
8.2.4. ПСП не несёт ответственности за убытки, которые Покупатель может понести в результате того, что его номер телефона перешёл в собственность другого лица, в том числе в результате длительного (от 3х месяцев) неиспользования такого номера телефона Покупателем.
8.3. Конфиденциальность и защита персональной информации.
8.3.1. Стороны обязуются соблюдать условия Политики конфиденциальности ПСП.
8.3.2. Информация, предоставляемая в связи с Условиями, является конфиденциальной информацией. Такая информация не может передаваться одной Стороной третьим лицам без письменного согласия другой Стороны за исключением случаев, когда передача такой информации третьим лицам необходима для исполнения Условий.
8.4. Коммуникация и иные условия
8.4.1. ПСП или Продавец направляют Покупателю уведомления и сообщения по электронному адресу, указанному Покупателем.
8.4.2. Переписка имеет юридическую силу в случае отправки в Личный кабинет, либо на электронный адрес Покупателя, указанный им при регистрации, либо в письменной форме на почтовый адрес Покупателя (при наличии такого распоряжения от Покупателя).
8.4.3. Все возникающие споры будут решаться путём переговоров, при недостижении согласия спор будет передан на рассмотрение в Арбитражный суд Томской области.
8.4.4. Признание судом недействительности какого-либо положения Условий и правил не влечёт за собой недействительность остальных положений.

` 