import styles from './ApplyButton.module.css';
import React, {useEffect, useState} from 'react';

function ApplyButton({
                         name,
                         isAddedNewReview, callback, setActiveReviewsTab, isEditedReview, disabled
                     }) {

    
    const [title, setTitle] = useState(name)
    const [backgroundColor, setBackgroundColor] = useState('#4F8CE7')

    useEffect(() => {
        let myTimeout
        if (isAddedNewReview) {
            setTitle("Отзыв отправлен")
            setBackgroundColor('#4FE770')
            myTimeout = setTimeout(() => setActiveReviewsTab(1), 1000);
        }
        if (isEditedReview) {
            setTitle("Отзыв изменен")
            setBackgroundColor('#4FE770')
            myTimeout = setTimeout(() => setActiveReviewsTab(0), 1000);
        }
        return () => isAddedNewReview || isEditedReview && clearTimeout(myTimeout);
    }, [isAddedNewReview, isEditedReview])


    useEffect(() => {
        disabled ? setBackgroundColor("rgba(189, 189, 189, 0.6)") : setBackgroundColor('#4F8CE7')
    }, [disabled])

    return (
        <button style = {{backgroundColor: `${backgroundColor}`}}
                className = {styles.simpleButton} onClick = {callback} disabled = {disabled}>
            {title}
        </button>
    );
}

export default ApplyButton;