import {NavLink} from "react-router-dom";
// import user from "../../../assets/svg/user.svg"
import {ReactComponent as Icon} from "../../../assets/svg/user.svg";
import React, {useContext} from 'react';
import {ModalContext} from '../../../App';
import classes from '../Footer.module.css';

const Profile = () => {
    // console.log('Profile')
    // const {setModalActive, setModalContent} = useContext(ModalContext);
    const linkClassname = ({isActive}) => isActive ? classes.activeImg : classes.img

    return (
        <div onClick = {() => {
            // setModalActive(false)
        }}>
            <NavLink to = {"/profile"} className = {linkClassname}>
                <Icon/>
                <div>Профиль</div>
            </NavLink>


        </div>
    )
}

export default React.memo(Profile);