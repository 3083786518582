import {NavLink, useNavigate} from "react-router-dom";
//import catalog from "../../../assets/svg/catalog.svg";
import {useContext} from 'react';
import {ModalContext} from '../../../App';
import classes from '../Footer.module.css';

import {ReactComponent as Icon} from '../../../assets/svg/backet.svg';

const ShopBacket = () => {
    console.log('ShopBacket')
    const navigate = useNavigate();
    const {setModalActive, setModalContent} = useContext(ModalContext);
    const linkClassname = ({isActive}) => isActive ? classes.activeImg : classes.img
    return (<div className = {classes.linkContainer}
                 onClick = {() => {
                     setModalActive(false)
                     setModalContent('')
                 }}>
        <NavLink to = {"/"} className = {linkClassname}>
            <Icon/>
            <div>Заказы</div>
        </NavLink>
    </div>);
};

export default ShopBacket;
