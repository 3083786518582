import React, {useState, useEffect, useContext} from "react";
import {useForm, useFieldArray} from "react-hook-form";
import link from '../../../../assets/svg/link.svg';
import info from '../../../../assets/svg/info.svg';

import styles from './CreateItem.module.css';
import {ADD_ITEM_MODES} from "./index";

import {ModalContext} from "../../../../App";
import {modalDisplayMode} from '../../../PrimaryModal/PrimaryModal';
import {useDispatch} from "react-redux";
// import { setItemInfo } from '../../../../features/itemSlice'
import {setItemInfo} from '../../../../features/createItemSlice'

import backArrow from '../../../../assets/png/backArrow.png';

function ChooseButton({
                          label,
                          displayMode,
                          setDisplayMode,
                          placeholder,
                          register,
                          name,
                          errors,

                      }) {
    function attributeHandler() {
        setDisplayMode(displayMode);
    }

    return (
        <div className = {styles.chooseForm} onClick = {attributeHandler}>
            
            <label className = {styles.label}>{label} </label>
            <div className = {styles.inputContainer}>
                <input
                    className = {styles.inputField}
                    type = "text"
                    placeholder = {placeholder}
                    {...register}
                />
                <img src = {link} alt = "link"/>
            </div>
        </div>
    );
}


function BoxButton({boxValue, setValue}) {
    const boxButtons = ["Да", "Нет"];

    return (
        <div className = {styles.buttonContainer}>
            {boxButtons.map((boxButton, i) => (
                <button
                    type = "button"
                    className = {`${styles.boxButton} ${
                        // selectedTaxButton === taxButton ? styled.active : ""
                        boxValue === boxButton ? styles.active : ""
                    }`}
                    key = {boxButton}
                    //   onClick={() => setSelectedTaxButton(taxButton)}
                    onClick = {() => setValue(boxButton)}
                >
                    {boxButton}
                </button>
            ))}
        </div>
    );
};


function FormField({label, type, placeholder, register, errors}) {
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    return (
        <div className = {styles.formItem}>
            <label className = {styles.label}>{label}</label>
            {type === "textarea" ? (
                <textarea
                    className = {styles.inputBig}
                    onKeyDown={handleKeyDown}

                    placeholder = {placeholder}
                    {...register}
                />
            ) : (
                <input className = {styles.input} 
                onKeyDown={handleKeyDown}
                type = {type} placeholder = {placeholder} {...register}/>
            )}
            {/*{errors && <span>Обязательное поле</span>}*/}
            <div className={styles.errorContainer}>
                {errors && <p className = {styles.errors}> {errors?.message || "Обязательное поле"}</p>}
            </div>


        </div>
    );
}


function handleCharacterInfo(setModalActive,
                             setModalContent,
                             setModalMode
) {

    setModalMode(modalDisplayMode.withOverlay)
    const modalContent = (
        <div className = {styles.infoContainer}>
            <h4 className = {styles.infoTitle}>Характеристики:</h4>
            <span className = {styles.infoText}>
        Здесь вы можете указать все характеристики товара по пунктам.
        </span>
            <div style = {{height: "2vh"}}/>
            <span className = {styles.infoText}>
        Параметр № - название характеристики товара.
        </span>
            <span className = {styles.infoText}>
        Значение № - значение заданной характеристики.
        </span>
            <div style = {{height: "2vh"}}/>
            <span className = {styles.infoText}>
        Вы можете добавить необходимое количество параметров, 
        которое требуется для определенного товара.
        </span>
            <div style = {{height: "2vh"}}/>
            <span className = {styles.infoText}>
        Рекомендуем не писать много текста в значении, лучше разбить на несколько параметров.
                </span>
        </div>
    )
    setModalContent(modalContent);
    setModalActive(true);
}


function handleQuantityInfo(setModalActive,
                            setModalContent,
                            setModalMode
) {
    setModalMode(modalDisplayMode.withOverlay)
    const modalContent = (
        <div className = {styles.infoContainer}>
            <h4 className = {styles.infoTitle}>Количество заводских упаковок:</h4>
            <span className = {styles.infoText}>
            Сколько заводских упаковок вы собрали вместе и теперь продаете их, как одно SKU. 
            </span>
            <div style = {{height: "2vh"}}/>
            <span className = {styles.infoText}>
                Eсли ваш товар - это 12 бутылок воды, запаянных в 1 упаковку на заводе, 
                и в таком же виде этот товар может продаваться 
                в других магазинах - значение поля будет 1. 
            </span>
            <div style = {{height: "2vh"}}/>

            <span className = {styles.infoText}>
                Если ваш товар - это 4 тетради, 
                запаянные или связанные в одну упаковку вами, 
                и вы решили их объединить самостоятельно, 
                а завод выпускает тетради в упаковках, например, 1 шт. 
                и 10 шт. - тогда значение поля будет 4.
            </span>
        </div>
    )
    setModalContent(modalContent);
    setModalActive(true);
}


function handleSeveralBoxInfo(setModalActive,
                              setModalContent,
                              setModalMode
) {
    setModalMode(modalDisplayMode.withOverlay)
    const modalContent = (
        <div className = {styles.infoContainer}>
            <h4 className = {styles.infoTitle}>Товар в нескольких упаковках:</h4>
            <span className = {styles.infoText}>
                    Выберите “Да”, если доставляете товар в нескольких упаковках. 
            </span>
            <div style = {{height: "2vh"}}/>
            <span className = {styles.infoText}>
                Например, это пригодится при продаже мебели в разобранном виде.
            </span>
            <div style = {{height: "2vh"}}/>


        </div>
    )
    setModalContent(modalContent);
    setModalActive(true);
}


function InfoItem({
                      formData,
                      setFormData,
                      setDisplayMode,
                      selectedBrand,
                      boxButton,
                      setBoxButton,
                  }) {
    const dispatch = useDispatch();

    const {setModalActive, setModalContent, setModalMode} = useContext(ModalContext);

    const {
        register,
        handleSubmit,
        watch,

        formState: {errors, isValid},
        getValues,
        // setValue,
        control,
    } = useForm({
        defaultValues: formData,
        mode: "all", criteriaMode: 'all'
    })


    useEffect(() => {
        setFormData(watch())
    }, [watch('name'),
        watch('brand'),
        watch('articleNumber'),
        watch('barcode'),
        watch('description'),
        watch('partNumber'),
        watch('waranty'),
        watch('quantity_box'),
        watch('weight'),
        watch('height'),
        watch('width'),
        watch('length'),
        watch('character')
    ])


    const {fields, append} = useFieldArray({
        name: "character",
        control,
    });

    const onSubmit = (data) => {

        dispatch(setItemInfo(data))
        setDisplayMode(ADD_ITEM_MODES.media);

    }
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }



    return (


        <div>

            <h1 className = {styles.header}>Информация о товаре</h1>
            <div className={styles.stepContainer}>
                <img className={styles.backArrow} src={backArrow} alt="back" onClick={() => setDisplayMode(ADD_ITEM_MODES.productCategory)} />
                <span className={styles.step} >Шаг 2/4</span>
            </div>
            <form className = {styles.form} onSubmit = {handleSubmit(onSubmit)}>


                <ChooseButton
                    label = "Бренд:"
                    displayMode = {ADD_ITEM_MODES.brand}
                    setDisplayMode = {setDisplayMode}
                    placeholder = "Обязательно"
                    register = {register('brand', {required: true})}
                    errors = {errors.brand}

                />
                <FormField
                    label = "Название:"
                    type = "text"
                    placeholder = "Обязательно"
                    register = {register('name', {
                        required: true,
                        minLength: {value: 2, message: "Обязательно от 2 до 50 символов"},
                        maxLength: {value: 50, message: "Обязательно от 2 до 50 символов"},

                    })}
                    errors = {errors.name}
                />


                <FormField
                    label = "Артикул:"
                    type = "text"
                    placeholder = {`Обязательно`}
                    register = {register('articleNumber', {required: true})}
                    errors = {errors.articleNumber}
                />

                <FormField
                    label = "Штрихкод:"
                    type = "text"
                    placeholder = {`Обязательно`}

                    // register = {register('barcode', {required: true, valueAsNumber: true,})}
                    register = {register('barcode', {

                        validate: {
                            validateNumber: (_, values) => {
                                const reg = new RegExp('^[0-9]+$');
                                return reg.test(values.barcode) || "Только цифры"
                            },


                        },
                        required: true,
                        maxLength: {value: 19, message: "до 19 цифр"},
                    })}
                    errors = {errors.barcode}
                />

                <FormField
                    label = "Партномер (заводской артикул):"
                    type = "text"
                    placeholder = {`Необязательно`}
                    register = {register('partNumber')}
                    errors = {errors.partNumber}
                />


                <FormField
                    label = "Описание:"
                    type = "textarea"
                    placeholder = {`Обязательно`}
                    register = {register('description', {required: true})}
                    errors = {errors.description}
                />


                <div className = {styles.formCharacter}>
                    <div className = {styles.labelIcon}>
                        <label className = {styles.label}>Характеристики: </label>
                        <img src = {info} alt = "info" onClick = {() => handleCharacterInfo(
                            setModalActive,
                            setModalContent,
                            setModalMode)}/>
                    </div>
                    {fields.map((field, index) => {
                            return <section className = {styles.characterContainer}
                                            key = {field.id}>
                                <input className = {styles.inputCharacter}
                                    onKeyDown={handleKeyDown}

                                       type = "text"
                                       placeholder = {`параметр ${index + 1}`}
                                       {...register(`character.${index}.characrerName`)}/>


                                <input type = "text"
                                       className = {styles.inputCharacter}
                                        onKeyDown={handleKeyDown}
                                       
                                       placeholder = {`значение ${index + 1}`}
                                       {...register(`character.${index}.characterValue`, {minLength: 1})}/>
                            </section>
                        }
                    )}
                    <span className = {styles.addCharacter}
                          type = "button" onClick = {() => append({
                        characterName: "",
                        characterValue: "",
                    })}>
                        Добавить параметр</span>

                </div>


                <ChooseButton
                    label = "Страна производитель"
                    displayMode = {ADD_ITEM_MODES.country}
                    setDisplayMode = {setDisplayMode}
                    placeholder = "Необязательно"
                    register = {register('country')}
                    // name="country"
                    errors = {errors}
                />
                <FormField
                    label = "Гарантиейный срок:"
                    type = "text"
                    placeholder = "Необязательно"
                    register = {register('waranty')}
                    errors = {errors.waranty}
                />


                <div className = {styles.formCharacter}>
                    <div className = {styles.labelIcon}>
                        <label className = {styles.label}>Количество заводских упаковок: </label>
                        <img src = {info} alt = "info" onClick = {() => handleQuantityInfo(
                            setModalActive,
                            setModalContent,
                            setModalMode)}/>

                    </div>
                    <input
                        className = {styles.input}
                        placeholder = "Необязательно"
                        onKeyDown={handleKeyDown}

                        type = "number" {...register('quantity_box',)} />
                </div>
                <div className = {styles.formCharacter}>
                    <div className = {styles.labelIcon}>
                        <label className = {styles.label}>Товар в нескольких упаковках? </label>
                        <img src = {info} alt = "info" onClick = {() => handleSeveralBoxInfo(setModalActive,
                            setModalContent,
                            setModalMode
                        )}/>
                    </div>

                    <BoxButton boxValue = {boxButton} setValue = {setBoxButton}/>
                </div>
                <ChooseButton
                    label = "Цвет:"
                    displayMode = {ADD_ITEM_MODES.color}
                    setDisplayMode = {setDisplayMode}
                    placeholder = "Необязательно"
                    register = {register('color',)}
                    // name="color"
                    errors = {errors}


                />

                <FormField
                    label = "Вес с упаковкой (г):"
                    type = "number"
                    placeholder = "Обязательно"
                    register = {register('weight', {required: true})}
                    errors = {errors.weight}
                />
                <FormField
                    label = "Длина упаковки (мм):"
                    type = "number"
                    placeholder = "Обязательно"
                    register = {register('length', {required: true})}
                    errors = {errors.length}
                />
                <FormField
                    label = "Ширина упаковки (мм):"
                    type = "number"
                    placeholder = "Обязательно"
                    register = {register('width', {required: true})}
                    errors = {errors.width}
                />
                <FormField
                    label = "Высота упаковки (мм):"
                    type = "number"
                    placeholder = "Обязательно"
                    register = {register('height', {required: true})}
                    errors = {errors.height}
                />

                <button className = {styles.addCategory} disabled = {!isValid} type = "submit">
                    Далее
                </button>



            </form>
        </div>


    )
}

export default InfoItem;