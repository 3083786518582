import React from "react";
import styles from "./Company.module.css";
import { useQuery } from "@apollo/client";
import { COMPANY_PERFOMANCE } from "../../../../requests/companyRequest";

function Performance({company_id}) {

    const {data, error, loading} = useQuery(COMPANY_PERFOMANCE,{
        fetchPolicy: 'cache-and-network',
        variables: {company_id }
    }); 

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('liked items error', error);
        return 'error';
    }


    const  ordersAggregate = data.shop_orders_aggregate.aggregate.count || 0;
    const shopPayments = data.shop_payments_aggregate.aggregate.sum.total_amount || 0 + ' ₽';
    const avgCheck = (ordersAggregate !== 0)
    ? (Math.round((shopPayments / ordersAggregate) * 10) / 10).toFixed(1) + ' ₽'
    : '0 ₽';

    const itemsOnSold = data.item_aggregate.aggregate.count || 0;
    // const cancelledOrders = data.order_items_aggregate.aggregate.count || 0;

    const items = [
        { label: 'Всего магазинов |', value: data.shop_aggregate.aggregate.count },
        { label: 'Всего заказов |', value: ordersAggregate },
        // { label: 'Отменено заказов |', value: 0 },
        // { label: 'Продано товаров |' , value: 0 },
        // { label: 'Возвращено товаров |', value: 0 },
        { label: 'Всего выручки |', value: shopPayments },
        { label: 'Средний чек |', value: avgCheck },
        { label: 'Товаров в продаже |', value: itemsOnSold },
    ];

    return (
        <div className={styles.performanceContainer}>
            <div className={styles.performanceLabel}>
                ПОКАЗАТЕЛИ ПО КОМПАНИИ
            </div>
            {items.map((item, index) => (
                <div className={styles.performance} key={index}>
                    <div className={styles.performanceItemLabel}>
                        {item.label}
                    </div>
                    <div className={styles.performanceItemValue}>
                        {item.value}
                    </div>
                </div>
            ))}
        </div>
    );
}

function Company({ company }) {
    const { name, address, inn, ogrn, tax_system, kpp } = company;
    const company_id = company.id;
    const tax = { 'general': 'Основная', 'simlfiled': 'Упрощенная', 'patent': 'Патентная' };
    const sno = tax[tax_system];





    return (
        <div className={styles.wrapper}>
            <div>
                <h3 className={styles.name}>{name}</h3>
                <h5 className={styles.address}>{address}</h5>
            </div>
            <div className={styles.detailsContainer}>
                <div className={styles.details}>
                    <label className={styles.detailsLabel}>ИНН</label>
                    <p className={styles.detailsValue}>{inn}</p>
                </div>
                <div className={styles.details}>
                    <label className={styles.detailsLabel}>КПП</label>
                    <p className={styles.detailsValue}>{kpp}</p>
                </div>
                <div className={styles.details}>
                    <label className={styles.detailsLabel}>ОГРН(ОГРНИП)</label>
                    <p className={styles.detailsValue}>{ogrn}</p>
                </div>
                <div className={styles.details}>
                    <label className={styles.detailsLabel}>СНО</label>
                    <p className={styles.detailsValue}>{sno}</p>
                </div>
            </div>
            <div className={styles.changeContainer} style={{'color' : '#B5B5B5' }}>
                Редактировать данные компании
            </div>
            <Performance company_id = {company_id}/>
        </div>
    );
}

export default Company;