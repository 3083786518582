import styles from './EditReview.module.css'
import {useState} from 'react';
import {Rating} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ReviewItem from '../ReviewItem/ReviewItem';
import NumberFormat from '../../../../utils/NumberFormat';


export default function EditReviewItem({item, setItem}) {
    //console.log(item)

    const [value, setValue] = useState(item.itemComments[0].estimate);

    const modifiersKeys = Object.keys(item.modifiers);

    return (<div className = {styles.wrapper}>

        <ReviewItem name = {item.item.name}
                    url = {item.url}
                    price = {NumberFormat(item.extended_cost)}
                    shop = {item.shop.name}
            // status = {item.}
                    firstModifierKey = {modifiersKeys[0]}
                    firstModifierValue = {item.modifiers[modifiersKeys[0]]}
                    secondModifierKey = {modifiersKeys[1]}
                    secondModifierValue = {item.modifiers[modifiersKeys[1]]}

        />

        <div className = {styles.ratingContainer}>
            <Rating
                name = "hover-feedback"
                value = {value}
                // precision = {0.5}
                onChange = {(event, newValue) => {
                    setValue(newValue);
                    setItem({...item, ratingValue: newValue})
                }}
                emptyIcon = {<StarIcon fontSize = "inherit"/>}
                // emptyIcon = {<img src = {emptyStarIcon} style = {{transform: "scale(0.7)"}}/>}
                style = {{transform: "scale(1.5)", marginRight: "20px"}}
            />
        </div>


    </div>);
}