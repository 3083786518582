import { gql } from "@apollo/client";



export const GET_ITEM_LOTS = gql`
  query getLot($lotsId: Int){
    item(where: {lots: {id: {_eq: $lotsId}}}) {
      id
      shop_id
      name
      articleNumber
      item_media(limit: 1,  where: {type: {_eq: "image"}}) {
        path
      }
      item_variants {
        id
        articleNumber
        image
        purposeName
        purposeValue
        purposeName2
        purposeValue2
        lots(where: {status: {_eq: "active"}}) {
          id
          status
          step
          total
          price
          ordered_quantity
          init_date
          expire_date
        }
      }
    }
  }
  
`;




export const GET_ITEM_ARCHIVED_LOTS = gql`
query getArchivedLot($lotsId: Int){
  item(where: {lots: {id: {_eq: $lotsId}}}) {
    
    name
    articleNumber
    item_media(limit: 1,  where: {type: {_eq: "image"}}) {
      path
    }
    item_variants {
      id
      articleNumber
      height
      image
      length
      weight
      width
      purposeName
      purposeValue
      purposeName2
      purposeValue2
      lots(where: {status: {_neq: "active"}}) {
        id
        status
        step
        total
        price
        ordered_quantity
        init_date
        expire_date
      }
    }
  }
}
  
`;


export const SHOP_ITEMS_FOR_LOTS = gql`
    query itemsForLots($shopId: Int!) {
      item(where: {shop_id: {_eq: $shopId}, _not: {lots: {id: {_is_null: false}}}}) {
            id
            about
            name
            articleNumber
            item_media(where: {type: {_eq: "image"}}) {
                path
            }
            item_variants {
                id
                articleNumber
                height
                weight
                length
                width
                image
                articleNumber
                purposeName
                purposeValue
                purposeName2
                purposeValue2
            }
    }
}
`;




export const GET_SHOP_LOTS = gql`
query getShopLots($shopId: Int) {
  lots(where: {shop_id: {_eq: $shopId }}, distinct_on: item_id) {
      id
      init_date
      expire_date
      status
      
      item {
          id
          articleNumber
          item_media(limit: 1, where: {type: {_eq: "image"}}) {
              path
          }
          name
          item_variants {
            purposeName
            purposeName2
          }
          allLotsAggregate: lots_aggregate {
            aggregate {
              count
            }
          }
          closedLotsAggregate: lots_aggregate(where: {status: {_eq: "sold"}}) {
            aggregate {
              count
            }
          }
          activeLotsAggregate: lots_aggregate(where: {status: {_eq: "active"}}) {

            aggregate {
              count
            }
          }
          item_variants_aggregate {
              aggregate {
                  count(distinct: true)
              }
          }
      }
  }
}

  
`;




export const GET_SHOP_ARCHIVED_LOTS = gql`
query getShopArchivedLots($shopId: Int) {
  lots(where: {shop_id: {_eq: $shopId }, status: {_neq: "active"}}, distinct_on: item_id) {
      id
      init_date
      expire_date
      status
      
      item {
          id
          articleNumber
          item_media(limit: 1, where: {type: {_eq: "image"}}) {
              path
          }
          name
          item_variants {
            purposeName
            purposeName2
          }
          allLotsAggregate: lots_aggregate {
            aggregate {
              count
            }
          }
          closedLotsAggregate: lots_aggregate(where: {status: {_eq: "sold"}}) {
            aggregate {
              count
            }
          }

          item_variants_aggregate {
              aggregate {
                  count(distinct: true)
              }
          }
      }
  }
}

  
`;




export const SAVE_LOTS = gql`
mutation saveLots($lots: [lots_insert_input!]!) {
    insert_lots(objects: $lots) {
      returning {
        id
      }
    }
  }  
`;



export const GET_LOT = gql`
query getLots($lotsId: Int) {
  lots(where: {id: {_eq: $lotsId }}) {
      id
      init_date
      expire_date
      status
      item_id
      item {
          id
          articleNumber
          item_media(limit: 1, where: {type: {_eq: "image"}}) {
              path
          }
          name
          item_variants {
            purposeName
            purposeName2
          }
          allLotsAggregate: lots_aggregate {
            aggregate {
              count
            }
          }
          closedLotsAggregate: lots_aggregate(where: {status: {_eq: "closed"}}) {
            aggregate {
              count
            }
          }

          item_variants_aggregate {
              aggregate {
                  count(distinct: true)
              }
          }
      }
  }
}

  
`;


export const DELETE_ITEM_LOT = gql`
mutation deleteItemLots($lotId: Int!) {
    delete_lots(where: {id: {_eq: $lotId}}) {
      returning {
        id
      }
    }
  }  
`;