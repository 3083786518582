import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
// import {CREATE_ORDER} from "../requests/orderRequests";
// import {gql} from "@apollo/client"; 
import {populateItemsWithMaxPrice} from "../utils/orderUtils";

import { createOrderApi } from "../utils/apiCalls/ordersApi";
// import { create } from "@mui/material/styles/createTransitions";


const GET_USER_CART = `
    query GetUserCart($userID: bigint) {
        cart(where: {user_id: {_eq: $userID}}) {
            id
            quantity
            shop_id
            item_id
            item {
                about
                id
                name
                item_media {
                    path
                }
                lots {
                    price
                    
                }
                item_variants {
                  purposeName
                  purposeValue
                  purposeName2
                  purposeValue2
                }

            }
            
            lot {
                price
                step
                status
            }
            shop {
                id
                name
                shop_bonus {
                    cashback
                }
            }

        }
    }
`


const UPDATE_QUANTITY = `
    mutation updateQuantity($userID: bigint, $itemID: Int, $amount: Int, $cartID: Int) {
        update_cart(where: {item_id: {_eq: $itemID}, user_id: {_eq: $userID}, id: {_eq: $cartID}}, _set: {quantity: $amount}) {
            returning {
                id
                item_id
                quantity
            }
        }
    }
`

const DELETE_CART_ITEM = `
    mutation deleteCartItem($cartIDs: [Int]){
        delete_cart(where: {id: {_in: $cartIDs}}) {
          returning {
              id
          }
        }
    }
`


// const GET_ORDER_ITEMS_FROM_CART = `
//   query GetOrderItemsFromCart($userID: bigint, $cartIDs: [Int]) {
//     cart(where: {user_id: {_eq: $userID}, id: {_in: $cartIDs}}) {
//       item_id
//       quantity
//       lots_id
//       shop_id
//       variant_id
//       lot {
//         price
//       }
//     }
//   }
// `


// const CREATE_ORDER = `
//     mutation CreateOrder($userID: bigint) {
//         insert_order(objects: {user_id: $userID, status: "pending"}) {
//             affected_rows
//             returning {
//                 id
//             }
//         }
//     }
// `;

// const   ADD_ORDER_ITEMS = `
//   mutation AddOrderItems($orderItems: [order_items_insert_input!]!) {
//     insert_order_items(objects: $orderItems) {
//       affected_rows
//       returning {
//         id
//         order_id
//       }
//     }
//   }
// `


// const ADD_SHOP_ORDERS = `
//   mutation AddShopOrders($shopOrders: [shop_orders_insert_input!]!) {
//     insert_shop_orders(objects: $shopOrders) {
//       affected_rows
//       returning {
//         id
//         order_id
//         shop_id
//       }
//     }
//   }

// `


// const UPDATE_STOCK_LOTS = `
//   mutation UpdateStockLots($stockLots: [stock_lots_update_input!]!) {
//     update_stock_lots(where: {id: {_in: [1,2,3]}}, _set: {quantity: 10})
// `
  




const makeQuery = async ({ query, variables }) => {
  
  const res = await axios.post(process.env.REACT_APP_HASURA_URl, {
    query,
    variables,
  }, {
    headers: {
      "content-type": "application/json",
      "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
    }
  })

  // console.log('makeQuery', res.data)
  return res.data
}





export const fetchUserCart = createAsyncThunk(
  'cart/getUserCart',

  async (userID) => {
    
    return makeQuery({
      query: GET_USER_CART,
      variables: { userID }
    })
  }
)

export const updateCartItemQuantity = createAsyncThunk(
  'cart/updateItemQuantity',
  
  async ({ itemID, amount, cartID, userID }) => {
 
    return makeQuery({
      query: UPDATE_QUANTITY,
      variables: { userID, itemID, amount, cartID }
    })
  }
)

export const removeItemFromCart = createAsyncThunk(
  'cart/removeItemFromCart',
  async ({ cartIDs }) => {
    return makeQuery({
      query: DELETE_CART_ITEM,
      variables: { cartIDs },
    })
  }
)








export const createOrder = createAsyncThunk(
  'cart/createOrder',
  async ({ userID, cartIDs }) => {
      
    const result = await createOrderApi({ userID, cartIDs })
    // console.log('result', result)
    return result
    
    // const{ itemID, amount, cartID, userID } => {
 
    //   await  makeQuery({
    //     query: UPDATE_STOCK_LOTS,
    //     variables: { userID, itemID, amount, cartID }
    //   })
    // }

    // const selectedCartItemsRes = await makeQuery({
    //   query: GET_ORDER_ITEMS_FROM_CART,
    //   variables: { userID, cartIDs },
    // })

    // const updateLots = selectedCartItemsRes.map(({ quantity, lots_id }) => {
    //   return {
    //       quantity,
    //       lots_id,
    //   };
    // });

    // const { some } = await makeQuery({
    //   query: UPDATE_STOCK_LOTS,
    //   variables: { stockLots: updateLots },
    // });


    // const { data: { insert_order: { returning } } } = await makeQuery({
    //     query: CREATE_ORDER,
    //     variables: { userID},
    //   })
    
    // const cartItems = selectedCartItemsRes.data.cart



    // const orderId = returning[0]?.id
    // await orderApiQuery({
    //   variables: { orderId },
    // });

    // const orderItems = cartItems.map(({ shop_id, quantity, lot: { price }, variant_id, ...rest }) => {
    //   const extendedCost = price * quantity
    //   return {
    //     ...rest,
    //     shop_id,
    //     quantity,
    //     total : extendedCost,
    //     price: price,
    //     order_id: orderId,
    //     variant_id : variant_id,

    //   }
    // })

  //   const shopOrders = orderItems.map(({ shop_id,...rest }) => {
  //     return {
  //       shop_id,
  //       order_id: orderId,
  //      order_items_id: ordersItems.id,
  //     }
  //   })

  //   makeQuery({
  //     query: ADD_SHOP_ORDERS,
  //     variables: { shopOrders: orderItems},
  // })

    // return makeQuery({
    //   query: ADD_ORDER_ITEMS,
    //   variables: { orderItems: orderItems},
    // })
  }
  

)

export const saveCartIds = createAsyncThunk(
  'cart/saveCartIds',
  async (cartIds) => {
  // console.log('saveCartIds', cartIds)
  return {
    type: 'cart/saveCartIds',
    payload: cartIds
  };
}
)



const cartSlice = createSlice({
  name: 'cart',
  initialState: {

    items: [],
    totalEconomy: 0,
    isLoading: false,
    error: null,
    selectedItems: [],
    currentOrderId: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCart.fulfilled, (state, action) => {
        // console.log('fetchUserCart', { state, action })
        const cart = action?.payload?.data?.cart || []
        const modifiedCart = populateItemsWithMaxPrice(cart, true)
        state.items = modifiedCart
        state.isLoading = false
      })
      .addCase(fetchUserCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserCart.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
        // console.log('updateCartItemQuantity', { state, action }) 

        const { quantity, id: cartId, item_id: itemId } = action.payload.data.update_cart.returning[0]
        state.items = state.items.map(({ id, ...rest }) => {
          if (id === cartId && rest.item_id === itemId) {
            return {
              id,
              ...rest,
              quantity
            }
          }
          return { id, ...rest }
        })
      })
      .addCase(removeItemFromCart.fulfilled, (state, action) => {
        // console.log('removeItem', { state, action })
        const removedItemIds = action.payload.data.delete_cart.returning.map(({ id }) => id)
        state.items = state.items.filter(({ id }) => !removedItemIds.includes(id))
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        // console.log('createOrder', action.payload)

        // state.selectedItems = action.payload
        // console.log('createOrder', state.selectedItems)
        const createdOrderId = action.payload.order_id
        state.currentOrderId = createdOrderId
        // console.log('createdOrderId', createdOrderId)
        localStorage.setItem('lastActiveOrderId', createdOrderId)
      })
      .addCase(saveCartIds.fulfilled, (state, action) => {
        state.selectedItems = action.payload.payload;
        // console.log('state.selectedItems', state.selectedItems)
      });
  },
})

const { actions, reducer } = cartSlice

export const { setSelectedCartItems } = actions
export default reducer
