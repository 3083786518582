import React from 'react';
import classes from './AllReviewPhotos.module.css';
// import reviewPhoto from '../../../assets/jpg/img2.jpg';

export default function AllReviewPhotos(images =null) {

  const imagesArray = images?.images || [];

  return (

    <div>
      <h2 className={classes.title}>Фотографии покупателей</h2>
      <div className={classes.container}>
      {
        imagesArray.map((image, index) => (
            <img
              key={index}
              src={image.url} 
              className={classes.photo}
              alt={`reviewPhoto-${index}`}
            />
          ))
        }
        {/* <img src={reviewPhoto} className={classes.photo} alt="reviewPhoto" /> */}
      </div>
    </div>
  );
}
