import React, {useEffect, useState} from 'react';
import styles from "./ProfilePhoneApplyCode.module.css"
import ProfileInput from '../../components/ProfileInput/ProfileInput';
import CheckButtonTwoLines, {checkButtonModes} from '../../../CheckButtonTwoLines/CheckButtonTwoLines';
import RemoveChar from '../../../../../utils/RemoveChar';
import axios from 'axios';


function ProfilePhoneApplyCode({
                                   phone,
                                   phoneInputcallback,
                                   isConfirm,
                                   setIsConfirm,
                                   phoneError,
                                   verificationCode, setVerificationCode,
                                   readOnly, label
                               }) {


    const [codeValue, setCodeValue] = useState('')
    const [buttonMode, setButtonMode] = useState(checkButtonModes.default)
    const [isDisabled, setIsDisabled] = useState(false)


    //set duration timer
    const maxTimerLong = 120


    useEffect(() => {

        //phone !== "" && !phoneError ? 1 : ""
        setCodeValue('')
    }, [])
    useEffect(() => {
        setIsConfirm(false)
        setCodeValue('')
        setButtonMode(checkButtonModes.default)

        //phone !== "" && !phoneError ? 1 : ""
    }, [phone])

    useEffect(() => {
        codeValue.length === 5 && codeValue === verificationCode
            ? setIsConfirm(true)
            : setIsConfirm(false)
    }, [codeValue])

//TIMER
    const [seconds, setSeconds] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 1) {
                setSeconds(seconds - 1);
            }
            if (seconds === 1) {
                setIsDisabled(false)
                setButtonMode(checkButtonModes.get_again)
                setSeconds(null)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);
    //TIMER


    const onClickChangeButtonModeHandler = () => {
        if (buttonMode === checkButtonModes.default || checkButtonModes.get_again) {
            setCodeValue("")

            //get only numbers from phone
            const apiPhone = RemoveChar(phone)
            // console.log(apiPhone)
            //get verification code
            const apiUrl = `https://api.jbspace.xyz/send_sms/${apiPhone}`;
            axios.get(apiUrl).then((resp) => {
                // console.log('ответ получен')
                setVerificationCode(resp.data);
                // console.log(resp.data)
            }, (error) => {
                console.log(error);
            });


            setButtonMode(checkButtonModes.send)//title Код отправлен
            //set timer 2s for title timer
            const timer = setInterval(() => {
                setSeconds(maxTimerLong)
                setIsDisabled(true)
                setButtonMode(checkButtonModes.expect)
            }, 2000);
            (setInterval(() => {
                clearInterval(timer)
            }, 2000))
        }
    }

    return (<>
            <div className = {styles.wrapper}>
                <div className = {styles.container}>

                    <ProfileInput label = {"Телефон:"} type = {"tel"}
                                  value = {phone}
                                  callback = {phoneInputcallback}
                                  placeholder = {'Введите номер +7...'}
                                  readOnly = {readOnly}
                    />

                    <CheckButtonTwoLines buttonMode = {buttonMode}
                                         callback = {onClickChangeButtonModeHandler}
                                         setButtonMode = {setButtonMode}
                        //  buttonMode = {buttonMode}
                        //  setButtonMode = {setButtonMode}
                                         isDisabled = {isDisabled}
                                         setIsDisabled = {setIsDisabled}
                                         seconds = {seconds}/>

                </div>


                <ProfileInput type = {"text"}
                              placeholder = {"-"}
                              value = {codeValue}
                              maxLength = {5}
                              callback = {setCodeValue}
                              label = {label}/>
            </div>


        </>

    );
}

export default ProfilePhoneApplyCode;

