import React,{useState} from 'react'
import styles from './RefundItem.module.css'
import { formatDate } from '..';
import { useMutation } from '@apollo/client';
import { STATUS_CANCEL_ORDER } from '../../../../../requests/orderRequests';
import { useNavigate } from 'react-router-dom';
import { REFUND_ITEM } from '../../../../../requests/refundRequests';
import { refundItemApi } from '../../../../../utils/apiCalls/ordersApi';


function ReasonButton ({reason, setValue , shop}) {

    // console.log('shop', shop)
    let reasonButtons
    if(shop){
      reasonButtons = ['Товара нет в наличии', 'Проблемы с товаром', 'Не выгодный заказ', 'Другая причина']
    }
    else{
    reasonButtons = ['Передумал', 'Долго ждать', 'Нашел дешевле', 'Другая причина']
    }
    return (
      <div className={styles.buttonContainer}>
        {reasonButtons.map((button, index) => (
          <button
            type="button"
            className={`${styles.dealButton} ${
                reason === button ? styles.active : ""
            }`}
            key={index}
            onClick={() => setValue(button)}
          >
            {button}
          </button>
        ))}
      </div>
    );
  };





function RefundItem({setModalActiveItem,  order, orderItemId, shop = false }){

    console.log('cancel order item')
    console.log('order', order)
    console.log('orderItemId', orderItemId)
    // console.log('shop', shop)

    const navigate = useNavigate();

    const [reason, setReason] = useState('')
    const [comment, setComment] = useState('')
    const [loadProcess, setLoadProcess] = useState(false)




    // const [changeStatus] = useMutation(STATUS_CANCEL_ORDER);
    // const [insertRefund] = useMutation(REFUND_ITEM);


    function processCancelItem(  reason, comment, setLoadProcess, setModalActiveItem) {
        setLoadProcess(true)


        refundItemApi(orderItemId , reason, comment).then(() => {
                if(shop){
                  navigate('/shop_orders', { state: { shopId: shop }});
                }else{
                  navigate('/profile/user/orders');
                }
                setLoadProcess(false)
                setModalActiveItem(false)
                // navigate("/profile/order", { state: { orderId: orderId } });

            }).catch(err => {
                console.log('err', err)
                alert('Произошла ошибка при отмене заказа')
            })

    }

    const item = order.order_items.filter(item => item.id === orderItemId)[0]
    return (
        <div className = {styles.modalContainer}>
            <div className = {styles.closeLineContainer}
                     onClick = {() => setModalActiveItem(false)}>
                    <div className = {styles.closeLine}></div>
                    {/* <span className = {styles.checkmark}></span> */}
            </div>
            <div className = {styles.modalHeader}> 
                    <span className = {styles.modalTitle}>Вы точно хотите отменить заказ 
                            <span className={styles.orderId}>№ {order.id}</span>  
                             от {formatDate(order.created_on)}?
                    </span>
                </div>
                <div className = {styles.modalBody}>
                    
                    <div  style={{ display: 'flex', flexDirection: "column", gap :'5px   ' }}>
                        <div className={styles.itemImage}>
                          <img src={item.item.item_media[0].path} alt="item" />
                        </div>
                        <div className = {styles.quanityContainer}>
                            <div className = {styles.orderQuantity}>{item.quantity}шт.по {item.price} ₽</div>
                            <div className = {styles.orderSum}> = {item.price * item.quantity} ₽</div>
                        </div>
                    </div> 
                    
                </div>
                <span className={styles.reason}>
                    Причина отмены заказа : 
                </span>
                <ReasonButton reason={reason} setValue={setReason} shop={shop} />
                <textarea className = {styles.textarea} 
                        placeholder = 'Подробно опишите причину, по которой хотите отменить заказ...'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                <div className = {styles.totalButtons}>
                    {
                        loadProcess ? (
                            <span className={styles.proceedBtn}>обработка ....</span>
                        ) : (
                         <button disabled={!reason} 
                                className={styles.totalButton}
                                onClick={() => processCancelItem(
                                                                    reason, 
                                                                    comment, 
                                                                setLoadProcess,
                                                                setModalActiveItem
                                                                )}
                                 >Отменить заказ</button>
                                 )                }
                </div>


          </div>
    )
}

export default RefundItem;