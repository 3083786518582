import React, {useEffect, useRef, useState} from 'react';
import classes from './Search.module.css';
import searchIcon from '../../../assets/svg/search.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import {SearchContext} from '../../../App';

const Search = () => {
    const {searchValue, setSearchValue} = React.useContext(SearchContext);
    // const [localSearchValue, setLocalSearchValue] = useState('')
    const inputRef = useRef(null);
    // console.log('Search')

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //
    //         setSearchValue(() => {
    //             console.log("localSearchValue rerender")
    //             return localSearchValue
    //         });
    //     }, 1000);
    //     return () => {
    //         clearTimeout(handler)
    //     }
    // }, [localSearchValue])

    // console.log("searchValue", searchValue)
    return (<div className = {classes.div}>
        <input
            onKeyDown = {(event) => {
                if (event.key === "Enter") {
                    setSearchValue(prev => prev + " ")
                    // setLocalSearchValue(prev => prev + " ")
                    //setSearchValue(prev => prev.trim())
                    inputRef.current.blur()
                }
            }}
            ref = {inputRef}
            value = {searchValue}
            className = {classes.search}
            type = "text"
            onFocus = {() => {
                setSearchValue(prev => prev.trim())
                //setSearchValue(prev => prev.trim())
            }}
            placeholder = "Найти товар..."
            onChange = {(event) => {
                //setLocalSearchValue(event.target.value);
                setSearchValue(event.target.value);
            }}
        />
        <img src = {searchIcon} className = {classes.searchIcon} alt = "searchIcon"/>
        {searchValue && (<img
            onClick = {() => {
                setSearchValue('');
                //setLocalSearchValue('')
            }}
            src = {deleteIcon}
            className = {classes.deleteIcon}
            alt = "deleteIcon"
        />)}
    </div>);
};

export default Search;
