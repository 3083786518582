import React from 'react';
import styles from './СolorAdditionalAccent.module.css'

const ColorAdditionalAccent = ({conditions}) => {
    // dispay label promotion on the product card

    const accentAmount = conditions.filter(c => c.isExist).length

    const isNewProduct = conditions[0].isExist
    const isSomeOne = conditions[1].isExist


    switch (accentAmount) {

        case 1: //if only one action

            // console.log(" case 1:")
            // console.log("isNewProduct", isNewProduct)
            // console.log("isSomeOne", isSomeOne)

            return (<>
                {isNewProduct && <div className = {styles.newProduct}>
                    <span className = {styles.title}>{conditions[0].title}</span>
                </div>}

                {isSomeOne && <div className = {styles.someOne}>
                    <span className = {styles.title}>{conditions[1].title}</span>
                </div>}
            </>)

        case 2: //if two actions

            // console.log(" case 2:")
            // console.log("isNewProduct", isNewProduct)
            // console.log("isSomeOne", isSomeOne)

            return (<>
                <div className = {styles.someOne} style = {{paddingBottom: "24px"}}>
                    <span className = {styles.title}>{conditions[1].title}</span>
                </div>

                <div className = {styles.newProduct}>
                    <span className = {styles.title}>{conditions[0].title}</span>
                </div>
            </>)
        default :
            return null
    }


}
export default ColorAdditionalAccent
