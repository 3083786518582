import React from 'react';
import styles from "./ColoredButton.module.css"

function ColoredButton({name, disabled, callback, type}) {
    return (
        <button className = {styles.coloredButton} disabled = {disabled} type = {type} onClick = {callback}>
            {name}
        </button>
    );
}

export default ColoredButton;