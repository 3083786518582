import React from 'react';
import classes from './AddReviewImages.module.css';
import removeIcon from '../../../../assets/svg/remoove.svg';


export default function AddSingleReviewImage({
                                                 image,
                                                 index,
                                                 handleDeleteImage
                                             }) {
    //console.log('AddSingleImage')
    const style = {backgroundImage: `url(${image.localUrl})`}

    return (
        <div className = {classes.imageContainer} style = {style}>
            <div className = {classes.iconContainer}>
                <img src = {removeIcon} onClick = {() => handleDeleteImage(index)}/>
            </div>
        </div>)
}



