import React, { useState, useEffect } from 'react';
import style from './Login.module.css';
import ProfileInput from '../ProfileMain/components/ProfileInput/ProfileInput';
import { useCallback } from 'react';
import RemoveChar from '../../../utils/RemoveChar';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Telegram from '../../../assets/png/Telegram.png';

import Cookies from 'js-cookie';

import { useContext } from 'react';
import { TelegramContext } from '../../../App';


import { parseJwt } from '../../../utils/cookies';




const usePhoneValidation = () => {
    const [phone, setPhone] = useState('+');
    const [phoneError, setPhoneError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);



    const handlePhoneChange = useCallback((inputValue) => {
        setPhoneError(false);
        setIsVerified(false);

        const regex = /^\+?[0-9]{0,13}$/;

        if (regex.test(inputValue)) {
            setPhone(inputValue);
        }

        const regexFull = /(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){7,15}(\s*)/;
        if (!regexFull.test(inputValue)) {
            setPhoneError(true);
        }
    }, []);

    return [phone, handlePhoneChange, phoneError, isVerified, setIsVerified];
};



const Login = () => {

    const navigate = useNavigate();

    let {userFirstName, setUserFirstName, userID, setUserID} = useContext(TelegramContext);

    const [login, setLogin] = useState(false);
    const [name, setName]= useState('хер')
    const [id, setId] = useState(userID)


    

    useEffect(() => {
        if(login){
            setUserFirstName(name);
            setUserID(id)
            navigate(-1)
        }
          // Set state here if it depends on component mounting
    }, [login]);
    



    const [phone, handlePhoneChange, phoneError, isVerified, setIsVerified] = usePhoneValidation();


    const [checkPhone, setCheckPhone] = useState(false);
    const [codeError, setCodeError] = useState(false);

    const [password, setPassword] = useState('');
    const [seconds, setSeconds] = useState(0);
    const [codeValue, setCodeValue] = useState('')

    const [phoneCode, setPhoneCode] = useState('');
    const [disabledTimer, setDisabledTimer] = useState(true);

    const [disabledbutton, setDisabledButton] = useState(false)

    const [buttonTitle, setButtonTitle] = useState('Получить код')
    

    // console.log('disabledTimer:', disabledTimer)

    const codeValidation = (value, validationCallback) => {
        const regex = /^[0-9]{0,6}$/;
        if (regex.test(value)) {
            validationCallback(value)
        }
    }

    const checkCodeInputhandler = (value) => {
        
        codeValidation(value, setCodeValue)
        if (codeError){
            setCodeError(false)
        }
        
        // if (value.length === 7) {
            // setDisabledTimer(false)
            
        // }

    }



    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 1) {
                setSeconds(seconds - 1);
                setButtonTitle(seconds + ' сек')
                setDisabledButton(true)
            }
            if (seconds === 1) {
                setDisabledTimer(false)
                setButtonTitle('Получить код')
                setDisabledButton(false)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);


    

    const checkButtonHandler = () => {
        // setButtonTile('Код отправлен')
        const number= RemoveChar(phone)
        

        // const apiUrl = `http://127.0.0.1:8002/auth/login/${number}`;
        const apiUrl = `https://api.jbspace.xyz/auth/login/${number}`;
        axios.get(apiUrl).then((resp) => {
            // console.log('ответ получен')
            setPhoneCode(resp.data);
            // console.log(resp.data)
            setCheckPhone(false)
            setSeconds(120)
            setDisabledTimer(true)
    
        }, (error) => {

            console.log(error);
            setSeconds(0)
            setDisabledTimer(false)
            setCheckPhone('Не можем найти такой номер. Пожалуйста, зарегистрируйтесь.')
            setDisabledButton(false)
        });


    }




    const handleSubmit = (e) => {
        e.preventDefault();
        const number = parseInt(RemoveChar(phone), 10);
        // const apiUrl = `http://127.0.0.1:8002/auth/check_otp/${number}/${encodeURIComponent(codeValue)}`;
        const apiUrl = `https://api.jbspace.xyz/auth/check_otp/${phone}/${encodeURIComponent(codeValue)}`;

        axios.get(apiUrl).then((resp) => {
            // console.log('ответ получен')
            // console.log(resp)
            setPhoneCode(resp.data);
            const token = resp.data.access_token; 
            // console.log(token)
            
            Cookies.set('token', token, { expires: 7, secure :true, sameSite : 'strict' });

            const decodedToken = parseJwt(token);
            setId( decodedToken['https://hasura.io/jwt/claims']['X-Hasura-User-Id']);
            setName( decodedToken['https://hasura.io/jwt/claims']['x-user-firstname'])
            
            setLogin(true);
            // navigate(-1)

        }, (error) => {
            // console.log(error);
            console.log('error:', error.response)
            setCodeError('Не валидный код')
        });
    };

    return (
        <div className= {style.wrapper}>
            <div className = {style.greeting}>
                <h3 className = {style.title}> Вход в систему </h3>
            </div>

            <div className = {style.line}/> 
                <div className={style.numberContainer}>
                 <ProfileInput label = {"Телефон:"} type = {"tel"} value = {phone}
                              callback = {handlePhoneChange} placeholder = {'Введите номер +7...'}
                />
               
                        <button
                            className={style.buttonCode}
                            onClick={() => {
                                setDisabledButton(true);
                                checkButtonHandler();
                            }}
                            disabled={disabledbutton}
                        >
                            {buttonTitle}
                        </button>
                     
            </div>
                <div style = {{color: 'red', height: '2vh'}}>
                            {phoneError && <span>Пожалуйста, введите корректный номер телефона </span>}
                        </div>
                <div style = {{color: 'red', height: '2vh'}}>
                            {checkPhone && <span>{checkPhone}</span>}
                        </div>
                
            <div>
                     <ProfileInput label = {"Код для входа:"} type = {"tel"} value = {codeValue}
                              callback = {checkCodeInputhandler} placeholder = {'-'} />

                <div style = {{color: 'red', height: '2vh'}}>
                            {codeError && <span>{codeError}</span>}
                        </div>
            </div>


            <div className={style.submitContainer}>
            <button className={style.submitButton} 
                    disabled={codeValue.length < 5}
                    onClick={handleSubmit}
                    type="submit">
                            Войти
                </button>
                <button className={style.submitButton} 
                    disabled = {true}
                    type="submit">
                    
                    Войти через <img className={style.icon} src={Telegram} alt="Telegram" />  Telegram
                </button>
            </div>

            <div >
                <p className={style.link} onClick={()=> {navigate('/v1/registration')}}>У меня нет аккаунта. Зарегистрироваться.</p>
            </div>

            <div>
                <p className={style.link} style={{ fontSize: '15px' }} onClick = {() => {navigate('/aboutCompany')}}> О компании</p>
            </div>
        </div>
    );
};

export default Login;