import {gql} from "@apollo/client";


export const ADD_TO_BASKET = gql`
    mutation AddToBasket(
        $price: numeric,
        $itemId: Int,
        $shopId: Int,
        $userId: bigint,
        $lotsId: Int,
        $variantId: Int,
        $extendedCost: numeric,
        $quantity: Int) {
            insert_cart(objects: 
                {item_id: $itemId, 
                extended_cost: $extendedCost, 
                cost_item: $price, 
                lots_id: $lotsId,, 
                quantity: $quantity,, 
                user_id: $userId, 
                variant_id: $variantId, 
                shop_id: $shopId}, 
                on_conflict: {
                    constraint: cart_user_id_lots_id_key, 
                    update_columns: [quantity, cost_item, extended_cost]}) {
                    returning {
                      id
                    }
              }
    }
`;



export const READ_QUANTITY = gql`
    query readQuantity($userID: bigint, $lotsID: Int, $variantsId: Int) {
        cart(where: {
            user_id: {_eq: $userID},
            lots_id: {_eq: $lotsID},
            }) {
            quantity
            id
        }
    }`;

export const GET_ALL_LOTS = gql`
    query getAllLots($itemId: Int) {
        item_lots(where: {item_id: {_eq: $itemId}}) {
            id
            item_id
            price
        }
    }
`

export const GET_USER_CART = gql`
    query GetUserCart($userID: bigint) {
        cart(where: {user_id: {_eq: $userID}}) {
            cost_item
            extended_cost
            id
            shop_id
            item_id
            quantity
            user_id

            item {
                about
                id
                name
                images_items {
                    url
                }
            }

            item_lot {
                price
            }

            shop {
                id
                name
                shop_bonus {
                    cashback
                }
            }

        }
        user_address (where: {user_id: {_eq: $userID}}){
            choose
            flat
            home
            id
            index
            profile_id
            street
            title
            user_id
            city_id
            delivery_city {
                city
                country
                region
            }
        }
        user_profile (where: {user_id: {_eq: $userID}}){
            id
        }
    }

`;

export const UPDATE_QUANTITY = gql`
    mutation MyMutation($userID: bigint, $lotsID: Int, $amount: Int) {
        update_cart(where: {lots_id: {_eq: $lotsID}, user_id: {_eq: $userID}}, _set: {quantity: $amount}) {
            returning {
                id
            }
        }
    }
`;

export const DELETE_CART_ITEM = gql`
    mutation deleteCartItem($cartID: String){
        delete_cart(where: {id: {_eq: $cartID}})
        {
            returning {
                id
            }
        }
    }
`;

export const ADD_TO_CART = gql`
    mutation AddToBasket(
        $price: numeric,
        $itemID: Int,
        $shopID: Int,
        $userID: bigint,
        $lotsID: Int,
        $variantID: Int,
        $extendedCost: numeric,
        $quantity: Int) {
        insert_cart(objects:
        {   cost_item: $price, 
            item_id: $itemID, 
            quantity: $quantity, 
            shop_id: $shopID,
            variant_id: $variantID, 
            extended_cost: $extendedCost,
            user_id : $userID,
            lots_id: $lotsID}) {
            returning {
                extended_cost
                id
            }
            affected_rows
        }
    }
`;
