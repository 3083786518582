import React from 'react';
import styles from "./SuccessUserRegistrationChildren.module.css"

function SuccessUserRegistrationChildren() {
    return (

        <div className = {styles.wrapper}>
            <h3 className = {styles.title1}>Благодарим за регистрацию</h3>
            <h3 className = {styles.title1}>
                <span className = {styles.text}>на маркетплейсе</span><span
                className = {styles.item}>item</span>
            </h3>
            <h2 className = {styles.title2}>Приятных покупок!</h2>
        </div>
    );
}

export default SuccessUserRegistrationChildren;