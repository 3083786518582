import React from "react";
import {useQuery} from "@apollo/client";
import {USER_ADDRESS} from "../../requests/userRequests";
import {useContext} from "react";
import {TelegramContext} from "../../App";
import {useNavigate} from "react-router-dom";

export default function CartAddress() {
  const {userID} = useContext(TelegramContext);
  const navigate = useNavigate();

  const {loading, error, data} = useQuery(USER_ADDRESS, {
    variables: {userID}, fetchPolicy: "cache-and-network"
  });
  if (loading){return <div>loading...</div>}
  if (error){return <div>error...</div>}

  const handleChangeAddress = () => navigate("/addresses", {
    state: {
        addresses: data.user_address,
        profileId: data.user_profile[0].id
    }
  });

  const [userAddress] = data?.user_address?.filter(address => address.choose)
  const region = !!userAddress?.delivery_city?.region ? `${userAddress?.delivery_city?.region}, ` : ''
  if (userAddress) {
    return (
      <span  onClick={handleChangeAddress} >
        <span>{`${userAddress?.index}, ${userAddress?.delivery_city?.country},`}</span>
        <span>{` ${region} ${userAddress?.delivery_city?.city}, ${userAddress?.street}, ${userAddress?.home}, ${userAddress?.flat}`}</span>
      </span>
    );
  } else {
    return (
      <span  onClick={handleChangeAddress} >
        Выбрать
      </span>
      );
  }
};

 