import React from 'react';
import styles from "./SingleLinkContainer.module.css"
import link from '../../../assets/svg/link.svg';
import { HowToVoteRounded } from '@mui/icons-material';

function SingleLinkContainer({title, children, callback, active = true}) {

    // console.log('function SingleLinkContainer from multicontainer')
    

    return (


    <div className={active ?  styles.link : styles.notActive } onClick={active ? callback :  undefined  }>
    <span>{title}</span>
    <div>
        <span className={styles.children}>{children}</span>
        <img src={link} alt=""/>
    </div>
</div>
    );
}

export default SingleLinkContainer;