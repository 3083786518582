import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
// import { useMutation, useQuery } from '@apollo/client';
// import { gql } from "@apollo/client";
// import { UPDATE_ITEM_MEDIA } from '../requests/itemRequests';
// import { client } from '@apollo/client';


const GET_ITEM = `
    query getItem($itemId: Int) {
        item(where: {id: {_eq: $itemId}}) {
            id
            status
            name
            about
            articleNumber
            barcode
            brand
            partNumber
            character
            color
            country
            waranty
            created_on
            quantity_box

            item_media {
                id
                path
                type
            }
            item_variants{
                height
                weight
                length
                width
                image
                articleNumber
                purposeName
                purposeValue
                purposeName2
                purposeValue2
                
            }
            category_items {
                category {
                  category
                  subcategory
                  product_category
                }
              }
                   

        }
    }
`;


const ADD_ITEM_MEDIA = `
mutation AddItemMedia($media: [item_media_insert_input!]!) {
  insert_item_media(objects: $media) {
    returning {
      type
      path
    }
  }
}



`;

const UPDATE_ITEM_INFO = `
mutation UpdateItemInfo($itemId: Int!,
                         $name: String, 
                         $about: String, 
                         $articleNumber: String, 
                         $barcode: bigint, 
                         $brand: String, 
                         $partNumber: String, 
                         $character: jsonb, 
                         $country: String, 
                         $waranty: String, 
                         ) {
  update_item(
    where: { id: { _eq: $itemId } },
    _set: {
      name: $name,
      about: $about,
      articleNumber: $articleNumber,
      barcode: $barcode,
      brand: $brand,
      partNumber: $partNumber,
      character: $character,
      country: $country,
      waranty: $waranty,
      updated_on: "now()",
    }
  ) {
    returning {
      status
      name
      about
      articleNumber
      barcode
      brand
      partNumber
      character
      color
      country
      waranty
      created_on
    }
  }
}


  `;


  const UPDATE_ITEM_VARIANT = `
  mutation UpdateItemVariant($itemId: Int!,
    $name: String, 
    $about: String, 
    $articleNumber: String, 
    $barcode: Int, 
    $brand: String, 
    $partNumber: String, 
    $character: jsonb, 
    $country: String, 
    $waranty: String, 
    ) {
  update_item_variants(
    where: { id: { _eq: $itemId } },
    _set: {
      name: $name, 
      about: $about,
      articleNumber: $articleNumber,
      barcode: $barcode,
      brand: $brand,
      partNumber: $partNumber,
      character: $character,
      country: $country,
      waranty: $waranty,
      updated_on: "now()",
    }
  ) {
    returning {
      status

    }
  }
}`

  




const makeQuery = async ({ query, variables }) => {
    const res = await axios.post(process.env.REACT_APP_HASURA_URl, {
      query,
      variables,
    }, {
      headers: {
        "content-type": "application/json",
        "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
      }
    })
    return res.data
  }
  
  






export const fetchSettings = createAsyncThunk(
    'settingsItem/getItem',
    async (itemId) => {

      return makeQuery({
        query: GET_ITEM,
        variables: { itemId },
      })
    }
  );
  

function createMediaArray(newMedia, existingMedia, itemId) {
    const mediaArray = [];
  
    // Check and add new images
    if (newMedia.image) {
      newMedia.image.forEach(img => {
        if (!existingMedia.some(media => media.path === img && media.type === 'image')) {
          mediaArray.push({ item_id: itemId, path: img, type: 'image' });
        }
      });
    }
  
    // Check and add new video
    if (newMedia.video && !existingMedia.some(media => media.path === newMedia.video && media.type === 'video')) {
      mediaArray.push({ item_id: itemId, path: newMedia.video, type: 'video' });
    }
  
    // Check and add new cover
    if (newMedia.cover && !existingMedia.some(media => media.path === newMedia.cover && media.type === 'cover')) {
      mediaArray.push({ item_id: itemId, path: newMedia.cover, type: 'cover' });
    }
  
    return mediaArray;
  }


export const updateMediaSettings = createAsyncThunk(
    'settings/updateMediaSettings',
    async ({ itemId, media }, { getState, dispatch }) => {
      const state = getState();
      const mediaArray = createMediaArray(media, state.settingsItem.itemMedia, itemId);
  
      try {
        const insertResponse = await makeQuery({
          query: ADD_ITEM_MEDIA,
          variables: { media : mediaArray }, // Ensure this matches your GraphQL schema
        });
        console.log('Insert Response:', insertResponse);
  
        // Update Redux state with new media items here (if necessary)
        // dispatch(updateStateWithNewMedia(insertResponse.data.insert_item_media.returning));
  
        return insertResponse.data; // Return the response data for further processing
      } catch (error) {
        console.error('Error inserting media:', error);
        // Handle insertion error
        return { error: 'Failed to insert new media' };
      }
    }
  );


export const updateInfoSettings = createAsyncThunk(
    'settings/updateInfoSettings',
    async ({  info }) => {
      console.log('updateInfoSettings', {  info });
      
      console.log('itemId', info.itemId)
      try {
        const insertResponse = await makeQuery({
          query: UPDATE_ITEM_INFO,
          variables: {
              itemId: info.itemId,
              name: info.name,
              about: info.about,
              articleNumber: info.articleNumber,
              barcode: info.barcode,
              brand: info.brand,
              partNumber: info.partNumber,
              character: info.character,
              country: info.country,
              waranty: info.waranty,
             }, 
        });
        console.log('Insert Response:', insertResponse);
  
        // Update Redux state with new media items here (if necessary)
        // dispatch(updateStateWithNewMedia(insertResponse.data.insert_item_media.returning));
  
        return insertResponse.data; // Return the response data for further processing
      } catch (error) {
        console.error('Error inserting media:', error);
        // Handle insertion error
        return { error: 'Failed to insert new media' };
      }
    }
  );


  function createVariantArray(getValues, variant, variant2, imageUrls) {
    const variantsArray = [];

    // Helper function to create a variant object
    const createVariantObject = (el, imageUrl) => ({
        articleNumber: el.articleNumber,
        length: el.length,
        width: el.width,
        height: el.height,
        weight: el.weight,
        purposeName: variant,
        purposeValue: el.variantValue,
        purposeName2: variant2,
        purposeValue2: el.variantValue2,
        image: imageUrl,
    });

    // Loop through each purpose and create the corresponding variants
    getValues().purpose.forEach((el, index) => {
        const variantObject = createVariantObject(el, imageUrls[index]);

        // Push the variant into the variants array
        variantsArray.push(variantObject);
    });

    return variantsArray;
}



export const updateVariantSettings = createAsyncThunk(
    'settings/updateVariantSettings',
    async ({  variants }) => {
      console.log('updateVariantSettings', {  variants });
      
      console.log('itemId', variants.itemId)
      try {
        const insertResponse = await makeQuery({
          query: UPDATE_ITEM_VARIANT,
          variables: {
              itemId: variants.itemId,
              name: variants.name,
              about: variants.about,
              articleNumber: variants.articleNumber,
              barcode: variants.barcode,
              brand: variants.brand,
              partNumber: variants.partNumber,
              character: variants.character,
              country: variants.country,
              waranty: variants.waranty,
             }, 
        });
        console.log('Insert Response:', insertResponse);
  
        // Update Redux state with new media items here (if necessary)
        // dispatch(updateStateWithNewMedia(insertResponse.data.insert_item_media.returning));
  
        return insertResponse.data; // Return the response data for further processing
      } catch (error) {
        console.error('Error inserting media:', error);
        // Handle insertion error
        return { error: 'Failed to insert new media' };
      };
    }
  );


  
    
    

  






const settingItemSlice= createSlice({
    name : 'settingsItem',
    initialState : {
        category : '',
        articleNumber : '',
        itemMedia : {},
        itemId : null,
        variants : [],
        loading: false,
        error: null,
        item: {},
    },
    reducers: { },
    extraReducers: (builder) => {
        builder 
            .addCase(fetchSettings.fulfilled, (state, action) => {
                    console.log('fetchSettings', action)
                    console.log('state', state)
                    state.item = action.payload.data.item[0];
                    state.itemMedia = action.payload.data.item[0].item_media;
                    state.articleNumber = action.payload.data.item[0].articleNumber;
                    state.itemId = action.payload.data.item[0].id;
                    state.loading = false;
                })
            .addCase(fetchSettings.pending, (state) => {
                state.loading = true;
              })      
              
              .addCase(fetchSettings.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
              })

              .addCase(updateMediaSettings.fulfilled, (state, action) => {
                // state.item = action.payload.data.item[0];
                const newMediaItems = action.payload.insert_item_media.returning;
                state.itemMedia = [...state.itemMedia, ...newMediaItems];
                state.loading = false;
              })

              .addCase(updateInfoSettings.fulfilled, (state, action) => {
                console.log('state', state)

                console.log('updateInfoSettings', action.payload)
                const newInfoItems = action.payload.update_item.returning;               
                state.item.name = newInfoItems[0].name;
                state.item.about = newInfoItems[0].about;
                state.item.articleNumber = newInfoItems[0].articleNumber;
                state.item.barcode = newInfoItems[0].barcode;
                state.item.brand = newInfoItems[0].brand;
                state.item.partNumber = newInfoItems[0].partNumber;
                state.item.character = newInfoItems[0].character;
                state.item.country = newInfoItems[0].country;
                state.item.waranty = newInfoItems[0].waranty;
                state.loading = false;
              })
          
              .addCase(updateVariantSettings.pending, (state, action) => {
                console.log('updateVariantSettings', action.payload)

                const newVariants = action.payload.update_item_variants.returning;
                console.log('newVariants', newVariants)
                state.variants = newVariants;
              }

              );

    }
})

const {actions, reducer} = settingItemSlice;


export const { setSettingItems } = actions;

export default reducer