import React from 'react';
import s from "./LogOutContainer.module.css"

function LogOutContainer({ titles, callbacks }) {
    const singleLink = titles.map((el, index) => {
        if (el === "Удалить аккаунт") {
            return (
                <div key={index} className={s.link}>
                    <span style={{ color: '#B5B5B5' }}>{el}</span>
                </div>
            );
        } else {
            return (
                <div key={index} className={s.link} onClick={callbacks[index]}>
                    {el}
                </div>
            );
        }
    });

    return (
        <div className={s.wrapper}>
            <div className={s.linksContainer}>
                {singleLink}
            </div>
        </div>
    );
}



export default LogOutContainer;