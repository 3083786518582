import classes from './ShopsCard.module.css';
import {useNavigate} from 'react-router-dom';

import NoPhoto from '../Containers/Account/NoPhoto/NoPhoto';

import Share from '../Containers/ProductCard/Shop/SharingPage/Share';

import {ModalContext} from '../App';
import {useContext} from 'react';
import ShareButton from '../Containers/ShareButton/ShareButton';
import LikeShop from '../Containers/Likes/LikesShop';

import {Rating} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import {modalDisplayMode} from '../Containers/PrimaryModal/PrimaryModal';

export default function ShopsCard({shops, nav}) {

   
    const navigate = useNavigate();
        
    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const shareHandler = (shopId) => {
        setModalMode(modalDisplayMode.withOverlay);

        setModalActive(true);
        
        const url = `https://www.sovmestno.sale/shop/${shopId}`
        setModalContent(
            <Share url = {url}/>);
    }


    const displayShops = shops && Array.isArray(shops) && shops.map((shopItem, index) => {

        const averageEstimate = shopItem.comment_items_aggregate?.aggregate.avg.estimate || 0;
        const shopCommentCount = shopItem.comment_items_aggregate?.aggregate.count || 0;

        
        const callback = () => {
            shareHandler(shopItem.id);
          };
        const navigateToShop = () => {
        if(nav === 'settingsShop') {
                    const shop = shopItem;
                    navigate("/profile/shop/setting", {state: {shop}});
                } else {
                    navigate(`/shop/${shopItem.id}`);
                }
        }


        return (
            <div key={index} className={classes.shopContainer}>
                <div className= {classes.imageContainer} onClick= {navigateToShop}>
                    {shopItem.photo
                    ? <img src = {shopItem.photo} className = {classes.photo} alt = 'shopImage'/>
                    : <NoPhoto/>}
                </div>

            <div className = {classes.textContainer}>
                <div className = {classes.infoContainer}>
                    <div className = {classes.infoText} onClick= {navigateToShop}>
                        <span className = {classes.premiumText}>
                            {shopItem.approval === "approved" ? "Premium-продавец" : ""}
                             Premium-продавец
                        </span>
                        <span className = {classes.name}>{shopItem.name}</span>
                        <span className = {classes.info}>{shopItem.info}</span>
                    </div>
                    <div className = {classes.likedContainer}>
                    <LikeShop ShopID = {shopItem.id} doWeHaveLike = {true}/>
                    </div>
                </div>
                    
                
                <div className = {classes.boxContainer}>
                    <div className = {classes.ratingContainer}>
                            <Rating name = "read-only-rating" value = {averageEstimate}
                                        // precision = {0.5}
                                        readOnly size = "small"
                                        emptyIcon = {<StarIcon fontSize = "inherit"/>}
                                        style = {{transform: "scale(0.9)"}}
                                />

                                {shopCommentCount > 0 ? (
                                    <span className = {classes.starCount}>{shopCommentCount}</span>
                                ) : (
                                    ''
                                )}
                    </div>
                    
                    <div className = {classes.sharedContainer}>
                        <ShareButton
                            title = {"Поделиться магазином"}
                            callback = {callback}
                        />
                    </div>
                </div>
                </div>
                            
               
             
              
            </div>
        );
    });
    return (
        <div className = {classes.container}>
            {displayShops}  {/* Render the mapped items */}
        </div>
    );
}