export const brandList = [
"Нет бренда",
"A Bathing Ape",
"A Passion Play",
"A&M",
"A-Derma",
"A-RIM",
"A.B.",
"A Jour",
"A&A",
"A&P",
"A-Elita",
"A-Style",
"A.B.S.",
"A LA RUSSE",
"A&C Black",
"A&R",
"A-England",
"Louis Vuitton", "Chanel", "Hermès", "L’Oréal", 
"Dior", "Sephora", "Lancome", "Adidas",
 "Boss", "Nivea", "Puma", "Gucci",
  "Prada", "Benetton", "Bulgari", "Armani",
   "Zara", "H&M", "Cartier", "Swatch",
    "Rolex", "Burberry", "Nike",
  "Apple", "Microsoft", "Google",
   "Facebook", "Amazon", "Coca-Cola",
    "McDonald's", "Toyota", "Ford", "BMW",
     "Audi", "Volkswagen", "Pepsi", "Wal-Mart",
      "Ikea", "Visa", "Mastercard", "Unilever",
       "Procter & Gamble", "Johnson & Johnson",
        "AT&T", "Verizon", "Cisco", "Intel",
         "3M", "GE", "General Motors", "Honda", "Mitsubishi",
          "Tesla", "Samsung", "Sony", "LG", "Canon",
         "Panasonic", "Dell", "IBM", "Lenovo",
          "Toshiba", "Philips", "Nestle",
           "Starbucks", "Heinz", "KFC",
"Domino's", "Subway", "Shell", 
"BP", "ExxonMobil", "Chevron",
"Siemens", "Boeing", "Bayer",
"Ferrari", "Harley-Davidson",
"Tiffany & Co.", "Twitter", "Snapchat",
 "Spotify", "Netflix", "Disney",
  "Pixar", "Warner Bros.",
   "Universal Pictures", "MGM",
    "DreamWorks", "Paramount Pictures",
     "20th Century Fox", "Lamborghini",
      "Bugatti", "Maserati", "Alfa Romeo",
 "Aston Martin", "Rolls-Royce",
  "Bentley", "Jaguar", "Land Rover",
    "Volvo",
     "Kia",
      "Hyundai", "Mazda",
       "Chevy", "Cadillac",
        "Jeep", "Dodge", "Chrysler",
         "Infiniti", "Nissan", "Acura",
          "Lexus", "Porsche", "Subaru",
           "Fiat", "Skoda", "Mini",
            "Suzuki", "Peugeot", "Citroen", 
    "Renault", "Opel", "Seat", "Fossil",
     "Movado", "Omega", "Tag Heuer",
      "Breitling", "Tissot", "Casio",
       "Seiko", "Timex", "Fila",
"Reebok", "Skechers", "New Balance",
 "Converse", "Vans", "Under Armour",
  "Oakley", "Ray-Ban", "Tiffany",
   "Pandora", "Chopard", "Montblanc",
    "Piaget", "Tommy Hilfiger",
     "Calvin Klein", "Michael Kors",
      "Ralph Lauren", "Gap",
       "Abercrombie & Fitch",
        "Old Navy", "Banana Republic",
         "Levi's", "Wrangler",
          "Hollister", "Aeropostale",
           "Forever 21", "Coach",
            "Kate Spade", "Fendi",
             "Tory Burch", "Versace",
              "Moschino", "Dolce & Gabbana",
 "Miu Miu", "Yves Saint Laurent",
  "Givenchy", "Chloe", "Valentino",
   "Marc Jacobs", "Alexander Wang",
    "Oscar de la Renta", "Vera Wang",
     "Christian Louboutin",
      "Jimmy Choo", "Manolo Blahnik",
       "Stuart Weitzman",
        "Roger Vivier",
         "Salvatore Ferragamo",
          "Birkenstock", "Clarks",
           "Steve Madden", "Aldo",
            "Nine West", "Foot Locker",
             "Crocs", "Sandro", "Maje",
              "Karen Millen",
               "Ted Baker", "Paul Smith",
                "G-Star RAW",
                 "Superdry", "Lacoste",
                  "Fred Perry",
                   "Henri Lloyd",
                    "Nautica",
                     "Tommy Bahama", "Rip Curl", "Quiksilver",
                      "Billabong", "Roxy",
                       "Hurley", "O'Neill", "Patagonia",
                        "Columbia", "The North Face",
                         "Marmot", "Arc'teryx",
                          "Mountain Hardwear",
                           "Black Diamond", "Mammut", "La Sportiva",
                            "Helly Hansen", "Merrell", "Salomon", "Lowe Alpine",
                             "Osprey", "Kelty", "Gregory",
 "JanSport", "The Body Shop",
  "Estee Lauder", "Clinique",
   "Mary Kay", "Elizabeth Arden",
    "Revlon", "Max Factor", "Maybelline", "CoverGirl",
     "Almay", "Sally Hansen",
      "Sinful Colors", "Essie",
       "OPI", "Rimmel", "NYX",
        "Urban Decay", "Too Faced",
         "Tarte", "M.A.C.", "Bobbi Brown",
          "Lancome", "Shiseido",
           "Kiehl's", "Aveda",
            "Burt's Bees", "NARS",
             "Bare Minerals", "Benefit",
              "Smashbox", "Stila",
               "Cargo", "Laura Mercier",
                "Josie Maran", "Kat Von D", 
                "Sephora Collection",
                 "Make Up For Ever",
                  "Anastasia Beverly Hills",
                   "Morphe", "BH Cosmetics",
                    "Sigma", "IT Cosmetics",
                     "Tatcha", "Fresh", "Drunk Elephant",
                      "Caudalie", "GlamGlow", "Origins",
                       "Ole Henriksen",
                        "First Aid Beauty", "Dermalogica", "Murad", "Peter Thomas Roth",
                         "StriVectin", "Philosophy",
                          "Clarisonic", "NuFace", "Tria",
 "Foreo", "Dr. Brandt", "Perricone MD",
  "La Mer", "SK-II", "La Prairie",
   "Sisley", "Chantecaille", "Jo Malone",
    "Diptyque", "Acqua di Parma",
     "Creed", "Clive Christian",
      "Bond No. 9", "Byredo", "Le Labo", 
      "Serge Lutens", "Annick Goutal", 
      "Amouage", "Montale", "Tom Ford",
       "By Kilian", "Acqua Di Gio",
        "Cool Water", "Nautica Voyage", 
"Bleu de Chanel", "Yves Saint Laurent La Nuit de l'Homme",
 "Jean Paul Gaultier Le Male",
  "Armani Code", "Dior Homme",
   "Bvlgari", "Paco Rabanne", "Dunhill",
    "Dolce & Gabbana Light Blue", "Thierry Mugler Angel", "Britney Spears Fantasy",
     "Juicy Couture Viva La Juicy",
      "Marc Jacobs Daisy", "Chanel Chance",
       "Chloe Eau de Parfum", "Dior J'adore", 
       "Estee Lauder Pleasures", "Giorgio Armani Acqua di Gioia", "Gucci Bloom", "Hermes Twilly d'Hermes", 
       "Issey Miyake L'Eau d'Issey", "Jimmy Choo", "Jo Malone Wood Sage & Sea Salt", "Kate Spade Live Colorfully",
        "Lancome La Vie Est Belle", "Michael Kors Sexy Amber", "Narciso Rodriguez For Her",
         "Prada Candy", "Ralph Lauren Romance", "Tiffany & Co.", "Tom Ford Black Orchid",
          "Tory Burch", "Vera Wang Princess", "Viktor & Rolf Flowerbomb", "Yves Saint Laurent Black Opium",
           "Bath & Body Works", "Lush", "Sundial Brands",
            "St. Ives", "Nivea", "Vaseline", "Ponds", "Olay", "Neutrogena", "Cetaphil", "CeraVe",
             "Aveeno", "Jergens", "Gold Bond", "Lubriderm",
              "Eucerin", "Dove", "Ivory", "Zest", "Coast",
               "Irish Spring", "Old Spice", "Dial", "Caress", "Lever", "Softsoap", 
               "Dr. Bronner's", "Method", "Mrs. Meyer's",
                "Seventh Generation", "Ecover", "Babyganics", "Honest Company", 
                "Jessica Alba", "Burt's Bees Baby", "Johnson's Baby", "Aveeno Baby",
                 "Mustela",
"Gerber", "Fisher-Price", "Mattel",
 "Hasbro", "LEGO", "Nerf", "Barbie",
  "Hot Wheels", "Tonka", "Play-Doh", "Disney", "Nickelodeon",
   "Cartoon Network", "Warner Bros.",
    "Sony Pictures", "Universal Pictures",
     "20th Century Fox", "Paramount Pictures", "DreamWorks", "Pixar",
      "Lionsgate", "MGM", "BBC", "HBO", "Netflix", "Hulu",
       "Amazon Studios", "Showtime", "Starz", "Coca-Cola",
        "Pepsi", "Dr. Pepper", "Sprite", "Fanta", "Mountain Dew",
         "7-Up", "Gatorade", "Powerade", "Red Bull", "Monster Energy", "Starbucks", 
         "Dunkin' Donuts", "Tim Hortons", "Costa Coffee", "McCafe", "Taco Bell",
          "KFC", "Pizza Hut", "Subway", "McDonald's", "Burger King", 
          "Wendy's", "Chick-fil-A", "Domino's Pizza", "Papa John's",
           "Chipotle", "Olive Garden", "Chili's", "Red Lobster", "Applebee's", "Outback Steakhouse",
            "IHOP", "Denny's", "Cracker Barrel", "Golden Corral", "TGI Friday's",
             "Ruby Tuesday", "LongHorn Steakhouse", "Cheesecake Factory", "Panera Bread", 
             "Five Guys", "In-N-Out Burger", "Shake Shack", "Whataburger", 
             "White Castle", "Carl's Jr.",
              "Jack in the Box", "Sonic Drive-In", "Arby's", 
              "Hardee's", "Jimmy John's", "Dairy Queen", "Baskin-Robbins",
               "Haagen-Dazs", "Ben & Jerry's", "Cold Stone Creamery",
                "Krispy Kreme", "Auntie Anne's", "Cinnabon", "Mrs. Fields",
                 "Little Debbie", "Hostess", "Sara Lee", "Pepperidge Farm",
                  "Oreo", "Ritz", "Triscuit", "Wheat Thins", "Nabisco",
                   "Keebler", "Betty Crocker",
 "Pillsbury", "Duncan Hines", "Campbell's", "Heinz", "Hunt's",
  "Hellmann's", "Best Foods", "French's",
   "Kraft", "Velveeta", "Oscar Mayer", "Ball Park", "Jimmy Dean",
    "Hillshire Farm", "Eckrich", "Perdue", "Tyson", "Foster Farms", "Hormel",
     "Spam", "Omaha Steaks", "Smithfield", "Land O' Lakes", "Tillamook",
      "Sargento", "Philadelphia", "Borden", "Blue Bell",
       "Dannon", "Yoplait", "Chobani", "Fage", "Stonyfield", "Siggi's",
        "Noosa", "Quaker", "Kellogg's", "General Mills", "Post", "Raisin Bran",
         "Cheerios", "Lucky Charms", "Cinnamon Toast Crunch", "Frosted Flakes", "Froot Loops",
          "Apple Jacks", "Corn Pops",
           "Cap'n Crunch", "Life", "Kix", "Trix", "Cocoa Puffs",
 "Golden Grahams", "Rice Krispies", "Shredded Wheat",
  "Grape Nuts", "Wheaties", "Chex",
   "Honey Bunches of Oats", "Special K",
    "Honey Nut Cheerios",
     "Pop-Tarts", "Nature Valley", "Clif Bar", "Kashi",
      "Fiber One", "Nutri-Grain", "Larabar",
       "Quaker Chewy", "KIND", "RXBAR", "Mott's", "Welch's",
        "Ocean Spray", "Tropicana", "Minute Maid",
         "Florida's Natural", "Sunkist", "Nestle", "Hershey's",
          "Mars", "Cadbury", "Lindt", "Ghirardelli", "Godiva", "Toblerone", "Ferrero Rocher", 
          "Kit Kat", "Snickers", "Milky Way", "Twix", "3 Musketeers",
           "M&M's", "Reese's", "Almond Joy", "Butterfinger", "Baby Ruth",
            "Rolo", "Hershey Kisses", "Heath", "Whoppers", "Milk Duds", "Tootsie Roll",
             "Junior Mints", "Sour Patch Kids", "Swedish Fish", "Haribo", "Jelly Belly",
              "Skittles", "Starburst", "Lifesavers", "Altoids", "Tic Tac", "Mentos",
               "Stride", "Trident", "Wrigley", "Orbit", "5",
                "Extra", "Eclipse", "Bubblicious", "Hubba Bubba", "Big Red", "Juicy Fruit",
                 "Doublemint", "Freedent", "Stimorol", "Airwaves", "Winterfresh",
                  "Big League Chew", "Dubble Bubble", "Bubble Yum", "Cry Baby", "Nicorette",
                   "Hall's", "Ricola", "Luden's", "ChapStick",
                    "Blistex", "Carmex", "Vaseline Lip Therapy", "EOS", "Nivea Lip Care", "Softlips", "Maybelline Baby Lips", 
                    "Revlon Kiss Balm", "CoverGirl Oh Sugar", 
                    "Almay Color + Care", "Neutrogena Revitalizing Lip Balm", 
                    "Aveeno Essential Moisture", "Burt's Bees", "Smith's Rosebud Salve",
                     "Aquaphor Lip Repair", "L'Occitane", "Jack Black",
                      "Fresh Sugar", "C.O. Bigelow", "Kiehl's", 
                      "MAC", "Clinique", "Bobbi Brown", "Dior Addict", "YSL", "Lancome", "Estee Lauder",
                       "Chanel", "Guerlain", "Clarins", "Elizabeth Arden", "Shiseido", "Laura Mercier", "NARS", 
                       "Bite Beauty", "Tarte", "Too Faced", "Buxom", "Stila", "Urban Decay", "Smashbox",
                        "Anastasia Beverly Hills", "BareMinerals", "Lorac", "Marc Jacobs", 
                        "Kat Von D", "Becca", "Fenty Beauty", 
                        "ColourPop", "Morphe", "Juvia's Place", "BH Cosmetics",
                         "Jeffree Star", "Lime Crime", "NYX", "Milani", 
                         "Wet n Wild", "e.l.f.", "Rimmel",
                          "CoverGirl", "Almay", "Physicians Formula", "Revlon",
 "Maybelline", "L'Oreal",
 "Magna International",
"Denso",
"Bosch",
"Continental",
"Bridgestone",
"Michelin",
"Goodyear",
"Aisin Seiki",
"Yazaki",
"JTEKT",
"Marelli",
"ZF Friedrichshafen",
"Hyundai Mobis",
"Panasonic Automotive",
"Faurecia",
"Valeo",
"Schaeffler",
"Johnson Controls",
"GKN",
"Cooper Standard",
"Dana",
"Tenneco",
"Autoliv",
"Hella",
"Federal-Mogul",
"Delphi",
"BorgWarner",
"Rheinmetall",
"Garrett Advancing Motion",
"Thyssenkrupp",
"Visteon",
"SKF",
"Dayco",
"Lear",
"TRW Automotive",
"Meritor",
"Boyd",
"Eberspächer",
"Flex-N-Gate",
"Tokai Rika",
"NGK Spark Plugs",
"Knorr-Bremse",
"Hitachi Automotive",
"Bando Chemical",
"Mahle",
"Gentex",
"Exedy",
"Sumitomo Electric",
"Ficosa",
"Aptiv",
"ElringKlinger",
"WABCO",
"Adient",
"Alpine Electronics",
"Trelleborg",
"Samvardhana Motherson",
"Webasto",
"Novares",
"Harman",
"NSK",
"Röchling",
"Stanadyne",
"IHI Turbo America",
"Mando",
"Gestamp",
"Mann+Hummel",
"NTN",
"Nexteer Automotive",
"TE Connectivity",
"Motherson Sumi Systems",
"Osram",
"Keihin",
"Magna Steyr",
"Takata",
"American Axle",
"Tower International",
"Nemak",
"Akebono Brake Industry",
"Calsonic Kansei",
"Gates",
"JATCO",
"Metaldyne",
"Melexis",
"Yanfeng Automotive",
"Cummins",
"Linamar",
"Allegro Microsystems",
"Brose",
"KYB",
"Pioneer",
"Minda Industries",
"HELLA KGaA Hueck & Co."


];



