import React, {useContext, useEffect} from 'react';
import styles from "./ShopRegistration.module.css"
import ColoredButton from '../ProfileMain/components/ColoredButton/ColoredButton';
import ProfileInput from '../ProfileMain/components/ProfileInput/ProfileInput';
import {displaySellerRegistrationsTypes, SellerRegistrationContext} from '../ProfileMain/SellerRegistration';
import {useMutation} from '@apollo/client';
import {ADD_SHOP_WITH_DOCS} from '../../../requests/shopsRequests';
import {CityContext} from '../../../App';
import ProfileTextarea from '../ProfileTextarea/ProfileTextarea';
import Separator from '../../../Сomponents/Separator/Separator';
import ProfileInputWithLinks from '../ProfileMain/components/ProfileInputWithLinks/ProfileInputWithLinks';
import ProfileSelectWithLinks from '../ProfileMain/components/ProfileSelectWithLinks/ProfileSelectWithLinks';


function ShopRegistration() {

    const {
        //general data
        setDisplayType,
        disabled, setDisabled,
        userID,
        //seller registration data

        iNN,
        OGRN,
        name,
        adress,

        //seller bank data

        BIC,
        bankTitle,
        KC,
        banksAccount,

        //shop data
        shopTitle, setShopTitle,
        setNewShopId,
        aboutShop, setAboutShop,
        busines, setBusines,
        taxSystemState, setTaxSystemState,
        deliveryState,
        // brandName, setBrandName

    } = useContext(SellerRegistrationContext)

    // console.log('shopResistration')

    // const {userID} = useContext(TelegramContext);
    // const {deliveriesCityID} = useContext(CityContext);
    // console.log('CITI', deliveriesCityID)
    // const [displayBrendName, setDisplayBrendName] = useState(false)


    useEffect(() => {
        checkAllConditions()
    }, [taxSystemState, shopTitle, busines, deliveryState])


    const typeOfBusiness = [{id: 1, title: "-", value: "-"}, {
        id: 2, title: "Продавец товаров", value: "product_seller"
    }, {id: 3, title: "Продавец услуг", value: "service_seller"}, {
        id: 4, title: "Представитель бренда", value: "brand_representation"
    }]

    const taxSystem = [{id: 1, title: "-", value: "-"}, {id: 2, title: "Общая", value: "general"}, {
        id: 3, title: "Упрощенная", value: "simplified"
    }, {id: 4, title: "Патент", value: "patent"}]

    //ADD_SHOP
    //! addNewShop  don't need anymore
    const [addNewShop] = ''
    // const [addNewShop, {data: id}] = useMutation(ADD_SHOP_WITH_DOCS, {
        // variables: {
            // userID: userID,
            // name: shopTitle,
            // info: aboutShop,
            // city_id: deliveriesCityID,
            // INN: iNN,
            // OGRN: OGRN,
            // company_title: name,
            // company_address: adress,
            // bank_title: bankTitle,
            // bic: BIC,
            // type_of_business: busines,
            // tax_system: taxSystemState,
            // KS: KC,
            // bank_account: banksAccount,
// 
        // },
    // },);


    // const [addNewShopDocs] = useMutation(ADD_SHOP_DOCS, {
    // variables: {
    // INN: iNN,
    // OGRN: OGRN,
    // company_title: name,
    // company_address: adress,
    // bank_title: bankTitle,
    // bic: BIC,
    // type_of_business: busines,
    // shop_id: newShopId,
    // tax_system: taxSystemState
    // },
    // },);


    //callback function
    const shopTitlInputcallback = (value) => setShopTitle(value);
    const aboutShopInputCallback = (value) => setAboutShop(value);
    const typeOfBusinessCallback = (event) => setBusines(event.currentTarget.value);

    const taxSystemCallback = (event) => setTaxSystemState(event.currentTarget.value)
    // const brendNameCallback = (value) => setBrandName(value)

    //change city
    const deliveryStateCallback = () => setDisplayType(displaySellerRegistrationsTypes.cityData);


    //callback onSubmit
    const handleSubmit = async () => {
        // console.log("Записываем shop data")

        const {data} = await addNewShop()
        if (data) {
            setNewShopId(+data.insert_shop.returning[0].id)
            setDisplayType(displaySellerRegistrationsTypes.success)
        }
    }


    const checkAllConditions = () => shopTitle !== "" && busines !== "" && taxSystemState !== "" && deliveryState !== "" && setDisabled(false)


    // console.log(busines, taxSystemState)

    return (<div className = {styles.wrapper}>

        <h3 className = {styles.title}>Регистрация продавца</h3>
        <p className = {styles.text}>данные о магазине</p>


        <Separator/>

        <div className = {styles.form}>

            <ProfileInput label = {"Название магазина:"}
                          type = {"text"}
                          value = {shopTitle}
                          callback = {shopTitlInputcallback}
                          placeholder = {'Введите название магазина...'}/>

            <div style = {{height: "2vh"}}></div>

            <ProfileTextarea label = {"О магазине:"}
                             type = {"text"}
                             value = {aboutShop}
                             callback = {aboutShopInputCallback}
                             placeholder = {'Введите описание магазина...'}/>

            <div style = {{height: "1vh"}}></div>

            <ProfileInputWithLinks label = {"Город отправки продукции:"}
                                   type = {"text"}
                                   readOnly = {true}
                                   placeholder = {"-"}
                                   onClickCallback = {deliveryStateCallback}
                                   value = {deliveryState}
                                   isLinksDisplay = {true}/>

            <ProfileSelectWithLinks label = {"Основной вид деятельности:"}
                                    value = {busines}
                                    values = {typeOfBusiness}
                                    callback = {typeOfBusinessCallback}/>
            <div style = {{height: "2vh"}}></div>


            <div style = {{height: "1vh"}}></div>

            <ProfileSelectWithLinks label = {"Система налогообложения:"}
                                    values = {taxSystem}
                                    value = {taxSystemState}
                                    callback = {taxSystemCallback}/>

            <div style = {{height: "2vh"}}></div>

            <ColoredButton name = {'Далее'} disabled = {disabled} callback = {handleSubmit}/>

            <div style = {{height: "60px"}}></div>
        </div>
    </div>);

}

export default ShopRegistration;


