import {gql} from "@apollo/client";

export const CHECK_USEFUL_COMMENT = gql`
    query checkUsefulComment ($comment_id: Int, $userID: bigint) {
        comment_likes (where: {user_id: {_eq: $userID}, comment_id: {_eq: $comment_id}}) {
            likes
        }
        user(where: {user_id: {_eq: $userID}}) {
            full_name
        }
    }
`;

export const ADD_USEFUL_COMMENT = gql`
    mutation addUsefulComment($comment_id: Int, $answer: Boolean, $usefulness: Int,$userID: bigint) {
        insert_useful_comment(objects: {answer: $answer, comment_id: $comment_id, usefulness: $usefulness, user_id: $userID}) {
            affected_rows
        }
    }
`;

export const ADD_COMPLAINT = gql`
    mutation addComplaint(
        
        $reason: String
        $status: String
        $textValue: String
    ) {
        insert_complaint_comment(
            objects: {
                
                reason: $reason
                status: $status
                text: $textValue
            }
        ) {
            affected_rows
        }
    }
`;


export const GET_ALL_ITEM_COMMENTS = gql`
    query getUserReviews($userID: bigint) {
        order(where: {user_id: {_eq: $userID}, status: {_eq: "completed"}}) {
            id
            order_items {
                id
                price
               
                item {
                    id
                    name
                    comment_items(where: {user_id: {_eq: $userID}}) {
                        advantages
                        status
                        disadvantages
                        estimate
                        id
                        text
                        updated_on
                        modifiers_id
                        order_id
                        anonim
                        comment_likes {
                            date
                            likes
                            user_id
                            comment_id
                        }
                        complaint_comments {
                            id
                            reason
                            status
                            text
                        }
                        images_comments {
                            id
                            tg_id
                            url
                            comment_id
                        }
                    }
                    images_items {
                        id
                        item_id
                        prime_queue
                        url
                    }
                    shop {
                        id
                        name
                    }
                }
            }
        }
    }`


export const GET_ITEM_COMMENTS = gql`
    query getItemComments($productId: Int) {
        comment_item(where: {item_id: {_eq: $productId}}) {
            advantages
            status
            disadvantages
            estimate
            id
            text
            updated_on
            
            anonim
                user {
                    full_name
                }
            images_comments {
                url
              }
            comment_likes {
                date
                likes
                user_id
                comment_id
            }
            complaint_comments {
                id
                reason
                status
                text
            }
            item_variant{
                purposeName
                purposeValue
                purposeName2
                purposeValue2
            
            }
            
            
        }
        item(where: {id: {_eq: $productId}}) {
            name
            item_media {
                path
            }
         
        }
    }
`;