import React, {useEffect, useState} from 'react';
import classes from './AddReviewImages.module.css';
import AddSingleReviewImage from './AddSingleReviewImage';
import addPhoto from '../../../../assets/svg/addPhoto.svg'
import {useMutation} from '@apollo/client';
import {DELETE_IMAGES_COMMENT} from '../../../../requests/commentUserRequests';


export default function AddReviewImages({images, setImages, setIsLoading}) {
    // console.log('AddImages')
    //  console.log("images", images)
    const [imgFile, setImgFile] = useState(null)
    const [localUrl, setLocalUrl] = useState('')


    useEffect(() => {
        imgFile && setImages(
            [...images,
                {
                    file: imgFile,
                    localUrl: localUrl,
                    imgUrl: '',
                    fileName: imgFile.name,
                    id: null
                }])
    }, [localUrl])

    const [deleteImagesComment] = useMutation(DELETE_IMAGES_COMMENT)


    const onChangeImageSrc = async (e) => {
        setIsLoading(true)
        //get new file from input
        const newImageFile = e.target.files[0];
        console.log(newImageFile)

        if (newImageFile && newImageFile.size <= 1024 * 1024) { // limit size to 1MB

            //here we can check length of images side
            // accept only PNG and JPEG formats
            setImgFile(newImageFile);
            setLocalUrl(URL.createObjectURL(newImageFile));

            const formData = new FormData();
            formData.append('files', newImageFile); // Make sure to use 'files' instead of 'image'
            const url = `https://api.jbspace.xyz/files/comment_photo`
            try {
                //setMessage("Файл загружается...")
                const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Image uploaded successfully:', data);
                    setImages([...images, {
                        file: newImageFile,
                        localUrl: URL.createObjectURL(newImageFile),
                        imgUrl: data.urls[0],
                        fileName: data.filenames[0],
                        id: null
                    }])
                    setIsLoading(false)

                    // Process the response data here
                } else {

                    new Error(`Upload image failed with status ${response.status}`);
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Error:', error);
                // Handle the error here
            }


        } else {
            alert('File size should not exceed 1MB!');
        }
    };

    const deleteImageFromServer = async (fileName) => {
        setIsLoading(true)
        const url = `https://api.jbspace.xyz/files/delete_comment_photo/${fileName}`
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                //  body: formData,
            });

            if (response.ok) {
                // const data = await response.json();
                //console.log('Image deleted successfully:', data);
                setIsLoading(false)
                // Process the response data here
            } else {
                new Error(`Upload failed with status ${response.status}`);
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error:', error);
            // Handle the error here
        }
    };

    const handleDeleteImage = (index) => {
        //  console.log(index)

        deleteImageFromServer(images[index].fileName)
        
        if (images[index].id) {
            deleteImagesComment({variables: {id: images[index].id}})
        }

        const newArr = [...images]
        delete newArr[index]
        setImages(newArr.filter(el => el !== undefined))
    }
    // console.log(images)

    return (
        <div className = {classes.wrapper}>
            {images.length > 0 && images.map((image, index) =>
                <AddSingleReviewImage key = {`images-${index}`}
                                      image = {image}
                                      index = {index}
                                      handleDeleteImage = {handleDeleteImage}
                />
            )}

            {images.length < 10 && <div className = {classes.addImageContainer}>
                <label className = {classes.container}>
                    <img src = {addPhoto} alt = {'alt'}/>
                    <input type = "file" accept = "image/*" onChange = {() => setImgFile(null)}
                           onInput = {onChangeImageSrc} style = {{display: "none"}}/>
                </label>
            </div>}

        </div>);
};


