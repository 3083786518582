import React, {useContext, useState} from 'react';
import s from "./ProfileDelete.module.css"
import {TelegramContext} from '../../../../App';
import {useNavigate} from 'react-router-dom';
import ColoredButton from '../components/ColoredButton/ColoredButton';
import ProfilePhoneApplyCode from '../Phone/ProfilePhoneApplyCode/ProfilePhoneApplyCode';
import {useMutation} from '@apollo/client';
import {DELETE_USER_PROFILE} from '../../../../requests/requests';
import SettingHeader from '../ProfileSetting/SettingHeader/SettingHeader';
import {ProfileSettingContext} from '../ProfileSetting';
import Separator from '../../../../Сomponents/Separator/Separator';
import HeaderMinimize from '../../../Header/HeaderMinimize';
import HeaderSearchOnly from '../../../Header/HeaderSearchOnly';


function ProfileDelete({
                           phone,
                           readOnly,
                           headerCallback,
                           verificationCode,
                           setVerificationCode,
                       }) {

    const {userID} = useContext(TelegramContext);
    // const {verificationCode, setVerificationCode} = useContext(ProfileSettingContext)

    const navigate = useNavigate()


    const [isConfirm, setIsConfirm] = useState(false)

    const [deleteUserProfile] = useMutation(DELETE_USER_PROFILE, {variables: {userID}});
    const deleteProfileHandler = () => {
        deleteUserProfile()
        navigate("/profile/",)
    }

    const headerCallbackHandler = () => headerCallback()

    return (
        <div className = {s.wrapper}>
            {/*<SettingHeader title = {"Удаление аккаунта"} callback = {headerCallbackHandler}/>*/}
            <HeaderSearchOnly/>
            <div className = {s.greeting}>
                <h3 className = {s.title}>Удаление аккаунта</h3>
            </div>

            <Separator/>

            <ProfilePhoneApplyCode
                isConfirm = {isConfirm}
                setIsConfirm = {setIsConfirm}
                phone = {phone}
                phoneError = {false}
                verificationCode = {verificationCode}
                setVerificationCode = {setVerificationCode}
                readOnly = {true}
                label = {"Код для удаления"}
            />
            <div style = {{padding: "40px 15px 40px"}}>
                <ColoredButton name = {"Удалить аккаунт"} disabled = {!isConfirm} callback = {deleteProfileHandler}/>
            </div>

            <div style = {{height: "60px"}}></div>
        </div>
    );
}

export default ProfileDelete;