import React, {useContext, useEffect, useState} from 'react';
// import {useMutation, useQuery} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { TelegramContext } from '../../App';
// import {DELETE_CART_ITEM, GET_ALL_LOTS, GET_USER_CART, UPDATE_QUANTITY} from '../../requests/cartRequests';
// import ShoppingItem from './CartItem';
// import { render } from 'react-dom';
// import Header from "../Header";
import styles from './CartPage.module.css'
import arrowIcon from '../../assets/svg/arrow.svg'
// import favouriteIcon from '../../assets/svg/favourite.svg'
import minusIcon from '../../assets/svg/minus.svg'
import plusIcon from '../../assets/svg/plus.svg'
import ItemCheckbox from "./ItemCheckbox";
// import useCart from "../AddElement/useCart";
// import {SHOPS_LIKES} from "../../requests/likesRequests";
// import { ModalContext } from '../../App';
import {amountDynamic} from "../Footer/Backet/Backet";

import {
  createOrder,
  fetchUserCart,
  removeItemFromCart,
  updateCartItemQuantity,
  saveCartIds
} from "../../features/cartSlice";
import {useDispatch, useSelector} from "react-redux";


// import cartAddress from '../UserAddresses/cartAddress'
import CartAddress from '../UserAddresses/cartAddress';
import {getItemEconomy, getTotalEconomy} from "../../utils/orderUtils";
// import { Co2Sharp } from '@mui/icons-material';

const groupItemsByShop = (data) => {

  // console.log('data', data)

  return data.reduce((acc, item) => {
    const { shop, lot: { price, status }, quantity, ...restItemData } = item
    const { id, ...restShopData } = shop
    if (acc[id] && acc[id]?.items) {
      acc[id].items.push({ ...restItemData, price, quantity, status })
      acc[id].totalPriceForShop += price * quantity
    } else {
      acc[id] = restShopData
      acc[id].items = [{ ...restItemData, price, quantity, status }]
      acc[id].totalPriceForShop = price * quantity
    }
    // console.log('acc', acc)
    return acc
  }, {})
}

const CartItem = ({ item, price, quantity, shopName, shopId, selectedItems, setSelectedItems, 
                    userID, id, refetch, cashback, modifiers,

                  }) => {


                    
  const dispatch = useDispatch()

  // console.log('item', item)

  const { item_media, name, step, lots} = item

  const [userQuantity, setQuantity] = useState(quantity || step)

 

  const isSelected = !!selectedItems.find((itemId) => itemId.toString() === id.toString())
  const tempImg = item_media[0]?.path || 'https://images.jbspace.xyz/items/photo/icon-image-not-found-free-vector.jpg'

  const selectItemHandler = () => {
     // check quantity becouse it can be less then step after when user try to buy last items on lots 
    if (quantity !== undefined && step !== undefined && quantity < step) {
      console.log('update to ensure minimum step');
      dispatch(updateCartItemQuantity({ itemID: item.id, amount: step, cartID: id, userID }));
      setQuantity(step)
    }
    if (isSelected) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id))
    } else {
      setSelectedItems([...selectedItems, id])
    }
  }

  const changeQuantity = (amount) => {
    if(userQuantity + amount < step) {
      return
    }
    setQuantity(userQuantity + amount)
    dispatch(updateCartItemQuantity({ itemID: item.id, amount: quantity + amount, cartID: id, userID }))
  }

  const handleInputChange = (value) => {
        // Directly update inputValue state to allow free text entry
        setQuantity(value);
    };

    const handleInputCommit = (value) => {
        const newQuantity = parseInt(value, 10);
    
        const validQuantity = !isNaN(newQuantity) && newQuantity >= step ? newQuantity : step;
        setQuantity(validQuantity);
        setQuantity(validQuantity.toString());
    
        if (!isNaN(newQuantity) && newQuantity >= step) {
            dispatch(updateCartItemQuantity({ itemID: item.id, amount: validQuantity, cartID: id, userID }));
        }
    };



  const deleteItemHandler = async () => {

    await dispatch(removeItemFromCart({ cartIDs: [id] }))
    setSelectedItems(selectedItems.filter((itemId) => itemId !== id))
    amountDynamic(amountDynamic() - 1)
  }

  const fullPrice = price * quantity
  const bonus = Math.round(fullPrice * (+cashback / 100))
  const economy = getItemEconomy({ price, quantity, cashback, lots })

  const allModifiers = Object.values(modifiers).join(', ')

  return (
    <div className={styles.itemRoot}>
      {<ItemCheckbox isChecked={isSelected} onClick={selectItemHandler} />}
      <div className={styles.itemImage}>
        <img src={tempImg} alt="Item " />
      </div>
      <div className={styles.itemMain}>
        <div className={styles.itemInfo}>
          <div className={styles.info}>
            <span className={styles.itemCost}>{price} &#8381; за 1 шт.</span><br/>
            <span className={styles.totalCost}>Итого: {fullPrice} &#8381;</span><br/>
            {!!economy && <span className={styles.discount}>Выгоднее на {economy} &#8381;</span>}
            {!!economy && <br/>}
            {!!bonus && <span className={styles.bonuses}>+{bonus} бонусов {shopName}</span>}
            <span className={styles.itemName}>{name}</span>
          </div>
          {/* <div className={styles.like}> 

            <img src={favouriteIcon} alt="Like"/>
          </div>*/}
        </div>
        <div className={styles.itemControls}>
          <span className={styles.about}>{allModifiers}</span>
          <span className={styles.progressTime}>{`21 дн. 4 ч. 8 мин.`}</span>
          <span className={styles.progress}>{`Прогресс: 155+5(160)/500`}</span>
          <div className={styles.buttonsBlock}>
            <span className={styles.deleteItem} onClick={deleteItemHandler}>Удалить товар</span>
            <div className={styles.counter}>
              <img src={minusIcon} alt="Minus" onClick={() => changeQuantity(-1)} />
              {/* <span>{quantity}</span> */}
              <input
                                type="number"
                                placeholder='0'
                                value = {userQuantity}
                                onChange={(e) => handleInputChange(e.target.value)}
                                onBlur={(e) => handleInputCommit(e.target.value)} // Commit changes on blur
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleInputCommit(e.target.value); // Commit changes on Enter
                                    }
                                }}
                                className={styles.inputText}
                            />
              <img src={plusIcon} alt="Plus" onClick={() => changeQuantity(1)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const renderShopsList = ({ groupedData, selectedItems, setSelectedItems, userID, refetch,
          }) => {
  return Object.entries(groupedData).map(([shopId, shopData]) => {
    const { name, items, shop_bonus } = shopData
    const totalCashback = shop_bonus.reduce((acc, { cashback }) => acc + cashback, 0)

    // console.log('items', items)

    return (
      <div key={shopId} className={styles.shopBlock}>
        <div className={styles.shopHeader}>
          <span className={styles.shopName}>{name}</span>
          <span className={styles.shopRating}>Рейтинг товаров:</span>
        </div>
        {items.map((item) =>

          <CartItem
            {...item}
            cashback={totalCashback}
            shopName={name}
            shopId={shopId}
            userID={userID}
            key={item.id}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            refetch={refetch}
          />
          )}
      </div>
    )
  })
}

const getSelectedItemsPrice = ({ items, selectedItems }) => {
  return items.reduce((acc, { price, quantity, id }) => {
    if (!selectedItems.includes(id)) {
      return acc
    }
    return acc + (price * quantity)
  }, 0)
}

const getTotalBonus = (groupedData, selectedItems) => {
  return Object.values(groupedData).reduce((acc, { shop_bonus, items }) => {
    const selectedItemsPrice = getSelectedItemsPrice({ items, selectedItems })
    const totalCashback = shop_bonus.reduce((acc, { cashback }) => acc + cashback, 0)
    const bonusCount = Math.round(selectedItemsPrice * (totalCashback / 100))
    return acc + bonusCount
  }, 0)
}

const renderBonusesCards = ({ groupedData, selectedItems }) => {
  const totalBonus = getTotalBonus(groupedData, selectedItems)
  return !!totalBonus && (
    <div className={styles.addedBonuses}>
      <div className={styles.bonusCardsRoot}>
        <span className={styles.bonusCardsTotal}>Будет начислено {totalBonus} бонусов от продавца:</span>
        <div className={styles.bonusCardWrapper}>
          {Object.entries(groupedData).map(([shopId, shopData]) => {
            const { name, shop_bonus, items } = shopData
            const selectedItemsPrice = getSelectedItemsPrice({ items, selectedItems })
            const totalCashback = shop_bonus.reduce((acc, { cashback }) => acc + cashback, 0)
            const bonusCount = Math.round(selectedItemsPrice * (totalCashback / 100))
            return !!bonusCount && (
              <div className={styles.bonusCard} key={`${shopId}_bonus`}>
                <span className={styles.bonusCardName}>{name}</span>
                <span className={styles.bonusCardCount}>{bonusCount} бонусов</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}




export default function CartPage() {
  const [selectedItems, setSelectedItems] = useState([])
  const [activeButton, setActiveButton] = useState(true)
  const [orderError, setOrderError] = useState(null);

  const navigate = useNavigate();
  const { userID } = useContext(TelegramContext);

  


  // console.log('userID', userID)


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserCart(userID)).then((action) => {
        // console.log('action', action)
    })
  }, [])

  



  const { isLoading, items, error } = useSelector(state => state.cart)

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    console.error(error)
    return <div>{JSON.stringify(error)}</div>;
  }


  if(!userID) {
    navigate('/v1/login');
    return;
  }

  const groupedData = groupItemsByShop(items)
  const totalDiscount = getTotalEconomy(items.filter(({ id }) => selectedItems.includes(id)))


  const handlePaymentButtonClick = async () => {

    if (!selectedItems.length) {
      return
    }

    setActiveButton(false);

    dispatch(saveCartIds(selectedItems));

    dispatch(createOrder({ userID, cartIDs: selectedItems })).then((action) => {
      // console.log('dispatched createOrder action', action )
      if (action.payload.status === 'error') {
        setOrderError(action.payload); 
        setActiveButton(true); // Keep button disabled until user makes a decision
        return; 
      }
      navigate('/payment');
    })
      .catch((error) => {
        //  handle any error,  notify the user
        console.error("Order creation failed:", error);
        // throw new Error('Insufficient products for order');
        
        // alert("Order creation failed:");
      })
      .finally(() => {
          setActiveButton(true);
      });
    
  };

  const selectAllHandler = () => {
    if (selectedItems.length !== items.length) {
      setSelectedItems(items.map(({ id }) => id))

    } else {
      setSelectedItems([])
    }

  }

  const deleteSelectedHandler = async () => {
    await dispatch(removeItemFromCart({ cartIDs: selectedItems }))
    amountDynamic(amountDynamic()- selectedItems.length)

    setSelectedItems([])
  }

  const [totalItemsCount, totalItemsPrice] = selectedItems.reduce(([itemsCount, itemsPrice], itemId) => {


    const { quantity, itemPrice } = items.find(({ id }) => id === itemId) || {}
    if (quantity === undefined || itemPrice === undefined) {
      return [itemsCount, itemsPrice]
    }

    
    return [itemsCount + quantity, itemsPrice + (quantity * itemPrice)]
  }, [0, 0])



  const handleRetry = (orderError) => {
    // console.log('Retrying...', orderError);
    if (orderError.remains === 0) {
      alert('Недостаточно товаров для заказа')
      return;
    }
    dispatch(updateCartItemQuantity({
      itemID: orderError.item_id, 
      amount: orderError.remains, 
      cartID: orderError.cart_id, 
      userID: userID
    }))
    .then((action) => {
      // console.log('action YYYYYYYYYYYYYYYyy', action); 
      handlePaymentButtonClick();
    })
    .catch((error) => {
      // Optionally handle any errors that occur during the retry
      console.error('Retry failed:', error);
      dispatch(updateCartItemQuantity({
        itemID: orderError.item_id, 
        amount: orderError.quantity, 
        cartID: orderError.cart_id, 
        userID: userID
      }))
      // setOrderError('Retry failed. Please try again.');
      setActiveButton(true);
    })
    .finally(() => {
      setOrderError('');
    });
  };

  const handleAlternative = (orderError) => {

      dispatch(updateCartItemQuantity({
        itemID: orderError.item_id, 
        amount: orderError.quantity, 
        cartID: orderError.cart_id, 
        userID: userID
      }))
      .catch((error) => {
        // Optionally handle any errors that occur during the retry
        console.error('alternative failed:', error);
        // setOrderError('Retry failed. Please try again.');
        setActiveButton(true);
      })
      .finally(() => {
        setOrderError('');
      });
  };

  return (
    


    <div className={styles.root}>
      {orderError && (
                <>
                    <div className={styles.overlay}></div>
                    <div className={styles.errorModal}>
                        <p>Вы пыталься совершить покупку товара: "{orderError.item_name}",
                            к сожелению требуемого количества {orderError.quantity}  не осталось.
                             вы можете заказать товар в количесте  {orderError.remains} единиц.</p>
                        <p> Общая стоимость этого товара будет {orderError.price * orderError.remains}</p>

                        <div className={styles.errorButtonContainer}>
                          <button onClick={() => {handleRetry(orderError)}}>Офоримть заказ</button>
                          <button onClick={() => {handleAlternative(orderError)}}>Отменить</button>
                        </div>
                    </div>
                </>
            )}
      <h3 className={styles.pageTitle}>Корзина</h3>
      <div className={styles.addressSelection}>
        <div className={styles.addressText}>
          <span className={styles.addressTitle}>Адрес доставки:</span>
          <span className={styles.addressSelect}>
            <CartAddress />
          </span>
        </div>
        <img src={arrowIcon} alt="Arrow"/>
      </div>
      <div className={styles.allItems}>
        <span className={styles.selectAll} onClick={selectAllHandler}>Выбрать все ({items.length})</span>
        <span className={styles.removeChosen} onClick={deleteSelectedHandler}>Удалить выбранные ({selectedItems.length})</span>
      </div>
      <div className={styles.shopsList}>
        {renderShopsList({ groupedData, selectedItems, setSelectedItems, userID  })}
      </div>
      
      {renderBonusesCards({ groupedData, selectedItems })}
      <div className={styles.totalSection}>
        <span className={styles.totalHeader}>Итог:</span>
        <div className={styles.totalCosts}>
          <div className={styles.totalItems}>
            <div className={styles.totalItemsCount}>
              <span className={styles.totalItemsDiff}>Наименований: {selectedItems.length}шт</span>
              <span className={styles.totalItemsAll}>Количество товаров: {totalItemsCount}шт</span>
            </div>
            <span className={styles.totalItemsPrice}>{totalItemsPrice +  totalDiscount} &#8381;</span>
          </div>
          {!!totalDiscount && 
          <div className={styles.totalDiscount}>
            <span className={styles.totalDiscountTitle}>Скидка за объем:</span>
            <span className={styles.totalDiscountSum}>{`${totalDiscount}`} &#8381;</span>
          </div> }
          <div className={styles.totalDivider} />
          <div className={styles.finalCost}>
            <span className={styles.finalCostTitle}>Общая стоимость:</span>
            <span className={styles.finalCostSum}>{totalItemsPrice} &#8381;</span>
          </div>
        </div>

        {!activeButton ? 
                <span className={`${styles.proceedBtn} ${styles.disabled}`}>
                  Обработка запроса
                </span>
              :
                <span
                  onClick={() => {
                    if (selectedItems.length) {
                      handlePaymentButtonClick();
                    }
                  }}
                  className={`${styles.proceedBtn} ${selectedItems.length ? '' : styles.disabled}`}
                >
                  Перейти к оформлению
                </span>
              }
      </div>
    </div>
  );
}


