import styles from './Variants.style.module.css';

import { useState, useEffect } from 'react';

import { useForm, useFieldArray} from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {  useMutation } from '@apollo/client';
import { SAVE_ITEM } from '../../../../requests/itemRequests';
import { setVariants } from '../../../../features/createItemSlice';
import { useNavigate } from 'react-router-dom';

import {  sendBotMessage, sendOnModeration } from '../../../../utils/apiCalls/botApi'

import backArrow from '../../../../assets/png/backArrow.png';


export const MAX_VARIANTS = 10; 





function handleDeletePurpose({value, 
    variant, 
    variant2, 
    setVariant, 
    setVariant2, 
    setVarinatsError,
    setVariantsInfo,
    }) {


    if (variant  && variant2) {
    if (value === 'first') {
    setVariant(variant2);
    setVariant2('');
    setVarinatsError(false);

    }else{
    setVariant2('');
    setVarinatsError(false);
    }
    }else{
    setVariantsInfo(null);

    };
}



function MoreVariants({register, 
                        errors, 
                        fields, 
                        append, 
                        remove, 
                        watch, 
                        getValues,
                        variant,
                        setVariant,
                        setVariant2,
                        setVarinatsError,
                        variant2,
                        imageUrls,
                        setImageUrls,
                        setVariantsInfo,
                    }) {

   
    

    function handleChangeImage(e, index) {
        const file = e.target.files[0];
        const url =  `https://api.jbspace.xyz/files/item_photo`;
          
        if (!file) {
            console.log('Uploader: No file selected!');
            return;
          }

        const formData = new FormData();
        formData.append('files', file);

        fetch(url, { method: 'POST', body: formData })
        .then((response) => {
            if (response.ok) return response.json();
            throw new Error('Error while uploading');
        }).then((data) => {
            let newImageUrls = [...imageUrls];
                        newImageUrls[index] = data.url[0];
                        setImageUrls(newImageUrls);
        }
        ).catch((error) => {
            console.error('Error:', error);
        }
        );
    }

    useEffect(() => {
        // Initialize with two variants
        append({
          articleNumber: '',
          length: '',
          width: '',
          height: '',
          weight: '',
          purposeName: '',
          image: ''
        });
        append({
          articleNumber: '',
          length: '',
          width: '',
          height: '',
          weight: '',
          purposeName: '',
          image: ''
        });
      }, []); 



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }        


    return (
        
             <div className={styles.variantsContainer}> 
             
    
                {fields.map((field, index) => {

                    return (
                        <div key={field.id} style={{ marginTop: '30px' }}>
                            {/* Variant 2 Section */}
                            <section className={styles.purposeContainer}>
                                <div className={styles.labelContainer}>
                                    <span className={styles.label}>Вариант {index + 1}</span>
                                    {index === 0 ? (
                                      <span className={styles.purposeLabel}>Исходный</span>
                                    ) : (
                                      <span className={styles.deletePurpose} 
                                                onClick={() => remove(index)}>Удалить вариант</span>
                                    )}
                                </div>
    
                                <div className={styles.imageContainer}>
                                    <div className={styles.avatarContainer}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className={styles.newAvatar}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => {
                                                handleChangeImage(e, index);
                                            }
                                            }
                                            // {...register(`purpose.${index}.image`, {required : true})}
                                        />
                                        {imageUrls[index] && <img src={imageUrls[index]} alt="" className={styles.avatar} />}
                                    </div>
    
                                    <div className={styles.imageInfo}>
                                        <span className={styles.imageLabel}>Фото варианта</span>
                                        <span className={styles.imageRequired}> Обязательно </span>
                                        <span className={styles.imageText}>1 фотография в формате PNG, JPEG</span>
                                    </div>
                                </div>
                            </section>




                            <form className={styles.formInfo}>
                                <div className={styles.formContainer}>
                                    <div className={styles.formLabel}>Артикул:</div>
                                    <input
                                        className={styles.input}                                        
                                        type="text"
                                        placeholder={'Обязательно'}
                                        onKeyDown={handleKeyDown}
                                        error={errors.articleNumber}
                                        {...register(`purpose.${index}.articleNumber`, {required: true, minLength: 2, maxLength: 30})}
                                    />
                                </div>

                                <div className={styles.sizeContainer}>
                                    <div className={styles.sizeField}>
                                        <div className={styles.sizeLabel}>Длинна упаковки (мм):</div>
                                            <input 
                                                className={styles.input}
                                                type="number"
                                                placeholder="Обязательно"
                                                onKeyDown={handleKeyDown}
                                                error={errors.length}
                                                {...register(`purpose.${index}.length`, {required: true, valueAsNumber: true,})}
                                            />
                                        </div>
                                        <div className={styles.sizeField}>
                                            <div className={styles.sizeLabel}>Ширина упаковки (мм):</div>
                                            <input 
                                                className={styles.input}
                                                type="number"
                                                placeholder="Обязательно"
                                                onKeyDown={handleKeyDown}
                                                error={errors.width}
                                                {...register(`purpose.${index}.width`, {required: true, valueAsNumber: true,})}
                                            />
                                        </div>
                                        <div className={styles.sizeField}>
                                            <div className={styles.sizeLabel}>Высота упаковки (мм):</div>
                                            <input 
                                                className={styles.input}
                                                type="number"
                                                placeholder="Обязательно"
                                                onKeyDown={handleKeyDown}
                                                error={errors.height}
                                                {...register(`purpose.${index}.height`, {required: true, valueAsNumber: true,})}
                                            />
                                        </div>
                                        <div className={styles.sizeField}>
                                            <div className={styles.sizeLabel}>Вес (г):</div>
                                            <input 
                                                className={styles.input}
                                                type="number"
                                                placeholder="Обязательно"
                                                onKeyDown={handleKeyDown}
                                                error={errors.weight}
                                                {...register(`purpose.${index}.weight`, {required: true, valueAsNumber: true, })}
                                            />
                                        </div>

                                    </div>
                                    <div className={styles.formContainer}>  
                                        <div className={styles.labelContainer}>                                  
                                        <div className={styles.formLabel}>{variant}:</div>
                                        {index === 0 && (
                                            <div className={styles.deletePurpose} onClick={() => handleDeletePurpose({
                                                  value: 'first',
                                                  variant: variant,
                                                  variant2: variant2,
                                                  setVariant: setVariant,
                                                  setVariant2: setVariant2,
                                                  setVarinatsError: setVarinatsError,
                                                  setVariantsInfo: setVariantsInfo,
                                                })}>
                                                  Удалить свойство
                                                </div>
                                            )}
                                        </div>
                                    <input className={styles.input}
                                        type="text"
                                        placeholder="Обязательно"
                                        onKeyDown={handleKeyDown}

                                        error={errors.variant}
                                        {...register(`purpose.${index}.variantValue`, {required: true, minLength: 2, maxLength: 30})}
                                        />
                                    </div>

                                        {errors.variant && <span className={styles.error}>Обязательно </span>}
                                    
                                    {variant2 && 
                                    <>
                                    <div className={styles.formContainer}>  
                                    <div className={styles.labelContainer}>                                  
                                        <div className={styles.formLabel}>{variant2}:</div>
                                        {index === 0 && (
                                            <div className={styles.deletePurpose} onClick={() => handleDeletePurpose({
                                                  value: 'first',
                                                  variant: variant,
                                                  variant2: variant2,
                                                  setVariant: setVariant,
                                                  setVariant2: setVariant2,
                                                  setVarinatsError: setVarinatsError,
                                                  setVariantsInfo: setVariantsInfo,
                                                })}>
                                                Удалить свойство
                                              </div>
                                            )}
                                    </div>
                                    <input className={styles.input}
                                        onKeyDown={handleKeyDown}

                                        type="text"
                                        placeholder="Обязательно"
                                        {...register(`purpose.${index}.variantValue2`, {required: true, minLength: 2, maxLength: 30})}
                                        />
                                    </div>

                                        {errors.variant && <span className={styles.error}>Обязательно </span>}
                                    </>  
                                    }
                                </form>
                        </div>
                    );
                })}
    
                {fields.length < MAX_VARIANTS && (
                <span 
                    className={styles.addVariant}
                    role="button"
                    onClick={() => append({
                        articleNumber: '',
                        lenght: '',
                        width: '',
                        height: '',
                        weight: '',
                        purposeName: '',
                        image: ''
                    })}
                >
                    Добавить вариант
                </span>
                )}
            </div>
        
    );
                    }




function createMedaiArray(itemMedia) {
    const mediaArray = [];

    if (itemMedia.image && itemMedia.image.length > 0) {
        itemMedia.image.forEach(img => {
          mediaArray.push({ path: img, type: 'image' });
        });
      }
    if (itemMedia.video) {
        mediaArray.push({ path: itemMedia.video, type: 'video' });
      }
    
      if (itemMedia.cover) {
        mediaArray.push({ path: itemMedia.cover, type: 'cover' });
      }
     
        return mediaArray;
}



function createVariantArray(getValues, variant, variant2, imageUrls) {
    const variantsArray = [];

    // Helper function to create a variant object
    const createVariantObject = (el, imageUrl) => ({
        articleNumber: el.articleNumber,
        length: el.length,
        width: el.width,
        height: el.height,
        weight: el.weight,
        purposeName: variant,
        purposeValue: el.variantValue,
        purposeName2: variant2,
        purposeValue2: el.variantValue2,
        image: imageUrl,
    });

    // Loop through each purpose and create the corresponding variants
    getValues().purpose.forEach((el, index) => {
        const variantObject = createVariantObject(el, imageUrls[index]);

        // Push the variant into the variants array
        variantsArray.push(variantObject);
    });

    return variantsArray;
}




function AddVariants({
                        firstImage,
                        setFirstImage, 
                        isMenuOpen,
                        setMenuOpen,
                        setVariant,
                        setVariant2,
                        variant2,
                        variant,
                        setVarinatsError,
                        setVariantsInfo
                         }) {

    const navigate = useNavigate();
    const [imageUrls, setImageUrls] = useState([]); 
        
    const {
            itemInfo,
            shopId,
            itemMedia,
            category, 
                    } = useSelector((state) => state.createItem);  


    const {
            register,
            getValues,
            watch,
            control,
            formState: { errors, isValid},
                  } = useForm({
                        defaultValues: {
                                        variant: variant ,
                                        variant2: variant2 ,
                                        purpose : [{
                                                'articleNumber': itemInfo.articleNumber || '',
                                                 'length': itemInfo.length || '',
                                                 'width': itemInfo.width || '',
                                                 'height': itemInfo.height || '',
                                                 'weight': itemInfo.weight || '',
                                                 'purposeName': '',
                                                 'variantValue': '',
                                                 'variantValue2': '',
                                                 'image': ''
                                        }],
                                    }, 
                          })

    const {fields, append, remove} = useFieldArray({
        name: "purpose",
        control,
        });
                        
    // const dispatch = useDispatch();


    const [saveItem, { data }] = useMutation(SAVE_ITEM);



    async function handleCreateButton(data) {
        // save data to db 
        const variantsArray = createVariantArray(getValues,
            variant, variant2, imageUrls);
        const mediaArray = createMedaiArray(itemMedia);

        const characterVariable = {};
        itemInfo.character.forEach((item) => {
          if (item.characrerName && item.characterValue) {
            characterVariable[item.characrerName] = item.characterValue;
          }
        });



        const variables = {
            about: itemInfo.description,
            brand: itemInfo.brand,
            name: itemInfo.name,
            articleNumber: itemInfo.articleNumber,
            barcode: itemInfo.barcode,
            partNumber: itemInfo.partNumber,
            character: characterVariable,
            country: itemInfo.country,
            waranty: itemInfo.waranty,
            color: itemInfo.color,
            shop_id: shopId,
            item_media: mediaArray,
            item_variants: variantsArray,
            category: category,
            
          };
        if (itemInfo.quantity_box !== '') {
            variables.quantity_box = itemInfo.quantity_box;
          }else{
            variables.quantity_box = 0;
          }
          try {
            const { data: mutationResult } = await saveItem({ variables });
            
            if (mutationResult) {  // Checking the correct variable
                navigate('/goods_storage', { state: { shopId } });
                await sendOnModeration(variables);
            } else {
                alert("An error occurred while saving the item.");
                await sendBotMessage(`проблема с moderation result 0`);

            }
        } catch (error) {
            console.error("An error occurred:", error);
            alert("An error occurred while saving the item.");
            await sendBotMessage(`An error occurred while saving the item. ${error}`);
        }

    }

   

    return (
        <>

        

        <MoreVariants register={register} 
                        watch={watch}
                        errors={errors} 
                        fields={fields} 
                        append={append} 
                        remove={remove} 
                        control={control}
                        getValues={getValues}
                        variant={variant}
                        setVariant={setVariant}
                        setVariant2={setVariant2}
                        setVarinatsError={setVarinatsError}
                        variant2={variant2}
                        imageUrls={imageUrls}
                        setImageUrls={setImageUrls}
                        setVariantsInfo={setVariantsInfo}
                             />
        {isMenuOpen ? 
                        <SecondPurpose isMenuOpen={isMenuOpen}
                                    setMenuOpen={setMenuOpen}
                                    setVariant2={setVariant2}
                                    variant2={variant2}
                                    setVarinatsError={setVarinatsError}
                                    variant={variant}
                                     /> 
                    : 
                    <>
                    <button type="submit" 
                            className={styles.button}
                            disabled={!isValid || getValues().purpose.length !== imageUrls.length }    
                            onClick={async () => {
                                    try {
                                        await handleCreateButton();
                                    } catch (error) {
                                        console.error("An error occurred:", error);
                                    }
                                }}
                            >
                            Создать товар
                        </button>
                    </>
        }
    </>
    
    )
}





function  SecondPurpose ({
                        isMenuOpen, 
                        setMenuOpen, 
                        setVariant2, 
                        variant2, 
                        variant, 
                        setVarinatsError,
                    }){
        
        const{
                register,
                handleSubmit,
                formState: {isValid},
             } = useForm({
                defaultValues: {variant2: ''},
            })
    
        if (variant2 && variant) {
                setMenuOpen(false);
                return setVarinatsError('Можно добавить только 2 свойства');
                } ;
    
        const onSubmit = (data) => {
            // console.log(data);
            setMenuOpen(false);
            setVariant2(data.variant2);
        }

        const clickEvent = (event) => {
            setMenuOpen(false);
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.target.blur();
            }
        }        
    

        
        return (
        <div className={styles.menuContainer}>
                <div
                    className = {styles.closeLineContainer}
                        onClick = {clickEvent}>
                        <div className = {styles.closeLine}></div>
                </div>
            <div className={styles.menuLabel}>Название свойства:</div>
            <div className={styles.menuInfo}>
                Добавьте свойства товара, такие как цвет, размер, материал. 
                    Используйте до 30 символов.
            </div>
            <form className={styles.formInfo} onSubmit= {handleSubmit(onSubmit)}>
                <input className={styles.input}
                    type="text"
                    onKeyDown={handleKeyDown}
                    placeholder="Например: Цвет"
                    {...register('variant2', {required: true, minLength: 2, maxLength: 30})}
                    />
                <button className={styles.addCategory} 
                            disabled={!isValid}
                             type="submit"
                              >Добавить</button>
            </form>
    </div>
        )
    }                    


    

function  LocalModalContent ({isMenuOpen, 
                                setMenuOpen, 
                                setVariantsInfo, 
                                setVariant
                                }){

        // function for show up modal window and add purpose
        // for example color, size, material

        const{
            register,
            handleSubmit,
            formState: { isValid},
        } = useForm({
            defaultValues: {variant: ''},
        })

        const onSubmit = (data) => {
            setMenuOpen(false);
            setVariantsInfo(true);
            setVariant(data.variant);
        }

        const clickEvent = (event) => {
            setMenuOpen(false);
        };
    

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.target.blur();
            }
        }        
    
        return (

        <div className={styles.menuContainer}>
                <div
                    className = {styles.closeLineContainer}
                        onClick = {clickEvent}>
                        <div className = {styles.closeLine}></div>
                        {/* <span className = {styles.checkmark}></span> */}
                </div>
            <div className={styles.menuLabel}>Название свойства:</div>
            <div className={styles.menuInfo}>
                Добавьте свойства товара, такие как цвет, размер, материал. 
                    Используйте до 30 символов.
            </div>
            <form className={styles.formInfo} onSubmit= {handleSubmit(onSubmit)}>
                <input className={styles.input}
                    type="text"
                    onKeyDown={handleKeyDown}
                    placeholder="Например: Цвет"
                    {...register('variant', {required: true, minLength: 2, maxLength: 30})}
                    />
                <button className={styles.addCategory} 
                            disabled={!isValid}
                             type="submit" >Добавить</button>
            </form>
        </div>
        )
    }






function Variants({
                        setDisplayMode,
                        ADD_ITEM_MODES  
                    }) {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [variantsInfo, setVariantsInfo] = useState(false); 
    const [firstImage, setFirstImage] = useState('null')
    const [variant, setVariant] = useState('')
    const [variant2, setVariant2] = useState('')
    const [varinatsError, setVarinatsError] = useState(false)
    
    function handleButton() {
        setMenuOpen(true)
    }

    const navigate = useNavigate();
    

    const { 
        itemInfo,
        shopId,
        itemMedia,
        category,
        } = useSelector((state) => state.createItem);

    const [saveItem, { data }] = useMutation(SAVE_ITEM);

    async function handleCreateButton() {
        const variantsArray ={
            articleNumber: itemInfo.articleNumber,
            length: itemInfo.length,
            width: itemInfo.width,
            height: itemInfo.height,
            weight: itemInfo.weight,
            purposeName: '',
            purposeValue: '',
            purposeName2: '',
            purposeValue2: '',
            image: itemMedia.image[0],
        }
        const mediaArray = createMedaiArray(itemMedia);
        const characterVariable = {};
        itemInfo.character.forEach((item) => {
          if (item.characrerName && item.characterValue) {
            characterVariable[item.characrerName] = item.characterValue;
          }
        });
        const variables = {
            about: itemInfo.description,
            brand: itemInfo.brand,
            name: itemInfo.name,
            articleNumber: itemInfo.articleNumber,
            barcode: itemInfo.barcode,
            partNumber: itemInfo.partNumber,
            character: characterVariable,
            country: itemInfo.country,
            waranty: itemInfo.waranty,
            color: itemInfo.color,
            shop_id: shopId,
            item_media: mediaArray,
            item_variants: variantsArray,
            category: category,
            
          };
        if (itemInfo.quantity_box !== '') {
            variables.quantity_box = itemInfo.quantity_box;
          }else{
            variables.quantity_box = 0;
          }
          try {
            const { data: mutationResult } = await saveItem({ variables });
            
            if (mutationResult) {  // Checking the correct variable
                navigate('/goods_storage', { state: { shopId } });

                await sendOnModeration(variables);
                
                

            } else {
                await sendBotMessage('Ошибка при создании товара');
                
                alert("An error occurred while saving the item.");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            alert("An error occurred while saving the item.");
            await sendBotMessage(`Ошибка при создании товара ${error.message}`);
            
    }
    }


    return (
        <div className={styles.wrapper}>
            <h1 className={styles.header}>Варианты товара</h1>
            <div className={styles.stepContainer}>
                <img className={styles.backArrow} src={backArrow} alt="back" onClick={() => setDisplayMode(ADD_ITEM_MODES.media)} />
                <span className={styles.step} >Шаг 4/4</span>
            </div>

            <div className={styles.infoContainer}>
                <span className={styles.infoText}>
                     Вариант — это товар с определенным набором свойств.
                    Например: двухспальный комплект постельного белья из льна.
                    Свойства товара: размер, материал. 
                    Каждый вариант может иметь отдельные 
                    артикул, цену, количество, габариты и фотографию.
                </span>
            </div>
                <div className={styles.buttonText} onClick={handleButton}>
                    Добавить cвойство
                </div>            

                {varinatsError && <span className={styles.error}>{varinatsError} </span>}

                {
                     variantsInfo ? 
                     <AddVariants 
                                    isMenuOpen={isMenuOpen}
                                    setMenuOpen={setMenuOpen}
                                    setVariant2={setVariant2}
                                    setVariant={setVariant}
                                    variant2={variant2}
                                    variant={variant}
                                    setVarinatsError={setVarinatsError}
                                    firstImage={firstImage}
                                    setFirstImage={setFirstImage}
                                    setVariantsInfo={setVariantsInfo}
                                    />
                    : 
                (
                    isMenuOpen ? 
                        <LocalModalContent isMenuOpen={isMenuOpen} 
                                            setMenuOpen={setMenuOpen}
                                             setVariantsInfo ={setVariantsInfo} 
                                            setVariant={setVariant}
                                            variant={variant}   
                                            variant2={variant2}
                                            setVarinatsError={setVarinatsError}
                                            varinatsError={varinatsError}
                                             />
                    : 
                    <button 
                            type="submit" 
                            className={styles.button} 
                            onClick={async () => {
                                try {
                                    await handleCreateButton();
                                } catch (error) {
                                    
                                    console.error("An error occurred:", error);
                                }
                            }}
                        >
                            Создать товар
                        </button>
                                        )
                    }
        </div>
    )
}




export default Variants;