export  function getVariantWord(count) {
    let word = '';
  
    if (count % 100 < 11 || count % 100 > 14) {
        switch (count % 10) {
            case 1:
                word = 'вариант';
                break;
            case 2:
            case 3:
            case 4:
                word = 'варианта';
                break;
            default:
                word = 'вариантов';
        }
    } else {
        word = 'вариантов';
    }
  
    return word;
  }
      
