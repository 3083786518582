import React, {useState} from 'react'
import { useLocation } from 'react-router-dom';
import styles from './SettingsLots.module.css';
import { useQuery} from '@apollo/client';
import { GET_ITEM_ARCHIVED_LOTS } from '../../../requests/lotsRequests';


import info from '../../../assets/svg/info.svg';
import  greenBox  from '../../../assets/svg/greenBox.svg';
import  blueBox from '../../../assets/svg/blueBox.svg';
import minusIcon from '../../../assets/svg/minus.svg';
import plusIcon from '../../../assets/svg/plus.svg';
import greenClock from '../../../assets/svg/greenClock.svg';
import redClock from '../../../assets/svg/redClock.svg';



function ShowVariants({variants, variantsObject}) {
 
    return(
        <div >
        
        {
            variantsObject.map((variant, index) => {

            if ( variant.lots?.length <= 0) {
                   return null;
                    }
                 else {

          return (
          <div className={styles.wraperVariat} key={index}>
                <div className={styles.row}>
                  <h2 >Вариант {index + 1}</h2>
                  {index === 0 && <span>Исходный</span>}
                </div>
          
              <div className={styles.infoVariant}>
                    <div className={styles.itemImage} key={variant.image}>
                        <img src={variant.image} alt="foto" className={styles.avatar} />
                    </div>
            
                    <div className={styles.variantsInfo}>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Артикул</label>
                            <span className={styles.labelInfo}>{variant.articleNumber}</span>
                        </div>
                        <div style= {{height: '1vh'}}/  >

            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Длина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.length || '-'}</span>
                        </div>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Ширина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.width || '-'}</span>
                        </div>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Высота упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.height || '-'}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Вес упаковки (мм)</label>
                            <span className={styles.textInfo}>{variant.weight || '-'}</span>
                        </div>
                    </div>
              </div>
            
                <div className={styles.characterRow}>
                      {variant.purposeName ? (

                        <div className={styles.character}>
                          <label className={styles.labelInfo}>{variant.purposeName}</label>
                          <span className={styles.textInfo}>{variant.purposeValue}</span>
                        </div>

                      ) : ( 
                        <div className={styles.character}>
                            <label className={styles.labelInfo}>-</label>
                        </div> )}

                      {variant.purposeName2 && (
                        <div className={styles.character}>
                      
                          <label className={styles.labelInfo}>{variant.purposeName2}</label>
                          <span className={styles.textInfo}>{variant.purposeValue2}</span>
                        </div>
                      ) }
                </div>

                   
                     <div>
                        <LotLabel />
                                          
                        
                    <div className={styles.formLots}>
                        
                        
                    {variant.lots.length > 0 ? (
                            variant.lots.map((lot) => (
                                <LotRow 
                                    key={lot.id}
                                    
                                    variant={variant}
                                    lot={lot}
                                />
                            ))
                        ) : (
                            <LotRow variant_id = {variant.id}
                                    variantsObject={variantsObject}
                                    lot={0} />
                        )}
                        
                
                            
                    </div>
                            
                            
                    </div>

                 
          </div>
      )}
          })       

        
        
        }
    </div>

    
  )
}

function random() {
    return Math.floor(Math.random() * 3) + 1;
}



function LotRow({lot, variant, reason = null}) {

    
    // reason need to change  how lot was end 


    console.log("reason", reason)
    reason = reason || random();
    console.log("reason", reason)

    let lotImage;
    

    if (reason === 1) {
        lotImage = greenBox;
    } else if (reason === 2) {
        lotImage = greenClock;
    } else if (reason === 3) {
        lotImage = redClock;
    }
    else {
        lotImage = blueBox;
    }

    const [variantObject, setVariants] = useState(variant);
    const [priceValue, setPriceValue] = useState(lot.price || '');
    const [stepValue, setStepValue] = useState(lot.step || 0);
    const [totalValue, setTotalValue] = useState(lot.total || 0);


    console.log('lot ', lot.ordered_quantity)
    const booking = lot.ordered_quantity ;
    const isDisabled = booking > 0;

    const handleDeleteClick = () => {
        console.log('handleDeleteClick');
        
    };
    const handlePriceChange = (event, lotId) => {
        console.log('handlePriceChange', event.target.value);
        setPriceValue(event.target.value);
    
        const updatedLots = variantObject.lots.map(lot => {
            if (lot.id === lotId) {
                return { ...lot, price: event.target.value };
            }
            return lot;
        });
    
        setVariants({ ...variantObject, lots: updatedLots });
    };

    const handleStepChange = (event) => {
        setStepValue(event.target.value); 
    }

    const handleTotalChange = (event) => {
        setTotalValue(event.target.value); 
    }

    

    
    
    return (
          
        <div className={styles.lotFields}   >
                <div className={styles.labelIcon}>
                    <img 
                        style={{ 
                                    height: '15px', 
                                    width: '13.3px', 
                                    opacity: booking > 0 ? '0.5' :'1.5'  
                                }} 
                        
                        src={lotImage} 
                        alt="deleteItem" 
                        onClick={() => {
                            if (!isDisabled) {
                                handleDeleteClick();
                            } 
                        }}
                    />
                    <input
                        className={styles.inputPrice}
                        value={priceValue}
                        placeholder='См. варианты'
                        onChange={(event) => handlePriceChange(event, lot.id)}
                        disabled={isDisabled}
                    />
                </div>
                            
            <div className={styles.lotsButton}>
                        <div className={isDisabled ? styles.countButtonDisabled : styles.countButton}>
                                <img src = {minusIcon} alt = "minus"  
                                            disabled = {isDisabled}
                                            onClick={() => {
                                            if (!isDisabled) {
                                                handleDeleteClick();
                                            } 
                                        }}
                                            />
                            <input
                                type="number"
                                placeholder='0'
                                value = {stepValue}
                                onChange={handleStepChange}
                                className={styles.buttonText}
                                disabled={isDisabled}
                            />

                            <img src = {plusIcon} alt = "plus" 
                                    onClick={() => {
                                            if (!isDisabled) {
                                                handleDeleteClick();
                                            } 
                                        }}
                                    />
                        </div>
                            
                            <div className={styles.countButton}>
                                <img src = {minusIcon} alt = "minus" 
                                           onClick={() => {
                                            if (!isDisabled) {
                                                handleDeleteClick();
                                            } 
                                        }}

                                            />
                                <input
                                    type="number"
                                    placeholder='0'
                                    value = {totalValue}
                                    onChange={handleTotalChange}
                                    className={ styles.buttonText}
                                    disabled={isDisabled}
                                    
                                    // style={{ width: '16px' }}

                                />
                                <img src = {plusIcon} alt = "plus"
                                    onClick={() => {
                                            if (!isDisabled) {
                                                handleDeleteClick();
                                            } 
                                        }}

                                        />
                            </div>
                            <div className={isDisabled ? styles.bookingItemsDisabled  : styles.bookingItems}>
                                {booking}
                            </div>
                            </div>
            </div>
                                );
}
    

function LotLabel() {
    return (
        <div className = {styles.labelContainer}>
            <div className={styles.rightContainer}>

             <label className = {styles.labelLot}>Цена за шт. </label>
            </div>
            <div className={styles.labelIcon}>
                <label className = {styles.labelLot}>Квант </label>
                <img className={styles.infoIcon} 
                    src = {info} alt = "info" />
            </div>
            <div className={styles.labelIcon}>
                <label className = {styles.labelLot}>Всего </label>
                <img src = {info} alt = "info" />
            </div>
            <div className={styles.labelIcon}>
                <label className = {styles.labelLot}>Продано </label>
                <img src = {info} alt = "info" />
            </div>
        </div>
    )

}

function ItemWithoutVariants({lots, variantsObject}) {

    return (
        <>
        <LotLabel />
        <div className={styles.lotContainer} >

       { lots.map((lot, index) => (
                        <LotRow
                            lot = {lot}
                            key= {index}
                            variantsObject={variantsObject}
                            variant_id={variantsObject[0].id}
                        />
                    ))}
                            
       </div>
       </>
                            
    )
}



function ArchevedLots( ) {
    const location = useLocation();
    const lotsId = location.state.lotsId;
    const [showVariants, setShowVariants] = useState(null);



    const {data, error, loading} = useQuery(GET_ITEM_ARCHIVED_LOTS, {
        variables: { lotsId },
        fetchPolicy: 'cache-and-network',
        });
        if (loading) {
            return 'loading...';
        }
        if (error) {
            console.error('liked items error', error);
            return 'error';
        }
        console.log("data", data)

    
    let item = data.item[0];


    let variantsObject = item.item_variants.map((variant) => {
        return {
            id: variant.id,
            image: variant.image,
            articleNumber: variant.articleNumber,
            length: variant.length,
            width: variant.width,
            height: variant.height,
            weight: variant.weight,
            purposeName: variant.purposeName,
            purposeValue: variant.purposeValue,
            purposeName2: variant.purposeName2,
            purposeValue2: variant.purposeValue2,
            lots: variant.lots,
        }
    }   
    );

    
    

    return (
        <div className={styles.wrapper}>
                <h1>{item.articleNumber}</h1>
            <div className={styles.contentContainer}>
          
            <div className={styles.itemContainer}> 
              <div className={styles.itemImage}>
                <img className={styles.image} src={item.item_media[0].path} alt="itemImage" />
              </div>
              <div className={styles.itemInfo}>
                <span className={styles.itemName}>{item.name}</span>
                <span className={styles.itemArticle}>{item.articleNumber}</span>
                <span className={styles.itemPurposeCount}>2 свойства; 2 варианта;</span>
              </div>
            
            </div>

            {item.item_variants.length > 1 ? (
                <>
                    <div className={styles.mainItemContainer}>
                         <LotLabel />
                               
                        <LotRow 
                            vatiantsObject = {variantsObject}
                            lot = {0}
                            reason={4}
                        />
                    </div>
                    <span className={styles.link} 
                        onClick={() => setShowVariants(showVariants === item.id ? null : item.id)}
                          >
                      {showVariants === item.id ? 
                            'Закрыть варианты' :
                            'Открыть варианты'}
                    </span>

                    {showVariants === item.id && (
                        
                      <ShowVariants 
                                    variantsObject={variantsObject}
                                     />
                    )}
                </>
              ) :(

                <ItemWithoutVariants lots={item.item_variants[0].lots}
                                        variantsObject={variantsObject}
                />
              )
              }            

          
        </div> 
        



     
      </div>
    )
}

export default ArchevedLots