import { gql } from "@apollo/client";


export const ADD_COMPANY = gql`
    mutation addCompany(
        $userID : bigint!, 
        $name: String!, 
        $INN : bigint!,
        $OGRN : bigint!,
        $address : String!,
        $tax_system : String!
        $kpp : bigint!,
        ) {
        insert_company(objects: {
                 inn: $INN,
                 ogrn: $OGRN,
                 name: $name,
                 kpp: $kpp,
                 address: $address,
                 tax_system: $tax_system,
                 user_id: $userID,
                 created_on: "now()",
                 }) {
          returning {
            id
          }
        }
    }
`;

export const GET_COMPAY_AND_SHOPS = gql`
    query getCompanyAndShops($userID: bigint!, $oneDayAgo: timestamptz!) {
        company(where: {user_id: {_eq: $userID}}) {
            id
            inn
            kpp
            ogrn
            name
            address
            tax_system
            
            

        }
        shop(where: {owner_id: {_eq: $userID}}) {
            id
            name
            photo
            slogan
            order_items_aggregate(where: {order: {status: {_eq: "payed"}, created_on: {_gte: $oneDayAgo}}}) {
                aggregate {
                    count
                  }
            }
            shop_orders_aggregate{
                aggregate {
                    count
                   
                }
            }
            shop_payments_aggregate {
                aggregate {
                    sum {
                        total_amount
                    }
                }
            }
            


            lots_aggregate(where: {status: {_eq: "sold"}}) {
                aggregate {
                    count
                }
            }
            comment_items_aggregate {
                aggregate {
                    avg {
                        estimate
                    }
                    count
                }
            }
            
         
        }
    }
`;



export const COMPANY_PERFOMANCE = gql`
    query CompanyPerformance($company_id: Int!) {
        
        shop_aggregate(where: {company_id: {_eq: $company_id}}) {
            aggregate {
                count
            }
        }
        shop_orders_aggregate(where: {shop: {company_id: {_eq: $company_id}}}) {
            aggregate {
                count
               
            }
        }
        shop_payments_aggregate(where: {shop: {company_id: {_eq: $company_id}}}) {
            aggregate {
                sum {
                    total_amount
                }
            }
        }
        item_aggregate(where: {shop: {company_id: {_eq: $company_id}}}  ) {
            aggregate {
                count
            }
        }
        
        
    }
`;