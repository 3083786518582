import classes from "./category.module.css";

import {useState} from 'react';
import {ReactComponent as SingleIcon} from "../../assets/svg/single.svg";
import {ReactComponent as DoubleIcon} from "../../assets/svg/double.svg";
import {ReactComponent as ListIcon} from "../../assets/svg/list.svg";

export default function ItemCardFormat({itemFormat, setItemFormat, options}) {

    // console.log("ItemCardFormat rerender with itemFormat=", itemFormat)

    const [localItemFormat, setLocalItemFormat] = useState(itemFormat)


    const onChangeItemCardFormat = (event) => {
        setItemFormat(event.currentTarget.value)
        setLocalItemFormat(event.currentTarget.value)
    }

    const cardFormatDisplay = options.map((el) => {

            const inputClassName = localItemFormat === el.value ? classes.cardFormatInput + ' ' + classes.activeCardFormatInput : classes.cardFormatInput
            let inputIcons = null
            switch (el.id) {
                case 1:
                    inputIcons = <DoubleIcon/>
                    break
                case 2:
                    inputIcons = <SingleIcon/>
                    break
                case 3:
                    inputIcons = <ListIcon/>
                    break
                default:
                    inputIcons = null
            }

            return (
                <label className = {inputClassName} key = {`radio-cardFormat-${el.id}`}>
                    <input className = {classes.itemCardFormatInput}
                        // id = {"radio-cardFormat-1"}
                           name = {"cardFormat"}
                           type = "radio"
                           value = {el.value}
                           checked = {localItemFormat === el.value}
                           onChange = {onChangeItemCardFormat}
                    />
                    <span>
                       {inputIcons}
                    </span>
                </label>
            )
        }
    )

    return (
        <div className = {classes.itemCardFormatContainer}>
            <h2 className = {classes.filterTitle}>Формат карточки товара</h2>
            <div className = {classes.itemCardFormatDisplayContainer}>
                {cardFormatDisplay}
            </div>

        </div>)
}