import React, {useContext, useState} from 'react';
import classes from './Shop.module.css';
// import {useQuery} from '@apollo/client';
import NoPhoto from '../../../Containers/Account/NoPhoto/NoPhoto';

import approveSvg from '../../../assets/svg/approve.svg';
import 'swiper/css';
import 'swiper/css/free-mode';

import ShareButton from '../../ShareButton/ShareButton';
import Share from './SharingPage/Share';


import {modalDisplayMode} from '../../PrimaryModal/PrimaryModal';




// import {GET_SHOP} from '../../../requests/shopRequests';
import {useNavigate} from 'react-router-dom';
import {ModalContext} from '../../../App';
import LikeShop from '../../Likes/LikesShop';

export default function Shop({shop}) {


    const navigate = useNavigate();
    

    const {
        // modalActive,
        setModalActive,
        // modalContent,
        setModalMode,
        setModalContent,
        // modalPage,
        // setModalPage,
    } = useContext(ModalContext);
    const [showModal, setShowModal] = useState(false);


    const shareHandler = () => {
        setModalActive(true);
        setModalContent(
            <Share url = {'https://t.me/item_market_bot'}/>);
    }



    const docs = shop.docs;

    const isLiked = shop.shops_likes?.length > 0;

    const estimate = shop.comment_items_aggregate?.aggregate?.avg.estimate || 0;
    const rating= estimate.toFixed(1);
    const starCount = shop.comment_items_aggregate?.aggregate?.count || 0;

    // const starCount = `${shop.comment_items_aggregate?.aggregate?.count} 
    // ${numWord(shop.comment_items_aggregate?.aggregate?.count,
    // [' отзыв', ' отзыва', ' отзывов'])}`;


    const cart_quantity = shop.carts_aggregate?.aggregate?.sum.quantity || 0;
    const approveLabel = shop.approval === 'approved' ? approveSvg : null;


    const sharedItemCallback = () => {

        setModalMode(modalDisplayMode.withOverlay);
        setModalContent(<Share url = {`https://sovmestno.sale/shop/${shop.id}`}/>);
        setModalActive(true);
    }

    return (
        <>
        <div className = {classes.ShopContainer}>
            
            
                <div className = {classes.row}>
                    <div className = {classes.title} onClick = {() =>
                        navigate(`/shop/${shop.id}`)
                    }>Продавец
                    </div>

                    <div className = {classes.likeContainer}>
                        <ShareButton 
                             title = {"Поделиться магазином"} callback = {sharedItemCallback}/>
                        <LikeShop ShopID = {shop.id} doWeHaveLike = {isLiked}/>
                    </div>
                </div>


                <div className = {classes.logoContainer} onClick = {() =>
                    navigate(`/shop/${shop.id}`)}>
                    {shop.photo
                        ? <img src = {shop.photo} className = {classes.logo} alt = 'shopImage'/>
                        : <NoPhoto/>}
                    {/* <img className = {styles.logo} src = {shop.photo} alt = ""/> */}
                </div>

                <div className = {classes.nameContainer} onClick = {() =>
                    navigate(`/shop/${shop.id}`)}>

                    <span className = {classes.name}>{shop.name}</span>
                    {approveLabel ? (
                        <img className = {classes.approveLabel} src = {approveLabel} alt = ""/>
                    ) : (
                        ''
                    )}
                </div>

                <div className = {classes.allInformation}>
                    <div className = {classes.ShopSlide}>
                        <div className = {classes.label}>Рейтинг&nbsp;товаров</div>
                        <div className = {classes.text}>{`⭐️ ${rating}/5`}</div>
                    </div>
                    <div className = {classes.ShopSlide}>
                        <div className = {classes.label}>Всего&nbsp;заказов</div>
                        <div className = {classes.text}>{cart_quantity}</div>
                    </div>
                    <div className = {classes.ShopSlide}>
                        <div className = {classes.label}>Всего&nbsp; отзывов</div>
                        <div className = {classes.text}>{starCount}</div>
                    </div>
                    <div className = {classes.ShopSlide}>
                        {/* <div onClick = {() => { */}
                        {/* setModalActive(true) */}
                        {/* setModalContent(<ShopModalContent docs = {docs}/>) */}
                        {/* }}> */}
                        <div>Юридическая</div>
                        <div>информация</div>
                    </div>

                </div>



        </div>
        </>

    )
        ;
}
