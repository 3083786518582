



import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Lots.module.css';


import { getVariantWord } from '../../../utils/format/getVariantWord';
import { formatNumberDate  } from '../../../utils/format/formatdata';

import { useQuery } from '@apollo/client';
import { GET_SHOP_LOTS, GET_SHOP_ARCHIVED_LOTS } from '../../../requests/lotsRequests';



function Lots({ shopId, archived =false}) {


    const navigate = useNavigate();


    const query = archived ?   GET_SHOP_ARCHIVED_LOTS : GET_SHOP_LOTS;
    const navigateTo = archived ? '/archived_lots' : '/settings_lots';
    
    const {data, error, loading} = useQuery(query,{
        fetchPolicy: 'cache-and-network',
        variables: {shopId},
    }); 

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('get shop items error', error);
        return 'error';
    }
    let  {lots } = {lots: data.lots,}

    // console.log('arvhived',archived, lots)





    if (!lots || lots.length === 0) {
        return (
          <div className={styles.wrapper}>
            <div className={styles.text}>У вас пока нет ни одного лота.</div>
            <button className={styles.addButton} onClick={() => navigate('/create_lots', { state: { shopId } })}>
              Создать
            </button>
          </div>
        );
      }
    
    
    



      
      return (
        <div className={styles.wrapper}>
          <div className={styles.contentContainer}>
            
            {lots.map((lot, index) => {
              const allLots = lot.item.allLotsAggregate.aggregate.count;
              const closedLots = lot.item.closedLotsAggregate.aggregate.count;
              const activeLots = lot.item.activeLotsAggregate?.aggregate.count;
              const variants  = lot.item.item_variants_aggregate.aggregate.count;
              const name = lot.item.item_variants[0].purposeName;
              const name2 = lot.item.item_variants[0].purposeName2;
              let purpose = '';
              if(!name){
                  purpose  = ''
              }else if(!name2){
                  purpose = '1 cвойство ;'
              }else {
                  purpose = '2 свойства ;'
              }
            
              const init_date = formatNumberDate(lot.init_date);
              {/* const expire_date = formatNumberDate(lot.expire_date); */}

            return(

                 
              <div className={styles.itemContainer} key={index} 
                 onClick={() => navigate(navigateTo, { state  : {lotsId : lot.id  } })}>
                <div className={styles.itemImage}>
                  <img className={styles.image} src={lot.item.item_media[0].path} alt="itemImage" />
                </div>
                <div className={styles.itemInfo}>
                  <span className={styles.itemName}>{lot.item.name}</span>
                  <span className={styles.itemArticle}>{lot.item.articleNumber}</span>
                  <span className={styles.itemPurposeCount}>{purpose} {variants} {getVariantWord(variants)};</span>
                </div>
                <div className={styles.countLots}>
                    <span className={styles.dataLots}>Cоздано:{init_date}</span>
                    {/* <span className={styles.dataLots}>Окончание:{expire_date}</span> */}
                    {
                      archived ? <span className={styles.countLotsNumber}>Cобрано лотов:</span> :
                      <span className={styles.countLotsNumber}>Активных лотов:</span>
                    }
                    {
                      archived ? <span className={styles.countLotsText}>{closedLots}/{allLots}</span> : 
                      <span className={styles.countLotsText}>{activeLots}/{allLots}</span>
                    }

                  
                </div>
              </div>
            )
            }
            )

            }
          </div>
          {
            archived ? null : 
            <button className={styles.addButton} onClick={() => navigate('/create_lots', { state: { shopId } })}>
            Создать
          </button>
          }
         
        </div>
      );
    }

export default Lots;

