import {gql} from "@apollo/client";





export const GET_SUBCATEGORY_WITH_ITEMS = gql`
    query subcategoryWithItems($currentCategory: String) {
        category(
            where: {category_items: {item_id: {_is_null: false}},
                     category: {_eq: $currentCategory}}
        ) {
            category
            subcategory
            category_items_aggregate {
                aggregate {
                    count
                }
            }
        }
        
    }
`;


export const GET_CATEGORY_WITH_ITEMS = gql`
    query GET_CATEGORY_WITH_ITEMS {
        category(distinct_on: category,where: {category_items: {item_id: {_is_null: false}}} ) {
            category

        }
    }
`;


export const GET_CATEGORIES = gql`
    query getCategory {
        category(distinct_on: category) {
            category
            id
        }
    }
`;


export const GET_SUBCATEGORIES = gql`
    query getSubcategory($category: String) {
        category(
            distinct_on: subcategory
            where: { category: { _eq: $category } }
        ) {

            subcategory
            id
        }
    }
`;

export const GET_PRODUCT_CATEGORIES = gql`
    query productCategory($subcategory: String) {
        category(
            distinct_on: product_category
            where: { subcategory: { _eq: $subcategory } }
        ) {

            product_category
            id
        }
    }
`;


export const GET_SHOP_CATEGORIES = gql`
    query shopCategory($category: String) {
        shop(where: {items: {category_items: {category: {category: {_eq: $category}}}}}) {
            id
            name
          }
    }
`;


export const GET_BRAND_CATEGORIES = gql`
    query brandCategory($category: String) {
        item(
            distinct_on: brand,
            where: {category_items: {category: {category: {_eq: $category}}}}) {
            brand
        }
    }
`;



// export const GET_CATEGORY_ID = gql`
    // query getCategoryID($category: String, $subcategory: String, $productCategory: String) {
        // category(where: {category: {_eq: $category}, subcategory: {_eq: $subcategory}, product_category: {_eq: $productCategory}}) {
            // id
        // }
    // }
// `


export const CATEGORY_ITEMS = gql`
    query categoryItemsQuery($userID: bigint,$current_category: String!,$limit: Int, $offset: Int) {
        item(limit:$limit, offset:$offset,
            where: {
                category_items: { category: { category: { _eq: $current_category } } }
            }
        ) {
            
            id
            name
            about
            brand   
            shop_id
            lots(where : {status: {_eq: "active"}},
                order_by: {price: asc}) {
                price
                total
                step
            }
            
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                    category_items_aggregate {
                        aggregate {
                            count
                        }
                    }
                }
            }
           
            item_media (where: {type: {_eq: "image"}}){
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
              }
        }
        
        category_item_aggregate(where: {category: {category: {_eq: $current_category}}}) {
            aggregate {
                count
            }
        }

    }
`;

export const CATEGORY_ITEMS_BY_PRICE_MIN = gql`
    query categoryItemsMinQuery($userID: bigint, $current_category: String!, $limit: Int, $offset: Int) {
        item(
            limit: $limit, 
            offset: $offset,
            where: {category_items: {category: {category: {_eq: $current_category}}},
            lots: {status: {_eq: "active"}}
                    },
            order_by: {lots_aggregate: {min: {price: asc}}}
        ) {
            id
            name
            about
            brand   
            shop_id
            lots(
                where: {status: {_eq: "active"}},
                order_by: {price: asc}
            ) {
                price
                total
                step
            }
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                    category_items_aggregate {
                        aggregate {
                            count
                        }
                    }
                }
            }
            item_media (where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }
        category_item_aggregate(where: {category: {category: {_eq: $current_category}}}) {
            aggregate {
                count
            }
        }
    }
`;


export const CATEGORY_ITEMS_BY_PRICE_MAX = gql`
    query categoryItemsMaxQuery($userID: bigint, $current_category: String!, $limit: Int, $offset: Int) {
        item(
            limit: $limit,
            offset: $offset,
            where: {
                category_items: {category: {category: {_eq: $current_category}}},
                                 lots: {status: {_eq: "active"}}},
                order_by: {lots_aggregate: {max: {price: asc}}}) {
            id
            name
            about   
            brand
            shop_id
            lots(
                where: {status: {_eq: "active"}},
                order_by: {price: desc}
            ) {
                price
                total
                step
            }
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                    category_items_aggregate {
                        aggregate {
                            count
                        }
                    }
                }
            }
            item_media (where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }
        category_item_aggregate(where: {category: {category: {_eq: $current_category}}}) {
            aggregate {
                count
            }
        }
    }
`;





export const CATEGORY_ITEMS_BY_RATING = gql`
    query categoryItemsRatingQuery($userID: bigint, $current_category: String!, $limit: Int, $offset: Int) {
        item(
            limit: $limit,
            offset: $offset,
            where: {category_items: {category: {category: {_eq: $current_category}}}},
            order_by: {comment_items_aggregate: {avg: {estimate: desc}}}  
        ) {
            id
            name
            about
            brand
            shop_id
            lots(
                where: {status: {_eq: "active"}},
                order_by: {price: asc}  
            ) {
                price
                total
                step
            }
            comment_items {
                estimate
            }
            comment_items_aggregate {
                aggregate {
                  avg {
                    estimate
                  }
              }
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                    category_items_aggregate {
                        aggregate {
                            count
                        }
                    }
                }
            }
            item_media (where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }
        
        category_item_aggregate(where: {category: {category: {_eq: $current_category}}}) {
            aggregate {
                count
            }
        }
    }
`;

export const CATEGORY_ITEMS_BY_ORDERS = gql`
    query categoryItemsOrdersQuery($userID: bigint, $current_category: String!, $limit: Int, $offset: Int) {
        item(
            limit: $limit,
            offset: $offset,
            where: {category_items: {category: {category: {_eq: $current_category}}}},
            order_by: {order_items_aggregate: {count: desc}}
        ) {
            id
            name
            about
            brand
            shop_id
            lots(
                where: {status: {_eq: "active"}},
                order_by: {price: asc}
            ) {
                price
                total
                step
            }
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                    category_items_aggregate {
                        aggregate {
                            count
                        }
                    }
                }
            }
            item_media (where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }
        category_item_aggregate(where: {category: {category: {_eq: $current_category}}}) {
            aggregate {
                count
            }
        }
    }
`;


export const CATEGORY_ITEMS_BY_MIN_STEP= gql`
    query categoryItemsMinStepQuery($userID: bigint, $current_category: String!, $limit: Int, $offset: Int) {
        item(
            limit: $limit,
            offset: $offset,
            where: {category_items: {category: {category: {_eq: $current_category}}}
            lots: {status: {_eq: "active"}}},
            
            order_by: {lots_aggregate: {min: {step: asc}}}
        ) {
            id
            name
            about
            brand
            shop_id
            lots(
                where: {status: {_eq: "active"}},
                order_by: {price: asc}
            ) {
                price
                total
                step
            }
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                    category_items_aggregate {
                        aggregate {
                            count
                        }
                    }
                }
            }
            item_media (where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }
        category_item_aggregate(where: {category: {category: {_eq: $current_category}}}) {
            aggregate {
                count
            }
        }
    }
`;