import React from 'react';
import classes from './DescribesAndProperties.module.css';
import './DescribesAndProperties.css';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function DescribesAndProperties({item}) {
    // console.log("item", item)
    const propertiesObj = item?.character ?? '';
    const propertiesKeysArray = Object.keys(propertiesObj);
    const properties = propertiesKeysArray.map((el, index) => {
        return (
            <div className = {classes.container} key = {index}>
        <span className = {classes.keys}>
          <span>{el}</span>
          <span className = {classes.dot}></span>
        </span>

                <span className = {classes.propets}> {propertiesObj[el]}</span>
            </div>
        );
    });
    const finalProperties =
        properties.length > 0 ? properties : 'Характеристики отсутствуют';
    //console.log(finalProperties);
    return (
        <Tabs>
            <TabList>
                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div>Описание</div>
                </Tab>
                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div>Характеристики</div>
                </Tab>
            </TabList>

            <TabPanel>
                <p className={classes.about}>{item.about}</p>
            </TabPanel>
            <TabPanel>{finalProperties}</TabPanel>
        </Tabs>
    );
}
