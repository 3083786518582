import React, {useContext} from "react";

import LoadItems from "../../StandartGoodsDisplay/7LoadItems";

import classes from "./Recommend.module.css";
import DisplayItems from "../../StandartGoodsDisplay/DisplayItems";
import {TelegramContext} from '../../../App';
import {GET_ITEMS} from '../../../requests/itemRequests';
import LoadItemsData from '../../StandartGoodsDisplay/LoadItemsData';



const Recommend = React.memo(function Recommend({limit}) {

    const {userID} = useContext(TelegramContext);

    const  dateNow = new Date();

    return (
        <div>
            <div>
                <span className = {classes.title}>Рекомендуем</span>
            </div>
            <br></br>
            <div className = {classes.ContainerJointShopping}>
                <LoadItemsData limit = {limit}
                               query = {GET_ITEMS}
                               variables = {{userID, offset: 0, limit, dateNow}}
                               keyName = {"item"}
                               aggregateKeyName = {"item_aggregate"}
                               isHorizontal = {false}/>
            </div>
        </div>
    );
}
)
export default Recommend;
