import ProfileInput from '../../Account/ProfileMain/components/ProfileInput/ProfileInput';
import ItemCheckbox from '../../CartPage/ItemCheckbox';
import ProfileTextarea from '../../Account/ProfileTextarea/ProfileTextarea';
import ColoredButton from '../../Account/ProfileMain/components/ColoredButton/ColoredButton';
import React, {useEffect, useState} from 'react';
import styles from './AddAddressesForm.module.css'

export default function AddAddressForm({
                                           editedAddressID,
                                           index,
                                           indexCallback,
                                           street,
                                           streetCallback,
                                           home,
                                           homeCallback,
                                           readOnly,
                                           flat,
                                           flatCallback,
                                           onClick,
                                           title,
                                           titleCallback,
                                           disabled,
                                           addAddressCallback,
                                           editAddressCallback
                                       }) {
    const [buttonName, setButtonName] = useState('')

    useEffect(() => {
        if (index === '' && street === '' && home === '') {
            setButtonName("Добавить адрес")

        } else {
            setButtonName("Изменить адрес")

        }
    }, [])

    const handleOnClickButtonCallback = () => {
        if (buttonName === "Добавить адрес") {
            addAddressCallback();
        } else {
            editAddressCallback(editedAddressID)
        }
    }

    return <div className = {styles.wrapper}>
        <h2 className = {styles.title}>Адрес</h2>
        <div>
            <ProfileInput placeholder = {'Индекс'} value = {index} callback = {indexCallback} type = {"number"}/>
            <ProfileInput placeholder = {'Улица'} value = {street} callback = {streetCallback}/>
            <ProfileInput placeholder = {'Дом'} value = {home} callback = {homeCallback}/>
            <ProfileInput placeholder = {'Квартира/офис'} readOnly = {readOnly} value = {flat}
                          callback = {flatCallback}/>
            <div className = {styles.container}>
                <p className = {styles.text}>Нет номера квартиры/офиса</p>
                <ItemCheckbox isChecked = {readOnly} onClick = {onClick}/>
            </div>
            <ProfileTextarea placeholder = {'Комментарий'} value = {title} callback = {titleCallback}/>
        </div>

            <div className = {styles.buttonContainer}>
                <ColoredButton name = {buttonName} disabled = {disabled}
                               callback = {handleOnClickButtonCallback}/>
            </div>

    </div>;
}