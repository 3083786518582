import React, {useContext, useEffect, useState} from 'react'
import Cities from '../Cities/Cities';
import {useLocation, useNavigate} from 'react-router-dom';
import Address from './Address';
import ColoredButton from '../Account/ProfileMain/components/ColoredButton/ColoredButton';
import styles from './UserAddresses.module.css'
import {useMutation} from '@apollo/client';
import {
    ADD_USER_ADDRESS,
    EDIT_ADDRESS,
    UPDATE_FIRST_USER_ADDRESS,
    UPDATE_USER_ADDRESSES
} from '../../requests/userRequests';
import {TelegramContext} from '../../App';
import AddAddressForm from './AddAddressesForm/AddAddressesForm';

export const addressesDisplayMode = {
    default: "addressesList", cities: 'cities', streetAndHouseNumber: 'streetAndHouseNumber'
}


const UserAddresses = () => {

    // console.log('userAddress')

    const location = useLocation();
    const profileId = location.state.profileId
    const [addresses, setAddresses] = useState(location.state.addresses)
    const [displayMode, setDisplayMode] = useState(addresses.length ? addressesDisplayMode.default : addressesDisplayMode.cities)

    const navigate = useNavigate();

    const {userID} = useContext(TelegramContext);

    const [checkedAddressId, setCheckedAddressId] = useState(null)
    const [prevCheckedAddress, setPrevCheckedAddress] = useState(null)

    const [cityData, setCityData] = useState({})
    const [flat, setFlat] = useState('')
    const [home, setHome] = useState('')
    const [index, setIndex] = useState('')
    const [street, setStreet] = useState('')
    const [title, setTitle] = useState('')
    const [isNoOffice, setIsNoOffice] = useState(false)
    const [editedAddressID, setEditedAddressID] = useState(null)
    //check all data
    const [isDisabled, setIsDisabled] = useState(true)

    const [updateAddress] = useMutation(UPDATE_USER_ADDRESSES);
    const [addAddress] = useMutation(ADD_USER_ADDRESS);
    const [addFirstAddress] = useMutation(UPDATE_FIRST_USER_ADDRESS);
    const [editAddress] = useMutation(EDIT_ADDRESS);

    useEffect(() => {
        //find checked address and save data
        const checkedAddress = addresses.find(address => address.choose) || {}
        setCheckedAddressId(checkedAddress?.id)
        setPrevCheckedAddress({...checkedAddress})
    }, [])

    const CheckFlat = (flat, isNoOffice) => {
        return isNoOffice
            ? true
            : flat !== ''
    }

    //check disabled button or not
    useEffect(() => {
        const flatData = CheckFlat(flat, isNoOffice)
        if (index !== "" && street !== "" && home !== "" && flatData) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [index, street, home, flat, isNoOffice])

    const handleAsyncUpdateAddress = async () => {
        const newCheckedAddress = addresses.find(address => address.id === checkedAddressId)
        const objects = [
            {
                city_id: newCheckedAddress.city_id,
                flat: newCheckedAddress.flat,
                home: newCheckedAddress.home,
                id: newCheckedAddress.id,
                index: newCheckedAddress.index,
                profile_id: profileId,
                street: newCheckedAddress.street,
                title: newCheckedAddress.title,
                user_id: userID,
                choose: true
            }, {
                city_id: prevCheckedAddress.city_id,
                flat: prevCheckedAddress.flat,
                home: prevCheckedAddress.home,
                id: prevCheckedAddress.id,
                index: prevCheckedAddress.index,
                profile_id: profileId,
                street: prevCheckedAddress.street,
                title: prevCheckedAddress.title,
                user_id: userID,
                choose: false
            }]
        //console.log(prevCheckedAddress)
        if (prevCheckedAddress.hasOwnProperty('id')) {
            if (!newCheckedAddress.choose) { //if checkedAddress false
                const {data, errors} = await updateAddress({variables: {objects: objects}})
                data && navigate("/cart")
            } else { //if checkedAddress true
                navigate("/cart")
            }
        } else {
            const {data, errors} = await addFirstAddress({variables: {id: newCheckedAddress.id}})
            data && navigate("/cart")
        }
    }

    const clearForm = () => {
        setCityData('')
        setFlat('')
        setHome('')
        setIndex('')
        setStreet('')
        setTitle('')
        setIsNoOffice(false)
        setEditedAddressID(null)
    }

    const handleAsyncAddAddress = async () => {
        const newAddress = {
            city_id: cityData.id,
            flat: flat,
            home: home,
            index: index,
            street: street,
            title: title,
            user_id: userID,
            profile_id: profileId,
        }
        const {data, errors} = await addAddress({
            variables: {...newAddress}
        })
        if (data) {
            setAddresses([...addresses,
                {
                    ...newAddress, __typename: "user_address", id: data.insert_user_address.returning[0].id,
                    delivery_city: {...cityData, __typename: "delivery_city"}
                }])
            setDisplayMode(addressesDisplayMode.default)
            clearForm()
        }
    }
    const handleAsyncEditAddress = async (id) => {
        const editedAddress = {
            city_id: cityData.id,
            flat: flat,
            home: home,
            index: index,
            street: street,
            title: title
        }
        const {data, errors} = await editAddress({
            variables: {id: id, ...editedAddress}
        })
        if (data) {
            setAddresses(addresses.map(address => address.id === id
                ? {...address, ...editedAddress, delivery_city: {...cityData}}
                : address))
            setDisplayMode(addressesDisplayMode.default)
            clearForm()
        }
    }

    const isCheckedAddress = (id) => id?.toString() === checkedAddressId?.toString()
    const changeCheckedAddress = (id) => setCheckedAddressId(id)

    //toggle flat
    const handleNoOfficeToggle = () => {
        setIsNoOffice(!isNoOffice)
        if (flat !== '') setFlat('')
    }

    //set address
    const handlerOnChangeIndex = (index) => setIndex(index)
    const handlerOnChangeStreet = (street) => setStreet(street)
    const handlerOnChangeHome = (home) => setHome(home)
    const handlerOnChangeFlat = (flat) => setFlat(flat)
    const handlerOnChangeTitle = (title) => setTitle(title)

    const handleAddNewAddress = () => setDisplayMode(addressesDisplayMode.cities)
    const handleSetStreetAndHouseNumberDisplayMode = () => setDisplayMode(addressesDisplayMode.streetAndHouseNumber)
    const handleEditAddress = (address) => {
        setFlat(address.flat)
        setHome(address.home)
        setIndex(address.index)
        setStreet(address.street)
        setTitle(address.title)
        setIsNoOffice(address.isNoOffice)
        setEditedAddressID(address.id)
        setDisplayMode(addressesDisplayMode.cities)
    }
    const deleteAddress = (id) => {
        const newAddresses = addresses.filter(address => address.id !== id)
        setAddresses(newAddresses)
        !newAddresses.length && navigate("/cart")
    }


    //  console.log(addresses)
    switch (displayMode) {
        case addressesDisplayMode.cities:
            return <Cities inAddresses = {true} setCityData = {setCityData}
                           setMode = {handleSetStreetAndHouseNumberDisplayMode}/>
        case addressesDisplayMode.streetAndHouseNumber:
            return (
                <AddAddressForm editedAddressID = {editedAddressID}
                                index = {index}
                                indexCallback = {handlerOnChangeIndex}
                                street = {street}
                                streetCallback = {handlerOnChangeStreet}
                                home = {home}
                                homeCallback = {handlerOnChangeHome}
                                readOnly = {isNoOffice}
                                flat = {flat}
                                flatCallback = {handlerOnChangeFlat}
                                onClick = {handleNoOfficeToggle}
                                title = {title}
                                titleCallback = {handlerOnChangeTitle}
                                disabled = {isDisabled}
                                addAddressCallback = {handleAsyncAddAddress}
                                editAddressCallback = {handleAsyncEditAddress}/>)
        default:
            return (
                <div className = {styles.wrapper}>
                    <h2>Мои адреса</h2>
                    {addresses?.length && addresses.map(shipping_address => <Address key = {shipping_address.id}
                                                                                     shipping_address = {shipping_address}
                                                                                     isChecked = {isCheckedAddress(shipping_address.id)}
                                                                                     changeCheckedAddress = {changeCheckedAddress}
                                                                                     deleteAddressCallback = {deleteAddress}
                                                                                     editAddressCallback = {handleEditAddress}/>)}
                    <div className = {styles.buttonContainer}>
                        <ColoredButton name = {"Добавить новый адрес"} callback = {handleAddNewAddress}/>
                        <ColoredButton name = {"Выбрать"} callback = {handleAsyncUpdateAddress}/>
                    </div>
                </div>)
    }
}

export default UserAddresses