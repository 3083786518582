import React, {  useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ORDERS_FOR_DELIVERY,
         GET_ORDERS_WITH_STATUS, 
         UPDATE_ORDER_ITEMS_STATUS,
         UPDATE_SHOP_ORDER_STATUS,
          SAVE_SHIPPING_TRACK } from "../../../requests/orderRequests";
import style from "./ShopOrders.module.css";

import { formatStringDate } from "../../../utils/format/formatdata";

import  RefundItem  from "../OrdersUser/Order/RefundItem";


function formatStatus(status) {
    let color ;
    switch (status) {
        case "payed":
            color = "rgba(96, 96, 96, 1)";
            return { color, status: "Оплачено" };
        case "confirmed":
            color =  "BLACK" ;
            return { color, status: "Принят" };
        case "shipped":
            color = "rgba(79, 140, 231, 1)" ;
            return { color, status: "Доставлено" };
        case "shipping":
            color = "rgba(79, 140, 231, 1)" ;
            return { color, status: "В доставке" };
        case "received":
            color = "rgba(79, 231, 112, 1)" ;
            return { color, status: "Получен" };
        case "cancell":
            color = "RED" ;
            return { color, status: "Отменен" };
        case "declined":
            color = "RED" ;
            return { color, status: "Возврат  " };
        default:
            color = "rgba(96, 96, 96, 1)";
            return { color, status: "В архиве" };
    }
}



function titleOrders(status) {
    let title ;
    let text ;
    switch (status) {
        case "payed":
            title = "Заказы к подверждению" 
            text = " У вас пока нет заказов к подтверждению"
            return { title, text };
        case "confirmed":
            title = "Заказы к отправке" ;
            text = "У вас пока нет завершеынных лотов готовых к отправке"
            return { title, text };
        case "shipped":
            title = "Заказы отправленные" ;
            text = "У вас пока нет отправленных заказов"
            return { title, text };
        case "shipping":
            title = "Заказы в доставке" ;
            text = "У вас пока нет отправленных заказов"
            return { title, text };
        case "received":
            title = "Заказы доставленные" ;
            text = "У вас   пока нет доставленных заказов "
            return { title, text };
        case "cancell":
            title = "Заказы отмененные" ;
            text = "У вас  нет отмененных заказов"
            return { title, text };
        case "declined":
            title = "Возвраты" ;
            text = "У вас  нет возвратов"

            return { title, text };
        default:
            title = "Заказы в архиве" ;
            text = "У вас пока нет заказов в архиве"
            return { title, text };
    }

}





function ShopOrdersForApprve({orderStatus, shopId}){

    const [isModalActiveItem, setModalActiveItem] = useState(false); 
    const [itemId, setItemId] = useState(null);
    const [refundOrder, setRefundOrder] = useState(false);
    const [updateStatus]  = useMutation(UPDATE_ORDER_ITEMS_STATUS );

    const {  title, text } = titleOrders(orderStatus);    


    const { loading, error, data, refetch } = useQuery(GET_ORDERS_WITH_STATUS, {
        fetchPolicy: "cache-and-network",
        variables: { shopId: shopId,
                    status : orderStatus },
    });
    if(loading) return null;
    if(error) console.log(error);

    // console.log('data order', data?.order);
    // console.log('data order_items', data?.order[0]?.order_items);

    if(data?.order.length === 0){
        return ( <div className = {style.wrapper}>
                    <div className={style.title}>
                        {title}
                    </div>  
                <div className={style.actions}>
                      {text}
                </div>
            </div>
        )   }

    function handleButtonClick(orderItemId, status, order = null) {
            console.log('status', status);
            console.log('order', order);
        
            const commonActions = () => {
                // console.log('FFFFFFFFFFFFFFFFFF')
                updateStatus({ variables: { orderItemId: orderItemId, status: status }})
                    .then(() => {
                        refetch();
                    })
                    .catch(error => {
                        console.error('Error in handleButtonClick:', error);
                    });
            };
                commonActions();
            }

    function handleDecline(orderItemId, order) {
                // console.log('orderItemId', orderItemId);
                // console.log('shop_id', shopId);
                // sendApiCall(orderItemId, "declined");
                setItemId(orderItemId);
                setRefundOrder(order);
                setModalActiveItem(true);
    
            }
    
    function closeModalActive() {
            setModalActiveItem(false);
            refetch();
            // console.log('refetch');
        }
    

    return (
        <>
        <div className={style.wrapper}>
                <div className={style.title}>
                    {title}
                </div>

        {data.order.map(order => (
                    
            <div key={order.id} className={style.orderContainer}>
              {order.order_items.length > 0 &&
                <div style={{ display: 'flex', flexDirection: "column", gap: '5px' }}>
                    <div className={style.orderInfo}>
                        <div className={style.orderNumber}>Заказ от {formatStringDate(order.updated_on)}</div>
                    </div>

                    <div className={style.imageContainer}>
                            {order.order_items.map(order_item => {
                              const { color, status } = formatStatus(order_item.status);
                              let orderItemId = order_item.id;
                            
                              return (
                                <div key={order_item.id} className={style.itemImage}>
                                  <div className={style.itemStatus} style={{ color: color }}>{status}</div>
                                  <img src={order_item.item.item_media[0]?.path} alt="foto" className={style.avatar} />
                                  <div className={style.quanityContainer}>
                                    <div className={style.orderQuantity}>{order_item.quantity}шт.по {order_item.price} ₽</div>
                                    <div className={style.orderSum}> = {order_item.price * order_item.quantity} ₽</div>
                                  </div>

                                  {orderStatus === 'payed' && (
                                    <div className={style.buttonContainer}>
                                      <button className={style.buttonDecline} onClick={() => handleDecline(orderItemId, order)}>
                                                                                                 
                                        Вернуть деньги ❌
                                      </button>
                                      <button className={style.buttonGetting} onClick={() => handleButtonClick(orderItemId, 'confirmed')}>
                                        Подтвердить ✅
                                      </button>
                                    </div>
                                  )}

                                  {isModalActiveItem && (
                                    <RefundItem
                                      setModalActiveItem={closeModalActive}
                                      order={refundOrder}
                                      orderItemId={itemId}
                                      shop={shopId}
                                      refetch = {refetch}
                                    />
                                  )}
                                </div>
                              );
                            })}
                    </div>
                  <span style={{ 'border': '2px solid #EDEDED'  }}></span>

                </div>

              }
            </div>
        ))}
        </div>
        </>
    )

}



function ShopOrdersForDelivery({shopId, orderStatus}) {

    const [saveShipping] = useMutation(SAVE_SHIPPING_TRACK);
    const [company, setCompany] = useState(null);
    const [trackNumber, setTrackNumber] = useState(null);
    const [updateItemStatus]  = useMutation(UPDATE_ORDER_ITEMS_STATUS );
    const [updateOrderStatus] = useMutation(UPDATE_SHOP_ORDER_STATUS);


    const { title, text } = titleOrders(orderStatus);

    console.log('shopId', shopId);
    console.log('orderStatus', orderStatus);

    const { loading, error, data, refetch } = useQuery(GET_ORDERS_FOR_DELIVERY, {
        fetchPolicy: "cache-and-network",
        variables: { shopId: shopId,
                    status : orderStatus },
    });

    if(loading) return null;
    if(error) console.log(error);

    // console.log('data AAAAAAAAAAAAAAAAAAAAAAAA', data);
    const shipping_address = data.order[0]?.shipping_addresses;

    

    function handleButtonClick(orderItemId, status, order = null) {
        // console.log('status', status);
        // console.log('order', order);

        const variables ={
          orderItemId: orderItemId,
          status: status,
          company: company,
          trackNumber: trackNumber,
          orderId: order.id,
          shopOrderId : order.shop_orders[0].id,
          shippingAddressId: order.shipping_addresses[0].id,
        }
        // console.log('shopOrderId', variables.sh);

        const changeStatus = () => {
            
            updateOrderStatus({ variables: { shopOrderId: variables['shopOrderId'], status: status }})
                .then(() => {
                    refetch();
                })
                .catch(error => {
                    console.error('Error while change shop order status for shipping:', error);
                });
        };

       
        // console.log('variables', variables);
        // console.log('shippping_details ', shipping_address, company, trackNumber, order,status );

        saveShipping({ variables: { ...variables }})
                    .then(updateItemStatus({ variables: { orderItemId: orderItemId, status: status }}))
                    .then(changeStatus)
                    .catch(error => {
                        console.error('Error in handleButtonClick:', error);
                    });
                }


    
        if(data.order.length === 0){

            return ( <div className = {style.wrapper}>
                        <div className={style.title}>
                            {title} 
                        </div>
                      <div className={style.actions}>
                            {text}
                      </div>
                  </div>
            )   }




        return (
              <>
                <div className={style.wrapper}>
                  <div className={style.title}>
                    {title}
                  </div>

                {data.order.map((order) => (
          
                  // Only render if order items exist to prevent errors
                  order.order_items.length > 0 && ( 
                    <div key={order.id} className={style.orderContainer}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <div className={style.orderInfo}>
                          <div className={style.orderNumber}>Заказ от {formatStringDate(order.updated_on)}</div>
                        </div>
          
                        <div className={style.imageContainer}>
                          {order.order_items.map((order_item) => {
                            const { color, status } = formatStatus(order_item.status);
          
                            return ( 
                              <React.Fragment key={order_item.id}> 
                                <div className={style.itemImage}>
                                  <div className={style.itemStatus} style={{ color: color }}>{status}</div>
                                  <img src={order_item.item.item_media[0]?.path} alt="foto" className={style.avatar} /> 
                                  <div className={style.quanityContainer}>
                                    <div className={style.orderQuantity}>{order_item.quantity}шт.по {order_item.price} ₽</div>
                                    <div className={style.orderSum}> = {order_item.price * order_item.quantity} ₽</div>
                                  </div>
                                  <div className={style.addressContainer}>
                                    <span>{shipping_address[0].post_code}, г.{shipping_address[0].delivery_city.city}</span>
                                    <span>ул.{shipping_address[0].street}, {shipping_address[0].house}
                                      {shipping_address[0].flat && `-${shipping_address[0].flat}`}</span>
                                  </div>
          
                                  <div className={style.inputContainer}>
                                    <input className={style.inputField} placeholder='Компания' onChange={(e) => setCompany(e.target.value)} />
                                    <input className={style.inputField} placeholder='Трэк-номер' onChange={(e) => setTrackNumber(e.target.value)} />
                                    <button className={style.buttonSave} onClick={() => handleButtonClick(order_item.id, 'shipping', order)}>
                                      Сохранить
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    <span style={{ 'border': '2px solid #EDEDED'  }}></span>

                    </div>
                  )
                ))}
                </div>
              </>
            );

          
            

}



  




export default function ShopOrders() {
    const location = useLocation()
    const shopId = location.state.shopId
    const orderStatus  = location.state.status || "payed";

    // console.log('shop orders',  orderStatus)

    

    // const { title} = titleOrders(orderStatus);    


   
        return (
            <>
             {/* <div className={style.wrapper}> */}
                {/* <div className={style.title}> */}
                    {/* {title} */}
                {/* </div> */}

                
                {orderStatus === 'confirmed' ? <ShopOrdersForDelivery shopId={shopId} orderStatus={orderStatus}   /> :
                <ShopOrdersForApprve  orderStatus = {orderStatus} shopId={shopId}  />}
                {/* {orderStatus ==='shipping' && <ShopOrdersInShipping shopId={shopId} orderStatus={orderStatus}  /> } */}
        
              
            </>
        );
                            }        