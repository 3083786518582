import { Co2Sharp } from "@mui/icons-material"

export const getItemEconomy = ({ price, quantity, lots }) => {
  const lotsPrices = lots?.map(({ price }) => price) || [0]
  const maxPrice = Math.max(...lotsPrices)
  return quantity * (maxPrice - price)
}

export const getTotalEconomy = (items) => {

  // console.log('items for total economy', items) 

  return items.reduce((acc, item) => {
    // console.log('item', item)

    const itemEconomy =  getItemEconomy({
      price: item.lot.price,
      quantity: item.quantity,
      lots: item.item.lots,
    })
    return acc + itemEconomy
  }, 0)
}

export const populateItemsWithMaxPrice = (items, shouldAddMods = false) => items.map((item) => {


  const itemPrice = item?.lot?.price


  const lots= item?.item?.lots

  // need for payment page
  const allLotPrices = lots?.map(({ price }) => price) || [0]
  const maxItemPrice = Math.max(...allLotPrices)
  

  item.item.step = item?.lot?.step

  const modifiers = []
  return {
    ...item,
    itemPrice,
    maxItemPrice,
    ...(shouldAddMods ? { modifiers: [] } : {})
  }
})
