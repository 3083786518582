import React, {useContext, useEffect, useState, useCallback} from 'react';
import {ModalContext, TelegramContext} from '../../../App';
import styles from './Registration.module.css';
import ColoredButton from '../ProfileMain/components/ColoredButton/ColoredButton';
import ProfileInput from '../ProfileMain/components/ProfileInput/ProfileInput';

import Separator from '../../../Сomponents/Separator/Separator';
// import {useLocation} from 'react-router-dom';
import {useMutation, useLazyQuery} from '@apollo/client';
import {ADD_USER_PROFILE, CHECK_USER_PROFILE} from '../../../requests/requests';
import RegisteredPhone from '../ProfileMain/Phone/RegisterdPhone';


import {useNavigate} from 'react-router-dom';

import { registerUser } from '../../../utils/apiCalls/authApi';
import { parseJwt } from '../../../utils/cookies';

import Cookie from 'js-cookie';





const useTextValidation = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = useCallback((inputValue) => {
        const regex = /^[a-zA-Zа-яА-Я-]*$/;
        if (regex.test(inputValue)) {
            setValue(inputValue);
        }
    }, []);

    return [value, handleChange];
};


const usePhoneValidation = () => {
    const [phone, setPhone] = useState('+');
    const [phoneError, setPhoneError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const handlePhoneChange = useCallback((inputValue) => {
        setPhoneError(false);
        setIsVerified(false);

        const regex = /^\+?[0-9]{0,13}$/;

        if (regex.test(inputValue)) {
            setPhone(inputValue);
        }

        const regexFull = /(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){7,15}(\s*)/;
        if (!regexFull.test(inputValue)) {
            setPhoneError(true);
        }
    }, []);

    return [phone, handlePhoneChange, phoneError, isVerified, setIsVerified];
};

const useEmailValidation = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const handleEmailChange = useCallback((inputValue) => {
        setEmailError(false);
        setEmail(inputValue);
    }, []);

    const validateEmail = useCallback((inputValue) => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!regex.test(inputValue)) {
            setEmailError(true);
        }
    }, []);

    return [email, handleEmailChange, emailError, validateEmail];
};

const Registration = () => {

    let {setUserFirstName, userID, setUserID} = useContext(TelegramContext);

    
    const navigate = useNavigate();


    const {setModalActive, setModalContent} = useContext(ModalContext);
    const [name, handleNameChange] = useTextValidation('');
    const [surname, handleSurnameChange] = useTextValidation('');
    const [phone, handlePhoneChange, phoneError, isVerified, setIsVerified] = usePhoneValidation();
    const [phoneCode, setPhoneCode] = useState('');
    const [email, handleEmailChange, emailError, validateEmail] = useEmailValidation();
    const [disabled, setDisabled] = useState(true);
    const currency = 'RUB';

    const [loadUserProfile, {loading, error, data}] = useLazyQuery(CHECK_USER_PROFILE);

    // console.log("UserRegistration");

    useEffect(() => {
        loadUserProfile({
            fetchPolicy: 'cache-and-network',
            variables: {userID},
        });
    }, [loadUserProfile, userID]);

    // const [addUserProfile] = useMutation(ADD_USER_PROFILE, {
    //     variables: {
    //         // address_id,
    //         currency,
    //         email,
    //         name,
    //         phone,
    //         surname,
    //         userID,
    //     },
    // });

    useEffect(() => {
        if (name.length >= 3 && surname.length >= 2 && isVerified) {
            setDisabled(false);
        }
    }, [name, surname, isVerified]);

    useEffect(() => {
        if (email !== '') {
            validateEmail(email);
        }
    }, [email, validateEmail]);

    if (loading) {
        return <div>Loading...is userProfile</div>;
    }
    if (error) {
        console.log('error', error  );
        return <div>Error!</div>;
    }

    let isUserExist;
    if (data) {
        isUserExist = data.user_profile[0]?.id;
    }

    const handleSubmit = () => {
        // console.log('handleSubmit');
        if (!isUserExist) {
            if (!emailError) {
                    try {
                        
                            registerUser({name, surname, phone, email})
                            .then(accessToken => {
                                // console.log('Retrieved Access Token:', accessToken);
                                // Use the access token as needed
                                // console.log('newToken', accessToken);
                                Cookie.set('token', accessToken);
                                
                                const decodedToken = parseJwt(accessToken);
                                // console.log('decode token' ,decodedToken);
                                setUserID( decodedToken['https://hasura.io/jwt/claims']['X-Hasura-User-Id']);
                                setUserFirstName( decodedToken['https://hasura.io/jwt/claims']['x-user-firstname'])
                                navigate('/profile')

                            })
                            .catch(error => {
                                alert('Произошла ошибка при регистрации.');
                                console.error('Error while registering user:', error);
                            });

                            


                            // navigate('/profile')
                            
                        }
                    catch(e) {
                        console.log(e);
                
                }
            }
        } else {
            setModalActive(true);
            setModalContent(<div style = {{height: '50vh'}}>Вы уже зарегистрированы ранее</div>);
        }
    };

    return (
        <>
            
                <div className = {styles.wrapper}>
                    <h3 className = {styles.title}>Регистрация</h3>
                    <Separator/>

                    <div className = {styles.form}>
                        <ProfileInput
                            label = "Фамилия:"
                            type = "text"
                            value = {surname}
                            callback = {handleSurnameChange}
                            placeholder = "Введите фамилию..."
                        />
                        <ProfileInput
                            label = "Имя:"
                            type = "text"
                            value = {name}
                            callback = {handleNameChange}
                            placeholder = "Введите имя..."
                        />
                        <RegisteredPhone
                            phone = {phone}
                            phoneCode = {phoneCode}
                            setPhoneCode = {setPhoneCode}
                            phoneInputcallback = {handlePhoneChange}
                            isVerified = {isVerified}
                            setIsVerified = {setIsVerified}
                            phoneError = {phoneError}
                        />
                        <div style = {{color: 'red', height: '2vh'}}>
                            {phoneError && <span>Пожалуйста, введите корректный номер телефона </span>}
                        </div>
                        {/* <PhoneNumberInput /> */}
                        <ProfileInput
                            label = "email:"
                            type = "mail"
                            value = {email}
                            callback = {handleEmailChange}
                            placeholder = "Введите e-mail..."
                        />
                        <div style = {{color: 'red', height: '2vh'}}>
                            {emailError && <span>Пожалуйста, введите корректный mail-адрес </span>}
                        </div>

                        <ColoredButton name = "Зарегистрироваться" disabled = {disabled} callback = {handleSubmit}/>
                    </div>
                </div>
        </>
    );
};

export default Registration;