import React, {useState, useEffect} from "react";
import styled from "./CompanyRegestration.module.css";
import styleButton from "./CheckButton.module.css"
import {  useForm } from "react-hook-form"
import ColoredButton from "../components/ColoredButton/ColoredButton";
import {useMutation} from '@apollo/client';
import { ADD_COMPANY } from "../../../../requests/companyRequest";
import { useContext } from "react";
import { TelegramContext } from "../../../../App";
import { useNavigate } from "react-router-dom";



import styles from "./SuccessSellerRegistrationChildren.module.css"



function SuccessRegistration(){
    const navigate = useNavigate();
        setTimeout(() => {
            navigate('/profile/my_shops')
        }, 3000);
        return (

            <div className = {styles.wrapper}>
                <div className = {styles.titleContainer}>
                    <h1 className={styles.title1}> ПСП </h1>
                <h2 className = {styles.title2}>платформа совместных покупок</h2>
                </div>
                <div className = {styles.separator}/>


                <div className = {styles.сontainer}>
                    <h2 className = {styles.title3}>Благодарим за регистрацию!</h2>
                    <h2 className = {styles.title3}>Вместе можем больше!</h2>
                </div>
            </div>)

}








const checkButtonDisplayTypes = {
    default: "DEFAULT", inCheck: "IN_CHECK", success: "SUCCESS", error: "ERROR"
}

function CheckButton({iNN, callback, checkButtonStyle}){
    // console.log('CheckButton', iNN)

    const getTitles = () => {
        switch (checkButtonStyle) {
            case checkButtonDisplayTypes.default:
                return "Найти"
            case checkButtonDisplayTypes.inCheck:
                return "Проверка..."
            case checkButtonDisplayTypes.success:
                return "Haйдено"
            case checkButtonDisplayTypes.error:
                return "Не найдено"
            default:
                return "Найти"
        }
    }

    const getClassNames = () => {
        switch (checkButtonStyle) {
            case checkButtonDisplayTypes.default:
                return `${styleButton.applyButton} ${styleButton.check}`
            case checkButtonDisplayTypes.inCheck:
                return `${styleButton.applyButton} ${styleButton.check}`
            case checkButtonDisplayTypes.success:
                return `${styleButton.applyButton} ${styleButton.valid}`
            case checkButtonDisplayTypes.error:
                return `${styleButton.applyButton} ${styleButton.error}`
            default:
                return `${styleButton.applyButton} ${styleButton.check}`
        }
    }

    const title = getTitles()
    const style = getClassNames()


    return (<button className={style}   
                disabled = {iNN.length <= 9  || iNN.length >= 13} 
            onClick = { callback}
            >{title}</button> )
}


function TaxButton ({taxValue, setValue}) {
  const taxButtons = ["Основная", "Упрощенная", "Патентная"];

  return (
    <div className={styled.buttonContainer}>
      {taxButtons.map((taxButton, i) => (
        <button
          type="button"
          className={`${styled.taxButton} ${
            // selectedTaxButton === taxButton ? styled.active : ""
            taxValue === taxButton ? styled.active : ""
          }`}
          key={taxButton}
        //   onClick={() => setSelectedTaxButton(taxButton)}
          onClick={() => setValue(taxButton)}
        >
          {taxButton}
        </button>
      ))}
    </div>
  );
};




    /* letter-spacing: 0px; */




const CompanyRegestration = () => {

    const [successRegistration, setSuccessRegistration] = useState(false)
    const [checkButtonStyle, setCheckButtonDisplayTypes] = useState(checkButtonDisplayTypes.default)
    const [onErrorMessage, setOnErrorMessage] = useState(false);
    const [checked, setChecked] = useState(false);
    const [taxValue, setTaxValue] = useState(null)
    const [mutationError, setMutationError] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const [disabled, setDisabled] = useState(true);


    const {userID} = useContext(TelegramContext);


    const {
        register,
        handleSubmit,   
        watch,
        formState: { errors, isValid},
        getValues,
        setValue,
      } = useForm({
        defaultValues : {
            inn : '',
            kpp : '',
            ogrn : '',
            name : '',
            address : '',
         

        }
      })
    

    //ADD_SHOP
    const [addCompany, { data }] = useMutation(ADD_COMPANY);

    const onSubmit =async() => {
        try {
        let formData = getValues()
        const nameTax = {'Основная' : 'general', 'Упрощенная' : 'simlfiled', 'Патентная' : 'patent'}
        let taxes = nameTax[taxValue]

        const { data: mutationResult } = await addCompany({
            variables: {
              userID: userID,
              INN: formData.inn,
              OGRN: formData.ogrn,
              name: formData.name,
              address: formData.address,
              tax_system: taxes,
              kpp: formData.kpp,
            },
    });

    if (mutationResult) {
       setSuccessRegistration(true) 
      // setDisplayType(displaySellerRegistrationsTypes.success)

    }
    } catch (error) {
        setMutationError("An error occurred during submission.");
        console.log('error on mutation', error);
        setOnErrorMessage(true);
        setChecked(true);
    }
  };



    const iNN = watch("inn")


    // set default button if after check user will change iNN
    useEffect (() => {
        if (checked) {
            setCheckButtonDisplayTypes(checkButtonDisplayTypes.default)
            console.log('textValue', taxValue)
            if (taxValue) {
                setDisabled(false)
            }
            setChecked(false)
            setValue('ogrn' , '',)
            setValue('kpp' , '',)
            setValue('name' , '',)
            setValue('address' , '',)
            setFetchError(false)
            setOnErrorMessage(false)
        }
    }, [iNN])


    
    useEffect(() => {
        if (isValid && taxValue) {
            setDisabled(false)
        }else {
            setDisabled(true)
        }
    }, [taxValue, isValid])
    
    
    

    // console.log('isValid', isValid)
    // console.log('errors', errors)


    const checkInn = () => {
        const url = `https://api.jbspace.xyz/check_company/v1/inn_${iNN}`
        // const url =`http://127.0.0.1:8000/check_company/v1/inn_${iNN}`
    
        async function fetchData() {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const data = await response.json();
                // console.log('data', data)
                if(data){

                    setCheckButtonDisplayTypes(checkButtonDisplayTypes.success)
                    data && setValue("kpp", data.kpp)
                    data && setValue("ogrn", data.ogrn)
                    data && setValue("name", data.name)
                    data && setValue("address", data.address) 
                }
                else {
                    setCheckButtonDisplayTypes(checkButtonDisplayTypes.error)
                }
                setChecked(true)

            } catch (error) {
                setCheckButtonDisplayTypes(checkButtonDisplayTypes.error)

                setFetchError("Error occurred while fetching data.");
                console.log('error', error);
                setOnErrorMessage(true);
                setChecked(true)

    
            }
        }
        setCheckButtonDisplayTypes(checkButtonDisplayTypes.inCheck)
        fetchData();
    }
    
  
    if(successRegistration){
        return <SuccessRegistration/>
    }
    
    
    


    return (
        

        
        <div className={styled.wrapper}>
            <div className={styled.titleContainer}>
                <h3 className={styled.title}>Регистрация компании</h3>
                <span className={styled.text}>
                    нажимая кнопку “Стать продавцом!” Вы принимаете <span
                        className={styled.link} style ={{'color' : '#B5B5B5'}}>публичную оферту ПСП </span> и <span
                        className={styled.link} style ={{'color' : '#B5B5B5'}}>условия пользования площадки ПСП</span>
                </span>
            </div>

            {mutationError && <span style = {{color: "red"}}>{mutationError}</span>}
            {fetchError && <span style = {{color: "red"}} className={styled.error}>{fetchError}</span>}

            
            <form className = {styled.form} onSubmit={handleSubmit(onSubmit)}  >
                <p className={styled.innText}>
                    Введите ИНН, а остальное найдем мы!
                </p>
                <p className={styled.smalText}>Если поиск не удачный, 
                    то напишите нам в <span className={styled.link}>поддержку</span></p>
                
                <label className={styled.label}>ИНН: </label>
                <div className = {styled.container}>
                    <input className = {styled.registeredInput}
                             type="number" 
                             placeholder="ИНН" {...register("inn", 
                                    { required: true , minLength : 9 , maxLength : 13},)} 
                                    />
                    <CheckButton iNN ={iNN}  
                                callback = {checkInn}
                                checkButtonStyle = {checkButtonStyle}
                                />
                </div>

                <div style = {{height: "2vh"}}>
                    {errors.inn && <span style = {{color: "red"}}>Пожалуйста, введите корректный ИНН </span>}
                </div>
                     {onErrorMessage && <span style = {{color: "red"}}>возможно ошибка сети, попробуйте еще раз  </span>}
                
                <div className={styled.inputContainer}>
                <label className={styled.label}>КПП: </label>
                <label className={styled.label}>ОГРН(ОГРНИП): </label>
                </div>
                <div className = {styled.container}>
                    
                    <input className = {styled.registeredInput} 
                        placeholder="Заполнится автоматически" 
                        readOnly = {true}
                        {...register("kpp")} />
                    <input className = {styled.registeredInput}
                        placeholder="Заполнится автоматически" 
                        readOnly = {true}
                        {...register("ogrn", {required : true})} />                 
                </div>
                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Наименование юридического лица: </label>
                <textarea  className = {styled.registeredInputBig}
                    type = "textarea"
                    readOnly = {true}
                     placeholder="Заполнится автоматически" 
                    {...register("name",{required : true})} />

                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Юридический адрес: </label>
                <textarea  className = {styled.registeredInputBig} 
                    placeholder="Заполнится автоматически"
                    readOnly = {true}
                    {...register("address", {required : true})} />
                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Система налогооблажения: </label>

                <TaxButton taxValue = {taxValue} setValue = {setTaxValue}/>


                <ColoredButton name = {'Стать продавцом'} disabled={disabled} callback = {handleSubmit}/>
            </form>

            

        </div>
        
    )
    }

export default CompanyRegestration;
