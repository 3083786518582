import React, {useEffect, useState, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useForm, useFieldArray} from "react-hook-form";
import { SETTINGS_ITEMS } from "..";
import link from '../../../../../assets/svg/link.svg';
import info from '../../../../../assets/svg/info.svg';
import { modalDisplayMode } from "../../../../PrimaryModal/PrimaryModal";
import { useContext } from "react";
import { ModalContext } from "../../../../../App";
import goBack from '../../../../../assets/svg/goBack.svg';
import { useNavigate } from "react-router-dom";

import styles from "./Description.module.css";


import { updateInfoSettings } from "../../../../../features/settingItemSlice";





function FormField({refForm, label, type, placeholder, register, errors}) {
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    return (
        <div className = {styles.formItem} ref = {refForm}>
            <label className = {styles.label}>{label}</label>
            {type === "textarea" ? (
                <textarea
                    className = {styles.inputBig}
                    onKeyDown={handleKeyDown}

                    placeholder = {placeholder}
                    {...register}
                />
            ) : (
                <input className = {styles.input} 
                onKeyDown={handleKeyDown}
                type = {type} placeholder = {placeholder} {...register}/>
            )}
            {/*{errors && <span>Обязательное поле</span>}*/}
            <div className={styles.errorContainer}>
                {errors && <p className = {styles.errors}> {errors?.message || "Обязательное поле"}</p>}
            </div>


        </div>
    );
}


function ChooseButton({
                label,
                displayMode,
                setDisplayMode,
                placeholder,
                register,
                name,
                errors,

                    }) {
    function attributeHandler() {
            setDisplayMode(displayMode);
        }

    return (
        <div className = {styles.chooseForm} onClick = {attributeHandler}>
        <label className = {styles.label}>{label} </label>
        <div className = {styles.inputContainer}>
            <input
            className = {styles.inputField}
            type = "text"
            placeholder = {placeholder}
            {...register}
            />
            <img src = {link} alt = "link"/>
            </div>
    </div>
    );
    }


function handleCharacterInfo(setModalActive,
                            setModalContent,
                            setModalMode
                            ) {

            setModalMode(modalDisplayMode.withOverlay)
    const modalContent = (
                <div className = {styles.infoContainer}>
                    <h4 className = {styles.infoTitle}>Характеристики:</h4>
                    <span className = {styles.infoText}>
                    Здесь вы можете указать все характеристики товара по пунктам.
                </span>
                <div style = {{height: "2vh"}}/>
                <span className = {styles.infoText}>
                Параметр № - название характеристики товара.
                </span>
                <span className = {styles.infoText}>
                Значение № - значение заданной характеристики.
                </span>
                <div style = {{height: "2vh"}}/>
                <span className = {styles.infoText}>
                Вы можете добавить необходимое количество параметров, 
                которое требуется для определенного товара.
                </span>
                <div style = {{height: "2vh"}}/>
                <span className = {styles.infoText}>
                Рекомендуем не писать много текста в значении, лучше разбить на несколько параметров.
                </span>
                </div>
                )
                setModalContent(modalContent);
                setModalActive(true);
                }




function DescriptionSettings({
                setDisplayMode,
                formData,
                setFormData,
                condition,
                                }) {
    // const item = useSelector((state ) => state.settingsItem.item)
    const dispatch = useDispatch()
    const articleNumber = useSelector((state ) => state.settingsItem.articleNumber)
    const itemId = useSelector((state) => state.settingsItem.itemId )
    
    const {setModalActive, setModalContent, setModalMode} = useContext(ModalContext);

    
    const aboutRef = useRef(null);
    const descriptionRef = useRef(null);
    const characterRef = useRef(null);
    const gabaritsRef = useRef(null);

    const scrollToElement = (condition) => {

        switch (condition) {

        case 'about':
            aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        case 'character':
            characterRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        case 'gabarits':    
            gabaritsRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        case 'description':
            descriptionRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        
        default:
            break;
        }
      };
  
      useEffect(() => {
        scrollToElement(condition);
      }, [condition]);

 

    
    const {
            register,
            handleSubmit,
            watch,
    
            formState: {errors, isValid},
            getValues,
            control,
            } = useForm({
                    defaultValues: formData,
                    mode: "all", 
                    criteriaMode: 'all'
    })
    
    
    useEffect(() => { 
     setFormData(watch())
    }, [watch('name'),
            watch('brand'),
            watch('articleNumber'),
            watch('barcode'),
            watch('description'),
            watch('partNumber'),
            watch('waranty'),
            watch('quantity_box'),
            watch('weight'),
            watch('height'),
            watch('width'),
            watch('length'),
            watch('character')
        ])
    
    
    const {fields, append} = useFieldArray({
    name: "character",
    control,
    });
    
    const onSubmit = (data) => {
        console.log('Submit', data)
        console.log('save data', getValues())
        console.log('set_timer', getValues('character'))
        
        
        const characterValue = getValues('character');
        const characterVariable = {};

        characterValue.forEach((item) => {
            console.log('item', item)
          if (item.characterName && item.characterValue) {
            characterVariable[item.characterName] = item.characterValue;
          }
        });
        console.log('characterVariable', characterVariable)
        
        const info = {
            itemId : itemId,
            name: getValues('name'),
            brand: getValues('brand'),
            articleNumber: getValues('articleNumber'),
            barcode: getValues('barcode'),
            about: getValues('description'),
            partNumber: getValues('partNumber'),
            waranty: getValues('waranty'),
            // quantity_box: getValues('quantity_box'),
            // color: getValues('color'),
            country: getValues('country'),
            character: characterVariable,
            

            // weight: getValues('weight'),
            // height: getValues('height'),
            // width: getValues('width'),
            // length: getValues('length'),
        }
        console.log('info', info)


        dispatch(updateInfoSettings({info}));   
        // dispatch(setItemInfo(data))
        
        
        
        setDisplayMode(SETTINGS_ITEMS.default);
    
    }
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
        e.target.blur();
    }
    }
    
    
    return (
    
    
    <div className={styles.wrapper}>
    
    <div className = {styles.headerContainer}>
                        <img src = {goBack} alt = "back" onClick = {() => setDisplayMode(SETTINGS_ITEMS.default)}/>
                        <div className={styles.row}>
                            <h1 style={{ fontWeight: 400 }}>Артикул</h1>
                            <h1>{articleNumber}</h1>
                        </div>
                    </div>


             <h1 className={styles.header}>Информация о товаре</h1>
    {/* <span className = {styles.step}>Шаг 2/4</span> */}
    <form className = {styles.form} onSubmit = {handleSubmit(onSubmit)}>
    
    
    <ChooseButton
      refForm={aboutRef}
      label = "Бренд:"
      displayMode = {SETTINGS_ITEMS.brand}
      setDisplayMode = {setDisplayMode}
      placeholder = "Обязательно"
      register = {register('brand', {required: true})}
      errors = {errors.brand}
    
    />
    
    <FormField
      label = "Название:"
      type = "text"
      placeholder = "Обязательно"
      register = {register('name', {
          required: true,
          minLength: {value: 2, message: "Обязательно от 2 до 26 символов"},
          maxLength: {value: 50, message: "Обязательно от 2 до 50 символов"},
    
      })}
      errors = {errors.name}
    />
  
  
    <FormField
      label = "Артикул:"
      type = "text"
      placeholder = {`Обязательно`}
      register = {register('articleNumber', {required: true})}
      errors = {errors.articleNumber}
    />
  
    <FormField
      label = "Штрихкод:"
      type = "text"
      placeholder = {`Обязательно`}
  
      // register = {register('barcode', {required: true, valueAsNumber: true,})}
      register = {register('barcode', {
    
          validate: {
              validateNumber: (_, values) => {
                  const reg = new RegExp('^[0-9]+$');
                  return reg.test(values.barcode) || "Только цифры"
              },
          
          
          }
          ,
      
          required: true,
      })}
      errors = {errors.barcode}
    />
  
    <FormField
      label = "Партномер (заводской артикул):"
      type = "text"
      placeholder = {`Необязательно`}
      register = {register('partNumber')}
      errors = {errors.partNumber}
    />
  

    <FormField  
      refForm={descriptionRef}
      label = "Описание:"
      type = "textarea"
      placeholder = {`Обязательно`}
      register = {register('description', {required: true})}
      errors = {errors.description}
    />
  
  
    <div className = {styles.formCharacter} ref = {characterRef}>
      <div className = {styles.labelIcon}>
          <label className = {styles.label}>Характеристики: </label>
          <img src = {info} alt = "info" onClick = {() => handleCharacterInfo(
              setModalActive,
              setModalContent,
              setModalMode)}/>
      </div>
      {fields.map((field, index) => {
              return <section className = {styles.characterContainer}
                              key = {field.id}>
                  <input className = {styles.inputCharacter}
                      onKeyDown={handleKeyDown}
    
                         type = "text"
                         placeholder = {`параметр ${index + 1}`}
                         {...register(`character.${index}.characterName`)}/>
    
    
                  <input type = "text"
                         className = {styles.inputCharacter}
                          onKeyDown={handleKeyDown}
                         
                         placeholder = {`значение ${index + 1}`}
                         {...register(`character.${index}.characterValue`, {minLength: 1})}/>
              </section>
          }
      )}
      <span className = {styles.addCharacter}
            type = "button" onClick = {() => append({
          characterName: "",
          characterValue: "",
      })}>
          Добавить параметр</span>
  
    </div>
    
  
     <ChooseButton 
      label = "Страна производитель"
      displayMode = {SETTINGS_ITEMS.country}
      setDisplayMode = {setDisplayMode}
      placeholder = "Необязательно"
      register = {register('country')}
      // name="country"
      errors = {errors}
    />
    <FormField
      label = "Гарантиейный срок:"
      type = "text"
      placeholder = "Необязательно"
      register = {register('waranty')}
      errors = {errors.waranty}
    />
  
  
     {/* <div className = {styles.formCharacter}>  
      <div className = {styles.labelIcon}>
          <label className = {styles.label}>Количество заводских упаковок: </label>
          <img src = {info} alt = "info" onClick = {() => handleQuantityInfo(
              setModalActive,
              setModalContent,
              setModalMode)}/>
    
      </div>
      <input
          className = {styles.input}
          placeholder = "Необязательно"
          onKeyDown={handleKeyDown}
        
          type = "number" {...register('quantity_box',)} />
    </div>
    <div className = {styles.formCharacter}>
      <div className = {styles.labelIcon}>
          <label className = {styles.label}>Товар в нескольких упаковках? </label>
          <img src = {info} alt = "info" onClick = {() => handleSeveralBoxInfo(setModalActive,
              setModalContent,
              setModalMode
          )}/>
      </div>
        
      <BoxButton boxValue = {boxButton} setValue = {setBoxButton}/>
    </div>
     <ChooseButton 
      label = "Цвет:"
      displayMode = {ADD_ITEM_MODES.color}
      setDisplayMode = {setDisplayMode}
      placeholder = "Необязательно"
      register = {register('color',)}
      // name="color"
      errors = {errors}
        
        
    />
    
    
        
    
    <FormField
      refForm={gabaritsRef}
      label = "Вес с упаковкой (г):"
      type = "number"
      placeholder = "Обязательно"
      register = {register('weight', {required: true})}
      errors = {errors.weight}
    />
    
    <FormField
      label = "Длина упаковки (мм):"
      type = "number"
      placeholder = "Обязательно"
      register = {register('length', {required: true})}
      errors = {errors.length}
    />
    <FormField
      label = "Ширина упаковки (мм):"
      type = "number"
      placeholder = "Обязательно"
      register = {register('width', {required: true})}
      errors = {errors.width}
    />
    <FormField
      label = "Высота упаковки (мм):"
      type = "number"
      placeholder = "Обязательно"
      register = {register('height', {required: true})}
      errors = {errors.height}
    />
    */}
        
    <button className = {styles.addCategory} disabled = {!isValid } type = "submit">
      Готово
    </button>
    
        
        
    </form>
    </div>
    
        
    )
    }
    
    
export default DescriptionSettings;