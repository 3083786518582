import axios from 'axios';


export const registerUser = async ({name, surname, phone, email}) => {
    

    console.log('registerUser', name, surname, phone, email)

    try {
        // const res = await axios.post(`http://127.0.0.1:8002/register_user`, {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register_user`, {
          variables: {
            name, 
            surname, 
            phone,
            email,
  
          }
        }, {
          headers: {
            "content-type": "application/json",
            // "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
          }
        });
        const accessToken = res.data.access_token;
        console.log('Access Token:', accessToken);
        return accessToken
      } catch (error) {
        await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: `error while confirm order ${error.message}`, }),
        });
        alert("Произошла ошибка при регистрации.");
      }
    }


  export const sendCode = async (phone) => {

    try {
        const res = `${process.env.REACT_APP_API_URL}/send_sms/${phone}`;
        // const res = await axios.get(`http://127.0.0.1:8002/send_sms/${phone}`);
        return res.data
    }catch (error) {
        console.error("An error occurred:", error);
    }
           
  }


export async function fetchAccessToken(role = 'guest') {

    const url = `${process.env.REACT_APP_API_URL}/auth/${role}`;
    // const url = `http://127.0.0.1:8002/auth/${role}`; 

    // console.log('fetchAccessToken');
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const newToken = data.access_token;
        const userFirstName = data.name;
        const userID = data.user_id;


        return {newToken, userFirstName, userID};
    } catch (error) {
        console.error("Error fetching the access token: ", error);
    }
}
