import React, {useContext} from 'react';
import classes from "./ProfileHeader.module.css"
// import Share from '../../ProductCard/Shop/SharingPage/Share';
// import shareImage from '../../../assets/svg/Share.svg';
// import {ModalContext, TelegramContext} from '../../../App';
// import ShareButton from '../../ShareButton/ShareButton';

import {useNavigate} from 'react-router-dom';


function ProfileHeader({mainTitle, avatarUrl, likes = 0, comments = 0, score = 0, userProfile}) {
    // const {setModalActive, setModalContent} = useContext(ModalContext);
    // const {userID} = useContext(TelegramContext);

    const navigate = useNavigate()


    const getInitialsFromFullName = (mainTitle) => {
        const nameLettersArray = mainTitle.split(' ');
        switch (nameLettersArray.length) {
            case 2:
                return `${nameLettersArray[0].slice(0, 1)}${nameLettersArray[1].slice(0, 1)}`
            case 1:
                return `${nameLettersArray[0].slice(0, 1)}`
            default:
                return 'A'
        }
    }

    // const shareCallbackHandler = () => {
    //     setModalActive(true);
        
    //     setModalContent(<Share url = {'https://t.me/item_market_bot'}/>);
    // }
    
    return (<div className = {classes.header}>
        <div className = {classes.avatarContainer}>
            {avatarUrl ? <img className = {classes.avatar} src = {avatarUrl} alt = 'avatarUrl'/> :
                <div className = {classes.noPhoto}>{getInitialsFromFullName(mainTitle)}</div>}
        </div>
        <div className = {classes.name}>{mainTitle}</div>
        {/* <div className = {classes.linkText} onClick = { () => {navigate("setting", {state: {userProfile}})}>Управление профилем</div> */}
        <div className={classes.linkText} onClick={() => navigate("setting", { state: { userProfile } })}>
                Управление профилем
        </div>
        <div className = {classes.allInformation}>
            <div className = {classes.profileSlide} onClick = {() => {
                navigate("/likes")
            }}>
                <div className = {classes.headerText}>Избранное</div>
                <div className = {classes.headerInfo}>{likes}</div>
            </div>
            <div className = {classes.profileSlide} onClick = {() => {
                navigate("/profile/reviews")
            }}>
                <div className = {classes.headerText}>Мои&nbsp;отзывы</div>
                <div className = {classes.headerInfo}>{comments}</div>
            </div>

            <div className = {classes.profileSlide} 
                // onClick={() => navigate("/profile/buyer/bonus", {state: {userID}})}
                >

                <div className = {classes.headerText}>Баллы&nbsp;и&nbsp;бонусы</div>
                <div className = {classes.headerInfoHide}>{score}</div>
            </div>

        </div>
        {/*shareContainer*/}
        {/* <div className = {classes.shareContainer}>
            <ShareButton title = {"Поделиться профилем"} callback = {shareCallbackHandler}/>
        </div> */}


       

    </div>);
}

export default ProfileHeader;
