import React, {useEffect, useState} from 'react';
import styles from "./DefaultDisplayProfileSetting.module.css"
import {useNavigate} from 'react-router-dom';
import SettingHeader from '../ProfileSetting/SettingHeader/SettingHeader';
import ProfileInputWithLinks from '../components/ProfileInputWithLinks/ProfileInputWithLinks';
import LogOutContainer from '../components/LogOutContainer/LogOutContainer';
import RegisteredEmail from '../Email/RegisterdEmail/RegisteredEmail';
import ProfileNotification from '../Notification/ProfileNotification/ProfileNotification';
import ProfileSelectWithLinks from '../components/ProfileSelectWithLinks/ProfileSelectWithLinks';
import {UPDATE_USER_PROFILE} from '../../../../requests/userRequests';
import {useMutation} from '@apollo/client';
import ColoredButton from '../components/ColoredButton/ColoredButton';
import {displayTypes} from '../ProfileSetting';

import ImageUploader from './ImageUploader';
import Separator from '../../../../Сomponents/Separator/Separator';
import ProfilePhoneApplyButton from '../Phone/ProfilePhoneApplyButton';

import Cookies from 'js-cookie';

import {fetchAccessToken} from '../../../../utils/apiCalls/authApi';
import {useContext} from 'react';
import {TelegramContext} from '../../../../App';


function DefaultDisplayProfileSetting({
                                          
                                          name,
                                          gender,
                                          setGender,
                                          dateBirthday,
                                          mail,
                                          surname,
                                          middleName,
                                          setDisplayType,
                                          setDateBirthday,
                                          setEmailError,
                                          setMail,
                                          photoUrl,
                                          setPhotoUrl,
                                          verificationCode,
                                          setVerificationCode,
                                          phone,
                                          emailError,
                                          isVerified,
                                          setIsVerified,
                                          notifications,
                                      }) {

    let {userFirstName, setUserFirstName, userID, setUserID} = useContext(TelegramContext);
    

    const navigate = useNavigate();
    const settingHeadercallback = () => {
        navigate(-1)
    }

    const [dateError, setDateError] = useState(false)


    useEffect(() => {
        mail !== "" && mailValidation(mail)
    }, [mail])

    const emailInputcallback = (value) => {
        setEmailError(false)
        setMail(value)
        setIsVerified(false)
    }

    const mailValidation = (value) => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!regex.test(value)) {
            setEmailError(true)
        }
    }

    const genders = [
        {id: 1, title: "-", value: "-"},
        {id: 2, title: "Мужской", value: "Мужской"},
        {id: 3, title: "Женский", value: "Женский"}
    ]

    const genderLetter =
        gender === "-"
            ? "-"
            : gender === "Мужской" ? "м" : "ж"
    const genderCallback = (e) => {
        setGender(e.currentTarget.value)
    }


    const stringNowDate = new Date().toISOString().slice(0, 10)

    //get string  with active notifications only
    const getNotificationString = () => {
        return notifications.filter(n => n.checked).map(n => n.title).join(", ")
    }
    const getNotificationsForUpdates = (id) => notifications.filter(n => n.id === id)[0].checked ? 1 : 0
    const notificationsOnClickHandler = () => setDisplayType(displayTypes.changeNotes)


    const logOutTitles = ["Выйти из аккаунта", "Удалить аккаунт"]


    const [updateUserProfile, {data, loading, error}] = useMutation(UPDATE_USER_PROFILE, {
        variables: {
            birthday: (new Date(dateBirthday)),
            email_notify: getNotificationsForUpdates(2),
            email_verify: isVerified ? 1 : 0,
            mail,
            phone,
            sms_notify: getNotificationsForUpdates(3),
            middlename: middleName,
            telegram_notify: getNotificationsForUpdates(1),
            gender: genderLetter,
            userID
        },
    });


    //change birthday
    const dateInputcallback = (e) => {
        setDateError(false)
        let year = (e.currentTarget.valueAsDate.getUTCFullYear())
        if (year < 1940) setDateError(true)
        setDateBirthday((new Date(e.currentTarget.value)).toISOString().slice(0, 10))
    }






    //change displayType for conditional rendering to change the name
    const changeNameOnClickHandler = () => setDisplayType(displayTypes.changeName)

    //change displayType for conditional rendering to change the phone number
    const changePhoneOnClickHandler = () => {
        setDisplayType(displayTypes.changePhone)
    }

    const changeEmailOnClickHandler = () => {
        setDisplayType(displayTypes.changeEmail)
    }

    const deleteProfile = () => {
        console.log("deleteProfile")      
    }
    
    async function logOutProfile() {
            const role = 'guest';
            const response = await fetchAccessToken(role); 

            let newToken = '';
            let userFirstName = '';
            ({ newToken, userID, userFirstName } = response);
            // Cookies.remove('token');
            setUserID(userID)
            setUserFirstName(userFirstName)

            Cookies.set('token', newToken, { expires: 7, secure: true, sameSite: 'lax' });

            navigate(-1)
    }
    const logOutCallback = [logOutProfile, deleteProfile]


    //updated data in database
    const onSubmitButtonCallback = () => {
        updateUserProfile({
            variables: {

                birthday: (new Date(dateBirthday)),
                email_notify: getNotificationsForUpdates(2),
                email_verify: isVerified ? 1 : 0,
                mail,
                phone,
                sms_notify: getNotificationsForUpdates(3),
                middlename: middleName,
                telegram_notify: getNotificationsForUpdates(1),
                gender: genderLetter,
                userID
            }
        })
        navigate("/profile")

        // setTimeout(() => {
        // navigate("/profile")
        // }, 100)

    }


    return (<div className = {styles.wrapper}>
        <div className = {styles.topContent}>
            <SettingHeader title = {"Мои данные"} callback = {settingHeadercallback}/>

            <ImageUploader userID = {userID}
                // serIsDisabled = {setIsDisabled}
                           photoUrl = {photoUrl}
                           setPhotoUrl = {setPhotoUrl}
                // setObjAvatarUrl = {setObjAvatarUrl}
            />

            <div className = {styles.content}>
                <div className = {styles.name}>{`${name} ${surname}`}</div>
                <div className = {styles.change} onClick = {changeNameOnClickHandler}>Изменить</div>
            </div>
            <Separator/>
        </div>

        <div className = {styles.bottomContent}>
            <div style = {{height: "2vh"}}></div>
            <div className = {styles.registeredPhoneContainer}>
                <ProfileInputWithLinks label = {"Телефон"}
                                       type = {""}
                                       readOnly = {true}
                                       onClickCallback = {changePhoneOnClickHandler}
                                       value = {phone}
                                       placeholder = {"-"}
                                       isLinksDisplay = {true}/>
                <ProfilePhoneApplyButton isVerified = {phone !== ''} onClickCallback = {changePhoneOnClickHandler}/>
            </div>

            <div style = {{height: "2vh"}}></div>
            <RegisteredEmail mail = {mail}
                             emailError = {emailError}
                             emailInputcallback = {emailInputcallback}
                             isVerified = {isVerified}
                             setIsVerified = {setIsVerified}
                             verificationCode = {verificationCode}
                             setVerificationCode = {setVerificationCode}
            
            />

            <div style = {{color: "red", height: "2vh"}}>
                {emailError && <span>Пожалуйста, введите корректный mail-адрес </span>}
            </div>
            <ProfileInputWithLinks label = {"День рождения"}
                                   type = {"date"}
                                   callback = {dateInputcallback}
                                   value = {dateBirthday}
                                   placeholder = {"-"}
                                   isLinksDisplay = {false}
                                   maxData = {stringNowDate}
                                   minData = {"1980-01-01"}
                                   required = {true}
                // readOnly = {true}

            />
            <div style = {{color: "red", height: "2vh"}}>
                {dateError && <span>Пожалуйста, введите корректную дату </span>}
            </div>
            <ProfileSelectWithLinks label = {"Пол"} values = {genders} value = {gender}
                                    callback = {genderCallback} isWithLink = {true}/>

            <ProfileNotification title = {getNotificationString()} callback = {notificationsOnClickHandler}/>
            <div className = {styles.buttonContainer}>
                <ColoredButton name = {"Сохранить"}
                               callback = {onSubmitButtonCallback}/>
            </div>


            <LogOutContainer titles = {logOutTitles} callbacks = {logOutCallback}/>
        </div>

    </div>)

}


export default DefaultDisplayProfileSetting;


