import {gql} from '@apollo/client';


// export const PRODUCT_CATEGORIES_ITEMS = gql`
//     query MyQuery {
//         category_item {
//             category_id
//             item_id
//             category {
//                 product_category
//             }
//         }
//     }
// `;


export const BASKET_AMOUNT = gql`
    query loadBasket($userID: bigint) {

        cart_aggregate(where: {user_id: {_eq: $userID}}) {
            aggregate {
              count(columns: id)
            }
          }
    }
`;

// cart(where: {user_id: {_eq: $userID}}) {
    // id
// }

export const AMOUNT_SHOP_ITEM_LIKES = gql`
    query AmountShopItemLikes($userID: bigint) {
        users_likes_aggregate(where: { user_id: { _eq: $userID } }) {
            aggregate {
                count(columns: item_id)
            }
        }
        shops_likes_aggregate(where: { user_id: { _eq: $userID } }) {
            aggregate {
                count(columns: shop_id)
            }
        }
    }
`;

export const LIKES_AMOUNT = gql`
    query LoadLikes($userID: bigint) {
        users_likes(where: { user_id: { _eq: $userID } }) {
            item_id
        }
    }
`;


export const UPDATE_CITY = gql`
    mutation updateCity( $city_id: Int, $userID: bigint ) {
        update_user(where: {user_id: {_eq: $userID}}, _set: {city_id: $city_id}){
            affected_rows
        }
    }`;




export const ADD_SHOP_MESSAGE = gql`
    mutation addShopMessage($userID: bigint,$shop_id: Int, $message: String, $reason: String, $status: String) {
        insert_shop_messages(
            objects: { user_id: $userID, 
                        shop_id: $shop_id, 
                        message: $message, 
                        reason: $reason, 
                        status: $status, 
                        created_on: "now()"}
        ) {
            affected_rows
        }
    }
`;
// export const SHOPS_NAMES = gql`
//     query shopNames {
//         shop {
//             id
//             name
//         }
//     }
// `;


export const TEST_ITEMS = gql`
    query getItem ($userID : bigint, $category : String) {
        item(offset: 10, limit: 10, where: {category_items: {category: {category: {_eq: $category}}}}) {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
            users_likes(where: { user_id: { _eq: $userID } }) {
                item_id
            }
        }
    }
`;


export const CHECK_CITY = gql`
    query checckCity($id: Int) {
        delivery_city(where: {id: {_eq: $id}}) {
            city
            code_sdek
            id
        }
    }
`;


export const BASKET_ITEM = gql`
    query MyQuery($id: Int) {
        item(where: { id: { _eq: $id } }) {
            about
            id
            joint_promo
            name
            price
            shop {
                name
            }
            images_items {
                url
            }
        }
    }
`;


export const PURCHASE = gql`
    query MyQuery {
        order(
            where: {
                line_items: {
                    item_id: { _in: [1913, 1909, 1968, 1915, 1903, 1905, 1907, 1911] }
                }
            }
        ) {
            id
            paid
            line_items {
                item_id
                quantity
            }
        }
    }
`;


export const CHECK_USER_PROFILE = gql`
    query checkUserProfile($userID: bigint) {
        user_profile(where: {user_id: {_eq: $userID}}) {
            id
        }

    }
`;
export const ADD_USER_PROFILE = gql`
    mutation addUserProfile(
        $currency: String,
        $mail: String,
        $name: String,
        $phone: String,
        $surname: String,$userID: bigint)
    {
        insert_user_profile(
            objects: {
                user_id: $userID,
                currensy: $currency,
                mail: $mail,
                name: $name,
                phone: $phone,
                surname: $surname,
                telegram_notify: "1",
                sms_notify: "1",
                gender: "-"
            })
        { affected_rows }

    }`;

export const USER_PROFILE = gql`
    query userProfile($userID: bigint) {
        user_profile(where: {user_id: {_eq: $userID}}) {
            address_id
            birthday
            currensy
            email_notify
            gender
            id
            mail
            email_verify
            name
            middlename
            phone
            photo_url
            sms_notify
            surname
            telegram_notify
        }

        user(where: {user_id: {_eq: $userID}}) {
            id
            user_city {
                city
                id
            }
        }

        shop(where: {owner_id: {_eq: $userID}}) {
            id
            name
        }
    }
`;


export const DELETE_USER_PROFILE = gql`
    mutation MyMutation ($userID: bigint){
        delete_user_profile(where: {user_id: {_eq: $userID}}) {
            affected_rows
        }
    }`

export const CHECK_CITY_TITLE = gql`

    query MyQuery($id: Int) {
        delivery_city(where: {id: {_eq: $id}}) {
            city
        }
    }
`;

export const ALL_USERS_SHOPS = gql`
    query MyQuery($userID: bigint) {
        shop(where: {owner_id: {_eq: $userID}}) {
            id
            info
            name
            photo
        }
    }
`

// 
// export const ADD_SHOP = gql`
// mutation MyMutation($name: String, $city_id: Int, $info: String,$userID: bigint) {
// insert_shop(objects: {name: $name,
// owner_id: $userID,
// city_id: $city_id,
// info: $info
// }) {
// affected_rows
// returning {
// id
// }
// }
// }
// `
// 
// 
// export const ADD_SHOP_DOCS8 = gql`
// mutation MyMutation($INN: String,
// $OGRN: String,
// $company_title: String,
// $company_address: String,
// $bank_title: String,
// $bic: String,
// $type_of_business: String,
// $tax_system: String,
// $shop_id: Int) {
// insert_docs(objects: {INN: $INN,
// OGRN: $OGRN,
// bank_title: $bank_title,
// company_title: $company_title,
// company_address: $company_address,
// bic: $bic,
// type_of_business: $type_of_business,
// tax_system: $tax_system,
// shop_id: $shop_id})
// {
// affected_rows
// }
// }
// `

export const ADD_SHOP_DOCS = gql`
    mutation MyMutation($INN: String,
        $OGRN: String,
        $company_title: String,
        $company_address: String,
        $bank_title: String,
        $bic: String,
        $type_of_business: String,
        $tax_system: String,
        $shop_id: Int
    ) {
        insert_docs(objects: {INN: $INN,
            OGRN: $OGRN,
            bank_title: $bank_title,
            company_title: $company_title,
            company_address: $company_address,
            bic: $bic,
            type_of_business: $type_of_business,
            tax_system: $tax_system,
            shop_id: $shop_id
        })
        {
            affected_rows
        }
    }
`


export const USER_SHOP_BY_ID = gql`
    query userShopById ($_ID: Int){
        item(where: {shop_id: {_eq: $_ID}}) {
            id
        }
        
        cart(where: {shop_id: {_eq: $_ID}}) {
            id
            item_id
            user_id
            cost_item
            extended_cost
        }
        shop(where: {id: {_eq: $_ID}}) {
            approval
            city_id
            id
            info
            inline
            name
            owner_id
            payment_token
            photo
            token

        }
    }

`


export const CHANGE_ITEM_SETTING = gql`
    mutation MyMutation($_ID: Int,$about: String,$character: json) {
        update_item(where: {id: {_eq: $_ID}}, _set: {about: $about}) {
            affected_rows
        }



    }
`


export const SHOPS_ORDERS_STATISTICS = gql`
    query ShopOrdersStatistics($endDate: timestamp = "2023-01-10", $shopID: Int = 3900) {
        order(where: {created_on: {_gte: $endDate}, shop_id: {_eq: $shopID}}) {
            id
            created_on
            paid
            payload
            promo
            shipped
            shipped_id
            shop_id
            status
            updated_on
            user_id
            line_items {
                id
                order_id
                quantity
                item_id
                item {
                    price
                    old_price
                }
            }
            payments {
                id
                total_amount
            }
        }
        cart(where: {shop_id: {_eq: $shopID}}) {
            id
            cost_item
            extended_cost
            item_id
            quantity
        }
    }

`

export const ADD_ITEM_IMAGE = gql`
    mutation MyMutation($item_id: Int, $url: String) {
        insert_images_item(objects: {url: $url, item_id: $item_id}) {
            affected_rows
        }
    }`

export const REMOVE_ITEM_IMAGE = gql`
    mutation MyMutation($id: Int) {
        delete_images_item(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }`


{/*
export const GET_CATEGORY_ITEMS = gql`
query getAllItems($like: String, $userID: bigint, $category: String) {
		item	(offset: 10, limit: 10, where: {category_items: {category: {category: {_eq: $category}}}}) 	{
      about
     	id
      in_stock
      joint_promo
      name
      price
			old_price
      shop_id
      comment_items {
        text
        estimate
      }
      shop {
        name
        approve
      }
      category_items {
        category {
          subcategory
        }
      }
		 joint_shopping {
      price
    }
    images_items {
      url
    }
    }
		users_likes(where: { user_id: { _eq: $userID } }) {
      item_id
    }
   }
`;

  */
}