import React from 'react';
import classes from './AllReviews.module.css';
import OnePersonReview from './OnePersonReview/OnePersonReview';
import ReviewModal from '../ReviewModal/ReviewModal';
// import SortByNewDate from '../../../utils/SortByNewDate';
// import SortByReviewRating from '../../../utils/SortByReviewRating';

export const ModalContext = React.createContext('');
export const IsAcviveModalContext = React.createContext('');

export default function AllReviews({ reviewsArray }) {


  
  const displayArray = (arr) => {
    return arr.map((review) => <OnePersonReview oneReviewData={review} key={review.id} />);
};

  const [modalActive, setModalActive] = React.useState(false);
  // const [modalContent, setModalContent] = React.useState('');
  const [isIncrease, setIncrease] = React.useState(true);
  const [displayReviews, setDisplayReviews] = React.useState(displayArray(reviewsArray));

  return (
    <>
      {/* <IsAcviveModalContext.Provider value={{ setModalActive }}> */}
      {/* <ModalContext.Provider value={{ setModalContent }}> */}
      
         <div className={classes.wrapper}> 
        {/*  <div className={classes.filterWrapper}>
            <span className={classes.filter} onClick={() => {}}>
              Полезные
            </span>
            <span
              className={classes.filter}
              onClick={() => {
                setDisplayReviews(displayArray(SortByNewDate(reviewsArray)));
              }}>
              Новые
            </span>
            <span
              className={classes.filter}
              onClick={() => {
                setIncrease(true);
                setDisplayReviews(displayArray(SortByReviewRating(reviewsArray, isIncrease)));
              }}>
              Высокая оценка
            </span>
            <span
              className={classes.filter}
              onClick={() => {
                setIncrease(false);
                setDisplayReviews(displayArray(SortByReviewRating(reviewsArray, isIncrease)));
              }}>
              Низкая оценка
            </span>
          </div>
          */}
          {displayReviews}
           <ReviewModal 
            modalActive={modalActive}
            setModalActive={setModalActive}
            // modalContent={modalContent}
          /> 
        </div>
      
       {/* </ModalContext.Provider> */}
     {/* </IsAcviveModalContext.Provider> */}
    </>
  );
}
