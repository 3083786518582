import React, {useState} from 'react';
import classes from './CategoryModalWindow.module.css';
import ItemCardFormat from './ItemCardFormat';
import Separator from '../../Сomponents/Separator/Separator';

import link from "../../assets/svg/link.svg";
// import ShopsFilter from '../Filtres/ShopsFilter';
import FilterByShops from "../../utils/FilterByShops";
// import SortByRating from "../../utils/SortByRating";

import Slider from "@mui/material/Slider";

import {ProductSubCategoryFilter , ShopsFilter, BrandsFilter} from './Filtres/CategoryFilters';
// import SortByCost from "../../utils/SortByCost";
import FilterByProductCateg from "../../utils/FilterByProductCateg";
import FilterByCost from './Filtres/FilterByCost';
import { filtersCollection } from '.';





function DisplayFilters({setModalActive,
                        setModalWindow,
                        setFilterBy,
                        
                        itemCardFormats,
                        setItemFormat,
                        itemFormat,  
                        }) {


  
    const [minCost, setMinCost] = useState(filtersCollection().costMin  || '');
    const [maxCost, setMaxCost] = useState(filtersCollection().costMax || '');

    

    const handleFilterBy = () => {
        filtersCollection().costMin = minCost;
        filtersCollection().costMax = maxCost;
        setFilterBy(true);
        setModalActive(false);
    }



    return (
    <div>

    <ItemCardFormat options = {itemCardFormats} itemFormat = {itemFormat} setItemFormat = {setItemFormat}/>
    <Separator/>
    <div className = {classes.filterContainer}>

        <div className={classes.headerContainer}>
            <h2 className = {classes.filterTitle}>Фильтры</h2>
            <div className = {classes.filterDropAll} onClick = {
                () => {
                    setFilterBy(true);
                    setModalActive(false);
                    filtersCollection({
                        shops: [], costMin: '',  costMax: '', brands:[], subcategory: [],
                    });
                }
            }> Сбросить все</div>
        </div>
        <form className = {classes.filterForm}>
            <ul>
                <li className = {classes.sortListPrice}>
                    <span>Цена</span>

                    <div className = {classes.priceRangeContainer}>
                        <input className={classes.priceInput} 
                                                type="tel" 
                                                placeholder={`от 12₽`}
                                                value={minCost}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || /^[0-9\b]+$/.test(inputValue)) {
                                                            setMinCost(inputValue);
                                                        }
                                                }
                                                } />
                        <span className={classes.priceRange}/>
                        <input className={classes.priceInput} type="tel" 
                                                        value={maxCost}
                                                        placeholder={`до 1000000₽   `}
                                                        onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (inputValue === '' || /^[0-9\b]+$/.test(inputValue)) {
                                                                setMaxCost(inputValue);
                                                                }
                                                        }
                                                        }
                                                         />
                    </div>

                    
                </li>

                <li  className = {classes.sortList}
                        onClick = {() => {
                            setModalWindow('subcategory')

                        }}>
                        <span className = {classes.sortText}>Подкатегория</span>

                        <div className={classes.counterContainer}>
                        {filtersCollection().subcategory.length > 0 && <span className = {classes.counter}>
                                        {filtersCollection().subcategory.length}
                        </span> }
                        <img src = {link} alt = "linkSubcategory" className = {classes.link}/>
                        </div>
                </li>

                <li className = {classes.sortList}
                        onClick ={() => {
                            setModalWindow('brand');
                        }}
                            >
                        <span className = {classes.sortText}>Бренд</span>
                        <div className={classes.counterContainer}>
                            {filtersCollection().brands.length > 0 && <span className = {classes.counter}>
                                {filtersCollection().brands.length}
                            </span> }
                        <img src = {link} alt = "linkBrand" className = {classes.link}/>
                        </div>
                </li>
                <li
                    className = {classes.sortList}
                    onClick = {() => {
                        setModalWindow('ShopFilters');
                    }}
                >
                    <span className = {classes.sortText}>Продавец</span>
                    <div className={classes.counterContainer}>
                        {filtersCollection().shops.length > 0 && <span className = {classes.counter}>
                            {filtersCollection().shops.length}
                        </span> }
                    <img src = {link} alt = "linkShop" className = {classes.link}/>
                    </div>
                </li>
                
                
            </ul>
            <button
                className = {classes.applyButton}
                onClick = {(event) => {
                    // event.preventDefault();
                    handleFilterBy();
                }}
            >
                
                Применить
            </button>
        </form>
    </div>
</div>

);
}





function DispalySort({setModalActive, setSortedBy, sortedBy, }) {
    const [sorted, setSorted] = useState({sortedBy})


    return (
    
    <div className = {classes.filterContainer}>
        <div className={classes.headerContainer}>
            <h2 className = {classes.filterTitle}>Сортировка</h2>
            <div className = {classes.filterDropAll} onClick = {
                () => {
                    setSortedBy("withOutSort");
                    setModalActive(false);
                }
            
            }> Сбросить все</div>
        </div>
   
    <ul>
        <li
            className = {classes.sortList}
            onClick = {() => {
                setSorted("lotsOfOrders")
            }}
        >
            
            <span className = {classes.sortText}>Много заказов </span>
            <span
                className = {sorted === "lotsOfOrders" ? `${classes.filterChoice} ${classes.active}` : `${classes.filterChoice}`}
            ></span>
        </li>
        <li
            className = {classes.sortList}
            onClick={() => {
                setSorted("sortByRating");
            }}
            
        >
            <span className = {classes.sortText}>Хорошие отзывы</span>
            <span
                className = {sorted === "sortByRating"  ? `${classes.filterChoice} ${classes.active}` : classes.filterChoice}
            ></span>
        </li>
        <li
            className = {classes.sortList}
            onClick = {() => {
                setSorted("sortByCostLow");
            }}
        >
            <span className = {classes.sortText}>По цене min</span>
            <span
                className = {sorted === "sortByCostLow" ? `${classes.filterChoice} ${classes.active}` : `${classes.filterChoice}`}
            ></span>
        </li>
        <li
            className = {classes.sortList}
            onClick = {() => {
                setSorted("sortByCostUp");
            }}
        >
            <span className = {classes.sortText}>По цене max</span>
            <span
                className = {sorted === "sortByCostUp" ? `${classes.filterChoice} ${classes.active}` : `${classes.filterChoice}`}
            ></span>
        </li>
        
        <li
            className = {classes.sortList}
            onClick = {() => {
                setSorted("minStepByLot");
            }}
        >
            <span className = {classes.sortText}>Минимальный заказ в лоте</span>
            <span
                className = {sorted === "minStepByLot" ? `${classes.filterChoice} ${classes.active}` : `${classes.filterChoice}`}
            ></span>
        </li> 
       
        <button
                                    className = {classes.applyButton}
                                    onClick = {(event) => {
                                        // event.preventDefault();
                                        setSortedBy(sorted);
                                        setModalActive(false);
                                    }}
                                >
                                    Применить
                </button>
    </ul>
        
</div>);
}




function CategoryModalWindow({modalWindow, 
                            setModalWindow,
                             setModalActive, 
                             setSortedBy, 
                             sortedBy,
                                setFilterBy,
                                itemCardFormats,
                                setItemFormat,
                                itemFormat,
                                listSubcategory,
                            } ){


    const category= listSubcategory.data.category[0].category
    
    // console.log('CategoryModalWindow', category)

    const clickEvent = (e) => {
        setModalActive(false);
    };


    
    

    const content= () =>{
        switch(modalWindow){
            case "sort":
                
                return( <DispalySort setModalActive = {setModalActive}
                                    setSortedBy = {setSortedBy}
                                    sortedBy = {sortedBy}
                                    />
                                    )
            case "filter":
                return  (<DisplayFilters setModalActive = {setModalActive}
                                        setModalWindow = {setModalWindow}
                                        setFilterBy = {setFilterBy}
                                        itemCardFormats = {itemCardFormats}
                                        setItemFormat = {setItemFormat}
                                        itemFormat = {itemFormat}
                                        />)
            case "subcategory":
                return (<div>
                    <ProductSubCategoryFilter listSubcategory = {listSubcategory}/>
                    <button
                        className = {classes.applyButton}
                        onClick = {(event) => {
                            setModalWindow('filter');
                        }}
                    >
                        Применить
                    </button>
                </div>)

            case "brand":
                return (<div>
                    <BrandsFilter category ={category} />
                    <button
                        className = {classes.applyButton}
                        onClick = {(event) => {
                            setModalWindow('filter');
                            // setModalActive(false);

                            // dataSub.item = FilterByShops(dataSub.item);
                            
                        }}
                    >
                        Применить
                    </button>
                </div>)
            
            case "ShopFilters" :
                return( <div>
                            <ShopsFilter category ={category}/>
                            <button
                                className = {classes.applyButton}
                                onClick = {(event) => {
                                    setModalWindow('filter');
                                }}
                            >
                                Применить
                            </button>
                        </div>)


            default:
                return ( <div>
                    didn't find any filters
                </div>)
        }
    }
    
    
 
    return(
        <div id = {'primaryModal'}
        className = {classes.modal}>
       {/*onClick={modalPage === "main" ? clickEvent : null}*/}

       <div
           className = {classes.closeLineContainer}
           onClick = {clickEvent}>
           <div className = {classes.closeLine}></div>
           <span className = {classes.checkmark}></span>
       </div>
        <div>{content()}</div>
         
   </div>
    )
} 

export default CategoryModalWindow;