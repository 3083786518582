import styles from "./MediaSettings.module.css"

import React, {useState,  useEffect} from 'react';
import vector from '../../../../../assets/svg/vector.svg'
import { SETTINGS_ITEMS } from "../";

import goBack from '../../../../../assets/svg/goBack.svg'

import { useDispatch, useSelector } from 'react-redux';

import {updateMediaSettings} from '../../../../../features/settingItemSlice';

import {useMutation} from '@apollo/client';
import {DELETE_ITEM_MEDIA} from '../../../../../requests/itemRequests';







function MediaSettings({setDisplayMode}){


    const [imageUrl, setImageUrl] = useState(null);
    const [imageError, setImageError] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoError, setVideoError] = useState(null);
    const [coverUrl, setCoverUrl] = useState(null);
    const [coverError, setCoverError] = useState(null);

    const [infoVideo, setInfoVideo] = useState(null);
    const [infoCover, setInfoCover] = useState(null);
    const [infoImage, setInfoImage] = useState(null);

    const [imageList, setImageList] = useState([])

    const [dleteImage, {data}] = useMutation(DELETE_ITEM_MEDIA);

    const dispatch = useDispatch();


    const media = useSelector((state) => state.settingsItem.itemMedia);
    const itemId = useSelector((state) => state.settingsItem.itemId);
    const articleNumber = useSelector((state) => state.settingsItem.articleNumber);

    useEffect(() => {
        setImageList(media.filter(mediaItem => mediaItem.type === 'image').map(mediaItem => mediaItem.path));
        setVideoUrl(media.find(mediaItem => mediaItem.type === 'video')?.path);
        setCoverUrl(media.find(mediaItem => mediaItem.type === 'cover')?.path);
        

      }, [media]); 




    function handleMediaChange(event, type) {
      if (imageList.length < 10) {
        
        const setError = {
          image: setImageError,
          video: setVideoError,
          cover: setCoverError,
        }[type];
      
        const setUrl = {
          image: setImageUrl,
          video: setVideoUrl,
          cover: setCoverUrl,
        }[type];

        const setInfo = {
          image: setInfoImage,
          video: setInfoVideo,
          cover: setInfoCover,
        }[type]

        
      
        const url = {
          image: `https://api.jbspace.xyz/files/item_photo`,
          video: `https://api.jbspace.xyz/files/item_video`,
          cover: `https://api.jbspace.xyz/files/item_cover`,
        }[type];
      
        setError(null);
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
          console.log('Uploader: No file selected!');
          return;
        }
        setUrl(URL.createObjectURL(selectedFile));
        setInfo('загрузка на сервер...');

        const formData = new FormData();
        formData.append('files', selectedFile);


        fetch(url, { method: 'POST', body: formData })
          .then((response) => {
            // console.log('RESPONSE', response)
            if (response.ok) return response.json();
            throw new Error(`Upload failed with status ${response.status}`);
            
          })
          .then((data) => {
            // console.log('data', data);

            if(type === 'image'){      
                setImageList(prevItems => [...prevItems, data.url[0]]);
                setInfo('фото загружено');
              } else {
                setUrl(data.url);
                setInfo('видео загружено');


              }
            
          })

          .catch((error) => {
            setError('не получилось загрузить фото, попробуйте еще раз');
          
            setUrl(null);
            setInfo(null);
            console.error('Error:', error);
          });

        } else {
          alert("No more than 10 images allowed.");
      }
        
      }


      
      const onSubmit = (event) => {
        const media ={
          image: imageList,
          video: videoUrl,
          cover: coverUrl,
        }




        dispatch(updateMediaSettings({itemId, media}));   
        event.preventDefault(); 
        setDisplayMode(SETTINGS_ITEMS.default);
      };


    
     

    function handleDelete(url,  type) {

      const setInfo = {
        image: setInfoImage,
        video: setInfoVideo,
        cover: setInfoCover,
      }[type]
      
      const setUrl = {
        image: setImageUrl,
        video: setVideoUrl,
        cover: setCoverUrl,
      }[type]


      console.log('URL', url)
      console.log('mediaItem ', media)

      const mediaId = media.find(mediaItem => mediaItem.path === url).id || 0;
      console.log('mediaId', mediaId)
      if (mediaId !== 0) {
        
        const {data } = dleteImage({variables: {id : mediaId}});
        console.log('mutationData', data)
      }
    
      
      fetch("https://api.jbspace.xyz/files/delete_media", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_url: url })
      })
        .then((response) => {
          // console.log('RESPONSE', response)
          
          if (response.ok) return response.json();
          throw new Error(`Upload failed with status ${response.status}`);
          
        
          
        })
      
        setVideoUrl(null);
        setInfo(null);
        if (type === 'image') {
        setImageList(prevList => prevList.filter(item => item !== url));
        } else {
          setUrl(null);
        }
      
    }

  

    return (

        // <div className={styles.wrapper}>


        <div className = {styles.wrapper}>
            <div className = {styles.headerContainer}>
                        <img src = {goBack} alt = "back" onClick = {() => setDisplayMode(SETTINGS_ITEMS.default)}/>
                        <div className={styles.row}>
                            <h1 style={{ fontWeight: 400 }}>Артикул</h1>
                            <h1>{articleNumber}</h1>
                        </div>
                    </div>


             <h1 className={styles.header}>Медиафайлы товара</h1>
            {/* <span className={styles.step} >Шаг 3/4</span> */}
            
        <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.formItem}>
                <label className={styles.label}>Изображения:</label>
                  <div className={styles.info} >
                    <span className={styles.itemInfo}> Формат - JPEG, JPG, PNG. </span>
                    <span className={styles.itemInfo}>Размер - не больше 10 МБ. </span>
                    <span className={styles.itemInfo}>Разрешение от 200 до 4000px по большей стороне. </span>
                    <span className={styles.itemInfo}>Можно загрузить до 10 фото.</span>
                  </div>

            <div className={styles.mediaContainer}>
                <div className={styles.avatarContainer}>
                <input
                     type="file"
                    accept="image/*"
                    className={styles.newAvatar}
                    onChange={(e) => handleMediaChange(e, 'image')}
                    />

                </div>
                {imageList.length > 0 && imageList.map((imageUrl) => (
                          <div className={styles.avatarContainer} key={imageUrl}>

                                  <img src={imageUrl} alt="foto" className={styles.avatar} />
                              <div type="button" className={styles.deleteButton} onClick={() => handleDelete(imageUrl , 'image')}>
                                <img src={vector} alt="delete"></img>
                              </div>

                          </div>
                      ))}
                      {infoImage ? <span className={styles.mediaText}> {infoImage} </span> : 
                       <span className={styles.mediaText}> минимум 1 </span>}

            </div>
            {imageError  && <span style = {{color: "red"}}>{imageError}  </span>}

            </div>


            

            <div className={styles.formItem}>
                <label className={styles.label}>Видео:</label>
                
                  <div className={styles.info} >
                    <span className={styles.itemInfo}> Формат - MP4 или MOV. </span>
                    <span className={styles.itemInfo}> Размер - не больше 100 МБ. </span>
                    <span className={styles.itemInfo}> Можно загрузить до 1 видео.</span>
                  </div>

            <div className={styles.mediaContainer}>
                <div className={styles.videoContainer}>
                <input
                    // type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    // className={styles.newAvatar}
                    className={styles.newAvatarHiden}
                    // onChange={(e) => handleMediaChange(e, 'video')}
                    />
                {videoUrl && 
                      <>
                          <video className={styles.avatar} 
                                src={videoUrl} 
                                alt="video"  />

                          <div type="button" 
                                className={styles.deleteButton} 
                                onClick={() => handleDelete(videoUrl, 'video')}>
                                <img src={vector} alt="delete"></img>
                          </div>
                      </>}
                </div>

                {infoVideo ? <span className={styles.mediaText}> {infoVideo} </span> : 
                <span className={styles.mediaText} style={{'color' : '#B5B5B5'}}> в разработке</span>

                }

            {videoError  && <span style = {{color: "red"}}>{videoError}  </span>}

            </div>
      </div>

            <div className={styles.formItem}>
                <label className={styles.label}>Видеообложка:</label>

                <div className={styles.info} >
                    <span className={styles.itemInfo}> Формат - MP4 или MOV. </span>
                    <span className={styles.itemInfo}> Размер - не больше 20 МБ. </span>
                    <span className={styles.itemInfo}> Можно загрузить до 1 видео </span>
                </div>

                <div className={styles.mediaContainer}>
                    <div className={styles.videoContainer}>
                        
                    
                  {coverUrl ?
                      <>
                          <video className={styles.avatar} 
                                src={coverUrl} 
                                alt="cover"  />

                          <div type="button" 
                                className={styles.deleteButton} 
                                onClick={() => handleDelete(coverUrl, 'cover')}>
                                <img src={vector} alt="delete"></img>
                          </div>
                      </> :
                      <input
                            // type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            className={styles.newAvatarHiden}
                            // onChange={(e) => handleMediaChange(e, 'cover')}
                        />
                      }
                </div>

                {infoCover ? <span className={styles.mediaText}> {infoCover} </span> : 
                <span className={styles.mediaText} style ={{'color' : '#B5B5B5'}}> в разработке </span>
                }
                {coverError  && <span style = {{color: "red"}}>{coverError}  </span>}
                </div>

            </div>
                
            <div className={styles.buttonContainer} >
                <button type="submit" className={styles.button} disabled={imageList.length === 0}>
                    Применить
                </button>
            </div>
        </form>
            
        </div>
    
    )
}

export default MediaSettings;
    

