import styles from '../Email/RegisterdEmail/RegisteredEmail.module.css';

const ProfilePhoneApplyButton = ({onClickCallback, isVerified}) => {

    const applyButtonClass = !isVerified ? styles.applyButton : `${styles.applyButton} ${styles.confirm}`
    const applyButtonTitle = !isVerified ? "Подтвердить" : "Подтвержден"

    const onClickHandler = !isVerified ? onClickCallback : () => {
    }

    return (
        <div>
            <button className = {applyButtonClass}
                    onClick = {onClickHandler}>{applyButtonTitle}</button>
        </div>)
}

export default ProfilePhoneApplyButton;