import React, {createContext, useContext, useState} from 'react';
import {TelegramContext} from '../../../../App';
import TitlesDataSellerRegistration from './TitlesDataSellerRegistration/TitlesDataSellerRegistration';
import SellersBankRegistration from './SellersBankRegistration/SellersBankRegistration';
import ShopRegistration from '../../ShopRegistration/ShopRegistration';
import {useLocation} from 'react-router-dom';
import SuccessRegistration from '../SuccessRegistration';
import ShopRegistrationsCity from '../../ShopRegistrationsCity/ShopRegistrationsCity';
import SuccessSellerRegistrationChildren
    from '../SuccessRegistration/SuccessSellerRegistrationChildren/SuccessSellerRegistrationChildren';

export const displaySellerRegistrationsTypes = {
    titlesData: "TITLES_DATA",
    bankData: "BANK_DATA",
    shopData: "SHOP_DATA",
    success: "SUCCESS",
    cityData: "CITY_DATA"
}

export const SellerRegistrationContext = createContext()

const SellerRegistration = () => {
    console.log('SellerRegistration ')

    const {userID} = useContext(TelegramContext);
    const location = useLocation()

    //general data
    const [displayType, setDisplayType] = useState(displaySellerRegistrationsTypes.titlesData)
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [onError, setOnError] = useState(false);
    const [onErrorMessage, setOnErrorMessage] = useState(false)
    const [disabled, setDisabled] = useState(true);
    const [isVerified, setIsVerified] = useState(false)


    //seller registration data
    const [isvalidINN, setisvalidINN] = useState(false)
    const [iNN, setiNN] = useState('544507337833');//544507337833
    const [OGRN, setOGRN] = useState('');
    const [name, setName] = useState('');
    const [adress, setAdress] = useState('');


    //seller bank data
    const [isvalidBIC, setisvalidBIC] = useState(false)
    const [BIC, setBIC] = useState('041909760');//041909760
    const [bankTitle, setBankTitle] = useState('');
    const [KC, setKC] = useState('');
    const [banksAccount, setBanksAccount] = useState('');
    const [ksError, setKsError] = useState(false);
    const [bankAccountError, setBankAccountError] = useState(false);


    //shop data
    const [shopTitle, setShopTitle] = useState('');
    const [newShopId, setNewShopId] = useState('');
    const [aboutShop, setAboutShop] = useState('');
    const [busines, setBusines] = useState('');
    const [taxSystemState, setTaxSystemState] = useState('');
    const [deliveryState, setDeliveryState] = useState(location.state?.cityName || "");
    // const [brandName, setBrandName] = useState('');
    //console.log(location.state)

    return (<SellerRegistrationContext.Provider value = {{
    
        //general data
        userID,
        displayType, setDisplayType,
        data, setData,
        error, setError,
        onError, setOnError,
        onErrorMessage, setOnErrorMessage,
        disabled, setDisabled,
        isVerified, setIsVerified,

        //seller registration data
        isvalidINN, setisvalidINN,
        iNN, setiNN,
        OGRN, setOGRN,
        name, setName,
        adress, setAdress,

        //seller bank data
        isvalidBIC, setisvalidBIC,
        BIC, setBIC,
        bankTitle, setBankTitle,
        KC, setKC,
        banksAccount, setBanksAccount,
        ksError, setKsError,
        bankAccountError, setBankAccountError,

        //shop data
        shopTitle, setShopTitle,
        newShopId, setNewShopId,
        aboutShop, setAboutShop,
        busines, setBusines,
        taxSystemState, setTaxSystemState,
        deliveryState, setDeliveryState,
        // brandName, setBrandName
    }}>
        {displayType === displaySellerRegistrationsTypes.titlesData && <TitlesDataSellerRegistration />}
        {displayType === displaySellerRegistrationsTypes.bankData && <SellersBankRegistration/>}
        {displayType === displaySellerRegistrationsTypes.shopData && <ShopRegistration/>}
        {displayType === displaySellerRegistrationsTypes.cityData && <ShopRegistrationsCity/>}
        {displayType === displaySellerRegistrationsTypes.success &&


            //information about successful shop registration is displayed and goes to main profile page
            <SuccessRegistration path = {"/profile/shop/setting"}
                                 data = {{shop: {id: newShopId, name: shopTitle}}}>
                <SuccessSellerRegistrationChildren shopTitle = {shopTitle}/>
            </SuccessRegistration>
        }

    </SellerRegistrationContext.Provider>);
};

export default SellerRegistration;


