export const sellerRules = `
Пользовательское соглашение (для потребителей)
Соглашение является публичным предложением ПСП (офертой) любому Покупателю к заключению Пользовательского соглашения на указанных ниже условиях.
Пользователь обязан полностью ознакомиться с настоящим Соглашением до начала использования Маркетплейса. Факт регистрации учетной записи на сайте или в Telegram, использования любых функций и программных возможностей Маркетплейса означает полное и безоговорочное принятие Пользователем настоящего Соглашения, а также Политики конфиденциальности.
Термины и определения
Термины
Определения
«ПСП»
Общество с ограниченной ответственностью «Мидори» (ОГРН 1217000003770; ИНН 7017484000; 	634026, Томская Область, г.о. Город Томск, г Томск, ул Ленская, д. 31, помещ. Ц037).
«Авторизация»
Процесс идентификации Покупателя путем ввода им логина и пароля для доступа к Личному кабинету.
«База данных ПСП»
Информационно-справочная система, содержащая информацию о товарах и услугах, предназначенную для потенциальных покупателей (потребителей) товаров (услуг).
«Блокирование Личного кабинета»
Наложение запрета на осуществление действий Покупателя на Маркетплейсе.
«Оформление заказа»
Действия Покупателя, направленные на заключение договора купли-продажи Товара с Продавцом и/или заключения договора о доставке Товара с ПСП. Каждому заказу присваивается отдельный номер.
Код
персональный идентификационный номер, служащий дополнительным средством защиты от несанкционированного получения Заказа, является аналогом собственноручной подписи Покупателя.
«Личный кабинет»
программный интерфейс взаимодействия Покупателя с ПСП, который содержит информацию о Покупателе, совершенном Заказе и иную информацию в отношении Услуг по Договору, а также предоставляет возможность удаленного взаимодействия Сторон в рамках Договора, доступный Покупателю после авторизации с использованием логина и пароля Покупателя в ПСП.
«Маркетплейс»
веб-сайт по адресу https://sovmestno.sale/, интегрированный с программами для ЭВМ и Базами данных ПСП, доменное имя которого зарегистрировано за Разработчиком, и которое используется ПСП для исполнения обязательств по настоящему Договору. Также представляет собой совокупность объектов интеллектуальной собственности, а именно программное обеспечение, базу данных, дизайн (графическое оформление), а также информацию, размещенную Агентом и/или Разработчиком. Доменное имя, наименование Сайта может быть изменено по усмотрению Разработчика.
«Покупатель»
Физическое лицо, приобретающее Товары у Продавца дистанционным способом посредством оформления заказа Товаров в интернет-магазине Продавца на Маркетплейсе.
«Политика конфиденциальности»
Документ, содержащий перечень организационных и технических мер, принимаемых ПСП для защиты персональных данных Покупателя от неправомерного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
«Продавец»
Зарегистрированный на Маркетплейсе индивидуальный предприниматель, юридическое лицо или плательщик налога на профессиональный доход, осуществляющий деятельность по продаже товаров через Маркетплейс Покупателю с использованием дистанционного способа.
«Пункт выдачи заказов/ПВЗ»
Пункт выдачи заказов транспортных компаний имеющих договора с ПСП .
«Разработчик»
ООО «Мидори»
«Соглашение»
настоящее Пользовательское соглашение, определяющие порядок и условие использования Маркетплейса.
«Товар»
означает товары, являющиеся предметом договора купли-продажи, заключаемого между Продавцом и Покупателем. В Договоре могут быть использованы термины, не определенные в настоящем разделе Договора. В этом случае толкование такого термина производится в соответствии с текстом Договора. В случае отсутствия однозначного толкования термина в тексте Договора следует руководствоваться толкованием термина, определенным: в первую очередь – законодательством РФ, во вторую очередь – сложившимся (общеупотребимым) в сети Интернет.
1. Предмет соглашения
1.1. Настоящее соглашение регулирует отношения между ПСП и Покупателем, возникающие при использовании последним Маркетплейса.
2. Регистрация и авторизация
2.1. Для возможности использования Маркетплейса Покупателю необходимо пройти регистрацию путем совершения ряда последовательных действий по заполнению формы регистрации на сайте и/или в Telegram, а именно: указания имени, номера телефона (логина), подтверждения номера телефона с помощью sms-сообщения, формирования пароля и нажатия на кнопку «Регистрация».
2.2. Покупатель самостоятельно формирует пароль для доступа к Личному кабинету. При этом ПСП вправе предъявлять требования к паролю при его формировании (длина, допустимые символы и т.д).
2.3. Стороны Соглашения гарантируют, что обладают необходимой правоспособностью и дееспособностью для заключения и исполнения настоящего Соглашения.
2.4. Учетная запись Покупателя является необходимой и достаточной информацией для доступа в личный кабинет ПСП. Доступ к личному кабинету восстановлению не подлежит. При утрате доступа к номеру телефона, с помощью которого покупателем был создан личный кабинет, рекомендуется пройти процедуру регистрации заново с использованием актуального номера телефона.
2.5. После регистрации Покупатель имеет возможность загрузить изображение для Личного кабинета. Указанное изображение не должно нарушать исключительных прав третьих лиц.
3. Совершение покупок
Оформление Заказа
3.1. С помощью Маркетплейса Покупатель может осуществлять поиск информации о Товарах, и приобретать необходимый Товар.
3.2. Покупателю доступны следующие способы получения Товара:
    • Самостоятельное получение в Пункте выдачи заказов (актуальный перечень городов размещен на сайте);
    • Курьерская доставка по указанному адресу в пределах города, в котором открыт Пункт выдачи заказов. При этом в случае, если Покупатель просит курьера оставить заказ у двери, услуги по доставке считаются исполненными надлежащим образом, риск утраты или повреждения заказа переходит к Покупателю в момент доставки.    
3.3. Покупатель при осуществлении Заказа:
    • Вступает в прямые договорные отношения с Продавцом, у которого покупает Товар;
    • Является заказчиком услуг по доставке Товара, оказываемых ПСП, если Покупателем была выбрана курьерская доставка до места нахождения Покупателя.
3.4. ПСП и Продавец могут поручить исполнение своих обязательств по договору с Покупателем третьим лицам, оставаясь при этом ответственным за его исполнение.
3.5. Осуществляя Заказ (путём нажатия кнопки «Оформить Заказ» или иной̆ аналогичной̆ кнопки), Покупатель подтверждает, что:
    • информация о Товарах (услугах) доведена до сведения Покупателя в полном объеме;
    • он полностью ознакомился с условиями настоящего Соглашения, что он полностью их понимает, а также понимает и согласен с предметом и условиями заключенного с Продавцом Договора.
    • Покупатель ознакомился со всеми существенными условиями продажи Товара. Существенные условия продажи Товара указываются Продавцом на странице с описанием Товара (карточка Товара).
3.6. Для осуществления верификации Покупателя при передаче Заказа Покупателю отправляется Код посредством смс-сообщения на телефонный номер или почта или телега, указанный им при регистрации. Выдача Товара также производится при предъявлении Покупателем штрихкода или наш вариант, сформированного в личном кабинете.
3.7. Обязательства продавца по передаче Товара и иные обязательства, связанные с передачей Товара, возникают с момента подтверждения факта наличия Товара на складе. В случае если при сборке заказа будет обнаружено, что Товар отсутствует на складе, обязательства продавца по передаче Товара Покупателю не возникает, уплаченные за Товар денежные средства возвращаются на счет, с которого была произведена оплата.
Оплата Товара
3.8. Оплата заказов на Маркетплейсе осуществляется безналичными денежными средствами, ПСП не использует в своей деятельности наличные расчеты.
3.9. Покупатель осуществляет оплату Товара в адрес ПСП, который действует по поручению соответствующего Продавца с привлечением уполномоченного оператора по приему платежей или оператора электронных денежных средств и является получателем платежа в качестве агента Продавца.
3.10. При оплате заказа формируется электронный кассовый чек, который направляется в личный кабинет Покупателя, привязанный к его абонентскому номеру. В случае, если Покупатель желает получить электронный чек также и на адрес электронной почты, его необходимо указать при оформлении Заказа.
При оформлении и оплате заказа Покупателю направляется электронной чек на сумму предоплаты (аванса). При получении товара формируется и направляется электронной чек на полный расчет (оплату товара). В случае возврата товара в личный кабинет Покупателя направляется чек на возврат, при этом ПСП возвращает денежные средства на счет Покупателя в день оформления возврата, однако их поступление на счет зависит от сроков межбанковских операций и может занять несколько дней.
3.11. Оплата заказов по желанию Покупателя может быть осуществлена частями в рассрочку (АО Тинькофф Банк). Условия рассрочки платежа и оплаты с помощью сервисов «Долями» и «Подели» определяются лицами, оказывающими соответствующие услуги. Информация о максимальной сумме заказа для оплаты «Долями» или в рассрочку доступна при оформлении Покупателем заказа на ПСП. Оформление заказа с оплатой в рассрочку возможно только в веб-версии Маркетплейса.
Получение Заказа
3.12. Для получения Заказа Покупатель заказывает курьерскую доставку, либо забирает заказ в ПВЗ транспортных компаний с которыми работает ПСП.
3.13. В целях исключения неправомерных действий при вручении предоплаченного Заказа сотрудник транспортной компании или курьер вправе потребовать у Покупателя предъявить документ, удостоверяющий личность Покупателя.
3.14. Заказ хранится в Пункте выдачи заказов в течение 30 дней с момента его поступления.
3.15. Неявка Покупателя в Пункт выдачи заказов в установленный пунктом 3.14. настоящего соглашения срок и несовершение иных необходимых действий для принятия Товара при курьерской доставке рассматриваются как отказ Покупателя от Товара. В этом случае оформляется возврат Заказа и осуществляется возврат уплаченных за Товар денежных средств.
Отказ Покупателя от Товара без претензий к его качеству
3.16. Покупатель вправе отказаться от Товара в любое время до его передачи, а после передачи Товара - в течение семи дней. 
3.17. Возврат Товара надлежащего качества возможен при условии, что сохранен его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного Товара. Возврат ювелирных изделий надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, документ, подтверждающий факт и условия покупки указанного товара у продавца, и потребительская упаковка.
3.18. Товарный вид признается сохраненным при условии, что товар не был в использовании, сохранены пломбы, фабричные ярлыки, внешний вид такого Товара вместе с потребительской упаковкой изготовителя позволяет осуществить его дальнейшую продажу другому покупателю. Отсутствие/повреждение невосстанавливаемой упаковки изготовителя, следы использования (в том числе однократного), которые не позволяют направить Товар в повторную продажу, являются препятствием для дальнейшей реализации Товара продавцом и свидетельствуют о несохранности товарного вида.
3.19. Возврат продуктов питания (в том числе БАДов), витаминов для людей и животных, кормов и препаратов для животных, возможен только до момента их получения либо в момент их получения в пункте выдачи транспортной компании(при доставке курьером).
3.20. При отказе Покупателя от Товара надлежащего качества стоимость услуг ПСП по доставке Товара не подлежит возврату.
Возврат и обмен Товара ненадлежащего качества
3.21. В случае обнаружения в Товаре недостатков, если они не были оговорены продавцом, Покупатель по своему выбору вправе:
    • потребовать замены на Товар этой же марки (этих же модели и (или) артикула);
    • потребовать замены на такой же Товар другой марки (модели, артикула) с соответствующим перерасчетом покупной цены;
    • потребовать соразмерного уменьшения покупной цены;
    • потребовать незамедлительного безвозмездного устранения недостатков Товара или возмещения расходов на их исправление Покупателем или третьим лицом;
    • отказаться от исполнения договора купли-продажи и потребовать возврата уплаченной за Товар суммы.
3.22. По требованию продавца и за его счет Покупатель должен возвратить Товар с недостатками.
3.23. В отношении технически сложного Товара Покупатель в случае обнаружения в нем недостатков вправе отказаться от исполнения договора купли-продажи и потребовать возврата уплаченной за такой Товар суммы либо предъявить требование о его замене на Товар этой же марки (модели, артикула) или на такой же Товар другой марки (модели, артикула) с соответствующим перерасчетом покупной цены в течение пятнадцати дней со дня передачи Покупателю такого Товара.
3.24. По истечении этого срока указанные требования подлежат удовлетворению в одном из следующих случаев:
    • обнаружение существенного недостатка Товара;
    • нарушение установленных Законом «О защите прав потребителей» сроков устранения недостатков Товара;
    • невозможность использования Товара в течение каждого года гарантийного срока в совокупности более чем тридцать дней вследствие неоднократного устранения его различных недостатков.
3.25. Покупатель вправе предъявить перечисленные требования к продавцу в отношении недостатков Товара, если они обнаружены в течение гарантийного срока или срока годности.
В отношении одежды и обуви гарантийный срок составляет 30 дней, если больший срок не указан в карточке товара или в документе к товару. Для сезонных товаров этот срок исчисляется с момента наступления соответствующего сезона.
3.26. В случаях, когда недостатки Товара обнаружены Покупателем по истечении гарантийного срока, установленного продавцом (изготовителем), но в пределах двух лет, Покупатель вправе предъявить продавцу требования, перечисленные ст.18 ФЗ «О защите прав потребителей», если докажет, что недостатки Товара возникли до его передачи Покупателю или по причинам, возникшим до этого момента.
3.27. Таким доказательством может является заключение экспертизы, проведенной по инициативе и за счет Покупателя.
3.28. Возврат Товара осуществляется в пунктах приема заказов транспортных компаний.
Доставка Товара
3.29. Доставка Товара Покупателю осуществляется по адресу и в сроки, согласованные при оформлении Заказа 
3.30. Точная стоимость доставки Товара определяется при оформлении Заказа и не может быть изменена после оформления Заказа Покупателем. При отказе Покупателя от Заказа в полном объеме по причине ненадлежащего качества Товара, стоимость доставки подлежит возврату Покупателю. В случае отказа от части Заказа по любой из причин услуги стоимость услуг по доставке возврату не подлежит.
3.31. Неявка Покупателя в ПВЗ или несовершение иных необходимых действий для принятия Товара могут рассматриваться Продавцом в качестве отказа Покупателя от исполнения Договора. Заказ хранится в пункте выдачи заказов в течение 5 дней с момента его поступления на пункт выдачи заказов.
3.32. Покупатель обязан осуществить проверку своего Заказа в ПВЗ (или в присутствии курьера при курьерской доставке), поскольку требования, связанные с оказанием услуг по доставке Заказа, могут быть предъявлены при принятии оказанной услуги согласно п.3 ст.29 ФЗ «О защите прав потребителей».
3.33. Недостатки исполнения Заказа:
- несоответствие Товара описанию в карточке/не тот размер/цвет и т.п.;
- неполная комплектация Товара;
- пустая упаковка Товара;
- отсутствие одного или нескольких Товаров в Заказе;
- Товар пришел с механическим повреждением/со следами использования/испорченный и т.п.
3.34. Если Покупатель не проверил Заказ в ПВЗ или в присутствии курьера, ПСП отвечает за недостатки исполнения Заказа в случае, если Покупатель докажет, что они возникли до момента получения Заказа.
4. Права и обязанности сторон
4.1. Покупатель:
4.1.1. Осознает, что любое действие, совершенное из Личного кабинета с использованием его Учетной записи, считается действием, совершенным самим Покупателем.
4.1.2. Обязуется не передавать свои логин (номер телефона) и пароль третьим лицами самостоятельно несет ответственность за сохранность логина (номер телефона) и пароля.
4.1.4. Должен предоставлять правдивую, точную и полную информацию о себе в Личном кабинете и поддерживать эту информацию в актуальном состоянии.
4.1.5. Обязан немедленно уведомить ПСП о любом случае несанкционированного (не разрешенного Покупателем) доступа к Личному кабинету с использованием учетной записи Покупателя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к Личному кабинету.
4.1.6. Не вправе при блокировании, удалении и/или приостановлении доступа к Личному кабинету создавать повторно Учетную запись на Маркетплейсе.
4.1.7. Осознает, что все претензии по поводу качества приобретенных с помощью Маркетплейса Товаров предъявляются соответствующему Продавцу.
4.1.8. Обязуется при использовании Маркетплейса соблюдать законодательство Российской Федерации и международные нормы, а также права и интересы третьих лиц.
4.1.9. До момента заключения Договора ознакомиться с содержанием договора-оферты, условиями оплаты и доставки на сайте магазина.
4.1.10. Предоставлять достоверную информацию о себе (ФИО, контактные телефоны, адрес электронной почты) и реквизиты для доставки Товара.
4.1.11. Должен принять и оплатить Товар в указанные в настоящем Договоре сроки.
4.1.12. Должен соблюдать условия участия в действующих акциях.
4.1.13. Вправе публиковать отзывы о приобретенных с использованием Маркетплейса товарах.
4.2. ПСП:
4.2.1. Вправе принимать любые разумные меры по выявлению и пресечению неправомерных действий с использованием Маркетплейса.
4.2.2. Вправе запросить документ, удостоверяющий личность Покупателя (скан-копия) и фотографию покупки (скриншот).
4.2.3. Не несет ответственности за недостоверность данных о банковской карте, электронном денежном счете и номере телефона, предоставленных Покупателем.
4.2.4. Вправе вносить изменения в Пользовательское соглашение в одностороннем порядке.
4.2.5. Вправе отказать в передаче товара в собственность Покупателю в случае нарушения условий участия в акциях.
4.2.6. Вправе требовать предъявить паспорт, если это обусловлено особенностями товара и условиями проведения акции.
5. Условия публикации отзывов Покупателей
5.1. Покупатель вправе оставлять отзывы на товары, которые были им приобретены на маркетплейсе ПСП.
5.2. Отзывы публикуются после их проверки на предмет соблюдения Покупателем условий публикации отзывов.
5.3. При публикации отзыва Покупатель обязан соблюдать следующие требования к содержанию отзыва:
5.3.1. Текст отзыва должен содержать описание Покупателем своего впечатления о приобретенном товаре и личного опыта использования товара. В тексте не должно быть посторонних, не связанных с товаром фотографий, видеороликов, сведений, просьб, ссылок, рекламных и иных материалов.
5.3.2. В тексте отрицательного отзыва должна быть указана причина, по которой Покупатель остался недоволен товаром.
5.3.3. Отзыв не должен содержать оскорбительных высказываний, клеветы и ненормативной лексики, в том числе слов, в которых буквы заменены другими символами частично или полностью.
5.3.4. Текст отзыва не должен дублировать ранее размещенный отзыв.
5.4. Покупатель вправе публиковать отзывы с указанием своих данных или анонимно. В случае публикации отзыва с указанием своих данных Покупатель раскрывает такие данные неопределенному кругу лиц по собственной инициативе.
5.5. ПСП вправе отклонить публикацию любого отзыва, не соответствующего требованиям, установленным законом или настоящим Соглашением, либо нарушающего права третьих лиц.
5.6. Отзыв может быть отклонен в случае, если часть отзыва (текст или фотографии) нарушает установленные настоящим разделом правила.
5.7. ПСП вправе отклонить публикацию отзыва, содержащего претензии к сервису сборки, упаковки и доставки заказа. Такие претензии не относятся к товару и подлежат рассмотрению службой клиентской поддержки по адресу электронной почты info@sovmestno.sale или в телеграмм в порядке, предусмотренном разделом 9 настоящего Соглашения.
6. Нарушение Соглашения Покупателем
6.1. В случае нарушения Покупателем данного Соглашения и/или совершения неправомерных действий ПСП имеет право заблокировать Личный кабинет Покупателя, или ограничить функциональные возможности использования Покупателем Маркетплейса.
7. Информационные (рекламные) сообщения
7.1. Пользуясь Маркетплейсом, Покупатель дает свое согласие на получение информационных сообщений рекламного характера.
7.2. В случае, если Покупатель желает отказаться от получения информационных электронных сообщений на электронный адрес и/или абонентский номер, он вправе сообщить об этом Администрации сайта по адресу info@sovmestno.sale, после чего рассылка информационных электронных сообщений на электронный адрес и/или абонентский номер телефона Пользователя немедленно прекращается.
8. Ограничение ответственности
8.1. Маркетплейс представляется «как есть» (as is), т.е. означает, что за проблемы, возникающие в процессе установки, обновления, поддержки и эксплуатации Маркетплейса (в т. ч. проблемы совместимости с другими программными продуктами (пакетами, драйверами и др.), несоответствия результатов использования Маркетплейса ожиданиям Покупателя и т. п.), ПСП ответственности не несет.
8.2. Покупатель осознает, что несет личную ответственность за возможные проблемы, связанные с несовместимостью и конфликтами Маркетплейса, с другим программным обеспечением, которое использует Покупатель.
8.3. ПСП не гарантирует, что Маркетплейс:
8.3.1. Соответствует или будет соответствовать требованиям Покупателя.
8.3.2. Будет предоставляться непрерывно, быстро, надежно и без ошибок.
8.3.3. Услуги и информация, полученные с использованием Маркетплейса, будут соответствовать ожиданиям Покупателя.
8.4. ПСП не отвечает, не возмещает и не несет ответственности за любые убытки, включая упущенную выгоду, моральный и иной вред, причиненные Покупателю или третьим лицам в результате использования ими Маркетплейса, или в связи с функционированием Маркетплейса, в том числе, за убытки, связанные с принятием каких-либо решений и действий, основанных на информации, размещенной в Маркетплейсе.
8.5. ПСП не несет ответственности по сделкам между Покупателем и Продавцом, заключенным в связи с использованием Маркетплейса, в том числе за услуги третьих лиц, которые Покупатель использует для оплаты покупок в Магазине (услуги операторов мобильной связи, услуги электронных платежных систем и т.д.).
8.6. ПСП не несет ответственность за любую информацию, материалы, размещенные на сайтах Магазинов, к которым Покупатель получает доступ с использованием Маркетплейса, а также за доступность таких сайтов или контента и последствия их использования Покупателем.
8.7. ПСП не несет ответственность за совершенные действия в Личном кабинете Покупателя третьими лицами, которые получили Учетную запись в результате неправомерных действий.
9. Претензии Покупателей
9.1. Требования Покупателей рассматриваются службой клиентской поддержки по адресу электронной почты info@sovmestno.sale
9.2. В случае, если Покупатель не согласен с решением спорной ситуации, претензии могут быть заявлены следующими способами:
9.2.1. Почтой России по адресу: 	634026, Томская Область, г.о. Город Томск, г Томск, ул Ленская, д. 31, помещ. Ц037.
9.2.2. По электронной почте info@ПСП.ru в виде электронного образа (скана) документа, подписанного собственноручной подписью.
9.3. Претензия составляется в свободной форме с обязательным указанием следующих сведений:
- номер заказа на сайте https://sovmestno.sale/;
- описание недостатков полученного товара;
- требование покупателя;
- платежные реквизиты, паспортные данные и ИНН покупателя, в случае, если заявлено требование об уплате неустойки.
10. Персональные данные
10.1. Персональные данные Покупателя, которые он указывает в Личном кабинете, хранятся и обрабатываются ПСП в соответствии с условиями Политики конфиденциальности, которая изложена в отдельном документе.
11. Применение рекомендательных технологий.
11.1. С целью улучшения пользовательского опыта и повышения уровня комфорта Покупателей ПСП применяет рекомендательные технологии на сайте и в приложениях.
11.2. С Правилами применения рекомендательных технологий можно ознакомиться по ссылке: Правила.
12. Интеллектуальная собственность
12.1. Обладателем исключительных прав на Маркетплейс, включая, но не ограничиваясь, права на доменное имя, размещенный логотип Маркетплейса, базы данных, все технические разработки, позволяющие осуществлять использование Маркетплейса является Разработчик.
12.2. Покупатель не вправе использовать Маркетплейс способами, не предусмотренными Соглашением, в том числе извлекать информацию в любой форме не предусмотренными Соглашением способами.
12.3. В целях исполнения Соглашения, проведения конкурсов, акций, анонсирования и информирования Покупателей и иных подобных мероприятий, осуществления законных прав и интересов ПСП и обеспечения функционирования Маркетплейса, Покупатель предоставляет ПСП действующее повсеместно, бессрочное право использовать фотографии, тексты отзывов на товары, которые он оставляет публично в сети Интернет) любым способом на всех известных или неизвестных информационных носителях в течение всего срока действия исключительного права, а также передавать такое право третьим лицам, как с указанием имени автора, так и без такового.
13. Изменение соглашения
13.1. Соглашение может быть изменено ПСП без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения на Маркетплейсе, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция Соглашения всегда находится на странице по адресу sovmestno.sale
14. Заключительные положения
14.1. Соглашение считается заключенным в момент регистрации Покупателя на Маркетплейсе и действует до момента удаления Личного кабинета.
14.2. Если по каким-либо причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.
14.3. Настоящее соглашение составлено на русском языке.
`