import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import classes from './ReviewPage.module.css';
import StarRating from 'react-svg-star-rating';
import AllReviewPhotos from './AllReviewPhotos/AllReviewPhotos';
import AllReviews from './AllReviews/AllReviews';
import Rating from '../../utils/Rating';
import {TelegramContext} from '../../App';
import {useQuery} from '@apollo/client';
import {GET_ITEM_COMMENTS} from '../../requests/commentRequests';

export default function ReviewPage() {

    // console.log('ReviewPage');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const {productId} = useParams();

    const {userID} = useContext(TelegramContext);


    const {loading, error, data} = useQuery(GET_ITEM_COMMENTS, {
        variables: {productId, userID}, fetchPolicy: 'cache-and-network',
    });

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        console.error(error);
        return <div>Error!</div>;
    }

    // console.log('data', data);

    //console.log('item', item);
    const generalRating = Rating(data.comment_item);
    const starCount = data.comment_item.length;
    const allReviews = data.comment_item;

    let allImages = [];

    allReviews.map((review) => {
        if (review.images_comments.length > 0) {
            allImages = [...allImages, ...review.images_comments];
        }
    }
    );






    

    if (starCount > 0) {
        return (
            <div className = {classes.wrapper}>
                <div className = {classes.titleContainer}>
                    <h1 className = {classes.title}>Отзывы </h1>{' '}
                    {/* <span className = {classes.starCount}>{starCount} </span> */}
                </div>

                <StarRating
                    isReadOnly
                    initialRating = {generalRating}
                    unit = "float"
                    containerClassName = {classes.starContainer}
                    starClassName = {classes.star}
                />
                <div className = {classes.ratingContent}>{generalRating}/5</div>

                {
                     allImages.length > 0 && <AllReviewPhotos images = {allImages}/>

                }
                
                <AllReviews reviewsArray = {allReviews}/>
            </div>
        );
    } else {
        return <div className = {classes.wrapper}>
                На данный товар пока нет отзывов
                </div>;
    }
}
