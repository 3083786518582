import {NavLink, useNavigate} from "react-router-dom";
//import catalog from "../../../assets/svg/catalog.svg";
import React, {useContext} from 'react';
import {ModalContext} from '../../../App';
import classes from '../Footer.module.css';
import {ReactComponent as Icon} from '../../../assets/svg/catalog.svg';

const Catalog = () => {
    return (
        <>  
            <div
                onClick={() => {
                    // setModalActive(false)
                    // setModalContent('')
                }}
            >
                {/* <NavLink to="/catalog"> */}
                <div className={classes.imgDisable}>
                    <Icon />
                    <div style={{ color: '#B5B5B5' }}>Каталог</div>
                </div>
                {/* </NavLink> */}
            </div>
        </>
    );
};


export default React.memo(Catalog)
