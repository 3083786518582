import React from 'react';
import styles from "./ShareButton.module.css"
import shared from "../../assets/svg/newShared.svg"

function ShareButton({title, callback}) {
    return (
        <div className = {styles.container} onClick={callback} >

            <img className={styles.image} src = {shared} alt = 'shareImage'/>
            <span className = {styles.title}>{title}</span>
        </div>
    );
}

export default ShareButton;