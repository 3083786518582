import React from "react";
// Import Slider React components
import {Swiper, SwiperSlide} from "swiper/react";
import {useNavigate} from "react-router-dom";
import {GET_CATEGORY_WITH_ITEMS} from "../../../requests/categoryRequests";
// Import Slider styles
import "swiper/css";

import classes from "./CategoRySlider.module.css";
import img1 from "../../../assets/png/1.png";
// import img2 from "../../assets/png/2.png";
// import img3 from "../../assets/png/3.png";

// import required modules
import {useQuery} from "@apollo/client";

//import color from "./backgroundColors";

export default function CategorySlyder() {
    // console.log("CategorySlyder");
    // console.log('request  GET_CATEGORY_WITH_ITEMS')

    const navigate = useNavigate();
    const {loading, error, data} = useQuery(GET_CATEGORY_WITH_ITEMS);
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        console.log('error CategorySlyder', error)
        return <div>Error!</div>;
    }


    const display_category =
        data.category.length > 0 &&
        data.category.map((category, index) => {
            //  const categoryColor = color[index];
            return (
                <SwiperSlide
                    className = {classes.Slide}
                    key = {index}

                    onClick = {() => {
                        navigate("/v1/category", {
                            state: {category: category.category, subCategory: "any"},
                        });
                    }}
                    // style = {{backgroundColor: categoryColor}}


                >
                    <img
                        className = {classes.img}
                        src = {img1}
                        alt = {category.category}
                        style = {{position: "relative"}}
                    ></img>
                    <div className = {classes.topLeft}>{category.category}</div>
                </SwiperSlide>
            );
        });

    return (
        <>
            <h3 className = {classes.title}>Категории</h3>
            <Swiper
                loop
                slidesPerView = {"auto"}
                spaceBetween = {3}
                className = "categorySwiper"
            >
                {display_category}
            </Swiper>
        </>
    );
}
