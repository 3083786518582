const CoinIcon = () => {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.56377 9.99902C0.56377 4.99902 4.15127 0.936522 8.56377 0.936523L13.1888 0.936523C13.7013 0.936523 14.1263 1.36152 14.1263 1.87402C14.1263 2.38652 13.7013 2.81152 13.1888 2.81152C9.91377 2.81152 7.43877 5.89902 7.43877 9.99902C7.43877 14.099 9.91377 17.1865 13.1888 17.1865C13.7013 17.1865 14.1263 17.6115 14.1263 18.124C14.1263 18.6365 13.7013 19.0615 13.1888 19.0615L8.56377 19.0615C4.15127 19.0615 0.56377 14.999 0.56377 9.99902ZM8.42627 2.81152C5.11377 2.89902 2.43877 6.08652 2.43877 9.99902C2.43877 13.9115 5.11377 17.099 8.42627 17.1865C6.66377 15.5615 5.56377 12.9865 5.56377 9.99902C5.56377 7.01152 6.66377 4.44902 8.42627 2.81152Z"/>
      <path d="M11.8118 9.99902C11.8118 6.54902 13.3618 3.44901 15.8618 1.93651C16.9243 1.28651 18.1618 0.936523 19.4368 0.936523C21.5868 0.936523 23.6118 1.89902 25.1368 3.63652C26.6243 5.33652 27.4368 7.59902 27.4368 9.99902C27.4368 12.399 26.6243 14.649 25.1493 16.3615C23.6118 18.099 21.5868 19.0615 19.4368 19.0615C18.1618 19.0615 16.9368 18.7115 15.8618 18.0615C13.3618 16.549 11.8118 13.449 11.8118 9.99902ZM25.5618 9.99902C25.5618 8.04902 24.9118 6.22404 23.7118 4.86154C22.5618 3.53654 21.0368 2.81152 19.4368 2.81152C18.4993 2.81152 17.6243 3.06151 16.8368 3.53651C14.8868 4.72401 13.6868 7.19902 13.6868 9.99902C13.6868 12.799 14.8993 15.274 16.8368 16.4615C17.6243 16.949 18.4993 17.1865 19.4368 17.1865C21.0368 17.1865 22.5618 16.4615 23.7368 15.124C24.9118 13.7615 25.5618 11.949 25.5618 9.99902Z"/>
      <path d="M5.56177 9.99902C5.56177 4.83652 8.83677 0.936523 13.1868 0.936523L19.4368 0.936523C23.8493 0.936523 27.4368 4.99902 27.4368 9.99902C27.4368 12.399 26.6243 14.649 25.1493 16.3615C23.6118 18.099 21.5868 19.0615 19.4368 19.0615L13.1868 19.0615C8.83677 19.0615 5.56177 15.1615 5.56177 9.99902ZM25.5618 9.99902C25.5618 6.03652 22.8118 2.81152 19.4368 2.81152L13.1868 2.81152C9.91177 2.81152 7.43677 5.89902 7.43677 9.99902C7.43677 14.099 9.91177 17.1865 13.1868 17.1865L19.4368 17.1865C21.0368 17.1865 22.5618 16.4615 23.7368 15.124C24.9118 13.7615 25.5618 11.949 25.5618 9.99902Z"/>
    </svg>
  )
}

export default CoinIcon
