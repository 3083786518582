import React, {useState} from "react";
import classes from "./podborki.module.css";
import {useNavigate} from "react-router-dom";

import img1 from "../../../assets/png/newShop.png";
import img2 from "../../../assets/png/hotPrice.png";
import img3 from "../../../assets/jpg/Rectangle 4.jpg";
import img4 from "../../../assets/jpg/Rectangle 5.jpg";
import img5 from "../../../assets/jpg/Rectangle 6.jpg";
import img6 from "../../../assets/jpg/Rectangle 7.jpg";

export default function Podborki() {
    const [amount, setAmount] = useState(true);
    const navigate = useNavigate();

    const displaypodborki = amount ? (
        <div>
            <h3 className = {classes.title}>ПОДБОРКИ НЕДЕЛИ</h3>
            <div
                className = {classes.Container}
                onClick = {() => {
                    // navigate("/v1/category", {
                        // state: {category: "Красота и здоровье", subCategory: "any"},
                    // });
                }}
            >
                <div className = {classes.newShop}>
                    <span className = {classes.textShop}>Новые магазины</span>
                    <img className = {classes.imgNewShop} src = {img1} alt='imgNewShop'></img>
                </div>
                <div className = {classes.hotPrice}>
                    <span className = {classes.textHotPrice}>Горячие предложения</span>
                    <img className = {classes.imgHopPrice} src = {img2} alt='imgHopPrice'></img>
                </div>
                <img className = {classes.img} src = {img3} alt = "img"></img>
                <img className = {classes.img} src = {img4} alt = "img"></img>
            </div>
            <p className = {classes.p} onClick = {() => setAmount(false)}>
                ПОКАЗАТЬ ЕЩЁ
            </p>
            <div className = {classes.BottomBorder}></div>
        </div>
    ) : (
        <div>
            <h3 className = {classes.title}>ПОДБОРКИ НЕДЕЛИ</h3>

            <div
                className = {classes.Container}
                // onClick = {() => {
                    // navigate("/v1/category", {state: {category: "Красота и здоровье"}});
                // }}
            >
                <div className = {classes.newShop}>
                    <span className = {classes.textShop}>Новые магазины</span>
                    <img className = {classes.imgNewShop} src = {img1} alt='img1'></img>
                </div>
                <div className = {classes.hotPrice}>
                    <span className = {classes.textHotPrice}>Горячие предложения</span>
                    <img className = {classes.imgHopPrice} src = {img2} alt='img2'></img>
                </div>
                <img className = {classes.img} src = {img3} alt = "ddd"></img>
                <img className = {classes.img} src = {img4} alt = "ddd"></img>
                <img className = {classes.img} src = {img5} alt = "ddd"></img>
                <img className = {classes.img} src = {img6} alt = "ddd"></img>
            </div>
            <p className = {classes.p} onClick = {() => setAmount(true)}>
                СВЕРНУТЬ
            </p>
            <div className = {classes.BottomBorder}></div>
        </div>
    );
    return <>{displaypodborki}</>;
}
