import React, { useState, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_USER_ORDER } from '../../../../requests/orderRequests';
import { TelegramContext } from '../../../../App';
import styles from './Order.module.css';
import { formatStatus } from '../../OrdersUser';
import Watched from '../../../Watched';

import  RefundItem from './RefundItem';
// import GetItem from './GetItem';
// import DeclineItem from './DeclineItem';
import { useNavigate } from 'react-router-dom';

import { useDispatch,  } from 'react-redux';

import { setItem } from '../../../../features/userOrderSlice';


import { ModalContext } from '../../../../App';
import { modalDisplayMode } from '../../../../Containers/PrimaryModal/PrimaryModal';




export function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('ru-RU', options);
}





function formatTimeDifference(endDate, startDate) {
  const difference = endDate - startDate;
  // console.log('difference', difference)

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);

  let formattedTime = "";
  if (days > 0) {
      formattedTime += `${days} дн. `;
  }
  if (hours > 0) {
      formattedTime += `${hours} ч. `;
  }
  if (minutes > 0) {
      formattedTime += `${minutes} мин.`;
  }

  // console.log('formattedTime', formattedTime)
  return formattedTime;
}


function formatRefund(status) {
  switch (status) {
    case "pending":
      return "В работе"
    case "done":
      return "закрыто"
    case "declined":
      return "отклонено"
    case "approved":
      return "возврат средств "
    default:
      return "на рассмотрении"
  }
}
  




const UserInfoBlock = ({ title, data, showArraw = true }) => {
  return (
    <div className={styles.infoBlock}>
      <div className={styles.info}>
        <span className={styles.infoTitle}>{title}:</span>
        <span className={styles.infoData}>{data}</span>
      </div>
    </div>
  )
}

const UserAddress = ({ address }) => {
  // console.log('address', address)
  if (!address) {
    return <div>Адрес не указан</div>
  }
  const {  delivery_city,  } = address;
  const region = !!delivery_city?.region ? `${delivery_city?.region}, ` : '';
  return (
    <div className={styles.address}>
       <span>
        <span>{`${address?.post_code}, ${address?.delivery_city?.country},`}</span>
        <span >{` ${region} ${address?.delivery_city?.city}, ${address?.street}, ${address?.house}, ${address?.flat}`}</span>
      </span>
    </div>
  )
}




const MenuInfo = ({ item }) => {
      // console.log('status', item.status)
      // console.log('refund',  item.refund_item)

      let content ;

      switch (item.status) {
        case "received":
          content = <div className={styles.deliveryContainer}> </div>
          return content


        case "declined":
          content =  <>
              <div className={styles.deliveryContainer}>
                <div className={styles.deliveryInfo}>
                  <span style={{ fontWeight: '700', fontSize: '8px' }}>Статус : </span>
                  <span> {formatRefund(item?.refund_item?.status) || '-'} </span>
                </div>
                <div className={styles.deliveryInfo}>
                  <span style={{ fontWeight: '700', fontSize: '8px' }}>Причина </span>
                  <span> {item?.refund_item?.reason || '-'} </span>
                </div>
                <div className={styles.deliveryButton} style={{ backgroundColor : (item.images) ? 'rgba(79, 140, 231, 1)'
                                                                                              : 'rgba(181, 181, 181, 1)'
                                                                                       }} >
                  <span style={{ fontWeight: '700', fontSize: '8px' }}>Фото товара</span>
                </div>
              </div>
              <textarea className ={styles.comment} 
                        placeholder="Комментарий к возврату" 
                        value = {item?.refund_item?.comment }
                        readOnly = {true}
                         />
                    
            </>
          

          return content
        
        default:

          content  = <div className={styles.deliveryContainer}>

          <div className={styles.deliveryInfo}>
              <span style ={{fontWeight:'700', fontSize : '8px'}}>Доставка </span>
              <span> {item.order_deliveries[0]?.company || '-'} </span> 
          </div>
          <div className={styles.deliveryInfo}>
              <span style ={{fontWeight:'700', fontSize : '8px'}}>Трек-номер </span>
              <span> {item.order_deliveries[0]?.track_number || '-'} </span> 
          </div>
  
          <div className={styles.deliveryButton} style={{ backgroundColor: item.status === 'shipping' ? 
                                                    'rgba(79, 140, 231, 1)' : 'rgba(181, 181, 181, 1)' }} 
                                                                                >
              <span style={{ fontWeight: '700', fontSize: '8px' }}>Отследить</span>
            </div>
        </div>
        return content
      }
      
}


const CardItem = ({  item, setModalActiveItem, setOrderItemId, setDisplayType }) => {
  // console.log('item', item)
  // console.log('status', item.status)

  const purposeName = item.item_variant.purposeName
  const purposeValue = item.item_variant.purposeValue
  const purposeName2 = item.item_variant.purposeName2
  const purposeValue2 = item.item_variant.purposeValue2

  const now = new Date();
  const expireDate = new Date(item.lot.expire_date) 
  const formattedTimeDifference = formatTimeDifference(expireDate, now);


  const {color,status, background = 'white'}  = formatStatus(item.status)

  const rate=item.item.shop.comment_items_aggregate.aggregate.avg.estimate || 0
  const shop_rating = rate.toFixed(1) 

  return (
    <div className={styles.itemRoot}  >
        <div className={styles.itemShop}>
          <div className={styles.itemShopName}>{item.item.shop.name}</div>
          <div className={styles.itemShopReit}>Рейтинг магазина ⭐️ {shop_rating}/5 </div>
        </div>

      <div className={styles.itemContainer}>
          <div className={styles.itemImage}>
            <img src={item.item.item_media[0].path} alt="item" />
          </div>
          <div className={styles.itemInfo}>
            <div className={styles.priceContainer}>
              <div className={styles.itemPrice}> {item.price} ₽ за {item.quantity} шт. </div>
              <div className={styles.itemTotal}> Итого :  {item.price * item.quantity } ₽</div>
            </div>
            <div className={styles.itemDescription}>
              <div  className={styles.itemName}>{item.item.name}</div>
              <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                {purposeName &&  <div className={styles.itemVariant}> {purposeName}: {purposeValue}</div>}
                {purposeName2 &&  <div className={styles.itemVariant}> {purposeName2}: {purposeValue2}</div>}
              </div>
            </div>
            <div className={styles.itemLots}>
              <span >{formattedTimeDifference}</span>
              <span >Прогресс: {item.lot.ordered_quantity}/{item.lot.total} </span>
            </div>
          </div>
          <div className={styles.itemStatusContainer}>
             {/* <div className={styles.itemStatus}> {formatStatus(item.status).status}</div> */}
             <div className = {styles.itemStatus} style = {{color : color, background : background}}>{status}</div>
             <div>
                <div className={styles.itemSteps}> от {item.lot.step} шт</div>
                <div > {item.quantity} шт.</div>
             </div>
          </div>
      </div>



      <MenuInfo item = {item} />


      <TotalButton status = {item.status}  
                    setModalActiveItem = {setModalActiveItem}
                    setOrderItemId = {setOrderItemId}
                    orderItemId = {item.id}
                    item = {item}
                    />

    </div> // Make sure this closing div is included
  );
};






// const LocalModalContent = ({ setModalActive }) => {


  
//   return (
//     <div>
//       <div className={styles.localModalContent}>
//         напишите нам мы ответим на все ваши вопросы
//       </div>

//       <input className={styles.localModalInput} type="text" placeholder="ваше сообщение" />

//       <div className={styles.localModalButton}>
//         <button className={styles.buttonCancel} onClick={() => setModalActive(false)}>отправить</button>
//       </div>
//     </div>
//   );
// };








function TotalButton({status, setModalActiveItem, setOrderItemId, orderItemId, item}){
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext)


  // const writeSupport = (item) => {

  
  //   const localModalContent = <LocalModalContent setModalActive={setModalActive} />;
  
  //   setModalContent(localModalContent);
  //   setModalMode(modalDisplayMode.withOverlay)
  //   setModalActive(true);
  // }
  
  



  switch (status) {
    case "payed" :
      return (<div className = {styles.buttonContainer}>
                <button className = {styles.buttonCancel} 
                          onClick={() => {
                                      setOrderItemId(orderItemId);
                                      setModalActiveItem(true)}}
                          >Отменить заказ</button>
                <button disabled={true} className = {styles.buttonGetting}>Получение товаров</button>
              </div>)
    case "confirmed":
      return (<div className = {styles.buttonContainer}>
                <button className = {styles.buttonCancel}
                          onClick={() => {
                                      setOrderItemId(orderItemId);
                                      setModalActiveItem(true);
                                      }}
                              >Отменить заказ</button>
                <button disabled={true} className = {styles.buttonGetting}>Получение товара</button>
              </div>)
    case "shipping":
      return (
                <button  disabled={false} 
                      className = {styles.buttonGetting}
                      onClick={() => {
                                      dispatch(setItem(item));
                                      navigate("/profile/order/get_item", { state: { orderId: orderItemId } })
                                      }}
                >Получение товара</button>
              )
    case "received":
      return (<div className={styles.buttonContainer} style={{ marginTop: '15px' }}>

              <button className = {styles.buttonDecline}
                      onClick={() => {
                                      dispatch(setItem(item));
                                      navigate("/profile/order/declined_item", { state: { orderId: orderItemId } })
                                      }}
                      >Возврат товара</button>
              </div>)
    case "declined":
      return (
              <button  className = {styles.buttonSupport} style ={{'backgroundColor': '#B5B5B5'}}
                      onClick={() => {
                                      // writeSupport(item)                                     
                                      }}
                      >Написать в поддержку</button>
              )
    case "cancell":
      return (
              <button  className = {styles.buttonDecline}>Смотреть заявку на возврат</button>
              )
    default:
          return 

  }
};





function OrderItem({order , setDisplayType, setOrderItemId, orderItemId, }){

  const [isModalActive, setModalActive] = useState(false);
  const [isModalActiveItem, setModalActiveItem] = useState(false);

  const address = order.shipping_addresses[0] || ''
  
  const user = order.user.user_profile.name + " " + order.user.user_profile.surname
  

  const orderQuantity = order.order_items.reduce((acc, item) => acc + item.quantity, 0);
  const orderTotal = order.total
  const order_number = order.order_number || '000-000-00'

  
 
  

 

  return (
    <>
    <div className={styles.root}>
      <div className={styles.header}>
        <h2> Заказ № {order_number}</h2>
        <div> от {formatDate(order.created_on)} </div>
      </div>
      <div className={styles.userInfo}>
        <UserInfoBlock title="Получатель" data={user} />
        <UserInfoBlock title="Номер телефона" data={address.phone_number} />
        <UserInfoBlock title="E-mail" data={address.email} />
        <UserInfoBlock title="Способ получения" data="Самовывоз" />
        <UserInfoBlock title="Адрес доставки" data={<UserAddress address={address}/>} />
        
        

      </div>

      <div>
      {
        order.order_items.map((item, index) => ( 
          
            <div key={index} >
            <CardItem  item={item}   
                      setModalActiveItem= {setModalActiveItem} 
                      setOrderItemId = {setOrderItemId}
                      setDisplayType = {setDisplayType}

                      />
            </div>
          
        ))
      }
      </div>

      <div className={styles.totalSection}>
        <span className={styles.totalHeader}>Итог: {orderTotal}</span>
        <div className={styles.totalCosts}>
          <div className={styles.totalItems}>
            <div className={styles.totalItemsCount}>
              <span className={styles.totalItemsDiff}>Наименований: {Object.keys(order.order_items).length}шт</span>
              <span className={styles.totalItemsAll}>Количество товаров: {orderQuantity}шт</span>
            </div>
            <span className={styles.totalItemsPrice}>{orderTotal} &#8381;</span>
          </div>
          
          <div className={styles.totalDivider} />
          <div className={styles.finalCost}>
            <span className={styles.finalCostTitle}>Общая стоимость:</span>
            <span className={styles.finalCostSum}>{orderTotal} &#8381;</span>
          </div>
        </div>
      
      
        <div className={styles.totalButtons}>
          <button className={styles.totalButtonDisable}>Электронный чек</button>
          <button className={styles.totalButtonDisable}>Повторить заказ</button>
        </div>
      

      </div>

      {/* {isModalActive && <CancelOrder setModalActive={setModalActive} order={order} />} */}
      {isModalActiveItem && <RefundItem setModalActiveItem={setModalActiveItem} order={order} orderItemId = {orderItemId} />}

    </div>
    </>
    )

}









const Order = () => {
  const location = useLocation();
  const orderId = location.state.orderId;
  const {userID} = useContext(TelegramContext) ;
  const [orderItemId, setOrderItemId] = useState(null)


  const state  = location.state
  // console.log('state', state)
  // console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD', orderId)

  

  const {data, error, loading} = useQuery(GET_USER_ORDER, {
    fetchPolicy: 'cache-and-network',
      variables: {userID, orderId},
  });

  if (loading) {
      return 'loading...';
  }
  if (error) {
      console.error('watched items error', error);
      return 'error';
  }

  const order = data.order[0]



  console.log('data', data) 
  return (
    <>
      <OrderItem order = {order} 
                 orderItemId={orderItemId}
                 setOrderItemId={setOrderItemId}
                  />
      
     <Watched />
    </>
    
  );
};

export default Order;
