import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import styles from './DefaultReviews.module.css'
import '../Tab.css';
import 'react-tabs/style/react-tabs.css';
import PendingReviews from '../PendingReviews/PendingReviews';
import DetailsReviewItem from '../DetailsReviewItem/DetailsReviewItem';


const DefaultReviews = ({
                            allItemsCount,
                            itemPendingComments,
                            itemWithComments,
                            setDisplayMode,
                            setItem,
                            defaultIndex, loading, refetch
                        }) => {


    return (
        <Tabs defaultIndex = {defaultIndex}>
            <TabList>
                <Tab className = {`${styles.react_tabs_tab} react-tabs__tab`}>
                    <div>Мои отзывы</div>
                </Tab>
                <Tab className = {`${styles.react_tabs_tab} react-tabs__tab`}>
                    <div>Ожидают отзыва</div>
                    <span className = {styles.pendingItemCount}>{itemPendingComments?.length || 0} </span>
                </Tab>
            </TabList>

            <TabPanel>
                 <div style = {{marginTop: "10px"}}> 
                    {!itemWithComments && <div>Загрузка данных...</div>}
                    {itemWithComments?.length
                        ? itemWithComments?.map(item => <DetailsReviewItem item = {item}
                                                                           key = {item.id}
                                                                           setDisplayMode = {setDisplayMode}
                                                                           setItem = {setItem}
                                                                           refetch = {refetch}
                        />)
                        : itemWithComments && <div>У вас нет ни одного отзыва</div>}
                        
                </div> 
                <div style = {{height: "45px"}}></div>
            </TabPanel>

            <TabPanel>
                <PendingReviews data = {itemPendingComments}
                                allItemsCount = {allItemsCount}
                                setDisplayMode = {setDisplayMode}
                                setItem = {setItem}
                />
            </TabPanel>

        </Tabs>
    )
}

export default DefaultReviews