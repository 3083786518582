import { gql} from "@apollo/client";










export const STORAGE_ITEMS = gql`
query storageItems2($storageId: Int) {
    storage_item(distinct_on: item_id, where: {storage_id: {_eq: $storageId}}) {
      item {
        articleNumber 
        name
        id
        item_variants(where: {storage_items: {storage_id: {_eq: $storageId}}}) {
          id
          articleNumber
          height
          image
          length
          purposeName
          purposeName2
          purposeValue
          purposeValue2
          weight
          width
          storage_items(where: {storage_id: {_eq: $storageId}}) {
            quantity
          }
        }
        item_media(where: {type: {_eq: "image"}}, limit: 1) {
            path
          }
      }
      
    }
  }
  
`;



export const UPDATE_STORAGE = gql`
mutation UpdateStorage($storageId: Int!, $variantId: Int!, $quantity: Int!) {
  update_storage_item(where: {storage_id: {_eq: $storageId},
                             _and: {variants_id: {_eq: $variantId}}},
                              _set: {quantity: $quantity}) {
    affected_rows
  }
}


`;




// export const STORAGE_ITEMS = gql`
//     query storageItems($storageId: Int) {
//         storage_item(where: {storage_id: {_eq: $storageId}}) {
//             item_id
//             variants_id
//             quantity
//             item {
//                 id
//                 name
//                 articleNumber
//                 item_media(where: {type: {_eq: "image"}}) {
//                     path
//                 }
//             }
//             item_variant {
//                 id
//                 articleNumber
//                 height
//                 weight
//                 length
//                 width
//                 image
//             }
//         }
//     }
// `;                



export const ADD_ITEM_TO_STORAGE = gql`
mutation addItemsToStorage($items: [storage_item_insert_input!]!) {
    insert_storage_item(objects: $items) {
      returning {
        id
      }
    }
  }  
`;






export const GET_SHOP_STORAGE = gql`
query getShopStorage($shopId: Int) {
    storage(where: {shop_id: {_eq: $shopId}}) {
        id
        storage_items {
            item_id
            storage_id
            variants_id
            quantity
          }
        storage_address {
            id
            city_id
            street
            comment
            flat
            house
            index
            delivery_city {
                city 
                region
            }
                    
            }
               

        }
        

}
`;


export const SAVE_STORAGE = gql`
mutation MyMutation($shopId: Int! ,
                        $street: String!,
                        $city_id: Int!,
                        $comment: String!,
                        $flat: Int!,
                        $house: String!,
                        $index: Int!


                ) {
    insert_storage(objects: {shop_id: $shopId, 
                        storage_address: {data: {city_id: $city_id,
                                    street: $street, 
                                    comment: $comment, 
                                    flat: $flat, 
                                    house: $house, 
                                    index: $index}}}) {
      returning {
        id
      }
    }
  }
  `;


export const DELETE_STORAGE_ADDRESS = gql`
mutation deleteStorageAdress($addressId: Int!) {
    delete_storage_address(where: {id: {_eq: $addressId}}) {
      returning {
        id
      }
    }
  }
  
`;
