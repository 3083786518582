import React from 'react';
import styles from "./ProfileInputWithLinks.module.css"
import link from '../../../../../assets/svg/link.svg';

function ProfileInputWithLinks({
                                   label,
                                   value,
                                   callback,
                                   type,
                                   placeholder,
                                   readOnly,
                                   onClickCallback,
                                   isLinksDisplay,
                                   maxData,
                                   minData,
                                   required
                               }) {

            
    return (<div className = {styles.wrapper}>
            <label className = {styles.label}>
                {label}
                <input className = {styles.registeredInput}
                       value = {value} type = {type}
                       onChange = {callback}
                       placeholder = {placeholder}
                       readOnly = {readOnly}
                       onClick = {onClickCallback}
                       max = {maxData}
                       min = {minData}
                       required = {required}
                />
            </label>
            {isLinksDisplay && <img src = {link} alt = ""/>}
        </div>


    );
}

export default ProfileInputWithLinks;