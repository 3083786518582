import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Slider/slider-theme.css";

import img1 from '../../../assets/jpg/img1.jpg';
import img2 from '../../../assets/jpg/img2.jpg';
import img3 from '../../../assets/jpg/img3.jpg';
import img4 from '../../../assets/jpg/img4.jpg';
import classes from './ProductSlider.module.css';

export default function ProductSwiper({images}) {
    // console.log('ProductSwiper ', images)

    const urls = [img1, img2, img3, img4]

    const imagesSlides =
        images.length > 0 ? (
            images.map((el, index) => {
                return (
                    <div key = {index}>
                        <img src = {el.path} className = {classes.sliderImage} alt = "product"/>
                    </div>
                );
            })
        ) : urls.map((photo, index) => {
            return (
                <div key = {index}>
                    <img src = {photo} alt = "x" className = {classes.sliderImage}></img>
                </div>
            );
        });

    let settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
    }

    return (
        <div className = "ProductSwiper">
            <Slider {...settings}>
                {imagesSlides}
            </Slider>
        </div>
    );
}
