import styles from './AddNewReview.module.css'
import React, {useContext, useState} from 'react';
import Separator from '../../../../Сomponents/Separator/Separator';
import ProfileInput from '../../ProfileMain/components/ProfileInput/ProfileInput';
import ProfileTextarea from '../../ProfileTextarea/ProfileTextarea';
import ItemCheckbox from '../../../CartPage/ItemCheckbox';
import ApplyButton from '../../ApplyButton/ApplyButton';
import AddReviewItem from './AddReviewItem';
import {ModalContext, TelegramContext} from '../../../../App';
import {useMutation} from '@apollo/client';
import {ADD_NEW_REVIEW} from '../../../../requests/commentUserRequests';
import {MODE} from '../index';
import {modalDisplayMode} from '../../../PrimaryModal/PrimaryModal';
import {text} from '../PublishingRulesText';
import AddReviewImages from '../AddReviewImages/AddReviewImages';
// import AddReviewVideo from '../AddReviewVideo/AddReviewVideo';


export default function AddNewReview({item, refetch, setDisplayMode, setDefaultIndex}) {
    //console.log(item)
    const {userID} = useContext(TelegramContext)
    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const [advantages, setAdvantages] = useState('')
    const [disAdvantages, setDisAdvantages] = useState('')
    const [comment, setComment] = useState('')
    const [isAnonymous, setIsAnonymous] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [images, setImages] = useState([])
    const [video, setVideo] = useState({
        file: null,
        localUrl: '',
        imgUrl: '',
        fileName: null
    })

    //for button
    const [isAddedNewReview, setIsAddedNewReview] = useState(false);

    const [addNewReview] = useMutation(ADD_NEW_REVIEW,);

    const addNewAdvantages = (value) => setAdvantages(value)
    const addNewDisAdvantages = (value) => setDisAdvantages(value)
    const addNewComment = (value) => setComment(value)

    const anonymousToggle = () => setIsAnonymous(!isAnonymous)

    //go to default mode with pending reviews
    const setActiveReviewsTab = (value) => {
        setDefaultIndex(value)
        setDisplayMode(MODE.default)
    }


    const localModalContent = <div className = {styles.localModalContent}>{text}</div>

    const openPublishingRules = () => {
        setModalContent(localModalContent);
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
    }

    const addNewReviewHandler = async () => {
        const imgData = images.map(img => {
            //  console.log(img.imgUrl)
            return {url: img.imgUrl.toString(), file_name: img.fileName.toString()}
        })

        console.log(imgData)

        const {data, error} = await addNewReview({
            variables: {
                item_id: +item.itemID,
                shop_id: item.shop.id,
                estimate: item.ratingValue,
                disadvantages: disAdvantages,
                text: comment,
                advantages: advantages,
                user_id: userID,
                anonim: isAnonymous,
                // modifiers_id: item.modifiersID,
                variant_id: item.modifiersID,
                order_id: item.orderID,
                imgData: imgData
            }
        })

        if (data) {
            //  console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',data)
            setIsAddedNewReview(true)
            refetch()
        }
        if (error) setDisplayMode(MODE.default)
    }

    return (<div className = {styles.wrapper}>

        <AddReviewItem item = {item}/>
        <h4 className = {styles.optionalText}>Если вас устроила покупка, можете поставить только оценку товара.
                                              Остальные&nbsp;поля&nbsp;не&nbsp;обязательны.</h4>
        <Separator/>

        {/*add video
        <div className = {styles.addVideoContainer}>
            <p>Добавьте видео длительностью до 3 минут</p>
            <AddReviewVideo video = {video} setVideo = {setVideo}/>
        </div>
        */}

        <div className = {styles.addImagesContainer}>
            <p>Добавьте до 10 фотографий</p>
            <AddReviewImages images = {images}
                             setImages = {setImages}
                             setIsLoading = {setIsLoading}/>
        </div>

        <div className = {styles.inputContainer}>

            <ProfileInput placeholder = {"Достоинства"}
                          callback = {addNewAdvantages}
                          value = {advantages}/>

            <ProfileInput placeholder = {"Недостатки"}
                          callback = {addNewDisAdvantages}
                          value = {disAdvantages}
            />

            <ProfileTextarea placeholder = {"Комментарий"}
                             callback = {addNewComment}
                             value = {comment}
            />
        </div>


        <div className = {styles.unknownComment}>
            <p>Анонимный отзыв</p>
            <ItemCheckbox isChecked = {isAnonymous} onClick = {anonymousToggle}/>
        </div>

        <div style = {{padding: "0 15px"}}>
            <ApplyButton name = {"Отправить отзыв"}
                         callback = {addNewReviewHandler}
                         isAddedNewReview = {isAddedNewReview}
                         setActiveReviewsTab = {setActiveReviewsTab}
                         disabled = {isLoading}
            />

        </div>


        <div className = {styles.linkContainer}>
            <span className = {styles.linkText}> Нажимая на кнопку, вы принимаете</span>
            <span className = {styles.link} style ={{'color' : '#B5B5B5'}} 
            // onClick = {openPublishingRules}
            >правила публикации</span>
        </div>
        <div style = {{height: "65px"}}></div>
    </div>);
}