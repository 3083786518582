import {gql} from '@apollo/client';





export const SHOP_ITEMS = gql`
    query itemsForStorage($shopId: Int!) {
        item(where: {shop_id: {_eq: $shopId}}) {
            id
            about
            name
            articleNumber
            item_media(where: {type: {_eq: "image"}}) {
                path
            }
            item_variants {
                id
                articleNumber
                height
                weight
                length
                width
                image
                articleNumber
                purposeName
                purposeValue
                purposeName2
                purposeValue2
            }
    }
}
`;





export const SAVE_ITEM = gql`
  mutation createItem(
    $about: String!,
    $brand: String!,
    $name: String!,
    $articleNumber: String!,
    $barcode: bigint!,
    $partNumber: String!,
    $character: jsonb!,
    $country: String!,
    $waranty: String!,
    $quantity_box: Int!,
    $color: String!,
    $shop_id: Int!,
    $item_media: [item_media_insert_input!]!,
    $item_variants: [item_variants_insert_input!]!,
    $category : Int, 
    
  ) {
    insert_item(objects: {
      about: $about,
      brand: $brand,
      created_on: "now()"
      name: $name,
      articleNumber: $articleNumber,
      barcode: $barcode,
      partNumber: $partNumber,
      character: $character,
      country: $country,
      waranty: $waranty,
      quantity_box: $quantity_box,
      color: $color,
      shop_id: $shop_id,
      updated_on: "now()",
      item_media: {data: $item_media}
      item_variants: {data: $item_variants}
      category_items: {data: {category_id: $category}}
    }) {
      returning {
        id
      }
    }
  }
`;


export const CHECK_ITEM_ORDERS = gql`
    query checkItemOrders($itemId: Int) {
        order_items(where: {item_id: {_eq: $itemId}}) {
            id
            status
        }
    }
`;






export const GET_ITEM = gql`
    query getItem($itemId: Int) {
        item(where: {id: {_eq: $itemId}}) {
            id
            status
            name
            about
            articleNumber
            barcode
            brand
            partNumber
            character
            color
            country
            waranty
            created_on
            quantity_box

            item_media {
                path
                type
            }
            item_variants{
                height
                weight
                length
                width
                image
                articleNumber
                purposeName
                purposeValue
                purposeName2
                purposeValue2
                
            }
            category_items {
                category {
                  category
                  subcategory
                  product_category
                }
              }
                   

        }
    }
`;

export const DELETE_ITEM = gql`
    mutation deleteItem($itemId: Int) {
        delete_item(where: {id: {_eq: $itemId}}) {
            affected_rows
        }
    }
`;












//  previous version


export const WATCHED_ITEMS = gql`
    query watchedItems($userID: bigint) {
        item(where: {user_watches:
        {user_id: {_eq: $userID}    } },
            order_by: {updated_on: desc}, limit: 10) {
            id
            name
            about
           
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }

            }
            item_media(where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }


    }
`;


export const BOUGHT_ITEM = gql`
    query boughtItems($userID: bigint) {
        item(where: {order_items: {order: {status: {_eq: "payed"}, user_id: {_eq: $userID}}}}, order_by: {updated_on: desc}, limit: 10) {
            id
            name
            about
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            item_media(where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }

        }
    }
`;










export const GET_ITEMS = gql`
    query getItems  ( 
        $userID: bigint,
        $limit: Int,
        $offset: Int,
        $dateNow:timestamptz) @cached {
        item(limit: $limit,
            offset: $offset,
            where: {
                _or:
                [{status: {_eq: "onsale"}},
                    {status: {_eq: "preparation"}}],

                })
        {
            id
            name
            about
            created_on
            lots(where: {status: {_eq: "active"}},
                    order_by: {price: asc})
                 {
                price
            }
            
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            item_media(where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }

        item_aggregate {
            aggregate {
                count
            }
        }
    }
`;


export const GET_SEARCH_ITEMS = gql`
    query getSearchItems ($like: String,
        $userID: bigint,
        $limit: Int,
        $offset: Int,
        $dateNow:timestamptz) {

        search_item_aggregate(
            args: {search: $like},
            where: {
                _or: [{status: {_eq: "onsale"}},
                    {status: {_eq: "preparation"}}

                ],

                }) {
            aggregate {
                count
            }
        }

        search_item(
            args: {search: $like 

            },
            limit:$limit,
            offset:$offset,
            where: {
               
                _or: [{status: {_eq: "onsale"}},
                    {status: {_eq: "preparation"}}],

                })        {
            id
            name
            about
            created_on
            lots(where: {status: {_eq: "active"}}) {
                price
            }
            comment_items {
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            item_media(where: {type: {_eq: "image"}}) {
                path
            }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
            }
        }

    }
`;

export const ITEM_BY_ID = gql`
    query itemById ($_ID: Int){
        item(where: {id: {_eq: $_ID}}) {
            about
            created_on
            id
            name
            photo
            promotion_id
            shop_id
            status
            updated_on
            item_modifiers {
                barcode
                height
                item_sku
                length
                modifiers
                weight
                width
                characteristic
                id
            }
            images_items {
                id
                url
            }
            category_items {
                category_id
                item_id
                category {
                    category
                    product_category
                    subcategory
                    fee
                }
            }
        }
    }
`

export const SHOPS_ITEMS = gql`
    query shopItems ($_ID: Int){
        item(order_by: {id: desc_nulls_last},where: {shop_id: {_eq: $_ID},status: {_neq: "preparation"}}) {
            id
            name
            about
            status
            images_items {
                id
                url
                prime_queue
            }
            joint_shopping {
                price
            }
            item_modifiers {
                id
                barcode
                characteristic
                height
                item_id
                item_sku
                length
                modifiers
                width
            }
            item_lots {
                id
                item_id
                price
                min_step
                lots_modificators {
                    id
                    lots_id
                    
                    quantity
                    status
                    item_modifier {
                        modifiers
                    }
                }
            }
        }
    }
`
export const ADD_ITEM_LOT = gql`
    mutation addItemLot(
        $price: numeric,
        $itemID: Int,
        $minStep: Int,
        $expire_date: timestamptz,
        $modifiersData: [lots_modificators_insert_input!] =
        [{modifiers_id: 5074, quantity: 10, status: "onsale"}]) {
        insert_item_lots(
            objects: {
                item_id: $itemID,
                min_step: $minStep,
                price: $price, status: "onsale",
                expire_date : $expire_date,
                lots_modificators: {data: $modifiersData}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`
export const DELETE_ITEM_LOT = gql`
    mutation deleteItemLot($id: Int) {
        delete_item_lots(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`

export const ADD_REST_NEW_PRODUCT_MUTATIONS = gql`
    mutation AddRestNewProduct(
        $imgObj: [images_item_insert_input!]=[] ,
        $modifiersObj: [item_modifiers_insert_input!]=[]) {
        insert_images_item(objects: $imgObj) {
            affected_rows
        }
        insert_item_modifiers(objects: $modifiersObj) {
            affected_rows
        }
    }
`;

export const ADD_ITEM_MUTATION = gql`
    mutation addNewItem(
        $about: String,
        $name: String,
        $shop_id: Int,
        $category_id: Int,
    ) {
        insert_item(objects: {
            about: $about,
            name: $name,
            shop_id: $shop_id,
            category_items: {data: {category_id: $category_id}}
        }) {
            returning {
                id
            }
        }
    }
`;

export const CHANGE_ITEM_STATUS = gql`
    mutation changeItemStatus($newProductID: Int) {
        update_item(where: {id: {_eq: $newProductID}}, _set: {status: "moderation"}) {
            affected_rows
        }
    }
`

export const GET_ITEM_MEDIA = gql`
    query getItemMedia($itemId: Int) {
        item(where: {id: {_eq: $itemId}}) {
            id
            
            articleNumber
            item_media {
                id
                path
                type
            }
        }
     
    }
`




export const DELETE_ITEM_MEDIA = gql`
    mutation deleteItemMedia($id: Int) {
        delete_item_media(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`