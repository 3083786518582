import {gql} from '@apollo/client';


export const GET_USER_REVIEWS = gql`
    query getUserReviews($userID: bigint) {
        order(where: {user_id: {_eq: $userID}, order_items: {status: {_eq: "received"}}}) {
            id
            order_items {
                id
                price
                item_variant {
                    id
                    purposeName
                    purposeValue
                    purposeName2
                    purposeValue2
                }
                item {
                    id
                    name
                    comment_items(where: {user_id: {_eq: $userID}}) {
                        advantages
                        status
                        disadvantages
                        estimate
                        id
                        text
                        updated_on
                        variant_id
                        order_id
                        anonim
                        comment_likes {
                            date
                            likes
                            user_id
                            comment_id
                        }
                        complaint_comments {
                            id
                            reason
                            status
                            text
                        }
                        images_comments {
                            id
                            tg_id
                            url
                            comment_id
                        }
                    }
                    item_media(where: {type: {_eq: "image"}}) {
                        id
                        item_id
                        path
                    }
                    shop {
                        id
                        name
                    }
                }
            }
        }
    }


`


export const ADD_NEW_REVIEW = gql`
    mutation addNewReview(
        $item_id: Int,
        $shop_id: Int,
        $estimate: Int,
        $disadvantages: String = "",
        $text: String = "",
        $advantages: String = "",
        $user_id: bigint,
        $variant_id: Int,
        $order_id: Int,
        $anonim: Boolean,
        $imgData: [images_comment_insert_input!]=[{}]) {
        insert_comment_item(
            objects: {advantages: $advantages,
                item_id: $item_id,
                estimate: $estimate,
                disadvantages: $disadvantages,
                text: $text,
                shop_id: $shop_id,
                anonim:$anonim,
                variant_id: $variant_id,
                order_id: $order_id,
                user_id: $user_id,
                images_comments: {data: $imgData}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`


export const EDIT_REVIEW = gql`
    mutation editReview(
        $id: Int,
        $estimate: Int,
        $anonim: Boolean,
        $advantages: String,
        $disadvantages: String,
        $text: String,
        $objects:
        [images_comment_insert_input!] = [{}]) {
        update_comment_item(where: {id: {_eq: $id}}, _set: {estimate: $estimate, anonim: $anonim, advantages: $advantages,disadvantages: $disadvantages, text: $text,  updated_on: "now()"}){
            affected_rows
            returning {
                id
            }
        }
        insert_images_comment(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }`

export const DELETE_REVIEW = gql`
    mutation deleteReview($id: Int) {
        delete_comment_item(where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
        delete_images_comment(where: {comment_id: {_eq: $id}}) {
            affected_rows
        }
    }
`


export const INSERT_LIKE = gql`
    mutation insertLike(
        $comment_id: Int,
        $likes: Boolean,
        $userID: bigint) {
        insert_comment_likes(objects: {comment_id: $comment_id, likes: $likes, user_id: $userID}, on_conflict: {constraint: comment_likes_pkey, update_columns: likes}) {
            affected_rows
            returning {
                comment_id,
                likes,
                user_id
            }
        }
    }`


export const DELETE_IMAGES_COMMENT = gql`
    mutation deleteImagesComment($id: Int) {
        delete_images_comment(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`