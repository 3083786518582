import React, {useState} from 'react';
import styles from './CreateStorage.module.css';
import cities from '../../../../utils/city.json';
import searchSvg from '../../../../assets/svg/search.svg';
import point from '../../../../assets/svg/point.svg';
import pointActive from '../../../../assets/svg/pointActive.svg';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { SAVE_STORAGE } from '../../../../requests/storageRequests';




import {  useForm } from 'react-hook-form';
// import { useDispatch } from 'react-redux';
// import { setAddress } from '../../../../features/storageSlice';
import { useLocation, useNavigate } from 'react-router-dom';

export const CREATE_STORE_MODES = {
    city : 'city',
    address : 'address',
}

function City({selectedCity, 
                setSelectedCity,
                setDisplayMode}) {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searc, setSearc] = useState('');
  
    const handleSearch = () => {
        setSearc(
            'ищем город...'
        )
        const matchingCities = cities.filter(
          cit => cit.Delivery_City && cit.Delivery_City.city.toLowerCase().includes(searchTerm.toLowerCase())
        );
    
        if (matchingCities.length === 0 && searchTerm !== '') {
          // Make the API call
          axios.get(`https://api.jbspace.xyz/check_${searchTerm}`)
            .then(response => {
              setSearchResults(response.data);  
                setSearc(null)
            })
            .catch(error => {
                setSearc(
                    'Не смогли найти населенный пункт, попробуйте еще раз'
                )
              console.error("There was an error fetching the data", error);
            });
        }
      };
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleSearch();
        }
      };

   
    
      const allCities = [...cities, ...searchResults];

    const filteredItems = allCities.filter(
        cit => cit.Delivery_City && cit.Delivery_City.city.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    function itemHandler(event, item) {
        // const name = { 'Бренд': 'brand', 'Страна': 'country', 'Цвет': 'color' }[title];
        // console.log('name', name);
        setSelectedCity(item);
        // setFormData((prev) => ({ ...prev, [name]: item })); 
      }
  
    return (
      <div className={styles.wrapper}>
        <h2 className={styles.title}>НАСЕЛЁННЫЙ ПУНКТ</h2>
        <div className={styles.findContainer}>
          <input
            className={styles.search}
            type="text"
            placeholder="Введите название населеённого пункта"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}

          />
          <button className={styles.find} onClick={handleSearch}>
            <span className={styles.buttonText}>Найти</span>
            <img className={styles.searchSvg} src={searchSvg} alt="" />
          </button>
        </div>
        {searc && <div className={styles.searchResult}>{searc}</div>}
        {filteredItems.map((cityWrapper, index) => {
                const cityItem = cityWrapper.Delivery_City;
                return (
                  <div className={styles.cityContainer}
                        onClick={(e) => itemHandler(e, cityItem)}
                        key={index}>
                    <div className={styles.city}>
                      <span className={styles.cityName}>{cityItem.city || ''}</span>
                    <div className={styles.cityInfo}>
                       
                        <span className={styles.cityCountry}>{cityItem.region || ''}</span>
                        <span className={styles.cityCountry}>{cityItem.country || ''}</span>
                    </div>
                    </div>
                    <div className="point">
                            {selectedCity === cityItem ? (
                              <img src={pointActive} alt="link" />
                            ) : (
                              <img src={point} alt="choose" />
                            )}
                      
                    </div>
                  </div>
                );
        
})}
            <button className={styles.addButton} disabled={!selectedCity} onClick={() => {setDisplayMode(CREATE_STORE_MODES.address)}}>
                Далее
            </button>

      </div>
    );
  }
  


  function FlatButton({flatValue, setFlatValue}) { 

    const flatHandler = (e) => {
        setFlatValue(!flatValue); 
    };
  
    return (
      <div className={styles.flatContainer}
        onClick={flatHandler}>
        <span className={styles.flatText}>Нет номера квартиры/офиса</span>
        <div className="point">
          {flatValue ? (
            <img src={pointActive} alt="link" />
          ) : (
            <img src={point} alt="choose" />
          )}
        </div>
      </div>
    );
  }
  


function Address({
          city,
          shopId,
}
        ){

    const navigate = useNavigate();
    const [flatValue, setFlatValue] = useState(false)



    const {
      register,
      handleSubmit,   
      
      formState: { errors, isValid},
      // setValue,
      getValues, 
    } = useForm({
      defaultValues: {
        index: '',
        street: '',
        house: '',
        flat: '',
        comment: '',
      }, 

    })

    const [saveStorage, { data }] = useMutation(SAVE_STORAGE )


    async function submit( ){
      const value = getValues()
        try {
           const {data: mutationResult} = await  saveStorage({ variables: {
              shopId: shopId,
              city_id: city.id,
              index: value.index,
              street: value.street,
              house: value.house,
              flat: value.flat,
              comment: value.comment,
            } });

             if (mutationResult) {
              navigate('/goods_storage', { state: { shopId } });
            } else {
              alert("An error occurred while saving the item.");
              await fetch(process.env.REACT_APP_BOT_NOTIFICATION, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: 'проблема с mutation result 3', }),
              });
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }

    }


    return(
        <div>
            <h2 className={styles.title}>АДРЕС</h2>
            <form className={styles.form} onSubmit={handleSubmit(submit)}>
                    <input className={styles.input} type="number" 
                            placeholder="Индекс" 
                            {...register('index', {required: true, valueAsNumber:true})}/>
                    {/* {errors.index & errors.text} */}
                    <input className={styles.input} type="text"
                            placeholder="Улица"
                            {...register('street', {required: true})}/>
                    <input className={styles.input} type="text"
                            placeholder="Дом"
                            {...register('house', {required: true})}/>
                    <input className={styles.input} type="number"
                            placeholder="Квартира"
                            {...register('flat', {required: {flatValue}, valueAsNumber: true})}/>
                    <FlatButton flatValue = {flatValue}
                                setFlatValue = {setFlatValue}  />
                    <textarea className={styles.textarea} type="text"
                            placeholder="Комментарий"
                            {...register('comment', {required: true})}/>

                <button className={styles.addButton} 
                disabled={!isValid} 
                type="submit"
                            >
                Далее
            </button>

            </form>
        </div>
    )
}




function CreateStorage(){
    const [displayMode, setDisplayMode] = useState(CREATE_STORE_MODES.city)
    const [selectedCity, setSelectedCity] =  useState(null)
    const location = useLocation();
    const shopId = location.state.shopId;

    
    let content;
    switch(displayMode){
        
        case CREATE_STORE_MODES.city:
            content = <City 
            selectedCity = {selectedCity}
            setSelectedCity ={setSelectedCity}
            setDisplayMode = {setDisplayMode}
            />
            break;
        case CREATE_STORE_MODES.address:
            content = <Address
                shopId = {shopId}
                city = {selectedCity}
            />
            break;
        default:
            content = <div>Invalid mode</div>

    }

    return(
        <div className={styles.wrapper}>
            {content}
        </div>
    )
}

export default CreateStorage;