import React, {useEffect} from 'react';
import favouriteImage from '../../../assets/svg/favourite.svg';
import {useNavigate} from 'react-router-dom';
import  AmountOfLikes from '../../LikedItems/utils/AmountOfLikes';
import classes from './Favoutites.module.css';
import {useContext} from 'react';
import {TelegramContext} from '../../../App';


const Favoutites = () => {
    // console.count('FAOVIRITES');
    const {userID} = useContext(TelegramContext);

    const navigate = useNavigate();



    useEffect(() => {
    });
    return (
        <div
            className = {classes.container}
            onClick = {() => {
                if (userID === 0) {
                    navigate('/v1/login');
                } else{
                navigate('/likes');
                }
            }}>
            <img className = {classes.img} src = {favouriteImage} alt = "favouriteImage"></img>
            {/* <div className={styles.count}> */}
            <AmountOfLikes/>
            {/*   </div> */}
        </div>
    );
};

export default Favoutites;
