import styles from "./Shops.module.css";
import {Link, useNavigate } from "react-router-dom";

function PerformanceItem({label, value}) {
    return (
        <div className={styles.performanceItem}>
            <div className={styles.performanceItemLabel}>
                {label}
            </div>

            <div className={styles.performanceItemValue}>
                {value}
            </div>
        </div>
    );
}

function Performance( {shop}) {
    // Define the data for your performance items

    // console.log('index Perfomanse', shop )
    const rating = shop.comment_items_aggregate?.aggregate.avg.estimate 
    const ratingValue = rating ? `⭐️ ${rating.toFixed(1)}/5` : "⭐️ 0/5";
    const ratingCount = shop.comment_items_aggregate?.aggregate.count || 0;
    const ordersCount = shop.shop_orders_aggregate?.aggregate.count || 0;
    const soldCount = shop.shop_payments_aggregate?.aggregate.sum.total_amount || 0;
    const lotsCount = shop.lots_aggregate?.aggregate.count || 0;
    const ordersToday = shop.order_items_aggregate?.aggregate.count ;
    // console.log('ordersToday', ordersToday)



    const performanceData = [
        {label: "Рейтинг товаров", value: ratingValue},
        {label: "Всего заказов", value: ordersCount},
        {label: "Продано на", value:  `${soldCount} ₽`},
        {label: "Всего отзывов", value: ratingCount},
    ];

    return (
        <div className={styles.performanceContainer }>
            <div className={styles.performanceMain}>
                <div className={styles.performanceMainItem}>
                    <div className={styles.performanceMainLabel}>
                        Заказов сегодня |
                    </div>
                    <div className={styles.performanceMainValue}>
                        {ordersToday}
                    </div>
                </div>
                <div className={styles.performanceMainItem}>
                    <div className={styles.performanceMainLabel}>
                        Собраных лотов |
                    </div>
                    <div className={styles.performanceMainValue}>
                        {lotsCount}
                    </div>
                </div>

            </div>
            
            <div className={styles.performance}>
                {performanceData.map((item, index) => (
                    <PerformanceItem 
                        key={index} 
                        label={item.label} 
                        value={item.value} 
                    />
                ))}
            </div>
        </div>
    );
}

function Shop({ shop }) {
    const navigate = useNavigate();
    
    const handleOnClick = () => {
        navigate(`/shop_settings/${shop.id}`, {state: {shop}} );
    };


    
    return (
        < div onClick={handleOnClick}>
            
            <div className={styles.shopInfo} >
                    <div className={styles.photo}>
                        <img src={shop.photo} alt="shopImage" /> 
                    </div>
                    <div className={styles.infoContainer}>
                        <span className={styles.shopName}>
                            {shop.name}
                        </span>
                        <span className={styles.shopSlogan}>
                            {shop.slogan}
                        </span>
                    </div>
                </div>
                <Performance shop = {shop} />
                <div className={styles.line}/>                    
        </div>
    );
};

function Shops({ shops }) {
    const navigate = useNavigate();
    // console.log(' shops' , shops)

    return (
        <>
            <div className={styles.wrapper}>

            {shops.length === 0 ? 
                <div className={styles.text}>У вас пока нет ни одного магазина</div> 
                : 
                <div>
                    {shops.map((shop, index) => (
                        
                        <Shop key={index} shop={shop}  >
                            
                        </Shop>
                               ))}
                </div>
            }

            <button className={styles.addButton} onClick={() => navigate('/profile/create_shop')}>Добавить магазин</button>     
        </div>
            
        </>
    );
}

 
export default Shops;