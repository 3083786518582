import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
// import {CREATE_ORDER} from "../requests/orderRequests";
import {gql} from "@apollo/client";
import {populateItemsWithMaxPrice} from "../utils/orderUtils";

const GET_ORDER_ITEMS = `
  query GetOrderItems($orderID: Int) {
    order_items(where: {order_id: {_eq: $orderID}}) {
      order_id
      quantity
      price
      item {
        lots {
          id
          item_id
          price
        }
      }
      lot {
          price
          step
      }
    }
  }
`

const Payment_Order = `
  mutation PaymentOrder($orderId: Int!){
    update_order_by_pk(_set: {status: "completed", updated_on: "now()"}, pk_columns: {id: $orderId}) {
      id
    }
}
  `


const makeQuery = async ({ query, variables }) => {
  const res = await axios.post(process.env.REACT_APP_HASURA_URl, {
    query,
    variables,
  }, {
    headers: {
      "content-type": "application/json",
      "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
    }
  })
  return res.data
}

export const orderPayment = createAsyncThunk(
  'order/orderPayment',
  async ({ orderId }) => {
    // console.log('orderPayment', orderId)
    return makeQuery({
      query: Payment_Order,
      variables: { orderId }
    })
  }
)


export const fetchOrderItems = createAsyncThunk(
  'order/getOrderItems',
  async ({ orderID }) => {
    return makeQuery({
      query: GET_ORDER_ITEMS,
      variables: { orderID }
    })
  }
)

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderItems: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderItems.fulfilled, (state, action) => {
        const orderItems = action.payload?.data?.order_items || []
        // console.log('orderItems', orderItems)
        const modifiedOrderItems = populateItemsWithMaxPrice(orderItems)

        state.orderItems = modifiedOrderItems
        // console.log('modifiedOrderItems', modifiedOrderItems)
        state.isLoading = false
      })
      .addCase(fetchOrderItems.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderItems.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

const { actions, reducer } = orderSlice

export default reducer
