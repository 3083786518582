import {useQuery} from "@apollo/client";
//import { useQuery } from 'react-apollo-hooks';
import React from "react";
import {GET_ITEMS, GET_SEARCH_ITEMS} from "../../requests/itemRequests";
import {SearchContext} from "../../App";


const LoadItems = (userID, offset, limit) => {

    // console.log('LoadItems', userID);
    console.count('COUNT -------- LoadItems!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');

    // const {userID} = useContext(TelegramContext)
    const {searchValue} = React.useContext(SearchContext);
    let like = searchValue;

    // choose right requst and variables
    let query = GET_ITEMS;
    let variables = {userID, offset, limit};
    if (like) {
        query = GET_SEARCH_ITEMS;
        variables = {like, userID, offset, limit};

    }

    // const useQueryMultiple = () => {
    //     const result  = useQuery(query, {
    //         fetchPolicy: "cache-and-network",
    //         variables: variables
    //     });
    //     return result;
    // };

    const {loading, error, data} = useQuery(query, {
        variables, // analog - "variables: variables"
        fetchPolicy: "cache-and-network"
    });

    if (loading) {
        console.log("loading...");
        return loading;
    }
    if (error) {
        console.error(error);
    }

    let res;
    if (like) {

        if (data) {
            res = {
                item: data.search_item,
                users_likes: data.users_likes,
                count: data.search_item_aggregate.aggregate.count
            };
            console.log("SEARCH DATA", res)
            return res;
        }
    } else {
        if (data) {
            res = {
                item: data.item,
                users_likes: data.users_likes,
                count: data.item_aggregate.aggregate.count
            };
            console.log("RES DATA", res)
            return res;
        }
    }
};

export default LoadItems;



