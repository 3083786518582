import React, {useContext} from 'react';
import {TelegramContext} from '../../../App';
import {useQuery} from '@apollo/client';
import {GET_USER_REVIEWS} from '../../../requests/commentUserRequests';

const UseFetchUserReview = () => {
    const {userID} = useContext(TelegramContext);
    console.log("userID", userID)
    console.log("FETCH")
    const allUserOrderItems = []
    //   Fetch product details
    const {loading, error, data, refetch} = useQuery(GET_USER_REVIEWS, {
        variables: {userID},
        fetchPolicy: 'cache-and-network'
    });

    if (loading) return loading;
    if (error) {
        console.error(error);
        return <div>Error!</div>;
    }
    if (data) {
        // console.log("data", data)
        data.order.forEach(order => {
            order.order_items.forEach((orderItem => {
                console.log('DDDDDDDDDDDDDDDDDDDD')
                console.log(orderItem)

                //find prime photo
                // const primeUrl = orderItem.item.item_media.filter(image => image.prime_queue === 1)

                allUserOrderItems.push(
                    {
                        itemID: orderItem.item.id,
                        itemComments: orderItem.item.comment_items,
                        shop: orderItem.item.shop,
                        modifiersID: orderItem.item_variant.id,
                        orderID: order.id,
                        modifiers: orderItem.item_variant.purposeName + " " + orderItem.item_variant.purposeValue,
                        url:orderItem?.item?.item_media[0].path || null,
                        ...orderItem
                    }
                )

            }))


        })

        //allUserOrderItems.forEach(el => console.log(el))
        return {allUserOrderItems, refetch, loading}
    }


}

export default UseFetchUserReview