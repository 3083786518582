import s from "./ProfileNotificationSetting.module.css"
import SettingHeader from '../../ProfileSetting/SettingHeader/SettingHeader';
import ColoredButton from '../../components/ColoredButton/ColoredButton';
import {displayTypes} from '../../ProfileSetting';


function ProfileNotificationSetting({notifications, 
                                    setNotifications, 
                                    setDisplayType }) {
    // Log the notifications object for debugging purposes
    // console.log(notifications);
  
    // Callback for the save button
    const handleSaveButtonClick = () => {
      // Save the user's choice and switch the display type back to base
      setDisplayType(displayTypes.base);
    };
  
    // Render the list of notifications
    const renderNotifications = notifications.map((notification, index) => {
      const handleCheckboxChange = (event) => {
        setNotifications(
          notifications.map((note) =>
            note.title === notification.title ? { ...note, checked: event.currentTarget.checked } : note
          )
        );
      };
      const circleClassName = notification.checked
        ? `${s.circle} ${s.activeCircle}`
        : `${s.circle}`;
  
      return (
        <div className={s.row} key={index}>
          <label>
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              checked={notification.checked}
              style={{ display: 'none' }}
            />
            <span className={circleClassName}></span>
            <span className={s.title}>{notification.title}</span>
          </label>
        </div>
      );
    });
  
    // Callback for the setting header
    const handleSettingHeaderCallback = () => setDisplayType(displayTypes.base);
  
    return (
      <div className={s.wrapper}>
        <SettingHeader title="Уведомления" callback={handleSettingHeaderCallback} />
  
        <p className={s.text}>
          Укажите удобные способы для получения информации об акциях, скидках и специальных предложениях на
          маркетплейсе ITEM.
        </p>
        <div className={s.container}>{renderNotifications}</div>
  
        <ColoredButton name="Сохранить" callback={handleSaveButtonClick} />
      </div>
    );
  }
  
  export default ProfileNotificationSetting;