import React, {useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import SubCategorySwiper from "./SubCategorySwiper";
import DisplayItems from "../StandartGoodsDisplay/DisplayItems";
import LoadSubCategory from "./LoadSubCategory";
import sortImg from "../../../src/assets/png/sort.png";
import filters from "../../../src/assets/png/filters.png";
import classes from "./category.module.css";
import {makeVar, useQuery} from "@apollo/client";


import { TelegramContext} from "../../App";

// import SortByCost from "../../utils/SortByCost";
// import SortJointPromo from "../../utils/SortJointPromo";
// import SortByRating from "../../utils/SortByRating";
// import SortByDiscount from "../../utils/SortByDiscount";
// import FilterByCost from "../../utils/FilterByCost";
// import link from "../../assets/svg/link.svg";
// import ShopsFilter from "../Filtres/ShopsFilter";
// import FilterByShops from "../../utils/FilterByShops";
// import Slider from "@mui/material/Slider";
// import ProductCategoryFilter from "../Filtres/ProductCategoryFilter";
// import FilterByProductCateg from "../../utils/FilterByProductCateg";
// import ItemCardFormat from './ItemCardFormat';
// import Separator from '../../Сomponents/Separator/Separator';
// import {modalDisplayMode} from '../PrimaryModal/PrimaryModal';


import {CATEGORY_ITEMS, 
        CATEGORY_ITEMS_BY_PRICE_MIN, 
        CATEGORY_ITEMS_BY_PRICE_MAX,
        CATEGORY_ITEMS_BY_ORDERS,
        CATEGORY_ITEMS_BY_MIN_STEP,
         CATEGORY_ITEMS_BY_RATING} from '../../requests/categoryRequests';



import CategoryModalWindow from './CategoryModalWindow';



export const filtersCollection = makeVar({
    shops: [], costMin: null,  costMax: null, brands:[], subcategory: [],
});


function FilterItems(data) {


    const { costMin, costMax, shops, brands, subcategory } = filtersCollection();

    const parsedCostMin = parseInt(costMin, 10); 
    const parsedCostMax = parseInt(costMax, 10);



    const applyShopFilter = shops.length > 0;
    const applyBrandFilter = brands.length > 0;
    const applySubcategoryFilter = subcategory.length > 0;


    // Filter the items based on the criteria
    const filteredItems = data.filter((item) => {

        const lots = item.lots;
        const shopId = item.shop_id;
        const brandName = item.brand;
        const subCategory = item.category_items[0]?.category?.subcategory;


        let isValid = true;

        if (!isNaN(parsedCostMin)) {
            isValid &&= lots.some(lot => lot.price >= parsedCostMin);
        }

        if (!isNaN(parsedCostMax)) {
            isValid &&= lots.some(lot => lot.price <= parsedCostMax);
        }
        

        if (applyShopFilter) isValid &&= shops.includes(shopId);
        if (applyBrandFilter) isValid &&= brands.includes(brandName);
        if (applySubcategoryFilter) isValid &&= subcategory.includes(subCategory);

        return isValid;
    });

    return filteredItems;
}


function getQueryForSortedBy(sortBy) {
    switch (sortBy) {
      case 'sortByCostLow':
        return CATEGORY_ITEMS_BY_PRICE_MIN;
      case 'sortByCostUp':
        return CATEGORY_ITEMS_BY_PRICE_MAX;
      case 'sortByRating':
        return CATEGORY_ITEMS_BY_RATING;
      case 'lotsOfOrders':
        return CATEGORY_ITEMS_BY_ORDERS;
      case 'minStepByLot':
        return CATEGORY_ITEMS_BY_MIN_STEP;
      default:
        return CATEGORY_ITEMS;
    }
  }

export default function CategoryPage() {


    //update that would open the page from above
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let limit = 10

    const location = useLocation();
    const currentCategory = location.state.category;
    const {userID} = useContext(TelegramContext)
    // const [activeQuery, setActiveQuery] = useState(CATEGORY_ITEMS);
    const [sortedBy, setSortedBy] = useState("");
    const [filterBy, setFilterBy] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [subCategory, setSubCategory] = useState(location.state.subCategory);
    const [modalActive, setModalActive] = useState(false);
    const [modalWindow, setModalWindow] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [isFetchMore, setIsFetchMore] = useState(false);
    const setIsFetchMoreCallback = useCallback(setIsFetchMore, [])


    const itemCardFormats = [{id: 1, value: "DOUBLE"}, {id: 2, value: "SINGLE"}, {id: 3, value: "LIST"}]
    const [itemFormat, setItemFormat] = useState(itemCardFormats[0].value)
    const [dataSub, setDataSub] = useState({ item: [], users_likes: [] });
    


    const listSubcategory = LoadSubCategory({currentCategory});

    const {loading, error, data, fetchMore} = useQuery(getQueryForSortedBy(sortedBy), {
        variables: {current_category: currentCategory, userID, offset: 0, limit}, fetchPolicy: "cache-and-network"
    });
    



    useEffect(() => {
        // get more items if there are more items to fetch
        if (isFetchMore && data.item.length < data.category_item_aggregate.aggregate.count) {
            fetchMore({
                variables: {userID, offset: data.item.length, limit}, updateQuery: (prevResult, {fetchMoreResult}) => {
                    if (!fetchMoreResult) return prevResult;
                    return {
                        ...prevResult, item: [...prevResult.item, ...fetchMoreResult.item],
                    };
                },
            })
            setIsFetchMore(false)
        }

    }, [isFetchMore, data])


    useEffect(() => {
        // set hasMore to true if there are more items to fetch
        data && data.item && data.item.length < data.category_item_aggregate.aggregate.count ? setHasMore(true) : setHasMore(false)
    }, [data])



    useEffect(() => {
        // if subCategory is "any" then display all items
        if (data && data.item && data.item.length > 0) {
            const newItems = subCategory === "any" ? data.item : data.item.filter(item => item.category_items[0].category.subcategory === subCategory);
            setDataSub(prev => ({ ...prev, item: newItems, users_likes: data.users_likes }));
        }
    }, [data, subCategory]);


  

    dataSub.users_likes = data && data.users_likes;






        useEffect(() => {


          if (sortedBy && data?.item) {
            let sortedItems;
            if (sortedBy === 'sortByRating') {
              sortedItems = data.item.slice().sort((a, b) => {
                const ratingA = a.comment_items_aggregate?.aggregate?.avg?.estimate || 0;
                const ratingB = b.comment_items_aggregate?.aggregate?.avg?.estimate || 0;
                return ratingB - ratingA;
              });
            } else if (sortedBy === 'sortByCostUp') {
              sortedItems = data.item.slice().sort((a, b) => {
                const maxPriceA = Math.max(...a.lots.map(lot => lot.price));
                const maxPriceB = Math.max(...b.lots.map(lot => lot.price));
                return maxPriceB - maxPriceA;
              });
            } else if (sortedBy === 'sortByCostLow') {
              sortedItems = data.item.slice().sort((a, b) => {
                const minPriceA = Math.min(...a.lots.map(lot => lot.price));
                const minPriceB = Math.min(...b.lots.map(lot => lot.price));
                return minPriceA - minPriceB;
              });
            }else{
                sortedItems = data.item;
            }
        




            if(showFilters){
                setFilteredItems(FilterItems(sortedItems));
            }
            setDataSub({ item: sortedItems, users_likes: data.users_likes });
          }
        }, [data, sortedBy]);
    
        useEffect(() => {


            if (filterBy && dataSub.item.length) {
              const filteredItems = FilterItems(dataSub.item);
              setFilteredItems(filteredItems);

              setShowFilters(
                filtersCollection().shops.length > 0 ||
                filtersCollection().costMin !== null || filtersCollection().costMin !== undefined ||
                filtersCollection().costMax !== null || filtersCollection().costMax !== undefined ||
                filtersCollection().brands.length > 0 ||
                filtersCollection().subcategory.length > 0
              );
              setFilterBy(false);
            }
          }, [filterBy, dataSub]);
    
          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error: {error.message}</div>;
    



    
    // amount of items to display
    const amountOfItems = data.category_item_aggregate.aggregate.count

    

    return (<div className = {classes.container}>
        <h2>{currentCategory}</h2>
        <div className = {classes.Sort}>
            <img 
                src = {sortImg}
                alt = "sort"
                onClick = {() => {
                    setModalActive(!modalActive);
                    setModalWindow('sort');
                }}
            ></img>
            <h5 className = {classes.count}
                    >
                Товаров в разделе:{" "}
                <span className = {classes.count}>{amountOfItems}</span>
            </h5>
            <img
                src = {filters}
                alt = "filters"
                onClick = {() => {
                    setModalActive(!modalActive);
                    setModalWindow('filter');
                }}
            ></img>
        </div>
        <SubCategorySwiper data = {listSubcategory} setSubCategory = {setSubCategory}/>
        <br></br>

        {
         !dataSub.item.length || (showFilters && filteredItems.length===0) ? 
            <div>По вашему запросу ничего не найдено </div>
            : 
            <DisplayItems data = {
                showFilters ? {item: filteredItems, users_likes: data.users_likes} : dataSub
                } 
                setIsFetchMoreCallback = {setIsFetchMoreCallback}
                      hasMore = {hasMore} cartFormat = {itemFormat}/>

        }

       


        {modalActive && 
            <CategoryModalWindow modalWindow={modalWindow}
                                setModalWindow={setModalWindow}
                                listSubcategory={listSubcategory}
                                setSortedBy={setSortedBy}
                                sortedBy={sortedBy}
                                setModalActive={setModalActive}
                                itemCardFormats={itemCardFormats}
                                setItemFormat={setItemFormat}
                                itemFormat={itemFormat}
                                setFilterBy={setFilterBy}

                                 />
        }   
    </div>
    

);
}
