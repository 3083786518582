import { ResetTvOutlined } from "@mui/icons-material";



export async function sendOnModeration(variables){

   
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/bot/item_moderation`, {

          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: `создание товара \n ${JSON.stringify(variables, null, 2)}`,
        }),


        });
    
        if (!response.ok) {
          // If the server response is not ok 
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json(); 
        console.log("Response data:", data);
      } catch (error) {
        console.error("Error sending item moderation message:", error);
      }
      

}

export async function sendBotMessage(message){
  try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: message, }),
        });

        if (!response.ok) {
          // If the server response is not ok (e.g., 404, 500, etc.)
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // Assuming the server responds with JSON
        console.log("Response data:", data);
      } catch (error) {
        console.error("Error sending item moderation message:", error);
      }
}