import {gql} from "@apollo/client";


export const ALL_USER_REFUNDS = gql`
    query AllUserRefunds($userID: bigint,) {
            order(where: {user_id: {_eq: $userID},
                             _and: {order_items: {_or: [
                                {status: {_eq: "declined"}},
                                {status: {_eq: "cancell"}}  
                            ]}}},
                            order_by: { updated_on: desc }) {
                id
                created_on
                updated_on
                order_items(where: {
                    _or: [
                        {status: {_eq: "declined"}},
                        {status: {_eq: "cancell"}}  
                    ]
                }) {
                    quantity
                    price
                    status
                    refund_item {
                        status
                        reason
                        comment
                      }
                    item {
                        id
                        name
                        item_media {
                            path
                        }
                        shop {
                            id
                            name
                        }
                    }
                }
            }
        }
`;

export const STATUS_REFUND_ITEM = gql`
    mutation refundItem($orderItemID: Int!,) {
        update_order_items(where: {id: {_eq: $orderItemID}}, _set: {status: "declined"}) {
            returning {
                id    
            }
        }
        
    }
`;

export const REFUND_ITEM = gql`
    mutation refundItem($orderItemID: Int!, $reason: String!, $comment: String!) {
        insert_refund_items_one(object: {order_items_id: $orderItemID, reason: $reason, comment: $comment}) {
            id
        }
        
    }
`;