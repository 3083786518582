import React from 'react';
import style from './AboutCompany.module.css';



const AboutCompany = () => {
    return (
        <div className={style.container}>
            <h2>О Компании</h2>
            <h2 className={style.aboutTitle}>О нас</h2>
            <div className={style.text}>
            <p className={style.string}>Платформа совместных покупок – ПСП предлагает комплексные решения для розничных и оптовых покупателей в секторе B2B.</p>
            <p>Покупатели сталкиваются с проблемой высокой стоимости товаров и доставки. </p>
             <p>Наша платформа позволит покупателям осуществлять коллективные покупки: </p>
            <p>Покупатели смогут объединять заказы одноплановой продукции и делать заказ оптом, что снизит цену товара </p>
            <p>Платформа позволит покупателям одного города покупать разноплановую продукцию 
                у одного поставщика и меньше платить за доставку(сумма доставки делится между покупателями).</p>
                 <p>Наша платформа  идеально подходит для предпринимателей продвигающих
                 товары на маркетплейсах таких как OZON, Wildberries и тд. </p>
                  <p>Обширные возможности для розничных продавцов региональных рынков.</p>
                  <p>Выгодные решения для закупок крупных предприятий. </p>
                  <p>ПСП – Вместе мы двигаемся в будущее!</p>
          </div>
            <h2 className={style.aboutTitle}>Реквизиты</h2>

            <div className={style.text}>
              <p>  Полное наименование: Общество с ограниченной ответственностью "МИДОРИ" </p>
              <p>Сокращенное наименование: ООО " МИДОРИ "</p>
              <p>Юридический адрес: 634026, Томская область, г Томск, Ленская ул, д. 31, помещ. ц037</p>
              <p>Почтовый адрес: 634026, Томская область, г Томск, Ленская ул, д. 31, помещ. ц037</p>
              <p>ИНН/КПП: 7017484000/701701001</p>
              <p>ОГРН: 1217000003770</p>
              <p>Расчетный счет: 40702810006000001673 </p>
              <p>Корреспондентский счет: 30101810100000000850 </p>
              <p>БИК банка: 045004850 </p>
              <p>Банк: ПАО Новосибирский социальный коммерческий банк «Левобережный» </p>
              <p>E-mail: Qingdao.agechen@gmail.com</p>
            </div>

            <ul className={style.linksContainer}>
        <li>
          <a className={style.link} href="/personal_data.docx" target="_blank" rel="noopener noreferrer">
          Политика ООО «Мидори» в отношении обработки персональных данных
          </a>
        </li>
        <li>
          <a className={style.link} href="/dogovor_fl.docx" target="_blank" rel="noopener noreferrer">
          Пользовательское соглашение (для потребителей)
          </a>
        </li>
        <li>
          <a className={style.link} href="/dogovor_ul.docx" target="_blank" rel="noopener noreferrer">
          ДОГОВОР КУПЛИ-ПРОДАЖИ (для юридических лиц)
          </a>
        </li>
        <li>
          <a className={style.link} href="/rules_recomed_technology.docx" target="_blank" rel="noopener noreferrer">
          Правила применения рекомендательных технологий на сайте и в приложениях ПСП
          </a>
        </li>
      </ul>
        </div>
    );
};

export default AboutCompany;