import React, {useContext} from 'react';
import styles from './UserAddresses.module.css'
import ItemCheckbox from '../CartPage/ItemCheckbox';
import {ModalContext} from '../../App';
import {useMutation} from '@apollo/client';
import {DELETE_ADDRESS} from '../../requests/userRequests';
import {modalDisplayMode} from '../PrimaryModal/PrimaryModal';
import ColoredButton from '../Account/ProfileMain/components/ColoredButton/ColoredButton';

const Address = ({shipping_address, isChecked, changeCheckedAddress, deleteAddressCallback, editAddressCallback}) => {
    const {
        setModalActive,
        setModalContent,
        setModalMode
    } = useContext(ModalContext)

    const [deleteAddress] = useMutation(DELETE_ADDRESS, {variables: {id: shipping_address.id}})

    const addressTitle = shipping_address.title ? shipping_address.title : "Адрес"
    const region = !!shipping_address.delivery_city.region ? `${shipping_address.delivery_city.region}, ` : ''

    const asyncDeleteAddress = async () => {
        const {data, error} = await deleteAddress()
        if (data) {
            deleteAddressCallback(shipping_address.id)
            setModalContent('');
            setModalActive(false);
        }
    }

    const handleDeleteAddress = () => {
        setModalMode(modalDisplayMode.withOverlay)
        const modalContent = (
            <div>
                <h4 className = {styles.deleteTitle}>Вы точно хотите удалить адрес?</h4>
                <p className = {styles.deleteText}>{`${shipping_address.title} | ${shipping_address.index}, 
                ${shipping_address.delivery_city.country}, ${region}${shipping_address.delivery_city.city}, ${shipping_address.street},
        ${shipping_address.home}, ${shipping_address.flat}`}</p>
                <ColoredButton name = {"Да"} callback = {asyncDeleteAddress}/>
            </div>
        )
        setModalContent(modalContent);
        setModalActive(true);
    }

    const handleEditAddress = () => editAddressCallback(shipping_address)

    return (
        <div className = {styles.addressWrapper}>
            <div className = {styles.content}>
                <span className = {styles.addressTitle}>{addressTitle}</span>
                <span
                    className = {styles.addressContent}>{`${shipping_address.index}, ${shipping_address.delivery_city.country}, 
        ${region}${shipping_address.delivery_city.city}, ${shipping_address.street},
        ${shipping_address.home}, ${shipping_address.flat}`}
            </span>
                <div className = {styles.addressControlTitles}>
                    <span className = {styles.addressEdit} onClick = {handleEditAddress}>Редактировать</span>
                    <span className = {styles.addressDelete} onClick = {handleDeleteAddress}>Удалить</span>
                </div>
            </div>

            <div className = {styles.checkBoxContainer}><ItemCheckbox isChecked = {isChecked}
                                                                      onClick = {() => changeCheckedAddress(shipping_address.id)}/>
            </div>
        </div>
    );

};

export default Address