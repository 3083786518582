import {NavLink} from "react-router-dom";
import React, {useContext} from 'react';
import {ModalContext} from '../../../App';
//import home from "../../../assets/svg/home.svg"
import {ReactComponent as Icon} from "../../../assets/svg/home.svg";
import classes from '../Footer.module.css';

const Home = () => {

    // console.log('Home')
    // const {setModalActive, setModalContent} = useContext(ModalContext);

    const linkClassname = ({isActive}) => isActive ? classes.activeImg : classes.img
    return (

        <div onClick = {() => {
            // setModalActive(false)
            // setModalContent('')
        }}>
            <NavLink to = {"/"} className = {linkClassname}>
                <Icon/>
                <div>Главная</div>
            </NavLink>

        </div>)
}

export default React.memo(Home)