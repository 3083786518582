

import React from 'react';
import {useLocation} from 'react-router-dom';
import classes from './ShopLots.module.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useQuery } from '@apollo/client';
import { GET_SHOP_LOTS } from '../../../requests/lotsRequests';
import Lots from './Lots';



function ShopLots() {
    const location = useLocation();
    const shopId = location.state.shopId;



    





    return (
        <>
        {/* <h3 className = {classes.header}>ЛОТЫ</h3> */}

        <Tabs className = {classes.container}>
            <TabList>

                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}>Текущие  </div>
                </Tab>
                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Архив </div>
                </Tab>
            </TabList>
            <TabPanel>
                <Lots  shopId={shopId}/>          
            </TabPanel>
            <TabPanel>
                <Lots  shopId={shopId} archived = {true}/>
            </TabPanel>
        </Tabs>
        </>
    );
}

export default ShopLots;