import {Outlet, useNavigate} from 'react-router-dom';
import classes from './Header.module.css';
import logo from '../../assets/svg/logo.svg';
import Search from './Search';
import Favoutites from './Favourites';
import React, {useContext} from 'react';
import User from './User';
import {TelegramContext} from '../../App';

const HeaderMain = () => {
    const navigate = useNavigate();
    const {userFirstName} = useContext(TelegramContext);
    // console.log('userFirstName', userFirstName);
    const userOrLogin = userFirstName ? 
    `привет ${userFirstName}` : 
    <div onClick={() => navigate("/v1/login")}>Войти</div>;


    return <header className = {classes.header}>
        <div className = {classes.wrapper}>
            <div className = {classes.row}>
                <img
                    className = {classes.logo}
                    alt = ""
                    onClick = {() => {
                        navigate('/');
                    }}
                    src = {logo}
                />
                <div className = {classes.align}>
                    {userOrLogin}
                    <Favoutites/>
                </div>
            </div>
            <Search/>
            <Outlet/>
        </div>
    </header>
};

export default HeaderMain


