import React, {useContext, useEffect} from 'react';
import classes from './PrimaryModal.module.css';
import {ModalContext} from '../../App';

export const modalDisplayMode = {
    empty: "empty",
    withOverlay: "withOverlay",
    withoutOverlay: "withoutOverlay",

}

export default function PrimaryModal() {


    // console.log('PrimaryModal DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')

    const {
        modalActive,
        setModalActive,
        modalMode,
        setModalMode,
        modalContent,
        setModalContent,
        modalPage,
        etModalPage
    } =
        useContext(ModalContext);


    

    useEffect(() => {
        //disable scrolling
        const body = document.querySelector('body');
        modalActive ? body.style.overflowY = "hidden" : body.style.overflowY = "scroll"


        //scroll to top
        const element = document.getElementById('primaryModal');
        if (element && modalMode === modalDisplayMode.withoutOverlay && modalActive) {
            element.scrollTo(0, 0)
        }
        // !modalActive && setModalMode(modalDisplayMode.empty)
    }, [modalActive]);

    const clickEvent = (event) => {
        setModalActive(false);
        setModalContent(null);
        console.log("onClick PrimaryModal clickEvent = ", event.currentTarget)
    };

    return (<>
           


            {modalActive && modalMode === modalDisplayMode.withoutOverlay &&
                <div id = {'primaryModal'}
                     className = {modalActive ? `${classes.modal} ${classes.active}` : `${classes.modal}`}>
                    {/*onClick={modalPage === "main" ? clickEvent : null}*/}

                    <div
                        className = {classes.closeLineContainer}
                        onClick = {clickEvent}>
                        {/*// onClick = {modalPage === 'main' ? clickEvent : null}>*/}
                        <div className = {classes.closeLine}></div>
                        <span className = {classes.checkmark}></span>
                    </div>
                    <div className = {classes.modalContent}
                         onClick = {(event) => {
                             event.stopPropagation()
                         }}>{modalContent}</div>
                </div>}


            {modalActive && modalMode === modalDisplayMode.withOverlay &&
                <div className = {modalActive ? `${classes.overlay}` : ""} onClick = {clickEvent}>
                    <div
                        className = {modalActive ? `${classes.modalWithOverlay} ${classes.active}` : `${classes.modalWithOverlay}`}>
                        {/*onClick={modalPage === "main" ? clickEvent : null}*/}

                        <div
                            className = {classes.closeContainer}
                            onClick = {clickEvent}>
                            {/*// onClick = {modalPage === 'main' ? clickEvent : null}>*/}
                            <div className = {classes.closeText}>{"Закрыть"}</div>
                            <span className = {classes.crossLine}></span>
                        </div>
                        <div className = {classes.modalContent} onClick = {(event) => {
                            event.stopPropagation()
                        }}>{modalContent}</div>
                    </div>
                </div>}

            {/*{modalMode === modalDisplayMode.empty &&*/}
            {/*    <div className = {modalActive ? `${styles.modal} ${styles.active}` : `${styles.modal}`}></div>}*/}
        </>
    );
}
