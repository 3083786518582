import React, {useContext, useEffect, useState} from 'react';
import classes from './Cities.module.css';
import cities from './city.json' ;
import axios from 'axios';
import SortCities from '../../utils/SortCities';
import {ModalContext, TelegramContext, CityContext} from '../../App';
import {useNavigate} from 'react-router-dom';
import {UPDATE_CITY} from '../../requests/requests';
import {useMutation} from '@apollo/client';
import clearSvg from '../../assets/svg/delete.svg';
import searchSvg from '../../assets/svg/search.svg';
import {displaySellerRegistrationsTypes} from '../Account/ProfileMain/SellerRegistration';

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';


export default function Cities({
                                   inShopRegistration,
                                   setDeliveryState,
                                   setDisplayType,
                                   inAddresses,
                                   setCityData,
                                   setMode
                               }) {

    console.log('cities')

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const {userID} = useContext(TelegramContext);



    const [isFromList, setIsFromList] = useState(true);

    const displayCitiesObj = SortCities(cities);

    const [activeCityTitle, setActiveCityTitle] = useState('');
    const [activeCity, setActiveCity] = useState('initial state');
    const {deliveriesCityID, setDeliveryCityID} = useContext(CityContext);
    const [cityContent, setCityContent] = useState('');


    const [searchValue, setSearchValue] = useState('');
    const apiUrl = `https://api.jbspace.xyz/check_${searchValue}`;

    const [jsonCityState, setJsonCityState] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [onErrorMessage, setOnErrorMessage] = useState(false);


    //console.log(activeCity)
    // console.log('deliveryCityID', deliveriesCityID)


    const [updateCity] = useMutation(UPDATE_CITY, {
        
        variables: {city_id: deliveriesCityID, userID: userID},
    });


    useEffect(() => {
        if (searchValue !== '') {
            setIsLoading(true);
            setIsFromList(false);
            axios
                .get(apiUrl)
                .then((resp) => {
                    setOnErrorMessage(false);
                    setIsLoading(false);
                    setJsonCityState(resp.data);
                }, (error) => {
                    setIsLoading(false);
                    setOnErrorMessage(true);
                });
        }
    }, [cityContent]);

    const createCityContent = (city) => (
        
        <div
        key = {city.Delivery_City.id}
        className = {classes.cityContent}
        onClick = {() => {
            setActiveCity(city.Delivery_City.id);
            setDeliveryCityID(city.Delivery_City.id);
            setActiveCityTitle(city.Delivery_City.city);
            if (setCityData){
                // check becouse city component can be used in different places
                // and when it is used from profile it doesn't have setCityData

            setCityData({
                id: city.Delivery_City.id,
                city: city.Delivery_City.city,
                country: city.Delivery_City.country,
                region: city.Delivery_City.region,
                subRegion: city.Delivery_City.sub_region
            })
            }
        }}
    >
        <div>
            <div className = {classes.mainCity}>{city.Delivery_City.city}</div>
            <div className = {classes.secondaryCity}>
                {city.Delivery_City.city}, {city.Delivery_City.region}, {city.Delivery_City.sub_region},
                {city.Delivery_City.country}
            </div>
        </div>
        <div
            className = {activeCity === city.Delivery_City.id ? `${classes.circle} ${classes.active}` : `${classes.circle}`}>
        </div>
    </div>);

    const displayCitiesFromAPI = !isFromList && jsonCityState.length ? jsonCityState.map((city) => createCityContent(city)) : 'Ищем город...';

    const filterCities = displayCitiesObj.filter((city) => city.Delivery_City.city.toLowerCase().includes(searchValue.toLocaleLowerCase()));

    const displayCitiesFromList = filterCities.map((city) => createCityContent(city));

    
    console.log('delivery city id ', deliveriesCityID)

    return (<div className = {classes.wrapper}>
        <div className = {classes.headerContainer}>
            <h2 className = {classes.title}>Населенный пункт</h2>
            <div className = {classes.searchContainer}>
                <div className = {classes.inputContainer}>
                    <input
                        placeholder = "Введите город"
                        className = {classes.searchCity}
                        value = {searchValue}
                        onKeyDown = {(event) => {
                            if (event.key === 'Enter') {
                                // scrollToTop();
                                setCityContent(searchValue);
                                setJsonCityState('');
                                setIsFromList(false);
                            }
                        }}
                        onChange = {(event) => {
                            if (isLoading) setIsLoading(false);
                            if (onErrorMessage) setOnErrorMessage(false);
                            setSearchValue(event.currentTarget.value);
                            setIsFromList(true);
                        }}
                    />
                    {searchValue !== '' && (<img
                        className = {classes.clearSvg}
                        src = {clearSvg}
                        alt = ""
                        onClick = {() => {
                            // scrollToTop();
                            setSearchValue('');
                            setIsFromList(true);
                            setIsLoading(false);
                            setOnErrorMessage(false);
                        }}
                    />)}
                </div>
                <div className = {classes.findContainer}>
                     <button 
                        className = {classes.find}
                        onClick = {() => {
                            // scrollToTop();
                            setCityContent(searchValue);
                            setJsonCityState('');
                            setIsFromList(false);
                        }}
                    >
                        Найти
                    </button>
                    
                    <img className = {classes.searchSvg} src = {searchSvg} alt = ""/>
                </div>
                

            </div>
        </div>
        <div>
            <div className = {classes.content}>
                {isFromList && displayCitiesFromList}
                {!isFromList && displayCitiesFromAPI}
                {isLoading && <div>Идет загрузка......</div>}
                <div className = {onErrorMessage ? `${classes.show}` : `${classes.hidden}`}>
                    Загрузка не удалась, введите другой город, или попробуйте еще раз позже
                </div>
            </div>
        </div>
        <div className = {classes.applyContainer}>
            <button
                className = {classes.applyButton} disabled = {activeCity === 'initial state'}
                onClick = {() => {

                    console.log('deliverycityid', deliveriesCityID)
                    updateCity();
                    if (inAddresses) {
                        setMode()
                    } else if (!inShopRegistration) {
                        navigate(-1);
                    } else {
                        setDeliveryState(activeCityTitle);
                        setDisplayType(displaySellerRegistrationsTypes.shopData);
                    }
                }}
            >
                <span>Применить</span>
            </button>
        </div>
    </div>);
}
