export default function isDateMoreThanTwoWeeksAgo(date) {
    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0); //remove hours, minutes ...

    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    twoWeeksAgo.setHours(0, 0, 0, 0); //remove hours, minutes ...

    return inputDate > twoWeeksAgo ? true : false

}
