import Video from '../../ProductCard/Video/Video';
import {modalDisplayMode} from '../../PrimaryModal/PrimaryModal';
import React, {useContext} from 'react';
import {ModalContext} from '../../../App';

export default function usePrimaryModal() {

    // console.log('usePrimaryModal HHHHHHHHHHHHHHHHHHHHHHHHHHHhhhhh')

    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);


    const videoUrl = ''
    const posterImageUrl = ''

    const localModalContent = (!!videoUrl
        ? <Video
            url = {videoUrl}
            onClick = {(event) => {
                event.stopPropagation();
            }}
            posterImage = {posterImageUrl}
        />
        : <div style = {{height: "40vh"}}>Видео будет загружено позже</div>)

    const onClIckHandler = () => {
        setModalContent(
            localModalContent
        );
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
    }
    return {onClIckHandler}
}

