import {Outlet, useNavigate} from 'react-router-dom';
import styles from './Header.module.css';
import goBack from '../../assets/svg/goBack.svg';
import Search from './Search';
import React from 'react';
import {ReactComponent as ChatSvg} from '../../assets/svg/chat.svg'

const HeaderProfile = () => {
    const navigate = useNavigate();
    return <header className = {styles.headerMinimize}>
        <div className = {styles.wrapperMinimize}>
            <div className = {styles.row}>
                <img src = {goBack} alt = "" onClick = {() => navigate(-1)} style = {{cursor: "pointer"}}/>
                <Search/>
                <div className = {styles.chatsContainer}>
                    <ChatSvg/>
                    <div className = {styles.chatsCountContainer}>
                        <span className = {styles.chatsCountContainer}>6</span>
                    </div>
                </div>
                {/*<Favoutites/>*/}
            </div>
            <Outlet/>
        </div>
    </header>
};

export default HeaderProfile