import {gql} from "@apollo/client";


export const ADD_NEW_SHOP = gql`
mutation addNewShop (
                          $userID : bigint, 
                          $companyId : Int,
                          $info: String!, 
                          $name: String!,
                          $slogan: String!,
                          $corespondent_account : String,
                          $bank_title : String!,
                          $bic : Int,
                          $type_of_business : String!,
                          $bank_account : String,
                          $photo : String,
                          ) 
                           {
    insert_shop(objects: 
            {shop_account: 
              {data: 
                { 
                  bic: $bic,
                  bank_title: $bank_title,
                  corespondent_account :  $corespondent_account,
                  bank_account : $bank_account,
                  }},
            owner_id : $userID,
            photo: $photo,
            name: $name,
            info: $info,
            slogan: $slogan,
            type_of_business: $type_of_business,
            company_id: $companyId,

            
          }) {
      returning {
        id
      }
    }
  }
            `;


      


export const GET_SHOP_ITEMS = gql`
    query getShopItems($shopId: Int) {
      item(where: {shop_id: {_eq: $shopId}}) {
        id
        name
        about
        status
        articleNumber
        item_media(where: {type: {_eq: "image"}}) {
          path
        }
        item_variants {
          purposeName
          purposeName2
        }
      }
    }
`;
        


export const GET_SHOP = gql`
    query getShop($shopId: Int, $userID: bigint) {
        shop(where: { id: { _eq: $shopId } }) {
            id
            info
            name
            approval
            photo
            company {
                inn
                ogrn
                address
              }
              shops_likes(where: {user_id: {_eq: $userID}}) {
                shop_id
                
              }
            carts_aggregate {
                aggregate {
                  sum {
                    quantity
                  }
                }
              }  
            comment_items_aggregate {
                aggregate {
                  avg {
                    estimate
                  }
                  count(columns: user_id)
                }
              }
              items{
                id
                name
                about
                brand
                lots(
                  where : {status: {_eq: "active"}},
                  order_by: {price: asc}
                ) {
                    price
                }
                shop {
                    approval
                  }
                comment_items {
                    estimate
                }
                category_items {
                    category {
                        subcategory
                    }
                }
                item_media {
                    path
                }
                users_likes(where: {user_id: {_eq: $userID}}) {
                    user_id
                  }
              }
              


            }
        }

`;

export const SHOP_ITEMS = gql`
    query ShopItems($userID: bigint,$shopId: Int!) {
        item(where: { id: { _eq: $shopId } }) {
            id
            name
            about
            brand
            item_modifiers {
                id
                in_stock
                characteristic
                modifiers
                height
                length
                weight
                width
            }
            comment_items_aggregate {
                aggregate {
                  count(columns: user_id)
                  avg {
                    estimate
                  }
                }
              }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
              }
            category_items {
                category {
                    category
                    subcategory
                }
            }
            item_media {
                path
            }
            lots {
                expire_date
                id
                price
                start_date
                status
                min_step
                lots_modificators {
                    modifiers_id
                    quantity
                    status
                }
                }
            promotion_id
            videos {
                url
                id
            }
            shop {
                id
                info
                name
                approval
                photo
                docs {
                  INN
                  OGRN
                  company_address
                }
                shops_likes(where: {user_id: {_eq: $userID}}) {
                    shop_id
                    
                  }
                carts_aggregate {
                    aggregate {
                      sum {
                        quantity
                      }
                    }
                  }  
                comment_items_aggregate {
                    aggregate {
                      avg {
                        estimate
                      }
                      count(columns: user_id)
                    }
                  }
              }
              
                
            
        }
        
    }
`;



export const COMPANY_ID_BY_USER = gql`
    query CompanyIdByUser($userID: bigint) {
        company(where: {user_id: {_eq: $userID}}) {
            id
        }
    }
`;