import React, {useEffect, 
                useState,
                useContext,
            } from 'react';
import styles from './ChooseProduct.module.css';
import question from '../../../assets/svg/questionGrey.svg';

import usePrimaryModal from './useModal';
import { GET_PRODUCT_TO_ADD } from '../../../requests/productRequests';

import { useQuery } from '@apollo/client';
import { TelegramContext } from '../../../App';
import NumberFormat from '../../../utils/NumberFormat';

import DateToEnd from '../../../utils/DateToEnd';


import MainLot from '../MainLot/MainLot';

import bonus from '../../../assets/png/bonus.png';


import { useMutation } from '@apollo/client';
import { ADD_TO_BASKET } from '../../../requests/cartRequests';

import { useNavigate } from 'react-router-dom';
import { amountDynamic } from '../../Footer/Backet/Backet';


export function   Modifiers({ variants, 
                    setCurrentVariant, setSelectedItem }) {

    // console.log('variants', variants)

    const [allValue, setAllValue] = useState(new Set());
    const [allValue2, setAllValue2] = useState(new Set());

    const [secondValue, setSecondValue]= useState(false)
    

    useEffect(() => {
        setAllValue(new Set(variants.map(variant => variant.purposeValue)));
    
        if (variants[0]?.purposeName2) {
            setSecondValue(true)
            setAllValue2(new Set(variants.map(variant => variant.purposeValue2)));
        }

    }, [variants]);



    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedSecondValue, setselectedSecondValue] = useState(null);

    const [availableValue2, setAvailableValue2] = useState(null);
    const [availableValue, setAvailableValue] = useState(null);

   

    // Update selected value based on user interaction.
    const handleValueClick = (value) => {
        let  availabelVariants =  null; 
        
        if (secondValue) {  
            // when we have second value

            if (selectedSecondValue){
                // when we have selectedSecondValue

                if(selectedValue === value){
                    // when we click on selected value
                    
                    setSelectedValue(null)
                    setSelectedItem(false)
                    // setAvailableValue2(null)

                }else{
                    // when we click on not selected value
                    setSelectedValue(value);
                    availabelVariants = variants.filter(variant =>
                        variant.purposeValue === value &&
                        variant.purposeValue2 === selectedSecondValue
                    )
                    if (availabelVariants.length > 0){
                        
                        setCurrentVariant(availabelVariants[0].id)
                        availabelVariants = variants.filter(variant =>
                            variant.purposeValue === value
                        )
                        setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
                        setSelectedItem(true)
                    }else{

                    availabelVariants = variants.filter(variant =>
                        variant.purposeValue === value
                    )
                    
                    setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
                    setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue2)))
                    
                    setselectedSecondValue(null)
                    setSelectedItem(false)

                }
            }

            }else{
                // when we haven't selectedSecondValue
                if (selectedValue === value) {
                    setSelectedValue(null)
                    setAvailableValue(null)
                    setAvailableValue2(null)
                }else{

                    
                setSelectedValue(value);
                availabelVariants = variants.filter(variant =>
                    variant.purposeValue === value
                )
                
                setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
                setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
                }
            }
        }else{
            // when we have only one value
            if (selectedValue === value) {
                setSelectedValue(null)
                setAvailableValue(null)
                setSelectedItem(false)
            }else{
                setSelectedValue(value);
                availabelVariants = variants.filter(variant =>
                    variant.purposeValue === value )
                 setCurrentVariant(availabelVariants[0].id)
                 setSelectedItem(true)
        }
    }

    }


    const handleSecondValueClick = (value) => {
        let availabelVariants =  null; 

        if (selectedValue){
            // when we have first value selected
            if (selectedSecondValue === value){
                setselectedSecondValue(null)
                setSelectedItem(false)
                
            }else{

                setselectedSecondValue(value);
                availabelVariants = variants.filter(
                    variant =>
                    variant.purposeValue === selectedValue &&
                    variant.purposeValue2 === value  
                )
                
                if (availabelVariants.length >0 ){
                    setCurrentVariant(availabelVariants[0].id)
                    availabelVariants = variants.filter(variant => 
                        variant.purposeValue2 === value
                    )
                    setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
                    setSelectedItem(true)                    
                }else{
                    console.log('here eroor')
                    setSelectedValue(null)
                    availabelVariants = variants.filter(variant => 
                        variant.purposeValue2 === value
                    )
                    setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
                    setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
                    setSelectedItem(false)

                }
        }

        }else{
            // console.log('without selectedValue')

            if(selectedSecondValue === value){
                setselectedSecondValue(null)
                setAvailableValue2(null)
                setAvailableValue(null)
            }else{


            setselectedSecondValue(value);
            availabelVariants = variants.filter(variant => 
                variant.purposeValue2 === value
            )
            setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
            setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
            
            // setSelectedItem(true)
        } }


    }


   
    const renderDiv = (value, index, isSelected, isAvailable, click) => (
        <div
             className={
                isSelected 
                    ? styles.modifiersSelected  
                : isAvailable 
                    ? styles.modifiers  
                : styles.modifiersUnavailable  
        }
            key={index}
            onClick={() =>  click(value)}
        >
            {value}
        </div>
    );
    


    return (
        <div className={styles.content}>
            <div className={styles.row}>
                <span className={styles.rowTitle}>{variants[0].purposeName} : </span>
            </div>
            <div className={styles.chooseRow}>
                {[...allValue].map((value, index) => {
                    const isSelected = selectedValue === value;
                    const isAvailable = availableValue ? availableValue.has(value) : true;
                  
                    const click = handleValueClick

                
                    return renderDiv(value, index, isSelected, isAvailable, click);
                })}
            </div>
            {secondValue && (
                <>
                    <div className={styles.row}>
                        <span className={styles.rowTitle}>{variants[0].purposeName2} : </span>
                        {/* <span className={styles.rowContent}>{selectedSize2 || ''}</span> */}
                    </div>
                    <div className={styles.chooseRow}>
                {[...allValue2].map((value, index) => {
                    const isSelected = selectedSecondValue === value;
                    const isAvailable = availableValue2 ? availableValue2.has(value) : true;
                    
                    
                    const click = handleSecondValueClick
                
                    return renderDiv(value, index, isSelected, isAvailable, click );
                })}
            </div>
                </>
            )}
        </div>
    );

                        }



function CountPrice({ activeLot, maxPrice, amount }) {
    return (
        <div className = {styles.priceContainer}>
                <div className = {styles.cost}>
                    <span style = {{minWidth: "10%"}}>{`${NumberFormat(activeLot?.price)}`}</span>
                    <span style = {{marginLeft: "5px"}}>{`₽ за 1 шт.`}</span>
                </div>
                <div className = {styles.minStep}>{`от ${activeLot?.step } шт.`}</div>
                <div className = {styles.totalPrice}>
                    <span> Итого:</span>
                    <span
                        style = {{minWidth: "10%", textAlign: "center", marginLeft: "3px"}}>  {`  ${NumberFormat(activeLot?.price * amount )}     `}</span>
                    <span style = {{marginLeft: "3px"}}>  {` ₽`}</span>
                    { ((amount > 0) && (maxPrice > activeLot?.price) ) && (
                        <span className = {styles.maxPrice}>  {`  ${NumberFormat(maxPrice * amount )} ₽`}</span>
                    )}

                </div>
                <div className={styles.bonusContainer}>
                    <img src = {bonus} alt = 'bonuns_image'/>
                    <span className = {styles.bonusText}>  200</span>
                    { ((amount > 0) && (maxPrice > activeLot?.price) ) && (

                    <span className = {styles.benefitText}> 
                                     Выгоднее на
                                      {`  ${NumberFormat(maxPrice * amount  - activeLot?.price * amount) } ₽`}
                                      </span>
                    )}

                </div>
            </div>

    )
}


function ChangeAmount( {lot, amount, setAmount}) {


    useEffect(
            () => {
                if (lot) {
                    setAmount(lot.step);
                }
            }, [lot]);


    const decreaseOnClickHandler = () => {
        if (amount > lot?.step){
            setAmount((a) => a - 1);
        }else{
            setAmount(lot?.step)
        }
    }

    const increaseOnClickHandler = () => {
        
            setAmount((a) => a + 1);
    }


    

    return (
    
    <div className = {styles.changeWraper}>
        <div className = {styles.changeContainer}>
            <span className = {styles.decrease} onClick = {decreaseOnClickHandler}>-</span>
            <span className = {styles.amount}> {amount} </span>
            <span className = {styles.increase} onClick = {increaseOnClickHandler}>+</span>

        </div>

        <div className = {styles.dateContainer}>
            <div className = {styles.timeContent}>
                {DateToEnd(lot?.expire_date)}
            </div>
        </div>

        <div className = {styles.jointInfo}>
            {`Прогресс: ${lot.ordered_quantity} шт.`}
            <span className = {styles.highlighting}>{`+(${amount})`}</span>
            {`/${lot?.total}`}
        </div>

    </div>

)
}




function DislayChoose({ item, setCurrentVariant, currentVariant ,
                        setActiveLot, activeLot,
                        amount, setAmount,
                        setSelectedItem,
                        setNotForSale
                    }) {
    
    const [maxPrice, setMaxPrice] = useState(0)

    let modifiers = null;

    useEffect(() => {
        if (item.item_variants && item.item_variants.length > 0) {
         
            let minPrice = Infinity;
            let variantWithMinPrice = null;
            let minLot = null;
            let maxPrice = 0;

            item.item_variants.forEach(variant => {

                variant.lots.forEach(lot => {
                    if (lot.price < minPrice) {
                        minPrice = lot.price;
                        minLot = lot;
                        variantWithMinPrice = variant.id;
                    }
                    if (lot.price > maxPrice) {
                        maxPrice = lot.price;
                    }

                })
            })

            if(!item.item_variants[0].purposeName){
                // console.log('FFFFFFFFFFFFFFFF', item.item_variants[0].purposeName)
                setSelectedItem(true)
            }

            setCurrentVariant(variantWithMinPrice);
            setActiveLot(minLot);
            setMaxPrice(maxPrice);


        }
    }, []);

    let variantToRender = item.item_variants.find(variant => variant.id === currentVariant)

    
    

    // console.log('variantToRender', variantToRender)
    // console.log('currentVariant', currentVariant)
    useEffect ( () => {
        
        const  variantToRender = item.item_variants.find(variant => variant.id === currentVariant);
         if(variantToRender){
             setActiveLot(variantToRender.lots[0])
        }
    }, [currentVariant])



    // when no variants available
    if (!variantToRender) {
        return(
            <div className = {styles.buttonContainer}>
                        <button
                            className = {styles.BasketToCardButton}
                            disabled = {true }
                            >
                            <span> СКОРО В ПРОДАЖЕ</span>
                        </button>
                   </div>
        )
    }



    if ( item.item_variants[0]?.purposeName) {

        modifiers = <Modifiers variants={item.item_variants} 
                        
                        setCurrentVariant={setCurrentVariant}
                        setSelectedItem={setSelectedItem}
                        />
    } 
    

 
    return (
        <>
        {modifiers}

        
            {
                    variantToRender  ? (
                <div className={styles.lotContent}>
                  {variantToRender.lots.map((lot, index) => (
                    <MainLot
                      key={index}
                      lot={lot}
                      activeLot={activeLot}
                      setActiveLot ={setActiveLot}

                    />
                  ))}
                </div>
              ) : (
                <p className={styles.text}> нет в продаже</p>
              )
            }
            <div style={{height: "2px", backgroundColor: "#4F8CE7", margin: "25px 0 14px"}}></div>
        

            <div className={styles.totalContainer}>

            <CountPrice activeLot={activeLot} 
                        maxPrice={maxPrice}
                        amount={amount}

                         />
            
            <ChangeAmount lot = {activeLot}
                        amount={amount}
                        setAmount={setAmount}
            />
            </div>

            
        </>
    )

}



function Notification({ message }) {
    return (
      <div className={styles.notification}>
        {message}
      </div>
    );
  }






export default function ChooseProduct({
                                                isModalActive,
                                                setModalActive,
                                                setModalContent,

                                                currentItem,    
                                            }) {
                                            

    // console.log('choose product currentItem', currentItem)

    const {userID }= useContext(TelegramContext)

    const navigate = useNavigate();

    const {onClIckHandler} = usePrimaryModal()
 
    const [currentVariant, setCurrentVariant] = useState(null)
    const [activeLot, setActiveLot] = useState(null)
    const [amount, setAmount] = useState(0);

    const [selectedItem, setSelectedItem] = useState(false)
    const [disable, setDisable] = useState(null)
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');


    const [addToBasket ] = useMutation(ADD_TO_BASKET);
    const [notForSale, setNotForSale] = useState(false)


    let modalContent = null;

    const {loadind, error, data} = useQuery(GET_PRODUCT_TO_ADD, {
        variables: {itemId: currentItem,}
    })
        if(loadind) console.log('loading', loadind)
        if(error) console.log('error', error)
        if(data) {
            if (data?.item.length === 0) {
                modalContent = false;
            } else {

            modalContent = <DislayChoose item={data.item[0]}
                                setCurrentVariant={setCurrentVariant}
                                currentVariant={currentVariant}
                                setActiveLot={setActiveLot}
                                activeLot={activeLot}
                                amount={amount}
                                setAmount={setAmount}
                                setSelectedItem={setSelectedItem}
                                setNotForSale={setNotForSale}
             />;
            }
        }

    

    const clickEvent = (e) => {
         setModalActive(false);
        setModalContent(null);
     };


     useEffect(() => {
        if (selectedItem && amount > 0 && !showNotification)  {
            setDisable(true);
        }
         else {
            setDisable(false);
        }
    }, [selectedItem, amount, showNotification]);
    
    


    

    
    //   const displayNotification = (message) => {
    //     setNotificationMessage(message);
    //     setShowNotification(true);
    
    //     setTimeout(() => {
    //       setShowNotification(false);mutationResult
    //     }, 5000); // Hide after 5 seconds
    //   };


    const buttonHandler = () => {

        if(!userID ) {

            setModalActive(false);
            setModalContent(null);
            navigate('/v1/login');
            return;
            
        }

        const shopId = data.item[0].shop.id;

        try{
            const {data : mutationResult} =  addToBasket({  
                variables: {
                    itemId: currentItem,
                    shopId: shopId,
                    userId: userID,
                    lotsId: activeLot.id,
                    variantId: currentVariant,
                    quantity: amount,
                    price: activeLot.price,
                    extendedCost: activeLot.price * amount,
                }
            })
            // console.log('data', mutationResult)   
            // setDisable(true);

            setShowNotification(true);
            setNotificationMessage("Товар был добавлен в вашу корзины");
            amountDynamic(amountDynamic() + 1);
            setTimeout(() => {
                setShowNotification(false);
                setModalActive(false);
                setModalContent(null);
              }, 1500);
            
            // displayNotification('Item has been added to your cart!');
           
        } catch (e) {
            console.log(e)
            alert('Ошибка при добавлении в корзину')
        }

        



    };

    return (<>


            <div id = {'primaryModal'}
                 className = {styles.modal}>
                <div className = {styles.closeLineContainer}
                     onClick = {clickEvent}>
                    <div className = {styles.closeLine}></div>
                    <span className = {styles.checkmark}></span>
                </div>
                <div className = {styles.question} onClick = {onClIckHandler}>
                    <span>{"Как это работает"}</span>
                    {/* when it will be work change svg file for question instead questionGrey */}
                    <img src = {question} alt = 'question'/>
                </div>
                <div >
                   {modalContent}  
                </div>

                
                {!modalContent ? (
                    <div className = {styles.buttonContainer}>
                        <button
                            className = {styles.BasketToCardButton}
                            disabled = {true }
                            >   
                            <span>  СКОРО В ПРОДАЖЕ</span>
                        </button>
                </div>
                ) : (

                <div className = {styles.buttonContainer}>
                        <button
                            className = {styles.BasketToCardButton}
                            onClick={() => {

                                buttonHandler()}}
                            disabled = {!disable  }
                            >
                            <span>  В КОРЗИНУ</span>
                        </button>
                        {showNotification && <Notification message={notificationMessage} />}
                </div>
                )}
                

            </div>
            
        </>

    );
}
