import React from 'react';
import styles from "./MultiContainer.module.css"
import SingleLinkContainer from '../SingleLinkContainer/SingleLinkContainer';
import {Link, useNavigate} from 'react-router-dom';


function MultiContainer({
                            mainTitle,
                            titles,
                            callbacks = [],
                            cityName,
                            currencyTitle,
                            params = []
                            
                        }) {

                
    const navigate = useNavigate();
                            

    // console.log('params', params)


   

    const singleLink = params.map((el, index) =>
            
        <SingleLinkContainer key = {index} 
                            title = {el.title} 
                            callback = {el.callback} 
                            active = {el.active}
                            >


            {el.title === "Город" && el.secondTitle}
            {el.title === "Валюта" && el.secondTitle}
               
            {el === "О проекте" && <span onClick = {() => navigate("/about")}></span>}
            {el === "Помощь" && <Link to = "/help"> Помощь </Link>}
        </SingleLinkContainer>)

    return (

        <div className = {styles.wrapper}>
            <h3 className = {styles.mainTitle}>{mainTitle}</h3>
            <div className = {styles.linksContainer}>
                {singleLink}
            </div>

        </div>
    );
}

export default MultiContainer;