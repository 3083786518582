import React from 'react';
import classes from './Goods.module.css';

import {useNavigate} from 'react-router-dom';
import Like from '../Likes/Likes';
import AddElement from '../AddElement/AddElement';
import SwiperWithRealPhotos from './SwiperWithRealPhotos';
import StarRating from 'react-svg-star-rating';
import approveSvg from '../../assets/svg/approve.svg';
import {ModalContext} from '../../App';
import {useContext} from 'react';

const DisplayItemsHorisontal = ({data}) => {
    console.log('DisplayItemsHorisontal');
    const navigate = useNavigate();
    const {modalActive, setModalActive, modalContent, setModalContent} = useContext(ModalContext);

    //cheking item does it have like from the user
    function isLiked(id) {
        if (
            data.users_likes.findIndex((like) => {
                return id === like.item_id;
            }) > -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    const display_goods =
        data &&
        data.item &&
        data.item.map((item, index) => {
            const rating = (
                item.comment_items.reduce((a, b) => {
                    return a + b.estimate;
                }, 0) / item.comment_items.length
            ).toFixed(1);
            const approveLabel = item.shop.approve ? approveSvg : '';

            const oldPrice = !!item.old_price ? item.old_price : '';
            /////////////////////////////////////////////////////////////////////
            let subCategoryContent = item?.category_items[0]?.category?.subcategory ?? '';
            /////////////////////////////////////////////////////////////////////

            const Price = () => {
                if (!!item.old_price && oldPrice - item.price > 0) {
                    return (
                        <div className = {classes.PriceContainer}>
                            <span className = {classes.jointPrice}>{item.price} ₽</span>
                            <span className = {classes.ordinaryPrice}>{oldPrice} ₽</span>
                        </div>
                    );
                } else {
                    return <div className = {classes.Price}>{item.price} ₽</div>;
                }
            };

            // let subDisplay;
            //
            // try {
            //     subDisplay = item.category_items[0].category.subcategory;
            // } catch (error) {
            //     console.error(error);
            //     subDisplay = '';
            // }

            return (
                <div key = {index} className = {classes.item}>
                    <div
                        onClick = {() => {
                            navigate('/product', {state: {productId: item.id}});
                        }}>
                        <div className = {classes.slide}>
                            <SwiperWithRealPhotos photos = {item.images_items}/>
                            {/* {item.joint_promo ? ( */}
                            {/* <div className = {styles.jointAny}>{item.joint_promo.toString()}</div> */}
                            {/* ) : ( */}
                            {/* '' */}
                            {/* )} */}
                            {/* {item.joint_promo ? ( */}
                            {/* <div className = {styles.jointPromo}> */}
                            {/* <span className = {styles.promoTitle}>Вместе выгоднее</span> */}
                            {/* <span className = {styles.promoPrice}>{item.joint_shopping.price} ₽</span> */}
                            {/* </div> */}
                            {/* ) : ( */}
                            {/* '' */}
                            {/* )} */}
                        </div>
                        <div className = {classes.ProductContent}>
                            {/* <Price/> */}

                            <div className = {classes.about}>{item.name}</div>
                            <div>{subCategoryContent}</div>

                            <div>
                                <span>{item.shop.name}</span>
                                <img src = {approveLabel} alt = ""/>
                            </div>
                            <div>
                                <StarRating
                                    isReadOnly
                                    initialRating = {rating}
                                    unit = "float"
                                    containerClassName = {classes.container}
                                    count = {5}
                                    starClassName = {classes.star}
                                    activeColor = "#FFD700"

                                />
                                {item.comment_items.length > 0 ? (
                                    <span className = {classes.starCount}>{item.comment_items.length}</span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <AddElement item = {item} inProductCard = {false}/> */}
                    <Like ItemID = {item.id} doWeHaveLike = {isLiked(item.id)}/>
                </div>
            );
        });

    return (
        <>
            <div className = {classes.ContainerHorisontal}>{display_goods}</div>
        </>
    );
};

export default DisplayItemsHorisontal;
