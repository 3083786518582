export default function getReadableDate(strDate) {
    let updatedDate = new Date(strDate);
    let formatter = new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    let dataArray = formatter.formatToParts(updatedDate);
    return `${dataArray[0].value} ${dataArray[2].value} ${dataArray[4].value}`;
}
