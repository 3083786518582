
import React, {useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client';
import { GET_ITEM_LOTS } from '../../../requests/lotsRequests';
import styles from './SettingsLots.module.css';
import info from '../../../assets/svg/info.svg';
import  minusIcon from '../../../assets/svg/minus.svg';
import  plusIcon from '../../../assets/svg/plus.svg';

import { getVariantWord } from '../../../utils/format/getVariantWord';


import deleteCart from '../../../assets/svg/deleteCart.svg';
import { DELETE_ITEM_LOT } from '../../../requests/lotsRequests';
import { updateLotApi } from '../../../utils/apiCalls/lotsApi';
import { set } from 'react-hook-form';



function random() {
    return Math.floor(Math.random() * 999) + 1;
}

function LotLabel() {
    return (
        <div className = {styles.labelContainer}>
            <div className={styles.rightContainer}>

             <label className = {styles.labelLot}>Цена за шт. </label>
            </div>
            <div className={styles.labelIcon}>
                <label className = {styles.labelLot}>Квант </label>
                <img className={styles.infoIcon} 
                    src = {info} alt = "info" />
            </div>
            <div className={styles.labelIcon}>
                <label className = {styles.labelLot}>Всего </label>
                <img src = {info} alt = "info" />
            </div>
            <div className={styles.labelIcon}>
                <label className = {styles.labelLot}>Бронь </label>
                <img src = {info} alt = "info" />
            </div>
        </div>
    )
}



function LotRow({ lot, mutableVariants, setMutableVariants,  variantId,
                    }) {


    // console.log('lot', lot)

    const lotId = lot.id;
    
    const [priceValue, setPriceValue] = useState(lot.price || '');
    const [stepValue, setStepValue] = useState(lot.step || 0);
    const [totalValue, setTotalValue] = useState(lot.total || 0);

    const [deleteLot] = useMutation(DELETE_ITEM_LOT, );

    const booking = lot.ordered_quantity || 0;
    const isDisabled = booking > 0 || lot === 0;


    const handleDeleteClick = (lotId, variantId) => {
        // console.log(lotId);

        if (!isDisabled) {
            deleteLot(
                {
                    variables: {
                        lotId: lotId,
                    },
                    refetchQueries: [
                        {
                            query: GET_ITEM_LOTS,
                            variables: {
                                variantId: variantId,
                            },
                        },
                    ],
                }
            );
            setMutableVariants(prevVariants =>
                prevVariants.map(variant =>
                    variant.id === variantId ? {
                        ...variant,
                        lots: variant.lots.filter(lot =>
                            lot.id !== lotId && lot
                        )
                    } : variant
                )
            );


        }
    };

    const handlePriceChange = (event, lotId, variantId) => {
        const newPrice = event.target.value;
        setPriceValue(newPrice);
        setMutableVariants(prevVariants =>
                prevVariants.map(variant =>
                    variant.id === variantId ? {
                        ...variant,
                        lots: variant.lots.map(lot =>
                            lot.id === lotId ? { ...lot, price: newPrice } : lot
                        )
                    } : variant
                )
            );
           
    };



    const handleStepChange = (value) => {

        if (value < 0) {
            value = 0;
        } 

        const newStep = value;  

        setStepValue(newStep);

        setMutableVariants(prevVariants => 
                prevVariants.map(variant =>
                    variant.id === variantId ? {
                        ...variant,
                        lots: variant.lots.map(lot =>
                            lot.id === lotId ? { ...lot, step: newStep } : lot
            )} : variant
            )
            );
    }

    const handleTotalChange = (value) => {
        const newTotal = value;
        setTotalValue(newTotal);

        setMutableVariants(prevVariants =>
                prevVariants.map(variant =>
                    variant.id === variantId ? {
                        ...variant,
                        lots: variant.lots.map(lot =>
                            lot.id === lotId ? { ...lot, total: newTotal } : lot
                        )
                    } : variant
            )
            );
    }

    
    return (
          
        <div className={styles.lotFields}   >
                <div className={styles.labelIcon}>
                    <img 
                        style={{ 
                                    height: '15px', 
                                    width: '13.3px', 
                                    opacity: booking > 0 ? '0.5' :'1.5'  
                                }} 
                        
                        src={deleteCart} 
                        alt="deleteItem" 
                        onClick={() => {
                            if (!isDisabled) {
                                handleDeleteClick(lotId, variantId);
                            } 
                        }}
                    />
                    <input
                        className={styles.inputPrice}
                        value={priceValue}
                        placeholder='См. варианты'
                        onChange={(event) => handlePriceChange(event, lotId, variantId)}
                        disabled={isDisabled}
                    />
                </div>
                            
            <div className={styles.lotsButton}>
                        <div className={isDisabled ? styles.countButtonDisabled : styles.countButton}>
                                <img src = {minusIcon} alt = "minus"  
                                            disabled = {isDisabled}
                                            onClick={() => {
                                            if (!isDisabled) {
                                                handleStepChange(parseInt(stepValue) - 1);
                                            } 
                                        }}
                                            />
                            <input
                                type="number"
                                placeholder='0'
                                value = {stepValue}
                                onChange={e => handleStepChange(e.target.value)}
                                className={styles.buttonText}
                                disabled={isDisabled}
                            />

                            <img src = {plusIcon} alt = "plus" 
                                    onClick={() => {
                                            if (!isDisabled) {
                                                handleStepChange(parseInt(stepValue) + 1);
                                            } 
                                        }}
                                    />
                        </div>
                            
                            <div className={styles.countButton}>
                                <img src = {minusIcon} alt = "minus" 
                                           onClick={() => {
                                            if (!isDisabled) {
                                                handleTotalChange(parseInt(totalValue) - 1);
                                            } 
                                        }}

                                            />
                                <input
                                    type="number"
                                    placeholder='0'
                                    value = {totalValue}
                                    onChange={e => handleTotalChange(e.target.value)}
                                    className={ styles.buttonText}
                                    disabled={isDisabled}
                                    
                                    // style={{ width: '16px' }}

                                />
                                <img src = {plusIcon} alt = "plus"
                                    onClick={() => {
                                            if (!isDisabled) {
                                                handleTotalChange(parseInt(totalValue) + 1);
                                            } 
                                        }}

                                        />
                            </div>
                            <div className={isDisabled ? styles.bookingItemsDisabled  : styles.bookingItems}>
                                {booking}
                            </div>
                            </div>
            </div>
                                );
}
    



function ShowVariants({mutableVariants, setMutableVariants}) {

    return(
        <div >
        {
            mutableVariants.map((variant, index) => {
                const variantId = variant.id;
                
                function handleButtonClick() {
                    const newLot = { id: random(), price: '', total: 0, step: 0 };
                    setMutableVariants(prevVariants => prevVariants.map((variant, index) => {
                    if (variant.id === variantId) {
                            return {
                                ...variant,
                                lots: [...variant.lots, newLot]
                            };
                        }
            
                    return variant;
                        }));
                            }

          return (
          <div className={styles.wraperVariat} key={index}>
                <div className={styles.row}>
                  <h2 >Вариант {index + 1}</h2>
                  {index === 0 && <span>Исходный</span>}
                </div>
          
              <div className={styles.infoVariant}>
                    <div className={styles.itemImage} key={variant.image}>
                        <img src={variant.image} alt="foto" className={styles.avatar} />
                    </div>
            
                    <div className={styles.variantsInfo}>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Артикул</label>
                            <span className={styles.labelInfo}>{variant.articleNumber}</span>
                        </div>
                        <div style= {{height: '1vh'}}/  >

            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Длина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.length || '-'}</span>
                        </div>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Ширина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.width || '-'}</span>
                        </div>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Высота упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.height || '-'}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Вес упаковки (мм)</label>
                            <span className={styles.textInfo}>{variant.weight || '-'}</span>
                        </div>
                    </div>
              </div>
            
                <div className={styles.characterRow}>
                      {variant.purposeName ? (

                        <div className={styles.character}>
                          <label className={styles.labelInfo}>{variant.purposeName}</label>
                          <span className={styles.textInfo}>{variant.purposeValue}</span>
                        </div>

                      ) : ( 
                        <div className={styles.character}>
                            <label className={styles.labelInfo}>-</label>
                        </div> )}

                      {variant.purposeName2 && (
                        <div className={styles.character}>
                      
                          <label className={styles.labelInfo}>{variant.purposeName2}</label>
                          <span className={styles.textInfo}>{variant.purposeValue2}</span>
                        </div>
                      ) }
                </div>

                   
                     <div>
                        <LotLabel />
                                          
                        
                    <div className={styles.formLots}>
                        

                    {variant.lots.length > 0 ? (
                            <ShowVariantLots
                                lots = {variant.lots}
                                mutableVariants = {mutableVariants}
                                setMutableVariants={setMutableVariants}
                                variant = {variant}
                            />
                        ): (
                            <button className={styles.addLot}
                          type="button"
                          disabled={variant.lots.length >= 3}
                          onClick={() => handleButtonClick(mutableVariants,)}
                          
                          >
                          
                          Добавить лот
                        </button>
                        )}
                        
                   
                            
                    </div>
                            
                    </div>
                            
                
                            

          </div>
      )
          })       
        
        }
    </div>

    
  )
}





function ShowVariantLots({lots, mutableVariants, setMutableVariants, variant} ){

    const variantId = variant.id;  

    function handleButtonClick() {
        if (typeof setMutableVariants !== 'function') {
            console.error('setMutableVariants is not a function');
            return;
        }
        const newLot = { id: random(), price: '', total: 0, step: 0 };

        // Update the lots array of the specific item_variants object
        setMutableVariants(prevVariants => prevVariants.map((variant, index) => {
            if (variant.id === variantId) {
                // Found the target item_variants, update its lots array
                return {
                    ...variant,
                    lots: [...variant.lots, newLot]
                };
            }
            
            return variant;
        }));
    }

    return (
        <>
        {/* <LotLabel /> */}
        <div className={styles.lotContainer} >

       { variant.lots.map((lot, index) => (
                        <div key={lot.id}>
                        <LotRow
                            lot = {lot}
                            setMutableVariants = {setMutableVariants}
                            variantId={variantId}
                        />
                        </div>
                    ))}
                            
       <button className={styles.addLot}
                          type="button"
                          disabled={variant.lots.length >= 3}
                          onClick={() => handleButtonClick(mutableVariants)}
                          
                          >
                          
                          Добавить лот
                        </button>
       </div>
       </>
                            
    )
}





function ItemWithoutVariants({ mutableVariants, setMutableVariants, setSaveButton }) {
   

    const variantId = mutableVariants[0]?.id || null
    function handleButtonClick() {
        
   
        const newLot = { id: random(), price: '', total: 0, step: 0 };

        // Update the lots array of the specific item_variants object
        setMutableVariants(prevVariants => prevVariants.map((variant, index) => {
            if (variant.id === variantId) {
                // Found the target item_variants, update its lots array
                return {
                    ...variant,
                    lots: [...variant.lots, newLot]
                };
            }
            
            return variant;
        }));
        
    }


    return (
        <>
            <LotLabel />
             <div className={styles.lotContainer}> 
             
                {mutableVariants[0]?.lots.map((lot, index) => (
                    <LotRow
                        lot={lot}
                        key={index}
                        mutableVariants={mutableVariants}
                        variantId = {variantId}
                        setMutableVariants={setMutableVariants}
                        
                    />
                ))} 
                <button 
                    className={styles.addLot}
                    type="button"
                    disabled={mutableVariants[0]?.lots.length >= 3}
                    onClick={handleButtonClick}
                >
                    Добавить лот
                </button>
            </div>
            
        </>
    );
}



function validateVariants(mutableVariants, variants,validate, setValidate) {

    let newLots = {};
    let changedLots = {};

    function isNumeric(value) {
        return /^\d+$/.test(value);
    }

    try {

    mutableVariants.forEach(variant => {
        const variantId = variant.id;
        // Initialize arrays for new and changed lots under this variant ID
        newLots[variantId] = [];
        changedLots[variantId] = [];

        const variantLots = variant.lots;
        // Find the corresponding variant in 'variants' array
        const compareVariant = variants.find(item => item.id === variantId);

        if (compareVariant && compareVariant.lots) {
            const compareLots = compareVariant.lots.reduce((acc, lot) => {
                acc[lot.id] = lot;
                return acc;
            }, {});

            variantLots.forEach(lot => {
                // Validate the lot before checking if it's changed or new
                if(isNumeric(lot.total) && isNumeric(lot.price) && isNumeric(lot.step) &&
                        parseInt(lot.total) > 0 && parseInt(lot.price) > 0 && parseInt(lot.step) > 0 &&
                        parseInt(lot.total) >= parseInt(lot.step))  {
                    if (lot.id in compareLots && (lot.total !== compareLots[lot.id].total || 
                        lot.price !== compareLots[lot.id].price || 
                        lot.step !== compareLots[lot.id].step)) {
                        // Lot exists and has different total, price, or step
                        changedLots[variantId].push(lot);
                    } else if (!(lot.id in compareLots)) {
                        // Lot does not exist in compareLots, it's new
                        newLots[variantId].push(lot);
                    }
                } else {
                   
                    // Lot is invalid, do not add it to newLots or changedLots arrays
                    throw new Error('Invalid lot data');
                    

                }
            });
        } else {
            // No corresponding variant found, consider all lots as new after validation
            variantLots.forEach(lot => {
                if(isNumeric(lot.total) && isNumeric(lot.price) && isNumeric(lot.step) &&
                    parseInt(lot.total) > 0 && parseInt(lot.price) > 0 && parseInt(lot.step) > 0 &&
                    parseInt(lot.total) >= parseInt(lot.step))  {
                    newLots[variantId].push(lot);
                } else {
                    throw new Error('Invalid lot data');
                }
            });
        }
    });

    }catch (error) {
        
        {/* console.log(changedLots, newLots); */}
        alert('Проверьте данные в лоте');
        return { changedLots: null, newLots: null };
    }

   
    return { changedLots, newLots };
    

    

}




function SettingsLots( ) {
    const location = useLocation();
    const lotsId = location.state.lotsId;

    const [showVariants, setShowVariants] = useState(null);
    const [mutableVariants, setMutableVariants] = useState([])
    const [saveButton, setSaveButton] = useState(false);
    const [validate, setValidate] = useState(true);
    const [oldLots, setOldLots] = useState({});
    const [processButton, setProcessButton] = useState(false);

    const navigate = useNavigate()

    const deepEqual = (obj1, obj2) => {
            return JSON.stringify(obj1) === JSON.stringify(obj2);
            };

    const {data, error, loading} = useQuery(GET_ITEM_LOTS, {
        variables: { lotsId },
        fetchPolicy: 'cache-and-network',
        });

    {/* console.log('data', data) */}


    useEffect(() => {
        if (data && data.item && data.item.length > 0) {
            const item = data.item[0];
            setMutableVariants(item.item_variants);
            setOldLots(item.item_variants);
        }
    }, [data]); 

    useEffect(() => {
        setSaveButton(!deepEqual(mutableVariants, oldLots));
    }, [mutableVariants]);
        
    if (loading) {
            return 'loading...';
        }
    if (error) {
            console.error('liked items error', error);
            return 'error';
        }
    

    

    
    
    const item = data.item[0];
    //const lots = item.item_variants[0].lots

    let purpose = ''

    if(!item.item_variants[0].purposeName){
                  purpose  = ''
              }else if(!!item.item_variants[0].purposeName2){
                  purpose = '1 cвойство; '
              }else {
                  purpose = '2 свойства; '
              }

    const variants = item.item_variants.length;


    {/* console.log('mutableVariants', mutableVariants); */}
    {/* console.log('saveButton', saveButton); */}

    const handleSave = async (mutableVariants) => {
            setProcessButton(true);     

             const { changedLots, newLots } = validateVariants(mutableVariants, item.item_variants,validate, setValidate);

             const shopId = item.shop_id;
             const itemId = item.id;


             // Объединяем объекты changedLots и newLots в один объект для отправки

             if(changedLots && newLots){
                 const data = {changedLots, newLots, shopId, itemId};

                 // Отправляем новые и измененные лоты на сервер
                 {/* console.log('data', data); */}

             try {
                 const result =  await updateLotApi(data)
                    {/* console.log('result', result); */}
                if (result.status === 200) {
                    navigate(-1);
                }

             } catch (error) {
                 console.error('There was a problem with your fetch operation:', error);
             }
         
             }
             setProcessButton(false);
             setSaveButton(false);
             {/* setSaveButton(true); */}
    }
    


    return (
        <div className={styles.wrapper}>
                <h1>{item.articleNumber}</h1>
            <div className={styles.contentContainer}>
          
            <div className={styles.itemContainer}> 
              <div className={styles.itemImage}>
                <img className={styles.image} src={item.item_media[0].path} alt="itemImage" />
              </div>
              <div className={styles.itemInfo}>
                <span className={styles.itemName}>{item.name}</span>
                <span className={styles.itemArticle}>{item.articleNumber}</span>
                <span className={styles.itemPurposeCount}>{purpose}{variants}  {getVariantWord(variants)};</span>
              </div>
            
            </div>

            {item.item_variants.length > 1 ? (
                <>
                    <div className={styles.mainItemContainer}>
                         <LotLabel />
                               
                        <LotRow 
                            vatiantsObject = {mutableVariants}
                            lot = {0}
                        />
                    </div>
                    <span className={styles.link} 
                        onClick={() => setShowVariants(showVariants === item.id ? null : item.id)}
                          >
                      {showVariants === item.id ? 
                            'Закрыть варианты' :
                            'Открыть варианты'}
                    </span>

                    {showVariants === item.id && (
                      <ShowVariants 
                                    mutableVariants={mutableVariants}
                                    setMutableVariants = {setMutableVariants}

                                     />
                    )}
                </>
              ) :(

                <ItemWithoutVariants 
                          mutableVariants={mutableVariants} 
                          setMutableVariants={setMutableVariants} 
                          setSaveButton={setSaveButton}
                        />
              )
              }            
        </div> 

        {
            processButton ?
            <button className={styles.buttonFixed}
                    disabled = {true}
                    >
                <span>Сохраняем...</span>
            </button>  :

            <button className={styles.buttonFixed}
            disabled = {!saveButton}
            onClick={() => handleSave(mutableVariants)}>
          Cохранить
        </button>

        }
       
      </div>
    )
}

export default SettingsLots