import React from 'react';

import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../Slider/slider-theme.css";

import swiperDuble from './SwiperWithRealPhotos.module.css';
import swiperList from './DisplaySingleItem/ListDisplayItemFormat.module.css';
import swiperSingle from './DisplaySingleItem/SingleDisplayItemFormat.module.css';

import img1 from '../../assets/png/goods1.png';
import img2 from '../../assets/png/goods2.png';
import img3 from '../../assets/png/goods3.png';
import img4 from '../../assets/png/goods4.png';


export default function SwiperWithRealPhotos({photos = [], cartFormat}) {
    // console.log('SwiperWithRealPhotos', photos);

    const urls = [img1, img2, img3, img4]


    let classes = {}
    switch (cartFormat) {
        case "SINGLE":
            classes = {...swiperSingle}
            break
        case "LIST":
            classes = {...swiperList}
            break
        default:
            classes = {...swiperDuble}
    }

    const toDisplay =
        photos.length > 0 ? (
                photos.map((photo, index) => {
                    return (
                        <div key = {index} className = {classes.imageContainer}>
                            <img src = {photo.path} alt = "productImage" className = {classes.sliderImage}></img>
                         </div>
                    );
                })
            ) :
            urls.map((photo, index) => {
                console.log('index = ', index)
                return (
                    <div key = {index}>
                        <img src = {photo} alt = "productImage" className = {classes.sliderImage}></img>
                    </div>
                );
            })
    ;

    let settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
    }


    return (
        <div className = {classes.GoodsMainSlider}>
            <Slider {...settings}>
                {toDisplay}
            </Slider>
        </div>
    );
}
