import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Storage.module.css';

// import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SHOP_STORAGE , DELETE_STORAGE_ADDRESS} from '../../../../requests/storageRequests';






  function Storage({shopId,}) {
    const navigate = useNavigate();
    const [deleteStorage] = useMutation(DELETE_STORAGE_ADDRESS);
    const {data, error, loading, refetch} = useQuery(GET_SHOP_STORAGE, {
      fetchPolicy: 'cache-and-network',
      variables: {shopId},
    });
    if (loading) {
      return 'loading...';
    }
    if (error) {
      console.error('get shop storage error', error);
      return 'error';
    }
    
    let {storage } = {storage: data.storage};
    // console.log('storage', storage)
  
  
    const handleChangeButton = async (addressId) => {
      console.log('change storage info button', addressId);
    }
  
    const handleDeleteButton = async (addressId) => {
      try {
        const {data} = await deleteStorage({
          variables: {  
            addressId: addressId,
          },
          refetchQueries: [{ query: GET_SHOP_STORAGE, variables: { shopId } }]
        });
        refetch();
        if (!data) {
          alert("An error occurred while deleting storage.");
        }
      } catch (error) {
        console.error("An error occurrstorage.ided:", error);
      }
    }
  
  
    return (
      <div className={styles.wrapper}>
        {(storage.length === 0) ? (
          <>
          <div className={styles.text}>У вас пока нет ни одного склада</div>
          <button 
            className={styles.addButton} 
            onClick={() => navigate('/create_storage', { state: { shopId } })}>
            Добавить склад
          </button>     
        </>
        ) : (
          <div className={styles.brandList}>
          
          {storage.map((storage, index) => (
            
            <div className={styles.storageContainer} key={index}>
              <div className={styles.labelContainer}>
                <span className={styles.storageLabel}>{storage.storage_address.comment}</span>
                <span className={styles.items}>Всего товаров: 999 </span>
              </div>
              <div className={styles.storageAddress}>
                {storage.storage_address.index},
                {storage.storage_address.delivery_city.region}, 
                {storage.storage_address.delivery_city.city},
                ул. {storage.storage_address.street}, 
                 {storage.storage_address.house} -
                {storage.storage_address.flat}
              </div>
              <div className={styles.changeButtonContainer}>
                <span 
                  className={styles.changeButton} 
                  onClick={async () => await handleChangeButton(storage.storage_address.id)}>
                  Редактировать
                </span>
                <span 
                  className={styles.deleteButton} 
                  onClick={async () => await handleDeleteButton(storage.storage_address.id)}>
                  Удалить
                </span>
              </div>
              <div className={styles.buttonContainer}>
              <button 
                    className={styles.addButton} 
                    disabled={storage.storage_items.length === 0}

                    onClick={() => navigate('/change_storage_items', { state: { storageId: storage.id, 
                                                                      shopId : shopId } })}
                    >
                  Редактировать остатки
              </button>
  
              <div className={styles.addContainer}>
              <button 
                    className={styles.addButton} 
                    onClick={() => navigate('/storage_items', { state: { storageId: storage.id, shopId: shopId } })}
                    // onClick={() => setDisplayType(STORAGE.addItem)}
                    >
                  Оприходовать товары
              </button>
              </div>
              </div>
            </div>
            
          ))}
  
          <button 
            className={styles.addButtonFixed} 
            onClick={() => navigate('/create_storage', { state: { shopId } })}>
            Добавить склад
          </button>
          
          </div>
        )}
        
      </div>
    );
  } 
  




export default Storage;
    

