import React, {useRef, useState,  useEffect} from 'react';
import styles from './AmountChanger.module.css';
import {ProductCardContext} from '../../ProductCard/ProductCard';

const AmountChanger = ({amount, setAmount, lot, setIsAmountChanged}) => {

    const [timer, setTimer] = useState(null);

    const decreaseOnClickHandler = () => {
        if (amount > lot.step) {
            setAmount((a) => a - 1);
        } else {
            setAmount(0);
        }
        (amount - 1 <= lot.step) && setAmount(lot.step)
        setIsAmountChanged(true) 
    }

    const increaseOnClickHandler = () => {
        if (amount === 0) {
            setAmount(lot.step)
        } else {
            setAmount((a) => a + 1);
        }
        (amount + 1 <= lot.step) && setAmount(lot.step)
        setIsAmountChanged(true)

    }

  


    const validateAmount = (currentValue) => {
        if (currentValue < lot.step) {
            setAmount(lot.step);
        }
    };


    const handleInputChange = (event) => {
    const value = event.target.value;
    const isValidNumber = /^-?\d+(\.\d+)?$/.test(value);
       
    if (isValidNumber) {
            if (timer) clearTimeout(timer);
            const newTimer = setTimeout(() => {
                validateAmount(value);
            }, 3000);
            setTimer(newTimer);
    
            setAmount(value !== '' ? parseInt(value) : '');
       
        } else  {
            if (timer) clearTimeout(timer);
            const newTimer = setTimeout(() => {
                validateAmount(value);
            }, 5000);
            setTimer(newTimer);
            setAmount('');
        }
     
    };


    return (

        <div className = {styles.container}>
            <span className = {styles.decrease} onClick = {decreaseOnClickHandler}> - </span>
            {/* <span 
                ref={amountRef}
                className={`${styles.amount} ${isEditing ? styles.editing : ''}`} 
                onClick={handleSpanClick}
            >
                {amount}
            </span> */}
            <input 
                type="number"
                value={amount}
                onChange={handleInputChange}
                min = {lot.step}
                className={styles.hiddenInput}
            />
            <span className = {styles.increase} onClick = {increaseOnClickHandler}> + </span>

        </div>)
}
export default AmountChanger