import React from 'react';
import classes from './NoPhoto.module.css';

const NoPhoto = () => {
    return (
        <div className = {classes.wrapper}>
            <div className = {classes.noPhoto}>NO FOTO</div>
        </div>)
};

export default NoPhoto;