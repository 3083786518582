import React, {useContext, useEffect, useState} from 'react';
import { useQuery} from '@apollo/client';
// import { gql } from '@apollo/client';
import arrowIcon from '../../assets/svg/arrow.svg'
import styles from './Payment.module.css'
import {CHOOSING_ADDRESS} from "../../requests/userRequests";
import {TelegramContext} from "../../App";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate } from "react-router-dom";
import {fetchOrderItems} from "../../features/orderSlice";
import {getTotalEconomy} from "../../utils/orderUtils";
import CartAddress from '../UserAddresses/cartAddress';


import {modalDisplayMode} from '../PrimaryModal/PrimaryModal';
import {ModalContext} from '../../App';

import { createPaymentApi } from "../../utils/apiCalls/ordersApi";


import {sellerRules} from '../../utils/docs/sellerRules';
import {politicRules} from '../../utils/docs/politicRules';





const UserInfoBlock = ({ title, data, showArraw = true }) => {
  return (
    <div className={styles.infoBlock}>
      <div className={styles.info}>
        <span className={styles.infoTitle}>{title}:</span>
        <span className={styles.infoData}>{data}</span>
      </div>
      <img src={arrowIcon} alt="Arrow"/>
    </div>
  )
}

const DiscountItem = ({ discountTitle, discountValue, isFree }) => {
  return (
    <div className={styles.totalDiscount}>
      <span className={styles.totalDiscountTitle}>{discountTitle}:</span>
      {isFree
        ? <span className={styles.free}>{discountValue}</span>
        : <span className={styles.totalDiscountSum}>{`${discountValue}`} &#8381;</span>
      }
    </div>
  )
}

const countBonus = () => {
  
  
  // получаем бонусы магазина, если есть
  // считаем сумму бонусов для всех товаров магазина в заказе
  // считаем сумму бонусов для магазинов в заказе

  // получаем бонусы пользователя, по магазинам в заказе
  // считаем сумму пользователя в каждом магазине
  // считаем сумму бонусов пользователя для магазинов в заказе

  return 0;
}






export default function Payment(){
  const { userID } = useContext(TelegramContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [ loadProcess, setLoadProcess] = useState(false);


  const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);
  const localModalContent = ( <div style = {{height: "40vh"}}>
                            Видео будет загружено позже
                            </div>)

  // const [promo, setPromo] = useState('')
  const [promoValue, setPromoValue] = useState(0)

  const handelPromoButton = (event) => {
    event.preventDefault();
    console.log('promo button', event.target.value)
  }



  const [selectedBlock, setSelectedBlock] = useState('add');
  const handleBonusSelection = (block) => {
    setSelectedBlock(block);
    };





    
    const handlePaymentButtonClick = async() => {
      setLoadProcess(true); // Start loading process
      try {
        const response = await createPaymentApi({
          variables: { orderId },
        });

        navigate('/processPayment', {state: {paymentData: response}});
      } catch (error) {
        console.log('error', error);

        if (error.response && error.response.data) {
            alert(`${error.response.data.detail}`);
        } else {
          // 00070 - Lot with id __  does not have enough quantity available
          // 00071 - Lot with id __  duplicate key value violates unique constraint "payment_user_id_order_id_key"
          // 00072 - UserAddress - not found or found more than one

            alert('Ошибка "00070" при создании платежа, попробуйте еще раз');
        }
        navigate('/cart');
      } finally {
        setLoadProcess(false); 
      }
      

      }
    

    const handlePaymentCompanyButtonClick = async() => {
      navigate('/paymentCompany', {state: {orderId : orderId, cartIds : cartIds}});
    }



  const {loading, error, data} = useQuery(CHOOSING_ADDRESS, {
    fetchPolicy: 'cache-and-network', variables: {userID}
  });


  
  const currentOrderId = useSelector((state) => state.cart.currentOrderId)
  const lastActiveOrderId = localStorage.getItem('lastActiveOrderId')
  const orderId = currentOrderId || lastActiveOrderId
  const cartIds = useSelector((state) => state.cart.selectedItems)

  useEffect(() => {
    dispatch(fetchOrderItems({ orderID: orderId }))
  }, [orderId, dispatch])

  const { orderItems, isLoading, isError } = useSelector((state) => ({
    orderItems: state.order.orderItems,
    isLoading: state.order.loading,
    isError: state.order.error,
  }))



  if (loading || isLoading) {
    return <div>Loading...</div>
  }
  if (error || isError) {
    return <div>{JSON.stringify(error)}</div>
  }
  if (!orderId) {
    navigate('/cart')
  }

  const shippingAddress = data.user_profile[0] || navigate('/profile')
  const {
    surname,
    mail,
    phone,
    name,
  } = shippingAddress
  const userName = `${surname}  ${name}`
  
  
  const [totalBonusAdd, totalBonusReduse] = [0, 0]


  const [totalItemsCount, totalItemsPrice] = orderItems.reduce(([itemsCount, itemsPrice], orderItem) => {
    const { quantity, maxItemPrice } = orderItem
    if (quantity === undefined || maxItemPrice === undefined) {
      return [itemsCount, itemsPrice]
    }
    return [itemsCount + quantity, itemsPrice + (maxItemPrice * quantity)]
  }, [0, 0])

  const totalEconomy = getTotalEconomy(orderItems)


  const selerRule = () => {
    const localModalContent = <div className = {styles.localModalContent}>{sellerRules}</div>
    setModalContent(localModalContent);
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
  }

  const politicsRule = () => {
    const localModalContent = <div className = {styles.localModalContent}>{politicRules}</div>
    setModalContent(localModalContent);
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
  }



  // ${styles.blue}
  return (
    <div className={styles.root}>
      <span className={styles.pageHeader}>Оформление заказа</span>
      <div className={styles.userInfo}>
        <UserInfoBlock title="Получатель" data={userName} />
        <UserInfoBlock title="Номер телефона" data={phone} />
        <UserInfoBlock title="E-mail" data={mail} />
        <UserInfoBlock title="Способ получения" data="Самовывоз" />
        <UserInfoBlock title="Адрес доставки" data={<CartAddress/>} />
      </div>
      <div className={styles.bonusSection}>
        <div className={styles.bonusTitle}>
          <span className={styles.title}>Бонусы</span>
          <span className={styles.bonusHelp} onClick = {() => {
                setModalContent(
                    localModalContent
                );
                setModalMode(modalDisplayMode.withOverlay)
                setModalActive(true);
            }}>Как это работает?</span>
        </div>
      <div className={styles.bonusControls}>
        <div className={`${styles.bonusControlBlock}`}
              onClick={() => handleBonusSelection('add')}>
          <span className={styles.bonusControlName}>Начислить</span>
          <span className={`${styles.bonusControlCount} ${
                selectedBlock === 'add' ? styles.blue : ''}`}>
              397 бонусов
              </span>
        </div>
        <div className={`${styles.bonusControlBlock}`}
            onClick={() => handleBonusSelection('deduct')}>
          <span className={styles.bonusControlName}>Списать</span>
          <span className={`${styles.bonusControlCount} ${
              selectedBlock === 'deduct' ? styles.blue : ''}`}>
                    1457 бонусов
          </span>
        </div>
      </div>
      </div>
      <div className={styles.promoSection}>
        <span className={styles.promoTitle}>Промокод или сертификат:</span>
        <div className={styles.promoControls}>
          <span className={styles.enterNumber}>Введите номер:</span>
          <input type="text" placeholder="Код" className={styles.enterPromo} />
          <div className={styles.confirmPromo} onClick={handelPromoButton }>Использовать</div>
        </div>
      </div>
      <div className={styles.totalSection}>
        <span className={styles.totalSectionHeader}>Итог:</span>
        <div className={styles.totalCosts}>
          <div className={styles.totalItems}>
            <div className={styles.totalItemsCount}>
              <span className={styles.totalItemsDiff}>Наименований: {orderItems?.length}шт</span>
              <span className={styles.totalItemsAll}>Количество товаров: {totalItemsCount}шт</span>
            </div>
            <span className={styles.totalItemsPrice}>{totalItemsPrice} &#8381;</span>
          </div>
          {!!totalEconomy && <DiscountItem discountTitle="Скидка за объем" discountValue={totalEconomy} />}
          {selectedBlock === 'deduct' ? <DiscountItem discountTitle="Оплата бонусами" discountValue={1457} /> : null}
          {!!promoValue && <DiscountItem discountTitle="Промокод или сертификат" discountValue={promoValue} />}
          <DiscountItem discountTitle="Стоимость доставки" discountValue="Бесплатно" isFree />
          <div className={styles.totalDivider} />
          <div className={styles.finalCost}>
            <span className={styles.finalCostTitle}>Общая стоимость:</span>
            <span className={styles.finalCostSum}>{totalItemsPrice - totalEconomy} &#8381;</span>
          </div>
        </div>
        {
          loadProcess ? (
                <span className={styles.proceedBtn}>Обработка запроса....</span>
            ) : (
                <>
                    <span onClick={handlePaymentButtonClick} className={styles.proceedBtn}>Оплатить онлайн</span>
                    <span onClick={handlePaymentCompanyButtonClick} className={`${styles.proceedBtn} ${styles.redBg}`}>
                        Оплатить по счету | <span className={styles.small}>для ИП и юр.лиц</span>
                    </span>
                </>
            )
        }
       
        <span className={styles.text}>нажимая одну из кнопок, Вы принимаете <span 
              className={styles.link} onClick = {selerRule}> условия продажи </span> и <span 
              className={styles.link} onClick = {politicsRule}>политику конфиденциальности</span>
                </span>
      </div>
    </div>
  );
};


