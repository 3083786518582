import React from 'react';
import s from "./SettingHeader.module.css"
import goBack from '../../../../../assets/svg/goBack.svg';


function SettingHeader({title, callback}) {

    const onClickCallbackhandler = (e) => {
        callback()
        //navigate(-1)
    }

    return (
        <div className = {s.wrapper}>
            <div className = {s.container}>
                <img src = {goBack} alt = "" onClick = {onClickCallbackhandler} className = {s.goBack}/>
                <span className = {s.title}>
               {title}
           </span>
            </div>

        </div>
    );
}

export default SettingHeader;