import React from "react";
import {
  TelegramShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  InstapaperIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";

import classes from "./Share.module.css";

export default function Share({ url }) {
  // console.log('SHARE COMPONENT',url);
  return (
    <div className={classes.Share}>
      <TelegramShareButton url={url}>
        <TelegramIcon size={40} round={true} />
      </TelegramShareButton>
      {/* <InstapaperShareButton  */}
          {/* url={url} */}
          {/* > */}
        {/* <InstapaperIcon style={{'color' : "B5B5B5"}}    size={32} round={true} /> */}
      {/* </InstapaperShareButton> */}
      <TwitterShareButton url={url}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>
    </div>  
  );
}
