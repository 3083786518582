import React, {useEffect, useState} from 'react';  
import style from "./OrdersUser.module.css"
import link from "../../../assets/svg/link.svg";
import {useQuery} from '@apollo/client';
import {ALL_USER_ORDERS, STATUS_USER_ORDERS} from '../../../requests/orderRequests';
import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {TelegramContext} from '../../../App';

import point from "../../../assets/svg/point.svg";
import pointActive from "../../../assets/svg/pointActive.svg";


function YearButton ({yearValue, setValue}) {
    const yearButtons = ["2022 г.", "2023 г.", "2024 г."];
  
   
    return (
      <div className={style.buttonContainer}>
        {yearButtons.map((yearButton, i) => (
          <button
            type="button"
            className={`${style.dealButton} ${
              yearValue === yearButton ? style.active : ""
            }`}
            key={yearButton}
            onClick={() => setValue(yearButton)}
          >
            {yearButton}
          </button>
        ))}
      </div>
    );
  };


  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
}

export function formatStatus(status) {

  // console.log("status",status)
  let color , background;
  switch (status) {
    case "payed":
      color = "rgba(96, 96, 96, 1)";    
      return { color, status: "Оплачено" };

    case "confirmed":
        color =  "BLACK" ;
        return { color, status: "Принят" };

    case "shipping":
        color = "rgba(79, 140, 231, 1)" ;
        return { color, status: "В доставке" };
    case "received":
        color = "white"
        background = "rgba(79, 231, 112, 1)"
        return { color, status: "Получено" , background};
        // color = "rgba(79, 231, 112, 1)" ;
        // return { color, status: "Получено" };
    case "cancell":
        color = "RED" ;
        return { color, status: "Отменен" };
    case "declined":
        color = "RED" ;
        return { color, status: "Возврат  " };
    case "pending":
        color = "rgba(96, 96, 96, 1)";
        return { color, status: "Выставлен счет" };
    default:
      // console.log("status default ",status)
      color = "rgba(96, 96, 96, 1)";
        return { color, status: "В архиве" };
    }
}


function ShowTypes({ setShowTypes, setTypeOfOrder, typeOfOrder }) {
  const types = ["Все", "Оплачено", "Принят", "В доставке", "Получено", "Возврат"];

  
  return (
        <div className = {style.chooseForm} >
            <label className = {style.label}>Статус заказа </label>
                <input
                    className = {style.inputField}
                    type = "text"
                    placeholder = "Введите статус заказа"
                    
                />
            {types.map((type, i) => (
              <div className = {style.typeContainer} key = {type} onClick = {()=> {
                                                                      setTypeOfOrder(type)
                                                                      setShowTypes(false)
                                                                      }}>
              <div className = {style.type}>{type}</div>
              <div className={style.point}>
                        {type === typeOfOrder
                            ? <img src={pointActive} alt="link"/> 
                            : <img src={point} alt="choose"/>
                        }
                    </div>
              </div>
              
            ))
              }
        </div>
  );
}




function ShowOrders ({data, setShowTypes, typeOfOrder}) {

  const [yearValue, setYearValue] = useState('2024 г.');
  const navigate = useNavigate();

  // console.log("data",data)

    return (
      <div className = {style.wrapper}>

      <div className={style.title} >
      Заказы
      </div>

      <div className = {style.container} onClick = { () => setShowTypes(true)}>
          <div className = {style.textContainer}>
            <span className = {style.text}>Статус заказа</span>
            <span className = {style.linkText}>{typeOfOrder}</span>
          </div>
          <img src = {link} alt = "" className = {style.link}/>
      </div>

      {/* <YearButton yearValue = {yearValue} setValue = {setYearValue}/> */}


      
  
  
      {data.order.map((order, index) => <div key = {index}
                      className = {style.orderContainer}  >
              <div  style={{ display: 'flex', flexDirection: "column", gap :'5px   ' }}
                  >
              <div className = {style.orderInfo}>
                  <div className = {style.orderNumber}>Заказ от {formatDate(order.updated_on)}</div>
                  

              </div>

              <div className = {style.imageContainer} >


              {order.order_items.map((order_item, index) =>{

                  const {color,status, background ='white'}  = formatStatus(order_item.status)


                  return (
                  
                  <div className = {style.itemImage} key= {index} >
                  <div className = {style.itemStatus} style = {{color : color, background : background }}>{status}</div>
              
                  <img src={order_item.item.item_media[0].path} alt="foto" className={style.avatar}  />
                
                <div className = {style.quanityContainer}>
                  <div className = {style.orderQuantity}>{order_item.quantity}шт.по {order_item.price} ₽</div>
                  <div className = {style.orderSum}> = {order_item.price * order_item.quantity} ₽</div>
                </div>          

                                                    
              </div> ) }
              )}
              </div>

              </div>

              <div className={style.buttonContainer}>
                  <button 
                      className={style.buttonGetting}
                      onClick={() => navigate("/profile/order_info", { state: { orderId: order.id } })}
                  >
                      Подробнее
                  </button>
              </div>


          </div>)}

   
      

  </div>
    )
  }



const OrdersUser = () => {

    // console.log("OrdersUser")

    const [showTypes, setShowTypes] = useState(false);  
    const [typeOfOrder, setTypeOfOrder] = useState('Все');
    const {userID} = useContext(TelegramContext) ;  

    

    const status = {
        "Все" : "all",
        "Оплачено" : "payed",
        "Принят" : "confirmed",
        "В доставке" : "shipping",
        "Получено" : "received",
        "Отменен" : "canselled",
        "Возврат" : "declined",
        // "Возврат" : "returned"
          
    } 

    const getQuery = () => {
      if (typeOfOrder === "Все") {
          return ALL_USER_ORDERS;
      } else {
          return STATUS_USER_ORDERS;
      }
        };
    let QUERY = getQuery();


    // console.log('typeOfOrder', typeOfOrder)
    
      
    const { data, error, loading } = useQuery(QUERY, {
            fetchPolicy: 'cache-and-network',
            variables: { userID, status: status[typeOfOrder] },
        });
      
        if (loading) return 'Loading...';
        if (error) {
            console.error('Watched items error', error);
            return 'Error';
        }

  return (
      showTypes 
          ? <ShowTypes setShowTypes={setShowTypes} setTypeOfOrder={setTypeOfOrder} typeOfOrder={typeOfOrder}/> 
          : <ShowOrders data={data} setShowTypes={setShowTypes} typeOfOrder={typeOfOrder} />
  );
};


export default OrdersUser;
