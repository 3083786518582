import React from "react";
// import {gql} from "@apollo/client";
import {useMutation} from "@apollo/client";
import {SHOPS_LIKES} from "../../requests/likesRequests";
import likesRed from "../../assets/svg/likesRed.svg";
import classes from "./Likes.module.css";
import {DELETE_LIKE_SHOP} from "../../requests/likesRequests";
import {likesAmount} from "../LikedItems/utils/AmountOfLikes";
import {useContext} from "react";
import {TelegramContext} from "../../App";

export default function DeleteLikeShop({ShopID, setAdd}) {
    const {userID} = useContext(TelegramContext) || 534677865;

    const [deleteFunction] = useMutation(DELETE_LIKE_SHOP, {
        variables: {ShopID, userID},
        refetchQueries: [
            // DocumentNode object parsed with gql
            {queri: SHOPS_LIKES},
        ],
    });

    return (
        <img
            className = {classes.Likes}
            alt = "like"
            src = {likesRed}
            onClick = {() => {
                if (userID !== 0){
                deleteFunction();
                }
                setAdd((a) => !a);
                likesAmount(likesAmount() - 1);
            }}
        ></img>
    );
}
