import {NavLink, useNavigate} from "react-router-dom";
import {useContext} from 'react';
import {ModalContext} from '../../../App';
import classes from './Menu.module.css';

import {ReactComponent as Icon} from '../../../assets/svg/menu.svg';

const Menu = () => {
    console.log('Menu')
    const navigate = useNavigate();
    const {setModalActive, setModalContent} = useContext(ModalContext);
    const linkClassname = ({isActive}) => isActive ? classes.activeImg : classes.img
    return (
        <div className = {classes.linkContainer}
             onClick = {() => {
                 setModalActive(false)
                 setModalContent('')
             }}>
            <NavLink to = {"/"} className = {linkClassname}>
                <Icon/>
                <div>Меню</div>
            </NavLink>
        </div>);
};

export default Menu;
