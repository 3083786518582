import React, { useState } from "react";
import styles from "./ProcessPayment.module.css"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GET_PAYMENT } from "../../requests/orderRequests";


import {finishPaymentAPI} from "../../utils/apiCalls/ordersApi"

import {amountDynamic} from "../Footer/Backet/Backet"



export default function ProcessPayment(){
    const navigate = useNavigate();
    const location = useLocation();

    const [loadProcess, setLoadProcess] = useState(false)
    // console.log(location.state)

    const paymentId= location.state.paymentData || null;
    // console.log(paymentId)


    const {data, error, loading} = useQuery(GET_PAYMENT,{
        fetchPolicy: 'cache-and-network',
        variables: {paymentId},
    }); 

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('get shop items error', error);
        return 'error';
    }
    const paymentData = data.payment[0];
    
 

    const handleButton = async()=>{
        setLoadProcess(true)
      try {
            const response = await finishPaymentAPI(paymentId)
            // console.log('responseDDDDDDDDDDDDDD', response)
            if (response.ok) {
                // console.log('Payment successful:', response.data);
                navigate('/');
            } else {
                // Handle HTTP errors
                console.error('Payment failed:', response.status);
                alert('Payment failed, please try again.');
            }
        } catch (error) {
            console.error('Payment failed:', error);
            alert('Payment failed, please try again.');
        }
        setLoadProcess(false)
        amountDynamic(amountDynamic() - paymentData.order.order_items.length)

    }

   
        

    return(
        <div className={styles.main}>
            <h1>ПЕРЕХОД 
                НА ПЛАТЕЖНЫЙ СЕРВИС
            </h1>

            <p>Введите данные карты</p>
            <input className={styles.input} type="text" placeholder="Номер карты"/>
            <input className={styles.input} type="text" placeholder="Имя на карте"/>
            <input className={styles.input} type="text" placeholder="Срок действия"/>
            <input className={styles.input} type="text" placeholder="CVV"/>
            <p>Сумма к оплате</p>
            <p>{paymentData.total_amount} руб</p>
            {loadProcess ?
                <button className={styles.button} >
                ПРОЦЕСС ОПЛАТЫ
                </button>
                :
                <button className={styles.button}  onClick={() => handleButton()}>
                    ОПЛАТИТЬ
                </button>
            }
        </div>
    )
}
