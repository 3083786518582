import React, {useContext, useEffect, useState} from 'react';
import styles from './MainLotData.module.css';
import DateToEnd from '../../../../utils/DateToEnd';
import {AddModalContext} from '../../DisplayItems';

export default function MainLotData({endDate, id, isActive}) {
    // const {setExpireTime} = useContext(AddModalContext);
    const [time, setTime] = useState(DateToEnd(endDate));
    const [expireTime, setExpireTime] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(DateToEnd(endDate));
            // setExpireTime(DateToEnd(endDate));
        }, 1000 * 60);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isActive) setExpireTime(DateToEnd(endDate));
    }, [isActive])

    return (<div className = {styles.timeContent}>
        {time}
    </div>);
}
