import React from 'react'
import Lot from './Lot/Lot';
import styles from './Lots.module.css'

const Lots = ({lots, selectLot, setLotID }) => {
    // console.log("Lots rerender", lots)

    return (
        <div className = {styles.container}>
            {lots.map((lot, index) => <div key={index}>
                                        <Lot 
                                           lot = {lot}
                                           index = {index}
                                           selectLot = {selectLot}
                                           setLotID = {setLotID}
                                           />
                                           </div>
                                           )}
        </div>)
}

export default Lots