import React, {useEffect} from 'react';
import styles from "./SuccessRegistration.module.css"
import {useNavigate} from 'react-router-dom';


function SuccessRegistration({path, data, children}) {
    const navigate = useNavigate();


    useEffect(() => {

        setTimeout(() => {
            navigate(path, {state: data})
        }, 2000)
    }, [])

    return (<div className = {styles.wrapper}>

            {children}


        </div>


    );
}

export default SuccessRegistration;

