import axios from "axios";


  

export const createOrderApi = async ({ userID, cartIDs }) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/orders/create_order`, {
          variables: {
            user_id: userID,
            cart_id: cartIDs
          }
        }, {
          headers: {
            "content-type": "application/json",
          }
        });

        return res.data;
      } catch (error) {

        await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: `error while create order ${error.message}`, }),
        });

        alert("Произошла ошибка при создании заказа.Попробуйте еще раз.");

      }
    }



export const createPaymentApi = async ({ variables }) => {

        try {
              const res = await axios.post(`${process.env.REACT_APP_API_URL}/orders/create_payment`, {
                variables: variables
              }, {
                headers: {
                  "content-type": "application/json",
                  // "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
                }
              });
              console.log('res', res);
              return res.data;
            } catch (error) {
                await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: `error while create order ${error.message}`, }),
                  });
            }
        
            
          }
        


export    const finishPaymentAPI = async (paymentId) => {
            // const data = new URLSearchParams();
                // data.append('paymentId', paymentId);
            
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/payment_done`, {
                method: 'POST',
                headers: {
                  'Content-Type': "application/json",
                },
                body: JSON.stringify({ paymentId: paymentId }) ,
              });
              console.log('response', response);
              if (response.ok) {
                // console.log('Payment successful:', response.data);
                return response;
              } else {
                // Handle HTTP errors
                console.error('Payment failed:', response.status);
                alert('Payment failed, please try again.');
              }
            } catch (error) {
              // Handle network errors or other unexpected issues
              console.error('Network error or something went wrong:', error);
              alert('An error occurred, please try again.');
            } finally {
            }
          };


export const refundItemApi = async (orderItemId, reason, comment, images = []) => {
    try {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/orders/refund_item`, {
            variables: {
              orderItemId: orderItemId,
              reason: reason,
              comment: comment,
              images: images
            }
          }, {
            headers: {
              "content-type": "application/json",
              // "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
            }
          });
          return res.data;
        } catch (error) {
          await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message: `error while create refund ${error.message}`, }),
          });
          alert("Произошла ошибка при отмене заказa.");
        }
      }

export const confirmOrderApi = async (orderItemId, shop_estimate, delivery_estimate) => {
  try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/orders/confirm_order`, {
        variables: {
          orderItemId: orderItemId,
          shop_estimate: shop_estimate,
          delivery_estimate: delivery_estimate

        }
      }, {
        headers: {
          "content-type": "application/json",
          // "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
        }
      });
      return res.data;
    } catch (error) {
      await fetch(`${process.env.REACT_APP_API_URL}/bot/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: `error while confirm order ${error.message}`, }),
      });
      alert("Произошла ошибка при подтверждении заказa.");
    }
  }