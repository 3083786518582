import React,{useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './GoodsSettings.module.css'
import {  DELETE_ITEM, CHECK_ITEM_ORDERS } from '../../../../requests/itemRequests';
import goBack from '../../../../assets/svg/goBack.svg';
import deleteCart from '../../../../assets/svg/deleteCart.svg';
import { useMutation,  useLazyQuery } from '@apollo/client';
import MediaSettings from './MediaSettings';
import { useDispatch, useSelector } from 'react-redux';
import {fetchSettings} from '../../../../features/settingItemSlice'
import DescriptionSettings from './DescriptionSettings';
import VariantsSettings from './VariantsSettings';

import point from '../../../../assets/svg/point.svg';
import pointActive from '../../../../assets/svg/pointActive.svg';
import { brandList } from '../../../../utils/brandList';
import { countryList } from '../../../../utils/countryList';
import Variants from '../CreateItem/Variants';


export const SETTINGS_ITEMS = {
    default: "DEFAULT",
    media: "MEDIA",
    description: "DESCRIPTION",
    about: "ABOUT",
    character: "CHARACTER",
    variants: "VARIANTS",
    gabarits: "GABARITS",
    brand: "BRAND",
    country: "COUNTRY",
}


function getMediaLabel(length) {
    if (length === 1) {
      return 'файл';
    } else if (length === 2 || length === 3 || length === 4) {
      return 'файла';
    } else {
      return 'файлов';
    }
  }

  function DeleteButton({ itemId }) {

    const navigate = useNavigate();
    const [deleteItem] = useMutation(DELETE_ITEM);
    const [checkForDelete] = useLazyQuery(CHECK_ITEM_ORDERS);
  
    const deleteHandleItem = async () => {
      try {
        const { data, loading, error } = await checkForDelete({
          variables: { itemId: itemId },
        });
  
        if (loading) {
          console.log('loading');
        }
  
        if (error) {
          console.error('Error:', error.message);
          
        }
  
        if (data.order_items.length > 0) {
            alert('Нельзя удалить товар, который есть в заказе');
          return;       
        }
  
        await deleteItem(
            {
          variables: { itemId: itemId },
        });
  
        navigate(-1); // Navigate after successful deletion
      } catch (err) {
        console.error('Failed to delete item', err);
        // Show error state to the user
      }
    };
  
    return (
      <img
        className="delete-button" // use class instead of inline style
        src={deleteCart}
        alt="Delete Item"
        onClick={deleteHandleItem}
      />
    );
  }




function DefaultSettings({  setDisplayMode}){
    const navigate = useNavigate();

    let item = useSelector((state) => state.settingsItem.item);
    let itemMedia = useSelector((state) => state.settingsItem.itemMedia);


    if (!item || Object.keys(item).length === 0) {
        return <div>Loading...</div>;
    }
    
    // let { item } = { item: data.item[0] };



    const {articleNumber, 
                name, 
                about, 
                category_items,
                status,
                // itemMedia,
                brand,
                barcode,
                partNumber,
                country,
                waranty,
                quantity_box,
                color,
                character,
                item_variants,
            } = item;
    
    const itemStatus = {
                'preparation' : 'Модерация',  
                'onsale' : 'В продаже',
                'moderation' : 'Модерация',
                'denied' : 'Отклонен',
                'archive' : 'В архиве',
                    }[status]
   
    const {category, subcategory, product_category} = category_items[0].category;
   

    const mediaLabel = getMediaLabel(itemMedia.length );


    // console.log('variants', item_variants)
    const gabarits = item_variants[0];

   
    return (
    <div className={styles.container}>
        <div className = {styles.headerContainer}>
            <div className={styles.status}>{itemStatus}</div>
            <div className = {styles.header}>
                        <img src = {goBack} alt = "back" onClick = {() => navigate(-1)}/>
                        <div className={styles.row}>
                            <h1 style={{ fontWeight: 400 }}>Артикул</h1>
                            <h1>{articleNumber}</h1>
                        </div>
                        <DeleteButton  itemId={item.id}
                        />

            </div>
                <div className={styles.nameContainer}>
                    <h2>{name}</h2>
                    <span>
                    {category}/{subcategory}/{product_category}
                    </span>
                </div>
        </div>

        <div className={styles.infoContainer}>
            <div className={styles.row}>
            <label className={styles.label}>
                <h2>Mедиафайлы</h2>
                <span>{itemMedia.length} {mediaLabel}</span>
            </label>
            <span className={styles.buttonFix} onClick={()=> setDisplayMode(SETTINGS_ITEMS.media)} > Редактировать </span>
            </div>
            
            <div className={styles.mediaContainer}>
            {itemMedia.length > 0 ? itemMedia.map((imageUrl , index) => (
                          <div className={styles.avatarContainer} key={index}>
                                {imageUrl.type === 'image' ? (
                                  <img src={imageUrl.path} alt="foto" className={styles.avatar} />) :

                                (<video src={imageUrl.path} alt="video" className={styles.avatar} />)}
                              {/* <div type="button" className={styles.deleteButton} onClick={() => handleDelete(imageUrl , 'image')}> */}
                                {/* <img src={vector} alt="delete"></img> */}
                              {/* </div> */}

                          </div>
                      )) : <span className={styles.mediaText} > Изображение </span>}
            </div>
        </div>
        <div className={styles.infoContainer}>
            <div className={styles.row}>
                <h2>Описание</h2>
                <span className={styles.buttonFix} onClick={() => setDisplayMode(SETTINGS_ITEMS.description)}> Редактировать </span>
            </div>
            <p>
            {about}
            </p>
        </div>

        <div className={styles.infoContainer}>
            <div className={styles.row}>
                <h2>О товаре</h2>
                <span className={styles.buttonFix} onClick={() => setDisplayMode(SETTINGS_ITEMS.about)}> Редактировать </span>
            </div>
            <div className={styles.infoRow}>
                <div className={styles.info}>
                    <label className={styles.labelInfo}>Бренд</label>
                    <span className={styles.textInfo}>{brand}</span>
                </div>
                <div className={styles.info}>
                    <label className={styles.labelInfo}>Штрихкод</label>
                    <span className={styles.textInfo}>{barcode}</span>
                </div>
            </div>
            <div className={styles.infoRow}>
                <div className={styles.info}>
                    <label className={styles.labelInfo}>Партномер (заводской артикул)</label>
                    <span className={styles.textInfo}>{partNumber || '-'}</span>
                </div>
            </div>
            <div className={styles.infoRow}>
                <div className={styles.info}>
                    <label className={styles.labelInfo}>Страна изготовитель</label>
                    <span className={styles.textInfo}>{country || '-'}</span>
                </div>
                <div className={styles.info}>
                    <label className={styles.labelInfo}>Гарантийный срок</label>
                    <span className={styles.textInfo}>{waranty || '-'}</span>
                <div/>
            </div>
            </div>
            <div className={styles.info}>
                    <label className={styles.labelInfo}>Количество заводских упаковок</label>
                    <span className={styles.textInfo}>{quantity_box || '-'}</span>
            </div>
            <div className={styles.info}>
                    <label className={styles.labelInfo}>Цвет</label>
                    <span className={styles.textInfo}>{color || 'Без цвета'}</span>
            </div>
        
        </div>
        <div className={styles.infoContainer}>

            <div className={styles.row}>
                <h2>Характеристики</h2>
                <span className={styles.buttonFix} onClick={()=> setDisplayMode(SETTINGS_ITEMS.character)}> Редактировать </span>
            </div>
            <div className={styles.characterRow}>    
                    {Object.keys(character).length > 0 ? Object.entries(character).map(([key, value], index) => (
                      <div key={index} className={styles.character}>
                        <label className={styles.labelInfo}>{key}</label>
                        <span className={styles.textInfo}>{value}</span>
                      </div>
                    )) : (
                    <div className={styles.info}>
                    {/* <label className={styles.labelInfo}>Цвет</label> */}
                        <span className={styles.textInfo}>{'-'}</span>
                    </div>
                )}
            </div>
        </div>
        <div className={styles.infoContainer}>
            <div className={styles.row}>
                <h2>Габариты </h2>
                <span className={styles.buttonFix} onClick={()=> setDisplayMode(SETTINGS_ITEMS.gabarits)}> Редактировать </span>
            </div>
            <div className={styles.characterRow}>
                <div className={styles.character}>
                    <label className={styles.labelInfo}>Длина упаковки (мм)</label>
                    <span className={styles.textInfo}>{gabarits.length || '-'}</span>
                </div>
                <div className={styles.character}>
                    <label className={styles.labelInfo}>Ширина упаковки (мм)</label>
                    <span className={styles.textInfo}>{gabarits.width || '-'}</span>
                </div>
                <div className={styles.character}>
                    <label className={styles.labelInfo}>Высота упаковки (мм)</label>
                    <span className={styles.textInfo}>{gabarits.height || '-'}</span>
                </div>
                <div className={styles.character}>
                    <label className={styles.labelInfo}>Вес упаковки (мм)</label>
                    <span className={styles.textInfo}>{gabarits.weight || '-'}</span>
                </div>  
                        
            </div>
        </div>
        <div className={styles.infoContainer}>
            <div className={styles.row}>
                <h2>Варианты </h2>
                {/* <span className={styles.buttonFix} onClick={()=> setDisplayMode(SETTINGS_ITEMS.variants)}> Редактировать </span> */}
            </div>

            {item_variants.map((variant, index) => (
                <div key = {index}>
            <div className={styles.row} >
                    <h2>Вариант {index} </h2>
                    {index === 0 && (<span > Исходный </span>)}
                </div>
                <div className={styles.variantsInfo}>
                    <div className={styles.avatarContainer} key={variant.image}>
                        <img src={variant.image} alt="foto" className={styles.avatar} />
                    </div>
                    <div className={styles.variantsInfoColumn}>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Артикул</label>
                            <span className={styles.labelInfo}>{variant.articleNumber}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Длина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.length || '-'}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Ширина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.width || '-'}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Высота упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.height || '-'}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Вес упаковки (мм)</label>
                            <span className={styles.textInfo}>{variant.weight || '-'}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.characterRow}>

                      {variant.purposeName ? (
                        <>
                        <div className={styles.character}>
                          <label className={styles.labelInfo}>{variant.purposeName}</label>
                          <span className={styles.textInfo}>{variant.purposeValue}</span>
                          </div>
                        </>
                      ) : ( 
                        <div className={styles.character}>
                            <label className={styles.labelInfo}>-</label>
                        </div> )}
                      {variant.purposeName2 && (
                        <div className={styles.character}>

                          <label className={styles.labelInfo}>{variant.purposeName2}</label>
                          <span className={styles.textInfo}>{variant.purposeValue2}</span>
                        </div>
                       
                      ) }
                
                </div>
                </div>
            ))}

            
            
        </div>

    </div>
    )
}






function SelectionList({
              title,
              setDisplayMode,
              setSelectedValue,
              selectedValue,
              list,
              placeholder,
              setFormData,
            }) {
  
  const [searchTerm, setSearchTerm] = useState('');
 
  const name = { 'Бренд': 'brand', 'Страна': 'country', 'Цвет': 'color' }[title];
  
  function itemHandler(event, item) {
    const name = { 'Бренд': 'brand', 'Страна': 'country', 'Цвет': 'color' }[title];
    // console.log('name', name);
    setSelectedValue(item);
    setFormData((prev) => ({ ...prev, [name]: item })); 
  }

  const filteredItems = list.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1 className={styles.header}>{title}</h1>
      <span
        className={styles.cthrow}
        onClick={() => {
          setDisplayMode(SETTINGS_ITEMS.about);
          setSelectedValue('');
          setFormData((prev) => ({ ...prev, [name]: '' }));
        }}
      >
        Сбросить
      </span>
      <input
        className={styles.search}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className={styles.brandList}>
        {filteredItems.map((item, index) => (
          <div
            className={styles.categoryContainer}
            onClick={(e) => itemHandler(e, item)}
            key={index}
          >
            <span>{item}</span>
             
            <div className="point">
              {selectedValue === item ? (
                <img src={pointActive} alt="link" />
              ) : (
                <img src={point} alt="choose" />
              )}
            </div>
          </div>
        ))}
      </div>

      <button
        className={styles.addButton}
        disabled={!selectedValue}
        onClick={(event) => {
          event.preventDefault();

          setDisplayMode(SETTINGS_ITEMS.about)}}
      >
        Далее
      </button>
    </div>
  );
}



function GoodsSettings(){
    //path : goods_storage  state : itemId
    
    const location = useLocation();
    const itemId = Number(location.state);
    const [displayMode, setDisplayMode] = useState(SETTINGS_ITEMS.default)

    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const item = useSelector((state) => state.settingsItem.item);

    const dispatch = useDispatch();

    const initialFormData = {
      brand: '',
      name: '',
      articleNumber: '',
      partNumber: '',
      barcode: '',
      description: '',
      country: '',
      color: '',
      quantity_box: 0,
      waranty: '',
      character: [],
        };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
      dispatch(fetchSettings(itemId)).then((action) => {
          
      });
    }, [dispatch, itemId]);
    

    // console.log('item FFFFFFFFF', item)
    

    useEffect(() => {
      if (item && Object.keys(item).length > 0) {
          setFormData({
              brand: item.brand || '',
              name: item.name || '',
              articleNumber: item.articleNumber || '',
              partNumber: item.partNumber || '',
              barcode: item.barcode || '',
              description: item.about || '',
              country: item.country || '',
              color: item.color || '',
              quantity_box: item.quantity_box || 0,
              waranty: item.waranty || '',
              weight : item.item_variants[0].weight || '',
              height : item.item_variants[0].height || '',
              width : item.item_variants[0].width || '',
              length : item.item_variants[0].length || '',
              character: Object.entries(item.character || {}).map(([key, value]) => ({
                  characterName: key || '',
                  characterValue: value || ''
              })),
          });
      }
    }, [item]);





    let content;
    switch (displayMode) {
      case SETTINGS_ITEMS.default:
        content = <DefaultSettings   setDisplayMode={setDisplayMode}
                  
        />;
        break;
      case SETTINGS_ITEMS.media:
        content = <MediaSettings setDisplayMode={setDisplayMode}/>;
        break;

      case SETTINGS_ITEMS.description:
        content = <DescriptionSettings setDisplayMode = {setDisplayMode}
                    condition = {'description'}
                    formData = {formData}
                    setFormData = {setFormData}
        />;
        break;
      case SETTINGS_ITEMS.about:
        content = <DescriptionSettings setDisplayMode = {setDisplayMode}
                    condition = {'about'}
                    formData = {formData}
                    setFormData = {setFormData}
        />;
        break;
      case SETTINGS_ITEMS.character:
        content = <DescriptionSettings setDisplayMode = {setDisplayMode}
                    condition = {'character'}
                    formData = {formData}
                    setFormData = {setFormData}
        />;
        break;
      case SETTINGS_ITEMS.gabarits:
        content = <DescriptionSettings setDisplayMode = {setDisplayMode}
                    condition = {'gabarits'}
                    formData = {formData}
                    setFormData = {setFormData}
        />;
        break;
      case SETTINGS_ITEMS.variants:
        content = <VariantsSettings setDisplayMode = {setDisplayMode}

        />;
        break;
      case SETTINGS_ITEMS.brand:
            content = <SelectionList
            title="Бренд"
            setDisplayMode={setDisplayMode}

            setSelectedValue={setSelectedBrand}
            selectedValue={selectedBrand}
            list={brandList}
            placeholder="Введите бренд"
            setFormData={setFormData}
      
        />;
        break;

      case SETTINGS_ITEMS.country:
        content = <SelectionList
              title="Страна"
              setDisplayMode={setDisplayMode}
              setSelectedValue={setSelectedCountry}
              selectedValue={selectedCountry}
              list={countryList}
              placeholder="Введите страну"
              setFormData={setFormData}
                />
        break;
      

      default:
        content = 
                  <DefaultSettings  setDisplayMode={setDisplayMode}
                    formData = {formData}
                    setFormData = {setFormData}
                  />;
                  
        break;    
      
      
    }
    return (
        <>
            {content}
        </>
    )
}

export default GoodsSettings;