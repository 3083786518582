import React, {useContext, useEffect,} from 'react';
import {makeVar,  useReactiveVar, useLazyQuery} from '@apollo/client';
import {AMOUNT_SHOP_ITEM_LIKES} from '../../../requests/requests';
import {TelegramContext} from '../../../App';

export const likesAmount = makeVar(0);

function AmountOfLikes() {


  const {userID} = useContext(TelegramContext)
  const reactiveAmountOfLikes = useReactiveVar(likesAmount);


  
  const [getLikes, {loading, error, data}] = useLazyQuery(AMOUNT_SHOP_ITEM_LIKES);

  useEffect(() => {
    getLikes({
      fetchPolicy: 'cache-and-network',
      variables: {userID}
    });
  }, []);  



  useEffect(() => {
    if (data) {
      const userLikes = data.users_likes_aggregate.aggregate.count;
      const shopLikes = data.shops_likes_aggregate.aggregate.count;
      likesAmount(userLikes + shopLikes);

    }
  }, [data]);  // Add data to the useEffect dependencies list.

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    
    <>
    {
      (reactiveAmountOfLikes > 0) ? 
        (<div className="amountsCount">
          <span>{reactiveAmountOfLikes}</span>
        </div>) : null
    }
    </>
  );
}
export default React.memo(AmountOfLikes);



