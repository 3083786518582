import React from "react";
import styles from "../../components/ProfileInputWithLinks/ProfileInputWithLinks.module.css";
import link from "../../../../../assets/svg/link.svg";

function ProfileInputMail({
            value,
            callback,
        }) {

   
    

    return (<div className = {styles.wrapper}>
            <label className = {styles.label}>
            {"Почта:"}
                <input className = {styles.registeredInput}
                       value = {value} type = {"text"}
                       onChange = {(event) => callback(event.target.value)}
                       placeholder = {'Введите email'}
                />
            </label>
            <img src = {link} alt = ""/>
        </div>



    );
}

export default ProfileInputMail;