import React, {createContext, useContext, useEffect, useState} from 'react';
import ProductSwiper from './ProductSwiper/ProductSwiper';
import classes from './ProductCard.module.css';
import {useMutation, useQuery} from '@apollo/client';
import approveSvg from '../../assets/svg/approve.svg';
import link from '../../assets/svg/link.svg';
import numWord from '../../utils/NumWord';
import Shop from './Shop/Shop';
import Links from './Links/Links';
import {GET_PRODUCT, WATCH_PRODUCT} from '../../requests/productRequests';
import {useNavigate, useParams} from 'react-router-dom';
import {ModalContext, TelegramContext} from '../../App';
import ShopMessage from './ShopMessage/ShopMessage';
import Share from './Shop/SharingPage/Share';
import Like from '../Likes/Likes';
import ShowVideoReview from './VideoReview/VideoReview';
import {Rating} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ShareButton from '../ShareButton/ShareButton';
import {modalDisplayMode} from '../PrimaryModal/PrimaryModal';
import Lots from './Lots/Lots';
import DescribesAndProperties from './DescribesAndProperties/DescribesAndProperties';
import defaultImage from '../../assets/jpg/img1.jpg'

import {Modifiers} from './Modifiers';

import AddElement from '../AddElement/AddElement';

import Recommend from './Recommend/Recommend';
import LooksLike from './LooksLike/LooksLike';
import ByWith from './ByWith/ByWith';





// Create a Context for ProductCard
export const ProductCardContext = createContext();


export default function ProductCard() {

    // console.log('src/Containers/ProductCard ')

    
    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const {userID} = useContext(TelegramContext) //||508519898
    const navigate = useNavigate();

    const {productId} = useParams()




    // amount add to the cart
    const [amount, setAmount] = useState(null);
    // is amount changed for items which have in cart
    const [isAmountChanged, setIsAmountChanged] = useState(false)
    // is product in cart
    const [inProductCard, setInProductCard] = useState(false);




    const [currentVariant, setCurrentVariant] = useState(null);
    const [selectedItem, setSelectedItem] = useState(false);
    const [renderLots, setRenderLots] = useState([]);
    const [selectedLotID, setSelectedLotID] = useState(null);



    // for save which product user watch
    const [upsertWatch] = useMutation(WATCH_PRODUCT);



    let lots =[];
  




    useEffect(() => {
      
        if (selectedItem) {
            setRenderLots(lots.filter(lot => lot.item_variant.id === currentVariant))
            
        }

        setInProductCard(false)
        


    }, [selectedItem, currentVariant]);





    // Fetch product details
    const {loading, error, data} = useQuery(GET_PRODUCT, {
        variables: {productId, userID},
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.item[0].item_variants.length <= 1) {
                setSelectedItem(true);
                setCurrentVariant(data.item[0].item_variants[0]?.id);
            }
            if (userID !== 0){ 
            upsertWatch({variables: {productId, userID}});
            }
        }
    });
    if (loading) return <div>Loading...</div>;
    if (error) {
        console.error(error);
        return <div>Error!</div>;
    }

  

    let item, isLiked;
    item = data.item[0];
    lots = item.lots ?? [];
    isLiked = item.users_likes.length > 0;

    const category = item?.category_items[0].category.category;
    const subcategory = item?.category_items[0].category.subcategory;
    const shop = item?.shop;
    const videoMedia = item.item_media.find(media => media.type === 'video');
    const videoUrl = videoMedia?.url || null;
    const imagesItem = item.item_media.filter(media => media.type === 'image') || [];
    const posterImageUrl = imagesItem[0]?.path || null;
    const variants = item.item_variants ?? null;
    const rating = Number(item.comment_items_aggregate?.aggregate?.avg.estimate);
    const starCount = `${item.comment_items_aggregate?.aggregate?.count} 
                            ${numWord(item.comment_items_aggregate?.aggregate?.count,
        [' отзыв', ' отзыва', ' отзывов'])}`;
    const approveLabel = item.shop.approval === 'approved' ? approveSvg : null;

    
    
    
    


   




    const onChangeLotIdHandler = (id) => {
        setSelectedLotID(id);
        const lot = lots.find(lot => lot.id === id);
        setInProductCard(lot.carts.length > 0)
        setAmount(lot.carts[0]?.quantity || lot.step);
        setIsAmountChanged(false);
    }

    // Callback for shared item
    const sharedItemCallback = () => {

        
        setModalMode(modalDisplayMode.withOverlay);
        setModalContent(<Share url = {`https://sovmestno.sale/product/${productId}`}/>);
        setModalActive(true);
    }


    // Handler to send a message to the shop
    const sendMessageToTheShop = () => {
        setModalActive(true);
        setModalContent(<ShopMessage
            setModalActive = {setModalActive}
            shop_id = {shop.id}/>);
    }


    let withOutLot = false;
    // console.log(lots.length)
    if(lots.length === 0) {
         withOutLot = true;
    }
    // console.log('withOutLot', withOutLot)

    return (
        <div className = {classes.ProductCard}>
                <div>
                    <ProductSwiper images = {imagesItem}/>
                    
                    <div className = {classes.shareContainer}>
                            
                            <Like ItemID = {productId} doWeHaveLike = {isLiked}/>
                    </div>
                    


                    <div className = {classes.InfoAll}>
                        <div className = {classes.Name}>{`${item.name}`}</div>
                        <div className = {classes.row}>
                            <div
                                onClick = {() => {
                                    navigate(`/product/${productId}/reviews`);
                                }}>
                                <Rating name = "read-only-rating" value = {rating}
                                    // precision = {0.5}
                                        readOnly size = "small"
                                        emptyIcon = {<StarIcon fontSize = "inherit"/>}
                                />
                                {item.comment_items_aggregate?.aggregate?.count > 0 ? (<span className =
                                                                                                 {classes.starCount}>{starCount} </span>) : ('')}
                            </div>
                            <button className = {classes.buyersChoice}
                                    onClick = {() => {
                                        navigate(`/v1/category`, {state: {category: category, subCategory: subcategory}});
                                    }}
                            >Выбор покупателей
                            </button>
                        </div>


                        <div className = {classes.row}>
                            <ShowVideoReview posterImageUrl = {posterImageUrl} videoUrl = {videoUrl}/>
                            <ShareButton 
                             title = {"Поделиться товаром"} callback = {sharedItemCallback}/>
                        </div>



                        {variants[0]?.purposeName && <div className = {classes.row}>

                            <div className = {classes.rowModifiers}>
                            <Modifiers  variants = {variants}
                                        setCurrentVariant = {setCurrentVariant}
                                        setSelectedItem = {setSelectedItem}

                            /> 

                                </div>  
                        </div>}


                         {selectedItem && 
                        <div className = {classes.row}>
                            <Lots lots = {renderLots}
                                   setLotID = {onChangeLotIdHandler} 
                                   selectLot = {selectedLotID} 
                            /> 
                        </div>
                        } 


                        



                        <div className = {classes.separator}></div>

                        <div className = {classes.row}>
                            <DescribesAndProperties item = {item}/>
                        </div>

                        <div className = {classes.separator}></div>

                        {/* <div className = {styles.delivery}> */}

                        {/* <Delivery inProductCard = {true} itemId = {item.id} cityID = {cityID}/> */}
                        {/* </div> */}

                        <div className = {classes.shopContainer}>
                            <Shop shop = {item.shop}/>
                        </div>
                        <div className = {classes.row}
                             onClick = {sendMessageToTheShop}>
                            <span className = {classes.contactStore}>Задать вопрос продавцу</span>
                            <img src = {link} alt = ""/>
                        </div>
                        <div className = {classes.shopSeparator}></div> 
                        <div>
                            <Links
                                category = {category}
                                subcategory = {subcategory}
                                shop = {shop}
                                image = {item.item_media[0]?.path ?? defaultImage}
                            />
                        </div> 


                        <div className = {classes.separator}></div>


                        <div className = {classes.horisontalGoods}>
                            <div>
                                <LooksLike limit = {5}/>
                            </div>
                            <div>
                                <ByWith limit = {5}/>
                            </div>
                            <div>
                                <Recommend limit = {5}/>
                            </div>


                        </div> 

                    </div>
                    </div>
                {((selectedItem && selectedLotID) || withOutLot) && 
                 <div className = {classes.Footer}>
                    <AddElement shopId = {shop.id}
                                itemId = {item.id}
                                lot = {lots.find(lot => lot.id === selectedLotID)} 
                                amount = {amount}
                                setAmount={setAmount}
                                setIsAmountChanged={setIsAmountChanged}
                                isAmountChanged={isAmountChanged}
                                setInProductCard = {setInProductCard}
                                inProductCard = {inProductCard}
                                withOutLot = {withOutLot}

                            />
                </div>
                }
                


        </div>
    );
}


