import {GET_SUBCATEGORY_WITH_ITEMS} from "../../requests/categoryRequests";
import { useQuery} from "@apollo/client";

export default function LoadSubCategory({currentCategory }) {
     // console.log('LoadSubCategory', currentCategory)
    const {loading, error, data} = useQuery(GET_SUBCATEGORY_WITH_ITEMS, {
        variables: {currentCategory},
    });

    if (loading) {
        return null;
    }
    if (error) {
        console.error(error);
        return null;
    }
    // console.log('SUBCATEGORY !!!!!!!!!', data)
    return {data}; 
}
