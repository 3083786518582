import classes from './App.module.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Header from './Containers/Header';
import Footer from './Containers/Footer/Footer';
import {createContext, useState} from 'react';
import {Provider} from 'react-redux';
import store from './store';
import PrimaryModal, {modalDisplayMode} from './Containers/PrimaryModal/PrimaryModal';


// import { Card } from '@mui/material';
import {RoutesPath} from './routes';


export const ModalContext = createContext();
export const SearchContext = createContext('');
export const CityContext = createContext();
export const TelegramContext = createContext();


function App({userId, userName}) {
    const [modalActive, setModalActive] = useState(false);
    const [modalMode, setModalMode] = useState(modalDisplayMode.withoutOverlay);//withOverlay, withoutOverlay
    const [modalContent, setModalContent] = useState('');
    const [modalPage, setModalPage] = useState('main');
    const [searchValue, setSearchValue] = useState('');
    const [deliveriesCityID, setDeliveryCityID] = useState(2706);
    const [isModifiedCity, setModifiedCity] = useState(false);

    const [userID, setUserID] = useState(userId)   //2137984815'//'1457431377' //'534677865'// || '1457431377'//'1457431377' //|| '508519898'//
    const [userFirstName, setUserFirstName] = useState(userName);


   


    return (
        <>
            <Router>
                <div className = {classes.App}>
                    <TelegramContext.Provider value = {{userID, userFirstName, setUserFirstName, setUserID}}>
                        <SearchContext.Provider value = {{searchValue, setSearchValue}}>
                            <ModalContext.Provider
                                value = {{
                                    modalActive,
                                    setModalActive,
                                    modalContent,
                                    setModalContent,
                                    modalMode,
                                    setModalMode,
                                    modalPage,
                                    setModalPage
                                }}>
                                <CityContext.Provider
                                       value = {{deliveriesCityID, setDeliveryCityID, isModifiedCity, setModifiedCity}}> 
                                    <Provider store = {store}>


                                        <Header/>
                                        <RoutesPath/>


                                         <PrimaryModal/>{/* show modal content */}
                                        <Footer/>   
                                    </Provider>
                                </CityContext.Provider>
                            </ModalContext.Provider>
                        </SearchContext.Provider>
                    </TelegramContext.Provider>
                </div>
            </Router>
        </>
    );
}

export default App;

