import React, {useState, useEffect} from 'react';
import s from "./DefaultDisplayProfileSetting.module.css"
import {UPDATE_PROFILE_AVATAR} from '../../../../requests/userRequests';
import {useMutation} from '@apollo/client';
import classes from '../../ProfileHeader/ProfileHeader.module.css';
import {ReactComponent as Camera} from '../../../../assets/svg/camera.svg';


const ImageUploader = ({
                           userID,
                           // photoUrl,
                           photoUrl,
                           // setObjAvatarUrl,
                           setPhotoUrl,
                       }) => {

    // const [photo_url, setPhotoUrl] = useState(localAvatarUrl);
    const [localAvatarUrl, setLocalAvatarUrl] = useState(photoUrl ? photoUrl : '');
    const [updateAvatar, setUpdateAvatar] = useState('');

    console.log('ImageUploader');

    const [updateProfileAvatar] = useMutation(UPDATE_PROFILE_AVATAR,
        {variables: {userID: userID, photo_url: updateAvatar}}
    );


    // function (url) {
    // console.log('updateProfileAvatar');
    // updateProfileAvatar();
    // setPhotoUrl(updateAvatar);
    // }                                        
    // useEffect(() => {
    // if (updateAvatar) {
    // updateProfileAvatar();
    // console.log('updateProfileAvatar  GGGG');
    // setPhotoUrl(updateAvatar);
    // setLocalAvatarUrl(updateAvatar);
    // setLocalAvatarUrl(photoUrl);
    // console.log('setLocalAvatarUrl');
// 
    // }
    // }, [updateAvatar]);


    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            console.log('iMAGE uplader No file selected!');
            return;
        }

        //create url for images src
        setLocalAvatarUrl(URL.createObjectURL(event.target.files[0]))
        setPhotoUrl(URL.createObjectURL(event.target.files[0]))
        //get new file from input
        // setObjAvatarUrl(event.target.files[0]);


        const formData = new FormData();
        formData.append('files', selectedFile); // Make sure to use 'files' instead of 'image'      
        try {
            const response = await fetch(`https://api.jbspace.xyz/files/upload_profile/${userID}`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Image uploaded successfully:', data);
                updateProfileAvatar({
                    variables: {
                        userID: userID,
                        photo_url: data.url
                    }
                });
                setPhotoUrl(data.url);
                setLocalAvatarUrl(data.url);

                // console.log('SSSSSSSSSSSS updateProfileAvatar');
                // setUpdateAvatar(data.url);
                //   return data.url;setPhotoUrl(updateAvatar);

                // Process the response data here
            } else {
                setPhotoUrl(photoUrl)
                throw new Error(`Upload failed with status ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle the error here
        }
    };

    return (
        <div className = {s.avatarContainer}>
            <input
                type = "file"
                accept = "image/*"
                className = {s.newAvatar}
                onChange = {handleFileChange}
            />
            {localAvatarUrl !== '' ? <img className = {s.avatar} src = {localAvatarUrl} alt = "avatar"/> :
                <div className = {classes.noPhoto}><Camera/></div>}

        </div>
    );
};

export default ImageUploader;