import React, {useEffect, useState} from 'react';
import styles from './ProfileReviews.module.css'
import './Tab.css';
import 'react-tabs/style/react-tabs.css';
import UseFetchUserReview from './fetchUserReviews';
import DefaultReviews from './DefaultReviews/DefaultReviews';
import AddNewReview from './AddNewReview/AddNewReview';
import EditReview from './EditReview/EditReview';

export const MODE = {
    default: "default",
    add: "addReview",
    edit: "editReview"
}

const ProfileReviews = () => {

    console.log('profile reviews')

    const [displayMode, setDisplayMode] = useState(MODE.default)
    const [item, setItem] = useState(null)

    const [defaultIndex, setDefaultIndex] = useState(0)

    const {allUserOrderItems, refetch, loading} = UseFetchUserReview()

    const uniqueOrderItems = allUserOrderItems?.map(orderItem => ({
        ...orderItem,
        
        itemComments: orderItem.itemComments.filter(comment => comment.variant_id === orderItem.modifiersID && comment.order_id === orderItem.orderID)
    }))


    const itemWithComments = uniqueOrderItems?.filter(item => item.itemComments.length > 0)
    const itemPendingComments = uniqueOrderItems?.filter(item => item.itemComments.length === 0)

    // console.log(allUserOrderItems)
    //console.log(itemWithComments)
    // console.log(itemPendingComments)
   
    return (
        <div className = {styles.container}>

             {displayMode === MODE.default && 
                <DefaultReviews itemWithComments = {itemWithComments}
                                itemPendingComments = {itemPendingComments}
                                allItemsCount = {allUserOrderItems?.length}
                                setDisplayMode = {setDisplayMode}
                                setItem = {setItem}
                                defaultIndex = {defaultIndex}
                                loading = {loading}
                                refetch = {refetch}
                />}

                
                
            {displayMode === MODE.add && <AddNewReview item = {item}
                                                       refetch = {refetch}
                                                       setDisplayMode = {setDisplayMode}
                                                       setDefaultIndex = {setDefaultIndex}
            />}

            

            {displayMode === MODE.edit && <EditReview item = {item}
                                                      setItem = {setItem}
                                                      refetch = {refetch}
                                                      setDisplayMode = {setDisplayMode}
                                                      setDefaultIndex = {setDefaultIndex}
            />}


        </div>

    )
}

export default ProfileReviews