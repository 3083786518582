import React, {useContext, useMemo} from "react";
import classes from "./MyShops.module.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import { TelegramContext } from "../../../App";
import { useQuery } from "@apollo/client";
import { GET_COMPAY_AND_SHOPS } from "../../../requests/companyRequest";
import Company from "./Company";
import Shops from "./Shops";


function MyShops() {

    // console.log('MyShops')
    const {userID} = useContext(TelegramContext) ;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const oneDayAgoISO = yesterday.toISOString();


    // console.log('oneDayAgoISO', oneDayAgoISO)
    
    
    
    const variables = useMemo(() => ({
        userID,
        oneDayAgo: oneDayAgoISO
    }), [userID])

    const {data, error, loading} = useQuery(GET_COMPAY_AND_SHOPS,{
        fetchPolicy: 'cache-and-network',
        variables: variables,
    }); 

    if (loading) {

        return 'loading...';
    }
    if (error) {
        console.error('liked items error', error);
        return 'error';
    }
    const {shops, company } = {shops: data.shop,
                            company : data.company[0]}

    // console.log('shops', shops)

    return (
        <>
        <h3 className = {classes.header}>МОИ МАГАЗИНЫ</h3>

        <Tabs className = {classes.container}>
            <TabList>

                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Магазины</div>
                </Tab>
                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Компания</div>
                </Tab>
            </TabList>
            <TabPanel>
                <Shops shops={shops}/>          
            </TabPanel>
            <TabPanel>
                <Company company = {company}/>
            </TabPanel>
        </Tabs>
        {/* <div className={classes.line}> </div> */}
        

        </>
    );
}

export default MyShops;