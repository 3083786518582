import React from 'react';
import {useLocation} from 'react-router-dom';
import classes from './GoodsStorage.module.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useQuery } from '@apollo/client';
import { GET_SHOP_ITEMS } from '../../../requests/shopRequests';
import { useNavigate } from 'react-router-dom';
import Goods from './Goods';
import Storage from './Storage';
import ShopLots from '../ShopLots/ShopLots';


function GoodsStorage() {
    const location = useLocation();
    const shopId = location.state.shopId;
    const navigate = useNavigate();



    const {data, error, loading} = useQuery(GET_SHOP_ITEMS,{
        fetchPolicy: 'cache-and-network',
        variables: {shopId},
    }); 

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('get shop items error', error);
        return 'error';
    }
    let  {items } = {items: data.item,}






    return (
        <>
        <h3 className = {classes.header}>ТОВАРЫ И СКЛАДЫ</h3>

        <Tabs className = {classes.container}>
            <TabList>

                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Товары </div>
                </Tab>
                <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Лоты </div>
                </Tab>
                {/* <Tab className = {`${classes.react_tabs_tab} react-tabs__tab`}>
                    <div className = {classes.title}> Склады </div>
                </Tab>
                 */}
            </TabList>
            
            <TabPanel>
                <Goods items={items} shopId={shopId}/>          
            </TabPanel>
            <TabPanel>
                <ShopLots shopId={shopId}/>
            </TabPanel>
            {/* <TabPanel> 
                <Storage shopId={shopId}/>
            </TabPanel>
            */}
        </Tabs>
        </>
    );
}

export default GoodsStorage;