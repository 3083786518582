import React from 'react';
import './BasketImg.module.css'
import {ReactComponent as Icon} from '../../../assets/svg/backet.svg';
import styles from './BasketImg.module.css'

const BasketImg = ({ onClickCallback}) => {

    return (
        <div onClick = {onClickCallback} className = {styles.img}>
            <Icon/>
        </div>)


}
export default BasketImg;
