import React, {useContext, useEffect, useState} from 'react'
import styles from './Lot.module.css'
import LotData from '../LotData/LotData';
import {ProductCardContext} from '../../ProductCard';


const Lot = ({
                
                lot,
                index,
                selectLot,
                setLotID,
            }) => {

    const [isActive, setIsActive] = useState(false)



    useEffect(() => {
        if (index === 0) setLotID(lot.id)
        setIsActive(true)
    }, [])


    const totalLotQuantity = lot.total

    useEffect(() => {
        setIsActive(selectLot === lot.id)
    }, [selectLot])


    const handleChangeActiveLot = (e) => setLotID(+e.currentTarget.value)

    const contentClassName = isActive
        ? `${styles.content} ${styles.active}`
        : styles.content

    return (
        <label >

             <input type = {'radio'} name = {'product-lots'} value = {lot.id} style = {{display: "none"}} 
                    onChange = {handleChangeActiveLot}/>

           <div className = {contentClassName} >
                <div>
                    <div className = {styles.title}>{`Лот № ${index + 1}`}</div>

                    <LotData endDate = {lot.expire_date} 
                                        isActive = {isActive} 
                                        currentItems={lot.ordered_quantity}
                                        totalLotQuantity= {totalLotQuantity}/>

                </div>
                <div className = {styles.priceContainer}>
                    <div className = {styles.price}>{`${lot.price}₽`}</div>
                    <div className = {styles.minQuantity}>{`от ${lot.step} шт.`}</div>
                </div>
            </div>
        </label>)
}

export default Lot