import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const GET_USER_BONUS = `
    query GetUserBonus($userID: bigint) {
        user_bonus(where: {user_id: {_eq: $userID}}) {
            id
            expire_date
            score
            bonus_market_id
            bonus_shop_id
            event_type
            shop_bonu {
              shop {
                name
                photo
                info
              }
            }
        }
    }
`

const makeQuery = async ({ query, variables }) => {
  const res = await axios.post(process.env.REACT_APP_HASURA_URl, {
    query,
    variables,
  }, {
    headers: {
      "content-type": "application/json",
      "x-hasura-admin-secret": process.env.REACT_APP_HASURA_KEY,
    }
  })
  return res.data
}

export const fetchUserBonus = createAsyncThunk(
  'bonus/getUserBonus',
  async (userID) => {
    return makeQuery({
      query: GET_USER_BONUS,
      variables: { userID }
    })
  }
)

const bonusSlice = createSlice({
  name: 'bonus',
  initialState: {
    items: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBonus.fulfilled, (state, action) => {
        const bonus = action?.payload?.data?.user_bonus || []

        state.items = bonus
        state.isLoading = false
      })
      .addCase(fetchUserBonus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserBonus.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

const { reducer } = bonusSlice

export default reducer
