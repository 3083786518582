import styles from './CreateShop.module.css';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useContext } from 'react';
import styleButton  from './CheckButton.module.css';
import ColoredButton from "../ProfileMain/components/ColoredButton/ColoredButton";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {ADD_NEW_SHOP, COMPANY_ID_BY_USER} from '../../../requests/shopRequests'
import { TelegramContext } from '../../../App';
import { redirect, useNavigate } from 'react-router-dom';
import { sendBotMessage } from '../../../utils/apiCalls/botApi';
// import defaultImage from '../../../assets/png/camera.png';







function DealButton ({dealValue, setValue}) {
    const dealButtons = ["Продавец товаров", "Продавец услуг", "Бренд"];
  
   
    return (
      <div className={styles.buttonContainer}>
        {dealButtons.map((dealButton, i) => (
          <button
            type="button"
            className={`${styles.dealButton} ${
              dealValue === dealButton ? styles.active : ""
            }`}
            key={dealButton}
            onClick={() => setValue(dealButton)}
          >
            {dealButton}
          </button>
        ))}
      </div>
    );
  };


  const checkButtonDisplayTypes = {
    default: "DEFAULT", inCheck: "IN_CHECK", success: "SUCCESS", error: "ERROR"
}

function CheckButton({bic, callback, checkButtonStyle}){

    const getTitles = () => {
        switch (checkButtonStyle) {
            case checkButtonDisplayTypes.default:
                return "Найти"
            case checkButtonDisplayTypes.inCheck:
                return "Проверка..."
            case checkButtonDisplayTypes.success:
                return "Haйдено"
            case checkButtonDisplayTypes.error:
                return "Не найдено"
            default:
                return "Найти"
        }
    }

    const getClassNames = () => {
        switch (checkButtonStyle) {
            case checkButtonDisplayTypes.default:
                return `${styleButton.applyButton} ${styleButton.check}`
            case checkButtonDisplayTypes.inCheck:
                return `${styleButton.applyButton} ${styleButton.check}`
            case checkButtonDisplayTypes.success:
                return `${styleButton.applyButton} ${styleButton.valid}`
            case checkButtonDisplayTypes.error:
                return `${styleButton.applyButton} ${styleButton.error}`
            default:
                return `${styleButton.applyButton} ${styleButton.check}`
        }
    }

    const title = getTitles()
    const style = getClassNames()


    return (<button className={style}   
                disabled = {bic.length <= 8  && bic.length >= 13} 
            onClick = { callback}
            >{title}</button> )
}



  


export default function CreateShop() {
    const {userID} = useContext(TelegramContext)

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
        setValue,
    } = useForm({
        defaultValues: {
            avatar: '',
            name: '',
            slogan: '',
            info: '',
            bic: '',
            bank_accaount: '',
            bank_name: '',
            corespondent_account: '',

        },
    });
    const [dealValue, setDealValue] = useState(null);
    const [localAvatarUrl, setLocalAvatarUrl] = useState('');
    const [checkButtonStyle, setCheckButtonDisplayTypes] = useState(checkButtonDisplayTypes.default)
    const [checked, setChecked] = useState(false);
    const [mutationError, setMutationError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [avatarError, setAvatarError] = useState(null);



    const [get_company_id] = useLazyQuery(COMPANY_ID_BY_USER )


    const bic= watch("bic")

    
 
    // useEffect(() => {

    //     console.log(checkButtonStyle);  
    // }, [checkButtonStyle]);

    const checkBic = () => {
        const url = `https://api.jbspace.xyz/check_company/v1/bic_${bic}`
        // const url =`http://127.0.0.1:8000/check_company/v1/bic_${bic}` 
    
        async function fetchData() {
            // console.log('fetchData')
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const data = await response.json();
                if(data){
                    setCheckButtonDisplayTypes(checkButtonDisplayTypes.success)
                    data && setValue("bank_name", data.name)
                    data && setValue('corespondent_account', data.ks)
                    // console.log('data', data)
                }
                else {
                    setCheckButtonDisplayTypes(checkButtonDisplayTypes.error)
                    setErrorMessage("попробуйте исправить и отправьте еще раз" );
                }
                setChecked(true)

            } catch (error) {
                // console.log('error', error);
                setErrorMessage("Error occurred while fetching data." );
                setChecked(true)

    
            }
        }
        setCheckButtonDisplayTypes(checkButtonDisplayTypes.inCheck)
        fetchData();
    }


    // set default button if after check user will change iNN
    useEffect (() => {
        if (checked) {
            setCheckButtonDisplayTypes(checkButtonDisplayTypes.default)
            setChecked(false)
            setValue('bank_name', '')
            setValue('corespondent_account', '')
            setErrorMessage(null)

        }
    }, [bic])


  
    


    const handleFileChange = async (event) => {
        setAvatarError(null)
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            // console.log('iMAGE uplader No file selected!');
            return;
        }
        //create url for images src
        setLocalAvatarUrl(URL.createObjectURL(event.target.files[0]))
      
        const url  = `https://api.jbspace.xyz/files/shop_photo`
        // const url  =  `http://127.0.0.1:8080/files/shop_photo`

        const formData = new FormData();
        formData.append('files', selectedFile);      
        try {
            const response = await fetch(url,
             {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                // console.log('Image uploaded successfully:', data);
              
                setLocalAvatarUrl(data.url[0]);
            } else {
                // setPhotoUrl(photoUrl)
                setLocalAvatarUrl(null)
                // console.log(response)
                setAvatarError('не получилось загрузить фото,  попробуйте еще раз')

                throw new Error(`Upload failed with status ${response.status}`);

            }
        } catch (error) {
            setAvatarError('не получилось загрузить фото,  попробуйте еще раз')
            setLocalAvatarUrl(null)


            console.error('Error:', error);
            // Handle the error here
        }
    };


    const [addNewShop, { data }] = useMutation(ADD_NEW_SHOP);

    const onSubmit =async(data) => {
        // console.log(data);
        // console.log('localAvatr', localAvatarUrl)
    try {
        // let formData = getValues()
        const nameDeal = {"Продавец товаров" :  "seller",  "Продавец услуг" : "service", "Бренд" : "brand"}
        let dealSystem = nameDeal[dealValue]


         const { data: company } = await get_company_id({
            variables: {
                userID
            }
        })

        console.log('company', company)
        
        const companyId = company.company[0].id 
        console.log('company', companyId)

        if (!companyId) {
            setMutationError("Ошибка при получении данных! Пробуйте еще раз");
            return
        }
        

        const { data: mutationResult } = await addNewShop({
            variables: {
                
                userID,
                companyId : companyId,
                name: data.name,
                slogan: data.slogan,
                info: data.info,
                bic: data.bic,
                bank_account: String(data.bank_accaount),   
                bank_title: data.bank_name,
                corespondent_account: String(data.corespondent_account),
                photo: localAvatarUrl,
                type_of_business: dealSystem,
                }

        }

    );

    if (mutationResult) {
        navigate('/profile/my_shops')
        await sendBotMessage(`Создание магазина ${data.name} ${data.info} ${data.slogan} ${data.bic} ${data.bank_accaount} ${data.bank_name} ${data.corespondent_account} ${localAvatarUrl} ${dealSystem}`)

    //    setSuccessRegistration(true) 
      // setDisplayType(displaySellerRegistrationsTypes.success)

    }
    } catch (error) {
        await sendBotMessage(`ошибка при создании магазина ${error.message}`)
        setMutationError("Ошибка при добавление данных! Пробуйте еще раз");
        console.log('error on mutation', error);
    }
  };
       

    

    return (
        <>
        <div className={styles.wrapper}>
            <div className={styles.titleContainer}>
                <h3 className={styles.title}>Новый магазин</h3>
                <span className={styles.text}>
                    Нажимая кнопку “Открыть магазин!” Вы принимаете{' '}
                    <span className={styles.link}>условия работы магазина </span> и{' '}
                    <span className={styles.link}>условия взаиморасчетов</span>
                </span>
            </div>

         <form className={styles.form}> 
            <div className={styles.container} >
            <div className={styles.avatarContainer}>
            <input
                type = "file"
                accept = "image/*"
                className = {styles.newAvatar}
                onChange = {handleFileChange}
                />
            {localAvatarUrl && <img src={localAvatarUrl} alt="Avatar" className={styles.avatar} />}

        </div>
        {avatarError && <span style = {{color: "red"}}>{avatarError}  </span>}
            </div>
                <label className={styles.label}> Название магазина: </label>
                <input
                    className={styles.registeredInput}
                    type="text"
                    placeholder="Обязательно"
                    {...register('name', { required: true })}
                />

                <div style={{ height: '2vh' }} />

                <label className={styles.label}> Слоган магазина: </label>
                <input
                    className={styles.registeredInput}
                    type="text"
                    placeholder="Hе обязательно"
                    {...register('slogan')}
                />

                <div style={{ height: '2vh' }} />
                
                <label className={styles.label}> Информация о магазине: </label>

                <input
                    className={styles.registeredInput}
                    type="text"
                    placeholder="Обязательно"
                    {...register('info', { required: true })}
                />
                <div style={{ height: '2vh' }} />
                <label className={styles.label}> Основной вид деятельности: </label>
                <DealButton dealValue = {dealValue} setValue = {setDealValue}/>
            </form> 

             <form className = {styles.form} onSubmit={handleSubmit(onSubmit)}  > 

                <p className={styles.bicText}>
                    Введите БИК вашего банка, а остальное найдем мы! 
                </p>
                <p className={styles.smalText}>Если поиск не удачный, 
                    то напишите нам в <span className={styles.link}>поддержку</span></p>
                
                <label className={styles.label}> БИК банка: </label>
                <div className = {styles.bicContainer}>
                    <input className = {styles.registeredInput}
                             type="number" 
                             placeholder="Введите БИК банка" {...register("bic", 
                                    { required: true , minLength : 8 },)} 
                                    />
                    <CheckButton bic ={bic}  
                                callback = {checkBic}
                                checkButtonStyle = {checkButtonStyle}
                                />
                    
                </div>
                {errorMessage && <span style = {{color: "red"}}>{errorMessage}  </span>}
                
                <div style={{ height: '2vh' }} />
                <label className={styles.label}> Рассчетный счет: </label>
                <input
                    className={styles.registeredInput}
                    type="text"
                    placeholder="Введите номер расчетного счета"
                    {...register('bank_accaount', { required: true , minLength : 20})}
                />

                <div style={{ height: '2vh' }} />
                <label className={styles.label}> Наименование банка: </label>
                <textarea
                    className={styles.registeredInputBig}
                    placeholder="Заполнится автоматически"
                    readOnly ={true}
                    {...register('bank_name', { required: true })}
                />


                <div style={{ height: '2vh' }} />
                <label className={styles.label}> Корреспондентский счет: </label>
                <input
                    className={styles.registeredInput}
                    type="text"
                    readOnly ={true}
                    placeholder="Заполнится автоматически"
                    {...register('corespondent_account', { required: false })}
                />
                <div style={{ height: '2vh' }} />
                {mutationError && <span style = {{color: "red"}}>{mutationError}  </span>}  

                <ColoredButton name = {'Открыть магазин!'} disabled={!isValid || !dealValue} callback = {handleSubmit}/>

             </form> 

        </div>
        </>
    );
}


// export default CreateShop;