import styles from './EditReview.module.css'
import React, {useContext, useState} from 'react';
import Separator from '../../../../Сomponents/Separator/Separator';
import ProfileInput from '../../ProfileMain/components/ProfileInput/ProfileInput';
import ProfileTextarea from '../../ProfileTextarea/ProfileTextarea';
import ItemCheckbox from '../../../CartPage/ItemCheckbox';
import ApplyButton from '../../ApplyButton/ApplyButton';
import {ModalContext, TelegramContext} from '../../../../App';
import {useMutation} from '@apollo/client';
import {EDIT_REVIEW} from '../../../../requests/commentUserRequests';
import {MODE} from '../index';
import {modalDisplayMode} from '../../../PrimaryModal/PrimaryModal';
import {text} from '../PublishingRulesText';
import EditReviewItem from './EditReviewItem';
import AddReviewImages from '../AddReviewImages/AddReviewImages';


export default function EditReview({
                                       item,
                                       setItem,
                                       refetch,
                                       setDisplayMode,
                                       setDefaultIndex
                                   }) {
    console.log(item)
    const initImages = item.itemComments[0].images_comments.map(img => ({
        file: null,
        localUrl: img.url,
        imgUrl: img.url,
        fileName: img.url.slice(42),
        id: img.id
    }))


    const {userID} = useContext(TelegramContext)
    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const [advantages, setAdvantages] = useState(item.itemComments[0].advantages || '')
    const [disAdvantages, setDisAdvantages] = useState(item.itemComments[0].disadvantages || '')
    const [comment, setComment] = useState(item.itemComments[0].text || '')
    const [isAnonymous, setIsAnonymous] = useState(item.itemComments[0].anonim)

    const [isLoading, setIsLoading] = useState(false)

    const [images, setImages] = useState(initImages)
    console.log(images)
    const [video, setVideo] = useState({
        file: null,
        localUrl: '',
        imgUrl: '',
        fileName: null
    })

    //for button
    const [isEditedReview, setIsEditedNewReview] = useState(false);

    const [editReview] = useMutation(EDIT_REVIEW);

    const addNewAdvantages = (value) => setAdvantages(value)
    const addNewDisAdvantages = (value) => setDisAdvantages(value)
    const addNewComment = (value) => setComment(value)

    const anonymousToggle = () => setIsAnonymous(!isAnonymous)

    //go to default mode with completed reviews
    const setActiveReviewsTab = (value) => {
        setDefaultIndex(value)
        setDisplayMode(MODE.default)
    }

    const localModalContent = <div className = {styles.localModalContent}>{text}</div>

    const openPublishingRules = () => {
        setModalContent(localModalContent);
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
    }


    const editReviewHandler = async () => {
        const commentID = +item.itemComments[0].id
        const imagesObjects = images.filter(img => !img.id).map(img => ({
            comment_id: commentID,
            file_name: img.fileName,
            url: img.imgUrl
        }))
        const {data, error} = await editReview({
            variables: {
                id: commentID,
                estimate: item.ratingValue || item.itemComments[0].estimate,
                disadvantages: disAdvantages,
                text: comment,
                advantages: advantages,
                anonim: isAnonymous,
                objects: imagesObjects
            }
        })

        if (data) {
             console.log('dddddddddddddddddddddddddddddddddddddddddd',data)
            setIsEditedNewReview(true)
            refetch();
        }
        if (error) setDisplayMode(MODE.default)
    }

    return (<div className = {styles.editReviewContainer}>

        <EditReviewItem item = {item} setItem = {setItem}/>
        <h4 className = {styles.optionalText}>Если вас устроила покупка,
                                              можете поставить только оценку товара.
                                              Остальные&nbsp;поля&nbsp;не&nbsp;обязательны.</h4>
        <Separator/>


        <div>
            Добавьте видео
        </div>

        <div className = {styles.imageContainer}>
            <p>Добавьте до 10 фотографий</p>
            <AddReviewImages
                images = {images}
                setImages = {setImages}
                setIsLoading = {setIsLoading}
            />
        </div>

        <div className = {styles.inputContainer}>

            <ProfileInput placeholder = {"Достоинства"}
                          callback = {addNewAdvantages}
                          value = {advantages}/>

            <ProfileInput placeholder = {"Недостатки"}
                          callback = {addNewDisAdvantages}
                          value = {disAdvantages}
            />

            <ProfileTextarea placeholder = {"Комментарий"}
                             callback = {addNewComment}
                             value = {comment}
            />
        </div>


        <div className = {styles.unknownComment}>
            <p>Анонимный отзыв</p>
            <ItemCheckbox isChecked = {isAnonymous} onClick = {anonymousToggle}/>
        </div>

        <div className = {styles.applyButtonContainer}>
            <ApplyButton name = {"Изменить отзыв"}
                         callback = {editReviewHandler}
                         isEditedReview = {isEditedReview}
                         setActiveReviewsTab = {setActiveReviewsTab}
                         disabled = {isLoading}
            />
        </div>


        <div className = {styles.linkContainer}>
            <span className = {styles.linkText}> Нажимая на кнопку, вы принимаете</span>
            <span className = {styles.link} onClick = {openPublishingRules}>правила публикации</span>
        </div>
        <div style = {{height: "60px"}}></div>
    </div>);
}