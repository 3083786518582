import React from 'react';
import style from "./SingleContainer.module.css"
import link from '../../../assets/svg/link.svg';

function SingleContainer({title, callback, count}) {
    // console.log('function  SingleContainer from ProfileMain')
    // console.log(title, callback, count)
    // console.log(count)

    return (

        <div className = {style.wrapper} onClick = {() => {
            callback()
        }}>
            <span style = {{flexGrow: "grow"}} className = {style.title}>{title} </span>
            <div className = {style.content}>

                <span style = {{paddingRight: "20px"}}>  {count ? count : ""}</span>

                <img src = {link} alt = ""/>
            </div>

        </div>
    );
}

export default SingleContainer;