import React from 'react';
import SingleContainer from '../SingleContainer/SingleContainer';
import {useNavigate} from 'react-router-dom';


function ProfileShop({company}) {

    const navigate = useNavigate()
    //console.log(cityName)

    //if company exist
    // const signUpSellerProfileCallback = () => {
        // navigate("/profile/seller_registration", {state: {cityName: cityName}})
    // }

    const signUpSellerProfileCallback = () => {
        navigate("/profile/company_registration")
    }

    const goToMyShopsCallback = () => {
        navigate("/profile/my_shops")
        // navigate("/profile/seller_shops", {state: {userShop: userShop, cityName: cityName}})
    }

    return (<>
        {company.length > 0 ?
            <SingleContainer title = {"МОИ МАГАЗИНЫ"} callback = {goToMyShopsCallback}/> :
            <SingleContainer title = {"СТАТЬ ПРОДАВЦОМ"} callback = {signUpSellerProfileCallback}/>}
    </>);
}

export default ProfileShop;