import React, {useContext, useEffect, useState} from 'react';
import {GET_ITEMS, GET_SEARCH_ITEMS} from '../../requests/itemRequests';
import {SearchContext, TelegramContext} from '../../App';
import LoadItemsData from './LoadItemsData';

const MainItems = ({limit}) => {
    // here we choose which query to use for loading items
    // depending on the search value

    // console.count('MainItems')

    const {userID} = useContext(TelegramContext);
    const {searchValue, setSearchValue} = useContext(SearchContext)
    const [query, setQuery] = useState(GET_ITEMS);
    const [variables, setVariables] = useState({userID, offset: 0, limit});
    const [keyName, setKeyName] = useState("item");
    const [aggregateKeyName, setAggregateKeyName] = useState("item_aggregate");

    const dateNow = new Date()



    useEffect(() => {
        if (searchValue !== "") {
            setQuery(GET_SEARCH_ITEMS)
            setVariables({like: searchValue, offset: 0, limit, dateNow, userID })
            setKeyName("search_item")
            setAggregateKeyName("search_item_aggregate")
        } else {
            setQuery(GET_ITEMS)
            setVariables({offset: 0, limit, dateNow, userID})
            setKeyName("item")
            setAggregateKeyName("item_aggregate")
        }

    }, [searchValue])

    const onClickResetSearchHandler = () => {
        if (searchValue !== "") setSearchValue('')
    }

    return <LoadItemsData limit = {limit} query = {query} variables = {variables} keyName = {keyName}
                          aggregateKeyName = {aggregateKeyName} isHorizontal = {false}
                          onClickCallback = {onClickResetSearchHandler}/>

}

export default React.memo(MainItems)


