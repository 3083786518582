import React, {useState, useContext} from 'react';
import styled from './PaymentCompany.module.css'

// import arrowIcon from '../../assets/svg/arrow.svg'
// import {useMutation} from "@apollo/client";
// import {ADD_COMPANY} from "../../requests/userRequests";
import {useLocation} from "react-router-dom";

import { useForm} from "react-hook-form";
import {useEffect} from "react";
// import {SuccessRegistration} from "../SuccessRegistration";
import {TelegramContext} from "../../App";
import CartAddress from "../UserAddresses/cartAddress";
import arrowIcon from '../../assets/svg/arrow.svg'
// import {TaxButton} from "./TaxButton";
// import {ColoredButton} from "../../Components/ColoredButton";
// import {CheckButton, checkButtonDisplayTypes} from "./CheckButton";

import {useNavigate} from "react-router-dom";
import { generatePDFApi } from '../../utils/apiCalls/pdfApi';

import {modalDisplayMode} from '../PrimaryModal/PrimaryModal';
import { companySellerRules } from '../../utils/docs/companySellerRules';

import {ModalContext} from '../../App';


function ColoredButton({name, disabled, callback, type}) {
    return (
        <button className = {styled.coloredButton} disabled = {disabled} type = {type} onClick = {callback}>
            {name}
        </button>
    );
}

const checkButtonDisplayTypes = {
    default: "DEFAULT", inCheck: "IN_CHECK", success: "SUCCESS", error: "ERROR"
}


function CheckButton({iNN = '', bic = '', callback, checkButtonStyle}){
    console.log('CheckButton', iNN)
    console.log('CheckButton', bic)

    const getTitles = () => {
        switch (checkButtonStyle) {
            case checkButtonDisplayTypes.default:
                return "Найти"
            case checkButtonDisplayTypes.inCheck:
                return "Проверка..."
            case checkButtonDisplayTypes.success:
                return "Haйдено"
            case checkButtonDisplayTypes.error:
                return "Не найдено"
            default:
                return "Найти"
        }
    }

    const getClassNames = () => {
        switch (checkButtonStyle) {
            case checkButtonDisplayTypes.default:
                return `${styled.applyButton} ${styled.check}`
            case checkButtonDisplayTypes.inCheck:
                return `${styled.applyButton} ${styled.check}`
            case checkButtonDisplayTypes.success:
                return `${styled.applyButton} ${styled.valid}`
            case checkButtonDisplayTypes.error:
                return `${styled.applyButton} ${styled.error}`
            default:
                return `${styled.applyButton} ${styled.check}`
        }
    }

    const title = getTitles()
    const style = getClassNames()


    return (
        iNN ? (
          <button 
            className={style}   
            disabled={iNN.length <= 9 || iNN.length >= 13} 
            onClick={callback}
          >
            {title}
          </button>
        ) : (
          <button 
            className={style}   
            disabled={bic.length <= 6}
            onClick={callback}
          >
            {title}
          </button>
        )
      );
}




function PaymentCompany() {

    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const [successRegistration, setSuccessRegistration] = useState(false)
    
    const [checkButtonStyleInn, setCheckButtonDisplayTypesInn] = useState(checkButtonDisplayTypes.default)
    const [checkButtonStyleBic, setCheckButtonDisplayTypesBic] = useState(checkButtonDisplayTypes.default)
    const [onErrorMessage, setOnErrorMessage] = useState(false);
    const [checkedInn, setCheckedInn] = useState(false);
    const [checkedBic, setCheckedBic] = useState(false);
    const [taxValue, setTaxValue] = useState(null)
    const [mutationError, setMutationError] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    console.log('location', location.state)


    const {userID} = useContext(TelegramContext);


    const {
        register,
        handleSubmit,   
        watch,
        formState: { errors, isValid},
        getValues,
        setValue,
      } = useForm({
        defaultValues : {
            inn : '',
            kpp : '',
            ogrn : '',
            name : '',
            address : '',
            bic: '',
            bank_account: '',
            bank_name: '',
            correspondent_account: '',
            phoneNumber: '',
            email: '',

         

        }
      })


    
    
    // useEffect(() => {
    //   dispatch(fetchOrderItems({ orderID: orderId }))
    // }, [orderId, dispatch])

    


    //ADD_SHOP
    // const [generatePDF, { data }] = useMutation(ADD_COMPANY);

    const onSubmit =async() => {
        setLoading(true)
        
        let formData = getValues()
        formData.orderId = location.state.orderId
        formData.cartIds = location.state.cartIds
        console.log(formData)
        generatePDFApi(formData)

        .then((response) => {
            if(response.status === 200){
                setSuccessRegistration(true)
            }
            
        })       
        .catch((error) => { 
            setMutationError("An error occurred during submission.");
            console.log('error', error)
        })
        .finally(() => {
            setLoading(false)
        })
  };


     



    const iNN = watch("inn") //544507337833
    useEffect (() => {
        if (checkedInn) {
            setCheckButtonDisplayTypesInn(checkButtonDisplayTypes.default)
            setCheckedInn(false)
            setValue('ogrn' , '',)
            setValue('kpp' , '',)
            setValue('name' , '',)
            setValue('address' , '',)
            setFetchError(false)
            setOnErrorMessage(false)
        }
    }, [iNN])



    const bic= watch("bic") //044525593
    useEffect (() => {
        if (checkedBic) {
            setCheckButtonDisplayTypesBic(checkButtonDisplayTypes.default)
            setCheckedBic(false)
            setValue('bank_name', '')
            setValue('correspondent_account', '')
            setErrorMessage(null)

        }
    }, [bic])



    
    useEffect(() => {
        console.log('isValid', isValid)
        if (isValid ) {
            setDisabled(false)
        }else {
            setDisabled(true)
        }
    }, [isValid])
    
    
    



    const checkInn = () => {
        const url = `https://api.jbspace.xyz/check_company/v1/inn_${iNN}`
        // const url =`http://127.0.0.1:8000/check_company/v1/inn_${iNN}`
    
        async function fetchData() {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const data = await response.json();
                // console.log('data', data)
                if(data){

                    setCheckButtonDisplayTypesInn(checkButtonDisplayTypes.success)
                    data && setValue("kpp", data.kpp)
                    data && setValue("ogrn", data.ogrn)
                    data && setValue("name", data.name)
                    data && setValue("address", data.address) 
                }
                else {
                    setCheckButtonDisplayTypesInn(checkButtonDisplayTypes.error)
                }
                setCheckedInn(true)

            } catch (error) {
                setCheckButtonDisplayTypesInn(checkButtonDisplayTypes.error)

                setFetchError("Error occurred while fetching data.");
                console.log('error', error);
                setOnErrorMessage(true);
                setCheckedInn(true)

    
            }
        }
        setCheckButtonDisplayTypesInn(checkButtonDisplayTypes.inCheck)
        fetchData();
    }
    


    const checkBic = () => {
        const url = `https://api.jbspace.xyz/check_company/v1/bic_${bic}`
        // const url =`http://127.0.0.1:8000/check_company/v1/bic_${bic}` 
    
        async function fetchData() {
            // console.log('fetchData')
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const data = await response.json();
                if(data){
                    setCheckButtonDisplayTypesBic(checkButtonDisplayTypes.success)
                    data && setValue("bank_name", data.name)
                    data && setValue('correspondent_account', data.ks)
                    // console.log('data', data)
                }
                else {
                    setCheckButtonDisplayTypesBic(checkButtonDisplayTypes.error)
                    setErrorMessage("попробуйте исправить и отправьте еще раз" );
                }
                setCheckedBic(true)

            } catch (error) {
                // console.log('error', error);
                setErrorMessage("Error occurred while fetching data." );
                setCheckedBic(true)

    
            }
        }
        setCheckButtonDisplayTypesBic(checkButtonDisplayTypes.inCheck)
        fetchData();
    }

  
    if(successRegistration){
        
        setTimeout(() => {
            navigate ('/cart')
        }, 3000)
        return (
            <div>
                <h1> Платежное поручение было отправлено </h1>
            </div>
        )
    }
    
    const publicRules = () => {
        const localModalContent = <div className = {styled.localModalContent}>{companySellerRules}</div>

        setModalContent(localModalContent);
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
    }
    


    return (
        

        
        <div className={styled.wrapper}>
            <div className={styled.titleContainer}>
                <h3 className={styled.title}>Заявка на получение счета для Юр.Лиц</h3>
                <span className={styled.text}>
                    нажимая кнопку "выставить счет" Вы принимаете <span
                        className={styled.link}  onClick = {publicRules} >публичную оферту ПСП </span> и <span
                        className={styled.link} style ={{'color' : '#B5B5B5'}}>условия пользования площадки ПСП</span>
                </span>
            </div>

            {mutationError && <span style = {{color: "red"}}>{mutationError}</span>}
            {fetchError && <span style = {{color: "red"}} className={styled.error}>{fetchError}</span>}

            
            <form className = {styled.form} onSubmit={handleSubmit(onSubmit)}  >
                <p className={styled.innText}>
                    Введите ИНН
                </p>
                <p className={styled.smalText}>Если поиск не удачный, 
                    то напишите нам в <span className={styled.link}>поддержку</span></p>
                
                <label className={styled.label}>ИНН: </label>
                <div className = {styled.container}>
                    <input className = {styled.registeredInput}
                             type="number" 
                             placeholder="ИНН" {...register("inn", 
                                    { required: true , minLength : 9 , maxLength : 13},)} 
                                    />
                    <CheckButton iNN ={iNN}  
                                callback = {checkInn}
                                checkButtonStyle = {checkButtonStyleInn}
                                />
                </div>

                <div style = {{height: "2vh"}}>
                    {errors.inn && <span style = {{color: "red"}}>Пожалуйста, введите корректный ИНН </span>}
                </div>
                     {onErrorMessage && <span style = {{color: "red"}}>возможно ошибка сети, попробуйте еще раз  </span>}
                
                <div className={styled.inputContainer}>
                <label className={styled.label}>КПП: </label>
                <label className={styled.label}>ОГРН(ОГРНИП): </label>
                </div>
                <div className = {styled.container}>
                    
                    <input className = {styled.registeredInput} 
                        placeholder="Заполнится автоматически" 
                        readOnly = {true}
                        {...register("kpp")} />
                    <input className = {styled.registeredInput}
                        placeholder="Заполнится автоматически" 
                        readOnly = {true}
                        {...register("ogrn", {required : true})} />                 
                </div>
                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Наименование юридического лица: </label>
                <textarea  className = {styled.registeredInputBig}
                    type = "textarea"
                    readOnly = {true}
                     placeholder="Заполнится автоматически" 
                    {...register("name",{required : true})} />

                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Юридический адрес: </label>
                <textarea  className = {styled.registeredInputBig} 
                    placeholder="Заполнится автоматически"
                    readOnly = {true}
                    {...register("address", {required : true})} />
                <div style = {{height: "2vh"}}/>
                <label className={styled.label}>БИК банка: </label>
                <div className = {styled.container}>
                    <input className = {styled.registeredInput}
                             type="number" 
                             placeholder="Введите БИК банка" {...register("bic", 
                                    { required: true , minLength : 6 },)} 
                                    />
                    <CheckButton bic ={bic}  
                                callback = {checkBic}
                                checkButtonStyle = {checkButtonStyleBic}
                                />
                </div>
                {errorMessage && <span style = {{color: "red"}}>{errorMessage}  </span>}

                
                <div style={{ height: '2vh' }} />
                <label className={styled.label}> Наименование банка: </label>
                <textarea
                    className={styled.registeredInputBig}
                    placeholder="Заполнится автоматически"
                    readOnly ={true}
                    {...register('bank_name', { required: true })}
                />


                <div style={{ height: '2vh' }} />
                <label className={styled.label}> Корреспондентский счет: </label>
                <input
                    className={styled.registeredInput}
                    type="text"
                    readOnly ={true}
                    placeholder="Заполнится автоматически"
                    {...register('correspondent_account', { required: false })}
                />

                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Рассчетный счет: </label>
                <input
                    className={styled.registeredInput}
                    type="text"
                    placeholder="Введите номер расчетного счета"
                    {...register('bank_account', { required: true , minLength : 20})}
                />

                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> E-mail: </label>
                <input
                    className={styled.registeredInput}
                    type="text"
                    placeholder="e-mail"
                    {...register('email', { required: true , minLength : 5})}
                />

                <div style = {{height: "2vh"}}/>
                <label className={styled.label}> Телефон: </label>
                <input
                    className={styled.registeredInput}
                    type="text"
                    placeholder="Номер телефона"
                    {...register('phoneNumber', { required: true , minLength : 7})}
                />

                {/* <label className={styled.label}> Система налогооблажения: </label> */}

                {/* <TaxButton taxValue = {taxValue} setValue = {setTaxValue}/> */}

                 <div className={styled.addressSelection}>
                   <div className={styled.addressText}>
                     <span className={styled.addressTitle}>Адрес доставки:</span>
                     <span className={styled.addressSelect}>
                       <CartAddress />
                     </span>
                   </div>
                   <img src={arrowIcon} alt="Arrow"/>
                 </div>


                 <div style={{ height: '2vh' }} />
                <label className={styled.label}> Коментарий: </label>
                <textarea
                    className={styled.registeredInputBig}
                    placeholder="Коментарий к заказу"
                    {...register('comment', { required: false })}
                />

                 <div style = {{height: "2vh"}}/>



                <div className={styled.totalSection}>
                    <span className={styled.totalHeader}>Итог:</span>
                    <div className={styled.totalCosts}>
                      <div className={styled.totalItems}>
                        <div className={styled.totalItemsCount}>
                          <span className={styled.totalItemsDiff}>Наименований: {99999}шт</span>
                          <span className={styled.totalItemsAll}>Количество товаров: {99999}шт</span>
                        </div>
                        <span className={styled.totalItemsPrice}>{9999} &#8381;</span>
                      </div>
                     
                      <div className={styled.totalDivider} />
                      <div className={styled.finalCost}>
                        <span className={styled.finalCostTitle}>Общая стоимость:</span>
                        <span className={styled.finalCostSum}>{99999} &#8381;</span>
                      </div>
                    </div>
                </div>
                {
                    loading ? <ColoredButton name = "Обрабатываем запрос" disabled={true} /> : 
                    <ColoredButton name = {'Выставить счет'} disabled={disabled} callback = {handleSubmit}/>
                
                }
            </form>

            

        </div>
        
    )
    }
      

  


export default PaymentCompany;