import React, {useContext} from 'react';
import {useQuery} from '@apollo/client';
import {WATCHED_ITEMS} from '../../requests/itemRequests';
import {TelegramContext} from '../../App';
import DisplayItems from '../StandartGoodsDisplay/DisplayItems';
import classes from './watched.module.css';

export default function Watched() {
    const {userID} = useContext(TelegramContext) || 534677865;

    const {data, error, loading} = useQuery(WATCHED_ITEMS, {
        fetchPolicy: 'cache-and-network',
        variables: {userID},
    });

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('watched items error', error);
        return 'error';
    }

    return (
        <>
            <h3 className = {classes.header}>ВЫ СМОТРЕЛИ</h3>
            <DisplayItems data = {data} 
                        
                />
        </>
    );
}