// import React, {useContext, useEffect, useState} from 'react';
import classes from './OnePersonReview.module.css';
import StarRating from 'react-svg-star-rating';
// import reviewPhoto from '../../../../assets/jpg/img2.jpg';
// import attention from '../../../../assets/svg/attention.svg';
// import {ModalContext} from '../../AllReviews/AllReviews';
// import {IsAcviveModalContext} from '../../AllReviews/AllReviews';
import getupdatedDate from '../../../../utils/getupdatedDate';
// import {useMutation, useQuery} from '@apollo/client';
// import {ADD_USEFUL_COMMENT, CHECK_USEFUL_COMMENT, PRODUCT_QUERY} from '../../../../requests/commentRequests';
// import { TelegramContext } from '../../../../App';


    export default function OnePersonReview({oneReviewData}) {

        // console.log('oneReviewData', oneReviewData);

    // const {setModalContent} = useContext(ModalContext);
    // const {setModalActive} = useContext(IsAcviveModalContext);

    // const {userID}  = useContext(TelegramContext);
 
    // const [isHelpful, setHelpful] = useState(true);
    // const [answer, setAnswer] = useState(false);
    // let usefulness = 0;

    const advantages = oneReviewData?.advantages ;
    const disadvantages = oneReviewData?.disadvantages;
    const text = oneReviewData?.text ;
    const estimate = oneReviewData.estimate ;
    const userFullName = oneReviewData?.user?.full_name ;
    const anonim = oneReviewData.anonim;

    const variant = oneReviewData?.item_variant


    //? if(!advantages && !disadvantages && !text) {
        // return null;
    // }   

  



    
    // const [addUsefulComment] = useMutation(ADD_USEFUL_COMMENT, {
    //     variables: {comment_id, answer, usefulness, userID},
    // });
    // const {loading, error, data} = useQuery(CHECK_USEFUL_COMMENT, {
    //     variables: {comment_id, userID}, fetchPolicy: 'cache-and-network',
    // });

    // if (loading) {
    //     return <div>Loading...</div>;
    // }
    // if (error) {
    //     console.error(error);
    //     return <div>Error!</div>;
    // }
    // let isUseful;
    // let userFullName = '' ;
    // if (data) {
    //     isUseful = data.comment_likes.length;
    //     userFullName = data.user?.full_name;
    // }

    // console.log('oeReviewData', oneReviewData.images_comments);



    return (<div className = {classes.onePersonReview}>
                    <div className = {classes.alignContainer}>
                        <div>
                      <span className = {classes.usersLetterContainer}>
                        {/* <span className = {classes.usersLetter}>{userFullName}</span> */}
                        </span>
                            {
                                anonim ?
                                <span className = {classes.userName}>{userFullName}</span> :
                                    <span className = {classes.userName}>Анонимный отзыв</span>
                            }
                            
                        </div>
                        <div className = {classes.alignItem}>
                            <StarRating
                                isReadOnly
                                initialRating = {estimate}
                                unit = "float"
                                containerClassName = {classes.starContainer}
                                starClassName = {classes.star}
                            />
                            <div className = {classes.date}>{getupdatedDate(oneReviewData.updated_on)}</div>
                        </div>
                    </div>
            {
                variant.purposeName && (
                    <div>
                        <span className = {classes.productPropertyName}>{variant.purposeName}</span>
                        <span className = {classes.productProperty}>&nbsp;{variant.purposeValue}</span>
                    </div>
                )
            }
            {
                variant.purpoesName2 && (
                    <div>
                        <span className = {classes.productPropertyName}>{variant.purpoesName2}</span>
                        <span className = {classes.productProperty}>&nbsp;{variant.purposeValue2}</span>
                    </div>
                )
            }

            
            {
                advantages && (
                    <div>
                        <h4 className = {classes.propertyTitle}>Достоинства</h4>
                        <p className = {classes.propertyContent}>{advantages}</p>
                    </div>
                )
            }

            {
                disadvantages && (
                    <div>
                        <h4 className = {classes.propertyTitle}>Недостатки</h4>
                        <p className = {classes.propertyContent}>{disadvantages}</p>
                    </div>
                )

            }
            
            {
                text && (
                    <div>
                        <h4 className = {classes.propertyTitle}>Комментарий</h4>
                        <p className = {classes.propertyContent}>{text}</p>
                    </div>
                )
            }
            
           

            {oneReviewData.images_comments.length > 0 && (
                  <div className={classes.userPhotoContainer}>
                    {oneReviewData.images_comments.map((image, index) => (
                      <img
                        key={index}
                        src={image.url}
                        className={classes.photo}
                        alt="reviewPhoto"
                      />
                    ))}
                  </div>
                )}
        
        
            {/*{!answer &&
                <p className = {isUseful > 0 ? `${classes.hidden}` : `${classes.text}`}>Вам полезен это отзыв?</p>}
            <div className = {classes.alignContainer}>

                {!answer && <div className = {isUseful > 0 ? `${classes.hidden}` : ''}>
                        <span
                            className = {isHelpful ? `${classes.buttonYN} ${classes.active}` : `${classes.buttonYN}`}
                            onClick = {() => {
                                setHelpful(true);
                                setAnswer(true)
                                addUsefulComment({
                                    variables: {
                                        comment_id: comment_id,
                                        answer: true,
                                        usefulness: 1
                                    }
                                });
                            }}>Да
                        </span>
                     <span 
                        className = {!isHelpful ? `${classes.buttonYN} ${classes.active}` : `${classes.buttonYN}`}
                        onClick = {() => {
                            setHelpful(false);
                            setAnswer(true)
                            addUsefulComment({
                                variables: {
                                    comment_id: comment_id,
                                    answer: true,
                                    usefulness: 0
                                }
                            });
                        }}>Нет
                        </span>
                        
                </div>}

            
                <div
                    className = {classes.attentionContainer}
                    onClick = {() => {
                        setModalContent(oneReviewData.id);
                        setModalActive(true);
                    }}>
                    <img className = {classes.attentionImage} src = {attention} alt = "attention"/>
                    <span className = {classes.attentionText}>!</span>
                </div>
            </div>
            */}

            <span className={classes.line}> </span>
        
    </div>);
}
