import React, {useEffect, useState} from 'react';
import s from "./CheckButtonTwoLines.module.css"
import resend from '../../../assets/svg/resend.svg';

export const checkButtonModes = {
    default: "GET_CODE",
    send: "SEND",
    expect: "EXPECT",
    get_again: "GET_AGAIN",
    success: "SUCCESS",
    error: "ERROR"
}

function CheckButtonTwoLines({
                                 callback, isValid,
                                 error, setError,
                                 buttonMode, setButtonMode,
                                 isDisabled, setIsDisabled, seconds
                             }) {


    const classname = !isValid ? `${s.applyButton} ${s.check}` : `${s.applyButton} ${s.valid}`
    const finallyClassname = error ? `${s.applyButton} ${s.error}` : classname


    const getTitle = () => {
        switch (buttonMode) {
            case checkButtonModes.default:
                return "Получить код";
            case checkButtonModes.send:
                return "Код отправлен";
            case checkButtonModes.expect:
                return `${seconds} c`;
            case checkButtonModes.get_again:
                return <img className = {s.resend} src = {resend}/>;
            case checkButtonModes.success:
                return "Подтвержден";
            case checkButtonModes.error:
                return "Ошибка";
            default:
                return "";
        }
    }


    //
    // useEffect(() => {
    //     if (error) {
    //         setTimeout(() => {
    //             setError(false)
    //         }, 3000)
    //     }
    //
    // }, [])
    //
    // useEffect(() => {
    //     if (error) {
    //         setTimeout(() => {
    //             setError(false)
    //         }, 2000)
    //     }
    //
    // }, [error])


    return (<button className = {finallyClassname} disabled = {isDisabled} onClick = {callback}>
        {getTitle()}
    </button>);
}

export default CheckButtonTwoLines;