import classes from './Error.module.css';
import {useEffect} from 'react';

export default function Error() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className={classes.wrapper}>
            <h1>404</h1>
            <h2>Page not found</h2>
        </div>
    )
}