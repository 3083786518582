import React, {useContext, useEffect, useState} from 'react';
import styles from './ReviewLikes.module.css';
import usefulSvg from '../../../../assets/svg/useful.svg';
import uselessSvg from '../../../../assets/svg/useless.svg';
import {useMutation} from '@apollo/client';
import {INSERT_LIKE} from '../../../../requests/commentUserRequests';
import {TelegramContext} from '../../../../App';

function ReviewLikes({commentLikes, refetch, reviewID, updateLikes}) {
    const {userID} = useContext(TelegramContext);

    const [likes, setLikes] = useState(null)

    const [insertLike] = useMutation(INSERT_LIKE)

    useEffect(() => {
        likes !== null && insertLike({variables: {userID, likes, comment_id: reviewID}})
            .then(() => {
                // refetch()
                updateLikes(likes)
            })


    }, [likes])

    const likesCount = commentLikes.filter(like => like.likes)?.length || 0
    const dislikesCount = commentLikes.filter(like => !like.likes)?.length || 0


    return (
        <div className = {styles.likeContainer}>
            <p>Вам полезен этот отзыв?</p>
            <button className = {styles.likeButton} onClick = {() => setLikes(true)}>
                <img src = {usefulSvg}/>
                <span>{likesCount}</span>
            </button>
            <div className = {styles.likeButton} onClick = {() => setLikes(false)}>
                <img src = {uselessSvg}/>
                <span>{dislikesCount}</span>
            </div>
        </div>
    );
}

export default ReviewLikes;