import React, {useContext, useState} from 'react';
import s from "./ChangePhone.module.css"
import ColoredButton from '../../components/ColoredButton/ColoredButton';
import ProfilePhoneApplyCode from '../ProfilePhoneApplyCode/ProfilePhoneApplyCode';
import SettingHeader from '../../ProfileSetting/SettingHeader/SettingHeader';
import {displayTypes, ProfileSettingContext} from '../../ProfileSetting';
import Separator from '../../../../../Сomponents/Separator/Separator';


function ChangePhone({
                         readOnly,
                         headerCallback,
                         phone,
                         setPhone,
                         verificationCode,
                         setVerificationCode,
                         setDisplayType,

                     }) {

    // const {phone, setPhone, verificationCode, setVerificationCode, setDisplayType} = useContext(ProfileSettingContext)


    const [isConfirm, setIsConfirm] = useState(false)
    const [localPhone, setLocalPhone] = useState(phone)


    const changePhoneHandler = () => {
        setPhone(localPhone)
        setVerificationCode('')
        setDisplayType(displayTypes.base)
    }

    const headerCallbackHandler = () => headerCallback()

    return (
        <div className = {s.wrapper}>
            <div style = {{height: "60px"}}></div>
            <SettingHeader title = {"Изменение телефона"} callback = {headerCallbackHandler}/>
            <div className = {s.greeting}>
                <h3 className = {s.title}>Изменение телефона</h3>
            </div>

            <Separator/>

            <ProfilePhoneApplyCode
                isConfirm = {isConfirm}
                setIsConfirm = {setIsConfirm}
                phone = {localPhone}
                phoneInputcallback = {setLocalPhone}
                phoneError = {false}
                verificationCode = {verificationCode}
                setVerificationCode = {setVerificationCode}
                readOnly = {false}/>

            <ColoredButton name = {"Изменить телефон"} disabled = {!isConfirm} callback = {changePhoneHandler}/>

        </div>
    );
}

export default ChangePhone;