import {gql} from '@apollo/client';


export const ADD_LIKE = gql`
    mutation addLike($ItemID: Int, $userID: bigint) {
        insert_users_likes(objects: { item_id: $ItemID, user_id: $userID}) {
            affected_rows
        }
    }
`;
export const ADD_LIKE_SHOP = gql`
    mutation addShopLike($ShopID: Int,$userID: bigint) {
        insert_shops_likes(objects: { shop_id: $ShopID, user_id: $userID}) {
            affected_rows
        }
    }
`;
export const DELETE_LIKE = gql`
    mutation deleteLike($ItemID:Int,$userID: bigint) {
        delete_users_likes(
            where: { user_id: { _eq: $userID }, item_id: { _eq: $ItemID } }
        ) {
            affected_rows
        }
    }
`;
export const DELETE_LIKE_SHOP = gql`
    mutation deleteShopLike($ShopID:Int,$userID: bigint) {
        delete_shops_likes(
            where: { user_id: { _eq: $userID }, shop_id: { _eq: $ShopID } }
        ) {
            affected_rows
        }
    }
`;
export const SHOPS_LIKES = gql`
    query shopLikes ($userID: bigint){
        shops_likes(where: {user_id: {_eq: $userID}}) {
            shop_id
        }
    }
`;

export const USERS_LIKES = gql`
    query userLikes ($userID: bigint){
        shop(where: {shops_likes: {user_id: {_eq: $userID}}}) {
          id
          info
          name
          photo
          comment_items_aggregate {
            aggregate {
              avg {
                estimate
              }
              count
            }
          }
        
        }
        item(where: {users_likes: {user_id: {_eq: $userID}}}) {
            lots(order_by: {price: asc}) {
                price
                total
                step
            }
            id
            name
            about
            
            comment_items {
              estimate
            }
            shop {
              name
              approval

            }
            category_items {
              category {
                subcategory
              }
            }
            item_media(where: {type: {_eq: "image"}}) {
              path
            }
            
          
        }
      }
`;