import React, {useContext} from "react";
import {TelegramContext} from '../../../App';
import {GET_ITEMS} from '../../../requests/itemRequests';
import LoadItemsData from '../../StandartGoodsDisplay/LoadItemsData';
import classes from "./LooksLike.module.css";

const LooksLike = React.memo(function LooksLike({limit}) {


    const {userID} = useContext(TelegramContext);
    const dateNow = new Date();
    // const display = DisplayItems({horizontal = {horizont}})
    return (
        <div>
            <div>
                <span className = {classes.title}>Похожие товары</span>
            </div>
            <br></br>

            <div><LoadItemsData limit = {limit}
                                query = {GET_ITEMS}
                                variables = {{userID, offset: 0, limit, dateNow }}
                                keyName = {"item"}
                                aggregateKeyName = {"item_aggregate"}
                                isHorizontal = {true}/>
            </div>
        </div>

    )

}
)
export default LooksLike;
