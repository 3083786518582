import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './StorageItems.module.css';
import {useMutation, useQuery} from '@apollo/client';
import {ADD_ITEM_TO_STORAGE} from '../../../../requests/storageRequests';
import {SHOP_ITEMS} from '../../../../requests/itemRequests';
import {useLocation} from 'react-router-dom';
import point from '../../../../assets/svg/point.svg';
import pointActive from '../../../../assets/svg/pointActive.svg';
import { ContactSupportOutlined } from '@mui/icons-material';




function AddVariants({variants , setItemsValue, itemsValue, itemId}){
  const [selectedValues, setSelectedValues] = useState({});


  const updateCount = (variantId, newCount) => {
    if (newCount < 0) {
      return;
    }
    setSelectedValues({
      ...selectedValues,
      [variantId]: { ...selectedValues[variantId], count: newCount },
    });
    
  };

  const toggleSelected = (variantId) => {
    setSelectedValues({
      ...selectedValues,
      [variantId]: { 
        ...selectedValues[variantId], 
        selected: !selectedValues[variantId]?.selected,
        count: selectedValues[variantId]?.count ?? 0
      },
    });
    
  };




  useEffect(() => {
    let counts = 0;
    let choose = false;
    let variants = [];
    Object.keys(selectedValues).forEach(variantId => {
      
      // counts += parseInt(selectedValues[variantId].count);
      if (selectedValues[variantId].selected) {
        choose = true;
        counts += parseInt(selectedValues[variantId].count);
        variants.push( {variantId : variantId, count: selectedValues[variantId].count} ); 
      }        

    });

    setItemsValue(prevItemsValue => {
      const newItemsValue = { ...prevItemsValue };
      if (!newItemsValue[itemId]) {
        newItemsValue[itemId] = { count: 0, choose: false };
      }
      newItemsValue[itemId].count = counts  ;
      newItemsValue[itemId].choose = choose;
      newItemsValue[itemId].variants = variants;
      return newItemsValue;
    });
  
  }, [selectedValues]); 



  return(
    <div >
    {
    variants.map((variant, index) => {
      const variantData = selectedValues[variant.id] || { count: 0, selected: false };
      return (
      <div className={styles.wraperVariat} key={index}>
            <div className={styles.row}>
              <h2 >Вариант {index + 1}</h2>
              {index === 0 && <span>Исходный</span>}
            </div>

          <div className={styles.infoVariant}>

          
          {variantData.selected ? (
                <img src={pointActive} alt="link"
                  onClick={() => toggleSelected(variant.id)} />
              ) : (
                <img src={point} alt="choose" 
                  onClick={() => toggleSelected(variant.id)}
                />
              )}



                <div className={styles.itemImage} key={variant.image}>
                    <img src={variant.image} alt="foto" className={styles.avatar} />
                </div>

                <div className={styles.variantsInfo}>

                    <div className={styles.variantText}>
                        <label className={styles.textInfo}>Артикул</label>
                        <span className={styles.labelInfo}>{variant.articleNumber}</span>
                    </div>
                    <div style= {{height: '1vh'}}/  >
                    

                    <div className={styles.variantText}>
                        <label className={styles.textInfo}>Длина упаковки (мм)</label>
                        <span className={styles.labelInfo}>{variant.length || '-'}</span>
                    </div>

                    <div className={styles.variantText}>
                        <label className={styles.textInfo}>Ширина упаковки (мм)</label>
                        <span className={styles.labelInfo}>{variant.width || '-'}</span>
                    </div>

                    <div className={styles.variantText}>
                        <label className={styles.textInfo}>Высота упаковки (мм)</label>
                        <span className={styles.labelInfo}>{variant.height || '-'}</span>
                    </div>
                    <div className={styles.variantText}>
                        <label className={styles.textInfo}>Вес упаковки (мм)</label>
                        <span className={styles.textInfo}>{variant.weight || '-'}</span>
                    </div>
                </div>


                <div className={styles.countButton}>
                  <button type='button' className={styles.doButton} onClick={() =>updateCount(variant.id, parseInt(variantData.count) - 1)}>-</button>
                  <input 
                    type="number"
                    value={variantData.count}
                    onChange={e => updateCount(variant.id, e.target.value)}
                    className={styles.buttonText}
                    style={{ width: `${Math.max(16, (variantData.count.toString().length * 8))}px` }}
                  />
                  <button type='button' className={styles.doButton} onClick={() => updateCount(variant.id, parseInt(variantData.count) + 1)}>+</button>
                </div>
          </div>
            
            <div className={styles.characterRow}>
                  {variant.purposeName ? (
                    
                    <div className={styles.character}>
                      <label className={styles.labelInfo}>{variant.purposeName}</label>
                      <span className={styles.textInfo}>{variant.purposeValue}</span>
                    </div>
                    
                  ) : ( 
                    <div className={styles.character}>
                        <label className={styles.labelInfo}>-</label>
                    </div> )}
                  {variant.purposeName2 && (
                    <div className={styles.character}>
                  
                      <label className={styles.labelInfo}>{variant.purposeName2}</label>
                      <span className={styles.textInfo}>{variant.purposeValue2}</span>
                    </div>
                  ) }
            </div>


          </div>
  )})}
  </div>

    
  )
}




function StorageItems()  {
    const location = useLocation();
    const shopId = location.state.shopId;
    const storageId = location.state.storageId;
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [showVariants, setShowVariants] = useState(null );



    const [itemsValue, setItemsValue] = useState({});
    const [items, setItems] = useState([]);

    const [saveItems, { saveData }] = useMutation(ADD_ITEM_TO_STORAGE )

   
    const { data, error, loading } = useQuery(SHOP_ITEMS, {
    fetchPolicy: 'cache-and-network',
    variables: { shopId },
      });
    
      useEffect(() => {
        if (data) {
          setItems(data.item);
          const initialItemsValue = data.item.reduce((acc, item) => {
            acc[item.id] = { count: 0, selected: false };
            return acc;
          }, {});
          setItemsValue(initialItemsValue);
        }
      }, [data]);
    
      if (loading) return <div>Loading...</div>;
      if (error) {
        console.error('items for storage error', error);
        return <div>Error!</div>; 
      }
    


  
    if (!items || items.length === 0) {
        return (
          <div className={styles.wrapper}>
            <div className={styles.text}>У вас пока нет ни одного товара</div>
            <button className={styles.addButton} onClick={() => navigate('/create_item', { state: { shopId } })}>
              Добавить товар
            </button>
          </div>
        );
      }

    



    const updateCount = (itemId, newCount) => {
      if (newCount < 0) {
        return;
      }
      if(!isNaN(newCount)){
        setItemsValue({
         ...itemsValue,
          [itemId]: {
           ...itemsValue[itemId],
            count: newCount,
          },
        });
      }

      {/* setItemsValue({
        ...itemsValue,
        [itemId]: { ...itemsValue[itemId], 
                        count: newCount },
      }); */}

    };



    const chooseSelected = (itemId, variant) => {
      setItemsValue({
        ...itemsValue,
        [itemId]: {
          ...itemsValue[itemId],
          choose: !itemsValue[itemId]?.choose,
          variantId: variant,
        },
      });
    };



    const filteredItems = items.filter(
        it => it.name.toLowerCase().includes(searchTerm.toLowerCase())
          || it.articleNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );




    async function handleSubmit() {

            const variablesArray = [];

            Object.keys(itemsValue).forEach((itemId) => {
              const itemData = itemsValue[itemId];
              if (itemData.choose) {
                if (itemData.variants) {
                  itemData.variants.forEach((variant) => {
                    variablesArray.push({
                      storage_id : storageId,
                      item_id: parseInt(itemId),
                      quantity: parseInt(variant.count) || 0,
                      variants_id: variant.variantId,
                    });
                  });
                } else {
                  variablesArray.push({
                    storage_id : storageId,
                    item_id: parseInt(itemId),
                    quantity: parseInt(itemData.count) || 0,
                    variants_id: itemData.variantId,
                  });
                }
              }
            });
            try {
              const { data: mutationResult } = await saveItems({ 
                variables: {
                  items: variablesArray,
                } 
              });
            
              if (mutationResult) {
                navigate('/goods_storage', { state: { shopId } });
              } else {
                alert("An error occurred while saving the item.");
                await fetch(process.env.REACT_APP_BOT_NOTIFICATION, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ message: 'not mutation result', }),
                    });
              }
            } catch (error) {
              console.error("An error occurred:", error);
              alert("Возможно такой товар уже есть на складе.Please try again.");
              await fetch(process.env.REACT_APP_BOT_NOTIFICATION, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: error.message, }),
                  });
            }
          }
   
        

    
      return (
        <div className={styles.wrapper} >
          <h1>
            Оприходование товаров
          </h1>
            
          <div className={styles.contentContainer}>
            <div className={styles.inputField}>
              <input
                className={styles.search}
                type="text"
                placeholder="Введите наименование товара или артикул"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            <form className={styles.form}>      

            {filteredItems.map((item, index) => (
              <div className={styles.wraperItem} key={index}>
              <div className={styles.itemContainer} >


                <div className={styles.point}>



                {item.item_variants.length === 1 ? (
                        itemsValue?.[item.id]?.choose ? (
                          <img
                            src={pointActive}
                            alt="link"
                            onClick={() => chooseSelected(item.id, item.item_variants[0].id)}
                          />
                        ) : (
                          <img
                            src={point}
                            alt="choose"
                            onClick={() => chooseSelected(item.id, item.item_variants[0].id)}
                          />
                        )
                      ) : (
                        itemsValue?.[item.id]?.choose ? (
                          <img src={pointActive} alt="link" />
                        ) : (
                          <img src={point} alt="choose" />
                        )
                      )}

                </div>
                <div className={styles.itemImage}>
                  <img className={styles.image} src={item.item_media[0].path} alt="itemImage" />
                </div>
                <div className={styles.itemInfo}>
                  <span className={styles.itemName}>{item.name}</span>
                  <span className={styles.itemArticle}>{item.articleNumber}</span>
                  <span className={styles.itemPurposeCount}>2 свойства; 2 варианта;</span>
                </div>


                {
                  (item.item_variants.length === 1) ? (
                    <div className={styles.countButton}>
                  <button type='button' className={styles.doButton} 
                              onClick={() =>updateCount(item.id, parseInt(itemsValue?.[item.id]?.count || 0) - 1)}>-</button>
                  <input 
                        type="number"
                        value={itemsValue?.[item.id]?.count.toString() || ""}
                        onChange={e => {
                          const newValue = e.target.value;
                          // Update the state only if the new value is empty or a valid number
                          updateCount(item.id, newValue === "" ? "" : Number(newValue));
                        }}
                        className={styles.buttonText}
                        style={{
                          width: `${
                            itemsValue?.[item.id]?.count
                              ? Math.min(
                                  Math.max(16, ((itemsValue[item.id].count || "0").toString().length * 8)),
                                  100
                                )
                              : 12
                          }px`
                        }}
                            />
                  <button type='button' className={styles.doButton} 
                              onClick={() => updateCount(item.id, parseInt(itemsValue?.[item.id]?.count || 0) + 1)}>+</button>
                </div>
                  ) : (
                    <div className={styles.countButton}>
                  <button type='button' className={styles.doButton}>-</button>
                    <span className={styles.buttonText}> {itemsValue?.[item.id]?.count  || 0} </span>
                  <button type='button' className={styles.doButton}>+</button>
                </div>
                  )     
                }

              </div>
              {item.item_variants.length > 1 && (
                <>
                <span className={styles.link} 
                      onClick={() => setShowVariants(showVariants === item.id ? null : item.id)}
                      >
                      {showVariants === item.id ? 
                      'Закрыть варианты' :
                        'Открыть варианты'}
                    </span>

                    {showVariants === item.id && (
                      <AddVariants variants={item.item_variants} 
                                    setItemsValue={setItemsValue}
                                    itemId={item.id}
                                    itemsValue={itemsValue}
                      />
                    )}
                    
              </>
              )}
              </div>
  
    
            ))}
            </form>
            </div>
          
          <button className={styles.buttonFixed} 
                disabled={!itemsValue} 

                onClick={async() => await handleSubmit()}  >
            Добавить на склад
          </button>
        </div>
      );
    }
  
  







export default StorageItems;