import React from "react";
import classes from "./Links.module.css";
import link from "../../../assets/svg/link.svg";
import {useNavigate} from "react-router-dom";

export default function Links({category, subcategory, shop, image}) {

    const navigate = useNavigate();

    // console.log(shop);
    return (
        <div className = {classes.wrapper}>
            <div className = {classes.container}
                    onClick = {() => {
                    navigate("/v1/category", {
                        state: {category: category, subCategory: "any"},
                    });
                        }} >
                <span className = {classes.align}>
                    <img src = {shop.photo} alt = ""/>
                    <span className = {classes.title}>{shop.name}</span>
                    <span className = {classes.text}>Бренд</span>
                </span>
                    <img src = {image} alt = "" className = {classes.link}/>
            </div>
            <div className = {classes.container}
                    onClick = {() => {
                            navigate("/v1/category", {
                                state: {category: category, subCategory: "any"},
                            });
                        }}>
                <span className = {classes.align}>
                    <img src = {image} alt = ""/>
                    <span className = {classes.title}>{category}</span>
                    <span className = {classes.text}>Категория и Бренд</span>
                </span>
                    <img src = {link} alt = "" className = {classes.link}/>
            </div>
            <div
                className = {classes.container}
                onClick = {() => {
                    navigate("/v1/category", {
                        state: {category: category, subCategory: "any"},
                    });
                }}
            >
                <span className = {classes.align}>
                  <img src = {image} alt = ""/>
                  <span className = {classes.title}>{category}</span>
                  <span className = {classes.text}>Категория</span>
                </span>
                <img src = {link} alt = "" className = {classes.link}/>
            </div>
            <div
                className = {classes.container}
                onClick = {() => {
                    navigate("/v1/category", {
                        state: {
                            category: category,
                            subCategory: subcategory,
                        },
                    });
                }}
            >
            <span className = {classes.align}>
              <img src = {image} alt = ""/>
              <span className = {classes.title}>{subcategory}</span>
              <span className = {classes.text}>Подкатегория</span>
            </span>
                <img src = {link} alt = "" className = {classes.link}/>
            </div>
        </div>
    );
}
