import { gql } from "@apollo/client";




export const CREATE_ORDER = gql`
    mutation CreateOrder($userID: bigint) {
        insert_order(objects: {user_id: $userID, status: "pending"}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;


export const GET_PAYMENT = gql`
    query GetPayment($paymentId: Int!) {
        payment(where: {id: {_eq: $paymentId}}) 
        {
            status
            total_amount
            created_on
            order_id
           order{
            order_items{
                id
            }
        }

    }
}
`;


export const ALL_USER_ORDERS = gql`
    query AllUserOrders($userID: bigint) {
        order(where: {
            _and: [
              { user_id: { _eq: $userID } },
              { status: { _neq: "pending" } },
              { status: { _neq: "payment_process" } },
              { status: { _neq: "invoice" } },
            ]
          }
          order_by: { updated_on: desc }) {
            id
            status
            created_on
            updated_on
            total
            order_items {
                quantity
                price
                status
                item {
                    id
                    name
                    item_media {
                        path
                    }
                    shop {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const STATUS_USER_ORDERS = gql`
    query StatusUserOrders($userID: bigint, $status: String) {
        order(where: {
            _and: [
                { user_id: { _eq: $userID } },
                { order_items: { status: { _eq: $status }} }
            ]
          },
          order_by: { updated_on: desc }) {
            id
            status
            created_on
            updated_on
            total
            order_items (where: {status: {_eq: $status}}) {
                quantity
                price
                status
                item {
                    id
                    name
                    item_media {
                        path
                    }
                    shop {
                        id
                        name
                    }
                }
            }
        }
    }
`;





export const GET_USER_ORDER = gql`
    query GetUserOrder($userID: bigint, $orderId: Int!) {
        order(where: {user_id: {_eq: $userID}, id: {_eq: $orderId}}) {
            id
            status
            created_on
            updated_on
            total
            order_number
            order_items {
                id
                quantity
                price
                total
                status
                refund_item{
                    reason 
                    status
                    comment
                    images
                }

                order_deliveries {
                    company
                    track_number
                }

                item {
                    id
                    name
                    item_media {
                        path
                    }
                    shop {
                        name
                        comment_items_aggregate {
                          aggregate {
                            avg {
                              estimate
                            }
                          }
                        }
                      }
                }
                item_variant {
                    purposeName
                    purposeValue
                    purposeName2
                    purposeValue2
                }
                lot{
                    expire_date
                    status 
                    step 
                    ordered_quantity
                    total
                }
            }
            user{
                user_profile{
                    name
                    surname
                    
                    
                }
            }
            shipping_addresses{
                street 
                house 
                flat
                post_code
                email 
                phone_number
                delivery_city {
                    city
                    country
                    region
                  }
            }
            
        }
    }
`;


export const SAVE_SHIPPING_TRACK = gql`
    mutation saveDelivery(
                        $orderItemId: Int!,
                        $orderId: Int!,
                        $shopOrderId: Int!,
                        $trackNumber: String!,
                        $company: String!,
                        $shippingAddressId: Int,
                        $status: String!,

                        ) {
        insert_order_delivery(objects: {
            
            order_items_id: $orderItemId,
            order_id: $orderId,
            shop_order_id: $shopOrderId,
            track_number: $trackNumber,
            company: $company,
            shipping_address_id: $shippingAddressId,
            status: $status,
        })
        {
            affected_rows
        }
    }

`



export const CONFIRM_ITEM = gql`
    mutation confirmItem($order_item: Int!) {
        update_order_items(where: {id: {_eq: $order_item}}, _set: {status: "received",}) {
            returning {
                id    
            }
        }
    }
`;

export const STATUS_CANCEL_ORDER = gql`
    mutation refundItem($orderItemID: Int!,) {
        update_order_items(where: {id: {_eq: $orderItemID}}, _set: {status: "cancell"}) {
            returning {
                id    
            }
        }
        
    }
`;







export const GET_ORDERS_WITH_STATUS = gql`
    query GetOrdersWithStatus($status: String!, $shopId: Int) {
        order(       
            where: {
                _and: [
                    { order_items: { status: { _eq: $status } } },
                    { shop_orders: { shop_id: { _eq: $shopId } } }
                ]
            },
            order_by: { updated_on: desc }
        ) {
            id
            status
            created_on
            updated_on
            total
            order_items(
                where: {
                    _and : [
                    {status: {_eq: $status}},
                    {shop_id: {_eq: $shopId}},

                    ]
                }
            ) {
                id
                quantity
                price
                status
                item {
                    id
                    name
                    item_media {
                        path
                    }
                    shop {
                        name
                    }
                }
            }
        }
    }
 `




export const GET_ORDERS_FOR_DELIVERY = gql`
    query GetOrdersWithStatus($status: String!, $shopId: Int) {
        order(
            where: {
              _and: [
                { order_items: { status: { _eq: $status }, lot: { status: { _neq: "active" } } } },
                { shop_orders: { shop_id: { _eq: $shopId } } }
              ]
            },
            order_by: { updated_on: desc }
          )  {
            id
            status
            created_on
            updated_on
            
            total
            shop_orders {
                id
              }

            shipping_addresses {
                id
                country_code
                email
                delivery_city {
                  city
                }
                house
                flat
                street
                post_code
            }
            order_items(where : {
                _and : [
                    {status: {_eq: $status}},
                    {shop_id: {_eq: $shopId}},
                ]
            })  {
                id
                shop_id
                quantity
                price
                status
                
                item {
                    id
                    name
                    item_media {
                        path
                    }
                    shop {
                        id
                        name
                    }
                }
            }
        }
    }

`







export const UPDATE_ORDER_ITEMS_STATUS = gql`
    mutation UpdateOrderStatus($orderItemId: Int!, $status: String!) {
        update_order_items(where: {id: {_eq: $orderItemId}}, _set: {status: $status}) {
        returning {
          id
        }
        }
    }
        `;



export const UPDATE_SHOP_ORDER_STATUS = gql`
    mutation UpdateShopOrderStatus($shopOrderId: Int!, $status: String!) {
        update_shop_orders(where: {id: {_eq: $shopOrderId}}, _set: {status: $status}) {
        returning {
          id
        }
        }
    }
`;



export const GET_NEW_SHOP_ORDERS = gql`
    query GetNewShopOrders($shopId: Int!, $date: timestamptz!) {
        
          shop_orders(where: {
                        created_on: { _gte: $date },
                        shop_id: {_eq: $shopId}}) {
            id
            order {
              shop_payments_aggregate {
                aggregate {
                  sum {
                    total_amount
                  }
                  count
                }
              }
            }
          }
          }
  
      `;


export const GET_SHOP_INDICATORS = gql`
    query GetShopIndicators($shopId: Int!) {
        comment_item_aggregate(where: {item: {shop_id: {_eq: $shopId}}}) {
            aggregate {
                avg {
                    estimate
                }
            }
        }
        shop_orders_aggregate(where: {shop_id: {_eq: $shopId}}) {
            aggregate {
                count
            }
        }
        refund_items_aggregate(where: {order_item: {item: {shop_id: {_eq: $shopId}}}}) {
            aggregate {
                count
            }
        }
        order_items_aggregate(where: {item: {shop_id: {_eq: $shopId}}}) {
            aggregate {
                sum {
                    quantity
                }
            }
        }
        shop_payments_aggregate(where:  {shop_id: {_eq: $shopId}}) {
            aggregate {
                sum {
                    total_amount
                }
            }
        }
        shop_payments_aggregate(where:  {shop_id: {_eq: $shopId}, }) {
            aggregate {
                avg {
                    total_amount
                }
            }
        }
        item_aggregate( where: {
            shop_id: {_eq: $shopId},
            lots: {status: {_eq: "active"}}
        }) {
            aggregate {
                count
            }
        }
    }
`;