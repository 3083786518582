import React from 'react';
import {gql} from '@apollo/client';
import {useMutation} from '@apollo/client';
import {GET_ITEMS} from '../../requests/itemRequests';
import likesRed from '../../assets/svg/likesRed.svg';
import classes from './Likes.module.css';
import {DELETE_LIKE} from '../../requests/likesRequests';
import {likesAmount} from '../LikedItems/utils/AmountOfLikes';
import {useContext} from 'react';
import {TelegramContext} from '../../App';


export default function DeleteLike({ItemID, setAdd}) {
    // const userID = localStorage.getItem('userID') || '534677865';

    const {userID} = useContext(TelegramContext) || 534677865;

    const [deleteFunction] = useMutation(DELETE_LIKE, {
        variables: {ItemID,  userID},
        // refetchQueries: [
            // DocumentNode object parsed with gql
            // {queri: GET_ITEMS},
        // ],
    });

    return (
        <img
            className = {classes.Likes}
            alt = "like"
            src = {likesRed}
            onClick = {() => {
                if (userID !== 0){
                deleteFunction();
                }
                setAdd((a) => !a);
                likesAmount(likesAmount() - 1);
            }}></img>
    );
}
