import React from 'react';
import doubleDisplayItemFormatClasses from './DoubleDisplayItemFormat.module.css';
import SingleDisplayItemFormatClasses from './SingleDisplayItemFormat.module.css';
import ListDisplayItemFormatClasses from './ListDisplayItemFormat.module.css';
import SwiperWithRealPhotos from '../SwiperWithRealPhotos';
import approveSvg from '../../../assets/svg/approve.svg';
import {Rating} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import NumberFormat from '../../../utils/NumberFormat';
import ColorAdditionalAccent from '../СolorAdditionalAccent/СolorAdditionalAccent';
import isDateMoreThanTwoWeeksAgo from '../../../utils/isDateMoreThenTwoWeeksAgo';


const DisplaySingleItem = ({item = '', isLiked = false, cartFormat}) => {


    let styles = {}
    switch (cartFormat) {
        case "SINGLE":
            styles = {...SingleDisplayItemFormatClasses}
            break
        case "LIST":
            styles = {...ListDisplayItemFormatClasses}
            break
        default:
            styles = {...doubleDisplayItemFormatClasses}
    }

    //console.log(data.item)
    let rating = Number((
        item.comment_items.reduce((a, b) => {
            return a + b.estimate;
        }, 0) / item.comment_items.length
    ).toFixed(1))

    if (rating === 'NaN') {
        rating = 0
    }


    const approveLabel = item.shop.approval ? approveSvg : '';


    let subCategoryContent = item?.category_items[0]?.category?.subcategory ?? '';

    // console.log('item', item)

    const addColorAccentConditions = [
        {
            name: "isNewProduct",
            isExist: isDateMoreThanTwoWeeksAgo(item.created_on),
            title: "Новое предложение"
        },
        {name: "isSomeOne", isExist: false, title: "Любая другая акция"}]


    // console.log('item', item)

    return (

    
        <div className = {styles.item}>
            
                <div className = {styles.slide}>
                      <SwiperWithRealPhotos photos = {item.item_media} cartFormat = {cartFormat}/>


                    <ColorAdditionalAccent conditions = {addColorAccentConditions}/>


                </div>
                <div className = {styles.ProductContent}>
                <div className={styles.Price}>
                        {(item?.lots?.[0]?.price) ? 
                            (`от ${NumberFormat(item?.lots?.[0]?.price )}  ₽`) :
                            ("скоро в продаже")
                        }
                        </div>


                    <div className = {styles.about}>{item.name}</div>
                    <div className = {styles.subCategoryContent}>{subCategoryContent}</div>

                    <div className = {styles.shopNameContent}>
                        <span>{item.shop.name}</span>
                        <img className={styles.approveImage} src = {approveLabel} alt = "approveImage"/>
                    </div>
                    <div className = {styles.ratingContainer}>
                        <Rating name = "read-only-rating" value = {rating}
                            // precision = {0.5}
                                readOnly size = "small"
                                emptyIcon = {<StarIcon fontSize = "inherit"/>}
                                style = {{transform: "scale(0.7)"}}
                        />

                        {item.comment_items.length > 0 ? (
                            <span className = {styles.starCount}>{item.comment_items.length}</span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
        </div>
    

    );


};

export default React.memo(DisplaySingleItem);
