import React, { useState, useEffect } from 'react';
import styles from './Modifiers.module.css';



export function   Modifiers({ variants, 
            setCurrentVariant, setSelectedItem }) {


    const [allValue, setAllValue] = useState(new Set());
    const [allValue2, setAllValue2] = useState(new Set());

    const [secondValue, setSecondValue]= useState(false)


    useEffect(() => {
    setAllValue(new Set(variants.map(variant => variant.purposeValue)));

    if (variants[0]?.purposeName2) {
    setSecondValue(true)
    setAllValue2(new Set(variants.map(variant => variant.purposeValue2)));
    }

    }, [variants]);



    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedSecondValue, setselectedSecondValue] = useState(null);

    const [availableValue2, setAvailableValue2] = useState(null);
    const [availableValue, setAvailableValue] = useState(null);



    // Update selected value based on user interaction.
    const handleValueClick = (value) => {
    let  availabelVariants =  null; 

    if (secondValue) {  
    // when we have second value

    if (selectedSecondValue){
    // when we have selectedSecondValue

    if(selectedValue === value){
        // when we click on selected value

        setSelectedValue(null)
        setSelectedItem(false)
        // setAvailableValue2(null)

    }else{
        // when we click on not selected value
        setSelectedValue(value);
        availabelVariants = variants.filter(variant =>
            variant.purposeValue === value &&
            variant.purposeValue2 === selectedSecondValue
        )
        if (availabelVariants.length > 0){

            setCurrentVariant(availabelVariants[0].id)
            availabelVariants = variants.filter(variant =>
                variant.purposeValue === value
            )
            setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
            setSelectedItem(true)
        }else{

        availabelVariants = variants.filter(variant =>
            variant.purposeValue === value
        )
        
        setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
        setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue2)))
        
        setselectedSecondValue(null)
        setSelectedItem(false)

    }
    }

    }else{
    // when we haven't selectedSecondValue
    if (selectedValue === value) {
        setSelectedValue(null)
        setAvailableValue(null)
        setAvailableValue2(null)
    }else{


    setSelectedValue(value);
    availabelVariants = variants.filter(variant =>
        variant.purposeValue === value
    )

    setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
    setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
    }
    }
    }else{
    // when we have only one value
    if (selectedValue === value) {
    setSelectedValue(null)
    setAvailableValue(null)
    setSelectedItem(false)
    }else{
    setSelectedValue(value);
    availabelVariants = variants.filter(variant =>
        variant.purposeValue === value )
     setCurrentVariant(availabelVariants[0].id)
     setSelectedItem(true)
    }
    }

    }


    const handleSecondValueClick = (value) => {
    let availabelVariants =  null; 

    if (selectedValue){
    // when we have first value selected
    if (selectedSecondValue === value){
    setselectedSecondValue(null)
    setSelectedItem(false)

    }else{

    setselectedSecondValue(value);
    availabelVariants = variants.filter(
        variant =>
        variant.purposeValue === selectedValue &&
        variant.purposeValue2 === value  
    )

    if (availabelVariants.length >0 ){
        setCurrentVariant(availabelVariants[0].id)
        availabelVariants = variants.filter(variant => 
            variant.purposeValue2 === value
        )
        setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
        setSelectedItem(true)                    
    }else{
        console.log('here eroor')
        setSelectedValue(null)
        availabelVariants = variants.filter(variant => 
            variant.purposeValue2 === value
        )
        setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
        setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))
        setSelectedItem(false)

    }
    }

    }else{
    // console.log('without selectedValue')

    if(selectedSecondValue === value){
    setselectedSecondValue(null)
    setAvailableValue2(null)
    setAvailableValue(null)
    }else{


    setselectedSecondValue(value);
    availabelVariants = variants.filter(variant => 
    variant.purposeValue2 === value
    )
    setAvailableValue( new Set(availabelVariants.map(variant => variant.purposeValue)))
    setAvailableValue2( new Set(availabelVariants.map(variant => variant.purposeValue2)))

    // setSelectedItem(true)
    } }


    }



    const renderDiv = (value, index, isSelected, isAvailable, click) => (
    <div
    className={
    isSelected 
        ? styles.modifiersSelected  
    : isAvailable 
        ? styles.modifiers  
    : styles.modifiersUnavailable  
    }
    key={index}
    onClick={() =>  click(value)}
    >
    {value}
    </div>
    );



    return (
    <div className={styles.content}>
    <div className={styles.row}>
    <span className={styles.rowTitle}>{variants[0].purposeName} : </span>
    </div>
    <div className={styles.chooseRow}>
    {[...allValue].map((value, index) => {
        const isSelected = selectedValue === value;
        const isAvailable = availableValue ? availableValue.has(value) : true;
    
        const click = handleValueClick


        return renderDiv(value, index, isSelected, isAvailable, click);
    })}
    </div>
    {secondValue && (
    <>
        <div className={styles.row}>
            <span className={styles.rowTitle}>{variants[0].purposeName2} : </span>
            {/* <span className={styles.rowContent}>{selectedSize2 || ''}</span> */}
        </div>
        <div className={styles.chooseRow}>
    {[...allValue2].map((value, index) => {
        const isSelected = selectedSecondValue === value;
        const isAvailable = availableValue2 ? availableValue2.has(value) : true;


        const click = handleSecondValueClick

        return renderDiv(value, index, isSelected, isAvailable, click );
    })}
    </div>
    </>
    )}
    </div>
    );

            }
