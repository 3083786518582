import styles from "./ItemMedia.module.css"

import React, {useState} from 'react';
import vector from '../../../../assets/svg/vector.svg'
import { setItemMedia}  from '../../../../features/createItemSlice'
import { useDispatch} from "react-redux";

import backArrow from '../../../../assets/png/backArrow.png'




function ItemMedia({ setDisplayMode, ADD_ITEM_MODES}){

    const [imageUrl, setImageUrl] = useState(null);
    const [imageError, setImageError] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoError, setVideoError] = useState(null);
    const [coverUrl, setCoverUrl] = useState(null);
    const [coverError, setCoverError] = useState(null);

    const [infoVideo, setInfoVideo] = useState(null);
    const [infoCover, setInfoCover] = useState(null);
    const [infoImage, setInfoImage] = useState(null);

    const [imageList, setImageList] = useState([])

    const dispatch = useDispatch();     

    function handleMediaChange(event, type) {
      if (imageList.length < 10) {
        // existing code to add an image
        
        const setError = {
          image: setImageError,
          video: setVideoError,
          cover: setCoverError,
        }[type];
      
        const setUrl = {
          image: setImageUrl,
          video: setVideoUrl,
          cover: setCoverUrl,
        }[type];

        const setInfo = {
          image: setInfoImage,
          video: setInfoVideo,
          cover: setInfoCover,
        }[type]

        
      
        const url = {
          image: `https://api.jbspace.xyz/files/item_photo`,
          video: `https://api.jbspace.xyz/files/item_video`,
          cover: `https://api.jbspace.xyz/files/item_cover`,
        }[type];
      
        setError(null);
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
          console.log('Uploader: No file selected!');
          return;
        }
        setUrl(URL.createObjectURL(selectedFile));
        setInfo('загрузка на сервер...');

        const formData = new FormData();
        formData.append('files', selectedFile);



        fetch(url, { method: 'POST', body: formData })
          .then((response) => {
            // console.log('RESPONSE', response)
            if (response.ok) return response.json();
            throw new Error(`Upload failed with status ${response.status}`);
            
          })
          .then((data) => {
            // console.log('data', data);

            if(type === 'image'){      
                setImageList(prevItems => [...prevItems, data.url[0]]);
              } else {
                setUrl(data.url);
                setInfo('видео загружено');


              }
            
          })

          .catch((error) => {
            setError('не получилось загрузить фото, попробуйте еще раз');
          
            setUrl(null);
            setInfo(null);
            console.error('Error:', error);
          });

        } else {
          alert("No more than 10 images allowed.");
      }
        
      }
      
      const onSubmit = (event) => {
        const media ={
          image: imageList,
          video: videoUrl,
          cover: coverUrl,
        }
        dispatch(setItemMedia(media));
        event.preventDefault(); // Prevent the default form behavior
        setDisplayMode(ADD_ITEM_MODES.variants);
      };
    
     

    function handleDelete(url,  type) {
      const setInfo = {
        image: setInfoImage,
        video: setInfoVideo,
        cover: setInfoCover,
      }[type]
      
      const setUrl = {
        image: setImageUrl,
        video: setVideoUrl,
        cover: setCoverUrl,
      }[type]



      // const formData = new FormData();
      // formData.append('file_name', coverUrl);



      fetch("https://api.jbspace.xyz/files/delete_media", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_url: url })
      })
        .then((response) => {
          // console.log('RESPONSE', response)
          if (response.ok) return response.json();
          throw new Error(`Upload failed with status ${response.status}`);
          
        
          
        })

        setVideoUrl(null);
        setInfo(null);
        if (type === 'image') {
        setImageList(prevList => prevList.filter(item => item !== url));
        } else {
          setUrl(null);
        }

    }

  

    return (

        // <div className={styles.wrapper}>
        <div>
             <h1 className={styles.header}>Медиафайлы товара</h1>
             <div className={styles.stepContainer}>
                <img className={styles.backArrow} src={backArrow} alt="back" onClick={() => setDisplayMode(ADD_ITEM_MODES.itemInfo)} />
                <span className={styles.step} >Шаг 3/4</span>
            </div>
            
        <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.formItem}>
                <label className={styles.label}>Изображения:</label>
                  <div className={styles.info} >
                    <span className={styles.itemInfo}> Формат - JPEG, JPG, PNG. </span>
                    <span className={styles.itemInfo}>Размер - не больше 10 МБ. </span>
                    <span className={styles.itemInfo}>Разрешение от 200 до 4000px по большей стороне. </span>
                    <span className={styles.itemInfo}>Можно загрузить до 10 фото.</span>
                  </div>

            <div className={styles.mediaContainer}>
                <div className={styles.avatarContainer}>
                <input
                     type="file"
                    accept="image/*"
                    className={styles.newAvatar}
                    onChange={(e) => handleMediaChange(e, 'image')}
                    />

                </div>
                {imageList.length > 0 && imageList.map((imageUrl) => (
                          <div className={styles.avatarContainer} key={imageUrl}>

                                  <img src={imageUrl} alt="foto" className={styles.avatar} />
                              <div type="button" className={styles.deleteButton} onClick={() => handleDelete(imageUrl , 'image')}>
                                <img src={vector} alt="delete"></img>
                              </div>

                          </div>
                      ))}
                      {infoImage ? <span className={styles.mediaText}> {infoImage} </span> : 
                       <span className={styles.mediaText}> Обязательно </span>}

            </div>
            {imageError  && <span style = {{color: "red"}}>{imageError}  </span>}

            </div>


            

            <div className={styles.formItem}>
                <label className={styles.label}>Видео:</label>
                
                  <div className={styles.info} >
                    <span className={styles.itemInfo}> Формат - MP4 или MOV. </span>
                    <span className={styles.itemInfo}> Размер - не больше 100 МБ. </span>
                    <span className={styles.itemInfo}> Можно загрузить до 1 видео.</span>
                  </div>

            <div className={styles.mediaContainer}>
                <div className={styles.videoContainer}>
                <input
                    // type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    className={styles.newAvatarHiden}
                    onChange={(e) => handleMediaChange(e, 'video')}
                    />
                {videoUrl && 
                      <>
                          <video className={styles.avatar} 
                                src={videoUrl} 
                                alt="video"  />

                          <div type="button" 
                                className={styles.deleteButton} 
                                onClick={() => handleDelete(videoUrl, 'video')}>
                                <img src={vector} alt="delete"></img>
                          </div>
                      </>}
                </div>

                {infoVideo ? <span className={styles.mediaText}> {infoVideo} </span> : 
                <span className={styles.mediaText} style={{'color' : '#B5B5B5'}}> в разработке</span>
                }

            {videoError  && <span style = {{color: "red"}}>{videoError}  </span>}

            </div>
      </div>

            <div className={styles.formItem}>
                <label className={styles.label}>Видеообложка:</label>

                <div className={styles.info} >
                    <span className={styles.itemInfo}> Формат - MP4 или MOV. </span>
                    <span className={styles.itemInfo}> Размер - не больше 20 МБ. </span>
                    <span className={styles.itemInfo}> Можно загрузить до 1 видео </span>
                </div>

                <div className={styles.mediaContainer}>
                    <div className={styles.videoContainer}>
                        
                    
                  {coverUrl ?
                      <>
                          <video className={styles.avatar} 
                                src={coverUrl} 
                                alt="cover"  />

                          <div type="button" 
                                className={styles.deleteButton} 
                                onClick={() => handleDelete(coverUrl, 'cover')}>
                                <img src={vector} alt="delete"></img>
                          </div>
                      </> :
                      <input
                            // type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            className={styles.newAvatarHiden}
                            onChange={(e) => handleMediaChange(e, 'cover')}
                        />
                      }
                </div>

                {infoCover ? <span className={styles.mediaText}> {infoCover} </span> : 
                <span className={styles.mediaText} style={{'color' : '#B5B5B5'}}> в разработке</span>
                }
                {coverError  && <span style = {{color: "red"}}>{coverError}  </span>}
                </div>

            </div>
                
            <div className={styles.buttonContainer} >
                <button type="submit" className={styles.button} disabled={imageList.length === 0}>
                    Далее
                </button>
            </div>
        </form>
            
        </div>
    
    )
}

export default ItemMedia;