
import React, {useState, useEffect} from 'react'

import styles from './VariantsSettings.module.css'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {useForm, useFieldArray, get} from "react-hook-form";
import goBack from '../../../../../assets/svg/goBack.svg';
import { MAX_VARIANTS } from '../../CreateItem/Variants';


import { SETTINGS_ITEMS } from "../";



// const MAX_VARIANTS = 10; 


function  LocalModalContent ({isMenuOpen, 
                setMenuOpen, 
                setVariantsInfo, 
                setVariant
                                 }){

            // function for show up modal window and add purpose
            // for example color, size, material

    const{
            register,
            handleSubmit,
            formState: { isValid},
            } = useForm({
            defaultValues: {variant: ''},
            })

            const onSubmit = (data) => {
            setMenuOpen(false);
            setVariantsInfo(true);
            setVariant(data.variant);
            }

            const clickEvent = (event) => {
            setMenuOpen(false);
            };


            const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
            event.target.blur();
            }
            }        

return (

        <div className={styles.menuContainer}>
        <div
        className = {styles.closeLineContainer}
        onClick = {clickEvent}>
        <div className = {styles.closeLine}></div>
        {/* <span className = {styles.checkmark}></span> */}
        </div>
        <div className={styles.menuLabel}>Название свойства:</div>
        <div className={styles.menuInfo}>
        Добавьте свойства товара, такие как цвет, размер, материал. 
        Используйте до 30 символов.
        </div>
        <form className={styles.formInfo} onSubmit= {handleSubmit(onSubmit)}>
        <input className={styles.input}
        type="text"
        onKeyDown={handleKeyDown}
        placeholder="Например: Цвет"
        {...register('variant', {required: true, minLength: 2, maxLength: 30})}
        />
        <button className={styles.addCategory} 
        disabled={!isValid}
         type="submit" >Добавить</button>
        </form>
        </div>
)
}










function  SecondPurpose ({
                        isMenuOpen, 
                        setMenuOpen, 
                        setVariant2, 
                        variant2, 
                        variant, 
                        setVarinatsError,
                    }){
        
        const{
                register,
                handleSubmit,
                formState: {isValid},
             } = useForm({
                defaultValues: {variant2: ''},
            })
    
        if (variant2 && variant) {
                setMenuOpen(false);
                return setVarinatsError('Можно добавить только 2 свойства');
                } ;
    
        const onSubmit = (data) => {
            // console.log(data);
            setMenuOpen(false);
            setVariant2(data.variant2);
        }

        const clickEvent = (event) => {
            setMenuOpen(false);
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.target.blur();
            }
        }        
    

        
        return (
        <div className={styles.menuContainer}>
                <div
                    className = {styles.closeLineContainer}
                        onClick = {clickEvent}>
                        <div className = {styles.closeLine}></div>
                </div>
            <div className={styles.menuLabel}>Название свойства:</div>
            <div className={styles.menuInfo}>
                Добавьте свойства товара, такие как цвет, размер, материал. 
                    Используйте до 30 символов.
            </div>
            <form className={styles.formInfo} onSubmit= {handleSubmit(onSubmit)}>
                <input className={styles.input}
                    type="text"
                    onKeyDown={handleKeyDown}
                    placeholder="Например: Цвет"
                    {...register('variant2', {required: true, minLength: 2, maxLength: 30})}
                    />
                <button className={styles.addCategory} 
                            disabled={!isValid}
                             type="submit"
                              >Добавить</button>
            </form>
    </div>
        )
    }                    

















function VariantsSettings({setDisplayMode}) {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [imageUrls, setImageUrls] = useState([]); 

    console.log('isMenuOpen', isMenuOpen)
    const [value, setValue] = useState('first');
    const [variant, setVariant] = useState('');
    const [variant2, setVariant2] = useState('');
    const [varinatsError, setVarinatsError] = useState(false);
    const [variantsInfo, setVariantsInfo] = useState(true);



    const articleNumber = useSelector((state ) => state.settingsItem.articleNumber)
    const item = useSelector((state) => state.settingsItem.item )
    const variants = item.item_variants;


    
    const {
        register,
        getValues,
        // watch,
        control,
        formState: { errors, isValid},
        } = useForm({
     defaultValues: {
                // purpose: variants[0].purposeName ,
                // purpose2: variants[0].purposeName2 || '',
                variant: variants.map(variant => ({
                    'articleNumber': variant.articleNumber || '',
                    'length': variant.length || '',
                    'width': variant.width || '',
                    'height': variant.height || '',
                    'weight': variant.weight || '',
                    'purposeName': variant.purposeName || '',
                    'purposeValue': variant.purposeValue || '',
                    'purposeName2': variant.purposeName2 || '',
                    'purposeValue2': variant.purposeValue2 || '',
                    'image': variant.image || ''
                })),
            }, 
  })

    const {fields, append, remove} = useFieldArray({
        name: "variant",
        control,
    });


    const handleDeleteFirstPurpose = () => {
        const updatedVariants = variants.map(variant => {
            const { purposeName, purposeValue, ...rest } = variant;
            return rest;
        });
    
        // Update your state with the new variants
        // This depends on how you're managing your state, for example:
        // setVariants(updatedVariants);
    };

    function handleDeletePurpose() {
        
    
        if (variant  && variant2) {
        if (value === 'first') {
        setVariant(variant2);
        setVariant2('');
        setVarinatsError(false);
    
        }else{
        setVariant2('');
        setVarinatsError(false);
        }
        }else{
        setVariantsInfo(null);
    
        };
    }


    function handleButton() {
        console.log('button clicked')
        setMenuOpen(true)
    }

    async function handleCreateButton() {
        console.log(getValues())
        
        console.log('submit button on Main screen need to create item');
    }

    
    // console.log('varinats', variants)

    return (
        <div className={styles.wrapper}>
            <div className = {styles.headerContainer}>
                        <img src = {goBack} alt = "back" onClick = {() => setDisplayMode(SETTINGS_ITEMS.default)}/>
                        <div className={styles.row}>
                            <h1 style={{ fontWeight: 400 }}>Артикул</h1>
                            <h1>{articleNumber}</h1>
                        </div>
                    </div>


             <h1 className={styles.header}>Варианты товара</h1>

            <div className={styles.infoContainer}>
                <span className={styles.infoText}>
                     Вариант — это товар с определенным набором свойств.
                    Например: двухспальный комплект постельного белья из льна.
                    Свойства товара: размер, материал. 
                    Каждый вариант может иметь отдельные 
                    артикул, цену, количество, габариты и фотографию.
                </span>
            </div>
                <div className={styles.buttonText} onClick={handleButton}>
                    Добавить cвойство
                </div>
            <ShowVariants  
                    imageUrls={imageUrls}
                    setImageUrls={setImageUrls}
                    fields={fields}
                    remove={remove}
                    register={register}
                    errors={errors}
                    append={append}
                    variants={variants}
                    deletePurpose={handleDeletePurpose}
                        />
            
            
            {isMenuOpen ? 
                        <LocalModalContent isMenuOpen={isMenuOpen} 
                                            setMenuOpen={setMenuOpen}
                                             setVariantsInfo ={setVariantsInfo} 
                                            setVariant={setVariant}
                                            variant={variant}   
                                            variant2={variant2}
                                            setVarinatsError={setVarinatsError}
                                            varinatsError={varinatsError}
                                             />
                    : 
                    <button 
                            type="submit" 
                            className={styles.button} 
                            onClick={async () => {
                                try {
                                    await handleCreateButton();
                                } catch (error) {
                                    console.error("An error occurred:", error);
                                }
                            }}
                        >
                            Готово
                        </button>
            }
        </div>
    )
}



function ShowVariants({imageUrls,
                     setImageUrls,
                    fields,
                    remove,
                    register,
                    errors,
                    variants,
                    append,
                    deletePurpose,

                
                })  {




   
    // useEffect(() => {
    //     console.log('Updated image URLs:', imageUrls);
    //   }, [imageUrls]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }        

    function handleChangeImage(e, index) {
        console.log('File input changed', e, index);
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAaaa')
        
        const file = e.target.files[0];
        const url =  `https://api.jbspace.xyz/files/item_photo`;
          
        if (!file) {
            console.log('Uploader: No file selected!');
            return;
          }

        const formData = new FormData();
        formData.append('files', file);

        fetch(url, { method: 'POST', body: formData })
        .then((response) => {
            if (response.ok) return response.json();
            throw new Error('Error while uploading');
        }).then((data) => {
                console.log(imageUrls)
            let newImageUrls = [...imageUrls];
            newImageUrls[index] = data.url[0];
            setImageUrls(newImageUrls);
            console.log('Updated image URLs:', newImageUrls);
        }).catch((error) => {
            console.error('Error:', error);
        }
        );
    }

    useEffect(() => {
        const initialImageUrls = variants.map(variant => variant.image || '');
        setImageUrls(initialImageUrls);
    }, [variants]);

   
    return (
        
        <div className={styles.variantsContainer}> 
        

           {
            fields.map((field, index) => {
           
               return (
                   <div key={index} style={{ marginTop: '30px' }}>
                       {/* Variant 2 Section */}
                       <section className={styles.purposeContainer}>
                           <div className={styles.labelContainer}>
                               <span className={styles.label}>Вариант {index + 1}</span>
                               {index === 0 ? (
                                 <span className={styles.purposeLabel}>Исходный</span>
                               ) : (
                                 <span className={styles.deletePurpose} 
                                           onClick={() =>{ remove(index)
                                        //    setImageUrls(imageUrls[index] = 'null')
                                                                }         
                                           }>Удалить вариант</span>
                               )}
                           </div>

                           <div className={styles.imageContainer}>
                           <div className={styles.avatarContainer}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className={styles.newAvatar}
                                    onChange={(e) => handleChangeImage(e, index)}
                                />
                                {imageUrls[index] && <img src={imageUrls[index]} alt="" className={styles.avatar} />}
                            </div>

                               <div className={styles.imageInfo}>
                                   <span className={styles.imageLabel}>Фото варианта</span>
                                   <span className={styles.imageRequired}> Обязательно </span>
                                   <span className={styles.imageText}>1 фотография в формате PNG, JPEG</span>
                               </div>
                           </div>
                       </section>




                       <form className={styles.formInfo}>
                           <div className={styles.formContainer}>
                               <div className={styles.formLabel}>Артикул:</div>
                               <input
                                   className={styles.input}                                        
                                   type="text"
                                   placeholder={'Обязательно'}
                                   onKeyDown={handleKeyDown}
                                   error={errors.articleNumber}
                                   {...register(`variant.${index}.articleNumber`, {required: true, minLength: 2, maxLength: 30})}
                               />
                           </div>

                           <div className={styles.sizeContainer}> 
                               <div className={styles.sizeField}>
                                   <div className={styles.sizeLabel}>Длинна упаковки (мм):</div>
                                       <input 
                                           className={styles.input}
                                           type="number"
                                           placeholder="Обязательно"
                                           onKeyDown={handleKeyDown}
                                           error={errors.length}
                                           {...register(`variant.${index}.length`, {required: true, valueAsNumber: true,})}
                                       />
                                   </div>
                                   <div className={styles.sizeField}>
                                       <div className={styles.sizeLabel}>Ширина упаковки (мм):</div>
                                       <input 
                                           className={styles.input}
                                           type="number"
                                           placeholder="Обязательно"
                                           onKeyDown={handleKeyDown}
                                           error={errors.width}
                                           {...register(`variant.${index}.width`, {required: true, valueAsNumber: true,})}
                                       />
                                   </div>
                                   <div className={styles.sizeField}>
                                       <div className={styles.sizeLabel}>Высота упаковки (мм):</div>
                                       <input 
                                           className={styles.input}
                                           type="number"
                                           placeholder="Обязательно"
                                           onKeyDown={handleKeyDown}
                                           error={errors.height}
                                           {...register(`variant.${index}.height`, {required: true, valueAsNumber: true,})}
                                       />
                                   </div>
                                   <div className={styles.sizeField}>
                                       <div className={styles.sizeLabel}>Вес (г):</div>
                                       <input 
                                           className={styles.input}
                                           type="number"
                                           placeholder="Обязательно"
                                           onKeyDown={handleKeyDown}
                                           error={errors.weight}
                                           {...register(`variant.${index}.weight`, {required: true, valueAsNumber: true, })}
                                       />
                                   </div>
                                   

                               </div>
                               

                               {field.purposeName &&

                                <div className={styles.formContainer}>   
                                   <div className={styles.labelContainer}>                                  
                                   <div className={styles.formLabel}>{field.purposeName}:</div>
                                   
                                       <div className={styles.deletePurpose} onClick={() => deletePurpose({
                                           
                                           })}>
                                             Удалить свойство
                                           </div>
                                       
                                   </div>
                               <input className={styles.input}
                                   type="text"
                                   placeholder="Обязательно"
                                   onKeyDown={handleKeyDown}

                                   error={errors.variant}
                                   {...register(`variant.${index}.purposeValue`, {required: true, minLength: 2, maxLength: 30})}
                                   />
                               </div>
                               }
                               {field.purposeName2 &&

                                    <div className={styles.formContainer}>   
                                       <div className={styles.labelContainer}>                                  
                                       <div className={styles.formLabel}>{field.purposeName2}:</div>

                                           <div className={styles.deletePurpose} onClick={() => deletePurpose({
                                               
                                               })}>
                                                 Удалить свойство
                                               </div>
                                           
                                       </div>
                                    <input className={styles.input}
                                       type="text"
                                       placeholder="Обязательно"
                                       onKeyDown={handleKeyDown}
                                           
                                       error={errors.variant}
                                       {...register(`variant.${index}.purposeValue2`, {required: true, minLength: 2, maxLength: 30})}
                                       />
                                    </div>
                                    }

                                
                                   {errors.variant && <span className={styles.error}>Обязательно </span>}
                               
                               
                           </form>
                   </div>
               );
           })}

           {fields.length < MAX_VARIANTS && (
           <span 
               className={styles.addVariant}
               role="button"
               onClick={() => append({
                            articleNumber: '',
                            length: '',
                            width: '',
                            height: '',
                            weight: '',
                            purposeName: fields[0].purposeName,
                            purposeValue: '',
                            purposeName2: fields[0].purposeName2 || '',
                            purposeValue2: '',
                            
                            image: ''

               })}
           >
               Добавить вариант
           </span>
           )}
       </div>
   
);
            }




            

export default VariantsSettings