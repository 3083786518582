import React, {useRef} from 'react';
import styles from "./ProfileInput.module.css"

function ProfileInput({
                          label,
                          value,
                          callback,
                          onFocusCallback,
                          type,
                          placeholder,
                          readOnly,
                          onBlurCallback,
                          maxLength, ref
                      }) {

    const inputRef = useRef(null);
    const currentRef = ref ? ref : inputRef


    const onChangeCallbackHandler = (event) => {
        callback(event.currentTarget.value)
    }


    const onKeyDownHandler = (event) => {
        if (event.key === 'Enter') currentRef.current.blur()
    }


    return (<div className = {styles.wrapper}>
            <label className = {styles.label}>
                {label}
                <input className = {styles.registeredInput}
                       value = {value}
                       type = {type}
                       onChange = {onChangeCallbackHandler}
                       placeholder = {placeholder}
                       readOnly = {readOnly}
                       onBlur = {onBlurCallback}
                       maxLength = {maxLength}
                       onFocus = {onFocusCallback}
                       ref = {currentRef}
                       onKeyDown = {onKeyDownHandler}
                />
            </label>
        </div>


    );
}

export default ProfileInput;