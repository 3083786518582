import React, {useEffect, useState} from 'react';
import styles from "./ChangeName.module.css"
// import goBack from '../../../../assets/svg/goBack.svg';
import ProfileInput from '../components/ProfileInput/ProfileInput';
import ColoredButton from '../components/ColoredButton/ColoredButton';
import SettingHeader from '../ProfileSetting/SettingHeader/SettingHeader';
import textValidation from '../../../../utils/textValidation';
import {displayTypes} from '../ProfileSetting';

import {useMutation} from '@apollo/client';
import {UPDATE_PROFILE_NAME} from '../../../../requests/userRequests';


function ChangeName(
    {
        name,
        setName,
        surname,
        setSurname,
        middleName,
        setMiddleName,
        setDisplayType,
        userID,
    }) {


    const [localName, setLocalName] = useState(name ? name : '')
    const [localSurname, setLocalSurname] = useState(surname ? surname : '')
    const [localMiddleName, setLocalMiddleName] = useState(middleName ? middleName : '')


    const [updateProfileName] = useMutation(UPDATE_PROFILE_NAME,
        {variables: {userID: userID, name: localName, surname: localSurname, middleName: localMiddleName}}
    );

    const changeBaseDisplayTypes = () => setDisplayType(displayTypes.base)

    const surNameInputcallback = (value) => textValidation(value, setLocalSurname)
    const nameInputCallback = (value) => textValidation(value, setLocalName)
    const middleNameInputCallback = (value) => textValidation(value, setLocalMiddleName)

    const saveOnClickCallback = () => {
        //request to db
        updateProfileName()
        setName(localName)
        setSurname(localSurname)
        setMiddleName(localMiddleName)
        setDisplayType(displayTypes.base)
    }

    return (<div className = {styles.wrapper}>
        <SettingHeader title = {"Полное имя"} callback = {changeBaseDisplayTypes}/>
        <ProfileInput type = {"text"} label = {"Фамилия"} value = {localSurname} callback = {surNameInputcallback}/>
        <ProfileInput type = {"text"} label = {"Имя"} value = {localName} callback = {nameInputCallback}/>
        <ProfileInput type = {"text"} label = {"Отчество"} value = {localMiddleName}
                      callback = {middleNameInputCallback}/>
        <div className = {styles.buttonContainer}>
            <ColoredButton name = {"Сохранить"} callback = {saveOnClickCallback}/>
        </div>

    </div>);

};


export default ChangeName;

