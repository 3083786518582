import React from 'react';
import classes from './ShopPage.module.css';
import Shop from '../ProductCard/Shop/Shop';
import DisplayItems from '../StandartGoodsDisplay/DisplayItems';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useQuery } from '@apollo/client';
import { GET_SHOP } from '../../requests/shopRequests';
import { useContext } from 'react';
import { TelegramContext } from '../../App';


export default function ShopPage() {
  // console.log('ShopPage');

  const  {shopId} = useParams()
  const { userID } = useContext(TelegramContext) ;


  const { loading, error, data } = useQuery(GET_SHOP, {
    variables: { shopId, userID },
    fetchPolicy: 'cache-and-network',
  });
  if(loading) {
    return <div>Loading...</div>;
  }
  if(error) {
    const message = JSON.stringify(error, null, 2);
    console.log(message);
  }

  console.log('data', data)

  const shop = data.shop[0];

  console.log(shop.items);
  
  const items = { 'item' : shop.items};

  // const data2 = ShopItem({ shopId }); //<ShopItem shop_id={shop_id} />;
  //console.log(data);
  // const display = DisplayItems(data = items );
  // const display = shop;
  //console.log(display);

  return (
    <div className={classes.container}>
      
      <Shop shop={shop} />
      <Tabs className={`${classes.tabsWrapper} react-tabs`}>
        <TabList> 
          <Tab className={`${classes.react_tabs_tab} react-tabs__tab`}>
            <div>Главная</div>
          </Tab>
          <Tab className={`${classes.react_tabs_tab} react-tabs__tab`}>
            <div>Все товары</div>
          </Tab>
          <Tab className={`${classes.react_tabs_tab} react-tabs__tab`}>
            <div>О магазине</div>
          </Tab>
        </TabList>

        <TabPanel>
          <h1>Главная страница</h1>
          <div className={classes.displayContent}>
            <DisplayItems data={items} />
          </div>
        </TabPanel>
        <TabPanel>
          <h1>Товары магазина</h1>
          <div className={classes.displayContent}>
             <DisplayItems data={items} />
          </div>
        </TabPanel>
        <TabPanel>
          <h1>Информация о магазине</h1>
          <div className={classes.displayContent}>{shop.info}</div>
        </TabPanel>
      </Tabs>
    </div>
  );
}
