export default function DateToEnd(endStringDate) {

    const endDate = new Date(endStringDate);
    endDate.setHours(0, 0, 0, 0)

    const timeDifference = endDate - Date.now();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} дн. ${hours} ч. ${minutes} мин.`

}



