import React from 'react';
import s from "./ProfileNotification.module.css"
import link from '../../../../../assets/svg/link.svg';


function ProfileNotification({title, callback}) {

    return (
        <div className = {s.wrapper} onClick = {callback}>
            <label className = {s.label}>Уведомления
                <div className = {s.title}>{title}</div>
            </label>
            <img src = {link} alt = ""/>
        </div>

    )
        ;
}

export default ProfileNotification;