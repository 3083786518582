import {Outlet, useNavigate} from 'react-router-dom';
import styles from './HeaderSearchOnly.module.css';
import goBack from '../../assets/svg/goBack.svg';
import Search from './Search';
import React from 'react';

const HeaderSearchOnly = () => {
    const navigate = useNavigate();
    return <header className = {styles.wrapper}>
        <div className = {styles.wrapperSearchOnly}>
            <div className = {styles.row}>
                <img src = {goBack} alt = "" onClick = {() => navigate(-1)} style = {{cursor: "pointer"}}/>
                <div className = {styles.container}>
                    <Search/>
                </div>
            </div>
            <Outlet/>
        </div>
    </header>
};

export default HeaderSearchOnly