



import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Goods.module.css';

import { getVariantWord } from '../../../../utils/format/getVariantWord';
    

function Goods({items, shopId}) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    if (!items || items.length === 0) {
        return (
          <div className={styles.wrapper}>
            <div className={styles.text}>У вас пока нет ни одного товара</div>
            <button className={styles.addButton} onClick={() => navigate('/create_item', { state: { shopId } })}>
              Добавить товар
            </button>
          </div>
        );
      }

    // const {itemMedia, itemInfo, category, variants} = useSelector((state) => state.createItem);
    const filteredItems = items.filter(
        it => it.name.toLowerCase().includes(searchTerm.toLowerCase())
          || it.articleNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );


    

      // console.log(items)
    
      return (
        <div className={styles.wrapper}>
          <div className={styles.contentContainer}>
            <div className={styles.inputField}>
              <input
                className={styles.search}
                type="text"
                placeholder="Введите наименование товара или артикул"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            {filteredItems.map((item, index) => {

              let purpose =''
              if(!item.item_variants[0].purposeName){
                      purpose  = ''
                  }else if(!!item.item_variants[0].purposeName2){
                      purpose = '1 cвойство; '
                  }else {
                      purpose = '2 свойства; '
                  }
                
              const variants = item.item_variants.length;
              return(
                
              <div className={styles.itemContainer} key={index} 
                 onClick={() => navigate('/settings_goods', { state  : item.id  })}>
                <div className={styles.itemImage}>
                  <img className={styles.image} src={item.item_media[0].path} alt="itemImage" />
                </div>
                <div className={styles.itemInfo}>
                  <span className={styles.itemName}>{item.name}</span>
                  <span className={styles.itemArticle}>{item.articleNumber}</span>
                  <span className={styles.itemPurposeCount}>{purpose}  {variants} {getVariantWord(variants)};</span>
                </div>
                <div className={styles.itemStatus}>
                  <span className={styles.itemStatusText}>Модерация</span>
                </div>
              </div>
              )
            }
            )}
          </div>
          <button className={styles.addButton} onClick={() => navigate('/create_item', { state: { shopId } })}>
            Добавить товар
          </button>
        </div>
      );
    }

export default Goods;

