import React, {useContext, useState, useEffect} from 'react';
import s from "./ProfileMain.module.css"

import {TelegramContext} from '../../../App';
import SingleContainer from '../SingleContainer/SingleContainer';
import MultiContainer from '../MultiContainer/MultiContainer';
import {useNavigate} from 'react-router-dom';

import classes from "./ProfileMain.module.css"
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import ProfileShop from '../ProfileShop/ProfileShop';
import {useFetchUserProfile} from './fetchData';
import Separator from '../../../Сomponents/Separator/Separator';


function ProfileMain() {

    const {userID, userFirstName} = useContext(TelegramContext);
    const [currencyTitle, setCurrencyTitle] = useState("RUB")




    const navigate = useNavigate();

    

    const {loading, profileData, errorMessage} = useFetchUserProfile(userID);

    useEffect(() => {
        if (!userID ) {
            navigate("/v1/login");
        }
    }, [userID, navigate]);
    
    if (loading) return <div>Loading...</div>;
    if (errorMessage) return <div>{errorMessage}</div>;

    // if (!userID) {
    //     return navigate("/login")
    // };
    
    const {userProfile,  cityName, cityID, } = profileData;


    const signInCallback = () => navigate("/v1/login")
    const profileSettingCallback = () => navigate("setting", {state: {userProfile}})
    const signUpCallback = () => navigate("registration",
        {state: {cityName, cityID, currencyTitle}}
    )

    const registrationCallback = () => navigate("/v1/registration")

    // if user profile is not null tprofileDatahen open buyer profile
    const loginPage = (!userProfile)
        ? registrationPage(userFirstName,
            signInCallback,
            signUpCallback,
            registrationCallback,
            cityName,
            currencyTitle,
            setCurrencyTitle,
            userID)
        : <UserProfile
            profileData = {profileData}
            profileSettingCallback = {profileSettingCallback}
        />
    return loginPage
}


function registrationPage(userFirstName,
                          signInCallback,
                          signUpCallback,
                          registrationCallback,
                          cityName,
                          currencyTitle,
                          userID) {

    const parametersTitles = ["Город", "Валюта", "О проекте", "Помощь"]


    return (
        <div className = {s.wrapper}>

            <div className = {s.greeting}>
                <h3 className = {s.title}>{`Привет ${userFirstName ? userFirstName : "User"}!`}</h3>
                <p className = {s.text}>Пора совершать покупки выгодно</p>
            </div>
            <Separator/>

            {!userID ? (
                    <>
                        <SingleContainer title={"Зарегистрироваться"} callback={registrationCallback} />
                        <Separator />
                        <SingleContainer title={"Войти"} callback={signInCallback} />
                    </>
                ) : (
                    <>
                        <SingleContainer title={"Создать профиль"} callback={signUpCallback} />
                    </>
                )}

            <Separator/>


            {/* <MultiContainer mainTitle = {"Параметры"} 
                            titles = {parametersTitles}
                            cityName = {cityName}
                            currencyTitle = {currencyTitle}
                            />
                            */}
            <div style = {{height: "60px"}}></div>
                            

        </div>
    );

}


function UserProfile({profileData }){ 
    const {userProfile, 
            
            company,
            cityName, 
            likes, 
            comments,
            bonus} = profileData;

    const navigate = useNavigate();


    const goods = [
        {
        title: "Заказы",
        callback: () => navigate("/profile/user/orders"),
        active : true,
        },
        {title : "Возвраты",
        callback: () => navigate("/profile/user/refunds"),
        active : true,
        },
        {title : "Купленные товары",
        callback: () => navigate("/profile/user/bought"),
        active : true,
        },
        {
        title : "Отзывы",
        callback: () => navigate("/profile/reviews"),
        active : true,
        }

    
        ]



    const servises = [
        {
            title: "Мои карты",
            callback: () => navigate("/profile/buyer/cards"),
            active : false,
        },
        {
            title: "Мои адреса",
            callback: () => navigate("/profile/buyer/addresses"),
            active : false,
        },
        {
            title: "Финансы",
            callback: () => navigate("/profile/buyer/finances"),
            active : false,
        },
        {
            title: "Совместная покупка",
            callback: () => navigate("/profile/buyer/purchase"),
            active : false,
        },
        {
            title: "Бонусная программа",
            callback: () => navigate("/profile/buyer/bonus"),
            active : false,
        },
        {
            title: "Реферальная программа",
            callback: () => navigate("/profile/buyer/referral"),
            active : false,
        }
    ]



    const cities =[
        {
            title: "Город",
            callback: () => navigate("/profile/city"),
            secondTitle: cityName,
            active : true,
        },
        {
            title: "Валюта",
            callback: () => console.log("currency"),
            secondTitle: "RUB",

            active : false,

        },
        // {
            // title: "О проекте",
            // callback: () => navigate("/profile/buyer/parameters/about"),
            // active : false,
        // },
        {
            title: "О компании",
            callback: () => navigate("/aboutCompany"),
            active : true,
        }
    ]

    

    const headerMainTitle = userProfile ? `${userProfile.surname} ${userProfile.name}` : "userFirstName"


    return (


        <div className = {classes.wrapper}>

            <ProfileHeader mainTitle = {headerMainTitle}
                           avatarUrl = {userProfile.photo_url}

                           likes = {likes}
                           comments={comments}
                           score = {bonus}
                            userProfile={userProfile}
                                />

            <Separator/>
                <ProfileShop company={company}/>
            <Separator/>

                <MultiContainer mainTitle = {"Сделки"}  params ={goods}/>
            <Separator/>
                <MultiContainer mainTitle = {"Сервисы"} params={servises}/>
            <Separator/>

                <MultiContainer mainTitle = {"Параметры"}  
                                params={cities}                                    
                                />
                


            <div style = {{height: "60px"}}></div>
        </div>


    );
}


export default ProfileMain;
