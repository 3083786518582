import {NavLink, useNavigate} from "react-router-dom";
// import backet from "../../../assets/svg/backet.svg";
import {ReactComponent as Icon} from '../../../assets/svg/backet.svg';
import classes from "../Footer.module.css";
import {makeVar, useLazyQuery, useReactiveVar} from "@apollo/client";
import React, {useContext, useEffect} from "react";
import {BASKET_AMOUNT} from "../../../requests/requests";
import { TelegramContext} from '../../../App';

export const amountDynamic = makeVar();

const Backet = () => {
    // console.log('Basket rendered')
    // console.log('request BASKET_LOAD нахуя ? ',)


    const {userID} = useContext(TelegramContext)
    // const navigate = useNavigate();
    const amoountCart = useReactiveVar(amountDynamic);



    // const {setModalActive, setModalContent} = useContext(ModalContext);



    // const {loading, error, data} = useQuery(BASKET_LOAD, {variables: {userID},});
    const [getAmountCart, {loading, error, data}] = useLazyQuery(BASKET_AMOUNT, {variables: {userID},});


    useEffect(() => {
        getAmountCart({
          fetchPolicy: 'cache-and-network',
          variables: {userID}
        });
      }, []);

    // console.log('data', data)

    useEffect(() => {
        amountDynamic(data?.cart_aggregate.aggregate.count || 0);
    }, [data]);

    if (loading) {
        return null;
    }
    if (error) {
        console.error(error);
        return null;
    }


    const linkClassname = ({isActive}) => isActive ? classes.activeImg : classes.img

    return (<div
        className = {classes.container}
        // onClick = {() => {
            // setModalActive(false)
            // setModalContent('')
        // }}
        >
        <NavLink to = {"/cart"} className = {linkClassname}>
            <Icon/>
            <div>Корзина</div>
            {amoountCart > 0 ? (<div className = {classes.count}>
                <span>{amoountCart}</span>
            </div>) : ("")}
        </NavLink>

    </div>);
};
export default React.memo(Backet)
