import styles from './PendingReviews.module.css'
import PendingReviewItem from './PendingReviewItem';


export default function PendingReviews({data, allItemsCount, setDisplayMode, setItem}) {

    // console.log(data)


    const allReviewsCount = allItemsCount
    const pendingReviewsCount = data?.length
    const widthValue = (allReviewsCount - pendingReviewsCount) / allReviewsCount * 100

    return (<div className = {styles.wrapper}>

        <div className = {styles.textContainer}>
            <p>Отзывы - это отличный способ лучше понять и изучить товар перед покупкой,
               а также способ заработать бонусов продавца. <b>Вам осталось оценить еще
                    {'  '}{pendingReviewsCount} товаров.</b></p>
        </div>

        <div style = {{padding: "0 15px"}}>
            <div className = {styles.progressBarWrapper}>
                <div className = {styles.progressBarBackground}></div>
                <div className = {styles.progressBarValue} style = {{width: `${widthValue}%`}}></div>
                <span
                    className = {styles.progressBarContent}>{allReviewsCount - pendingReviewsCount}/{allReviewsCount}</span>
            </div>
        </div>


        <div className = {styles.itemsContainer}>
            {data.map((item => <PendingReviewItem
                item = {item}
                key = {item.id}
                setDisplayMode = {setDisplayMode}
                setItem = {setItem}
            />))}
        </div>


        <div style = {{height: "60px"}}></div>
    </div>);
}