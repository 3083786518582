import React, {useContext, useEffect, useState} from "react";
import classes from "./ByWith.module.css";
import LoadItems from "../../StandartGoodsDisplay/7LoadItems";
import DisplayItemsHorisontal from "../../StandartGoodsDisplay/DisplayItemsHorisontal";
import {TelegramContext} from '../../../App';
import {GET_ITEMS} from '../../../requests/itemRequests';
import LoadItemsData from '../../StandartGoodsDisplay/LoadItemsData';




const ByWith = React.memo(function ByWith({limit}) {

    const {userID} = useContext(TelegramContext);

    const  dateNow = new Date();
    return (
        <div>
            <div>
                <span className = {classes.title}>С этим покупают</span>
            </div>
            <br></br>
            <div>

                <LoadItemsData limit = {limit}
                               query = {GET_ITEMS}
                               variables = {{userID, offset: 0, limit, dateNow}}
                               keyName = {"item"}
                               aggregateKeyName = {"item_aggregate"}
                               isHorizontal = {true}/>
            </div>
        </div>
    );
}
)
export default ByWith;
