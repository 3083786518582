import { createSlice } from '@reduxjs/toolkit'


const createSrtorageSlice= createSlice({
    name : 'storageSlice',
    initialState : {
        shopId : null,
        city : '',
        address : {},
    },
    reducers: {
        setAddress: (state, action) => {
            state.shopId = action.payload;
        },

  
    }
})

export const { setAddress } = createSrtorageSlice.actions;

export default createSrtorageSlice.reducer