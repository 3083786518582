import React from 'react';
import {useNavigate} from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import urls from '../../Json/images_item_202211021229.json';
import classes from './Top_slider.module.css';


const TopSlider = () => {
    // console.log('TopSlider')
    const navigate = useNavigate();

    const isIphone = window.navigator.userAgent.toLowerCase().includes('iphone')

    // console.log(' is Iphone', isIphone)

    const swiperSlides = urls.map((img, index) => {

        const allDevicesVideo = <video className = {`${classes.sliderVideo}`} autoPlay loop playsInline muted>
            <source src = {img.url[1]} type = "video/mp4"/>
            <source src = {img.url[2]} type = "video/quicktime"/>
        </video>

        const iPhoneVideo = <img className = {classes.sliderVideo} src = {img.url[1]} type = "video/mp4" alt = {"alt"}/>

        return (
            <div key = {img.id} onClick = {() => {
                // navigate('/product', {state: {productId: img.item_id}});
            }}>
                <div className = {classes.container}>
                    {isIphone ? iPhoneVideo : allDevicesVideo}
                </div>
            </div>
        )
    })


    let settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        // speed: 2000,
        autoplaySpeed: 2000

    }
    return (
        <div className = {classes.topSlider}>
            <Slider {...settings}>
                {swiperSlides}
            </Slider>
        </div>
    );
};

export default TopSlider;


