import styles from './ReviewItem.module.css'
import React from 'react';

const STATUSES = {
    "published": {title: "Опубликовано", color: "#5EE37B"},
    "rejected": {title: "Отклонено", color: "#E74F4F"},
    "moderation": {title: "Модерация", color: "#FFA869"}
}


export default function ReviewItem({
                                       url,
                                       status,
                                       name = "PUMA футболка мужская синяя",
                                       price = "1 198",
                                       shop = "Магазин одежды #1",
                                       firstModifierKey,
                                       firstModifierValue,
                                       secondModifierKey,
                                       secondModifierValue

                                   }) {
    const imgUrl = url || 'https://static.nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto' + ':eco/f7a86c73-8948-4015-82b2-4343b97cca35/meski-t-shirt-z-nadrukiem-francja-fk4SpZ.png'


    return (
        <div className = {styles.itemContainer}>
            <div className = {styles.imgContainer}>
                <img src = {imgUrl}/>
            </div>
            <div className = {styles.itemContent}>
                {status && <div style = {{backgroundColor: `${STATUSES[status].color}`}}
                                className = {styles.status}>{STATUSES[status].title}</div>}
                <div className = {styles.name}>{name}</div>
                <div className = {styles.price}>{`${price} ₽ за 1 шт.`}</div>
                <div className = {styles.shop}>{shop}</div>
                <div className = {styles.modifier}>
                    <span>{firstModifierKey}</span>:{'  '}<span
                    className = {styles.modifierValue}>{firstModifierValue}</span>
                </div>
                <div className = {styles.modifier}>
                    <span>{secondModifierKey}</span>:{'  '}<span
                    className = {styles.modifierValue}>{secondModifierValue}</span>
                </div>

            </div>
        </div>);
}