import React, {useState} from 'react';
import styles from "./ProfileSelectWithLinks.module.css"
import link from '../../../../../assets/svg/link.svg';

function ProfileSelectWithLinks({label, values, callback, value, isWithLink}) {

    const options = values.map(v => <option key = {v.id} value = {v.value}>{v.title}</option>)

    return (<div className = {styles.wrapper}>
            <label className = {styles.label}>
                {label}
                <select value = {value} onChange = {callback}
                        className = {styles.registeredInput}>
                    {options}
                </select>
            </label>
            {isWithLink && <img src = {link} alt = ""/>}
        </div>


    );
}

export default ProfileSelectWithLinks;