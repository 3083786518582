import React from 'react';
import classes from './ShopMessage.module.css';
import {ADD_SHOP_MESSAGE} from '../../../requests/requests.js';
import {useMutation} from '@apollo/client';

export default function ShopMessage({setModalActive, shop_id}) {
    const reasonsArr = ['refine products information', 'refine delivery information', 'other'];
    const [reason, setReason] = React.useState(reasonsArr[0]);
    const [message, setMessage] = React.useState('');
    const status = 'pending processing';
    const [addShopMessage] = useMutation(ADD_SHOP_MESSAGE, {
        variables: {shop_id, message, reason, status},
    });

    return (
        <div
            className = {classes.modalContent}
            onClick = {(e) => {
                e.stopPropagation();
            }}>
            <h4 className = {classes.title}>
                Будьте добры, уточните по какой причине хотите связаться с продавцом:
            </h4>
            <div
                className = {classes.row}
                onClick = {() => {
                    setReason(reasonsArr[0]);
                }}>
        <span
            className = {
                reason === reasonsArr[0]
                    ? `${classes.circle} ${classes.activeCircle}`
                    : `${classes.circle}`
            }></span>
                <span className = {classes.complaints}>Уточнение информации о товаре</span>
            </div>
            <div
                className = {classes.row}
                onClick = {() => {
                    setReason(reasonsArr[1]);
                }}>
        <span
            className = {
                reason === reasonsArr[1]
                    ? `${classes.circle} ${classes.activeCircle}`
                    : `${classes.circle}`
            }></span>
                <span className = {classes.complaints}>Уточнение информации о доставке</span>
            </div>
            <div
                className = {classes.row}
                onClick = {() => {
                    setReason(reasonsArr[2]);
                }}>
        <span
            className = {
                reason === reasonsArr[2]
                    ? `${classes.circle} ${classes.activeCircle}`
                    : `${classes.circle}`
            }></span>
                <span className = {classes.complaints}>Другие вопросы</span>
            </div>
            <textarea
                value = {message}
                className = {classes.other}
                rows = "8"
                placeholder = "Введите сообщение..."
                onChange = {(event) => {
                    setMessage(event.target.value);
                }}
            />
            <button
                className = {classes.send}
                onClick = {() => {
                    addShopMessage();
                    setModalActive(false);
                    setMessage('');
                    setReason(reasonsArr[0]);
                }}>
                Отправить сообщение
            </button>
        </div>
    );
}
