import React from 'react';
import styles from "./Separator.module.css"

function Separator() {
    return (
        <div className = {styles.separator}>

        </div>
    );
}

export default Separator;