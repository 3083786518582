import { combineReducers } from "redux";
import cartSlice from "./cartSlice";
import orderSlice from "./orderSlice";
import bonusSlice from "./bonusSlice";
import createItemSlice from "./createItemSlice";
import storageSlice from "./storageSlice";
import userOrderSlice from "./userOrderSlice";
import settingItemSlice from "./settingItemSlice";


const myReducer = combineReducers({
  cart: cartSlice,
  bonus: bonusSlice,
  order: orderSlice,
  createItem : createItemSlice,
  storage : storageSlice,
  userOrder : userOrderSlice,
  settingsItem : settingItemSlice,
});


export default myReducer;
