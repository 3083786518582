import React from 'react';
import classes from './ReviewModal.module.css';
import {ADD_COMPLAINT} from '../../../requests/commentRequests';
import {useMutation} from '@apollo/client';
import './ReviewModal.module.css';

export default function ReviewModal({modalActive, setModalActive, }) {
    // console.log('Rewiew MOdal request ADD_COMPLAINT' )
    
    const reasonsArr = ['inappropriate content', 'not correspond', 'other'];
    const [reason, setReason] = React.useState(reasonsArr[0]);
    const [textValue, setTextValue] = React.useState('');
    const status = 'pending processing';

    const [addComplain] = useMutation(ADD_COMPLAINT, {
        variables: { reason, status, textValue},
    });

    return (
        <div className = {modalActive ? `${classes.modal} ${classes.active}` : `${classes.modal}`}>
            <div
                className = {classes.closeLineContainer}
                onClick = {(e) => {
                    setModalActive(false);
                }}>
                <div className = {classes.closeLine}></div>
            </div>
            <div
                className = {classes.modalContent}
                onClick = {(e) => {
                    e.stopPropagation();
                }}>
                {/*{modalContent}*/}
                <h4 className = {classes.title}>
                    Будьте добры, уточните по какой причине данный отзыв нарушает правила:
                </h4>

                <div
                    className = {classes.row}
                    onClick = {() => {
                        setReason(reasonsArr[0]);
                    }}>
          <span
              className = {
                  reason === reasonsArr[0]
                      ? `${classes.circle} ${classes.activeCircle}`
                      : `${classes.circle}`
              }></span>
                    <span className = {classes.complaints}>
            Информация в отзыве не соответсвует действительности
          </span>
                </div>

                <div
                    className = {classes.row}
                    onClick = {() => {
                        setReason(reasonsArr[1]);
                    }}>
          <span
              className = {
                  reason === reasonsArr[1]
                      ? `${classes.circle} ${classes.activeCircle}`
                      : `${classes.circle}`
              }></span>
                    <span className = {classes.complaints}>Неприемлемое содержание</span>
                </div>

                <div
                    className = {classes.row}
                    onClick = {() => {
                        setReason(reasonsArr[2]);
                    }}>
          <span
              className = {
                  reason === reasonsArr[2]
                      ? `${classes.circle} ${classes.activeCircle}`
                      : `${classes.circle}`
              }></span>
                    <span className = {classes.complaints}>Указать другую причину ниже</span>
                </div>

                <textarea
                    value = {textValue}
                    disabled = {reason === reasonsArr[2] ? false : true}
                    className = {classes.other}
                    rows = "8"
                    placeholder = "Опишите подробнее причину жалобы..."
                    onChange = {(event) => {
                        setTextValue(event.target.value);
                    }}
                />
                <button
                    className = {classes.send}
                    onClick = {() => {
                        //console.log(textValue);
                        addComplain();
                        setModalActive(false);
                        setTextValue('');
                        setReason(reasonsArr[0]);
                    }}>
                    Отправить жалобу
                </button>
            </div>
        </div>
    );
}
