
import Cookies from 'js-cookie';

import {fetchAccessToken} from '../../src/utils/apiCalls/authApi';




export function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1]; // Get the payload part
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error("Failed to parse JWT:", e);
        return null;
    }
}

// Example Usage
// const token = Cookies.get('token');
// const decodedToken = parseJwt(token);
// console.log('decode token' ,decodedToken);


async function setCookie() {

    
    let userID = 0;
    let userFirstName = '';
    const telegram = window.Telegram.WebApp;
    let fromTg = false; 
    
    let newToken = '';


    //! Cookies.remove('token');


    if (telegram.initData) {

        userID = telegram.initDataUnsafe.user.id;
        userFirstName = telegram.initDataUnsafe.user?.first_name;
        fromTg = true;

        return {
            userID,
            userFirstName,
            newToken,
            fromTg,
            
        };


    } else {
        

        if (!Cookies.get('token')) {

            // console.log('No token in cookies')
           
            
            const role = 'guest';
            const response = await fetchAccessToken(role); 

            ({ newToken, userID, userFirstName } = response);

            Cookies.set('token', newToken, { expires: 7, secure: true, sameSite: 'lax' });
        }else{

            // console.log('Token is already in cookies')
            newToken = Cookies.get('token');
            

            const decodedToken = parseJwt(newToken);
            userID = decodedToken['https://hasura.io/jwt/claims']['X-Hasura-User-Id'];
            
            if (userID === '0') {
                // console.log('User ID is 0');
                userID = 0;
            }
            userFirstName = decodedToken['https://hasura.io/jwt/claims']['x-user-firstname'];
            
            // console.log('decoded token', decodedToken)
            // console.log('decode userId', userID);
            // console.log('decode userFirstName', userFirstName);
            // console.log("Token received from cookie:", Cookies.get('token'));

            Cookies.set('token',newToken, { expires: 7, secure: true, sameSite: 'lax' });
           
            
        }

    }

    telegram.onEvent('viewportChanged', () => telegram.expand());


    // console.log('token:', newToken);

    return {
        userID,
        userFirstName,
        
        fromTg,
    };
}



export default setCookie;