import React from 'react';
import {useQuery} from '@apollo/client';
import {BOUGHT_ITEM} from '../../../requests/itemRequests';
import DisplayItems from '../../StandartGoodsDisplay/DisplayItems';
import classes from './BoughtItem.module.css';
import {useContext} from 'react';
import {TelegramContext} from '../../../App';

const BoughtItem = () => {
    const {userID} = useContext(TelegramContext) || 534677865;

    const {data, error, loading} = useQuery(BOUGHT_ITEM, {
        fetchPolicy: 'cache-and-network',
        variables: {userID},
    });

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('watched items error', error);
        return 'error';
    }

    return (
        <>
            <h3 className = {classes.header}>Купленные товары</h3>
            <DisplayItems data = {data} 
                        
                />
        </>
    );
   
};

export default BoughtItem;
