import React from 'react';
import styles from "./SuccessSellerRegistrationChildren.module.css"

function SuccessSellerRegistrationChildren({shopTitle}) {
    return (

        <div className = {styles.wrapper}>
            <h3 className = {styles.title3}>ITEM приветствует нового продавца</h3>
            <h3 className = {styles.title4}>
                {shopTitle}
            </h3>
            <h2 className = {styles.title5}>Успешной торговли!</h2>
        </div>);
}

export default SuccessSellerRegistrationChildren;