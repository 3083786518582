const pagesPaths = {
    main: "/",
    error: '*',
    catalog: "/catalog",
    category: "/v1/category",
    product: productId => `product/${productId}`,
    profileMain: "/profile",
    userRegistration: "/profile/registration",
    profileSetting: "/profile/setting",
    sellerRegistration: "/profile/seller_registration",
    myShops: "/profile/my_shops",
    createShop: "/profile/create_shop",
    company_registration: "/profile/company_registration",
    shopSettings: shopId => `shop_settings/${shopId}`,
    bonus: "/profile/buyer/bonus",
    myReviews: "/profile/reviews",
    likes: "/likes",
    shop: shopId => `shop/${shopId}`,
    reviews: productId => `product/${productId}/reviews`,
    cart: "/cart",
    addresses: "/addresses",
    payment: "/payment",
    goodsStorage: "/goods_storage",
    createItem: "/create_item",
    goodsSettings: "/settings_goods",
    storageItems: "/storage_items",
    changeStorageItems: "/change_storage_items",
    createLots : "/create_lots",
    shopLots : "/shop_lots",    
    settingsLots : "/settings_lots",


}

export default pagesPaths

