import styles from './DetailsReviewItem.module.css'
import {Rating} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import React, {useContext, useState} from 'react';
import getReadableDate from '../../../../utils/getupdatedDate';
import Separator from '../../../../Сomponents/Separator/Separator';
import SingleLinkContainer from '../../SingleLinkContainer/SingleLinkContainer';
import NumberFormat from '../../../../utils/NumberFormat';
import ReviewItem from '../ReviewItem/ReviewItem';
import {MODE} from '../index';
import {ModalContext, TelegramContext} from '../../../../App';
import {modalDisplayMode} from '../../../PrimaryModal/PrimaryModal';
import DeleteReview from '../DeleteReview/DeleteReview';
import ReviewLikes from '../ReviewLikes/ReviewLikes';


export default function DetailsReviewItem({item, setDisplayMode, setItem, refetch}) {

    //console.log(item)
    const {userID} = useContext(TelegramContext);
    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const [localItem, setLocalItem] = useState(item)
    const {itemComments: [{id: reviewID, comment_likes: commentLikes}]} = localItem

    const [images, setImages] = useState(localItem.itemComments[0].images_comments)
    const [video, setVideo] = useState({
        file: null,
        localUrl: '',
        imgUrl: '',
        fileName: null
    })
    //console.log(images)

    const modifiersKeys = Object.keys(item.modifiers);
    const advantages = item.itemComments[0].advantages || "пустое поле"
    const disadvantages = item.itemComments[0].disadvantages || "пустое поле"
    const commentText = item.itemComments[0].text || "пустое поле"

    const rating = item.itemComments[0].estimate || 0
    const commentDate = getReadableDate(item.itemComments[0].updated_on)

    const editReviewHandler = () => {
        setItem(item)
        setDisplayMode(MODE.edit)
    }

    const deleteReviewHandler = () => {
        setModalContent(<DeleteReview reviewID = {reviewID}
                                      refetch = {refetch}
                                      setDisplayMode = {setDisplayMode}
                                      images = {images}
        />);
        setModalMode(modalDisplayMode.withOverlay)
        setModalActive(true);
    }

    const updateLikes = (isLike) => {
        const isLikeExist = localItem.itemComments[0].comment_likes.filter(el => el.user_id == userID)
        setLocalItem({
            ...localItem,
            itemComments: [{
                ...localItem.itemComments[0],
                comment_likes: isLikeExist.length
                    ? localItem.itemComments[0].comment_likes.map(like => like.user_id == userID ? {
                        ...like,
                        likes: isLike
                    } : like)
                    : [{user_id: userID, likes: isLike, comment_id: reviewID}]
            }]
        })
    }


    return (
        <div className = {styles.wrapper}>

            <ReviewItem name = {item.item.name}
                        url = {item.url}
                        price = {NumberFormat(item.extended_cost)}
                        shop = {item.shop.name}
                        status = {item.itemComments[0].status}
                        firstModifierKey = {modifiersKeys[0]}
                        firstModifierValue = {item.modifiers[modifiersKeys[0]]}
                        secondModifierKey = {modifiersKeys[1]}
                        secondModifierValue = {item.modifiers[modifiersKeys[1]]}

            />

            <div className = {styles.commentContent}>
                <div className = {styles.advantages}>
                    <h4>Достоинства</h4>
                    <p>{advantages}</p>
                </div>
                <div className = {styles.ratingContainer}>
                    <Rating name = "read-only-rating" value = {rating}
                        // precision = {0.5}
                            readOnly size = "small"
                            emptyIcon = {<StarIcon fontSize = "inherit"/>}
                            style = {{transform: "scale(0.9)"}}
                    />
                    <p className = {styles.ratingDate}>{commentDate}</p>
                </div>
                <div className = {styles.disadvantages}>
                    <h4>Недостатки</h4>
                    <p>{disadvantages}</p>
                </div>
                <div className = {styles.comment}>
                    <h4>Комментарий</h4>
                    <p>{commentText}</p>
                </div>
            </div>

            <div className = {styles.imageContainer}>
                {images.map((image) => {
                    return <div className = {styles.imageContent} key = {image.id}>
                        <img src = {image.url} alt = {'alt'}/>
                    </div>
                })}
            </div>

            <ReviewLikes commentLikes = {commentLikes}
                         refetch = {refetch}
                         reviewID = {reviewID}
                         updateLikes = {updateLikes}
            />

            <div className = {styles.editContainer}>
                <div className = {styles.edit} onClick = {editReviewHandler}>Редактировать отзыв</div>
                <div className = {styles.delete} onClick = {deleteReviewHandler}>Удалить отзыв</div>
                <div className = {styles.answer}>Ответы на отзыв <span>(1)</span></div>
            </div>

            <Separator/>

            <div className = {styles.linkContainer}><SingleLinkContainer title = {"Все отзывы на товар"}/></div>

            <Separator/>


        </div>);
}