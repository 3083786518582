import React from "react";
import styles from "./CartButton.module.css";
import BasketImg from '../../AddElement/BasketImg/BasketImg';


function CartButton({itemId,  setModalActive, setCurrentItem}) {
    // console.log("MainAddElement",)
    // console.count("MainAddElement")

    const onClickHandler = async () => {
            // console.log("MainAddElement",itemId)
            setCurrentItem(itemId)
            setModalActive(true);
    }

    return (<div className = {styles.displayItemContainer}>
        <BasketImg onClickCallback = {onClickHandler}/>
    </div>
    );
}

export default React.memo(CartButton)