import { createSlice } from '@reduxjs/toolkit';



const userOrderSlice = createSlice({
    name: 'userOrder',

    initialState :{
        order: {},
        item: {},
        isLoading: false,
        error: null,
    },
    
    reducers: {
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setItem: (state, action) => {
            state.item = action.payload;
        },
        
    },
});

export const { setOrder,  setItem } = userOrderSlice.actions;
export default userOrderSlice.reducer;
