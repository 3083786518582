import React, {useState} from "react";
// import { SHOPS_NAMES} from "../../../requests/requests";
import {GET_SHOP_CATEGORIES, GET_BRAND_CATEGORIES} from "../../../requests/categoryRequests";
import {useQuery} from "@apollo/client";
import classes from "./filters.module.css";
import {filtersCollection} from "..";
// import { CATEGORY } from "../../../requests/productRequests";





export  function ProductSubCategoryFilter({listSubcategory}) {

    
  

    const display = [];
            Object.entries(listSubcategory.data).forEach(([key, value]) => {
                value.map((elem)=>{
                    display.push(elem);
                });
    });
    

    return (
        <div className = {classes.filterContainer}>
            {display?.map((category, index) => {
                return (
                    <div className = {classes.sortListCategories} key = {index}>
                        <div className = {classes.nameAndAmount}>
                            <span className = {classes.sortText}>{category.subcategory}</span>

                            <div className = {classes.amountOfProducts}>
                                продуктов: {category.category_items_aggregate.aggregate.count}
                            </div>
                        </div>
                        <span
                            onClick = {(event) => {
                                event.currentTarget.classList.toggle(classes.active);
                                // setChoose(category.subcategory)
                                if(filtersCollection().subcategory.includes(category.subcategory)) {
                                    const currentFilters = filtersCollection();
                                    const index = currentFilters.subcategory.findIndex(
                                        (index) => index === category.subcategory
                                    );
                                    currentFilters.subcategory.splice(index, 1);
                                    filtersCollection(currentFilters);
                                } else {
                                    const currentFilters = filtersCollection();
                                    currentFilters.subcategory.push(category.subcategory);
                                    filtersCollection(currentFilters);
                                }
                               
                            }}
                            className = {
                                    filtersCollection().subcategory.includes(category.subcategory)
                                    ? `${classes.filterChoice} ${classes.active}`
                                    : `${classes.filterChoice}`
                            }
                        ></span>
                    </div>
                );
            })}
        </div>
    );
}


export  function ShopsFilter({category}) {


    const {loading, error, data} = useQuery(GET_SHOP_CATEGORIES, {
            variables: {category},
        }
        );
    const currentShops = filtersCollection().shops;

    if (loading) {
        return null;
    }
    if (error) {
        console.error(error);
        return null;
    }

    return (
        <div className = {classes.filterContainer}>
            {data?.shop.map((shop, index) => {
                return (
                    <div className = {classes.sortList} key = {index}>
                        <span className = {classes.sortText}>{shop.name}</span>
                        <span
                            onClick = {(event) => {
                                event.currentTarget.classList.toggle(classes.active);

                                if (currentShops.includes(shop.id)) {
                                    const currentFilters = filtersCollection();
                                    const index = currentFilters.shops.findIndex(
                                        (index) => index === shop.id
                                    );
                                    currentFilters.shops.splice(index, 1);
                                    filtersCollection(currentFilters);
                                }else {
                                    const currentFilters = filtersCollection();
                                    currentFilters.shops.push(shop.id);
                                    filtersCollection(currentFilters);
                                }

                            }}
                            className = {
                                currentShops.includes(shop.id)
                                    ? `${classes.filterChoice} ${classes.active}`
                                    : `${classes.filterChoice}`
                            }
                        ></span>
                    </div>
                );
            })}
        </div>
    );
}


export function BrandsFilter({category}){


    const {loading, error, data} = useQuery(GET_BRAND_CATEGORIES, {
        variables: {category},
    }
    );

    const currentBrands = filtersCollection().brands;

    if (loading) {
        return null;
    }
    if (error) {
        console.error(error);
        return null;
    }
    
    // console.log('data', data)


    return (
        <div className = {classes.filterContainer}>
            {data?.item.map((item, index) => {
                return (
                    <div className = {classes.sortList} key = {index}>
                        <span className = {classes.sortText}>{item.brand}</span>
                        <span
                            onClick = {(event) => {
                                event.currentTarget.classList.toggle(classes.active);

                                if (currentBrands.includes(item.brand)) {
                                    const currentFilters = filtersCollection();
                                    const index = currentFilters.brands.findIndex(
                                        (index) => index === item.brand
                                    );
                                    currentFilters.brands.splice(index, 1);
                                    filtersCollection(currentFilters);
                                }else {
                                    const currentFilters = filtersCollection();
                                    currentFilters.brands.push(item.brand);
                                    filtersCollection(currentFilters);
                                }

                            }}
                            className = {
                                currentBrands.includes(item.brand)
                                    ? `${classes.filterChoice} ${classes.active}`
                                    : `${classes.filterChoice}`
                            }
                        ></span>
                    </div>
                );
            })}
        </div>
);
}

