import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './CreateLots.module.css';
import {useMutation, useQuery} from '@apollo/client';
import {SAVE_LOTS} from '../../../../requests/lotsRequests';
import {SHOP_ITEMS_FOR_LOTS} from '../../../../requests/lotsRequests';
import {useLocation} from 'react-router-dom';
import point from '../../../../assets/svg/point.svg';
import pointActive from '../../../../assets/svg/pointActive.svg';
import info from '../../../../assets/svg/info.svg';
import deleteCart from '../../../../assets/svg/deleteCart.svg';


import minusIcon from '../../../../assets/svg/minus.svg';
import plusIcon from '../../../../assets/svg/plus.svg';



function AddVariants({variants , setItemsValue, itemsValue, itemId}){
      const [selectedValues, setSelectedValues] = useState({});

      const toggleSelected = (variantId) => {
        setSelectedValues({
          ...selectedValues,
          [variantId]: { 
            ...selectedValues[variantId], 
            selected: !selectedValues[variantId]?.selected,
            count: selectedValues[variantId]?.count ?? 0,
            lots: selectedValues[variantId]?.lots ?? [{price: '', step: 0, total: 0}]
          },
        });
      };
    


      const updateSelectedValues = (variantId, updatedLots) => {
          setSelectedValues(prev => ({
            ...prev,
            [variantId]: {
              ...prev[variantId],
              lots: updatedLots
            }
          }));
        };
      
      const updateLot = (variantId, index, key, value) => {
          if (value < 0 ) {
            return 0;
          
          };

          const updatedLots = [...selectedValues[variantId].lots];
            if (updatedLots[index]) {
              updatedLots[index][key] = value;
            }
          
          updateSelectedValues(variantId, updatedLots);
        };
        
      const updateSteps = (variantId, newStep, index) => {
          updateLot(variantId, index, 'step', newStep);
          };
      const updateTotal = (variantId, newTotal, index) => {
          updateLot(variantId, index, 'total', newTotal);
        };
      const updatePrice = (variantId, newPrice, index) => {
          updateLot(variantId, index, 'price', newPrice);
        };
      
      
      const addNewLot = (variantId) => {
            const updatedLots = [...selectedValues[variantId].lots, {price: '', step: 0, total: 0}];
            updateSelectedValues(variantId, updatedLots);
          };
        
      const removeLot = (variantId, index) => {
            const updatedLots = [...selectedValues[variantId].lots];
            updatedLots.splice(index, 1);
            updateSelectedValues(variantId, updatedLots);
        };
      
      
      
      useEffect(() => {
        let counts = 0;
        let choose = false;
        let variants = [];
        // let lots = [];
        Object.keys(selectedValues).forEach(variantId => {

            if (selectedValues[variantId].selected) {
              choose = true;
              // counts += parseInt(selectedValues[variantId].count);
              // lots = selectedValues[variantId].lots;
              variants.push( {variantId : variantId, lots: selectedValues[variantId].lots} ); 
            }        
          
          });
        
        setItemsValue(prevItemsValue => {
          const newItemsValue = { ...prevItemsValue };
          if (!newItemsValue[itemId]) {
            newItemsValue[itemId] = { variants: {},  choose: false };
          }
          // newItemsValue[itemId].count = counts  ;
          newItemsValue[itemId].choose = choose;
          newItemsValue[itemId].variants = variants;
          // newItemsValue[itemId].lots = lots;
        
          return newItemsValue;
          });
        
      }, [selectedValues]); 
      



      return(
        <div >
        {
        variants.map((variant, index) => {
          const variantData = selectedValues[variant.id] || { count: 0, selected: false };
          return (
          <div className={styles.wraperVariat} key={variant.id}>
                <div className={styles.row}>
                  <h2 >Вариант {index + 1}</h2>
                  {index === 0 && <span>Исходный</span>}
                </div>
          
              <div className={styles.infoVariant}>
          

              {variantData.selected ? (
                    <img src={pointActive} alt="link"
                      onClick={() => toggleSelected(variant.id)} />
                  ) : (
                    <img src={point} alt="choose" 
                      onClick={() => toggleSelected(variant.id)}
                    />
                    )
              } 
                    <div className={styles.itemImage} key={variant.image}>
                        <img src={variant.image} alt="foto" className={styles.avatar} />
                    </div>
            
                    <div className={styles.variantsInfo}>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Артикул</label>
                            <span className={styles.labelInfo}>{variant.articleNumber}</span>
                        </div>
                        <div style= {{height: '1vh'}}/  >

            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Длина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.length || '-'}</span>
                        </div>
            
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Ширина упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.width || '-'}</span>
                        </div>

                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Высота упаковки (мм)</label>
                            <span className={styles.labelInfo}>{variant.height || '-'}</span>
                        </div>
                        <div className={styles.variantText}>
                            <label className={styles.textInfo}>Вес упаковки (мм)</label>
                            <span className={styles.textInfo}>{variant.weight || '-'}</span>
                        </div>
                    </div>
              </div>
            
                <div className={styles.characterRow}>
                      {variant.purposeName ? (

                        <div className={styles.character}>
                          <label className={styles.labelInfo}>{variant.purposeName}</label>
                          <span className={styles.textInfo}>{variant.purposeValue}</span>
                        </div>

                      ) : ( 
                        <div className={styles.character}>
                            <label className={styles.labelInfo}>-</label>
                        </div> )}

                      {variant.purposeName2 && (
                        <div className={styles.character}>
                      
                          <label className={styles.labelInfo}>{variant.purposeName2}</label>
                          <span className={styles.textInfo}>{variant.purposeValue2}</span>
                        </div>
                      ) }
                </div>

                    {
                        variantData.selected && (
                    <div>
                        <div className = {styles.labelContainer}>
                             <label className = {styles.labelLot}>Цена за шт. </label>
                            <div className={styles.labelIcon}>
                                <label className = {styles.labelLot}>Квант </label>
                                <img className={styles.infoIcon} 
                                    src = {info} alt = "info" />
                            </div>
                            <div className={styles.labelIcon}>
                                <label className = {styles.labelLot}>Всего </label>
                                <img src = {info} alt = "info" />
                            </div>
                        </div>
                        
                        
                    <div className={styles.formLots}>
                        
                        
                            {variantData.lots.map((lot, index) => (
                        <div className={styles.lotFields} key={variant.id + index}>
                            <div className={styles.labelIcon}>
                            
                                <img 
                                style={{ height: '15px', width: '13.3px' }} 
                                  src={deleteCart} 
                                  alt="deleteItem" 
                                  onClick={() => removeLot(variant.id, index)} 
                            
                                />
                                <input
                                  className={styles.inputPrice}
                                  value={lot.price || ''}
                                  placeholder='0₽'
                                  onChange={e => updatePrice(variant.id, parseInt(e.target.value) , index)}
                            
                                />
                            </div>
                            
                            <div className={styles.countButton}>
                                
                                <img src={minusIcon} alt='minus'
                                            onClick={() =>updateSteps(variant.id, parseInt(lot.step) - 1, index)}/>
                                            
                                      
                                <input
                                    type="number"
                                    value={lot.step}
                                    onChange={e => updateSteps(variant.id, e.target.value, index)}
                                    className={styles.hiddenInput}

                                    // style={{ width: `${Math.min(Math.max(16, (lot.step.toString().length * 7)), 45)}px` }}
                            

                                />
                                <img src={plusIcon} alt='plus'
                                        onClick={() => updateSteps(variant.id, parseInt(lot.step) + 1, index)}/>
                            </div>
                            
                            <div className={styles.countButton}>
                                <img src={minusIcon} alt='minus' 
                                            onClick={() =>updateTotal(variant.id, parseInt(lot.total) - 1, index)}/>
                                <input
                                    type="number"
                                    value={lot.total}
                                    onChange={e => updateTotal(variant.id, e.target.value, index)}
                                    className={styles.hiddenInput}
                                    // style={{ width: `${Math.min(Math.max(16, (lot.total.toString().length * 7)), 45)}px` }}
                                    

                                />
                                <img src={plusIcon} alt='plus'
                                        onClick={() => updateTotal(variant.id, parseInt(lot.total) + 1, index)} />
                            </div>
                        </div>
                            ))
                            } 
                            
                        <button className={styles.addLot}
                          type="button"
                            disabled={selectedValues[variant.id].lots.length >= 3}
                          onClick={() =>addNewLot(variant.id, )}>
                          Добавить лот
                        </button>
                            
                    </div>
                            
                            
                    </div>
                            
                    )
                 }
                            

          </div>
      )
          })       
        
        }
    </div>

    
  )
}




function CreateLots()  {
    const location = useLocation();
    const shopId = location.state.shopId;
    const storageId = location.state.storageId;
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [showVariants, setShowVariants] = useState(null );

    const [itemsValue, setItemsValue] = useState({});

    
    const [saveLots, { saveData }] = useMutation(SAVE_LOTS)




   

  
    const {data, error, loading} = useQuery(SHOP_ITEMS_FOR_LOTS, {
            fetchPolicy: 'cache-and-network',
            variables: {shopId},
          });
          if (loading) {
            return 'loading...';
          }
          if (error) {
            console.error('items for storage error', error);
            return 'error';
          }
        
    let items  =  data.item;
    

  
    if (!items || items.length === 0) {
        return (
          <div className={styles.wrapper}>
            <div className={styles.textEmpty}> Для создания лота необходимо два усовия. Что бы тоавар был на складе и 
                                        что бы у товара не было текущих лотов  </div>
            <button className={styles.addButton} onClick={() => navigate('/create_item', { state: { shopId } })}>
              Добавить товар
            </button>
          </div>
        );
      }


    const updateCount = (itemId, newCount) => {
      if (newCount < 0) {
        return;
      }
      setItemsValue(prevItemsValue =>({

        ...prevItemsValue,
        [itemId]: { ...prevItemsValue[itemId], 
                        count: newCount },
      }));
      

    };


    {/* console.log('itemsValue', itemsValue) */}

    //when choose item create object with variants and lots for him
    const chooseSelected = (itemId, variant) => {
      setItemsValue(prevItemsValue => ({
          ...prevItemsValue,
          [itemId]: {
            ...prevItemsValue[itemId],
            choose: !prevItemsValue[itemId]?.choose,
            variantId: variant,
            lots: prevItemsValue[itemId]?.lots ?? [{price: '', step: 0, total: 0}]

          },
        }));
    };


    const updateSelectedValues = (itemId, updatedLots) => {
          setItemsValue(prev => ({
            ...prev,
            [itemId]: {
              ...prev[itemId],
              lots: updatedLots
            }
          }));
        };

    const updateLot = (itemId, index, key, value) => {
          if (value < 0 ) return;

          const updatedLots = [...itemsValue[itemId].lots];
            if (updatedLots[index]) {
              updatedLots[index][key] = value;
            }
          
          updateSelectedValues(itemId, updatedLots);
        };
        
    const updateSteps = (itemId, newStep, index) => {
          updateLot(itemId, index, 'step', newStep);
          };
    const updateTotal = (itemId, newTotal, index) => {
          updateLot(itemId, index, 'total', newTotal);
        };
    const updatePrice = (itemId, newPrice, index) => {
          updateLot(itemId, index, 'price', newPrice);
        };

    const addNewLot = (itemId) => {
            const updatedLots = [...itemsValue[itemId].lots, {price: '', step: 0, total: 0}];
            updateSelectedValues(itemId, updatedLots);
          };
        
    const removeLot = (itemId, index) => {
            const updatedLots = [...itemsValue  [itemId].lots];
            updatedLots.splice(index, 1);
            updateSelectedValues(itemId, updatedLots);
        };





    const filteredItems = items.filter(
        it => it.name.toLowerCase().includes(searchTerm.toLowerCase())
          || it.articleNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );




    async function handleSubmit() {
          
          

          const variablesArray = [];

          Object.keys(itemsValue).forEach((itemId) => {
                  const itemData = itemsValue[itemId];

                  if (itemData.choose) {
                    if (Array.isArray(itemData.variants)) {
                      itemData.variants.forEach((variant) => {
                        if (Array.isArray(variant.lots)) {
                          variant.lots.forEach((lot) => {
                            const price = parseInt(lot.price, 10) || 0;
                            const step = parseInt(lot.step, 10) || 0;
                            const total = parseInt(lot.total, 10) || 0;
                          if(step <= 0 || total <= 0 || step > total || price <= 0) {
                            alert("Проверьте введенные данные");
                            throw new Error("Invalid data entered");
                            
                          }
                            variablesArray.push({
                              price: parseInt(lot.price, 10) || 0,
                              step: parseInt(lot.step, 10) || 0,
                              total: parseInt(lot.total, 10) || 0,
                              variant_id: variant.variantId,
                              shop_id: shopId,
                              item_id: itemId,
                            });
                          });
                        }
                      });
                    } else if (Array.isArray(itemData.lots)) {
                      itemData.lots.forEach((lot) => {
                            const price = parseInt(lot.price, 10) || 0;
                            const step = parseInt(lot.step, 10) || 0;
                            const total = parseInt(lot.total, 10) || 0;
                          if(step <= 0 || total <= 0 || step > total || price <= 0) {
                            alert("Проверьте введенные данные");
                            throw new Error("Invalid data entered");
                            
                          }
                        variablesArray.push({
                          variant_id: itemData.variantId,
                          price: parseInt(lot.price, 10) || 0,
                          step: parseInt(lot.step, 10) || 0,
                          total: parseInt(lot.total, 10) || 0,
                          shop_id: shopId,
                          item_id: itemId,
                        });
                      });
                    }
                  }
                });
            {/* console.log('variablesArray', variablesArray) */}
            try {
              const { data: mutationResult } = await saveLots({ 
                variables: {
                  lots: variablesArray,
                } 
              });
            
              if (mutationResult) {
                navigate('/goods_storage', { state: { shopId } });
              } else {
                alert("An error occurred while saving the item.");
                await fetch(process.env.REACT_APP_BOT_NOTIFICATION, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: error.message, }),
                  });
              }
            } catch (error) {
              console.error("An error occurred:", error);
              alert("Возможно такой товар уже есть на складе.Please try again.");
            } 
          }
   

    
      return (
        <div className={styles.wrapper} >
          <h1>
            СОЗДАНИЕ ЛОТА 
          </h1>
            
          <div className={styles.contentContainer}>
            <div className={styles.inputField}>
              <input
                className={styles.search}
                type="text"
                placeholder="Введите наименование товара или артикул"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            <form className={styles.form}>      

            {filteredItems.map((item, index) => (
              <div className={styles.wraperItem} key={item.id}>
              <div className={styles.itemContainer} >


                <div className={styles.point}>



                {item.item_variants.length === 1 ? (
                        itemsValue?.[item.id]?.choose ? (
                          <img
                            src={pointActive}
                            alt="choose"
                            onClick={() => chooseSelected(item.id, item.item_variants[0].id)}
                          />
                        ) : (
                          <img
                            src={point}
                            alt="chooseActive"
                            onClick={() => chooseSelected(item.id, item.item_variants[0].id)}
                          />
                        )
                      ) : (
                        itemsValue?.[item.id]?.choose ? (
                          <img src={pointActive} alt="choose" />
                        ) : (
                          <img src={point} alt="chooseActive" />
                        )
                      )}

                </div>
                <div className={styles.itemImage}>
                  <img className={styles.image} src={item.item_media[0].path} alt="itemImage" />
                </div>
                <div className={styles.itemInfo}>
                  <span className={styles.itemName}>{item.name}</span>
                  <span className={styles.itemArticle}>{item.articleNumber}</span>
                  <span className={styles.itemPurposeCount}>2 свойства; 2 варианта;</span>
                </div>

              </div>
              
              {

                (itemsValue?.[item.id]?.choose && item.item_variants.length === 1)  && 

                (
                    <div>
                        <div className = {styles.labelContainer}>
                             <label className = {styles.labelLot}>Цена за шт. </label>
                            <div className={styles.labelIcon}>
                                <label className = {styles.labelLot}>Квант </label>
                                <img className={styles.infoIcon} 
                                    src = {info} alt = "info" />
                            </div>
                            <div className={styles.labelIcon}>
                                <label className = {styles.labelLot}>Всего </label>
                                <img src = {info} alt = "info" />
                            </div>
                        </div>
                        
                        
                    <div className={styles.formLots}>
                        
                        
                       {itemsValue[item.id].lots.map((lot, index) => (
                        <div className={styles.lotFields} key={ index}>
                            <div className={styles.labelIcon}>
                            
                                <img 
                                style={{ height: '15px', width: '13.3px' }} 
                                  src={deleteCart} 
                                  alt="deleteItem" 
                                  onClick={() => removeLot(item.id, index)} 
                            
                                />
                                <input
                                  className={styles.inputPrice}
                                  value={lot.price || ''}
                                  placeholder='0₽'
                                  onChange={e => updatePrice(item.id, parseInt(e.target.value) , index)}
                            
                                />
                            </div>
                            
                            <div className={styles.countButton}>
                                <img src={minusIcon} alt='minus'
                                            onClick={() =>updateSteps(item.id, parseInt(lot.step) - 1, index)}
                                          />
                                <input
                                    type="number"
                                    value={lot.step}
                                    onChange={e => updateSteps(item.id, e.target.value, index)}
                                    className={styles.hiddenInput}
                                    // style={{ width: `${Math.min(Math.max(16, (lot.step.toString().length * 7)), 45)}px` }}
                            

                                />
                                <img src={plusIcon} alt='plus' 
                                        onClick={() => updateSteps(item.id, parseInt(lot.step) + 1, index)}
                                        />
                            </div>
                            
                            <div className={styles.countButton}>
                                {/* <span type='button' className={styles.doButton}  */}
                                <img src={minusIcon} alt='minus'
                                            onClick={() =>updateTotal(item.id, parseInt(lot.total) - 1, index)}
                                      />
                                            {/* // >-</span> */}
                                <input
                                    type="number"
                                    value={lot.total}
                                    onChange={e => updateTotal(item.id, e.target.value, index)}
                                    className={styles.hiddenInput}
                                    // style={{ width: `${Math.min(Math.max(16, (lot.total.toString().length * 7)), 45)}px` }}

                                />
                                <img src={plusIcon} alt='plus'
                                        onClick={() => updateTotal(item.id, parseInt(lot.total) + 1, index)}
                                        />
                            </div>
                        </div>
                            ))
                            } 

                            
                            
                        <button className={styles.addLot}
                          type="button"
                            disabled={itemsValue[item.id].lots.length >= 3}
                          onClick={() =>addNewLot(item.id, )}
                          >
                          Добавить лот
                        </button>
                            
                    </div>
                            
                            
                    </div>
                            
                    )
                 }



              {item.item_variants.length > 1 && (
                <>
                <span className={styles.link} 
                      onClick={() => setShowVariants(showVariants === item.id ? null : item.id)}
                      >
                      {showVariants === item.id ? 
                      'Закрыть варианты' :
                        'Открыть варианты'}
                    </span>

                    {showVariants === item.id && (
                      <AddVariants variants={item.item_variants} 
                                    setItemsValue={setItemsValue}
                                    itemId={item.id}
                                    itemsValue={itemsValue}
                      />
                    )}
                    
              </>
              )}
              </div>
  
    
            ))}
            </form>
            </div>
          
          <button className={styles.buttonFixed} 
                disabled={!itemsValue} 

                onClick={async() => await handleSubmit()}  >
            Создать 
          </button>
        </div>
      );
    }
  
  










export default CreateLots;