export const countryList = [
  
  "Австралия", 
  "Австрия",
   "Азербайджан",
    "Албания", 
  "Алжир", 
  "Ангола", 
  "Андорра", 
  "Антигуа и Барбуда",
  "Аргентина", 
  "Армения", 
  "Афганистан", 
  "Багамы", 
  "Бангладеш", 
  "Барбадос", 
  "Бахрейн", 
  "Беларусь", 
  "Белиз",
  "Бельгия", 
  "Бенин", 
  "Болгария", 
  "Боливия", 
  "Босния и Герцеговина", 
  "Ботсвана", 
  "Бразилия", 
  "Бруней", 
  "Буркина-Фасо",
  "Бурунди", 
  "Бутан", 
  "Вануату", 
  "Ватикан", 
  "Великобритания", 
  "Венгрия", 
  "Венесуэла", 
  "Вьетнам", 
  "Габон", 
  "Гаити",
  "Гайана", 
  "Гамбия", 
  "Гана", 
  "Гватемала", 
  "Гвинея", 
  "Гвинея-Бисау", 
  "Германия", 
  "Гондурас", 
  "Гренада", 
  "Греция",
  "Грузия", 
  "Дания", 
  "Джибути", 
  "Доминика", 
  "Доминиканская Республика", 
  "Египет", 
  "Замбия", 
  "Зимбабве", 
  "Израиль",
  "Индия", 
  "Индонезия", 
  "Иордания", 
  "Ирак", 
  "Иран", 
  "Ирландия", 
  "Исландия", 
  "Испания", 
  "Италия", 
  "Йемен", 
  "Кабо-Верде",
  "Казахстан", 
  "Камбоджа", 
  "Камерун", 
  "Канада", "Катар", "Кения", "Кипр", "Киргизия", "Кирибати", "Китай", "Колумбия",
  "Коморы", "Конго", "Корея (Северная)", "Корея (Южная)", "Коста-Рика", "Кот-д'Ивуар", "Куба", "Кувейт", "Лаос", "Латвия",
  "Лесото", "Либерия", "Ливан", "Ливия", "Литва", "Лихтенштейн", "Люксембург", "Маврикий", "Мавритания", "Мадагаскар",
  "Малави", "Малайзия", "Мали", "Мальдивы", "Мальта", "Марокко", "Маршалловы Острова", "Мексика", "Мозамбик", "Молдавия",
  "Монако", "Монголия", "Мьянма", "Намибия", "Науру", "Непал", "Нигер", "Нигерия", "Нидерланды", "Никарагуа", "Новая Зеландия",
  "Норвегия", "ОАЭ", "Оман", "Пакистан", "Палау", "Панама", "Папуа — Новая Гвинея", "Парагвай", "Перу", "Польша", "Португалия",
  "Россия", "Руанда", "Румыния", "Сальвадор", "Самоа", "Сан-Марино", "Сан-Томе и Принсипи", "Саудовская Аравия", "Свазиленд",
  "Северная Македония", "Сейшелы", "Сенегал", "Сент-Винсент и Гренадины", "Сент-Китс и Невис", "Сент-Люсия", "Сербия",
  "Сингапур", "Сирия", "Словакия", "Словения", "Соломоновы Острова", "Сомали", "Судан", "Суринам", "США", "Сьерра-Леоне",
  "Таджикистан", "Таиланд", "Танзания", "Того", "Тонга", "Тринидад и Тобаго", "Тувалу", "Тунис", "Туркмения", "Турция",
  "Уганда", "Узбекистан", "Украина", "Уругвай", "Фиджи", "Филиппины", "Финляндия", "Франция", "Хорватия", "Центральноафриканская Республика",
  "Чад", "Черногория", "Чехия", "Чили", "Швейцария", "Швеция", "Шри-Ланка", "Эквадор", "Экваториальная Гвинея", "Эритрея", "Эсватини",
  "Эстония", "Эфиопия", "ЮАР", "Ямайка", "Япония"
  ];
  
  