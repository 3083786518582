import React, {useContext} from 'react';
import NumberFormat from '../../../utils/NumberFormat';
import styles from './AddElementInfo.module.css'
import {ProductCardContext} from '../../ProductCard/ProductCard';

const AddElementInfo = ({lot, amount}) => {


    return (
        <div className = {styles.container}>
            <div className = {styles.cost}>{`${NumberFormat(lot.price || 0)} ₽`}</div>
            <div className = {styles.jointInfo}>
                {/*//От 5 шт. | Прогресс: 155+5(160)/500*/}
                {`От ${lot.step} шт. | Прогресс: ${lot.ordered_quantity}`}
                <span className = {styles.highlighting}>{`+${amount}(${lot.ordered_quantity + amount})`}</span>
                {`/${lot.total}`}
            </div>
        </div>)
}
export default AddElementInfo