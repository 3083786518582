const ItemCheckbox = ({ isChecked, onClick }) => {
  const strokeWidth = isChecked ? '4' : '2'
  const strokeColor = isChecked ? '#4F8CE7' : '#B5B5B5'
  return (
    <svg onClick={onClick} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="9" stroke={strokeColor} strokeWidth={strokeWidth}/>
    </svg>

  )
}

export default ItemCheckbox
