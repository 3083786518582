import React, {useContext, useEffect, useState} from 'react';
import styles from './LotData.module.css';
import clockImage from '../../../../assets/png/clock.png';
import boxImage from '../../../../assets/png/box.png';


export default function LotData({isActive, 
                                endDate, 
                                currentItems,
                                totalLotQuantity}) {
    // const currentItems = 25;


    const [time, setTime] = useState(() => formatTime(DateToEnd(endDate ? endDate : '2024-10-08')));

    function DateToEnd(endDate) {
        const end = new Date(endDate).getTime();
        const now = new Date().getTime();
        return end - now;  // return difference in milliseconds
    }

    function formatTime(ms) {
        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / (1000 * 60)) % 60);
        const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        const days = Math.floor(ms / (1000 * 60 * 60 * 24));

        return ms <= 7 * 24 * 60 * 60 * 1000
            ? `${days} дн. ${hours} ч. ${minutes} мин. ${seconds} сек.`
            : `${days} дн. ${hours} ч. ${minutes} мин.`;
    }

    // update remaining time every second if remaining time is less than 7 days
    // otherwise update remaining time every minute
    useEffect(() => {
        let intervalId;

        function updateRemainingTime() {
            const remainingTime = DateToEnd(endDate);
            setTime(formatTime(remainingTime));

            if (remainingTime <= 7 * 24 * 60 * 60 * 1000) {
                clearInterval(intervalId);
                intervalId = setInterval(updateRemainingTime, 1000);
            } else {
                clearInterval(intervalId);
                intervalId = setInterval(updateRemainingTime, 1000 * 60);
            }
        }

        updateRemainingTime();

        return () => clearInterval(intervalId);
    }, [endDate]);

    const containerClassName = isActive
        ? `${styles.container} ${styles.active}`
        : styles.container;

    return (
        <div className={containerClassName}>
            <div className={styles.timerContainer}>
                <img src={clockImage} alt='clockImage'/>
                <div className={styles.timeContent}>
                    {time}
                </div>
            </div>
            <div className={styles.box}>
                <img src={boxImage} alt='boxImage'/>

                <span>{currentItems}/{totalLotQuantity}</span>
            </div>
        </div>
    );
}
