import React, {useContext} from 'react';
import styles from './ButtonAddElement.module.css'
import {ReactComponent as Icon} from '../../../assets/svg/checkMark.svg';
import {amountDynamic} from '../../Footer/Backet/Backet';
import { TelegramContext } from '../../../App';
import { useMutation } from '@apollo/client';

import {ADD_TO_CART, UPDATE_QUANTITY} from '../../../requests/cartRequests';

import { useNavigate } from 'react-router-dom';


const ButtonAddElement = ({
                            lot,
                            itemID,
                            shopID,
                            amount,
                            setIsAmountChanged,
                            isAmountChanged,
                            inProductCard,  
                            setInProductCard,
                                                        }) => {
 



    const {userID} = useContext(TelegramContext) 

    const naviagate = useNavigate();

    
    const [addItemToCart, {data: addToCartData, error: addToCartError}] = useMutation(ADD_TO_CART, {
        variables: {
            itemID : itemID, 
            shopID : shopID,
            userID : userID,
            lotsID: lot.id, 
            quantity: amount, 
            price :lot.price,
            extendedCost : (lot.price * amount),
            variantID: lot.item_variant.id
        }
    });

    // const [updateQuantity, {data: updateQuantityData, error: updateQuantityError}] = useMutation(UPDATE_QUANTITY, {
    //     variables: {amount: amount, itemID, userID}, update(cache, {data}) {
    //             });
    //         }


    //     },
    // });

    const [updateQuantity, {data: updateQuantityData, error: updateQuantityError}] = useMutation(UPDATE_QUANTITY, {
        variables: {amount: amount, lotsID: lot.id, userID} }
    );







    const onAddItemHandler =  () => {
        if(!userID){
            naviagate("/v1/login")
            return
        }


        // isModalActive && setModalActive(false);
         addItemToCart().then((returningData) => {

            
            setIsAmountChanged(false)
            setInProductCard(true)               
            amountDynamic(amountDynamic() + 1);
        })
        if (addToCartError) console.log("addToCartError", addToCartError.message)
    }

    const onChangeItemHandler = async () => {

        updateQuantity().then(() => {
            setIsAmountChanged(false)
            setInProductCard(true)
        }   )
        if (updateQuantityError) console.log("updateQuantityError", updateQuantityError.message)

    }




    return (
        <>
            

            {!inProductCard && <div className = {styles.BasketToCardButtonContainer}>
                <button
                    className = {styles.BasketToCardButton}
                    onClick = {onAddItemHandler}
                    >
                    <span>В КОРЗИНУ</span>
                </button>
            </div>}

            {inProductCard && !isAmountChanged && <div className = {styles.BasketToCardButtonContainer}>
                {/* <NavLink to = {"/cart"} className = {styles.linkClassname}> */}
                    <button
                        className = {`${styles.BasketToCardButton} ${styles.active}`}
                    >
                        <span>{"ДОБАВЛЕНО В КОРЗИНУ"}</span>
                        <Icon/>
                    </button>
                {/* </NavLink> */}
            </div>}

            {inProductCard && isAmountChanged && <div className = {styles.BasketToCardButtonContainer}>
                <button
                    className = {styles.BasketToCardButton}
                    onClick = {onChangeItemHandler}>
                    <span>{amount === 0 ? "УДАЛИТЬ" : "ИЗМЕНИТЬ"}</span>
                </button>
            </div>}
        </>
    )
}
export default ButtonAddElement;
