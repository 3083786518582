import classes from '../ProductCard.module.css';
import Video from '../Video/Video';
import React, {useContext} from 'react';
import {ModalContext} from '../../../App';
import {modalDisplayMode} from '../../PrimaryModal/PrimaryModal';
import video from "../../../assets/svg/video.svg"


function ShowVideoReview({videoUrl, posterImageUrl}) {
    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const localModalContent = (!!videoUrl
        ? <Video
            url = {videoUrl}
            onClick = {(event) => {
                event.stopPropagation();
            }}
            posterImage = {posterImageUrl}
        />
        : <div style = {{height: "40vh"}}>Видео будет загружено позже</div>)




    return (

        <div
            className = {classes.showVideosButtonDisabled}
           >
            <img src = {video} alt = 'imageVideo'/>
            <span>Видеообзор товара от продавца</span>
        </div>
    )

    //! change the return statement to this after addd video from shops 
    // return (
    //     <div
    //         className = {classes.showVideosButton}
    //         onClick = {() => {
    //             setModalContent(
    //                 localModalContent
    //             );
    //             setModalMode(modalDisplayMode.withOverlay)
    //             setModalActive(true);
    //         }}>
    //         <img src = {video} alt = 'imageVideo'/>
    //         <span>Видеообзор товара от продавца</span>
    //     </div>
    // )
}

export default ShowVideoReview;