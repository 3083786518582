import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Main from '../Containers/Main';
import Error from '../Containers/Error';



import CartPage from '../Containers/CartPage';
import CategoryPage from '../Containers/Category';
import ProductCard from '../Containers/ProductCard/ProductCard'
import ReviewsPage from '../Containers/ReviewPage/ReviewPage';
import Cities from '../Containers/Cities/Cities';
import LikedItems from '../Containers/LikedItems/LikedItems';
import ShopPage from '../Containers/ShopPage/ShopPage';
import ProfileMain from '../Containers/Account/ProfileMain';
import Bonus from '../Containers/Account/ProfileMain/Bonus';
import UserRegistration from '../Containers/Account/ProfileMain/UserRegistration';
import ProfileSetting from '../Containers/Account/ProfileMain/ProfileSetting'
// import SellerRegistration from '../Containers/Account/ProfileMain/SellerRegistration';
import CompanyRegistration from '../Containers/Account/ProfileMain/CompanyRegestration';
// import ShopSetting from '../Containers/Account/ShopSetting';
// import MyShop from '../Containers/Account/MyShop/MyShop';
import Payment from '../Containers/Payment'
import UserAddresses from '../Containers/UserAddresses';
import ProfileReviews from '../Containers/Account/ProfileReviews';
import ProcessPayment from '../Containers/ProcessPayment';
import MyShops from '../Containers/Account/MyShops';
import CreateShop from '../Containers/Account/CreateShop';
import ShopSettings from '../Containers/Account/ShopSettings';
import GoodsStorage from '../Containers/Account/GoodsStorage';
import CreateItem from '../Containers/Account/GoodsStorage/CreateItem';
import CreateStorage from '../Containers/Account/GoodsStorage/CreateStorage';
import GoodsSettings from '../Containers/Account/GoodsStorage/GoodsSettings';
import StorageItems from '../Containers/Account/GoodsStorage/Storage/StorageItems';
import ChangeStorageItems from '../Containers/Account/GoodsStorage/Storage/ChangeStorageItems';
// import ShopLots from '../Containers/Account/ShopLots/ShopLots';
import CreateLots from '../Containers/Account/ShopLots/CreateLots';
import SettingsLots from '../Containers/Account/ShopLots/SettingsLots';
import ArchevedLots from '../Containers/Account/ShopLots/ArchevedLots';

import OrdersUser from '../Containers/Account/OrdersUser';
import Order from '../Containers/Account/OrdersUser/Order';



import ShopOrders from '../Containers/Account/ShopOrders/ShopOrders';


import RefundsUser from '../Containers/Account/RefundsUser';


import GetItem from '../Containers/Account/OrdersUser/Order/GetItem';
import DeclineItem from '../Containers/Account/OrdersUser/Order/DeclineItem';
import BoughtItem from '../Containers/Account/BoughtItem';
import PaymentCompany from '../Containers/Payment/paymentCompany';

import Login from '../Containers/Account/Login';
import Registration from '../Containers/Account/Login/Registration';

import AboutCompany from '../Containers/AboutCompany';




export const RoutesPath = () => {
    return (
        <Routes>
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Main />} />
            <Route path ="/v1/login" element = {<Login/>}/>
            <Route path = "/v1/registration" element = {<Registration/>}/>

            <Route path="/v1/category" element={<CategoryPage />} />

            <Route path="/cart" element={<CartPage />} />


            <Route path="/product/:productId" element={<ProductCard />} /> 
            <Route path="/product/:productId/reviews" element={<ReviewsPage />} />

            <Route path="/likes" element={<LikedItems />} />


            <Route path="/shop/:shopId" element={<ShopPage />} />

            <Route path="/profile" element={<ProfileMain />} />

            {/* окно регистрации нового пользователя  */}
            <Route path="profile/registration" element={<UserRegistration />} />
            {/* Настройки профиля покупателя или пользователя */}
            <Route path="profile/setting" element={<ProfileSetting />} />
            {/*   регистрация нового продавца и магазина*/}
            <Route path="/profile/company_registration" element={<CompanyRegistration />} />


            <Route path="/profile/my_shops"
                element={<MyShops />} />
            <Route path="/profile/create_shop"
                element={<CreateShop />} />
            <Route path="/shop_settings/:shopId" element={<ShopSettings />} />
            <Route path="/goods_storage" element={<GoodsStorage />} />
            <Route path="/create_item" element={<CreateItem />} />
            <Route path="/settings_goods" element={<GoodsSettings />} />
            <Route path="/create_storage" element={<CreateStorage />} />
            <Route path="/storage_items" element={<StorageItems />} />
            <Route path="/change_storage_items" element={<ChangeStorageItems />} />
            <Route path="/settings_lots" element={<SettingsLots />} />
            <Route path="/archived_lots" element={<ArchevedLots />} />


            <Route path="/create_lots" element={<CreateLots />} />

            <Route path="/profile/city" element={<Cities />} />

            {/*доступные адреса покупателя*/}
            <Route path="/addresses" element={<UserAddresses />} />

            {/* <Route path = "/profile/buyer/parameters/currency" element = {<Currency/>}/>  */}
            {/* <Route path = "/profile/buyer/parameters/about" element = {<About/>}/> */}
            {/*  */}
            {/*  */}
            {/* <Route path = "/profile/buyer/cards" element = {<Cards/>}/> */}
            {/* <Route path = "/profile/buyer/addresses" element = {<Addresses/>}/> */}
            {/* <Route path = "/profile/buyer/finances" element = {<Finances/>}/> */}
            {/* <Route path = "/profile/buyer/purchase" element = {<Purchase/>}/> */}
            
            <Route path="/profile/buyer/bonus" element={<Bonus />} />
           

            <Route path="/shop_orders" element={<ShopOrders />} />
           


            <Route path = "/profile/user/orders"  element  ={<OrdersUser/>}/>   
            <Route path = "/profile/order_info/"  element  ={<Order/>}/> 

            <Route path ="/profile/order/get_item" element ={<GetItem/>} />
            <Route path ="/profile/order/declined_item" element ={<DeclineItem/>} />

            <Route path = "/profile/user/refunds"  element ={<RefundsUser/>} />

            <Route path = "/profile/user/bought"  element  ={<BoughtItem/>}/>
            <Route path="/profile/reviews" element={<ProfileReviews />} />
            <Route path="/payment" element={<Payment />} />
            <Route path='/paymentCompany' element={<PaymentCompany />} />
            <Route path="/processPayment" element={<ProcessPayment />} />
            <Route path='/aboutCompany' element={<AboutCompany />} />

        </Routes>


    );
};
