import {NavLink, useNavigate} from "react-router-dom";
//import catalog from "../../../assets/svg/catalog.svg";
import {useContext} from 'react';
import {ModalContext} from '../../../App';
import classes from '../Footer.module.css';

import {ReactComponent as Icon} from '../../../assets/svg/home.svg';

const Dashbord = () => {
    console.log('Dashbord')
    const navigate = useNavigate();
    const {setModalActive, setModalContent} = useContext(ModalContext);
    const linkClassname = ({isActive}) => isActive ? classes.activeImg : classes.img
    return (<div className = {classes.linkContainer}
                 onClick = {() => {
                     setModalActive(false)
                     setModalContent('')
                 }}>
        <NavLink to = {"/profile/seller/shop/setting"} className = {linkClassname}>
            <Icon/>
            <div>Дашборд</div>
        </NavLink>
    </div>);
};

export default Dashbord;
