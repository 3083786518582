import {gql} from '@apollo/client';


//const userID = localStorage.getItem('userID') || '';
// const userID = localStorage.getItem('userID') || '534677865';
//const userID = localStorage.getItem('userID')


export const WATCH_PRODUCT = gql`
  mutation upsert_watch($productId: Int, $userID: bigint,) {
    insert_user_watch(
      objects: {
        user_id: $userID,
        item_id: $productId,
        updated_on: "now()"
      },
      on_conflict: {
        constraint: user_watch_pkey,
        update_columns: updated_on
      }
    ) {
      returning {
        item_id
      }
    }
  }
`;









export const GET_PRODUCT_TO_ADD = gql`
    query getProductToAdd($itemId: Int) {
        item(where: {id: {_eq: $itemId}, lots: {status: {_eq: "active"}}}) {
                        
            shop {
                id
                name
                approval
            }

            item_variants(where: {lots: {status: {_eq: "active"}}})  {
                id
                purposeName
                purposeName2
                purposeValue
                purposeValue2

                lots(where: {status: {_eq: "active"}}) {
                 
                    expire_date
                    id
                    price
                    step
                    total
                    ordered_quantity

                    }

                }
            
            

            
        }
    }
`;


export const GET_PRODUCT = gql`
    query getProduct($productId: Int,$userID: bigint) {
        item(where: { id: { _eq: $productId } }) {
            id
            name
            about
            brand
            character
            comment_items_aggregate {
                aggregate {
                  count(columns: user_id)
                  avg {
                    estimate
                  }
                }
              }
            users_likes(where: {user_id: {_eq: $userID}}) {
                user_id
              }
            category_items {
                category {
                    category
                    subcategory
                }
            }
            item_media {
                type
                path
            }
            lots (where: {status: {_eq: "active"}}) {
                id
                price
                step
                expire_date
                status
                total
                ordered_quantity
                item_variant {
                    id
                  }
                carts(where: {user_id: {_eq: $userID}}) {
                    id
                    quantity
                  }
                

            }
            item_variants(where: {lots: {status: {_eq: "active"}}})  {
                id
                purposeName 
                purposeName2
                purposeValue
                purposeValue2
                length
                width
                height
                weight
                image
            }
            
    
            shop {
                id
                info
                name
                approval
                photo
             
                shops_likes(where: {user_id: {_eq: $userID}}) {
                    shop_id
                    
                  }
                carts_aggregate {
                    aggregate {
                      sum {
                        quantity
                      }
                    }
                  }  
                comment_items_aggregate {
                    aggregate {
                      avg {
                        estimate
                      }
                      count(columns: user_id)
                    }
                  }
              }
              
                
            
        }
        
    }
`;



export const ALL_FROM_CATEGORIES = gql`
    query allFromCtegories {
        category {
            category
            fee
            id
            product_category
            subcategory
        }
    }
`;


export const ADD_PRODUCT_MUTATION = gql`
    mutation addNewItem(
        $about: String,
        $name: String,
        $shop_id: Int,
        $category_id: Int,
        $videoUrl: String,
        $imagesUrls: [ItemImages!],
        $barcode: Int,
        $characteristic: json,
        $height: Int,
        $item_sku: String,
        $length: Int,
        $modifiers: json,
        $weight: Int,
        $width: Int) {
        insert_item(objects: {
            about: $about,
            name: $name,
            shop_id: $shop_id,
            category_items: {
                data: {
                    category_id: $category_id}},
            videos: {data: {url: $videoUrl}},
            images_items: {data: $imagesUrls},
            item_modifiers: {
                data: {
                    barcode: $barcode,
                    characteristic: $characteristic,
                    height: $height,
                    item_sku: $item_sku,
                    length: $length,
                    modifiers: $modifiers,
                    weight: $weight,
                    width: $width}}}) {
            returning {
                id
            }
        }
    }
`;


export const UPDATE_ITEM_STATUS = gql`
    mutation updateItemStatus($_eq: Int) {
        update_item(where: {id: {_eq: $_eq}}, _set: {status: "moderation"}) {
            affected_rows
        }
    }
`


export const CATEGORY_ITEMS = gql`
    query MyQuery($current_category: String!) {
        item(
            where: {
                category_items: { category: { category: { _eq: $current_category } } }
            }
        ) {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category_id
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
        }
        users_likes(where: { user_id: { _eq: $userID}}) {
            item_id
        }
    }
`;


export const PRODUCT_CATEGORIES_ITEMS = gql`
    query MyQuery {
        category_item {
            category_id
            item_id
            category {
                product_category
            }
        }
    }
`;
export const SHOP_ITEMS = gql`
    query MyQuery($shop_id: Int!) {
        item(where: {shop_id: {_eq: $shop_id}}) {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
        }
        users_likes(where: { user_id: { _eq: $userID} }) {
            item_id
        }
    }
`;

export const SUB_CATEGORY = gql`
    query MyQuery($current_category: String) {
        category(
            distinct_on: subcategory
            where: { category: { _eq: $current_category } }
        ) {
            category
            subcategory
        }
    }
`;
export const BASKET_LOAD = gql`
    query MyQuery {
        cart(where: {user_id: {_eq: $userID}}) {
            id
        }
    }
`;
export const CATEGORY = gql`
    query MyQuery {
        category(distinct_on: category) {
            category
        }
    }
`;
export const LIKES_AMOUNT = gql`
    query LoadLikes {
        users_likes(where: { user_id: { _eq: $userID } }) {
            item_id
        }
    }
`;
export const UPDATE_CITY = gql`
    mutation MyMutation($city: String = "", $city_id: Int ) {
        update_user(where: {user_id: {_eq: $userID}}, _set: {city_id: $city_id}){
            affected_rows
        }
    }`;
export const ADD_LIKE = gql`
    mutation MyMutation($ItemID: Int) {
        insert_users_likes(objects: { item_id: $ItemID, user_id: $userID}) {
            affected_rows
        }
    }
`;
export const ADD_LIKE_SHOP = gql`
    mutation MyMutation($ShopID: Int) {
        insert_shops_likes(objects: { shop_id: $ShopID, user_id: $userID}) {
            affected_rows
        }
    }
`;
export const DELETE_LIKE = gql`
    mutation MyMutation($ItemID:Int) {
        delete_users_likes(
            where: { user_id: { _eq: $userID }, item_id: { _eq: $ItemID } }
        ) {
            affected_rows
        }
    }
`;
export const DELETE_LIKE_SHOP = gql`
    mutation MyMutation($ShopID:Int) {
        delete_shops_likes(
            where: { user_id: { _eq: $userID }, shop_id: { _eq: $ShopID } }
        ) {
            affected_rows
        }
    }
`;
export const SHOPS_LIKES = gql`
    query MyQuery {
        shops_likes(where: {user_id: {_eq: $userID}}) {
            shop_id
        }
    }
`;



export const ADD_COMPLAINT = gql`
    mutation MyMutation(
        $modalContent: Int
        $reason: String
        $status: String
        $textValue: String
    ) {
        insert_complaint_comment(
            objects: {
                comment_id: $modalContent
                reason: $reason
                status: $status
                text: $textValue
            }
        ) {
            affected_rows
        }
    }
`;
export const ADD_SHOP_MESSAGE = gql`
    mutation MyMutation($shop_id: Int, $message: String, $reason: String, $status: String, $created_on: timestamptz) {
        insert_shop_messages(
            objects: { user_id: $userID, shop_id: $shop_id, message: $message, reason: $reason, status: $status, created_on: $created_on}
        ) {
            affected_rows
        }
    }
`;
export const SHOPS_NAMES = gql`
    query MyQuery {
        shop {
            id
            name
        }
    }
`;


export const ITEMS_REQUEST = gql`
    query ($like: String) {
        item        {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
        }
        users_likes(where: { user_id: { _eq: $userID } }) {
            item_id
        }
    }
`;
export const SHOP_QUERY = gql`
    query MyQuery($shopID: Int) {
        shop(where: { id: { _eq: $shopID } }) {
            name
            approval
            photo
            info
            carts {
                id
            }
            comment_shops {
                id
                shop_id
                text
            }
        }
        docs(where: { shop_id: { _eq: $shopID } }) {
            activity_license_tg
            activity_license_url
            bank_certificate_tg
            bank_certificate_url
            company_certificate_tg
            company_certificate_url
        }
        item(where: { shop_id: { _eq: $shopID } }) {
            comment_items {
                estimate
            }
        }
        shops_likes(where: {user_id: {_eq: $userID}}) {
            shop_id
        }
    }
`;
export const PRODUCT_QUERY = gql`
    query MyQuery($productId: Int) {
        item(where: { id: { _eq: $productId } }) {
            id
            about
            shop_id
            joint_promo
            name
            price
            old_price
            category_items {
                category {
                    id
                    category
                    subcategory
                }
            }
            shop {
                approval
                name
                id
                photo
                comment_shops {
                    id
                    shop_id
                    text
                }
            }
            joint_shopping {
                id
                current_items
                items_to_end
                item_id
                time_to_end
                price
                end_date
            }
            comment_items {
                advantages
                disadvantages
                estimate
                id
                text
                item_id
                updated_on
                user_id
                user {
                    full_name
                    username
                }
            }
            images_items {
                url
            }
            item_characters {
                character
            }
            item_modifiers {
                modifiers
            }
            videos {
                url
                tg_id
            }}
        user (where: { user_id: { _eq: $userID} }){
            city_id
        }
        users_likes(where: {user_id: {_eq: $userID}}) {
            item_id
        }
    }
`;
export const CHECK_CITY = gql`
    query MyQuery($id: Int) {
        delivery_city(where: {id: {_eq: $id}}) {
            city
            code_sdek
            id
        }
    }
`;
export const BASKET_LOAD2 = gql`
    query MyQuery {
        cart(where: {user_id: {_eq: $userID}}) {
            cost_item
            extended_cost
            id
            shop_id
            item_id
            quantity
            user_id

            shop {
                id
                name
            }

        }
    }

`;
export const BASKET_ITEM = gql`
    query MyQuery($id: Int) {
        item(where: { id: { _eq: $id } }) {
            about
            id
            joint_promo
            name
            price
            shop {
                name
            }
            images_items {
                url
            }
        }
    }
`;

export const RODUCT_QUANTITY = gql`
    query productQuantity($itemId:Int) {
        cart(where: {item_id: {_eq: $itemId}, user_id: {_eq: $userID}}) {
            quantity
            cost_item
        }}`;

// export const ADD_TO_BASKET = gql`
//     mutation MyMutation($price: Int, $id:Int, $shopId:Int) {
//         insert_cart(objects: {cost_item: $price, item_id: $id, quantity: 1, user_id: $userID, shop_id: $shopId}) {
//             returning {
//                 cost_item
//                 extended_cost
//                 id
//                 item_id
//                 quantity
//                 shop_id
//                 user_id
//             }
//         }
//     }

// `;
export const UPDATE_QUANTITY = gql`
    mutation MyMutation($amount: Int!, $id: Int) {
        update_cart(where:
        {item_id: {_eq: $id}, user_id: {_eq: $userID}}, _set: { quantity: $amount }) {
            returning {
                id
            }
        }
    }
`;
export const DELETE_BASKET_ELEMENT = gql`
    mutation MyMutation($id: Int) {
        delete_cart(where: {item_id: {_eq: $id}, user_id: {_eq: $userID}}) {
            returning {
                id
            }
        }
    }
`;
export const PURCHASE = gql`
    query MyQuery {
        order(
            where: {
                line_items: {
                    item_id: { _in: [1913, 1909, 1968, 1915, 1903, 1905, 1907, 1911] }
                }
            }
        ) {
            id
            paid
            line_items {
                item_id
                quantity
            }
        }
    }
`;


export const CHECK_USER_PROFILE = gql`
    query MyQuery {
        user_profile(where: {user_id: {_eq: $userID}}) {
            id
        }
        shop_users(where: {user_id: {_eq: $userID}}) {
            shop_id
        }
    }
`;
export const ADD_USER_PROFILE = gql`
    mutation MyMutation(
        $address_id: Int,
        $currency: String,
        $mail: String,
        $name: String,
        $phone: String,
        $surname: String)
    {
        insert_user_profile(
            objects: {
                user_id: $userID,
                currensy: $currency,
                address_id: $address_id,
                mail: $mail,
                name: $name,
                phone: $phone,
                surname: $surname,
                telegram_notify: "1",
                sms_notify: "1",
                gender: "-"
            })
        { affected_rows }

    }`;

export const USER_PROFILE = gql`
    query MyQuery {
        user_profile(where: {user_id: {_eq: $userID}}) {
            address_id
            birthday
            currensy
            email_notify
            gender
            id
            mail
            email_verify
            name
            middlename
            phone
            photo_url
            sms_notify
            surname
            telegram_notify
        }
        user(where: {user_id: {_eq: $userID}}){
            city_id
        }
        shop(where: {owner_id: {_eq: $userID}}) {
            id
            name
        }
    }
`;

export const UPDATE_USER_PROFILE = gql`

    mutation MyMutation(
        $email_notify: Int,
        $birthday: timestamptz,
        $mail: String,
        $email_verify: Int,
        $sms_notify: Int,
        $name: String,
        $middlename: String,
        $phone: String,
        $surname: String,
        $gender: String,
        $photoUrl: String,
        $telegram_notify: Int) {
        update_user_profile(where: {user_id: {_eq: $userID}},
            _set: {
                birthday: $birthday,
                email_notify: $email_notify,
                mail: $mail,
                email_verify: $email_verify,
                name: $name,
                middlename: $middlename,
                phone: $phone,
                photo_url: $photoUrl,
                sms_notify: $sms_notify,
                surname: $surname,
                telegram_notify: $telegram_notify,
                gender: $gender
            }) {
            returning {
                user_id
            }
        }
    }
`;

export const DELETE_USER_PROFILE = gql`
    mutation MyMutation {
        delete_user_profile(where: {user_id: {_eq: $userID}}) {
            affected_rows
        }
    }`

export const CHECK_CITY_TITLE = gql`

    query MyQuery($id: Int) {
        delivery_city(where: {id: {_eq: $id}}) {
            city
        }
    }
`;

export const ALL_USERS_SHOPS = gql`
    query MyQuery {
        shop(where: {owner_id: {_eq: $userID}}) {
            id
            info
            name
            photo
        }
    }
`


export const ADD_SHOP = gql`
    mutation MyMutation($name: String, $city_id: Int, $info: String) {
        insert_shop(objects: {name: $name,
            owner_id: $userID,
            city_id: $city_id,
            info: $info
        }) {
            affected_rows
            returning {
                id
            }
        }
    }
`


export const ADD_SHOP_DOCS8 = gql`
    mutation MyMutation($INN: String,
        $OGRN: String,
        $company_title: String,
        $company_address: String,
        $bank_title: String,
        $bic: String,
        $type_of_business: String,
        $tax_system: String,
        $shop_id: Int) {
        insert_docs(objects: {INN: $INN,
            OGRN: $OGRN,
            bank_title: $bank_title,
            company_title: $company_title,
            company_address: $company_address,
            bic: $bic,
            type_of_business: $type_of_business,
            tax_system: $tax_system,
            shop_id: $shop_id})
        {
            affected_rows
        }
    }
`

export const ADD_SHOP_DOCS = gql`
    mutation MyMutation($INN: String,
        $OGRN: String,
        $company_title: String,
        $company_address: String,
        $bank_title: String,
        $bic: String,
        $type_of_business: String,
        $tax_system: String,
        $shop_id: Int
    ) {
        insert_docs(objects: {INN: $INN,
            OGRN: $OGRN,
            bank_title: $bank_title,
            company_title: $company_title,
            company_address: $company_address,
            bic: $bic,
            type_of_business: $type_of_business,
            tax_system: $tax_system,
            shop_id: $shop_id
        })
        {
            affected_rows
        }
    }
`

//! check usefull this query
// export const USER_SHOP_BY_ID = gql`
//     query MyQuery ($_ID: Int){
//         item(where: {shop_id: {_eq: $_ID}}) {

//             id

//             quantity
//             shop_id
//             status
//             updated_on

//         }
//         cart(where: {shop_id: {_eq: $_ID}}) {
//             id
//             item_id
//             user_id
//             cost_item
//             extended_cost
//         }
//         shop(where: {id: {_eq: $_ID}}) {
//             approval
//             city_id
//             id
//             info
//             inline
//             name
//             owner_id
//             payment_token
//             photo
//             token
//             username
//         }
//     }

// `


export const SHOPS_ITEMS = gql`
    query MyQuery ($_ID: Int){
        item(where: {shop_id: {_eq: $_ID}}) {
            images_items {
                id
                url
            }
            id
            item_sku
            joint_promo
            name
            old_price
            price
            quantity
            status
            joint_shopping {
                price
            }
        }
    }`


export const ITEM_BY_ID = gql`
    query MyQuery ($_ID: Int){
        item(where: {id: {_eq: $_ID}}) {
            about
            barcode
            created_on
            high
            id

            images_items {
                id
                url
            }
            item_sku
            joint_promo
            lenght
            name
            old_price
            photo
            price
            promotion_id
            quantity
            shop_id
            status
            updated_on
            weight
            width
            category_items {
                category_id
                item_id
                category {
                    category
                    product_category
                    subcategory
                    fee
                }
            }
            joint_shopping {

                id
                item_id

                price

            }
            item_characters {
                character
                id
                item_id
            }

        }
    }

`

export const CHANGE_ITEM_SETTING = gql`
    mutation MyMutation($_ID: Int,$about: String,$character: json) {
        update_item(where: {id: {_eq: $_ID}}, _set: {about: $about}) {
            affected_rows
        }
        update_item_character(where: {item_id: {_eq: $_ID}}, _set: {character: $character})
        {
            affected_rows
        }


    }
`


export const SHOPS_ORDERS_STATISTICS = gql`


    query MyQuery($endDate: timestamptz = "2023-01-10", $shopID: Int = 3900) {
        order(where: {created_on: {_gte: $endDate}, shop_id: {_eq: $shopID}}) {
            id
            created_on
            paid
            payload
            promo
            shipped
            shipped_id
            shop_id
            status
            updated_on
            user_id
            line_items {
                id
                order_id
                quantity
                item_id
                item {
                    price
                    old_price
                }
            }
            payments {
                id
                total_amount
            }
        }
        cart(where: {shop_id: {_eq: $shopID}}) {
            id
            cost_item
            extended_cost
            item_id
            quantity
        }
    }

`


