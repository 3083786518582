import React, { useMemo, useContext } from 'react';
import { useLocation, useParams  } from 'react-router-dom';
import styles from './ShopSettings.module.css'
import ShareButton from '../../ShareButton/ShareButton';
import link from '../../../assets/svg/link.svg';
import { useNavigate } from 'react-router-dom';
import info from '../../../assets/svg/info.svg';
import {useQuery} from '@apollo/client';
import {GET_NEW_SHOP_ORDERS, GET_SHOP_INDICATORS} from '../../../requests/orderRequests';


import {ModalContext} from '../../../App';
import Share from '../../ProductCard/Shop/SharingPage/Share';
import {modalDisplayMode} from '../../PrimaryModal/PrimaryModal';


function Orders({shopId}) {
    const navigate = useNavigate();
    const ordersMenu = [
        {label: "К подтверждению", status: "payed" , navi : '/shop_orders'},
        {label: "К отправке", status: 'confirmed', navi : '/shop_orders'},
        {label: "В доставке", status: 'shipping', navi : '/shop_orders'},
        {label: "Доставлены", status: 'received', navi : '/shop_orders'},
        {label: "Возвраты", status: 'declined', navi : '/shop_orders'},
        // {label: "Отменены", status: 'cancell', navi : '/shop_orders'},
    ];
    
    return (
        <div className={styles.options}>
            <div className={styles.label}>
                <span>ЗАКАЗЫ</span>
            </div>
            {ordersMenu.map((order, index) => (
                <React.Fragment key={index}>
                    <div className={styles.option} onClick={() => navigate(order.navi,  { state: { shopId , status : order.status} })}  >
                        <div className={styles.optionLabel}>
                            {order.label}
                        </div>
                        <div className={styles.arrow}>
                            <img src = {link} alt = ""/>
                        </div>
                    </div>
                    <div className={styles.line}>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

function Services() {
    const navigate = useNavigate();
    const ordersMenu = [
        {label: "Продвижение товара", value: () => navigate('/product_promotion/' )},
        {label: "Акции", value: () => navigate('/promotion/')},
        {label: "Бонусная программа", value: () => navigate('/bonus_settings/')},
        {label: "Промокод и сертификаты", value: () => navigate('/sertificats/')},
        {label: "Отзывы и вопросы", value: () => navigate('/questions')},
    ];
    
    return (
        <div className={styles.options}>
            <div className={styles.label}>
                <span>СЕРВИСЫ</span>
            </div>
            {ordersMenu.map((order, index) => (
                <React.Fragment key={index}>
                    <div className={styles.option} >
                        <div className={styles.optionLabelHide}>
                            {order.label}
                        </div>
                        <div className={styles.arrow}>
                            <img src = {link} alt = "link"/>
                        </div>
                    </div>
                    <div className={styles.line}>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}



function Indiccators({shopId}) {

    const { loading, error, data } = useQuery(GET_SHOP_INDICATORS, {
        fetchPolicy: "cache-and-network",
        variables: { shopId },
      })
    if(loading) return <p>Loading...</p>
    if(error) console.log('error', error)
    console.log('data', data)

    const allOrders = data.shop_orders_aggregate.aggregate.count || 0
    const cancelOrders = data.refund_items_aggregate.aggregate.count || 0
    let percentCancel = (cancelOrders / allOrders * 100).toFixed(2) + '%'
    if(percentCancel === 'NaN%') percentCancel = '0%'


    const payments = data.shop_payments_aggregate.aggregate.sum.total_amount || 0 + ' ₽'
    console.log('payments', payments)
    const avg = data.shop_payments_aggregate.aggregate.avg.total_amount || 0
    const avg_check = Number(avg.toFixed(2)) || 0 + ' ₽'
    const avg_comment = data.comment_item_aggregate.aggregate.avg.estimate || 0
    const avg_estimate = Number(avg_comment.toFixed(2)) 

    const ordersMenu = [
        {label: "Оценка товаров", value: avg_estimate  },
        {label: "Всего заказов", value: data.shop_orders_aggregate.aggregate.count || 0},
        {label: "Отменено заказов", value: data.refund_items_aggregate.aggregate.count || 0},
        {label: "Процент отмен", value: percentCancel || '0%'},
        {label: "Продано товаров", value: data.order_items_aggregate.aggregate.sum.quantity || 0},
        {label: "Всего выручки", value: payments},
        {label: "Средний чек", value: avg_check},
        {label: "Товаров в продаже", value: data.item_aggregate.aggregate.count || 0},

    ];
    
    return (
        <div className={styles.options}>
            <div className={styles.label}>
                <span>ПОКАЗАТЕЛИ</span>
            </div>
            {ordersMenu.map((order, index) => (
                <React.Fragment key={index}>
                    <div className={styles.indicators} >
                        <div className={styles.indicatorsLabel}>
                            {order.label}
                        </div>
                        <div className={styles.indicatorsValue}>
                            {order.value}
                        </div>
                        <div className={styles.infoImage}>
                            <img src = {info} alt = ""/>
                        </div>
                    </div>
                    <div className={styles.line}>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}


function ShopSettings() {
    const location = useLocation();
    const  {shopId} = useParams()
    const shop = location.state.shop;
    const navigate = useNavigate();

    const {setModalActive, setModalMode, setModalContent} = useContext(ModalContext);

    const twoWeeksAgoISO = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return date.toISOString();
      }, []);

      const sharedItemCallback = () => {
        console.log('sharedItemCallbackdddddddddddddddddddddddd')

        setModalMode(modalDisplayMode.withOverlay);
        setModalContent(<Share url = {`https://sovmestno.sale/shop/${shopId}`}/>);
        setModalActive(true);
    }
    

    const { loading, error, data } = useQuery(GET_NEW_SHOP_ORDERS, {
        fetchPolicy: "cache-and-network",
        variables: { shopId, date: twoWeeksAgoISO },
      }) 

    if (loading) return <p>Loading...</p>;
    if (error) console.log(
        'error'
    );

    console.log('data', data)
    const countOrders = data.shop_orders.length || 0
    const sumOrders = data.shop_orders.reduce((accumulator, order) => {
        return accumulator + order.order.shop_payments_aggregate.aggregate.sum.total_amount || 0;
    }, 0);


    
    return (
        
        <div className={styles.root}>
            <div className={styles.pictureContainer}>
                <div className={styles.shareButton}>
                    <ShareButton 
                        title = {"Поделиться магазином"} 
                        callback = {sharedItemCallback}
                        />
                </div>
                <div className={styles.picture}>    
                                                  
                    <img src ={shop.photo} alt='imageShop'/>
                </div>
                <div className ={styles.name}>
                    {shop.name}
                </div>
                <div className ={styles.settingsHide}>
                    <span>Управление магазином</span>
                </div>
            </div>
        <div>
            <div className={styles.mainInfoContainer}>
                <div className={styles.infoText}>
                    <span> новых заказов   <span className={styles.sumInfo}>  {countOrders} </span> </span>
                    <span>на сумму    <span className={styles.sumInfo}> {sumOrders} ₽ </span></span>
                </div>
                {/* <div className={styles.arrow}> 
                    <img src = {link} alt = ""/>

                </div>
                */}
            </div>
        </div>
        <div className={styles.moveConatainer } onClick={()=> navigate('/goods_storage', {state: {shopId}})}>  
            <div className={styles.move}>
                <span>Управление товарами</span>
            </div>
            <div className={styles.arrow}>
                <img src = {link} alt = ""/>
            </div>
        </div>
        {/* <div className={styles.moveConatainer} onClick={()=> navigate('/shop_lots', {state: {shopId}})}> */}
            {/* <div className={styles.move}> */}
                {/* Лоты */}
            {/* </div> */}
            {/* <div className={styles.arrow}> */}
                {/* <img src = {link} alt = ""/> */}
            {/* </div> */}
        {/* </div>     */}
        <div className={styles.optionConatainer}>
            <Orders
                shopId = {shopId}
            />
        </div>
        <div className={styles.optionConatainer}>
            <Services/>
        </div>
        <div className={styles.optionConatainer}>
            <Indiccators shopId = {shopId}/>
        </div>
        </div>
        
    )
}

export default ShopSettings