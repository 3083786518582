import React, {useEffect, useState} from "react";
import styles from "./AddElement.module.css";

import ButtonAddElement from './ButtonAddElement/ButtonAddElement';
import AddElementInfo from './AddElementInfo/AddElementInfo';
import AmountChanger from './AmountChanger/AmountChanger';
import { TelegramContext } from "../../App";

import { useQuery } from "@apollo/client";


export default function AddElement({
                                        shopId,
                                        itemId,
                                       lot,
                                       amount,
                                        setAmount,
                                        setIsAmountChanged,
                                        isAmountChanged,
                                        inProductCard,
                                        setInProductCard,
                                        withOutLot,
                                        

                                   }) {
 

    

    return (
        
        <div className={styles.productCardAddContainer}>
        {withOutLot ? (
            <div className={styles.BasketToCardButtonContainer}>
                <button className={styles.BasketToCardButton}>
                    <span>СКОРО В ПРОДАЖЕ</span>
                </button>
            </div>
        ) : (
            <div> {/* Wrap all components for the false case in a single parent */}
                <div className={styles.row}>
                    <AddElementInfo lot={lot} amount={amount} />
                    <AmountChanger
                        amount={amount}
                        setAmount={setAmount}
                        setIsAmountChanged={setIsAmountChanged}
                        lot={lot}
                    />
                </div>
                <ButtonAddElement
                    lot={lot}
                    itemID={itemId}
                    shopID={shopId}
                    amount={amount}
                    setIsAmountChanged={setIsAmountChanged}
                    isAmountChanged={isAmountChanged}
                    setInProductCard={setInProductCard}
                    inProductCard={inProductCard}
                />
            </div>
        )}
    </div>
    
        
    );

}
