import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { empty, useMutation, useQuery } from '@apollo/client';
import { STORAGE_ITEMS, UPDATE_STORAGE } from '../../../../requests/storageRequests';
import styles from './StorageItems.module.css';
import point from '../../../../assets/svg/point.svg';
import pointActive from '../../../../assets/svg/pointActive.svg';
import { setItem } from '../../../../features/userOrderSlice';




function ShowVariants({variants , setItemsValue, itemsValue, itemId, setChangedItems}) {
    const [selectedValues, setSelectedValues] = useState({});
  
  
  
    const updateCount = (variantId, newCount) => {
      if (newCount < 0) {
        return;
      }
      setSelectedValues({
        ...selectedValues,
        [variantId]: { ...selectedValues[variantId], count: newCount },
      });
      
      setChangedItems(prevItems => ({ ...prevItems, [itemId]: true }))
    };
  
    // const toggleSelected = (variantId) => {
    //   setSelectedValues({
    //     ...selectedValues,
    //     [variantId]: { 
    //       ...selectedValues[variantId], 
    //       selected: !selectedValues[variantId]?.selected,
    //       count: selectedValues[variantId]?.count ?? 0
    //     },
    //   });
      
    // };
  
  
  
  
    useEffect(() => {
      let counts = 0;
      // let choose = false;
      let variants = [];
      Object.keys(selectedValues).forEach(variantId => {
        
        counts += parseInt(selectedValues[variantId].count);
        variants.push( {variantId : variantId, count: selectedValues[variantId].count} ); 
        
        // counts += parseInt(selectedValues[variantId].count);
        // if (selectedValues[variantId].selected) {
        //   choose = true;
        //   }        
  
      });
  
      setItemsValue(prevItemsValue => {
        const newItemsValue = { ...prevItemsValue };
        if (!newItemsValue[itemId]) {
          newItemsValue[itemId] = { count: 0 };
        }
        newItemsValue[itemId].count = counts  ;
        // newItemsValue[itemId].choose = choose;
        newItemsValue[itemId].variants = variants;
        return newItemsValue;
      });
    
    }, [selectedValues]); 
  
  
  
    return(
      <div >
      {
      variants.map((variant, index) => {


        const variantData = selectedValues[variant.id] || 
                        { count: variant.storage_items[0].quantity, 
                                selected: false };
        return (
        <div className={styles.wraperVariat} key={index}>
              <div className={styles.row}>
                <h2 >Вариант {index + 1}</h2>
                {index === 0 && <span>Исходный</span>}
              </div>
  
            <div className={styles.infoVariant}>
  
  
  
                  <div className={styles.itemImage} key={variant.image}>
                      <img src={variant.image} alt="foto" className={styles.avatar} />
                  </div>
  
                  <div className={styles.variantsInfo}>
  
                      <div className={styles.variantText}>
                          <label className={styles.textInfo}>Артикул</label>
                          <span className={styles.labelInfo}>{variant.articleNumber}</span>
                      </div>
                      <div style= {{height: '1vh'}}/  >
                      
  
                      <div className={styles.variantText}>
                          <label className={styles.textInfo}>Длина упаковки (мм)</label>
                          <span className={styles.labelInfo}>{variant.length || '-'}</span>
                      </div>
  
                      <div className={styles.variantText}>
                          <label className={styles.textInfo}>Ширина упаковки (мм)</label>
                          <span className={styles.labelInfo}>{variant.width || '-'}</span>
                      </div>
  
                      <div className={styles.variantText}>
                          <label className={styles.textInfo}>Высота упаковки (мм)</label>
                          <span className={styles.labelInfo}>{variant.height || '-'}</span>
                      </div>
                      <div className={styles.variantText}>
                          <label className={styles.textInfo}>Вес упаковки (мм)</label>
                          <span className={styles.textInfo}>{variant.weight || '-'}</span>
                      </div>
                  </div>
  
  
                  <div className={styles.countButton}>
                    <button type='button' className={styles.doButton} onClick={() =>updateCount(variant.id, parseInt(variantData.count) - 1)}>-</button>
                    <input 
                      type="number"
                      value={variantData.count}
                      onChange={e => updateCount(variant.id, e.target.value)}
                      className={styles.buttonText}
                      style={{ width: `${Math.max(20, (variantData.count.toString().length * 8))}px` }}
                    />
                    <button type='button' className={styles.doButton} onClick={() => updateCount(variant.id, parseInt(variantData.count) + 1)}>+</button>
                  </div>
            </div>
              
              <div className={styles.characterRow}>
                    {variant.purposeName ? (
                      
                      <div className={styles.character}>
                        <label className={styles.labelInfo}>{variant.purposeName}</label>
                        <span className={styles.textInfo}>{variant.purposeValue}</span>
                      </div>
                      
                    ) : ( 
                      <div className={styles.character}>
                          <label className={styles.labelInfo}>-</label>
                      </div> )}
                    {variant.purposeName2 && (
                      <div className={styles.character}>
                    
                        <label className={styles.labelInfo}>{variant.purposeName2}</label>
                        <span className={styles.textInfo}>{variant.purposeValue2}</span>
                      </div>
                    ) }
              </div>
  
  
            </div>
    )})}
    </div>
  
      
    )
  }
  



function ChangeStorageItems(){
    const location = useLocation();
    const storageId = location.state.storageId;
    const shopId = location.state.shopId;
    const [searchTerm, setSearchTerm] = useState('');
    const [showVariants, setShowVariants] = useState(null );

    const [itemsValue, setItemsValue] = useState({});

    const [updateStorage, { saveData }] = useMutation(UPDATE_STORAGE )

    const navigate = useNavigate();

    const [items, setItems] = useState([]);
    const [changedItems, setChangedItems] = useState({}); 

    
   

    const { data, loading, error } = useQuery(STORAGE_ITEMS, {
    fetchPolicy: 'cache-and-network',
    variables: { storageId },
    });

    useEffect(() => {
      if (loading) return; 
      if (error) console.error('Error fetching data:', error);

      
      const items = data.storage_item.map(storageItem => storageItem.item);
      setItems(items);

      const newItemsValue = items.reduce((acc, item) => {
          const itemId = item.id;

          if (item.item_variants.length === 1) {
            const variant = item.item_variants[0];
            return {
              ...acc,
              [itemId]: {
                count: variant.storage_items[0].quantity,
                variantId: variant.id
              }
            };
          } else {
            const variantsData = item.item_variants.map(variant => ({
              variantId: variant.id,
              count: variant.storage_items[0].quantity
            }));
          
            return {
              ...acc,
              [itemId]: {
                count: variantsData.reduce((sum, v) => sum + v.count, 0),
                variants: variantsData
              }
            };
          }
        }, {});

          setItemsValue(newItemsValue); 
    }, [data, loading, error, storageId]);

    



    

   
    
    
   

    const updateCount = (itemId, newCount, variantId) => {

        if (newCount < 0) {
            newCount = 0;
        }

        setItemsValue({
          ...itemsValue,
          [itemId]: { ...itemsValue[itemId], 
                          variantId: variantId,
                          count: newCount },
        });

        setChangedItems(prevItems => ({ ...prevItems, [itemId]: true }));
  
      };


    

   
    const filteredItems = items.filter(
        it => it.name.toLowerCase().includes(searchTerm.toLowerCase()) 
        || it.articleNumber.toLowerCase().includes(searchTerm.toLowerCase())
        
      );

   

      


    async function handleSubmit() {

        const variablesArray = [];

        Object.keys(itemsValue).forEach(itemId => {

          if (changedItems.hasOwnProperty(itemId)) {

          const itemData = itemsValue[itemId];



          if (itemData.variantId){
            variablesArray.push({
              quantity: itemData.count,
              variantId: itemData.variantId,
            });

          }else{
            let variants = itemData.variants;
            variants.forEach(variant => {
              variablesArray.push({
                variantId: variant.variantId,
                quantity: variant.count,  
              }); 
          });

          }

        }
        }
        );
        for (const storageItem of variablesArray) {
        try {
           
              const { data: mutationResult } = await updateStorage({ 
                variables: {
                  storageId: storageId,
                  variantId: storageItem.variantId,
                  quantity: storageItem.quantity,
                } 
              });

            
              if (mutationResult) {
              } else {
                alert("An error occurred while saving the item.");
                await fetch(process.env.REACT_APP_BOT_NOTIFICATION, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: 'нет muatation result', }),
                  });
              }
            } catch (error) {
              console.error("An error occurred:", error);
              alert("Ошибка обновления остатков. Попробуйте еще раз");
              await fetch(process.env.REACT_APP_BOT_NOTIFICATION, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ message: error.message, }),
                });
            }
          
        }
        navigate('/goods_storage', { state: { shopId } });

    };


    return(
        <div className={styles.wrapper} >
            <h1>Редактирование остатков</h1>
            <div className={styles.contentContainer}>

                <div className={styles.inputField}>
                  <input
                    className={styles.search}
                    type="text"
                    placeholder="Введите наименование товара или артикул"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </div>
                <form className={styles.form}>  

                    {filteredItems.map((item, index) => 
                    
                    {

                    

                    return (
                        

                        <div className={styles.wraperItem} key={index}>
                        <div className={styles.itemContainer} >

                        
                            <div className={styles.itemImage}>
                        <img className={styles.image} src={item.item_media[0].path} alt="itemImage" />
                        </div>
                        <div className={styles.itemInfo}>
                          <span className={styles.itemName}>{item.name}</span>
                          <span className={styles.itemArticle}>{item.articleNumber}</span>
                          <span className={styles.itemPurposeCount}>2 свойства; 2 варианта;</span>
                        </div>
                        {
                  (item.item_variants.length === 1) ? (
                    <div className={styles.countButton}>
                      <button type='button' className={styles.doButton} 
                                  onClick={() =>updateCount(item.id, 
                                                        parseInt(itemsValue?.[item.id]?.count ) - 1,
                                                         item.item_variants[0].id)}>-</button>
                      <input 
                        type="number"
                        value={itemsValue?.[item.id]?.count }
                        onChange={e => updateCount(item.id, e.target.value, item.item_variants[0].id)}
                        className={styles.buttonText}
                        style={{
                                  width: `${Math.min(Math.max(20, ((itemsValue?.[item.id]?.count.toString().length  || 3) * 8)), 100)}px` 
                                }}
                        
                          />
                      <button type='button' className={styles.doButton} 
                                  onClick={() => updateCount(item.id,
                                                     parseInt(itemsValue?.[item.id]?.count ) + 1,
                                                     item.item_variants[0].id)}>+</button>
                  </div>
                  ) : (
                    <div className={styles.countButton}>
                  <button type='button' className={styles.doButton}>-</button>
                    <span className={styles.buttonText}> { itemsValue?.[item.id]?.count} </span>
                  <button type='button' className={styles.doButton}>+</button>
                </div>
                  )     
                }
                </div>
                {item.item_variants[0].purposeName && (
                <>
                <span className={styles.link} 
                      onClick={() => setShowVariants(showVariants === item.id ? null : item.id)}
                      >
                      {showVariants === item.id ? 
                      'Закрыть варианты' :
                        'Открыть варианты'}
                    </span>

                    {showVariants === item.id && (
                      <ShowVariants variants={item.item_variants} 
                                    setItemsValue={setItemsValue}
                                    itemId={item.id}
                                    itemsValue={itemsValue}
                                    setChangedItems={setChangedItems}
                      />
                    )}
                    
              </>
              )}
                        </div>
                    )
                    })}
                </form>
            </div>
            <button className={styles.buttonFixed} 
                disabled={!itemsValue} 

                onClick={async() => await handleSubmit()}  >
            Сохранить
          </button>
        </div>
    )
}

export default ChangeStorageItems;