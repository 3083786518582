import {gql} from '@apollo/client';


//const userID = localStorage.getItem('userID') || '';
const userID = localStorage.getItem('userID') || '534677865';
//const userID = localStorage.getItem('userID')


export const USER_ADDRESS = gql`
    query userAddress($userID: bigint) {
        user_address(where: {user_id: {_eq: $userID}}) {
            id
            user_id
            choose
            street
            home
            flat
            index
            title
            city_id
            delivery_city {
                city
                country
                region
            }
        }
        user_profile {
            id
        }
    }
`;

export const UPDATE_USER_PROFILE = gql`
    mutation updateProfile(
        $email_notify: Int,
        $birthday: timestamptz,
        $mail: String,
        $email_verify: Int,
        $sms_notify: Int,
        $phone: String,
        $gender: String,
        $telegram_notify: Int,
        $userID: bigint) {
        update_user_profile(where: {user_id: {_eq: $userID}},
            _set: {
                birthday: $birthday,
                email_notify: $email_notify,
                mail: $mail,
                email_verify: $email_verify,
                phone: $phone,
                sms_notify: $sms_notify,
                telegram_notify: $telegram_notify,
                gender: $gender
            }) {
            returning {
                user_id
            }
        }
    }
`;


export const UPDATE_PROFILE_NAME = gql`
    mutation updateName($userID: bigint,
        $name: String,
        $surname: String,
        $middleName: String) {
        update_user_profile(
            where: {user_id: {_eq: $userID}},
            _set: {
                name: $name,
                surname: $surname,
                middlename: $middleName
            }) {
            returning {
                id
            }
        }
    }
`;


export const UPDATE_PROFILE_AVATAR = gql`
    mutation updateAvatar($userID: bigint,
        $photo_url: String) {
        update_user_profile(
            where: {user_id: {_eq: $userID}},
            _set: {photo_url: $photo_url}) {
            returning {
                photo_url
            }
        }
    }
`;


export const USER_PROFILE = gql`
    query userProfile($userID: bigint)@cached {
        user_profile(where: {user_id: {_eq: $userID}})  {
            birthday
            currensy
            email_notify
            gender
            id
            mail
            email_verify
            name
            middlename
            phone
            photo_url
            sms_notify
            surname
            telegram_notify
            score
        }

        user(where: {user_id: {_eq: $userID}}) {
            id
            delivery_city {
                city
                id
            }
        }

        shop(where: {owner_id: {_eq: $userID}}) {
            name
        }
        company(where: {user_id: {_eq: $userID}}) {
            name
            address
            tax_system
            ogrn
            inn
            kpp
        }                        

        users_likes_aggregate(where: {user_id: {_eq: $userID}}) {
            aggregate {
                count
            }
        }
        shops_likes_aggregate(where: {user_id: {_eq: $userID}}) {
            aggregate {
                count
            }
        }
        comment_item_aggregate(where: {user_id: {_eq: $userID}}) {
            aggregate {
              count
            }
        }
        user_bonus(where: {user_id: {_eq: $userID}}) {
            score
            event_type
          }
          

    }
`;


export const CHOOSING_ADDRESS = gql `
    query choosingAddress($userID: bigint){

    user_profile(where: {user_id: {_eq: $userID}}) {
        name 
        surname
        phone
        mail
      }

}
`





export const GET_ORDERS_USER = gql`
    query MyQuery ($userID: bigint){
        order(where: {user_id: {_eq: $userID}}) {
            id
            created_on
            paid
            payload
            promo
            shipped
            status
            updated_on
            payments {
                total_amount
            }
        }
    }
`


export const GET_BOUGHT_ITEM = gql`
    query BoughtItem ($userID: bigint){
        order(where: {user_id: {_eq: $userID}, _and: {paid: {_eq: true}}}) {
            id
            created_on
            paid
            payload
            promo
            shipped
            status
            updated_on
            payments {
                total_amount
            }
        }
        line_items {
            item {
                name
                price
            }
        }
    }
`

export const GET_USER_ADDRESS = gql`
    query MyQuery ($userID: bigint){
        shipping_address(where: {user_id: {_eq: $userID}}) {
            city
            phone_number
            street_line1
        }
    }
`


export const PRODUCT_CATEGORIES_ITEMS = gql`
    query MyQuery {
        category_item {
            category_id
            item_id
            category {
                product_category
            }
        }
    }
`;
export const SHOP_ITEMS = gql`
    query MyQuery($shop_id: Int!) {
        item(where: {shop_id: {_eq: $shop_id}}) {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
        }
        users_likes {
            item_id
        }
    }
`;
export const SUB_CATEGORY = gql`
    query MyQuery($current_category: String) {
        category(
            distinct_on: subcategory
            where: { category: { _eq: $current_category } }
        ) {
            category
            subcategory
        }
    }
`;


export const CATEGORY = gql`
    query MyQuery {
        category(distinct_on: category) {
            category
        }
    }
`;
export const LIKES_AMOUNT = gql`
    query LoadLikes {
        users_likes{
            item_id
        }
    }
`;


export const ADD_LIKE = gql`
    mutation MyMutation($ItemID: Int) {
        insert_users_likes(objects: { item_id: $ItemID}) {
            affected_rows
        }
    }
`;
export const ADD_LIKE_SHOP = gql`
    mutation MyMutation($ShopID: Int) {
        insert_shops_likes(objects: { shop_id: $ShopID}) {
            affected_rows
        }
    }
`;
export const DELETE_LIKE = gql`
    mutation MyMutation($ItemID:Int) {
        delete_users_likes(
            where: { user_id: { _eq: $ItemID }, item_id: { _eq: $ItemID } }
        ) {
            affected_rows
        }
    }
`;
export const DELETE_LIKE_SHOP = gql`
    mutation MyMutation($ShopID:Int,$userID: bigint) {
        delete_shops_likes(
            where: { user_id: { _eq: $userID }, shop_id: { _eq: $ShopID } }
        ) {
            affected_rows
        }
    }
`;
export const SHOPS_LIKES = gql`
    query MyQuery ($userID: bigint) {
        shops_likes(where: {user_id: {_eq: $userID}}) {
            shop_id
        }
    }
`;



export const ADD_COMPLAINT = gql`
    mutation MyMutation(
        $modalContent: Int
        $reason: String
        $status: String
        $textValue: String
    ) {
        insert_complaint_comment(
            objects: {
                comment_id: $modalContent
                reason: $reason
                status: $status
                text: $textValue
            }
        ) {
            affected_rows
        }
    }
`;
export const ADD_SHOP_MESSAGE = gql`
    mutation MyMutation($shop_id: Int, $message: String, $reason: String, $status: String, $created_on: timestamptz) {
        insert_shop_messages(
            objects: { shop_id: $shop_id, message: $message, reason: $reason, status: $status, created_on: $created_on}
        ) {
            affected_rows
        }
    }
`;
export const SHOPS_NAMES = gql`
    query MyQuery {
        shop {
            id
            name
        }
    }
`;
export const ITEMS_REQUEST_SEARCH = gql`
    query ($like: String) {
        search_item(args: {search: $like })        {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
        }
        users_likes(where: { user_id: { _eq: $userID } }) {
            item_id
        }
    }
`;
export const ITEMS_REQUEST = gql`
    query ($like: String) {
        item        {
            about
            id
            in_stock
            joint_promo
            name
            price
            old_price
            shop_id
            comment_items {
                text
                estimate
            }
            shop {
                name
                approval
            }
            category_items {
                category {
                    subcategory
                }
            }
            joint_shopping {
                price
            }
            images_items {
                url
            }
        }
        users_likes(where: { user_id: { _eq: $userID } }) {
            item_id
        }
    }
`;
export const SHOP_QUERY = gql`
    query MyQuery($shopID: Int) {
        shop(where: { id: { _eq: $shopID } }) {
            name
            approval
            photo
            info
            carts {
                id
            }
            comment_shops {
                id
                shop_id
                text
            }
        }
        docs(where: { shop_id: { _eq: $shopID } }) {
            activity_license_tg
            activity_license_url
            bank_certificate_tg
            bank_certificate_url
            company_certificate_tg
            company_certificate_url
        }
        item(where: { shop_id: { _eq: $shopID } }) {
            comment_items {
                estimate
            }
        }
        shops_likes(where: {user_id: {_eq: "${userID}"}}) {
            shop_id
        }
    }
`;
export const PRODUCT_QUERY = gql`
    query MyQuery($productId: Int) {
        item(where: { id: { _eq: $productId } }) {
            id
            about
            shop_id
            joint_promo
            name
            price
            old_price
            category_items {
                category {
                    id
                    category
                    subcategory
                }
            }
            shop {
                approval
                name
                id
                photo
                comment_shops {
                    id
                    shop_id
                    text
                }
            }
            joint_shopping {
                id
                current_items
                items_to_end
                item_id
                time_to_end
                price
                end_date
            }
            comment_items {
                advantages
                disadvantages
                estimate
                id
                text
                item_id
                updated_on
                user_id
                user {
                    full_name
                    username
                }
            }
            images_items {
                url
            }
            item_characters {
                character
            }
            item_modifiers {
                modifiers
            }
            videos {
                url
                tg_id
            }}
        user (where: { user_id: { _eq: "${userID}"} }){
            city_id
        }
        users_likes(where: {user_id: {_eq: "${userID}"}}) {
            item_id
        }
    }
`;
export const CHECK_CITY = gql`
    query MyQuery($id: Int) {
        delivery_city(where: {id: {_eq: $id}}) {
            city
            code_sdek
            id
        }
    }
`;
export const BASKET_LOAD2 = gql`
    query MyQuery {
        cart(where: {user_id: {_eq: "${userID}"}}) {
            cost_item
            extended_cost
            id
            shop_id
            item_id
            quantity
            user_id

            shop {
                id
                name
            }

        }
    }

`;
export const BASKET_ITEM = gql`
    query MyQuery($id: Int) {
        item(where: { id: { _eq: $id } }) {
            about
            id
            joint_promo
            name
            price
            shop {
                name
            }
            images_items {
                url
            }
        }
    }
`;
export const READ_QUANTITY = gql`
    query readQuantity($itemId:Int) {
        cart(where: {item_id: {_eq: $itemId}, user_id: {_eq: "${userID}"}}) {
            quantity
            cost_item
        }}`;


// export const ADD_TO_BASKET = gql`
//     mutation MyMutation($price: Int, $id:Int, $shopId:Int) {
//         insert_cart(objects: {cost_item: $price, item_id: $id, quantity: 1, shop_id: $shopId}) {
//             returning {
//                 cost_item
//                 extended_cost
//                 id
//                 item_id
//                 quantity
//                 shop_id
//                 user_id
//             }
//         }
//     }

// `;
export const UPDATE_QUANTITY = gql`
    mutation MyMutation($amount: Int!, $id: Int) {
        update_cart(where:
        {item_id: {_eq: $id}, user_id: {_eq: "${userID}"}}, _set: { quantity: $amount }) {
            returning {
                id
            }
        }
    }
`;
export const DELETE_BASKET_ELEMENT = gql`
    mutation MyMutation($id: Int) {
        delete_cart(where: {item_id: {_eq: $id}, user_id: {_eq: "${userID}"}}) {
            returning {
                id
            }
        }
    }
`;
export const PURCHASE = gql`
    query MyQuery {
        order(
            where: {
                line_items: {
                    item_id: { _in: [1913, 1909, 1968, 1915, 1903, 1905, 1907, 1911] }
                }
            }
        ) {
            id
            paid
            line_items {
                item_id
                quantity
            }
        }
    }
`;


export const CHECK_USER_PROFILE = gql`
    query MyQuery {
        user_profile(where: {user_id: {_eq: "${userID}"}}) {
            id
        }
        shop_users(where: {user_id: {_eq: "${userID}"}}) {
            shop_id
        }
    }
`;

export const ADD_USER_PROFILE = gql`
    mutation MyMutation(
        $address_id: Int,
        $currency: String,
        $mail: String,
        $name: String,
        $phone: String,
        $surname: String)
    {
        insert_user_profile(
            objects: {
                currensy: $currency,
                address_id: $address_id,
                mail: $mail,
                name: $name,
                phone: $phone,
                surname: $surname,
                telegram_notify: "1",
                sms_notify: "1",
                gender: "-"
            })
        { affected_rows }

    }`;


export const DELETE_USER_PROFILE = gql`
    mutation deleteUserProfile {
        delete_user_profile(where: {user_id: {_eq: "${userID}"}}) {
            affected_rows
        }
    }`

export const CHECK_CITY_TITLE = gql`

    query MyQuery($id: Int) {
        delivery_city(where: {id: {_eq: $id}}) {
            city
        }
    }
`;

export const ALL_USERS_SHOPS = gql`
    query MyQuery {
        shop(where: {owner_id: {_eq: "${userID}"}}) {
            id
            info
            name
            photo
        }
    }
`


export const ADD_SHOP = gql`
    mutation MyMutation($name: String, $city_id: Int, $info: String) {
        insert_shop(objects: {name: $name,
            owner_id: "${userID}",
            city_id: $city_id,
            info: $info
        }) {
            affected_rows
            returning {
                id
            }
        }
    }
`


export const ADD_SHOP_DOCS8 = gql`
    mutation MyMutation($INN: String,
        $OGRN: String,
        $company_title: String,
        $company_address: String,
        $bank_title: String,
        $bic: String,
        $type_of_business: String,
        $tax_system: String,
        $shop_id: Int) {
        insert_docs(objects: {INN: $INN,
            OGRN: $OGRN,
            bank_title: $bank_title,
            company_title: $company_title,
            company_address: $company_address,
            bic: $bic,
            type_of_business: $type_of_business,
            tax_system: $tax_system,
            shop_id: $shop_id})
        {
            affected_rows
        }
    }
`

export const ADD_SHOP_DOCS = gql`
    mutation MyMutation($INN: String,
        $OGRN: String,
        $company_title: String,
        $company_address: String,
        $bank_title: String,
        $bic: String,
        $type_of_business: String,
        $tax_system: String,
        $shop_id: Int
    ) {
        insert_docs(objects: {INN: $INN,
            OGRN: $OGRN,
            bank_title: $bank_title,
            company_title: $company_title,
            company_address: $company_address,
            bic: $bic,
            type_of_business: $type_of_business,
            tax_system: $tax_system,
            shop_id: $shop_id
        })
        {
            affected_rows
        }
    }
`


// export const USER_SHOP_BY_ID = gql`
//     query MyQuery ($_ID: Int){
//         item(where: {shop_id: {_eq: $_ID}}) {

//             id

//             quantity
//             shop_id
//             status
//             updated_on

//         }
//         cart(where: {shop_id: {_eq: $_ID}}) {
//             id
//             item_id
//             user_id
//             cost_item
//             extended_cost
//         }
//         shop(where: {id: {_eq: $_ID}}) {
//             approval
//             city_id
//             id
//             info
//             inline
//             name
//             owner_id
//             payment_token
//             photo
//             token
//             username
//         }
//     }

// `


export const SHOPS_ITEMS = gql`
    query MyQuery ($_ID: Int){
        item(where: {shop_id: {_eq: $_ID}}) {
            images_items {
                id
                url
            }
            id
            item_sku
            joint_promo
            name
            old_price
            price
            quantity
            status
            joint_shopping {
                price
            }
        }
    }`


export const ITEM_BY_ID = gql`
    query MyQuery ($_ID: Int){
        item(where: {id: {_eq: $_ID}}) {
            about
            barcode
            created_on
            high
            id

            images_items {
                id
                url
            }
            item_sku
            joint_promo
            lenght
            name
            old_price
            photo
            price
            promotion_id
            quantity
            shop_id
            status
            updated_on
            weight
            width
            category_items {
                category_id
                item_id
                category {
                    category
                    product_category
                    subcategory
                    fee
                }
            }
            joint_shopping {

                id
                item_id

                price

            }
            item_characters {
                character
                id
                item_id
            }

        }
    }

`

export const CHANGE_ITEM_SETTING = gql`
    mutation MyMutation($_ID: Int,$about: String,$character: json) {
        update_item(where: {id: {_eq: $_ID}}, _set: {about: $about}) {
            affected_rows
        }
        update_item_character(where: {item_id: {_eq: $_ID}}, _set: {character: $character})
        {
            affected_rows
        }


    }
`


export const SHOPS_ORDERS_STATISTICS = gql`


    query MyQuery($endDate: timestamptz = "2023-01-10", $shopID: Int = 3900) {
        order(where: {created_on: {_gte: $endDate}, shop_id: {_eq: $shopID}}) {
            id
            created_on
            paid
            payload
            promo
            shipped
            shipped_id
            shop_id
            status
            updated_on
            user_id
            line_items {
                id
                order_id
                quantity
                item_id
                item {
                    price
                    old_price
                }
            }
            payments {
                id
                total_amount
            }
        }
        cart(where: {shop_id: {_eq: $shopID}}) {
            id
            cost_item
            extended_cost
            item_id
            quantity
        }
    }

`

export const UPDATE_USER_ADDRESSES = gql`
    mutation updateUserAddress($objects: [user_address_insert_input!]=[]) {
        insert_user_address(
            objects: $objects,
            on_conflict: {
                constraint: user_address_pkey,
                update_columns: choose}) {
            affected_rows
            returning {
                id
            }
        }
    }
`

export const EDIT_ADDRESS = gql`
    mutation editAddress(
        $city_id: Int,
        $flat: String,
        $home: String,
        $index: String,
        $street: String,
        $title: String,
        $id: Int) {
        update_user_address(where: {id: {_eq: $id}},
            _set: {city_id: $city_id, flat: $flat, home: $home, index: $index, street: $street, title: $title}) {
            affected_rows
            returning {
                id
            }
        }
    }
`

export const UPDATE_FIRST_USER_ADDRESS = gql`
    mutation updateFirstUserAddress($id: Int) {
        update_user_address(where: {id: {_eq: $id}}, _set: {choose: true}) {
            affected_rows
            returning {
                id
            }
        }
    }
`


export const DELETE_ADDRESS = gql`
    mutation deleteAddress($id: Int) {
        delete_user_address(where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }`
export const ADD_USER_ADDRESS = gql`
    mutation MyMutation(
        $city_id: Int,
        $flat: String ,
        $home: String ,
        $index: String ,
        $profile_id: Int,
        $street: String ,
        $title: String ,
        $user_id: bigint ) {
        insert_user_address(
            objects: {
                choose: false,
                city_id: $city_id,
                flat: $flat,
                home: $home,
                index: $index,
                profile_id: $profile_id,
                street: $street,
                title: $title,
                user_id: $user_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`

