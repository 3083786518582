import {Outlet, useNavigate} from 'react-router-dom';
import classes from './Header.module.css';
import goBack from '../../assets/svg/goBack.svg';
import Search from './Search';
import Favoutites from './Favourites';
import React from 'react';

const HeaderMinimize = () => {
    const navigate = useNavigate();
    return <header className = {classes.headerMinimize}>
        <div className = {classes.wrapperMinimize}>
            <div className = {classes.row}>
                <img src = {goBack} alt = "" onClick = {() => navigate(-1)}/>
                <Search/>
                <Favoutites/>
            </div>
            <Outlet/>
        </div>
    </header>
};

export default HeaderMinimize