import React, {createContext, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ChangeName from '../ChangeName/ChangeName';
import ProfileNotificationSetting from '../Notification/ProfileNotificationSetting/ProfileNotificationSetting';
import ProfileDelete from '../ProfileDelete/ProfileDelete';
import DefaultDisplayProfileSetting from '../DefaultDisplayProfileSetting/DefaultDisplayProfileSetting';
import ChangePhone from '../Phone/ChangePhone/ChangePhone';
import {TelegramContext} from '../../../../App';


export const displayTypes = {
    base: "BASE",
    changeName: "CHANGE_NAME",
    changePhone: "CHANGE_PHONE",
    changeNotes: "CHANGE_NOTES",
    changeEmail: "CHANGE_EMAIL",
    deleteProfile: "DELETE_PROFILE"
}


export const ProfileSettingContext = createContext("")


function ProfileSetting() {

    const location = useLocation()

    const userProfile = location.state?.userProfile
    const {userID} = useContext(TelegramContext);

    const [displayType, setDisplayType] = useState(displayTypes.base)
    const [name, setName] = useState(userProfile.name)
    const [surname, setSurname] = useState(userProfile.surname)
    const [middleName, setMiddleName] = useState(userProfile.middlename || '')//отчество
    const [phone, setPhone] = useState(userProfile?.phone || '')
    const [verificationCode, setVerificationCode] = useState('')
    const [mail, setMail] = useState(userProfile?.mail || '');
    const [dateBirthday, setDateBirthday] = useState(userProfile?.birthday || '')
    const [gender, setGender] = useState(0);
    const [photoUrl, setPhotoUrl] = useState(userProfile?.photo_url || '')
    const [emailError, setEmailError] = useState(false)
    const [isVerified, setIsVerified] = useState(userProfile?.email_verify || false)

    const notifcationsState = [
        {id: 1, title: "Telegram-бот", checked: !!userProfile.telegram_notify},
        {id: 2, title: "e-mail", checked: !!userProfile.email_notify},
        {id: 3, title: "смс", checked: !!userProfile.sms_notify}
    ]
    const [notifications, setNotifications] = useState(notifcationsState)


    useEffect(() => {
        if (userProfile?.gender === "м") setGender("Мужской")
        if (userProfile?.gender === "ж") setGender("Женский")
        if (userProfile?.gender === "-") setGender("-")
        if (userProfile?.birthday !== null) setDateBirthday(userProfile?.birthday.slice(0, 10))
    }, []) //first loading
   


    //change type if user changed mind about deleting profile or changed data
    const displayBase = () => setDisplayType(displayTypes.base)

    //console.log(verificationCode)
    return (
        <>
        
            {displayType === displayTypes.base && <DefaultDisplayProfileSetting
                                                                
                                                                name = {name}
                                                                gender = {gender}   
                                                                setGender = {setGender}
                 
                                                                dateBirthday = {dateBirthday}
                                                                mail    = {mail}
                                                                surname = {surname}
                                                                middleName = {middleName}
                                                                setDisplayType = {setDisplayType}
                                                                setDateBirthday = {setDateBirthday}
                                                                setEmailError = {setEmailError}
                                                                setMail = {setMail}
                                                                photoUrl = {photoUrl}
                                                                setPhotoUrl={setPhotoUrl}
                                                                verificationCode = {verificationCode} 
                                                                setVerificationCode = {setVerificationCode}
                                                                phone = {phone}
                                                                emailError = {emailError}
                                                                isVerified = {isVerified}
                                                                setIsVerified = {setIsVerified} 
                                                                notifications = {notifications}
                                            />}
                
            {displayType === displayTypes.changeName && <ChangeName
                                                    name = {name}
                                                    surname = {surname}
                                                    middleName = {middleName}
                                                    setDisplayType = {setDisplayType}
                                                    setName = {setName}
                                                    setSurname = {setSurname}
                                                    setMiddleName = {setMiddleName}
                                                    userID={userID}
                                />}

                {displayType === displayTypes.changeNotes &&
                    <ProfileNotificationSetting
                        notifications = {notifications}
                        setNotifications = {setNotifications}
                        setDisplayType = {setDisplayType}
                    />}

                {displayType === displayTypes.changePhone &&
                    <ChangePhone
                        readOnly = {true}
                        headerCallback = {displayBase}
                        phone = {phone}
                        setPhone = {setPhone}
                        verificationCode = {verificationCode}
                        setVerificationCode = {setVerificationCode}
                        setDisplayType = {setDisplayType}
                    />}
                
                {displayType === displayTypes.deleteProfile &&
                    <ProfileDelete phone = {phone} 
                                readOnly = {true} 
                                headerCallback = {displayBase}
                                verificationCode={verificationCode}
                                setVerificationCode={setVerificationCode}
                    />

                }
                {/* </ProfileSettingContext.Provider> */}

            
        </>
    );
}


export default ProfileSetting;
