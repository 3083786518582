import React, {useEffect, useContext, useState} from 'react';
import cn from 'classnames'
import CoinIcon from "./CoinIcon";
import styles from './Bonus.module.css'
import {useDispatch, useSelector} from "react-redux";
import {fetchUserBonus} from "../../../../features/bonusSlice";
import {TelegramContext} from "../../../../App";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import Accordion from './Accordion'
// import {TabPanel} from '@mui/lab';

const BonusCardItem = ({ bonusItem }) => {
  const isMarket = !!bonusItem.bonus_market_id
  const isShop = !!bonusItem.bonus_shop_id
  const daysUntilExpiration = new Date(new Date(bonusItem.expire_date).getTime() - Date.now()).getDate()
  const isExpiring = daysUntilExpiration <= 21

  const bonusCount = bonusItem.totalShopBonus
  const shopName = bonusItem?.shop_bonu?.shop?.name || 'ПСП'
  const shopDescription = bonusItem?.shop_bonu?.shop?.info || 'Надежно, выгодно, стабильно'
  const shopStatus = isMarket ? 'Площадка совместных покупок' : 'Premium-продавец'

  return (
    <div className={styles.bonusCard}>
      <img
        src={bonusItem?.shop_bonu?.shop?.photo ||
          "https://www.rae.es/sites/default/files/styles/wysiwyg_100_/public/2021-07/ramdomtwitter_Mesa%20de%20trabajo%201.png?itok=JfO9YVoD"}
        alt=""
        className={styles.bonusCardImage}
      />
      <div className={styles.bonusCardInfo}>
        <div className={styles.cardInfo}>
          <span className={cn(styles.shopStatus)}>{shopStatus}</span>
          <span className={styles.shopName}>{shopName}</span>
          <span className={styles.shopDescription}>{shopDescription}</span>
        </div>
        <div className={cn(styles.shopBonusCount, {
          [styles.black]: isShop,
          [styles.blue]: isMarket,
          [styles.red]: isExpiring,
        })}>
          <CoinIcon />
          <span>{bonusCount}</span>
        </div>
      </div>
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: '100%', maxHeight: '350px', overflowY: 'scroll' }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BonusHistoryContent = ({ bonusItems, type }) => {
  const filteredItems = bonusItems.filter(({ bonus_market_id, bonus_shop_id }) => {
    if (type === 'market') {
      return !!bonus_market_id
    }
    if (type === 'shop') {
      return !!bonus_shop_id
    }
  })
  return filteredItems.map((item) => (
    <div className={styles.historyItem} key={item.id}>
      <img
        src={item?.shop_bonu?.shop?.photo ||
          "https://www.rae.es/sites/default/files/styles/wysiwyg_100_/public/2021-07/ramdomtwitter_Mesa%20de%20trabajo%201.png?itok=JfO9YVoD"}
        alt=""
        className={styles.historyCardImage}
      />
      <div className={styles.historyCardInfo}>
        <span className={styles.historyShopName}>{item?.shop_bonu?.shop?.name || "ПСП"}</span>
        <span className={styles.historyShopStatus}>
          {item.event_type === 'add' ? "Начисление бонусов" : "Списание бонусов за заказ"}
        </span>
      </div>
      <span className={styles.historyBonusAmount}>{`${item.event_type === 'add' ? '+' : '-'}${item.score}`}</span>
    </div>
  ))
}

const Bonus = () => {
  const { userID } = useContext(TelegramContext);
  const dispatch = useDispatch()
  const { items: bonusItems, isLoading } = useSelector(state => state.bonus)

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchUserBonus(userID))
  }, [userID])

  if (isLoading) {
    return (
      <div>Loading...</div>
    )
  }

  const bonusItemsByShop = bonusItems.reduce((acc, item) => {
    const {
      score,
      bonus_market_id,
      bonus_shop_id,
      event_type
    } = item
    const shopId = bonus_market_id || bonus_shop_id
    if (!acc[shopId]) {
      acc[shopId] = {
        ...item,
        totalShopBonus: event_type === 'add' ? score : -score
      }
      return acc
    }
    if (event_type === 'add') {
      acc[shopId] = { ...acc[shopId], totalShopBonus: acc[shopId].totalShopBonus + score }
    } else {
      acc[shopId] = { ...acc[shopId], totalShopBonus: acc[shopId].totalShopBonus - score }
    }
    return acc
  }, {})

  const totalShopBonus = Object.entries(bonusItemsByShop).reduce((acc, [shopId, bonusItem]) => {
    if (shopId.toString() === '1') {
      return acc
    }
    return acc + bonusItem.totalShopBonus
  }, 0)

  const totalMarketBonus = bonusItemsByShop[1]?.totalShopBonus || 0

  return (
    <div className={styles.root}>
      <h3 className={styles.pageTitle}>Баллы и бонусы</h3>
      <div className={styles.bonusesCountSection}>
        <div className={styles.marketBonus}>
          <span className={styles.marketBonusTitle}>Баллы ПСП</span>
          <span className={styles.marketBonusValue}>{totalMarketBonus}</span>
        </div>
        <div className={styles.shopBonuses}>
          <div className={styles.sellerBonus}>
            <span className={styles.sellerBonusTitle}>Бонусы продавцов</span>
            <span className={styles.sellerBonusValue}>{totalShopBonus}</span>
          </div>
          <div className={styles.expiresSoon}>
            <span className={styles.expiresSoonTitle}>Скоро сгорят</span>
            <span className={styles.expiresSoonValue}>{300}</span>
          </div>
        </div>
        <span className={styles.bonusHelp}>
            Бонусы продавца можно потратить только на товары
            продавца, который их начислил. 1 балл/бонус = 1 ₽
        </span>
      </div>
      <div className={styles.bonusListSection}>
        {Object.values(bonusItemsByShop).map((bonusItem) => (
          <BonusCardItem bonusItem={bonusItem} key={bonusItem.id} />
        ))}
      </div>
      <div className={styles.historySection}>
        <span className={styles.historySectionTitle}>История</span>
        <div className={styles.historySectionContent}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" classes={{ flexContainer: styles.tabs }}>
              <Tab label="Бонусы" {...a11yProps(0)} sx={{ width: '40%', fontSize: '14px', textTransform: 'capitalize' }} />
              <Tab label="Баллы" {...a11yProps(1)} sx={{ width: '40%', fontSize: '14px', textTransform: 'capitalize' }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BonusHistoryContent bonusItems={bonusItems} type="shop" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BonusHistoryContent bonusItems={bonusItems} type="market" />
          </TabPanel>
        </div>
        <div className={styles.divider} />
        <div className={styles.faq}>
          <span className={styles.faqTitle}>Частые вопросы</span>
          <Accordion />
        </div>
      </div>
    </div>
  )
}

export default Bonus
