import React, {useEffect, useRef} from 'react';
import styles from "./ProfileTextarea.module.css"

function ProfileTextarea({
                             label,
                             value,
                             callback,
                             type,
                             placeholder,
                             readOnly,
                             onBlurCallback,
                             maxLength,
                             onKeyDownCallback
                         }) {
    //  console.log("ProfileTextarea")

    const currentRef = useRef(null);

    useEffect(() => {
        if (value) {
            currentRef.current.focus()
            currentRef.current.setSelectionRange(value.length, value.length);
        }
    }, [])
    const onChangeCallbackHandler = (event) => callback(event.currentTarget.value)

    const onKeyDownHandler = (event) => {


        if (event.key === 'Enter') {
            currentRef.current.blur()
            // onKeyDownCallback()
        }
    }

    return (<div className = {styles.wrapper}>
            <label className = {styles.label}>
                {label}
                <textarea className = {styles.registeredInput}
                          rows = {"3"}
                          value = {value}
                          type = {type}
                          onChange = {onChangeCallbackHandler}
                          placeholder = {placeholder}
                          readOnly = {readOnly}
                          onBlur = {onBlurCallback}
                          maxLength = {maxLength}
                          ref = {currentRef}
                          onKeyDown = {onKeyDownHandler}
                />
            </label>
        </div>


    );
}

export default ProfileTextarea;