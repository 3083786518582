import React, {useContext, useState} from "react";
import style from "../OrdersUser/OrdersUser.module.css";
import link from "../../../assets/svg/link.svg";
import point from "../../../assets/svg/point.svg";
import pointActive from "../../../assets/svg/pointActive.svg";


import {TelegramContext} from '../../../App';
import {useNavigate} from 'react-router-dom';

import { useQuery} from '@apollo/client';
import {ALL_USER_REFUNDS} from '../../../requests/refundRequests';

function YearButton ({yearValue, setValue}) {
    const yearButtons = ["2022 г.", "2023 г.", "2024 г."];
  
   
    return (
      <div className={style.buttonContainer}>
        {yearButtons.map((yearButton, i) => (
          <button
            type="button"
            className={`${style.dealButton} ${
              yearValue === yearButton ? style.active : ""
            }`}
            key={yearButton}
            onClick={() => setValue(yearButton)}
          >
            {yearButton}
          </button>
        ))}
      </div>
    );
  };




export function formatStatus(status) {

    // console.log("status",status)
    let color ;
    switch (status) {
      case "payed":
        color = "rgba(96, 96, 96, 1)";    
        return { color, status: "Оплачено" };
  
      case "confirmed":
          color =  "BLACK" ;
          return { color, status: "Принят" };
  
      case "shipping":
          color = "rgba(79, 140, 231, 1)" ;
          return { color, status: "В доставке" };
      case "received":
          color = "rgba(79, 231, 112, 1)" ;
          return { color, status: "Получен" };
      case "cancell":
          color = "RED" ;
          return { color, status: "Отменен" };
      case "declined":
          color = "RED" ;
          return { color, status: "Возврат  " };
      default:
        color = "rgba(96, 96, 96, 1)";
          return { color, status: "В архиве" };
      }
  }
  

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
}



function ShowTypes({ setShowTypes, setTypeOfOrder, typeOfOrder }) {

    // console.log('showTypes', typeOfOrder)

    const types = ["Все", "В работе", "Успешно", "Отказ"];
  
    
    return (
          <div className = {style.chooseForm} >
              <label className = {style.label}>Статус заказа </label>
                  <input
                      className = {style.inputField}
                      type = "text"
                      placeholder = "Введите статус заказа"
                      
                  />
              {types.map((type, i) => (
                <div className = {style.typeContainer} key = {i} onClick = {()=> {
                                                                        setTypeOfOrder(type)
                                                                        setShowTypes(false)
                                                                        }}>
                <div className = {style.type}>{type}</div>
                <div className={style.point}>
                          {type === typeOfOrder
                              ? <img src={pointActive} alt="link"/> 
                              : <img src={point} alt="choose"/>
                          }
                      </div>
                </div>
                
              ))
                }
          </div>
    );
  }
  

function ShowRefunds({data, setShowTypes, typeOfOrder}) {

    const [yearValue, setYearValue] = useState('2024 г.');

    const navigate = useNavigate();

    // console.log('refunds  ', data)

    return (

        <div className = {style.wrapper}>

        <div className={style.title} >
        Возвраты
        </div>
        
            
  
        {/* <div className = {style.container} onClick = { () => setShowTypes(true)}> 
            <div className = {style.textContainer}>
              <span className = {style.text}>Статус возврата</span>
              <span className = {style.linkText}>{typeOfOrder}</span>
            </div>
            <img src = {link} alt = "" className = {style.link}/>
        </div>
        */}
  
        {/* <YearButton yearValue = {yearValue} setValue = {setYearValue}/> */}

        {data.order.map((order, index) => <div key = {index}
                                    className = {style.orderContainer}  >
                                    
              <div  style={{ display: 'flex', flexDirection: "column", gap :'5px   ' }}
                  >
              <div className = {style.orderInfo}>
                  <div className = {style.orderNumber}>Заказ от {formatDate(order.updated_on)}</div>
                  

              </div>

              <div className = {style.imageContainer} >


              {order.order_items.map((order_item, index) =>{
                 
                  const {color,status}  = formatStatus(order_item.status)


                  return (
                  
                  <div className = {style.itemImage} key= {index} >
                  <div className = {style.itemStatus} style = {{color : color}}>{status}</div>
              
                  <img src={order_item.item.item_media[0].path} alt="foto" className={style.avatar}  />
                
                <div className = {style.quanityContainer}>
                  <div className = {style.orderQuantity}>{order_item.quantity}шт.по {order_item.price} ₽</div>
                  <div className = {style.orderSum}> = {order_item.price * order_item.quantity} ₽</div>
                </div>          

                                                    
              </div> ) }
              )}
              </div>

              </div>

              <div className={style.buttonContainer}>
                  <button 
                      className={style.buttonGetting}
                      onClick={() => navigate("/profile/order_info", { state: { orderId: order.id } })}
                  >
                      Подробнее
                  </button>
              </div>


          </div>)}

   
      

  </div>
    )

}


function RefundsUser() {


    const {userID} = useContext(TelegramContext);
    const [typeOfOrder, setTypeOfOrder] = useState('Все');
    const [showTypes, setShowTypes] = useState(false);  


    const {data, error, loading} = useQuery(ALL_USER_REFUNDS,{
        
        fetchPolicy: 'cache-and-network',
        variables: {userID},
    }); 

    if (loading) {
        return 'loading...';
    }
    if (error) {
        console.error('get shop items error', error);
        return 'error';
    }



    return (
        showTypes 
            ? <ShowTypes setShowTypes={setShowTypes} setTypeOfOrder={setTypeOfOrder} typeOfOrder={typeOfOrder}/> 
            : <ShowRefunds data={data} setShowTypes={setShowTypes} typeOfOrder={typeOfOrder} />
    );

    
}

export default RefundsUser
