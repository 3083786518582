import React, {useContext, useState} from "react";
import DeleteLikeShop from "./DeleteLikesShop";
import {useMutation} from "@apollo/client";
import likesImage from "../../assets/svg/likes.svg";
import classes from "./Likes.module.css";
import {likesAmount} from "../LikedItems/utils/AmountOfLikes";
import {ADD_LIKE_SHOP, SHOPS_LIKES} from "../../requests/likesRequests";
import {TelegramContext} from "../../App";


export default function LikeShop({ShopID, doWeHaveLike}) {

    const [add, setAdd] = useState(doWeHaveLike);
    const {userID} = useContext(TelegramContext) || 534677865;

    const [datachange] = useMutation(ADD_LIKE_SHOP, {
        variables: {ShopID, userID},
        refetchQueries: [
            // DocumentNode object parsed with gql
            {queri: SHOPS_LIKES},
        ],
    });

    const show_like = add ? (
        <DeleteLikeShop ShopID = {ShopID} setAdd = {setAdd}/>
    ) : (
        <img
            alt = ""
            src = {likesImage}
            className = {classes.Likes}
            onClick = {() => {
                if (!add && userID !== 0) {
                    datachange();
                }
                setAdd((a) => !a);

                likesAmount(likesAmount() + 1);
            }}
        ></img>
    );
    return <div> {show_like}</div>
    // return <div className = {styles.LikesContainer}>{show_like}</div>;
}
