export const colorList = [
  // "red", "blue", "#FFA500", "green",
    "Красный",    // Red
    "Синий",      // Blue
    "Зеленый",    // Green
    "Желтый",     // Yellow
    "Оранжевый",  // Orange
    "Фиолетовый", // Purple
    "Розовый",    // Pink
    "Серый",      // Gray
    "Черный",     // Black
    "Белый",      // White
    "Коричневый", // Brown
    "Голубой",    // Light Blue
    "Бежевый",    // Beige
    "Золотой",    // Gold
    "Серебряный", // Silver
    "Лиловый",    // Lilac
    "Темно-синий",// Navy Blue
    "Темно-зеленый", // Dark Green
    "Бирюзовый",  // Turquoise
    "Медный",     // Copper
  ];
  