import React, {useEffect, useState} from 'react';
import styles from "./RegisteredEmail.module.css"
import ProfileInput from '../../components/ProfileInput/ProfileInput';
import resend from "../../../../../assets/svg/resend.svg"
import axios from 'axios';
import ProfileInputWithLinks from '../../components/ProfileInputWithLinks/ProfileInputWithLinks';
import ProfileInputMail from './ProfileInputMail';


function RegisteredEmail({
                             mail,
                             emailInputcallback,
                             verificationCode,
                             setVerificationCode,
                             isVerified,
                             setIsVerified,
                             emailError,
                             onBlurCallback
                         }) {
    const [disabledApply, setDisabledApply] = useState(true);
    const [disabledCheck, setDisabledCheck] = useState(true);
    const [disabledTimer, setDisabledTimer] = useState(true);
    const [mode, setMode] = useState('input')//input
    const [seconds, setSeconds] = useState(0);
    const [codeValue, setCodeValue] = useState('')


    

    // const code = '111111'
    const maxTimerLong = 120

    useEffect(() => {
        setDisabledTimer(true)
    }, [mode])

    useEffect(() => {
        mail !== "" && !emailError ? setDisabledApply(false) : setDisabledApply(true)
        setCodeValue('')
    }, [mode])

    useEffect(() => {
        mail === "" && setDisabledApply(true)
    }, [mail])

    useEffect(() => {
        mail !== "" && !emailError
            ? setDisabledApply(false)
            : setDisabledApply(true)
    }, [emailError])

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 1) {
                setSeconds(seconds - 1);
            }
            if (seconds === 1) {
                setDisabledTimer(false)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);

    useEffect(() => {
        codeValue.length === 5 ? setDisabledCheck(false) : setDisabledCheck(true)
    }, [codeValue])

    const toVerifyMode = () => {
        setMode("verify")
        setSeconds(maxTimerLong)
        setDisabledApply(true)

        //get verification code
        const apiUrl = `https://api.jbspace.xyz/send_mail/${mail}`;
        axios.get(apiUrl).then((resp) => {
            // console.log('ответ получен')
            setVerificationCode(resp.data);
            // console.log(resp.data)
        }, (error) => {
            console.log(error);
        });

    }

    const applyButtonCallback = !isVerified ? toVerifyMode : () => {
    }

    const codeValidation = (value, validationCallback) => {
        const regex = /^[0-9]{0,6}$/;
        if (regex.test(value)) {
            validationCallback(value)
        }
    }

    const checkCodeInputhandler = (value) => {
        codeValidation(value, setCodeValue)
    }

    const checkButtonOnClickhandler = () => {
        setIsVerified(true)
        setMode("input")
    }

    const checkButtonClass = !disabledCheck && codeValue === verificationCode
        ? `${styles.checkButton} ${styles.success}`
        : `${styles.checkButton} ${styles.fail}`

    const checkButtonTitle = disabledCheck || codeValue === verificationCode
        ? "Подтвердить"
        : "Неверно"

    const applyButtonClass = !isVerified ? styles.applyButton : `${styles.applyButton} ${styles.confirm}`
    const applyButtonTitle = !isVerified ? "Подтвердить" : "Подтвержден"

    const timerButtonTitle = disabledTimer ? seconds : <img className = {styles.resend} src = {resend}/>

    const onClickTimerButtonHandler = () => {
        //send new email with new code
        //get verification code
        const apiUrl = `https://api.jbspace.xyz/send_mail/${mail}`;
        axios.get(apiUrl).then((resp) => {
            // console.log('ответ получен')
            setVerificationCode(resp.data);
            // console.log(resp.data)
        }, (error) => {
            console.log(error);
        });


        setSeconds(maxTimerLong)
        setDisabledTimer(true)
    }



    return (<>
            {mode === "input" && < div className = {styles.wrapper}>
                <ProfileInputMail  value = {mail} 
                                    callback = {emailInputcallback} 
                                    
                /> 
                <button className = {applyButtonClass} disabled = {disabledApply}
                        onClick = {applyButtonCallback}>{applyButtonTitle}</button>
            </div>}
            {mode === "verify" && < div className = {styles.verifyWrapper}>
                <p className = {styles.message}>{`Код придет на ${mail} течении 60 сек:`}</p>
                <div className = {styles.verifyContent}>
                    <p className = {styles.text}>Введите код:</p>
                    <ProfileInput type = {"text"} placeholder = {"Код"} value = {codeValue}
                                  callback = {checkCodeInputhandler}/>
                    
                    <button className = {checkButtonClass} disabled = {disabledCheck}
                            onClick = {checkButtonOnClickhandler}>{checkButtonTitle}</button>

                    <button className = {styles.timerButton} disabled = {disabledTimer}
                            onClick = {onClickTimerButtonHandler}
                    >
                        {timerButtonTitle}
                        {/*{seconds}*/}
                    </button>
                </div>

            </div>}
        </>

    );
}

export default RegisteredEmail;

